<template>
  <div class="reply-wrapper">

    <div :class="`reply-container ${isReplying ? 'replying' : ''}`">
      <Avatar :userID="reply.authorID" />
      <section class="reply-content">
        <Icon v-if="reply.feedback != null"
          :class="`feedback-icon option-${reply.feedback}`"
          :name="formatFeedbackIcon(reply.feedback)"
          size="0.75rem"
          @mouseover.native="() => tooltipVisible = true" @mouseout.native="() => tooltipVisible = false" />
        <p v-if="tooltipVisible" :class="`feedback-tooltip option-${reply.feedback}`">{{ formatFeedback(reply.feedback) }}</p>

        <p class="content-text">{{ reply.content }}</p>
        <div class="links">
          <p v-for="link in reply.links" class="writing"
            @click="loadAttachment(link._id)">{{ link.title }}</p>
        </div>

        <div class="reply-buttons">
          <div v-if="reply.replies && reply.replies.length" class="view-replies-button"
            @click="repliesVisible = !repliesVisible">
            <Icon :name="repliesVisible ? 'less-arrow' : 'more-arrow'" size="0.75rem" />
            <span>{{ `${repliesVisible ? 'Hide' : 'View'} ${formatReplyCount}` }}</span>
          </div>

          <div v-if="canReply" class="reply-button" @click="clickNewReply">
            <Icon name="plus" size="0.75rem" />
            <span>Reply</span>
          </div>

          <div v-if="$store.state.user && $store.state.user._id == reply.authorID" class="delete-button"
            @click="deleteModalVisible = true">
            <Icon name="x" size="0.75rem" />
            <span>Delete</span>
          </div>
        </div>

        <ReadingRoom v-if="readingRoomVisible"
          :content="attachmentPreview ? attachmentPreview.content : null"
          backgroundImage="https://cdnb.artstation.com/p/assets/images/images/005/620/115/large/raphael-lacoste-mountain-rider-v02-net.jpg?1492521048"
          @close="closeReadingRoom()" />
      </section>
    </div>

    <div v-if="repliesVisible" class="replies-container">
      <Reply v-for="reply in reply.replies" :reply="reply" :canReply="canReply" @new-reply-success="$emit('success')" />
    </div>

    <div v-if="isReplying" class="new-reply-container">
      <NewReply :parentID="reply._id" @close="isReplying = false" @success="newReplySuccess" />
    </div>

    <Modal v-if="deleteModalVisible" message="Delete reply?" :buttons="['yes', 'no']"
      @modal-yes="deleteReply" @modal-no="deleteModalVisible = false"/>

  </div>
</template>

<script>
  import Avatar from '../../User/Avatar.vue';
  import ReadingRoom from '../../Creation/Writings/ReadingRoom.vue';
  import Reply from './Reply.vue'
  import NewReply from './NewReply.vue';

  export default {
    name: 'Reply',
    components: {
      Avatar,
      ReadingRoom,
      NewReply
    },
    props: {
      reply: Object,
      canReply: Boolean
    },
    data() {
      return {
        tooltipVisible: false,
        readingRoomVisible: false,
        attachmentPreview: null,
        repliesVisible: false,
        isReplying: false,
        deleteModalVisible: false
      }
    },
    computed: {
      formatReplyCount() {
        if (!this.reply.replies || !this.reply.replies.length) return '0 Replies';

        const calc = (replies, count) => {
          let newCount = count;
          replies.forEach(reply => {
            if (reply.replies && reply.replies.length) newCount += calc(reply.replies, count);
          });
          return newCount + replies.length;
        }

        let count = calc(this.reply.replies, 0);

        if (count === 1) return `${count} Reply`;
        else return `${count} Replies`;
      }
    },
    methods: {
      formatFeedback(feedback) {
        switch(feedback) {
          case 0:
            return 'Tough Love';
          case 2:
            return 'Reactions Only';
          default:
            return 'Constructive Criticism';
        }
      },

      formatFeedbackIcon(feedback) {
        switch(feedback) {
          case 0:
            return 'swords';
          case 2:
            return 'no comments';
          default:
            return 'rook';
        }
      },

      loadAttachment(id) {
        this.$store.dispatch('fetchAttachment', id).then(response => {
          if (response.status === 200) {
            this.attachmentPreview = response.data;
            this.readingRoomVisible = true;
            this.$store.commit('changeScrollLock', true);
          }
        });
      },

      closeReadingRoom() {
        this.readingRoomVisible = false;
        this.$store.commit('changeScrollLock', false);
      },

      clickNewReply() {
        if (!this.$store.state.user) this.$router.push('/login');
        else this.isReplying = !this.isReplying;
      },

      newReplySuccess() {
        this.isReplying = false;
        this.repliesVisible = true;
        this.$emit('new-reply-success');
      },

      deleteReply() {
        this.$store.dispatch('deleteReply', this.reply._id).then(response => {
          console.log(response);
          this.$store.dispatch('fetchDiscoverPosts');
        });
        this.deleteModalVisible = false;
      }
    }
  }
</script>

<style scoped lang="scss">

  .reply-wrapper {
    padding-left: 0.5rem;
    background-color: #292d32;
  }

  .reply-container {
    position: relative;
    @include flex($align: flex-start);
    flex: 1;
    padding: 0.75rem 1.5rem;
    box-sizing: border-box;
    background-color: #393e45;
    border-left: 2px solid #fff;

    .avatar {
      margin-right: 1.5rem;
    }

    &.replying {
      box-shadow: 0 6px 12px -6px rgba(0,0,0,.5);
    }
  }

  .reply-content {
    position: relative;
    @include flex($direction: column, $align: flex-start);
    width: 100%;

    .feedback-icon{
      padding: 0.25rem;

      &.option-0 {
        color: #df4545;
      }
      &.option-1 {
        color: #467ac8;
      }
      &.option-2 {
        color: #35bb6b;
      }
    }

    .feedback-tooltip {
      position: absolute;
      top: -2.75rem;
      left: 0;
      padding: 0.5rem 1rem;
      transform: translateX(calc(-50% + 0.75rem));
      border-radius: 0.5rem;
      background-color: #34383e;
      font-size: 0.75rem;
      font-weight: 600;
      z-index: 999;

      &.option-0 {
        color: #df4545;
      }
      &.option-1 {
        color: #467ac8;
      }
      &.option-2 {
        color: #35bb6b;
      }
    }
  }

  .content-text {
    margin-top: 0.5rem;
  }

  .links {
    width: 100%;
    margin-bottom: 1rem;
  }

  .writing {
    width: 100%;
    max-height: 180px;
    margin-top: 1rem;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background-color: #1e1f22;
    white-space: pre-wrap;
    overflow: auto;

    &:hover {
      cursor: pointer;
      background-color: #222428;
    }
  }

  .reply-buttons {
    width: 100%;
    @include flex($justify: flex-end);

    > * {
      position: relative;
      @include flex();
      padding: 0.5rem 0.75rem;
      box-sizing: border-box;
      opacity: 0.75;
      transition: opacity 0.15s;
      user-select: none;

      &, span {
        color: $colorA;
      }
      span {
        margin-left: 0.25rem;
        font-size: 0.9rem;
        font-weight: 400;
      }

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }

    .view-replies-button {
      &, span {
        color: $colorC;
      }
    }

    .delete-button {
      &, span {
        color: $alert-error-color;
      }
    }
  }

  .new-reply-container {
    width: 100%;
    padding: 2rem;
    background-color: #4d5360;
  }

</style>
