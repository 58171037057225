
import Vue from 'vue'
import { api, apiFile } from '@/utility.js'


export const languageMutations = {

  loadMyLanguages(state, languages) {
    let myLanguages = {};
    languages.forEach(language => {
      myLanguages[language._id] = language;
    });
    state.languages = myLanguages;
  },

  loadLanguage(state, language) {
    if (!state.languages) state.languages = {};
    Vue.set(state.languages, [language._id], language);
  },

  renameLanguage(state, data) {
    let index = state.world.languages.findIndex(language => language._id == data.languageID)
    state.world.languages[index].title = data.title;
  },

  deleteLanguage(state, data) {
    let newLanguages = state.world.languages.filter(language => language._id != data.languageID)
    state.world.languages = newLanguages;
  },


  addStem(state, data) {
    if (!state.languages[data.languageID].stems) {
      state.languages[data.languageID].stems = [];
    }
    state.languages[data.languageID].stems.push(data.stem);
  },

  updateStem(state, data) {
    let newStems = [...state.languages[data.languageID].stems];
    newStems[data.stem.index][data.stem.header] = data.stem.value;
    state.languages[data.languageID].stems = newStems;
  },

  deleteStem(state, data) {
    state.languages[data.languageID].stems.splice(data.index, 1);
  },



  updateKeyMap(state, data) {
    state.languages[data.languageID].keyMap = data.keyMap;
  },

}


export const languageActions = {

  async fetchMyLanguages({ commit, state }, id) {
    let response = await api('GET', 'myLanguages');
    if (response.status === 200 && response.data && response.data.length) {
      commit('loadMyLanguages', response.data);
    }
    return response;
  },

  async fetchLanguage({ commit, state }, id) {
    let response = await api('GET', 'language/' + id);
    if (response.status === 200 && response.data) {
      commit('loadLanguage', response.data);
    }
    return response;
  },

  async newLanguage({ commit, state }, language) {
    return await api('POST', 'language/new', language);
  },

  async renameLanguage({ commit, state }, data) {
    return await api('PUT', 'language/rename', data);
  },

  async deleteLanguage({ commit, state }, data) {
    return await api('POST', 'language/delete', data);
  },



  async addStem({ commit, state }, data) {
    commit('addStem', data);
    return await api('PUT', `language/${data.languageID}/updateStems/`, state.languages[data.languageID].stems);
  },

  async updateStem({ commit, state }, data) {
    commit('updateStem', data);
    return await api('PUT', `language/${data.languageID}/updateStems/`, state.languages[data.languageID].stems);
  },

  async deleteStem({ commit, state }, data) {
    commit('deleteStem', data);
    return await api('PUT', `language/${data.languageID}/updateStems/`, state.languages[data.languageID].stems);
  },



  async updateKeyMap({ commit, state }, data) {
    commit('updateKeyMap', data);
    return await api('PUT', 'language/updateKeyMap', data);
  },

  async uploadRuneImage({ commit, state }, data) {
    return await apiFile('POST', 'language/uploadRune', data);
  },

}
