<template>
  <div class="landing">

    <div class="header-image"></div>

    <div class="spacer h5"></div>
    <div class="spacer h3"></div>

    <div class="landing-logo-mask animate">
      <div class="base"></div>
      <div class="glow"></div>
    </div>

    <div class="headline">

      <!-- <GlowingPattern pattern="top-right-corner-threads" /> -->
      <!-- <Fireflies /> -->

      <h1><span class="colorful-title animate glow" data-glow-text="Experience">Experience</span> your world as you create it.</h1>

      <div class="spacer"></div>
      <h3>Worldbuilding as <span>immersive</span> as storytelling.</h3>
      <div class="spacer"></div>
      <h3>The tools are laid out for you.</h3>
      <div class="spacer"></div>
      <h3>No distractions.</h3>
      <div class="spacer"></div>
      <h2>Turn your vision into reality.</h2>
      <div class="spacer h4"></div>
      <div class="small-horizontal-line"></div>
      <div class="spacer h4"></div>
      <div class="about-hook">
        <p>Stories are foundational to human existence.</p>
        <div class="spacer"></div>
        <p>Scrybe aims to be the world's first immersive worldbuilding platform. A toolset that helps storytellers visualize and organize both their stories and the worlds in which they take place.</p>
        <div class="spacer"></div>
        <p>No longer do you need deep pockets to realize your imagination.</p>
      </div>

      <div class="spacer h5"></div>

      <template v-if="promotionEndDate && (new Date(promotionEndDate).getTime()) > Date.now()">
        <div class="small-horizontal-line"></div>
        <div class="spacer h5"></div>

        <p><span class="promotion">All features, 1 year free</span></p>

        <div class="spacer"></div>
        <div class="about-hook" id="text-trigger">
          <TypewriterText
            text="During the promotional period for the official launch, all new Scrybe users will receive one year free acceess to all Scrybe features."
            :delay="0"
            :stagger="15"
            :on="promotionTextTriggered"
            :hidden="!promotionTextTriggered"
          />
          <!-- <p>During the promotional period for the Beta release, all new Scrybe users will receive one year free acceess to all Scrybe features.</p> -->
        </div>
        <div class="spacer h3"></div>

        <p class="promotion-timer-text">Promotion ends in:</p>
        <h1 class="colorful-title" style="font-size: 2rem;">{{ timeUntilRelease }}</h1>
        <div class="spacer"></div>
      </template>

      <CoolButton
        label="Create a Free Account"
        type="compact"
        textColor="#66f1d0"
        bgColor="rgb(102,241,208)"
        selected
        animation="infinite"
        touchClass="hoverAnimation"
        @click.native="$router.push('/register')"
      />
    </div>
    <div class="spacer"></div>


    <div class="subscription-container">

      <!-- WRITINGS -->
      <div class="subscription-item floating"
        @click="goToElement('writings')"
      >
        <div class="bg-container">
          <div class="texture-bg fantasy"></div>
        </div>

        <div class="card-image writings"></div>

        <img src="https://media.scrybe-app.com/assets/elements/writing_icon.png" />

        <h1 class="">Writings</h1>
        <p class="description">Immerse yourself in your writing and create an immersive reading experience for your readers.</p>
        <div style="margin: auto;"></div>
      </div>

      <!-- WIKIS -->
      <div class="subscription-item floating"
        @click="goToElement('wikis')"
      >
        <div class="bg-container">
          <div class="texture-bg fantasy"></div>
        </div>

        <div class="card-image wikis"></div>

        <img src="https://media.scrybe-app.com/assets/elements/wiki_icon.png" />

        <h1 class="">Wiki</h1>
        <p class="description">Organize your own worldbuilding library by categorizing information about your world and linking it across the site.</p>
        <div style="margin: auto;"></div>
      </div>

      <!-- MAPS -->
      <div class="subscription-item floating"
        @click="goToElement('maps')"
      >
        <div class="bg-container">
          <div class="texture-bg fantasy"></div>
        </div>

        <div class="card-image maps"></div>

        <img src="https://media.scrybe-app.com/assets/elements/map_icon.png" />

        <h1 class="">Maps</h1>
        <p class="description">Create interactive maps using your own images or generate a map in seconds to kick start your ideas.</p>
        <div style="margin: auto;"></div>
      </div>

      <!-- TIMELINES -->
      <div class="timelines-card subscription-item floating"
        @click="goToElement('timelines')"
      >
        <div class="bg-container">
          <div class="texture-bg fantasy"></div>
        </div>

        <div class="card-image timelines"></div>

        <img src="https://media.scrybe-app.com/assets/elements/timeline_icon.png" />

        <h1 class="">Timelines</h1>
        <p class="description">Keep a bird's eye view on your story by creating interactive timelines that use your own calendar and dating systems.</p>
        <div style="margin: auto;"></div>
      </div>

      <!-- LANGUAGES -->
      <div class="subscription-item floating"
        @click="goToElement('languages')"
      >
        <div class="bg-container">
          <div class="texture-bg fantasy"></div>
        </div>

        <div class="card-image languages"></div>

        <img src="https://media.scrybe-app.com/assets/elements/empty_icon.png" />

        <h1 class="">Languages</h1>
        <p class="description">Brainstorm ideas for your fictional languages and construct a cohesive system piece by piece.</p>
        <div style="margin: auto;"></div>

        <div class="price-container">
          <div class="coming-soon">
            <h5>Coming Soon</h5>
          </div>
        </div>
      </div>
    </div>


    <div class="spacer h5"></div>
    <div class="spacer h5"></div>


    <LandingWritings />
    <LandingWikis />
    <LandingTimelines />
    <LandingMaps />
    <LandingLanguages />
    <Data />
    <Roadmap :timeUntilRelease="timeUntilRelease" />

  </div>
</template>

<script>
  import LandingWritings from './Sections/Writings.vue'
  import LandingWikis from './Sections/Wikis.vue'
  import LandingTimelines from './Sections/Timelines.vue'
  import LandingMaps from './Sections/Maps.vue'
  import LandingLanguages from './Sections/Languages.vue'
  import Data from './Sections/Data.vue'
  import Roadmap from './Sections/Roadmap.vue'
  import shelves from './shelves'
  import data_animation_items from './data_animation_items'
  import Fireflies from './Fireflies.vue'
  import GlowingPattern from '../GlowingPattern.vue'
  import promotion from '../../config/promotion'


  export default {
    name: 'Landing',
    components: {
      LandingWritings,
      LandingWikis,
      LandingTimelines,
      LandingMaps,
      LandingLanguages,
      Data,
      Roadmap,
      Fireflies,
      GlowingPattern,
    },

    data() {
      return {
        scrollY: 0,
        tickInterval: null,
        promotionTextTriggered: false,
        promotionEndDate: promotion.promotionEndDate,
        timeUntilRelease: null
      }
    },

    computed: {
      shelves() {
        return shelves
      },

      animationItems() {
        return data_animation_items
      }
    },

    methods: {
      onScroll() {
        this.scrollY = window.scrollY;

        // trigger or reset promotion typewriter text animation
        const textTrigger = document.getElementById('text-trigger').getBoundingClientRect()
        const distanceFromBottom = textTrigger.bottom - window.innerHeight
        if (distanceFromBottom < -40 && !this.promotionTextTriggered) {
          this.promotionTextTriggered = true
        }
        if (distanceFromBottom > 60 && this.promotionTextTriggered) {
          this.promotionTextTriggered = false
        }
      },

      tickBetaTimer() {
        this.timeUntilRelease = this.formatDateDelta(this.promotionEndDate, true)
      },

      parallax(start, factor) {
        return `top: ${start + (this.scrollY / factor)}px;`;
      },

      goToElement(id) {
        const element = document.getElementById(id)
        if (element) element.scrollIntoView()
      }
    },

    mounted() {
      this.scrollY = window.scrollY
      window.addEventListener('scroll', this.onScroll)
      this.tickInterval = window.setInterval(() => {
        this.tickBetaTimer()
      }, 1000)

      this.$store.dispatch('access')
    },

    destroyed() {
      window.removeEventListener('scroll', this.onScroll)
      window.clearInterval(this.tickInterval)
    }
  }
</script>

<style lang="scss">

  .landing {
    position: relative;
    text-align: center;
  }

  .landing-logo {
    margin-bottom: -2rem;
    height: 320px;
    width: 320px;
    opacity: 0.5;
  }

  .landing-logo-mask {
    position: relative;
    height: 320px;
    width: 320px;
    margin: 0 auto;
    margin-bottom: -2rem;
    opacity: 1;

    .base, .glow {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      mask-size: 100% 100%;
      background-size: auto;
      background-position: 40% 40%;
    }

    .base {
      -webkit-mask-image: url('/img/preload/logo_only_dark.png');
      mask-image: url('/img/preload/logo_only_dark.png');
      background-image: url('/img/preload/text_background_01.jpeg');
      z-index: 1;
      opacity: 1;
    }

    .glow {
      -webkit-mask-image: url('/img/preload/logo_only_dark_blur.png');
      mask-image: url('/img/preload/logo_only_dark_blur.png');
      background-image: url('/img/preload/text_background_01_contrast_blur.jpeg');
      z-index: 2;
      opacity: 1;
      // filter: blur(8px);
    }

    &.animate {
      .base, .glow {
        animation: movingLogoBackground 15s infinite -5s;
        animation-timing-function: linear;
      }
    }
  }

  @keyframes movingLogoBackground {
    0% { background-position: 40% 0%; }
    100% { background-position: 60% 100%; }
  }

  .headline {
    position: relative;
    padding: 6rem 1rem;
    padding-top: 2rem;

    h1 {
      color: #fff;
      font-weight: 500;
    }
  }

  .small-horizontal-line {
    height: 1px;
    width: 40px;
    margin: auto;
    background-color: rgba(255,255,255,.5);
  }

  .about-hook {
    max-width: 640px;
    margin: auto;
  }

  .header-image {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 800px;
    margin-top: 0;
    margin-bottom: 2rem;
    // background-image: url('~@/assets/landing_bg.jpeg');
    // background-image: url('~@/assets/library_01.jpg');
    background-image: url('https://media.scrybe-app.com/assets/landing/header_bg.jpeg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    mask-image: linear-gradient(0deg, rgba(0,0,0,0), rgba(0,0,0,.15) 50%, rgba(0,0,0,1));
    // mask-image: radial-gradient(circle at top center, rgba(0,0,0,1), rgba(0,0,0,.25) 50%, rgba(0,0,0,0) 75%);
    z-index: -1;
  }

  .book-shelves {
    top: 500px;
    mix-blend-mode: color-dodge;
    z-index: -1;
  }

  h1 {
    margin: 1rem 0;
    color: $colorA;
    font-size: 2rem;
    font-weight: 600;
    text-transform: none;

    span {
      &.highlight {
        color: $colorB;
      }
    }
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    color: $offWhite;
    font-weight: 400;
    letter-spacing: -0.5px;

    span {
      font-weight: 600;
      &.highlight {
        color: $colorB;
      }
      &:not(.highlight) {
        filter: drop-shadow(0 0 8px rgba(255,255,255,.75));
      }
    }
  }

  h4 {
    font-size: 1rem;
  }

  p {
    line-height: 1.5rem;
  }

  .promotion {
    color: $colorA;
    font-size: 1.25rem;
    font-weight: 600;
    filter: drop-shadow(0 0 8px rgba(149,255,195,.75));
  }

  .promotion-timer-text {
    color: $colorB;
    font-size: 1.1rem;
  }




  // ==============
  // =  SECTIONS  =
  // ==============

  .landing-section {
    position: relative;
    width: 100%;
    padding: 10rem 3rem;
    box-sizing: border-box;

    .section-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      overflow: hidden;
      z-index: -1;

      .bg-image {
        width: 100%;
        height: 100%;
      }
    }

    h1 {
      margin-bottom: 4rem;
      color: #fff;
      opacity: 0.75;
      font-weight: 500;
    }

    p {
      color: $offWhite;
    }

    .section-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
      max-width: 1200px;
      margin: auto;

      .showcase-image {
        grid-area: image;
        @include flex($direction: column, $justify: center);
        width: 100%;
        height: 100%;

        img {
          width: 600px;
          border-radius: 12px;
          box-shadow: 0 20px 60px -40px rgba(0,0,0,1), 0 8px 16px 0px rgba(0,0,0,.25);
          object-fit: cover;
          user-select: none;
        }
      }

      .content-header {
        grid-area: header;
        display: inline-block;
        max-width: 480px;
        margin: auto 0;

        h2 {
          font-size: 1.25rem;
          padding-bottom: 1.5rem;
        }
      }
    }

    .points {
      // @include flex($align: stretch);
      @include flex($direction: column);
      // margin-top: 3.5rem;
      margin-top: 1rem;
      text-align: left;
      grid-area: points;

      > * {
        width: 100%;
        max-width: 480px;
        margin: 1.5rem 0;
        flex-basis: 100%;
        flex-shrink: 1;
        // padding: 1.5rem;
        padding: 0;
        // border-radius: 1rem;
        // background-color: rgba(255,255,255,.05);
        background-color: transparent;
        // box-shadow: 0 15px 50px -30px rgba(0,0,0,.5), 0 5px 15px -5px rgba(30,32,36,.25);
        box-shadow: none;

        h4 {
          @include flex();
          margin-bottom: 1rem;
          font-weight: 600;

          .icon {
            margin-right: 1rem;
          }
        }
      }

      // > *:not(:last-child) {
      //   margin-right: 2rem;
      // }
    }

    .data-animation-container {
      position: absolute;
      top: 0;
      left: calc(50vw - 240px);
      width: 480px;
      height: 160px;
      border-radius: 1rem;
      background-color: rgba(0,0,0,.1);
      white-space: nowrap;
      overflow: hidden;
      z-index: 99;

      p {
        position: absolute;
        left: -400px;
        font-weight: 600;
        animation-name: dataItemCycle;
        animation-duration: 20s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        &.colorA {
          color: #5ddfc4 !important;
        }
        &.colorB {
          color: #f16262 !important;
        }
        &.colorC {
          color: #98a8fb !important;
        }
        &.colorD {
          color: #f7e7a0 !important;
        }
      }
    }

    @keyframes dataItemCycle {
      0% {
        left: -400px;
      }
      100% {
        left: 600px;
      }
    }
  }

  // ==================
  // =  END SECTIONS  =
  // ==================



  .register-container {
    @include flex($justify: center);
    width: 100%;
    margin: 2rem 0;
  }




  .subscribe-title {
    letter-spacing: 1px;
  }

  .subscription-container {
    position: relative;
    @include flex($justify: center);
    flex-wrap: wrap;
  }

  .bg-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0.5rem;
    z-index: -1;
    opacity: 0.2;
    transition: all 0.15s;

    .texture-bg {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    z-index: -2;
    // opacity: 0.5;

    &.writings {
      background-image: url('https://media.scrybe-app.com/assets/elements/writings_card.png');
    }
    &.wikis {
      background-image: url('https://media.scrybe-app.com/assets/elements/wikis_card.png');
    }
    &.maps {
      background-image: url('https://media.scrybe-app.com/assets/elements/maps_card.png');
    }
    &.timelines {
      background-image: url('https://media.scrybe-app.com/assets/elements/timelines_card.png');
    }
    &.languages {
      background-image: url('https://media.scrybe-app.com/assets/elements/languages_card.png');
    }
  }

  .subscription-item {
    position: relative;
    @include flex($direction: column);
    width: 260px;
    height: 340px;
    margin: 1rem;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: bg(20%);
    user-select: none;
    transition: all 0.15s;
    z-index: 2;
    overflow: hidden;

    img {
      width: 90px;
      height: 90px;
    }

    h1 {
      margin: 1rem 0;
      font-size: 1.25rem;
      color: #fff;
      font-weight: 500;
    }

    .description {
      color: neutral(85%);
      font-size: 0.85rem;
      line-height: 1.25em;
      text-align: center;
    }

    .card-image {
      transition: filter 0.3s;
    }

    &.owned {
      img, h1, .description {
        opacity: 0.35;
      }
      .bg-container {
        opacity: 0.1;
      }
    }

    &:hover:not(.owned) {
      cursor: pointer;
      transform: scale(1.05);
      box-shadow: 0 40px 60px -50px rgba(0,0,0,.5), 0 40px 60px -50px rgba(0,0,0,1);

      .card-image {
        filter: brightness(1.5) saturate(1.1);
      }
    }
  }

  .coming-soon {
    @include flex();
    color: #fff;
    font-family: 'Cinzel Decorative', Avenir;
    opacity: 0.5;

    h5 {
      margin-left: 6px;
      font-size: 1rem;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }



  @media (max-width: $breakpoint-large) {
    .landing-section {
      .section-content {
        @include flex($direction: column);

        .showcase-image img, .showcase-image .async-image {
          transform: unset !important;
        }

        .content-header {
          max-width: unset;
          margin-top: 3.5rem;
          text-align: center !important;
        }

        .points {
          @include flex($direction: row, $justify: center);
          flex-wrap: wrap;

          > * {
            flex-basis: 200px;
            flex-grow: 1;
            flex-shrink: 1;
            min-width: 240px;
            max-width: 400px;
            margin: 1.5rem !important;
            align-self: stretch;

            h4 {
              justify-content: center !important;
            }

            h4, p {
              text-align: center;
            }
          }
        }
      }
    }
  }

</style>
