<template>
  <div class="page">

    <div class="spacer"></div>
    <h2>Payment Successful!</h2>
    <div class="spacer"></div>

  </div>
</template>

<script>
  export default {
    name: 'TestPaymentSuccess',
  }
</script>

<style scoped lang="scss">

  h2 {
    color: $colorA;
  }

</style>
