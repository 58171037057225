<template>
  <div class="page">
    <FeaturedWorlds />

    <div class="spacer h5"></div>
    <h1>User Worlds</h1>
    <div class="spacer h1"></div>
    <p class="hint">Only public worlds are visible.</p>
    <div class="spacer h3"></div>

    <Pagination
      :page="page"
      :limit="limit"
      :total="total"
      @update="fetchWorlds"
    />

    <div class="world-list">
      <template v-if="isLoading">
        <Loader />
        <div class="spacer h5"></div>
      </template>

      <template v-else>
        <WorldPreview
          v-for="world in worlds"
          class="world-list-item"
          :worldObject="world"
          :key="world._id"
					@click.native="$router.push('/world/' + world._id)"
        />
        <p v-if="!worlds.length">No worlds found.</p>
      </template>
    </div>

  </div>
</template>


<script>
  import FeaturedWorlds from './FeaturedWorlds.vue';
  import WorldPreview from '../World/WorldPreview.vue';

  export default {
    name: 'WorldBrowser',
    components: {
      FeaturedWorlds,
      WorldPreview,
    },

    data() {
      return {
        isLoading: true,

        page: 0,
        limit: 6
      }
    },

    computed: {
      worlds() {
        return this.$store.state.worldBrowser?.worlds || []
      },

      total() {
        return this.$store.state.worldBrowser?.total || 0
      }
    },

    methods: {
      fetchWorlds(params) {
        console.log(params);

        this.isLoading = true

        this.page = params?.page ?? 0

        this.$store.dispatch('fetchAllWorlds', {
          limit: this.limit,
          skip: params?.skip || 0
        }).then(response => {
          this.isLoading = false
          console.log(response);
          // console.log(response?.data?.worlds?.map(world => world.lastUpdated));
        })
      }
    },

    created() {
      this.fetchWorlds()
    }
  }
</script>


<style scoped lang="scss">

  .world-list {
    @include flex($justify: center, $align: flex-start);
    flex-wrap: wrap;
    width: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 2rem;

    .world-list-item {
      margin: 1rem;
    }
  }

</style>
