
export default [
  { icon: 'pin', displace: -14 },
  { icon: 'castle', displace: 0 },
  { icon: 'rook', displace: 0 },
  { icon: 'x', displace: 0 },
  { icon: 'swords', displace: 0 },
  { icon: 'boxes', displace: 0 },
  { icon: 'link', displace: 0 },
  { icon: 'solar system', displace: 0 },
  { icon: 'lock', displace: 0 },
  { icon: 'user', displace: 0 },
  { icon: 'wizard', displace: 0 },
  { icon: 'witch hat', displace: 0 },
  { icon: 'mountain', displace: 0 },
  { icon: 'magic', displace: 0 },
  { icon: 'stopwatch', displace: 0 },
  { icon: 'open book', displace: 0 },
  { icon: 'book', displace: 0 },
  { icon: 'books', displace: 0 },
  { icon: 'map', displace: 0 },
  { icon: 'bell', displace: 0 },
]
