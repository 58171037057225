<template>
  <nav>
    <router-link class="logo-container" to="/" exact>
      <img class="logo" :src="logo" />
    </router-link>


    <div :class="`buttons ${width < 800 ? 'mobile' : 'desktop'} ${mobileMenuOpen ? 'open' : ''}`"
      @click="mobileMenuOpen = false"
    >
      <template v-if="user">
        <!-- <router-link to="/inspiration">Inspiration</router-link> -->
        <router-link :class="'creation ' + theme()" to="/creation">Creation</router-link>
        <router-link :class="'community ' + theme()" to="/community">Community</router-link>
      </template>

      <template v-else>
        <router-link class="demo" to="/world/example">Demo</router-link>
        <router-link class="pricing" to="/pricing">Pricing</router-link>
      </template>

      <router-link class="help" to="/help/faq">FAQ</router-link>
      <router-link v-if="user" :class="theme()" to="/support">Feedback</router-link>

      <Button v-if="width < 800"
        label="Menu"
        :icon="mobileMenuOpen ? 'less-arrow' : 'more-arrow'"
        class="mobile-menu-button"
        @click.native.stop="mobileMenuOpen = !mobileMenuOpen"
      />
    </div>


    <TopNavigationUser />

    <TutorialHint :step="8" origin="bottom right">
      <p>When you're done exploring the world page, click here to go to your dashboard.</p>
    </TutorialHint>
  </nav>
</template>

<script>
  import TopNavigationUser from './TopNavigationUser.vue';

  export default {
    name: 'TopNavigation',
    components: {
      TopNavigationUser
    },

    data() {
      return {
        mobileMenuOpen: false,
        width: 900
      }
    },

    computed: {
      user() {
        return this.$store.state.user
      },

      logo() {
        return this.theme() === 'dark' ?
          'https://media.scrybe-app.com/assets/logo/logo_text_horizontal_dark.png' :
          'https://media.scrybe-app.com/assets/logo/logo_text_horizontal_light.png'
      }
    },

    methods: {
      onResize() {
        this.width = window.innerWidth;
      }
    },

    mounted() {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    },

    destroyed() {
      window.removeEventListener('resize', this.onResize)
    }
  }
</script>

<style scoped lang="scss">

  nav {
    position: relative;
    width: 100%;
    height: $navigation-height;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #111;
    z-index: 99;
  }

  .buttons {
    &.mobile {
      position: absolute;
      top: -240px;
      left: 0;
      @include flex($direction: column, $justify: center);
      width: 100vw;
      height: 240px;
      padding: 2rem;
      background-color: #111;
      transform: translateY(0);
      transition: top 0.5s;

      a {
        margin: 0.5rem 0;
      }

      &.open {
        top: 0;
      }
    }
  }

  .buttons a {
    padding: 0.5rem 1.5rem;
    border-radius: calc(1em + 0.25rem);
    color: neutral(90%);
    background-color: bg(15%);
    font-family: 'Quicksand', Avenir, Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    text-decoration: none;
    user-select: none;
    transition: color 0.15s, background 0.15s;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &:hover {
      background-color: bg(40%);
    }

    &.creation {
      color: $creation;
      &:hover {
        background-color: $creation-bg;
      }
    }

    &.community {
      color: $community;
      &:hover {
        background-color: $community-bg;
      }
    }

    &.demo {
      color: #f9b94d;
      &:hover {
        background-color: #d09a40;
      }
    }

    &.pricing {
      color: #40ddc0;
      &:hover {
        background-color: #28ac8d;
      }
    }

    &.help {
      color: $help;
      &:hover {
        background-color: darken($help, 5%);
      }
    }

    &:hover {
      color: neutral(100%);
    }
  }

  .mobile-menu-button {
    position: absolute;
    bottom: -3.75rem;
  }

  .logo-container {
    position: absolute;
    left: 0;
    @include flex($justify: center);
    padding: 0.5rem 1.5rem;
    transition: opacity 0.3s;
    z-index: 99;

    .logo {
      height: 48px;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .user {
    position: absolute;
    display: flex;
    align-items: center;
    right: 1.5rem;
  }

</style>
