<template>
  <div class="book-icon-container" :style="formatSize() + ' ' + formatStyle(size, rotation)">

    <svg v-if="name === 'plain big'"
      viewbox="0 0 40 120"
      width="100%" height="100%"
      :style="`transform: rotate(${rotation ? rotation : 0}deg);`"
      >
      <path :fill="colorA || '#333'" d="M 0 0   L 40 0   L 40 120   L 0 120   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 10   L 40 10   L 40 18   L 0 18   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 102   L 40 102   L 40 110   L 0 110   L 0 0" />
    </svg>

    <svg v-if="name === 'elegant wide'"
      viewbox="0 0 40 120"
      width="100%" height="100%"
      :style="`transform: rotate(${rotation ? rotation : 0}deg);`"
      >
      <path :fill="colorA || '#333'" d="M 0 0   L 40 0   L 40 120   L 0 120   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 5   L 40 5   L 40 15   L 0 15   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 20   L 40 20   L 40 23   L 0 23   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 40   L 40 40   L 40 50   L 0 50   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 97   L 40 97   L 40 100   L 0 100   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 105   L 40 105   L 40 115   L 0 115   L 0 0" />
      <circle :fill="colorB || '#888'" cx="20" cy="73" r="12" />
    </svg>

    <svg v-if="name === 'plain small'"
      viewbox="0 0 30 100"
      width="100%" height="100%"
      :style="`transform: rotate(${rotation ? rotation : 0}deg);`"
      >
      <path :fill="colorA || '#333'" d="M 0 0   L 30 0   L 30 100   L 0 100   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 10   L 30 10   L 30 18   L 0 18   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 82   L 30 82   L 30 90   L 0 90   L 0 0" />
    </svg>

    <svg v-if="name === 'deco small'"
      viewbox="0 0 30 100"
      width="100%" height="100%"
      :style="`transform: rotate(${rotation ? rotation : 0}deg);`"
      >
      <path :fill="colorA || '#333'" d="M 0 0   L 30 0   L 30 100   L 0 100   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 8   L 30 8   L 30 10   L 0 10   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 15   L 30 15   L 30 25   L 0 25   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 75   L 30 75   L 30 85   L 0 85   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 90   L 30 90   L 30 92   L 0 92   L 0 0" />
    </svg>

    <svg v-if="name === 'plain narrow'"
      viewbox="0 0 30 120"
      width="100%" height="100%"
      :style="`transform: rotate(${rotation ? rotation : 0}deg);`"
      >
      <path :fill="colorA || '#333'" d="M 0 0   L 30 0   L 30 120   L 0 120   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 10   L 30 10   L 30 15   L 0 15   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 105   L 30 105   L 30 110   L 0 110   L 0 0" />
    </svg>

    <svg v-if="name === 'deco narrow'"
      viewbox="0 0 30 125"
      width="100%" height="100%"
      :style="`transform: rotate(${rotation ? rotation : 0}deg);`"
      >
      <path :fill="colorA || '#333'" d="M 0 0   L 30 0   L 30 125   L 0 125   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 10   L 30 10   L 30 13   L 0 13   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 20   L 30 20   L 30 30   L 0 30   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 57   L 30 57   L 30 60   L 0 60   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 65   L 30 65   L 30 68   L 0 68   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 95   L 30 95   L 30 105   L 0 105   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 112   L 30 112   L 30 115   L 0 115   L 0 0" />
    </svg>

    <svg v-if="name === 'elegant narrow'"
      viewbox="0 0 30 120"
      width="100%" height="100%"
      :style="`transform: rotate(${rotation ? rotation : 0}deg);`"
      >
      <path :fill="colorA || '#333'" d="M 0 0   L 30 0   L 30 120   L 0 120   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 10   L 30 10   L 30 13   L 0 13   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 92   L 30 92   L 30 95   L 0 95   L 0 0" />
      <path :fill="colorB || '#888'" d="M 0 100   L 30 100   L 30 110   L 0 110   L 0 0" />
      <circle :fill="colorB || '#888'" cx="15" cy="40" r="8" />
    </svg>

    <svg v-if="name === 'space wide'"
      viewbox="0 0 40 120"
      width="100%" height="100%"
      :style="`transform: rotate(${rotation ? rotation : 0}deg);`"
      >
    </svg>

    <svg v-if="name === 'space narrow'"
      viewbox="0 0 20 120"
      width="100%" height="100%"
      :style="`transform: rotate(${rotation ? rotation : 0}deg);`"
      >
    </svg>

  </div>
</template>

<script>
  export default {
    name: '',
    props: {
      name: String,
      size: [Number],
      rotation: [Number, String],
      colorA: [String],
      colorB: [String],
    },
    methods: {
      formatSize() {
        let width = 40;
        let height = 120;

        switch(this.name) {
          case 'plain small':
            width = 30;
            height = 100;
            break;
          case 'deco small':
            width = 30;
            height = 100;
            break;
          case 'plain narrow':
            width = 30;
            break;
          case 'deco narrow':
            width = 30;
            height = 125;
            break;
          case 'elegant narrow':
            width = 30;
            break;
          case 'space narrow':
            width = 20;
            break;
        }

        // width *= this.size;
        // height *= this.size;

        return `width: ${width}px; height: ${height}px;`;
      },

      formatStyle(size, rotation) {
        if (!rotation) return '';

        const maxPadding = 60;
        let rotationInRadians = Math.abs(rotation) * (Math.PI / 180);
        let amount = maxPadding * Math.sin(rotationInRadians);

        return `padding-left: ${amount}px; padding-right: ${amount}px;`
      }
    }
  }
</script>

<style scoped lang="scss">

  .book-icon-container {
    // position: relative;
    display: inline-block;
    padding: 0.25rem;

    // svg {
    //   // position: absolute;
    //   width: 40px;
    //   height: 120px;
    // }
  }

</style>
