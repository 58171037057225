
import { api } from '@/utility.js'
import { loadStripe } from '@stripe/stripe-js'
import keys from '../keys/publicKeys'
import config from '../config/config'

let stripe;
const stripe_public_key = config?.mode == 'production' ?
  keys.STRIPE.LIVE.PUBLIC_KEY :
  keys.STRIPE.TEST.PUBLIC_KEY

loadStripe(stripe_public_key).then(s => {
  stripe = s;
})


export const paymentMutations = {

}


export const paymentActions = {

  async goToCheckout({ commit, state }, items) {
    let response = await api('POST', 'payment/checkout', { items });
    if (response.status != 200) {
      return response;
    }
    return await stripe.redirectToCheckout({ sessionId: response?.data?.id });
  },

  async fetchPrices({ commit, state }) {
    return await api('POST', 'payment/prices', {});
  },

  async previewProration({ commit, state }, params) {
    return await api('POST', 'subscription/update/preview', params);
  },

  async updateSubscription({ commit, state }, params) {
    return await api('POST', 'subscription/update', params);
  },

  async fetchInvoices({ commit, state }) {
    return await api('POST', 'payment/invoices', {});
  },

  async openCustomerPortal({ commit, state }) {
    return await api('POST', 'payment/portal', {});
  },



  // FOR TESTING

  async testStripe({ commit, state }, endpoint) {
    return await api('POST', 'payment/test/' + endpoint, { arbitraryCode: 987654321 });
  },

  async testGoToCheckout({ commit, state }, sessionID) {
    console.log('sessionID', sessionID);
    return await stripe.redirectToCheckout({ sessionId: sessionID });
  }

}
