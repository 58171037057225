
import config from './config/config';
import keys from './keys/publicKeys';
import cryptoJS from 'crypto-js';

import store from './store/_store'



export const publicImageURL = (category, filename, userID) => {
  if (!filename) return undefined

  if (filename.startsWith('http://') ||
    filename.startsWith('https://') ||
    filename.startsWith('data:image/')
  ) {
    return filename;
  }

  if (filename.startsWith('assets/')) {
    return require('@/' + filename)
  }

  if (category == 'avatar' && filename == 'default_avatar.png') {
    return `https://media.scrybe-app.com/avatar/default_avatar.png`
  }
  return `https://media.scrybe-app.com/${category}/${userID}/${filename}`


  let baseURL = window.location.origin;
  if (window.location.hostname === 'localhost' && window.location.port == '7777') {
    baseURL = 'http://localhost:8777';
  }

  if (category == 'runes') {
    return `${baseURL}/images/${category}/${userID}/${filename}`
  }

  return baseURL + '/images/' + category + '/' + filename;
}




const apiURL = () => {
  switch (config.mode) {
    case 'staging':
      return config.stagingServer;
    case 'production':
      return config.productionServer;
    default:
      return config.developmentServer;
  }
}


export const beacon = (type) => {
  if (window.location.hostname == 'localhost') {
    return;
  }

  let blob = new Blob([JSON.stringify({
    type,
    aId: window.localStorage.getItem('aId')
  })], {
    type: 'application/json; charset=UTF-8'
  })

  navigator.sendBeacon(apiURL() + '/api/v1/access/visibility', blob)
}


export const api = async (method, endpoint, params) => {
  const url = apiURL() + '/api/v1/' + endpoint;

  let fetchOptions = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token')
    }
  }
  if (method != 'GET') fetchOptions.body = JSON.stringify(params);

  let response = await fetch(url, fetchOptions);
  let data = await response.json();

  if (response.status !== 200 && response.status !== 201) {
    console.error(response.status, data.error);
  }
  return {
    status: response.status,
    data: data
  };
}


export const apiFile = async (method, endpoint, params) => {
  const url = apiURL() + '/api/v1/' + endpoint;

  let fetchOptions = {
    method: method,
    headers: {
      'Authorization': window.localStorage.getItem('token')
    },
    body: params
  }

  let response = await fetch(url, fetchOptions);
  let data = await response.json();

  if (response.status !== 200 && response.status !== 201) {
    console.error(response.status, response.error);
  }
  return {
    status: response.status,
    data: data
  };
}


export const apiRaw = async (method, endpoint, params) => {
  const url = apiURL() + '/api/v1/' + endpoint;

  let fetchOptions = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token')
    }
  }
  if (method != 'GET') fetchOptions.body = params;

  let response = await fetch(url, fetchOptions);

  if (response.status !== 200 && response.status !== 201) {
    console.error(response.status, response.error);
  }
  return {
    status: response.status,
    data: response
  };
}





export const getIP = async () => {
  try {
    const response = await fetch('https://ipapi.co/json/', { method: 'GET' })
    console.log('ip response', response);
    const data = await response.json()
    if (response.status != 200) {
      throw {
        status: response.status,
        data
      }
    }
    else return data
  }
  catch (error) {
    console.log('ip error', error);
    return { error }
  }
}





export const encrypt = (object) => {
  let result = cryptoJS.AES.encrypt(JSON.stringify(object), keys.encryptionKey);
  return {
    encrypted: result.toString()
  };
}





export const theme = (string) => {
  return string ? string + ' ' + store.state.theme : store.state.theme;
}





export const hasPermission = (element) => {
  if (!store?.state?.user?.permissions?.[element]) return false;
  let expirationDate = new Date(store?.state?.user?.permissions[element]);
  return expirationDate.getTime() > Date.now();
}






const getDateDelta = (date) => {
  // in ms
  const SECONDS = 1000;
  const MINUTES = SECONDS * 60;
  const HOURS = MINUTES * 60;
  const DAYS = HOURS * 24;
  const WEEKS = DAYS * 7;
  const MONTHS = DAYS * 30;
  const YEARS = DAYS * 365;

  let date_ms = new Date(date).getTime();
  let now_ms = Date.now();

  let ms_delta = now_ms - date_ms;

  let years = ~~(ms_delta / YEARS);
  ms_delta -= years * YEARS;
  let months = ~~(ms_delta / MONTHS);
  ms_delta -= months * MONTHS;
  let weeks = ~~(ms_delta / WEEKS);
  ms_delta -= weeks * WEEKS;
  let days = ~~(ms_delta / DAYS);
  ms_delta -= days * DAYS;
  let hours = ~~(ms_delta / HOURS);
  ms_delta -= hours * HOURS;
  let minutes = ~~(ms_delta / MINUTES);
  ms_delta -= minutes * MINUTES;
  let seconds = ~~(ms_delta / SECONDS);

  // console.log("years:", years);
  // console.log("months:", months);
  // console.log("weeks:", weeks);
  // console.log("days:", days);
  // console.log("hours:", hours);
  // console.log("minutes:", minutes);
  // console.log("seconds:", seconds);

  return {
    years,
    months,
    weeks,
    days,
    hours,
    minutes,
    seconds
  }
}


export const formatDateDelta = (date, full) => {
  let delta = getDateDelta(date);

  if (full) {
    let string = ''
    if (delta.years) string += `${Math.abs(delta.years)}y`;
    if (delta.months) string += ` ${Math.abs(delta.months)}mo`;
    if (delta.weeks) string += ` ${Math.abs(delta.weeks)}w`;
    if (delta.days) string += ` ${Math.abs(delta.days)}d`;
    if (delta.hours) string += ` ${Math.abs(delta.hours)}h`;
    if (delta.minutes) string += ` ${Math.abs(delta.minutes)}m`;
    if (delta.seconds) string += ` ${Math.abs(delta.seconds)}s`;
    return string;
  }

  if (delta.years) return `${delta.years}y`;
  if (delta.months) return `${delta.months}mo`;
  if (delta.weeks) return `${delta.weeks}w`;
  if (delta.days) return `${delta.days}d`;
  if (delta.hours) return `${delta.hours}h`;
  if (delta.minutes) return `${delta.minutes}m`;
  if (delta.seconds) return `${delta.seconds}s`;
  return '';
}


export const formatDate = (d, format) => {
  const date = new Date(d);

  let formattedDate = format;
  if (!format) formattedDate = '&weekday, &month &dd, &year | &hour12:&min &ap';

  let ampm = date.getHours() >= 12 ? 'PM' : 'AM';
  let hour12 = date.getHours() % 12;
  if (hour12 == 0) hour12 = 12;

  formattedDate = formattedDate.replaceAll('&year', date.getFullYear());
  formattedDate = formattedDate.replaceAll('&month', date.toLocaleString('en-US', { month: 'long'}));
  formattedDate = formattedDate.replaceAll('&mon', date.toLocaleString('en-US', { month: 'short'}));
  formattedDate = formattedDate.replaceAll('&mm', `0${date.getMonth() + 1}`.slice(-2));
  formattedDate = formattedDate.replaceAll('&dd', `0${date.getDate()}`.slice(-2));
  formattedDate = formattedDate.replaceAll('&weekday', date.toLocaleString('en-US', { weekday: 'long'}));
  formattedDate = formattedDate.replaceAll('&wkd', date.toLocaleString('en-US', { weekday: 'short'}));
  formattedDate = formattedDate.replaceAll('&w', date.toLocaleString('en-US', { weekday: 'narrow'}));
  formattedDate = formattedDate.replaceAll('&hour12', hour12);
  formattedDate = formattedDate.replaceAll('&hour', date.getHours());
  formattedDate = formattedDate.replaceAll('&ap', ampm);
  formattedDate = formattedDate.replaceAll('&min', `0${date.getMinutes()}`.slice(-2));
  formattedDate = formattedDate.replaceAll('&sec', `0${date.getSeconds()}`.slice(-2));
  formattedDate = formattedDate.replaceAll('&ms', `00${date.getMilliseconds()}`.slice(-3));
  return formattedDate;
}




export const validateEmail = (email) => {
  let atSignBreak = email.split('@');
  if (atSignBreak.length !== 2) {
    return false;
  }
  if (!atSignBreak[1].includes('.')) {
    return false;
  }
  if (atSignBreak[1][0] === '.' ||
      atSignBreak[1][atSignBreak[1].length - 1] === '.') {
    return false
  }
  return true;
}


export const validatePassword = (password) => {
  if (!password.trim()) return false;
  // Uppercase letter
  if (!password.match(/[A-Z]/)) {
    return false;
  }
  // Lowercase letter
  if (!password.match(/[a-z]/)) {
    return false;
  }
  // Number
  if (!password.match(/[0-9]/)) {
    return false;
  }
  return true;
}



export const pause = (ms) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('done');
    }, ms);
  });
}
