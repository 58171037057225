<template>
  <div class="writings-menu floating">

    <div class="writings-bg"></div>

    <div class="writings-header">
      <h4>Writings</h4>
    </div>


    <div class="writings-body">

      <div class="toolbar-row">
        <div class="center row spaced">
          <Button
            label="New Writing"
            icon="new file"
            class="primary"
            @click.native="newWritingModal = true; isFolder = false;"
          />
          <Button
            label="New Folder"
            icon="new folder"
            class="primary"
            @click.native="newWritingModal = true; isFolder = true;"
          />
        </div>
        <div class="right">
          <Button v-if="writings.length"
            label="Edit"
            icon="edit"
            :class="editing ? 'primary' : 'dark'"
            style="margin-left: auto;"
            @click.native="editing = !editing"
          />
        </div>
      </div>

      <div class="spacer"></div>

      <Alert :alert="alert" @dismiss="alert = null" />

      <p v-if="!writings.length">You have not created any writings.</p>


      <template v-for="item in writings">

        <Folder v-if="item.isFolder"
          :folder="item"
          :editing="editing"
          :renamingWriting="renamingWriting"
          @click-rename="(writing) => renamingWriting = writing"
          @rename-input="(input) => renamingWriting.title = input"
          @rename="renameWriting"
          @cancel-rename="renamingWriting = null"
          @click-delete="(writing) => deletingWriting = writing"
          @create-writing="(params) => createSubWriting(params)"
        />

        <div v-else
          :class="`item ${item.visibility == 'public' ? 'public-element' : 'private-element'}`"
          @click="clickItem(item._id)"
        >
          <div class="info">
            <input v-if="renamingWriting && renamingWriting._id == item._id"
              type="text"
              :value="item.title"
              @input="(e) => renamingWriting.title = e.target.value"
            />
            <p v-else>{{ item.title }}</p>
            <h6>{{ formatDate(item.lastUpdated, '&mon &dd, &year') }}</h6>
          </div>

          <template v-if="!editing || renamingWriting">
            <Icon v-if="item.visibility == 'public'"
              name="eye"
              class="public-icon"
              style="margin-left: auto;"
            />
            <Icon v-else
              name="lock"
              class="private-icon"
              style="margin-left: auto;"
            />
          </template>

          <template v-if="editing">
            <template v-if="renamingWriting && renamingWriting._id == item._id">
              <Button
                icon="check"
                class="primary"
                style="margin-left: 1rem;"
                @click.native="renameWriting"
              />
              <Button
                icon="x"
                class="delete"
                style="margin-left: 0.5rem;"
                @click.native="renamingWriting = null"
              />
            </template>
            <template v-else>
              <Select
                :options="Object.values(ui.visibility)"
                :value="item.visibility ? item.visibility : 'private'"
                @select="(value) => visibilityChanged(value, item)"
                style="min-width: 135px; margin-left: auto;"
                strict
              />
              <Button
                icon="edit"
                style="margin-left: 1rem;"
                @click.native="renamingWriting = item"
              />
              <Button
                icon="delete"
                class="delete"
                style="margin-left: 0.5rem;"
                @click.native="deletingWriting = item"
              />
            </template>
          </template>
        </div>

      </template>

    </div>



    <ModalContainer v-if="newWritingModal">
      <h4>New {{ isFolder ? 'Folder' : 'Writing' }}</h4>
      <div class="spacer"></div>

      <input type="text"
        placeholder="Name"
        @change="(e) => title = e.target.value"
      />

      <Alert :alert="modalAlert" @dismiss="modalAlert = null" />

      <div class="spacer"></div>

      <div class="row spaced">
        <Button
          label="Cancel"
          @click.native="newWritingModal = false; isFolder = false;"
        />
        <Button
          label="Create"
          class="primary"
          @click.native="createWriting"
        />
      </div>
    </ModalContainer>


    <ModalContainer v-if="deletingWriting">
      <h4>Delete "{{ deletingWriting.title }}"?</h4>
      <Message v-if="deletingWriting.isFolder" type="danger" :text="`All writings in this folder will be deleted.\nThis cannot be undone.`" />
      <Message v-else type="warning" text="All data in this writing will be lost." />
      <div class="row spaced">
        <Button
          label="Yes"
          class="primary"
          @click.native="deleteWriting"
        />
        <Button
          label="No"
          class="delete"
          @click.native="deletingWriting = null"
        />
      </div>
    </ModalContainer>


    <ModalContainer v-if="changingVisibilityElement">
      <h4>Make "{{ changingVisibilityElement.title }}" {{ newVisibility }}?</h4>

      <div class="spacer h1"></div>
      <p v-if="newVisibility == 'private'" class="hint">Only you will be able to see this element.</p>
      <Message
        v-if="newVisibility == 'public'"
        type="warning"
        text="By making this element public, you agree to the terms of service and privacy policy."
      />
      <div class="spacer h1"></div>

      <div class="row spaced">
        <Button
          label="Yes"
          class="primary"
          @click.native="updateElementVisibility"
        />
        <Button
          label="No"
          class="delete"
          @click.native="changingVisibilityElement = null"
        />
      </div>
    </ModalContainer>

  </div>
</template>

<script>
  import Folder from './Folder.vue';

  export default {
    name: 'WritingsMenu',
    components: {
      Folder
    },

    computed: {
      writings() {
        return this.$store.state.world?.writings ?
          Object.values(this.$store.state.world?.writings) : []
      }
    },

    data() {
      return {
        newWritingModal: false,
        renamingWriting: null,
        deletingWriting: null,
        alert: null,

        modalAlert: null,

        changingVisibilityElement: null,
        newVisibility: 'private',

        title: null,
        parent: null,
        isFolder: false,

        editing: false
      }
    },

    methods: {
      clickItem(id) {
        if (!this.editing) this.$router.push('/writing/' + id)
      },

      createSubWriting(params) {
        this.parent = params.parentID;
        this.isFolder = params.isFolder;
        this.newWritingModal = true;
      },

      async createWriting() {
        if (!this.title?.trim()) {
          this.modalAlert = {
            type: 'warning',
            message: 'Please enter a name.'
          }
          return;
        }

        const newWriting = {
          isFolder: this.isFolder,
          title: this.title,
          worldID: this.$store.state.user?.currentWorldID,
          parentID: this.parent,
          ...(!this.isFolder && {
            scenes: [
              {
                name: 'Default',
                content: [],
                overlayColor: '#414145',
                overlayOpacity: 0.5,
                backgroundImage: 'https://media.scrybe-app.com/assets/mountains_01.jpg',
                youtubeHTML: null,
              }
            ]
          })
        }

        const response = await this.$store.dispatch('newWriting', newWriting)

        if (response?.status == 201) {
          this.$store.dispatch('fetchCurrentUserWorld')
          this.newWritingModal = false
          this.parent = null

          if (!newWriting.isFolder && response?.data?.id) {
            this.$router.push('/writing/' + response.data.id)
          }
        }
      },

      renameWriting() {
        if (!this.renamingWriting) return;
        if (!this.renamingWriting.title?.trim()) {
          this.alert = {
            type: 'error',
            message: 'Please enter a name.'
          }
          return;
        }

        let params = {
          writingID: this.renamingWriting._id,
          worldID: this.$store.state.user?.currentWorldID,
          title: this.renamingWriting.title
        }

        this.$store.dispatch('renameWriting', params).then(response => {
          if (response.status == 200) {
            this.$store.dispatch('fetchCurrentUserWorld')
          }
          else {
            this.alert = {
              type: 'error',
              message: response.data.error
            }
          }
          this.renamingWriting = null;
        })
      },

      calcFolderChildren(folder, object) {
        const children = folder?.children
        if (!children || !object) return []

        for (let childID in children) {
          object[childID] = true
          if (children[childID]?.children) {
            this.calcFolderChildren(children[childID], object)
          }
        }
      },

      deleteWriting() {
        if (!this.deletingWriting) return;

        let children = {}
        this.calcFolderChildren(this.deletingWriting, children)

        let params = {
          writingID: this.deletingWriting._id,
          worldID: this.$store.state.user?.currentWorldID,
          isFolder: this.deletingWriting.isFolder,
          childrenIDs: Object.keys(children)
        }

        this.$store.dispatch('deleteWriting', params).then(response => {
          if (response.status == 200) {
            this.$store.dispatch('fetchCurrentUserWorld')
            this.alert = {
              type: 'success',
              message: 'Writing deleted successfully.'
            }
          }
          else {
            this.alert = {
              type: 'error',
              message: response.data.error
            }
          }
          this.deletingWriting = null;
        })
      },

      visibilityChanged(value, element) {
        this.newVisibility = value;
        this.changingVisibilityElement = element;
      },

      updateElementVisibility() {
        this.$store.dispatch('updateElementVisibility', {
          element: 'writing',
          elementID: this.changingVisibilityElement._id,
          worldID: this.$store.state.world?._id,
          visibility: this.newVisibility
        }).then(response => {
          if (response.status == 200) {
            this.alert = { type: 'success', message: `Made "${this.changingVisibilityElement.title}" ${this.newVisibility}.` }
            this.$store.dispatch('fetchCurrentUserWorld')
          }
          else {
            this.alert = { type: 'error', message: response?.data?.error }
          }
          this.changingVisibilityElement = null;
          this.newVisibility = 'private';
        })
      }
    }
  }
</script>

<style scoped lang="scss">

  .writings-menu {
    position: relative;
    @include flex($direction: column);
  }

  .writings-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 0.5rem;
    overflow: hidden;
    background-image: url('https://media.scrybe-app.com/assets/elements/writings_card.png');
    background-size: cover;
    background-position: bottom center;
  }

  .writings-header {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: rgba(255,255,255,0.15);
    z-index: 2;

    h4 {
      font-size: 0.75rem;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .writings-body {
    @include flex($direction: column);
    width: 100%;
    height: 100%;
    padding: 1rem;
    z-index: 2;
  }

  .new-button {
    display: inline-flex !important;
  }

  .item {
    @include flex($justify: space-between);
    width: 100%;
    margin: 0.25rem 0;
    padding: 1rem;
    border-radius: 0.25rem;
    background-color: bg(25%);

    .info {
      @include flex($direction: column, $align: flex-start);
    }

    h6 {
      color: $colorB;
    }

    &:hover {
      cursor: pointer;
      background-color: bg(30%);

      p {
        color: $colorA;
      }
    }
  }

  .icon-button {
    padding: 0.75rem;
    border-radius: 0.25rem;
    color: #888;

    &:hover {
      color: $colorA;
      background-color: #000;
    }
  }

</style>
