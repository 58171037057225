<template>
  <div class="language-writing">

    <h4>Writing</h4>

    <div class="spacer"></div>


    <div id="writing-output"
      :class="isOutputFocused ? 'focused' : ''"
    >
      <template v-if="!output.length && !queue">
        <div class="tip">
          <p v-if="!isOutputFocused" class="warning hint">Click to begin typing.</p>
          <p v-else class="success hint">Type to input runes.</p>
        </div>
      </template>
      <div v-if="queue" class="tip top">
        <p class="success hint">{{ matches.join(', ') }} | Type "{{ queue.substring(queue.length - 1, queue.length) }}" again to input "{{ queue }}".</p>
      </div>
      <div v-for="character in output" class="character">
        <div v-if="character == ' '" class="space"></div>
        <img v-else
          :class="inverted ? 'inverted' : ''"
          :src="publicImageURL('rune', keyMap[character].rune, userID)"
        />
      </div>
      <div v-if="queue" class="character">
        <p class="queue">{{ queue }}</p>
      </div>
    </div>

    <div class="spacer"></div>

    <!-- <div class="writing-input">
      <input type="text" @focus="readyInput" @blur="removeInput" />
    </div> -->

  </div>
</template>

<script>
  export default {
    name: 'LanguageWriting',

    props: {
      keyMapUpdate: Boolean,
      inverted: [Boolean]
    },

    watch: {
      keyMapUpdate: function(newValue) {
        this.clearOutput()
      }
    },

    data() {
      return {
        queue: '',
        matches: [],
        output: [],
        isOutputFocused: false,
      }
    },

    computed: {
      language() {
        return this.$store.state.languages?.[this.$route.params.id] || {}
      },

      keyMap() {
        return this.language.keyMap || {}
      },

      userID() {
        return this.$store.state.user?._id
      }
    },

    methods: {
      onMouseDown() {
        if (!event.path?.length) return;

        let isOutput = false;
        for (let element of event.path) {
          isOutput = element.id == 'writing-output'
          if (isOutput) break;
        }

        if (isOutput) {
          this.isOutputFocused = true;
          this.readyInput()
        }
        else {
          this.isOutputFocused = false;
          this.removeInput()
        }
      },

      readyInput() {
        window.addEventListener('keydown', this.onKeyDown);
      },
      removeInput() {
        window.removeEventListener('keydown', this.onKeyDown);
      },

      onKeyDown() {
        event.preventDefault();

        let key = event.key;
        let code = event.code;
        // console.log('key', key, 'code', code);

        if (code == 'Backspace') {
          if (this.queue) {
            this.queue = this.queue.substring(0, this.queue.length - 1);
            return;
          }
          else {
            if (this.output.length > 0) this.output.pop()
            return;
          }
        }

        if (code == 'Space') {
          this.output.push(' ')
          return;
        }


        if (!this.queue) {
          let matches = Object.keys(this.keyMap).filter(k => k.startsWith(key));
          console.log('matches', matches);
          if (matches.length > 1) {
            this.queue = key;
            this.matches = matches;
          }
          else {
            if (this.keyMap[key]) {
              this.output.push(key)
            }
          }
        }
        else {
          let matches = Object.keys(this.keyMap).filter(k => k.startsWith(this.queue + key));
          console.log('matches', matches);
          if (matches.length > 1) {
            this.queue += key;
            this.matches = matches;
          }
          else if (matches.length == 1) {
            this.output.push(this.queue + key)
            this.queue = '';
            this.matches = [];
          }
          else {
            this.output.push(this.queue)
            this.queue = '';
            this.matches = [];
          }
        }

      },

      clearOutput() {
        this.output = []
      }
    },

    mounted() {
      window.addEventListener('mousedown', this.onMouseDown)
    },

    destroyed() {
      window.removeEventListener('mousedown', this.onMouseDown)
    }
  }
</script>

<style scoped lang="scss">

  .language-writing {
    @include flex($direction: column);
    width: 100%;
  }

  #writing-output {
    position: relative;
    @include flex($justify: center);
    flex-wrap: wrap;
    max-width: 800px;
    min-width: 400px;
    margin: 1rem;
    padding: 3rem;
    border-radius: 1rem;
    border: 1px solid $alert-warning-color;
    overflow: hidden;
    user-select: none;

    &.focused {
      border: 1px solid $alert-success-color;
    }

    &:hover {
      cursor: text;
    }
  }

  .tip {
    @include flex();
    height: 40px;
    text-align: center;

    &.top {
      position: absolute;
      top: 0.25rem;
    }
  }

  .character {
    @include flex($align: center);
    height: 40px;

    .space {
      width: 20px;
    }

    img {
      height: 100%;
    }

    .queue {
      padding: 0.25rem;
      color: $colorA;
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  .writing-input {
    width: 300px;
  }

</style>
