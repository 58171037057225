
export default [
  {
    top: 80,
    scrollFactor: 0.15,
    size: 0.8,
    opacity: 0.75,
    left: [
      { name: 'plain big', rotation: 0 },
      { name: 'space narrow', rotation: 0 },
      { name: 'plain small', rotation: 0 },
      { name: 'deco small', rotation: -10 },
    ],
    right: [
      { name: 'elegant narrow', rotation: 10 },
      { name: 'deco small', rotation: 0 },
      { name: 'plain big', rotation: 0 },
      { name: 'elegant wide', rotation: 0 },
    ],
  },
  {
    top: 160,
    scrollFactor: 0.3,
    size: 0.5,
    opacity: 0.5,
    left: [
      { name: 'elegant narrow', rotation: 0 },
      { name: 'plain small', rotation: 0 },
    ],
    right: [
      { name: 'plain big', rotation: 0 },
      { name: 'plain small', rotation: 10 },
      { name: 'deco narrow', rotation: 0 },
    ],
  },
  {
    top: 360,
    scrollFactor: 0.1,
    size: 0.9,
    opacity: 1,
    left: [
      { name: 'plain narrow', rotation: 0 },
      { name: 'elegant wide', rotation: 0 },
      { name: 'plain small', rotation: -10 },
    ],
    right: [
      { name: 'deco small', rotation: 0 },
      { name: 'elegant narrow', rotation: 0 },
      { name: 'space narrow', rotation: 0 },
      { name: 'plain small', rotation: 0 },
      { name: 'plain big', rotation: 0 },
      { name: 'plain big', rotation: 0 },
    ],
  },
]
