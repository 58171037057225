import { render, staticRenderFns } from "./LoginHistory.vue?vue&type=template&id=48a83337&scoped=true"
import script from "./LoginHistory.vue?vue&type=script&lang=js"
export * from "./LoginHistory.vue?vue&type=script&lang=js"
import style0 from "./LoginHistory.vue?vue&type=style&index=0&id=48a83337&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a83337",
  null
  
)

export default component.exports