
import { api, apiFile, getIP } from '@/utility.js'

// DELAY FOR TESTING
const delay = async (time) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
        resolve('delay done.');
      }, time);
  });
}


export const appMutations = {

  startLoading(state) {
    state.loading = true;
  },

  doneLoading(state) {
    setTimeout(() => {
      state.loading = false;
    }, 250);
  },

  changeScrollLock(state, scrollLocked) {
    state.scrollLocked = scrollLocked;
  },

  dismissFeedbackAlert(state) {
    state.feedbackAlertVisible = false;
  }

}


export const appActions = {

  // SUPPORT

  async submitReport({ commit, state }, report) {
    return await apiFile('POST', 'support/newReport', report);
  },


  // ACCESS

  async access({ commit, state }) {
    if (window.location.hostname == 'localhost') {
      return;
    }

    const aId = window.localStorage.getItem('aId')

    if (!aId) {
      const location = await getIP()
      console.log('access location', location);
      const response = await api('POST', 'access', {
        aId,
        location,
        url: window?.location?.href,
        orientation: window?.screen?.orientation?.type,
        language: window?.navigator?.language,
        screenWidth: window?.screen?.width,
        screenHeight: window?.screen?.height,
        windowWidth: window?.innerWidth,
        windowHeight: window?.innerHeight,
        referrer: document?.referrer
      })

      if (response?.data?.aId) {
        window.localStorage.setItem('aId', response?.data?.aId)
      }
    }
    else {
      api('POST', 'access', {
        aId,
        url: window?.location?.href
      })
    }
  },


  async accessClose({ commit, state }) {
    if (window.location.hostname == 'localhost') {
      return;
    }

    await api('POST', 'access', {
      aId: window.localStorage.getItem('aId'),
      url: window?.location?.href,
      c: true
    })
  },



  async search({ commit, state }, text) {
    return await api('POST', 'search', { text })
  },


  // TEST

  async test({ commit, state }, endpoint) {
    return await api('POST', 'test/' + (endpoint || ''), { arbitraryCode: 456321789 });
    // const ip = await getIP();
    // console.log(ip);
  }

}
