<template>
  <div class="fireflies">
    <!-- <svg width="1000" height="1000">
      <path d="M 0 0   C 0 0, 250 20, 500 500   C 500 500, 750 980, 1000 1000" stroke="white" fill="transparent" />
    </svg> -->
    <!-- <svg width="1000" height="1000">
      <path d="M 500 1000   C 500 1000, 1000 750, 500 500   C 500 500, 0 250, 500 0" stroke="white" fill="transparent" />
    </svg> -->
    <!-- <svg width="1000" height="1000">
      <path d="M 500 1000   C 500 1000, 400 750, 500 500   C 500 500, 600 250, 500 0" stroke="white" fill="transparent" />
    </svg> -->

    <!-- <div class="firefly offset1 duration-10 delay-0"></div>
    <div class="firefly spiral-right duration-8 delay-2"></div>
    <div class="firefly spiral-slight-left duration-7 delay-5"></div> -->

    <!-- <div class="firefly offset1 duration-7 delay-6"></div>
    <div class="firefly spiral-slight-left duration-10 delay-5"></div>
    <div class="firefly spiral-right duration-12 delay-6"></div> -->

    <!-- <div class="firefly spiral-right duration-9 delay-5"></div>
    <div class="firefly spiral-right duration-12 delay-0"></div>
    <div class="firefly spiral-slight-right duration-11 delay-10"></div>
    <div class="firefly spiral-slight-right duration-10 delay-7"></div>
    <div class="firefly spiral-left duration-9 delay-6"></div>
    <div class="firefly spiral-left duration-10 delay-10"></div>
    <div class="firefly spiral-slight-left duration-12 delay-3"></div>
    <div class="firefly spiral-slight-left duration-11 delay-2"></div> -->

    <div class="firefly n1"></div>
    <div class="firefly n2" style="top: -120px; left: -150px;"></div>
    <div class="firefly n3" style="top: 360px; left: -210px;"></div>
    <div class="firefly n4" style="top: -240px; left: 180px;"></div>
    <div class="firefly n5" style="top: 270px; left: 150px;"></div>
    <div class="firefly n6" style="top: 30px; left: -300px;"></div>
    <div class="firefly n7" style="top: -20px; left: 290px;"></div>

  </div>
</template>


<script>
  export default {
    name: 'Fireflies'
  }
</script>


<style scoped lang="scss">



  .fireflies {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    // background-color: rgba(255,255,255,.05);
    transform: translate(-50%, -50%);
    z-index: 2;
    pointer-events: none;
    user-select: none;
  }

  .firefly {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #a7c9fc;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    // animation-name: movePath;
    // animation-name: hoverAnimation, flickerAnimation;
    // animation-duration: 10s;
    animation-timing-function: ease-in-out;
    opacity: 1;

    &::after {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-color: #5a7ffb;
      filter: blur(8px);
      mix-blend-mode: color-dodge;
    }

    &.n1 {
      animation: hoverAnimation 14s ease-in-out 0s infinite,
        flickerAnimation 4s ease-in-out 0s infinite;
    }
    &.n2 {
      animation: hoverAnimation 10s ease-in-out -4s infinite,
        flickerAnimation 7s ease-in-out 0s infinite;
    }
    &.n3 {
      animation: hoverAnimation 12s ease-in-out -3s infinite,
        flickerAnimation 6s ease-in-out 0s infinite;
    }
    &.n4 {
      animation: hoverAnimation 15s ease-in-out -1s infinite,
        flickerAnimation 5s ease-in-out 0s infinite;
    }
    &.n5 {
      animation: hoverAnimation 9s ease-in-out -5s infinite,
        flickerAnimation 9s ease-in-out 0s infinite;
    }
    &.n6 {
      animation: hoverAnimation 11s ease-in-out -3s infinite,
        flickerAnimation 5s ease-in-out 0s infinite;
    }
    &.n7 {
      animation: hoverAnimation 13s ease-in-out -4s infinite,
        flickerAnimation 7s ease-in-out 0s infinite;
    }


    &.offset1 {
      offset-path: path('M0,0  C0,0 250,20 500,500  C 500,500 750,980 1000,1000');
    }
    &.spiral-right {
      offset-path: path('M500,1000  C500,1000 1000,750 500,500  C500,500 0,250 500,0');
    }
    &.spiral-left {
      offset-path: path('M500,1000  C500,1000 0,750 500,500  C500,500 1000,250 500,0');
    }
    &.spiral-slight-right {
      offset-path: path('M500,1000  C500,1000 600,750 500,500  C500,500 400,250 500,0');
    }
    &.spiral-slight-left {
      offset-path: path('M500,1000  C500,1000 400,750 500,500  C500,500 600,250 500,0');
    }

    &.duration-15 {
      animation-duration: 15s;
    }
    &.duration-14 {
      animation-duration: 14s;
    }
    &.duration-12 {
      animation-duration: 12s;
    }
    &.duration-11 {
      animation-duration: 11s;
    }
    &.duration-10 {
      animation-duration: 10s;
    }
    &.duration-9 {
      animation-duration: 9s;
    }

    &.delay-0 {
      animation-delay: 0s;
    }
    &.delay-1 {
      animation-delay: 1s;
    }
    &.delay-2 {
      animation-delay: 2s;
    }
    &.delay-3 {
      animation-delay: 3s;
    }
    &.delay-5 {
      animation-delay: 5s;
    }
    &.delay-6 {
      animation-delay: 6s;
    }
    &.delay-7 {
      animation-delay: 7s;
    }
    &.delay-8 {
      animation-delay: 8s;
    }
    &.delay-10 {
      animation-delay: 10s;
    }
  }

  @keyframes movePath {
    0% {
      offset-distance: 0%;
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      offset-distance: 100%;
      opacity: 0;
    }
  }

  @keyframes hoverAnimation {
    0% {
      transform: translate(0, 0);
    }
    20% {
      transform: translate(50px, 10px);
    }
    40% {
      transform: translate(35px, 50px);
    }
    60% {
      transform: translate(-30px, -25px);
    }
    80% {
      transform: translate(15px, -50px);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 0.4;
    }
    45% {
      opacity: 0.8;
    }
    50% {
      opacity: 0.4;
    }
    70% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }

</style>
