<template>
  <div class="collection-container">
    <div class="collection">

      <div class="header row">
        <Icon :name="icon" style="margin-right: 0.5rem;" />
        <h4>{{ header }}</h4>
      </div>

      <div class="list">
        <slot></slot>
      </div>

      <div class="close-button-container">
        <Button
          label="Close"
          class="inline"
          @click.native="$emit('close')" />
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'SelectCreationElement',
    components: {
    },
    props: {
      header: String,
      icon: String
    }
  }
</script>

<style scoped lang="scss">

  .collection-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.25);
    z-index: 9;
    backdrop-filter: blur(4px);
  }

  .collection {
    position: fixed;
    @include flex($direction: column, $align: center);
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 600px;
    min-height: 300px;
    max-height: 60vh;
    margin: 1rem;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: bg(15%);
    z-index: 9;

    .list {
      width: 100%;
      overflow: auto;
      background-color: bg(18%);
    }

    .header {
      width: 100%;
      padding: 1rem;
      border-radius: 8px 8px 0 0;
      background-color: #253e36;
      text-align: center;
      font-size: 0.75rem;
      text-transform: uppercase;
      box-shadow: 0px 4px 20px -16px rgba(0,0,0,.5);
      user-select: none;

      h4, .icon {
        color: $colorA;
      }
    }

    .close-button-container {
      margin-top: auto;
      padding: 0.5rem;
    }
  }

</style>
