<template>
  <div class="page">

    <div class="spacer h5"></div>

    <h1 class="colorful-title">Page Not Found</h1>

    <div class="spacer h5"></div>

    <p class="hint">Error Code 404</p>
    <div class="spacer h1"></div>
    <p>Oops! Looks like this page doesn't exist.</p>

  </div>
</template>


<script>
  export default {
    name: 'PageNotFound',
  }
</script>


<style scoped lang="scss">

  h1.colorful-title {
    font-size: 2.5rem;
  }

</style>
