<template>
  <div class="introduction">

    <div class="spacer h5"></div>
    <img class="big-logo" src="https://media.scrybe-app.com/assets/logo/logo_only_dark.png" />

    <h1>Welcome to <span class="glow">Scrybe</span></h1>

    <div class="spacer h5"></div>

    <TypewriterText
      text="Our goal is to allow you to create your world effortlessly and with immersion."
      :delay="1000"
      :stagger="15"
      :on="!hasPreviouslyVisited"
    />
    <div class="spacer"></div>
    <TypewriterText
      text="What can you do with Scrybe?"
      :delay="3000"
      :stagger="20"
      :on="!hasPreviouslyVisited"
    />
    <div class="spacer"></div>
    <CoolButton
      icon="globe"
      label="Demo World"
      textColor="#ffe782"
      bgColor="rgb(252,206,115)"
      type="compact"
      animation="infinite"
      :class="!hasPreviouslyVisited ? 'fade-in-1' : ''"
      @click.native="$router.push('/world/example')"
    />

    <div class="spacer h5"></div>

    <TypewriterText
      text="Dive on in when you're ready..."
      :delay="5000"
      :stagger="20"
      :on="!hasPreviouslyVisited"
    />
    <div class="spacer"></div>
    <CoolButton
      icon="right-arrow"
      label="Start Creating"
      textColor="#a3ffd0"
      bgColor="rgb(36,255,189)"
      type="compact"
      animation="infinite"
      :class="!hasPreviouslyVisited ? 'fade-in-2' : ''"
      @click.native="$router.push('/start-creating')"
    />

  </div>
</template>

<script>
  export default {
    name: 'Introduction',

    data() {
      return {
        hasPreviouslyVisited: false
      }
    },

    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (from.name == 'World') vm.hasPreviouslyVisited = true
      })
    },
  }
</script>

<style scoped lang="scss">

  .introduction {
    @include flex($direction: column);
    padding: 2rem;
  }

  .glow {
    filter: drop-shadow(0 0 8px rgba(255,255,255,.75));
  }

  .big-logo {
    width: 200px;
  }

  .fade-in-1 {
    opacity: 0;
    animation: fadeIn 1.5s linear 4s forwards;
  }

  .fade-in-2 {
    opacity: 0;
    animation: fadeIn 1.5s linear 6s forwards;
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

</style>
