
export default {




  Age: '',
  Year: '',
  Month: '',
  Day: '',
  Time: '',


  calendars: [
    {
      name: 'Human Calendar',

      // calendar
      months: [
        { name: 'January', numDays: 31 },
        { name: 'February', numDays: 28 },
        { name: 'March', numDays: 31 },
        { name: 'April', numDays: 30 },
        { name: 'May', numDays: 31 },
        { name: 'June', numDays: 30 },
        { name: 'July', numDays: 31 },
        { name: 'August', numDays: 31 },
        { name: 'September', numDays: 30 },
        { name: 'October', numDays: 31 },
        { name: 'November', numDays: 30 },
        { name: 'December', numDays: 31 },
      ],

      days: [
        {
          name: 'AM',
          numHours: 12
        },
        {
          name: 'PM',
          numHours: 12
        }
      ]
    }
  ],




  // 'January': 30,
  // 'February': 28,
  // 'March': 31,
  // 'April': 30,
  // 'May': 31,
  // 'June': 30,
  // 'July': 31,
  // 'August': 31,
  // 'September': 30,
  // 'October': 31,
  // 'November': 30,
  // 'December': 31

  timelines: {
    f8a99df00asd: {
      _id: 'f8a99df00asd',
      authorID: 'd82a3c23',
      createdOn: '2021-06-02T08:06:20.888Z',
      description: 'This is a timeline',
      lastUpdated: '2021-06-02T08:06:20.888Z',
      backgroundImage: 'https://i.pinimg.com/originals/72/9f/8b/729f8bdb794cd248d5fd0bf7ac391d9f.jpg',
      units: [
        {
          _id: 'aaa333',
          name: 'year',
          units: 0,
        },
        {
          _id: 'bbb444',
          name: 'month',
          calendar: {
            'bbb456': 'January',
            'bbb678': 'February',
            'bbb890': 'March',
            'bbb123': 'April',
            'bbb234': 'May',
            'bbb345': 'June',
            'bbb654': 'July',
            'bbb876': 'August',
            'bbb987': 'September',
            'bbb098': 'October',
            'bbb321': 'November',
            'bbb432': 'December'
          },
          subsetOf: {
            'aaa333': 12
          },
        },
        {
          _id: 'ccc555',
          name: 'day',
          calendar: {
            'ccc123': 'Sunday',
            'ccc234': 'Monday',
            'ccc345': 'Tuesday',
            'ccc456': 'Wednesday',
            'ccc567': 'Thursday',
            'ccc678': 'Friday',
            'ccc789': 'Saturday'
          },
          subsetOf: {
            'bbb456': 30,
            'bbb678': 28,
            'bbb890': 31,
            'bbb123': 30,
            'bbb234': 31,
            'bbb345': 30,
            'bbb654': 31,
            'bbb876': 31,
            'bbb987': 30,
            'bbb098': 31,
            'bbb321': 30,
            'bbb432': 31
          }
        }
      ],
      levels: [
        {
          label: 'Year',
          name: 'Year',
          nodes: [
            {
              id: '193af02',
              parent: null,
              position: 3001,
              date: {
                'aaa333': 3001
              },
              subtitle: 'Start of LOTR',
              title: 'Bilbo Leaves The Shire'
            },
            {
              id: '0734aad',
              parent: null,
              position: 3009,
              date: {
                'aaa333': 3009
              },
              subtitle: '',
              title: 'Aragorn Captures Gollum'
            },
            {
              id: 'ad141ad',
              parent: null,
              type: 'range',
              start: 3018,
              end: 3021,
              subtitle: '',
              title: 'The Quest to Destroy the Ring'
            },
          ],
          units: 0
        },
        {
          label: 'Day',
          name: 'Day',
          nodes: [
            {
              id: '4123aa73',
              parent: 'ad141ad',
              position: 3018,
              date: {
                'aaa333': 3018,
                'bbb444': 3
              },
              subtitle: 'Frodo leaves The Shire',
              title: 'Ringwraiths Sent to Find the One Ring'
            },
            {
              id: '91298asd',
              parent: 'ad141ad',
              position: 3021,
              subtitle: 'End of LOTR',
              title: 'The One Ring Is Destroyed'
            },
            {
              id: '761231da',
              parent: 0,
              position: 60,
              subtitle: '...',
              title: 'Point C'
            },
          ],
          units: 365,
        }
      ],
      title: 'Third Age'
    }
  },


}
