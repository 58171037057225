<template>
  <div class="text-loader-container">
    <div class="text-loader"></div>
  </div>
</template>

<script>
  export default {
    name: 'TextLoader',
    components: {

    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped lang="scss">

  .text-loader-container {
    @include flex($direction: column, $justify: center);
    width: 100%;
    height: 1em;
    font-size: inherit;
  }

  .text-loader {
    --restingOpacity: 0.25;

    width: 100%;
    height: 80%;
    border-radius: 0.5em;
    background-color: rgba(204,236,255,.1);
    mask-image: linear-gradient(120deg, rgba(0,0,0,var(--restingOpacity)) 20%, black 30%, rgba(0,0,0,var(--restingOpacity)) 40%, rgba(0,0,0,var(--restingOpacity)) 60%, black 70%, rgba(0,0,0,var(--restingOpacity)) 80%);
    mask-size: 600% 100%;
    mask-position: center top;
    animation-name: gradientCycle;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes gradientCycle {
    0% {
      mask-position: left center;
    }
    100% {
      mask-position: right center;
    }
  }

</style>
