<template>
  <div class="notifications">
    <div :class="`notifications-icon${numUnread ? '' : ' unread'}`"
      @click.stop="dropdownIsOpen = !dropdownIsOpen"
    >
      <Icon name="bells" size="24px" />
      <div class="badge" v-if="numUnread">
        <p>{{ numUnread }}</p>
      </div>
    </div>

    <div :class="`notifications-dropdown ${dropdownIsOpen ? 'open' : 'closed'}`">

      <div v-if="!notifications.length" class="notification-item none">
        <Icon name="exclamation" size="16px" style="color: #facf4d;" />
        <div class="text">
          <h4>You have no notifications.</h4>
        </div>
      </div>

      <div :class="`notification-item ${notification.read ? 'read' : 'unread'}`"
        v-for="notification in notifications"
        @click.stop="clickNotification(notification)"
      >
        <Icon :name="notification.icon" size="20px" />
        <div class="text">
          <h4>{{ notification.title }}</h4>
          <p>{{ notification.subtitle }}</p>
        </div>
        <div v-if="!notification.read"
          class="ok-button"
          @click.stop="read(notification)"
        >
          <p>OK</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'Notifications',
    components: {
    },
    computed: {
      notifications() {
        return this.$store.state.notifications || [];
      },

      numUnread() {
        const { notifications } = this.$store.state;
        if (!notifications || !notifications.length) return;

        let num = 0;
        notifications.forEach(notification => {
          if (!notification.read) num++;
        });
        return num;
      }
    },
    data() {
      return {
        dropdownIsOpen: false
      }
    },
    methods: {
      onClick() {
        if (this.dropdownIsOpen) this.dropdownIsOpen = false;
      },

      clickNotification(notification) {
        this.$store.dispatch('readNotification', notification._id);
        if (notification.link) this.$router.push(notification.link);
      },

      read(notification) {
        this.$store.dispatch('readNotification', notification._id);
      }
    },

    mounted() {
      window.addEventListener('click', this.onClick);
    },
    destroyed() {
      window.addEventListener('click', this.onClick);
    }
  }
</script>

<style scoped lang="scss">

  .notifications {
    position: relative;
    user-select: none;
  }

  .notifications-icon {
    position: relative;
    @include flex($justify: center, $align: center);
    width: 50px;
    height: 50px;
    margin-right: 1rem;
    padding: 1rem;
    border-radius: 100%;
    background-color: bg(15%);
    transition: background 0.15s;

    .icon {
      color: $colorA;
      opacity: 0.75;
      transition: opacity 0.15s;
    }

    &.unread .icon {
      color: bg(70%);
    }

    &:hover {
      cursor: pointer;
      background-color: bg(30%);

      .icon {
        opacity: 1;
      }
    }
  }

  .badge {
    position: absolute;
    @include flex($justify: center, $align: center);
    width: 22px;
    height: 22px;
    border-radius: 100%;
    top: -0.25rem;
    right: -0.25rem;
    background-color: #b91e31;

    p {
      font-family: 'Avenir', sans-serif;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .notifications-dropdown {
    position: absolute;
    top: 0;
    right: 0;
    width: 360px;
    max-height: 420px;
    border-radius: 0.5rem;
    background-color: #303336;
    box-shadow: 0 12px 30px -10px rgba(0,0,0,1);
    transition: transform 0.3s, opacity 0.3s;;
    overflow-y: auto;

    &.closed {
      transform: translateY(80px);
      opacity: 0;
      pointer-events: none;
    }
    &.open {
      transform: translateY(60px);
      pointer-events: auto;
    }
  }

  .notification-item {
    @include flex();
    padding: 1rem 1.25rem;
    opacity: 0.75;

    &.none {
      padding: 1.5rem;
    }

    &:not(:first-child) {
      border-top: 1px solid #44484e;
    }

    .icon {
      color: #ccc;
    }

    .text {
      margin: 0 1rem;
      @include flex($direction: column, $align: flex-start);

      h4 {
        font-size: 0.8rem;
        font-weight: 600;
      }
      p {
        margin-top: 0.25rem;
        font-size: 0.8rem;
        opacity: 0.85;
        line-height: 1.25em;
      }
    }

    &.unread {
      &::before {
        content: '';
        display: inline-block;
        flex-shrink: 0;
        margin-right: 0.75rem;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: $colorC;
      }

      .icon {
        color: $colorA;
      }

      .text h4 {
        color: $colorA;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: #343539;
      opacity: 1;
    }
  }

  .ok-button {
    margin-left: auto;
    padding: 0.75rem;
    border-radius: 0.5rem;
    background-color: #2b2e32;
    font-size: 0.75rem;
    font-weight: 600;

    p {
      line-height: 1em;
    }

    &:hover {
      background-color: #202326;

      p {
        color: $colorA;
      }
    }
  }

</style>
