<template>
  <div class="manage-worlds page">

    <div class="spacer h5"></div>
    <h1
      class="colorful-title glow"
      data-glow-text="Manage Worlds"
    >Manage Worlds</h1>
    <div class="spacer h3"></div>


    <div class="row spaced">
      <Button
        label="Back"
        icon="left-arrow"
        @click.native="$router.push('/creation')"
      />
      <Button
        label="New World"
        icon="plus"
        class="primary"
        @click.native="$router.push('/create-world')"
      />
    </div>

    <div class="spacer"></div>

    <Alert :alert="alert" @dismiss="alert = null" />


    <div class="worlds">
      <p v-if="!worlds || !worlds.length">You have no worlds.</p>

      <div v-for="world in worlds"
        class="world-container"
        @click="selectWorld(world._id)"
      >

        <div v-if="isCurrentWorld(world._id)" class="selected">
          <Icon name="check" size="12px" class="circle-icon checkmark" />
        </div>

        <Button
          icon="delete"
          iconSize="12px"
          class="circle-icon delete"
          @click.native.stop="clickDelete(world)"
        />

        <WorldPreview
          :worldObject="world"
        />

      </div>
    </div>

    <div class="spacer h5"></div>



    <ModalContainer v-if="deleteModal">
      <h4>Delete "{{ worldToBeDeleted.name }}"?</h4>
      <div class="spacer"></div>

      <Message
        type="warning"
        :text="`Warning: This will delete all data connected to the world.\nPlease double check that you are sure before deleting this world.`"
      />

      <Alert :alert="modalAlert" @dismiss="modalAlert = null" />

      <div class="spacer"></div>

      <div class="row spaced">
        <Button
          label="Cancel"
          @click.native="deleteModal = false"
        />
        <Button
          label="Delete World"
          class="delete"
          @click.native="deleteWorld"
        />
      </div>
    </ModalContainer>

  </div>
</template>

<script>
  import WorldPreview from './WorldPreview.vue'

  export default {
    name: 'ManageWorlds',
    components: {
      WorldPreview
    },

    data() {
      return {
        alert: null,

        deleteModal: false,
        worldToBeDeleted: null,
        modalAlert: null
      }
    },

    computed: {
      worlds() {
        return this.$store.state.user?.worlds
      }
    },

    methods: {
      isCurrentWorld(id) {
        return this.$store.state.user?.currentWorldID == id
      },

      selectWorld(id) {
        if (this.isCurrentWorld(id)) return;
        this.$store.dispatch('selectWorld', id).then(response => {
          this.$router.push('/creation')
        })
      },

      createWorld() {
        if (!this.name?.trim()) {
          this.alert = {
            type: 'warning',
            message: 'Please enter a name for your world.'
          }
          return;
        }

        let newWorld = {
          name: this.name,
          description: this.description
        }

        this.$store.dispatch('createWorld', newWorld).then(response => {
          console.log(response);
          if (response.status == 201) {
            this.alert = {
              type: 'success',
              message: 'World created successfully.'
            }
            setTimeout(() => {
              this.$router.push('/creation')
            }, 500);
          }
        })
      },

      clickDelete(world) {
        this.deleteModal = true;
        this.worldToBeDeleted = world;
      },

      deleteWorld() {
        this.$store.dispatch('deleteWorld', this.worldToBeDeleted._id).then(response => {
          console.log(response);

          if (response.status != 200) {
            this.modalAlert = {
              type: 'error',
              message: response.data?.error
            }
            return;
          }

          this.$store.dispatch('fetchUserWorlds').then(response => {
            const worlds = this.$store.state.user?.worlds;
            if (worlds?.length) {
              this.$store.dispatch('selectWorld', worlds[0]._id)
            }
            else {
              this.$store.dispatch('selectWorld', null)
              this.$store.commit('loadWorld', null)
            }

            this.alert = {
              type: 'success',
              message: 'World deleted successfully.'
            }
            this.deleteModal = false;
            this.worldToBeDeleted = null;
          })
        })
      }
    },

    created() {
      this.$store.dispatch('fetchUserWorlds')
    }
  }
</script>

<style scoped lang="scss">

  h1.colorful-title {
    font-size: 2rem;
  }

  .worlds {
    @include flex($justify: center);
    flex-wrap: wrap;
    width: 100%;
    max-width: 900px;
    margin: 1rem auto;

    .world-container {
      position: relative;
      margin: 1rem;
    }
  }

  .selected {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    border: 1px solid $colorA;
  }

  .circle-icon {
    position: absolute;
    top: -1rem;
    right: -1rem;
    padding: 0.5rem;
    background-color: #000;
    border-radius: 100%;

    &.checkmark {
      color: $colorA;
      border: 1px solid $colorA;
    }

    &.delete {
      top: unset;
      bottom: -1rem;
    }
  }

</style>
