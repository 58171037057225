<template>
  <div>

    <div class="spacer"></div>
    <h2>Notification History</h2>
    <div class="spacer"></div>

    <p>Coming Soon!</p>

  </div>
</template>

<script>
  export default {
    name: 'NotificationHistory',

    computed: {
      user() {
        return this.$store.state.user;
      }
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">


</style>
