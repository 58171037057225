<template>
  <div
    class="word-processor"
  >

    <div class="toolbar" @mouseup.stop="">
      <Button label="T" @mouseup.native="title" />
      <Button label="S" @mouseup.native="subtitle" />
      <Button label="B" @mouseup.native="bold" />
    </div>


    <div
      class="nodes-container"
      id="nodes"
      contenteditable="true"
      @keydown="onInput"
      @change="onChange"
    >
      <template v-for="(node, nodeIndex) in nodes">

        <span :class="`node ${node.display} ${node.style}`">{{ node.content }}</span>

        <LinkDropdown v-if="node.style == 'link' && currentNodeIndex == nodeIndex" />

      </template>
    </div>
  </div>
</template>

<script>
  import LinkDropdown from '../../LinkDropdown.vue'

  const MAX_UNDO_HISTORY = 10

  export default {
    name: 'WordProcessor',
    components: {
      LinkDropdown
    },

    props: {
      content: [Array],
      currentSceneIndex: [Number]
    },

    data() {
      return {
        nodes: [
          {
            display: 'inline',
            style: '',
            content: 'This is a line that is really long so hopefully it reaches the edge of the page and continues on the following line. Then we will continue even further to get a third line in the same node so we can explore this more.'
          },
          {
            display: 'inline',
            style: '',
            content: 'This is '
          },
          {
            display: 'inline',
            style: 'bold',
            content: 'bold'
          },
          {
            display: 'inline-block',
            style: '',
            content: ' text.'
          },
          {
            display: 'inline-block',
            style: '',
            content: 'A line now'
          },
          {
            display: 'block',
            style: '',
            content: ''
          },
          {
            display: 'block',
            style: '',
            content: 'Another line now'
          }
        ],

        // currentNodeIndex: 0,
        currentCharIndex: 0,

        undoHistory: [],
        currentHistoryIndex: 0
      }
    },

    watch: {
      currentSceneIndex: function(newValue) {
        console.log(this.cursorChar);
        this.cursorLine = null;
        this.cursorChar = null;
        this.selection = {
          startNode: null,
          startChar: null,
          endNode: null,
          endChar: null
        }
      },

      content: function(newContent, oldContent) {
        // this.nodes = this.import(newContent);
      }
    },

    computed: {
      previousNode() {
        if (this.currentNodeIndex <= 0) {
          return null
        }
        return this.nodes[this.currentNodeIndex - 1]
      },

      nextNode() {
        if (!this.nodes?.length || this.currentNodeIndex >= this.nodes.length - 1) {
          return null
        }
        return this.nodes[this.currentNodeIndex + 1]
      },

      currentNode() {
        return this.nodes[this.currentNodeIndex()]
      },
    },


    methods: {
      currentNodeElement() {
        const node = window.getSelection().extentNode
        return node.tagName != 'SPAN' ? node.parentElement : node
      },
      currentNodeIndex() {
        return Array.prototype.indexOf.call(this.currentNodeElement().parentElement.children, this.currentNodeElement())
      },

      // Nodes

      newNode(newChars) {
        return {
          style: '',
          content: ''
        }
      },

      bold() {
        event.stopPropagation();
        const { baseNode, baseOffset, extentNode, extentOffset } = window.getSelection()
        if (baseNode == extentNode && baseOffset == extentOffset) {
          this.splitAndInsertNode({
            display: 'inline',
            style: 'bold'
          })
        }
        else if (baseNode == extentNode) {
          this.splitAndInsertNode({
            start: baseOffset < extentOffset ? baseOffset : extentOffset,
            end: extentOffset > baseOffset ? extentOffset : baseOffset,
            display: 'inline',
            style: 'bold'
          })
        }
        // this.$emit('updated', this.export());
      },
      title() {
        event.stopPropagation();
        this.nodes[this.cursorLine].style = this.nodes[this.cursorLine].style == 'title' ? '' : 'title';
        // this.$emit('updated', this.export());
      },
      subtitle() {
        event.stopPropagation();
        this.nodes[this.cursorLine].style = this.nodes[this.cursorLine].style == 'subtitle' ? '' : 'subtitle';
        // this.$emit('updated', this.export());
      },

      export() {
        let exportNodes = [];
        this.nodes.forEach(node => {
          exportNodes.push({
            content: node.chars.join(''),
            style: node.style
          });
        });
        return exportNodes;
      },

      appendHistory() {
        // Reset
        if (this.currentHistoryIndex < this.undoHistory.length - 1) {
          this.undoHistory = []
        }

        if (this.undoHistory.length >= MAX_UNDO_HISTORY) {
          this.undoHistory.shift()
        }

        const savedNodes = JSON.parse(JSON.stringify(this.nodes))
        this.undoHistory.push(savedNodes)
        this.currentHistoryIndex = this.undoHistory.length - 1
      },

      undo() {
        if (this.undoHistory?.length && this.currentHistoryIndex > 0) {
          this.currentHistoryIndex--
          console.log(JSON.parse(JSON.stringify(this.undoHistory[this.currentHistoryIndex])));
          this.nodes = [...this.undoHistory[this.currentHistoryIndex]]
        }
      },

      redo() {
        if (this.undoHistory?.length && this.currentHistoryIndex < this.undoHistory.length - 1) {
          this.currentHistoryIndex++
          console.log(JSON.parse(JSON.stringify(this.undoHistory[this.currentHistoryIndex])));
          this.nodes = [...this.undoHistory[this.currentHistoryIndex]]
        }
      },


      // Key Presses

      setSelection(nodeIndex, charIndex) {
        const selection = window.getSelection()
        const range = document.createRange()
        let node = document.getElementById('nodes').children[nodeIndex]
        if (node.childNodes?.length) node = node.childNodes[0]
        // console.log(node);
        range.setStart(node, charIndex)
        selection.removeAllRanges()
        selection.addRange(range)
      },

      async splitNodeAtCursor() {
        const charIndex = window.getSelection().extentOffset
        const before = this.currentNode.content.substring(0, charIndex)
        const after = this.currentNode.content.substring(charIndex)

        console.log('before', before);
        console.log('after', after);

        this.nodes[this.currentNodeIndex()].content = before + '\n'

        const newNode = {
          display: this.currentNode.display,
          style: this.currentNode.style,
          content: after || ''
        }
        this.nodes.splice(this.currentNodeIndex() + 1, 0, newNode)

        this.setSelection(this.currentNodeIndex() + 1, 0)
      },

      splitAndInsertNode(options) {
        const charIndex = window.getSelection().extentOffset
        const before = this.currentNode.content.substring(0, options?.start ?? charIndex)
        const after = this.currentNode.content.substring(options?.end ?? charIndex)
        const middle = this.currentNode.content.substring(options?.start, options?.end)

        if (options?.display) {
          this.nodes[this.currentNodeIndex()].display = options.display
        }
        this.nodes[this.currentNodeIndex()].content = before

        const newNode = {
          display: options?.display || 'block',
          style: options?.style || '',
          content: options?.end ? middle : ''
        }
        this.nodes.splice(this.currentNodeIndex() + 1, 0, newNode)

        if (after) {
          const afterNode = {
            display: options?.display || 'block',
            style: this.currentNode.style,
            content: after
          }
          this.nodes.splice(this.currentNodeIndex() + 2, 0, afterNode)
        }

        // console.log(this.currentNodeIndex());
        this.setSelection(this.currentNodeIndex() + 1, 0)
      },

      async updateNodes() {
        // Get DOM render after edit
        await new Promise((resolve, reject) => {
          window.setTimeout(() => {
            resolve(true)
          }, 10)
        })

        // Grab selection after edit
        const nodeIndex = this.currentNodeIndex()
        const charIndex = window.getSelection().extentOffset

        // Update nodes, causing re-render
        const nodes = this.parseDomToNodes()
        this.nodes = nodes
        await this.$nextTick()
        await new Promise((resolve, reject) => {
          window.setTimeout(() => {
            resolve(true)
          }, 10)
        })

        // Put selection back
        this.setSelection(nodeIndex, charIndex)
      },

      async onInput() {
        // event.preventDefault()
        event.stopPropagation()

        const { key, metaKey, shiftKey } = event

        if (metaKey && key == 'l') {
          event.preventDefault()
          console.log(JSON.parse(JSON.stringify(this.nodes)));
          return
        }

        if (metaKey && shiftKey && key == 'z') {
          this.redo()
          return
        }
        if (metaKey && key == 'z') {
          this.undo()
          return
        }

        if (metaKey && key == 'p') {
          event.preventDefault()
          this.parseDomToNodes()
          return
        }

        if (metaKey && key == 'a') {
          this.createNodeAtCursor({
            style: 'link',
            content: ''
          })
          return
        }

        console.log(key);

        switch (key) {
          case 'Meta':
          case 'Shift':
          case 'Alt':
          case 'Control':
            event.preventDefault()
            break

          case 'Enter':
            event.preventDefault()
            this.splitNodeAtCursor()
            break

          case 'Backspace':
            // event.preventDefault()
            // this.splitNodeAtCursor()
            // this.updateNodes()
            break

          default:
            this.updateNodes()
        }

        console.log(JSON.parse(JSON.stringify(this.nodes)));

        // this.$emit('updated', this.export());
      },

      onChange() {
        console.log('change');
      },

      parseDomToNodes() {
        const container = document.getElementById('nodes')

        let nodes = []
        container.children.forEach(el => {
          const classes = el.className.split(' ')
          const node = {
            display: classes[1],
            style: classes[2],
            content: el.innerText == '\n' ? '' : el.innerText
          }
          nodes.push(node)
        });

        return nodes
      }
    },

    created() {
      // this.nodes = this.content
      this.undoHistory = [JSON.parse(JSON.stringify(this.nodes))]
    },

    mounted() {
      window.addEventListener('keydown', this.keyDownOutside);
    },

    destroyed() {
      window.removeEventListener('keydown', this.keyDownOutside);
    }
  }
</script>

<style scoped lang="scss">

  .word-processor {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.25rem;
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background-color: rgba(0,0,0,.2);

    &:hover {
      cursor: text;
    }
  }

  .toolbar {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    > *:not(:last-child) {
      margin-right: 0.25rem;
    }

    &:hover {
      cursor: auto;
    }
  }

  .nodes-container {
    position: relative;
    outline: none;
  }

  .cursor-input {
    position: absolute;
    margin: 0;
    padding: 0;
    // height: 1px;
    width: 1px;
    background: none;
    border: 0;
    overflow: visible;
    opacity: 0;
  }

  .cursor-caret-container {
    position: relative;
    display: inline-block;
    height: 1em;
    line-height: 1em;
  }

  .cursor-caret {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    align-self: center;
    bottom: -2px;
    height: 100%;
    width: 1px;
    contain: strict;
    background-color: $colorA;
  }

  .node {
    position: relative;
    display: inline;
    white-space: pre-wrap;

    span {
      line-height: 2rem;
    }

    &.block {
      display: block;
    }
    &.inline {
      display: inline;
    }
    &.inline-block {
      display: inline-block;
    }

    &.bold {
      font-weight: 600;
    }
    &.title {
      margin-bottom: 0.25rem;
      font-size: 1.25rem;
      text-align: center;
      font-weight: 400;
    }
    &.subtitle {
      margin-bottom: 0.25rem;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
      span {
        color: rgba(209,222,255,.5);
      }
    }

    &.link {
      span {
        font-weight: 600;
        color: #edbb6f;
      }
    }

    &:hover {
      cursor: text;
    }
  }

  #click-low, #click-high, #next-line {
    position: absolute !important;
    top: 0;
    left: 0;
    pointer-events: none !important;
    opacity: 0 !important;
  }

  // .last-clicked {
  //   position: absolute;
  //   width: 8px;
  //   height: 8px;
  //   border-radius: 100%;
  //   background-color: #ff46d1;
  //   transform: translate(-50%, -50%);
  // }

</style>
