
export default {

  _id: 'default',
  name: 'Human Calendar',

  months: [
    { name: 'January', numDays: 31 },
    { name: 'February', numDays: 28 },
    { name: 'March', numDays: 31 },
    { name: 'April', numDays: 30 },
    { name: 'May', numDays: 31 },
    { name: 'June', numDays: 30 },
    { name: 'July', numDays: 31 },
    { name: 'August', numDays: 31 },
    { name: 'September', numDays: 30 },
    { name: 'October', numDays: 31 },
    { name: 'November', numDays: 30 },
    { name: 'December', numDays: 31 },
  ],

  days: [
    {
      name: 'AM',
      numHours: 12
    },
    {
      name: 'PM',
      numHours: 12
    }
  ]

}
