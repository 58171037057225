<template>
  <div class="progress-bubbles-container">
    <div class="progress-bubbles">
      <div v-for="(step, index) in steps"
          :class="`step ${step.status}`"
          :style="bubbleStyle(step, index)">
        <div class="bubble"></div>
        <div class="line" :style="lineStyle(step)"></div>
        <p>{{ step.name }}</p>
      </div>
    </div>
  </div>
</template>


<script>
  export default {
    name: 'ProgressBubbles',
    data() {
      return {
        steps: [
          {
            name: 'Creation: Writings & Wiki',
            status: 'complete',
            xOffset: -240,
            yOffset: 0,
            rotation: -15,
            lineLength: 85,
            lineOffset: 5,
            opacity: 0.5
          },
          {
            name: 'Community: Users & Posts',
            status: 'complete',
            xOffset: -210,
            yOffset: 80,
            rotation: -48,
            lineLength: 132,
            lineOffset: 7,
            opacity: 0.6
          },
          {
            name: 'Creation: Worlds, Timelines & Maps',
            status: 'complete',
            xOffset: -100,
            yOffset: 160,
            rotation: -31,
            lineLength: 98,
            lineOffset: 5,
            opacity: 0.7
          },
          {
            name: 'Alpha Release & Testing',
            status: 'complete',
            xOffset: -40,
            yOffset: 240,
            rotation: -22,
            lineLength: 89,
            lineOffset: 3,
            opacity: 0.8
          },
          {
            name: 'Beta Release',
            status: 'in-progress',
            xOffset: 0,
            yOffset: 320,
            rotation: -45,
            lineLength: 130,
            lineOffset: 8,
            opacity: 0.9,
          },
          {
            name: 'Languages',
            status: 'incomplete',
            xOffset: 105,
            yOffset: 400,
            rotation: 0,
            lineLength: 80,
            lineOffset: 0,
            opacity: 1
          }
        ]
      }
    },
    methods: {
      bubbleStyle(step, index) {
        return `--index: ${index}; opacity: ${step.opacity || 1}; transform: translate(${step.xOffset}px, ${step.yOffset}px);`
      },

      lineStyle(step) {
        return `height: ${step.lineLength}px; transform: rotate(${step.rotation}deg) translateX(${step.lineOffset}px);`
      },

      calcTransform(rotation, lineOffset) {
        if (!rotation) return '';

        // const maxOffset = 70;
        // let rotationInRadians = Math.abs(rotation) * (Math.PI / 180);
        // let offsetAmount = maxOffset * Math.sin(rotationInRadians);
        // if (rotation > 0) offsetAmount *= -1;

        return
      }
    }
  }
</script>


<style scoped lang="scss">

  $progress-bubble-size: 20px;
  $progress-bubble-complete-color: #30c280;
  $progress-bubble-in-progress-color: #fcc73e;
  $progress-bubble-incomplete-color: #a4a8b1;

  .progress-bubbles-container {
    position: relative;
    @include flex($justify: center);
    width: 900px;
    height: 600px;
    overflow: hidden;
    margin: auto;
  }

  .progress-bubbles {
    padding: 2rem;
  }

  .step {
    position: absolute;
    width: $progress-bubble-size;
    height: $progress-bubble-size;
    user-select: none;
    box-sizing: border-box;
    top: calc(#{$progress-bubble-size} * var(--index));

    .bubble {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-color: #30c280;
    }

    p {
      position: absolute;
      top: calc(50% - 0.5em);
      color: #fff;
      line-height: 1em;
      white-space: nowrap;
    }

    &:nth-child(odd) p {
      left: calc(100% + 1rem);
      text-align: left;
    }
    &:nth-child(even) p {
      right: calc(100% + 1rem);
      text-align: right;
    }

    .line {
      position: absolute;
      left: calc(50% - 0.5px);
      top: $progress-bubble-size;
      border: 1px solid #30c280;
      transform-origin: top;
      z-index: -9;
    }

    &:last-child .line {
      height: 0;
      border: 0;
    }

    &.complete {
      .bubble {
        background-color: $progress-bubble-complete-color;
      }

      .line {
        border-color: $progress-bubble-complete-color;
      }
    }

    &.in-progress {
      .bubble {
        background-color: $progress-bubble-in-progress-color;
        box-shadow: 0 0 12px rgba(255,255,255,.75);
        border: 1px solid #fff;
        animation-name: pulse;
        animation-duration: 3s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
      }

      .line {
        border-color: $progress-bubble-incomplete-color;
      }
    }

    &.incomplete {
      .bubble {
        background-color: $progress-bubble-incomplete-color;
      }

      .line {
        border-color: $progress-bubble-incomplete-color;
      }
    }
  }


  @keyframes pulse {
    0% {
      border-color: rgba(255,255,255,.25);
      box-shadow: 0 0 8px rgba(255,255,255,.25);
    }
    50% {
      border-color: rgba(255,255,255,1);
      box-shadow: 0 0 24px 4px rgba(255,255,255,1);
    }
    100% {
      border-color: rgba(255,255,255,.25);
      box-shadow: 0 0 8px rgba(255,255,255,.25);
    }
  }

</style>
