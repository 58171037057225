<template>
  <section class="thread">
    <Loader v-if="this.$store.state.loading" />

    <Button :label="this.$store.state.forum.currentSubforum.name" icon="back-arrow" iconSize="20px"
            class="back-button" @click.native="$emit('back-to-subforum')" />

    <h2>{{ thread.title }}</h2>

    <div class="thread-toolbar">
      <Button label="Post Reply" icon="plus" iconSize="20px"
              :class="showNewPost ? 'disabled' : ''" @click.native="openNewPost" />
    </div>

    <div class="tags">
      <h5 v-for="tag in exampleTags">{{ tag }}</h5>
    </div>

    <NewPost v-if="showNewPost" type="forum" @new-post-success="submitNewPost" @close-new-post="closeNewPost" />

    <ForumPost v-if="!loading" v-for="post in thread.posts" :key="post.id"
          type="normal"
          :level="0"
          :posterID="post.posterID"
          :name="post.userName"
          :content="post.content"
          :date="post.datePosted"
          :reactions="post.reactions"
          :myReaction="null"
          :replies="post.replies"
          @toggle-reply="resetAllRepliesToggle" />
  </section>
</template>


<script>
  import ForumPost from './ForumPost.vue';
  import NewPost from '../Posts/NewPost.vue';

  export default {
    name: 'Thread',
    components: {
      ForumPost,
      NewPost
    },
    props: {
      thread: Object
    },
    data() {
      return {
        showAllReplies: null,
        showNewPost: false,
        exampleTags: ['Character', 'Setting', 'Farming'],
        exampleReplies: null
      }
    },
    methods: {
      toggleAllReplies() {
        this.showAllReplies = !this.showAllReplies;
      },
      resetAllRepliesToggle() {
        this.showAllReplies = null;
      },

      openNewPost() {
        this.showNewPost = true;
      },
      submitNewPost() {
        // this.showNewPost = false;
        // this.$emit('refresh-thread');
      },
      closeNewPost() {
        this.showNewPost = false;
      }
    }
  }
</script>


<style scoped lang="scss">

  section.thread {
    position: relative;
    @include flex($direction: column, $align: center);
    width: $forum-width;
    max-width: $forum-max-width;
    margin: auto;

    .loader-container {
      top: 200px;
    }

    .back-button {
      position: absolute;
      top: 0;
      left: 0;
      text-transform: capitalize;
    }

    h2 {
      margin-bottom: 1.5rem;
      color: #fff;
      text-align: center;
    }

    .tags {
      @include flex($justify: center);
      margin-bottom: 2rem;

      h5 {
        @include margin-between($h: 1.5rem);
        padding: 0.4rem 1rem;
        border-radius: 1rem;
        color: $thread-tags-color;
        background-color: rgba(255,255,255,.05);
        font-size: 0.75rem;
        text-transform: uppercase;
        transition: all 0.1s;
        box-shadow: 2px 4px 6px rgba(0,0,0,.1);

        &:hover {
          cursor: pointer;
          background-color: rgba(255,255,255,.1);
        }
      }
    }
  }

  .thread-toolbar {
    position: absolute;
    top: 0;
    right: 0;
    @include flex($direction: column, $align: flex-end);
  }

</style>
