<template>
  <div class="dashboard">

    <div class="spacer"></div>

    <GlowingPattern
      pattern="line4"
    />

    <Sidebar />

    <router-view class="dashboard-page" />

    <div class="spacer h5"></div>

  </div>
</template>

<script>
  import Sidebar from './Dashboard/Sidebar.vue';
  import GlowingPattern from '../GlowingPattern.vue';

  export default {
    name: 'Dashboard',
    components: {
      Sidebar,
      GlowingPattern,
    },

    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (!vm.$store.state.user) {
          vm.$router.push('/login')
        }
      })
    }
  }
</script>

<style scoped lang="scss">

  .dashboard {
    min-height: calc(100vh - #{$navigation-height} - #{$footer-height});
  }

  .dashboard-page {
    position: relative;
    @include flex($direction: column, $align: center);
    width: calc(100vw - 4rem);
    margin: 0 2rem;
  }

  @media (max-height: 775px) {
    .dashboard {
      min-height: 550px;
    }
  }

</style>
