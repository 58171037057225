<template>
  <div class="permissions floating">

    <div class="title">
      <h3>Your Permissions</h3>
    </div>


    <div class="table">
      <div class="table-row">
        <div>
          <div class="element">
            <img src="https://media.scrybe-app.com/assets/elements/writing_icon.png" />
            <p class="writings">Writings</p>
          </div>
        </div>
        <div>
          <div class="row">
            <Icon name="check" class="yes" />
          </div>
        </div>
        <p class="expires-on">Forever</p>
      </div>

      <div class="table-row">
        <div>
          <div class="element">
            <img src="https://media.scrybe-app.com/assets/elements/wiki_icon.png" />
            <p class="wikis">Wikis</p>
          </div>
        </div>
        <div>
          <div class="row">
            <Icon name="check" class="yes" />
          </div>
        </div>
        <p class="expires-on">Forever</p>
      </div>

      <div class="table-row">
        <div>
          <div class="element">
            <img src="https://media.scrybe-app.com/assets/elements/timeline_icon.png" />
            <p class="timelines">Timelines</p>
          </div>
        </div>
        <div>
          <div class="row">
            <Icon :name="hasPermission('timelines') ? 'check' : 'x'"
              :class="hasPermission('timelines') ? 'yes' : 'no'"
            />
          </div>
        </div>
        <p class="expires-on">{{ permissions['timelines'] ? `Until ${date(permissions['timelines'] * 1000)}` : '--' }}</p>
      </div>

      <div class="table-row">
        <div>
          <div class="element">
            <img src="https://media.scrybe-app.com/assets/elements/map_icon.png" />
            <p class="maps">Maps</p>
          </div>
        </div>
        <div>
          <div class="row">
            <Icon :name="hasPermission('maps') ? 'check' : 'x'"
              :class="hasPermission('maps') ? 'yes' : 'no'"
            />
          </div>
        </div>
        <p class="expires-on">{{ permissions['maps'] ? `Until ${date(permissions['maps'] * 1000)}` : '--' }}</p>
      </div>

      <div class="table-row">
        <div>
          <div class="element">
            <img src="https://media.scrybe-app.com/assets/elements/empty_icon.png" />
            <p class="languages">Languages</p>
          </div>
        </div>
        <div>
          <div class="row">
            <Icon :name="hasPermission('languages') ? 'check' : 'x'"
              :class="hasPermission('languages') ? 'yes' : 'no'"
            />
          </div>
        </div>
        <p class="expires-on">{{ permissions['languages'] ? `Until ${date(permissions['languages'] * 1000)}` : '--' }}</p>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'Permissions',

    computed: {
      permissions() {
        return this.$store.state.user?.permissions || {}
      }
    },

    methods: {
      date(d) {
        return this.formatDate(d, '&month &dd, &year | &hour12:&min &ap')
      }
    }
  }
</script>

<style scoped lang="scss">

  .permissions {
    width: 100%;
    max-width: 500px;
    background-color: bg(20%);
    border-radius: 0.5rem;
    overflow: hidden;
    user-select: none;

    .table {
      width: 100%;
      padding: 1rem;
      padding-top: 0.5rem;
    }
  }

  .title {
    width: 100%;
    padding: 0.75rem 1.5rem;
    background-color: bg(5%);
    text-align: center;

    h3 {
      color: $colorB;
      font-size: 0.8rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  .header {
    p {
      color: $hint;
      font-size: 0.9rem;
    }
  }

  .element {
    @include flex();

    img {
      margin-right: 0.5rem;
      width: 40px;
    }

    p {
      font-size: 0.9rem;
    }
  }

  .table-row {
    text-align: center;
  }

  .icon {
    text-align: center;
  }

  .yes {
    color: $alert-success-color;
  }
  .no {
    color: $alert-error-color;
  }

  .expires-on {
    white-space: pre;
    font-size: 0.8rem;
    font-weight: 600;
    color: $colorB;
  }

  .writings {
    color: $writings-color;
  }
  .wikis {
    color: $wiki-color;
  }
  .timelines {
    color: $timelines-color;
  }
  .maps {
    color: $maps-color;
  }
  .languages {
    color: $languages-color;
  }

</style>
