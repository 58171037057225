<template>
  <div v-if="$store.state.user" class="new-reply">
    <textarea @input="inputChanged" @focus="inputChanged">{{ editingText }}</textarea>
    <DraftSaved :lastInput="lastInput" />

    <div class="attachments-list">
      <Button v-for="link in links" :label="link.title" class="attachment"
        icon="x" iconSize="0.75rem"
        @click.native="removeAttachment(link._id)" />
    </div>

    <item-layout>
      <Button label="Writing" icon="plus" iconSize="0.75rem"
        @click.native="() => writingCollectionVisible = true" />
    </item-layout>

    <item-layout>
      <Button label="Post" @click.native="submitNewReply" />
      <Button label="Cancel" @click.native="$emit('close')" />
      <Button label="Clear" @click.native="openModal" />
    </item-layout>

    <!-- <SelectWriting v-if="writingCollectionVisible"
      @select-file="selectWriting" @close="() => writingCollectionVisible = false" /> -->

    <Alert :alert="alert" @dismiss="alert = null" />
    <Modal v-if="modalOpen" @modal-confirm="clearProgress" @modal-cancel="closeModal" />
  </div>
</template>


<script>
  export default {
    name: 'NewReply',
    components: {
    },
    props: {
      parentID: String
    },
    data() {
      return {
        editingText: '',
        links: [],

        lastInput: null,
        alert: null,

        modalOpen: false,
        writingCollectionVisible: false
      }
    },
    methods: {
      selectWriting(file) {
        console.log("selected:", file);
        this.links.push({
          _id: file._id,
          type: 'writing',
          title: file.title
        });
        this.writingCollectionVisible = false;
      },

      removeAttachment(id) {
        this.links = this.links.filter(link => link._id != id);
      },

      inputChanged() {
        this.editingText = event.target.value;
        window.localStorage.setItem('new-reply-editing', event.target.value);

        let height = window.getComputedStyle(event.target).height;
        if (event.target.scrollHeight >= parseInt(height)) {
          event.target.style.height = event.target.scrollHeight + 'px';
        }
        else {
          event.target.style.height = '80px';
        }
        this.lastInput = Date.now();
      },
      clearProgress() {
        window.localStorage.removeItem('new-reply-editing');
        this.editingText = '';
        this.closeModal();
      },

      submitNewReply() {
        if (!this.editingText.trim()) {
          this.alert = {
            type: 'error',
            message: 'No reply content.'
          }
          return;
        }

        let params = {
          authorID: this.$store.state.user._id,
          parentID: this.parentID,
          content: this.editingText,
          links: this.links
        };

        this.$store.dispatch('submitNewReply', params)
          .then(response => {
            console.log("response:", response);
            if (response.status === 201) {
              this.alert = {
                type: 'success',
                message: 'New reply added!'
              }
              this.clearProgress();
              this.$emit('success');
              this.$store.dispatch('fetchDiscoverPosts');
            }
            else {
              this.alert = {
                type: 'error',
                message: response.data
              }
            }
          });
      },

      openModal() {
        this.modalOpen = true;
      },
      closeModal() {
        this.modalOpen = false;
      }
    },
    created() {
      this.editingText = window.localStorage.getItem('new-reply-editing') || '';
    }
  }
</script>


<style scoped lang="scss">

  .new-reply {
    @include flex($direction: column);
    max-width: 600px;
    margin: 1rem auto;

    textarea {
      height: 80px;
      max-height: 300px;
    }

    > *:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  item-layout {
    @include flex($justify: center);

    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .attachment {
    margin: 0.25rem 0;
    justify-content: flex-start;
  }

</style>
