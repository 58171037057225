<template>
  <div class="current-plan floating">

    <template v-if="!current_plan">
      <p class="hint">You are not yet subscribed. </p>
    </template>



    <template v-else>
      <p :class="`subscription-status ${subscription_status}`">Subscription Status: <span>{{ subscription_status }}</span></p>


      <template v-if="!current_plan.canceled_at">
        <div class="spacer h3"></div>
        <h3>Current Plan</h3>
        <p v-for="item in current_plan.items">{{ item }}</p>
        <div class="spacer h3"></div>

        <div class="billing-status">
          <div class="amount-due">
            <h6>Due</h6>
            <p class="hint">{{ formatDate(current_plan.last_payment * 1000) }}</p>
          </div>
        </div>
        <div class="spacer h1"></div>

        <div class="billing-status">
          <div :class="`amount-due ${current_plan.has_paid ? 'paid' : 'unpaid'}`">
            <h6>Total Amount</h6>
            <h2>${{ (current_plan.total / 100).toFixed(2) }}</h2>
          </div>

          <Message v-if="current_plan.has_paid" type="success" text="Paid" />
          <Message v-else type="danger" text="Not Paid" />
        </div>

        <template v-if="current_plan.cancel_at">
          <div class="spacer h1"></div>
          <Message type="warning" text="Plan Will Not Renew" />
        </template>

        <template v-if="!current_plan.has_paid">
          <Message type="warning" text="If no payment is received within 7 days of the due date, your subscription will be cancelled." />
          <div class="row">
            <Button
              label="Update Card Details"
              class="delete"
              @click.native="openPortal"
            />
          </div>
        </template>

        <div class="billing-cycle">
          <template v-if="current_plan.cancel_at">
            <p class="hint">Your plan is scheduled to expire on</p>
            <div class="important-date">
              <Icon name="no" size="1.1rem" />
              <p>{{ formatDate(current_plan.next_payment * 1000) }}</p>
            </div>
          </template>

          <template v-else>
            <p class="hint">Your next payment is scheduled for</p>
            <div class="important-date">
              <Icon name="credit card" size="1.1rem" />
              <p>{{ formatDate(current_plan.next_payment * 1000) }}</p>
            </div>
          </template>
        </div>
      </template>



      <template v-else>
        <div class="billing-cycle">
          <p class="hint">Your plan expired on</p>
          <div class="important-date expired">
            <Icon name="no" size="1.1rem" />
            <p>{{ formatDate(current_plan.canceled_at * 1000) }}</p>
          </div>
        </div>
      </template>

    </template>
  </div>
</template>


<script>
  export default {
    name: 'SubscriptionStatus',
    props: {
      current_plan: Object,
      subscription_status: [String],
    }
  }
</script>


<style scoped lang="scss">

  .subscription-status {
    color: neutral(80%);
    font-size: 0.9rem;
    text-transform: uppercase;


    span {
      font-weight: 600;
    }

    &.active span {
      color: $colorA;
    }

    &.expired span {
      color: $alert-error-color;
    }
  }

  .current-plan {
    max-width: 540px;
    margin-bottom: 2rem;
    padding: 3rem 4rem;
    border-radius: 0.25rem;
    color: #fff;
    background-color: bg(0%);

    h3 {
      margin-bottom: 0.5rem;
      text-align: center;
    }
    p {
      text-align: center;
    }

    .billing-status {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 0.75rem;

      .amount-due {
        margin-right: 2rem;

        h6 {
          margin-bottom: 0.25rem;
          color: $colorB;
          font-size: 0.75rem;
          text-transform: uppercase;
        }

        h2 {
          font-weight: 400;
        }

        &.paid h2 {
          color: $colorA;
        }

        &.unpaid h2 {
          color: $alert-error-color;
        }
      }

      /deep/ .message {
        margin: 0;
        padding-left: 1rem;

        .icon {
          margin-right: 0.5rem;
        }
      }
    }

    .billing-cycle {
      @include flex($direction: column, $justify: center);
      margin-top: 2.5rem;
      color: #fff;

      .important-date {
        @include flex($justify: center);
        margin-top: 0.25rem;
        text-align: center;
        font-weight: 600;

        .icon {
          margin-right: 0.5rem;
          color: $colorB;
          line-height: 2rem;
        }

        &.expired .icon {
          color: $alert-error-color;
        }

        p {
          // color: $colorA;
          line-height: 2rem;
        }
      }

      h3 {
        color: neutral(65%);
        font-size: 1.25rem;
        font-weight: 400;
      }
    }
  }

</style>
