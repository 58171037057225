<template>
  <div :class="`input ${focused ? 'focus' : ''}`" @mousedown="onClick()">
    <p @mouseup="onMouseUp">{{ content || '&zwnj;' }}</p>
    <div class="cursor" :style="`left: calc(0.5rem + ${cursorXOffset}px);`"></div>
  </div>
</template>

<script>
  export default {
    name: 'Input',
    components: {

    },
    data() {
      return {
        content: '',
        focused: false,
        selection: null,

        cursorXOffset: 0
      }
    },
    methods: {
      onClickOutside() {
        this.blur();
      },
      onClick() {
        event.stopPropagation();
        this.focus();
      },

      focus() {
        this.focused = true;
        window.addEventListener('keydown', this.onKeyDown);
      },
      blur() {
        this.focused = false;
        window.removeEventListener('keydown', this.onKeyDown);
      },

      onMouseUp() {
        console.log(event);
        this.selection = [window.getSelection().baseOffset, window.getSelection().extentOffset];
        let substring = this.content.substring(0, window.getSelection().extentOffset);
        console.log(substring);
        this.cursorXOffset = event.offsetX;
      },

      advanceSelection() {
        this.selection[0] += 1;
        if (this.selection[0] > this.content.length) this.selection[0] = this.content.length;
        this.selection[1] += 1;
        if (this.selection[1] > this.content.length) this.selection[1] = this.content.length;
      },
      retreatSelection() {
        this.selection[0] -= 1;
        if (this.selection[0] < 0) this.selection[0] = 0;
        this.selection[1] -= 1;
        if (this.selection[1] < 0) this.selection[1] = 0;
      },

      onKeyDown() {
        console.log(event);
        console.log(this.selection[0], this.selection[1], this.content.length);
        if (event.key === ' ') {
          event.preventDefault();
          event.stopPropagation();
          this.content += event.key;
          this.advanceSelection();
        }
        else if (event.key === 'Backspace') {
          let range = Math.abs(this.selection[0] - this.selection[1]);

          if (range === 0) {
            this.content = this.content.substring(0, this.selection[1] - 1) + this.content.substring(this.selection[1]);
            this.retreatSelection();
          }
        }
        else if (event.key === 'Enter') {
          this.$emit('input-enter');
        }
        else if (event.key === 'Alt' ||
                event.key === 'Control' ||
                event.key === 'Shift' ||
                event.key === 'CapsLock' ||
                event.key === 'Meta') {
          // Do nothing
        }
        else if (event.metaKey && event.key === 'a') {
          // Select all
          console.log("yay");
        }
        else {
          this.content += event.key;
          this.advanceSelection();
        }
      }
    },
    mounted() {
      window.addEventListener('mousedown', this.onClickOutside);
    },
    destroyed() {
      window.removeEventListener('mousedown', this.onClickOutside);
    }
  }
</script>

<style scoped lang="scss">

  .input {
    position: relative;
    width: 400px;
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: #000;
    border: 1px solid transparent;
    transition: border 0.3s;

    &:hover {
      cursor: text;
    }

    &.focus {
      border: 1px solid #c79aff;
    }
  }

  .cursor {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    width: 1px;
    height: calc(100% - 1rem);
    background-color: #fff;
  }

</style>
