<template>
  <div class="page wiki-page-container">

    <ImmersiveBackground
      :youtubeHTML="youtubeHTML"
      :isYoutubeLocked="isYoutubeLocked"
      :backgroundImage="backgroundImage"
      :overlayColor="overlayColor"
      :overlayOpacity="overlayOpacity"
      @toggle-lock="isYoutubeLocked = !isYoutubeLocked"
    />

    <BackButton
      style="top: 2rem; left: 2rem;"
      @click.native="comingFromNew ? $router.push('/creation') : $router.back()"
    />

    <Loader v-if="!page" />


    <div class="toolbar-row">
      <div class="center">
        <Button v-if="isAuthor"
          :label="isPreviewing ? 'Stop Preview' : 'Preview'"
          icon="eye"
          :class="isPreviewing ? 'primary' : ''"
          @click.native="isPreviewing = !isPreviewing"
        />
      </div>
      <div v-if="isAuthor && !isPreviewing" class="right">
        <Button
          label="Customize"
          icon="left-arrow"
          @click.native="customizeVisible = true"
        />
      </div>
    </div>



    <div v-if="page" class="wiki-page">

      <div class="header">
        <template v-if="editingHeader">
          <input
            :value="page.title"
            @input="(e) => page.title = e.target.value"
            @change="fieldChanged('title')"
          />
          <div class="spacer h1"></div>
          <textarea
            :value="page.subtitle"
            @input="(e) => page.subtitle = e.target.value"
            @change="fieldChanged('subtitle')"
          />

          <div class="spacer h1"></div>
          <div class="row spaced">
            <Button
              label="Done"
              icon="check"
              class="primary"
              @click.native="editingHeader = false"
            />
          </div>
        </template>

        <template v-else>
          <h1>{{ page.title }}</h1>
          <p>{{ page.subtitle }}</p>
        </template>

        <div v-if="isAuthor && !isPreviewing && !editingHeader" class="buttons">
          <Button
            icon="edit"
            class="primary"
            @click.native="editingHeader = true"
          />
        </div>
      </div>



      <div class="images">
        <template v-if="isLoadingImages">
          <div class="image-container">
            <Loader size="60px" class="loader-center" />
          </div>
        </template>

        <template v-else>
          <div class="image-container main-image">
            <img :src="mainImage ? publicImageURL('wiki', mainImage.name, page.authorID) : publicImageURL('avatar', 'default_avatar.png')" />
            <template v-if="isAuthor && !isPreviewing && mainImage">
              <Button
                class="delete-button delete"
                icon="x"
                iconSize="12px"
                @click.native="deleteImage(mainImage)"
              />
              <Button
                :class="`visibility-button ${mainImage.visibility == 'public' ? 'primary' : ''}`"
                :icon="mainImage.visibility == 'public' ? 'eye' : 'lock'"
                iconSize="12px"
                @click.native="toggleImageVisibility(0)"
              />
            </template>
          </div>

          <div class="image-scroll-container">
            <div v-for="(image, index) in otherImages" class="image-container">
              <Loader v-if="!image" size="60px" class="loader-center" />

              <template v-else>
                <img :src="publicImageURL('wiki', image.name, page.authorID)" />
                <template v-if="isAuthor && !isPreviewing">
                  <Button
                    class="delete-button delete"
                    icon="x"
                    iconSize="12px"
                    @click.native="deleteImage(image)"
                  />
                  <Button
                    :class="`visibility-button ${image.visibility == 'public' ? 'primary' : ''}`"
                    :icon="image.visibility == 'public' ? 'eye' : 'lock'"
                    iconSize="12px"
                    @click.native="toggleImageVisibility(index + 1)"
                  />
                </template>
              </template>
            </div>

            <input
              type="file"
              name="wiki"
              id="imageFileSelect"
              @change="selectImage"
            />
          </div>

          <Button v-if="isAuthor && !isPreviewing"
            label="Add Image"
            class="add-button primary"
            icon="plus"
            @click.native="imageModalVisible = true"
          />
        </template>
      </div>



      <div class="fields">
        <div class="fields-table">
          <div
            v-if="page.headerFields && !editingHeaderFields"
            v-for="item in page.headerFields"
            class="field-item"
          >
            <td>
              <span>{{ item.field }}</span>
            </td>
            <td>
              <p>{{ item.value }}</p>
            </td>
          </div>
        </div>


        <template v-if="editingHeaderFields">
          <div class="fields-inputs">
            <div
              v-if="page.headerFields"
              v-for="(item, index) in page.headerFields"
              class="field-input-item"
            >
              <td>
                <input type="text"
                  :value="item.field"
                  placeholder="Field"
                  @input="(e) => item.field = e.target.value"
                  @change="fieldChanged('headerFields')"
                />
              </td>
              <td>
                <input type="text"
                  :value="item.value"
                  placeholder="Value"
                  @input="(e) => item.value = e.target.value"
                  @change="fieldChanged('headerFields')"
                />
              </td>
              <td>
                <Button
                  icon="delete"
                  class="delete"
                  @click.native="deleteHeaderField(index)"
                />
              </td>
            </div>
          </div>

          <div class="spacer h1"></div>
          <div class="row spaced">
            <Button
              label="Add Field"
              icon="plus"
              class="primary"
              @click.native="newHeaderField"
            />
            <Button
              label="Done"
              icon="check"
              @click.native="editingHeaderFields = false"
            />
          </div>
          <div class="spacer h1"></div>
        </template>

        <div v-if="isAuthor && !isPreviewing && !editingHeaderFields" class="buttons">
          <Button
            icon="edit"
            class="primary"
            @click.native="editingHeaderFields = true"
          />
        </div>
      </div>



      <div class="sections">
        <section v-for="(section, index) in page.sections">

          <div v-if="isAuthor && !isPreviewing && editingSection != index" class="buttons">
            <Button label=""
              icon="edit"
              class="primary"
              @click.native="editingSection = editingSection === index ? null : index"
            />
            <template v-if="editingSection == null">
              <Button
                icon="less-arrow"
                class="dark"
                @click.native="moveUp(index)"
              />
              <Button
                icon="more-arrow"
                class="dark"
                @click.native="moveDown(index)"
              />
              <Button
                label=""
                icon="delete"
                class="delete delete-section-button"
                @click.native="showDeleteSection(index)"
              />
            </template>
          </div>


          <template v-if="editingSection == index">
            <input
              :value="section.title"
              @input="(e) => onSectionTitleInput(section)"
              @change="fieldChanged('sections')"
            />
            <div class="spacer h1"></div>
            <textarea
              :value="section.content"
              @input="(e) => onSectionContentInput(section)"
              @change="fieldChanged('sections')"
            />

            <div class="spacer h1"></div>
            <div class="row spaced">
              <Button
                label="Done"
                icon="check"
                class="primary"
                @click.native="editingSection = null"
              />
            </div>
          </template>

          <template v-else>
            <h2>{{ section.title }}</h2>
            <hr/>
            <p>{{ section.content }}</p>
          </template>

        </section>
      </div>

      <div v-if="isAuthor && !isPreviewing" class="row" style="grid-area: footer;">
        <Button
          label="New Section"
          icon="plus"
          class="primary"
          @click.native="newSection"
        />
      </div>



      <div v-if="isAuthor"
        :class="`customize-container ${customizeVisible ? 'open' : ''}`"
      >
        <Button
          label="Close"
          icon="right-arrow"
          style="margin-right: auto;"
          @click.native="customizeVisible = false"
        />
        <div class="spacer h3"></div>

        <h3>Customize</h3>
        <div class="spacer h3"></div>
        <p>Background Image:</p>
        <div class="spacer h1"></div>
        <input type="text" :value="page.backgroundImage" @change="changeBackgroundImage" />

        <div class="spacer h3"></div>
        <p>Youtube:</p>
        <div class="spacer h1"></div>
        <input type="text" @change="changeYoutube" />

        <div class="spacer h1"></div>
        <ColorPicker
          label="Overlay Color"
          :color="overlayColor"
          @color-picked="overlayColorChanged"
        />
        <Slider
          label="Opacity"
          :min="0"
          :max="100"
          :step="1"
          :value="overlayOpacity * 100"
          @change="overlayOpacityChanged"
        />

        <Alert :alert="customizeAlert" @dismiss="customizeAlert = null" />
      </div>



      <Modal
        v-if="deleteModalVisible"
        :message='`Delete section \"${page.sections[deletingSectionIndex].title}\"?`'
        :buttons="['yes', 'no']"
        @modal-yes="deleteSection(deletingSectionIndex)"
        @modal-no="deleteModalVisible = false"
      />

      <Modal
        v-if="imageModalVisible"
        message="Select an image source."
        :buttons="['upload', 'link', 'cancel']"
        @modal-upload="openImageSelect"
        @modal-link="openURLInput"
        @modal-cancel="imageModalVisible = false"
      />

      <ModalURLInput
        v-if="imageURLInputVisible"
        :buttons="['add', 'cancel']"
        @modal-add="addURLImages"
        @modal-cancel="imageURLInputVisible = false"
      />
    </div>

  </div>
</template>



<script>
  import ImmersiveBackground from '../ImmersiveBackground.vue';
  import exampleWorld from '@/components/World/example_world'

  export default {
    name: 'WikiPage',
    components: {
      ImmersiveBackground
    },

    data() {
      return {
        comingFromNew: false,

        page: null,
        isLoadingImages: false,

        newImages: [],

        editingHeader: false,
        editingHeaderFields: false,
        editingSection: null,

        imageModalVisible: false,
        imageURLInputVisible: false,

        deleteModalVisible: false,
        deletingSectionIndex: null,

        customizeVisible: false,
        customizeAlert: null,

        youtubeHTML: null,
        isYoutubeLocked: false,
        overlayColor: '#414145',
        overlayOpacity: 0.1,

        isPreviewing: false
      }
    },

    computed: {
      // page() {
      //   return this.$store.state.wikiPages[this.$route.params.id];
      // },

      isAuthor() {
        return this.$store.state.user?._id ==  this.page?.authorID
      },

      backgroundImage() {
        if (!this.page) return;
        return this.page.backgroundImage || 'https://media.scrybe-app.com/assets/mountains_01.jpg';
      },

      mainImage() {
        if (!this.page?.images?.length) return null
        return this.page.images[0]
      },

      otherImages() {
        return this.page?.images?.length > 1 ? this.page.images.slice(1) : []
      }
    },

    methods: {
      openImageSelect() {
        let fileSelect = document.getElementById('imageFileSelect');
        fileSelect.click();
        this.imageModalVisible = false;
      },

      openURLInput() {
        this.imageModalVisible = false;
        this.imageURLInputVisible = true;
      },

      addURLImages(inputs) {
        this.imageURLInputVisible = false;

        let images = [...this.page.images, ...inputs];
        this.updateWiki('images', images);
      },

      selectImage() {
        if (event.target.files?.length) {
          let formData = new FormData();
          formData.append('wiki', event.target.files[0], event.target.files[0].name);

          this.isLoadingImages = true
          this.$store.dispatch('uploadWikiImage', {
            wikiID: this.page._id,
            image: formData
          }).then(response => {
            this.refreshWiki()
            this.isLoadingImages = false
          });
        }
      },

      toggleImageVisibility(index) {
        let images = [...this.page.images];
        images[index].visibility = images[index].visibility == 'private' ? 'public' : 'private'
        this.page.images = images;
        this.updateWiki('images', images)
      },

      async deleteImage(image) {
        // let images = [...this.page.images];
        // images.splice(index, 1);
        // this.page.images = images;

        const response = await this.$store.dispatch('deleteWikiImage', {
          wikiID: this.page._id,
          image
        })
        this.refreshWiki()
      },

      newHeaderField() {
        let newPage = { ...this.page }
        if (!newPage.headerFields?.length) newPage.headerFields = []

        newPage.headerFields.push({
          field: '',
          value: ''
        });

        this.updateWiki('headerFields', newPage.headerFields);
      },

      deleteHeaderField(index) {
        let newPage = { ...this.page }
        newPage.headerFields.splice(index, 1);

        this.updateWiki('headerFields', newPage.headerFields);
      },

      fieldChanged(field) {
        let object = this.page[field];
        this.updateWiki(field, object);
      },

      newSection() {
        if (!this.page?.sections?.length) this.page.sections = []

        this.page.sections.push({
          title: 'New Section',
          content: '...'
        });

        this.updateWiki('sections', this.page.sections);
      },

      showDeleteSection(index) {
        this.deletingSectionIndex = index;
        this.deleteModalVisible = true;
      },

      deleteSection(index) {
        let newPage = { ...this.page }
        newPage.sections.splice(index, 1);

        this.updateWiki('sections', newPage.sections);
        this.deleteModalVisible = false;
      },

      moveUp(index) {
        if (index === 0) return;

        let sections = [...this.page.sections];
        let origin = sections[index];
        sections[index] = sections[index - 1];
        sections[index - 1] = origin;

        this.updateWiki('sections', sections);
      },

      moveDown(index) {
        if (index === this.page.sections.length - 1) return;

        let sections = [...this.page.sections];
        let origin = sections[index];
        sections[index] = sections[index + 1];
        sections[index + 1] = origin;

        this.updateWiki('sections', sections);
      },

      changeBackgroundImage() {
        let input = event.target.value;
        if (input.startsWith('http://') || input.startsWith('https://')) {
          this.updateWiki('backgroundImage', input);
        }
      },

      changeYoutube() {
        let input = event.target.value;
        if (!input.trim()) {
          this.youtubeHTML = null;
          return;
        }
        if (!input.startsWith('https://www.youtube.com') && !input.startsWith('https://youtu.be')) {
          this.customizeAlert = { type: 'warning', message: 'Link is not from youtube.' };
          return;
        }

        let youtubeURL = input;
        fetch(`https://www.youtube.com/oembed?url=${youtubeURL}&format=json`).then(response => response.json()).then(response => {
          let html = response.html;
          let url = /src=\"(.*?)\"/.exec(html)[1];
          if (url) {
            let newURL = url + '&autoplay=1';
            html = html.replace(url, newURL);
          }

          let width = /width=\"(.*?)\"/.exec(html);
          let newWidth = width[0];
          newWidth = newWidth.replace(width[1], '100%');
          html = html.replace(width[0], newWidth);

          let height = /height=\"(.*?)\"/.exec(html);
          let newHeight = height[0];
          newHeight = newHeight.replace(height[1], '100%');
          html = html.replace(height[0], newHeight);

          this.youtubeHTML = html;
        }).catch(error => {
          this.customizeAlert = { type: 'error', message: 'Invalid link.' };
        });
      },

      overlayColorChanged(color) {
        this.overlayColor = color;
      },

      overlayOpacityChanged(input) {
        this.overlayOpacity = parseInt(input) / 100;
        console.log(this.overlayOpacity);
      },



      onSectionTitleInput(section) {
        section.title = event.target.value
      },

      onSectionContentInput(section) {
        section.content = event.target.value
      },



      async updateWiki(field, object) {
        let params = {
          [field]: object
        }

        await this.$store.dispatch('updateWiki', {
          wikiID: this.page._id,
          pageParams: params
        })
        this.refreshWiki()
      },


      refreshWiki() {
        this.$store.dispatch('fetchWiki', this.$route.params.id).then(response => {
          if (response.status == 200) {
            this.page = response.data;
          }
        });
      }





      // formatSectionClass(index, id) {
      //   if (this.arrangeTargetIndex == null) return '';
      //   let string = 'dragging';
      //   if (id === 'placeholder') string += ' placeholder';
      //   return string;
      // },
      //
      // onMouseMove() {
      //   this.mouseX = event.clientX;
      //   this.mouseY = event.clientY;
      // },
      //
      // sectionMouseOver(index) {
      //   if (this.arrangeTargetIndex == null) return;
      //   // console.log(index);
      //   this.updatePlaceholderIndex(index);
      // },
      //
      // startDragging(section, index) {
      //   this.clickX = event.offsetX;
      //   this.clickY = event.offsetY;
      //   this.arrangeTargetIndex = index;
      //   this.placeholderID = section._id;
      //   this.updateArrangeModeSections();
      // },
      //
      // stopDragging(section) {
      //   if (this.arrangeTargetIndex == null) return;
      //
      //   let sections = JSON.parse(JSON.stringify(this.arrangeModeSections));
      //   let placeholder = sections.find(section => section._id == 'placeholder');
      //   sections = sections.filter(section => section._id != 'placeholder');
      //   // sections.splice(index, 0, placeholder);
      //   let draggingSection = sections[this.arrangeTargetIndex];
      //   let page = JSON.parse(JSON.stringify(this.$store.state.currentWikiPage));
      //   page.sections = sections;
      //   this.$store.commit('updateCurrentWikiPage', page);
      //
      //   this.arrangeTargetIndex = null;
      //   this.placeholderID = null;
      //   this.draggingSectionID = null;
      // },
      //
      // updateArrangeModeSections() {
      //   let newSections = JSON.parse(JSON.stringify(this.$store.state.currentWikiPage.sections));
      //
      //   // Copy target section and use as dragging piece
      //   let draggingSection = {...newSections[this.arrangeTargetIndex]};
      //   this.draggingSectionID = draggingSection._id;
      //
      //   // Push the dragging piece onto the end
      //   newSections.push(draggingSection);
      //
      //   // console.log("dragging:", newSections.map(section => section._id));
      //   this.arrangeModeSections = newSections;
      // },
      //
      // updatePlaceholderIndex(newIndex) {
      //   if (this.arrangeTargetIndex == null) return;
      //
      //   let sections = JSON.parse(JSON.stringify(this.arrangeModeSections));
      //   console.log("before update:", [...sections].map(s => s.title));
      //   let placeholder = sections.find(section => section._id == this.placeholderID);
      //
      //   // Remove placeholder
      //   sections = sections.filter(section => section._id != this.placeholderID);
      //   console.log("after deletion:", [...sections].map(s => s.title));
      //
      //   // Insert placeholder at new location
      //   sections.splice(newIndex, 0, placeholder);
      //   console.log("after insertion:", [...sections].map(s => s.title));
      //
      //   this.arrangeModeSections = sections;
      //   console.log("after update:", sections.map(section => section.title), this.arrangeTargetIndex);
      // }
    },

    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (from.name == 'NewWiki') vm.comingFromNew = true
      })
    },

    created() {
      if (this.$route.params.id == 'b1928a09384' || this.$route.params.id == 'a783400abdda') {
        this.page = exampleWorld.wikis.find(wiki => wiki._id == this.$route.params.id);
      }
      else {
        this.refreshWiki()
      }
    },

    mounted() {
      // window.addEventListener('mousemove', this.onMouseMove);
      // window.addEventListener('mouseup', this.stopDragging);
    },

    destroyed() {
      // window.removeEventListener('mousemove', this.onMouseMove);
      // window.removeEventListener('mouseup', this.stopDragging);
    }
  }
</script>



<style scoped lang="scss">

  .wiki-page-container {
    position: relative;
    @include flex($direction: column, $align: center);
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 2rem 3rem;
    padding-bottom: 2rem;
    box-sizing: border-box;
  }

  .back-button {
    top: 2rem;
    left: 2rem;
  }

  .wiki-page {
    position: relative;
    display: grid;
    grid-template-areas:
      "header images"
      "fields images"
      "sections sections"
      "footer footer";
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 2rem 2rem;
    max-width: 900px;
    margin-top: 4rem;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: rgba(0,0,0,.5);

    .header {
      grid-area: header;
      width: 100%;
      min-width: 50%;

      > * {
        text-align: left;
      }

      h1 {
        margin-bottom: 1rem;
        font-size: 1.75rem;
      }

      p {
        font-size: 0.9rem;
        font-weight: 400;
      }

      textarea {
        resize: vertical;
      }
    }

    .sections {
      position: relative;
      grid-area: sections;

      section {
        position: relative;
        padding-top: 1rem;
        padding-bottom: 3rem;

        &.dragging:last-child {
          position: fixed;
          max-width: calc(100vw - 10rem);
          padding: 0 5rem;
          z-index: -99;
        }

        &.placeholder {
          opacity: 0.2;
        }

        &:not(:last-child) {
          margin-bottom: 2rem;
        }

        h2 {
          margin-bottom: 0.5rem;
          color: #fff;
          font-size: 1.1rem;
          // font-weight: 400;
          text-transform: uppercase;
        }

        hr {
          border: 0;
          border-bottom: 1px solid rgba(255,255,255,.5);
        }

        p {
          color: #ccc;;
        }

        textarea {
          resize: vertical;
        }
      }
    }
  }

  .sections section, .header, .fields {
    position: relative;
    border-radius: 0.5rem;

    &:hover {
      .buttons {
        opacity: 1;
      }
    }
  }

  p {
    white-space: pre-line;
  }

  .buttons {
    position: absolute;
    @include flex($justify: flex-end);
    top: 0;
    right: 0;
    padding: 0.5rem;
    opacity: 0;

    > * {
      margin: 0 0.25rem;
    }
  }

  .delete-section-button {
    &:hover {
      background-color: $alert-error-color;
    }
  }

  .images {
    position: relative;
    grid-area: images;
    @include flex($direction: column, $align: flex-end);
    width: 100%;
  }

  .image-scroll-container {
    position: relative;
    @include flex();
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    // ::-webkit-scrollbar {
    //   width: 10px;
    //   background-color: rgba(0,0,0,.25);
    // }
    // ::-webkit-scrollbar-thumb {
    //   background-color: rgba(255,255,255,.1);
    //   border-radius: 5px;
    // }

    &::-webkit-scrollbar {
      height: 10px;
      background-color: rgba(0,0,0,.5);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255,255,255,.25);

      &:hover {
        background-color: rgba(255,255,255,.35);
      }
    }
  }

  .image-container {
    position: relative;
    height: 120px;
    width: 120px;
    object-fit: cover;
    flex-shrink: 0;

    &.main-image {
      height: unset;
      width: 100%;
    }

    img {
      height: 100%;
      width: 100%;
    }

    .visibility-button {
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
      opacity: 0;
    }

    .delete-button {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      opacity: 0;
    }

    &:hover {
      .delete-button, .visibility-button {
        opacity: 1;
      }
    }
  }

  .add-button {
    display: inline-flex;
    margin-top: 1rem;
    border-radius: 0.5rem;
    color: $colorA;

    &:hover {
      cursor: pointer;
      background-color: rgba(0,0,0,.5);
    }
  }


  .fields {
    grid-area: fields;
    border: 1px solid rgba(255,255,255,.25);

    .fields-table, .fields-inputs {
      display: table;

      .field-item, .field-input-item {
        display: table-row;

        p {
          font-size: 1rem;
          text-align: left;
        }

        span {
          color: $colorC;
          text-align: right;
        }

        input {

        }
      }
    }

    .fields-table {
      border-spacing: 1rem;
    }

    .fields-inputs {
      padding: 0.5rem;
      border-spacing: 0.5rem;
    }
  }

  .fields-inputs {
    @include flex();
    margin-bottom: 0.5rem;

    > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  #imageFileSelect {
    display: none;
  }

  .customize-container {
    position: fixed;
    @include flex($direction: column);
    top: 0;
    right: 0;
    width: 280px;
    height: 100vh;
    padding: 1rem;
    background-color: rgba(0,0,0,.5);
    z-index: 99;
    transform: translateX(280px);
    transition: transform 0.5s;
    // backdrop-filter: blur(16px);

    &.open {
      transform: translateX(0);
    }
  }

  // .customize-button {
  //   position: relative;
  //   top: 50%;
  //   right: calc(50% + 3rem);
  //   border-radius: 0.5rem;
  //   padding: 1rem;
  //   color: $colorA;
  //   transform: translateY(-50%);
  //
  //   &:hover {
  //     cursor: pointer;
  //     background-color: rgba(0,0,0,.5);
  //   }
  // }

</style>
