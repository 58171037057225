<template>
  <div :class="`draft-saved-hint ${determineIfHidden}`">
    <p :class="`${formatStatusClass}`">{{ formatStatus }}</p>
  </div>
</template>


<script>
  var tick;
  const DRAFT_SAVED_DELAY = 500;
  const CHECK_INTERVAL = 250;

  export default {
    name: 'DraftSaved',
    props: {
      lastInput: Number,
      emitName: [String],
      manuallySavingChanges: [Number],
      confirmSaved: [Boolean]
    },
    data() {
      return {
        status: null
      }
    },
    computed: {
      determineIfHidden() {
        return '';
      },
      formatStatusClass() {
        switch (this.status) {
          case 0: // Input received
            return 'typing';
          case 1: // Crossed delay threshold
            return 'saving';
          case 2: // Sent emit
            return 'saving';
          case 3: // Saved confirmed
            return 'saved';
          default:
            return 'nothing'
        }
      },
      formatStatus() {
        switch (this.status) {
          case 0: // Input received
            return 'Unsaved changes.';
          case 1: // Crossed delay threshold
            return 'Saving changes...';
          case 2: // Sent emit
            return 'Saving changes...';
          case 3: // Saved confirmed
            return 'Saved.';
          default:
            return 'No changes made.'
        }
      }
    },
    methods: {
      checkForInput() {
        if (this.confirmSaved) {
          this.status = 3;
          return;
        }

        if (!this.lastInput) {
          this.status = null;
          return;
        }

        let timeSinceLastInput = Date.now() - this.lastInput;

        // If automatic
        if (this.manuallySavingChanges == undefined) {
          if (timeSinceLastInput >= DRAFT_SAVED_DELAY) {
            if (this.status < 2) {
              if (this.emitName) this.$emit(this.emitName);
              this.status = 2;
            }
          }
          else {
            this.status = 0;
          }
        }
        // If manual
        else {
          if (!this.manuallySavingChanges) {
            this.status = 0;
          }
          else {
            if (this.status < 2) this.status = 2;
          }
        }

      }
    },
    mounted() {
      tick = setInterval(this.checkForInput, CHECK_INTERVAL);
    },
    destroyed() {
      window.clearInterval(tick);
    }
  }
</script>


<style scoped lang="scss">

  .draft-saved-hint {
    p {
      font-size: 0.75rem;
      font-weight: 600;

      &.nothing {
        color: #888;
      }

      &.typing {
        color: #ad5766;
      }

      &.saving {
        color: #dfb66d;
      }

      &.saved {
        color: #5ac27a;
      }
    }

    &.hidden {
      display: none;
    }
  }

</style>
