<template>
  <div class="landing-section" id="languages">

    <div class="section-bg">
      <img class="bg-image" src="https://media.scrybe-app.com/assets/landing/languages_background.jpeg" />
    </div>

    <h1>Languages (Coming Soon!)</h1>


    <div class="section-content">

      <div class="showcase-image">
        <AsyncImage src="https://media.scrybe-app.com/assets/landing/languages_screenshot.png" />
      </div>

      <div class="content-header">
        <h2>Immerse yourself in your own language.</h2>
        <p>For those who love inventing languages, this is paradise. Create words, etymological stems, grammar rules, and even your own writing system.</p>
      </div>

      <div class="points">
        <div>
          <h4><Icon name="pointer" size="1rem" />Type in Your Language</h4>
          <p>Upload your own glyphs and runes, map them to their own key or key combinations, and begin writing in your own language. All in a few clicks.</p>
        </div>
        <div>
          <h4><Icon name="link" size="1rem" />Use Across Your World (Coming Soon!)</h4>
          <p>Integrate your language into other Elements of your world via linked words and phrases, providing translations and live updating all across your world.</p>
        </div>
      </div>

    </div>

  </div>
</template>


<script>
  export default {
    name: 'LandingLanguages',
  }
</script>


<style scoped lang="scss">

  #languages {
    margin-bottom: -60px;
    padding-top: 10rem;
    padding-bottom: 14rem;

    .icon {
      color: $languages-color;
    }

    .section-bg {
      background-color: #1f1d3e;
      clip-path: polygon(0 60px, 100% 0, 100% 100%, 0 calc(100% - 60px));
      .bg-image {
        mask-image: radial-gradient(circle at center right, rgba(0,0,0,.75), rgba(0,0,0,.25) 25%, rgba(0,0,0,0) 75%);
      }
    }

    .section-content{
      grid-template-areas:
        "image header"
        "image points";
    }

    .content-header {
      text-align: right;
      margin-left: auto;
    }

    .showcase-image .async-image {
      width: 600px;
      height: 380px;
      transform: perspective(25cm) rotate3d(3, 5, -1, 20deg);
    }

    .points {
      text-align: right;

      h4 {
        justify-content: flex-end;
      }

      > * {
        margin-left: auto;
      }
    }
  }

</style>
