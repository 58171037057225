<template>
  <div>

    <!-- <ThemeToggle class="theme-toggle"
      :isOn="theme() === 'dark'"
      @on="$store.commit('changeTheme', 'dark')"
      @off="$store.commit('changeTheme', 'light')" /> -->

    <div v-if="!$store.state.userFetched" class="loading">
      <Loader />
    </div>


    <template v-if="user">
      <div class="spacer"></div>

      <template v-if="!isFirstLogin">
        <h2 class="colorful-title">{{ `Welcome back, ${user.name}!` }}</h2>
      </template>
      <template v-else>
        <h2 class="colorful-title">{{ `Welcome to Scrybe, ${user.name}!` }}</h2>
      </template>
      <div class="spacer h3"></div>


      <div class="ask-feedback floating">
        <h4>We would love your feedback.</h4>
        <div class="spacer"></div>
        <p>We want our users to be able to shape Scrybe to fit their needs.</p>
        <br />
        <p>What features are you looking for?</p>
        <p>What confuses or bothers you about the site?</p>
        <p>What do you like about Scrybe?</p>
        <div class="spacer"></div>
        <Button
          icon="check"
          label="Share your thoughts"
          class="secondary inline floating"
          @click.native="$router.push('/support')"
        />
      </div>


      <template v-if="isBlocked">
        <Message
          text="Your account has been blocked."
          type="error"
        />
        <Button
          label="More Info"
          icon="right-arrow"
          @click.native="$router.push('/dashboard/violations')"
        />
      </template>


      <template v-else>
        <div class="spacer h5"></div>

        <div class="row spaced center">
          <div :class="`account-type floating ${formatAccountType(user).class}`">
            <h5>Account Type</h5>
            <p>{{ formatAccountType(user).string }}</p>
          </div>

          <div class="account-type floating">
            <h5>Subscription Status</h5>
            <p :class="`subscription ${user.subscription_status || ''}`">{{ formatSubscriptionStatus(user.subscription_status) }}</p>
          </div>
        </div>

        <Permissions />

        <!-- <template v-if="isFirstLogin">
          <h2>{{ `Welcome to Scrybe, ${user.name}!` }}</h2>

          <p>Below is the Cycle of Creativity.</p>
          <p>Click on any of the three phases to begin.</p>
          <div class="spacer"></div>
          <p>You can also access them any time from the menu bar at the top of the screen.</p>
          <div class="spacer"></div>

          <div class="welcome">
            <CoreThree />
          </div>
        </template> -->
      </template>


      <div class="spacer h3"></div>
      <CoolButton type="compact"
        label="See Demo World"
        icon="globe"
        animation="infinite"
        textColor="#ffea9f"
        bgColor="rgb(254,204,93)"
        @click.native="$router.push('/world/example')"
      />

      <div class="spacer h3"></div>
      <CoolButton type="compact"
        label="Logout"
        animation="infinite"
        textColor="#9faeff"
        bgColor="rgb(93,100,254)"
        @click.native="logout"
      />
    </template>

  </div>
</template>

<script>
  import Sidebar from './Sidebar.vue'
  import Permissions from './Permissions.vue'
  import promotion from '../../../config/promotion'

  export default {
    name: 'DashboardHome',
    components: {
      Sidebar,
      Permissions
    },

    computed: {
      user() {
        return this.$store.state.user
      },
      isFirstLogin() {
        return this.user?.numLogins === 1
      },
      isBlocked() {
        return this.user?.accountStatus == 'blocked'
      }
    },

    methods: {
      checkUser() {
        if (this.$store.state.userFetched && !this.$store.state.user) {
          this.$router.push('/login');
        }
      },

      formatAccountType(user) {
        const joined_ms = (new Date(user.dateJoined)).getTime()
        const promotion_ms = (new Date(promotion.promotionEndDate)).getTime()

        // const date = (new Date(1667985270 * 1000))
        const date = (new Date('11-30-2022 18:14:30'))
        console.log(date.getTime() / 1000);

        if (joined_ms < promotion_ms) {
          return {
            class: 'beta',
            string: 'Beta Test User'
          }
        }
        else {
          return {
            class: 'normal',
            string: 'Normal User'
          }
        }
      },

      formatSubscriptionStatus(status) {
        switch (status) {
          case 'active':
            return 'Active';
          case 'set_to_cancel':
            return 'Set to Cancel';
          case 'expired':
            return 'Expired';
          default:
            return '--'
        }
      },

      logout() {
        this.$store.dispatch('logout');
        this.$router.push('/login');
      }
    },

    mounted() {
      this.checkUser();
    },

    updated() {
      this.checkUser();
    }
  }
</script>

<style scoped lang="scss">

  .ask-feedback {
    @include flex($direction: column, $justify: flex-start);
    max-width: 600px;
    margin: 0 2.5rem;
    padding: 2rem 3rem;
    border-radius: 0.5rem;
    background-color: bg(20%);
    // border: 1px solid rgba($colorA, 0.5);
    font-size: 0.9rem;
    text-align: center;

    h4 {
      color: $colorA;
      font-size: 1rem;
    }
  }

  .colorful-title {
    font-family: 'Cinzel Decorative', Avenir;
    font-weight: 500;
    font-size: 2rem;
  }

  .account-info-grid {
    @include flex($align: flex-start);
  }

  .account-info {
    @include flex($direction: column, $justify: flex-start);
    margin-right: 1rem;

    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .account-type {
    position: relative;
    padding: 1rem 1.5rem;
    background-color: bg(20%);
    border-radius: 0.25rem;

    h5 {
      margin-bottom: 0.25rem;
      color: $hint;
      font-weight: 400;
    }

    p {
      color: neutral(80%);
      font-weight: 600;
      white-space: pre;
      text-transform: capitalize;
    }

    &.beta p {
      color: $colorD;
    }

    .subscription {
      &.active {
        color: $alert-success-color;
      }
      &.set_to_cancel {
        color: $alert-warning-color;
      }
      &.expired {
        color: $alert-error-color;
      }
    }
  }

  .welcome {
    @include flex($direction: column);
    padding: 2rem 4rem;
    border-radius: 0.5rem;
    background-color: bg(20%);
    box-shadow: 0 12px 30px -20px rgba(0,0,0,1);
  }

  .theme-toggle {
    display: block;
    margin: auto;
  }

  .new-login {
    @include flex($direction: column);
  }

</style>
