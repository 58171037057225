<template>
  <div class="page">

    <div class="spacer h3"></div>
    <h1>Pricing</h1>
    <div class="spacer h4"></div>


    <!-- <div class="pricing-cards-container">
      <div class="pricing-card floating">
        <img :src="require('@/assets/icons/writing_icon.png')" />
        <div class="spacer h1"></div>
        <h4>Writings</h4>
        <div class="spacer"></div>
        <div class="price">
          <p class="amount"><span>$</span>5</p>
          <p class="per-month">/ month</p>
        </div>
      </div>

      <div class="pricing-card floating">
        <img :src="require('@/assets/icons/empty_icon.png')" />
        <div class="spacer h1"></div>
        <h4>Wikis</h4>
        <div class="spacer"></div>
        <div class="price">
          <p class="amount"><span>$</span>5</p>
          <p class="per-month">/ month</p>
        </div>
      </div>

      <div class="pricing-card floating">
        <img :src="require('@/assets/icons/empty_icon.png')" />
        <div class="spacer h1"></div>
        <h4>Timelines</h4>
        <div class="spacer"></div>
        <div class="price">
          <p class="amount"><span>$</span>5</p>
          <p class="per-month">/ month</p>
        </div>
      </div>

      <div class="pricing-card floating">
        <img :src="require('@/assets/icons/map_icon.png')" />
        <div class="spacer h1"></div>
        <h4>Maps</h4>
        <div class="spacer"></div>
        <div class="price">
          <p class="amount"><span>$</span>5</p>
          <p class="per-month">/ month</p>
        </div>
      </div>

      <div class="pricing-card floating">
        <img :src="require('@/assets/icons/empty_icon.png')" />
        <div class="spacer h1"></div>
        <h4>Languages</h4>
        <div class="spacer"></div>
        <div class="price">
          <p class="amount"><span>$</span>5</p>
          <p class="per-month">/ month</p>
        </div>
      </div>
    </div> -->


    <!-- <div class="spacer h5"></div> -->




    <!-- <div class="description">
      <p>Our pricing policy:</p>
      <p class="highlight">You only pay for what you use.</p>
      <div class="spacer"></div>
      <p>Scrybe's pricing features a waterfall discount system, where subscribing to additional elements becomes increasingly chepaer the more you own.</p>
      <div class="spacer"></div>
      <p>Each element is an individual subscription, so you can pause your subscriptions to any elements you aren't using to avoid paying for unused services. Additionally, if you've forgotten to pause your subscription, just contact <router-link to="/support">Support</router-link> and we're happy to refund any unused subscriptions.</p>
      <div class="spacer"></div>
      <p>To put it another way:</p>
      <p class="highlight">You don't pay for what you don't use.</p>
    </div>

    <div class="spacer h4"></div>

    <div class="pricing-table styled-table floating">
      <div class="headers">
        <div></div>
        <div>
          <div class="element">
            <img :src="require('@/assets/icons/writing_icon.png')" />
            <p class="writings">Writings</p>
          </div>
        </div>
        <div>
          <div class="element">
            <img :src="require('@/assets/icons/empty_icon.png')" />
            <p class="wikis">Wikis</p>
          </div>
        </div>
        <div>
          <div class="element">
            <img :src="require('@/assets/icons/empty_icon.png')" />
            <p class="timelines">Timelines</p>
          </div>
        </div>
        <div>
          <div class="element">
            <img :src="require('@/assets/icons/map_icon.png')" />
            <p class="maps">Maps</p>
          </div>
        </div>
        <div>
          <div class="element">
            <img :src="require('@/assets/icons/empty_icon.png')" />
            <p class="languages">Languages</p>
          </div>
        </div>
      </div>

      <div class="table-row">
        <p class="free">Free</p>
        <div>
          <Icon
            name="check"
            class="free"
          />
        </div>
        <div>
          <Icon
            name="check"
            class="free"
          />
        </div>
        <p>--</p>
        <p>--</p>
        <p>--</p>
      </div>

      <div class="table-row">
        <div>
          <p class="hint" style="margin-bottom: 0.5rem;">One Element</p>
          <div class="price">
            <p class="amount"><span>$ </span>5</p>
            <p class="per-month">/ mo</p>
          </div>
        </div>
        <div>
          <Icon name="check" />
        </div>
        <div>
          <Icon name="check" />
        </div>
        <td colspan="3">
          <div class="row" style="margin-bottom: 0.5rem;">
            <Icon
              name="check"
              class="free"
            />
          </div>
          <p>Choose One</p>
        </td>
      </div>

      <div class="table-row">
        <div>
          <p class="hint" style="margin-bottom: 0.5rem;">Two Elements</p>
          <div class="price">
            <p class="amount"><span>$ </span>9</p>
            <p class="per-month">/ mo</p>
          </div>
          <p class="discount">- 10%</p>
        </div>
        <div>
          <Icon
            name="check"
            class="free"
          />
        </div>
        <div>
          <Icon
            name="check"
            class="free"
          />
        </div>
        <td colspan="3">
          <div class="row" style="margin-bottom: 0.5rem;">
            <Icon
              name="check"
              class="free"
            />
            <div style="margin: 0 0.5rem;"></div>
            <Icon
              name="check"
              class="free"
            />
          </div>
          <p>Choose Two</p>
        </td>
      </div>

      <div class="table-row">
        <div>
          <p class="hint" style="margin-bottom: 0.5rem;">All Elements</p>
          <div class="price">
            <p class="amount"><span>$ </span>12</p>
            <p class="per-month">/ mo</p>
          </div>
          <p class="discount">- 20%</p>
        </div>
        <div>
          <Icon
            name="check"
            class="free"
          />
        </div>
        <div>
          <Icon
            name="check"
            class="free"
          />
        </div>
        <div>
          <Icon
            name="check"
            class="free"
          />
        </div>
        <div>
          <Icon
            name="check"
            class="free"
          />
        </div>
        <div>
          <Icon
            name="check"
            class="free"
          />
        </div>
      </div>
    </div> -->




    <div class="description">
      <p>Our pricing policy:</p>
      <p class="highlight">You only pay for what you use.</p>
      <div class="spacer"></div>
      <p>Scrybe's pricing features a waterfall discount system, where subscribing to additional elements becomes increasingly chepaer the more you own.</p>
      <div class="spacer"></div>
      <p>You can add or remove elements from your subscription at any time. If you have any problems or aren't satisfied with the product, just contact <router-link to="/support">Support</router-link> and we're happy to refund your subscription.</p>
      <div class="spacer"></div>
      <p>At Scrybe, <span class="highlight">you don't pay for what you don't use.</span></p>
    </div>

    <div class="spacer h4"></div>

    <div class="pricing-table styled-table floating">
      <div class="headers">
        <div></div>
        <div>
          <div class="element">
            <img src="https://media.scrybe-app.com/assets/elements/writing_icon.png" />
            <p class="writings">Writings</p>
          </div>
        </div>
        <div>
          <div class="element">
            <img src="https://media.scrybe-app.com/assets/elements/empty_icon.png" />
            <p class="wikis">Wikis</p>
          </div>
        </div>
        <div>
          <div class="element">
            <img src="https://media.scrybe-app.com/assets/elements/empty_icon.png" />
            <p class="timelines">Timelines</p>
          </div>
        </div>
        <div>
          <div class="element">
            <img src="https://media.scrybe-app.com/assets/elements/map_icon.png" />
            <p class="maps">Maps</p>
          </div>
        </div>
        <div>
          <div class="element">
            <img src="https://media.scrybe-app.com/assets/elements/empty_icon.png" />
            <p class="languages">Languages</p>
          </div>
        </div>
      </div>

      <div class="table-row">
        <p class="hint">0 Elements Owned</p>
        <p class="free">Free</p>
        <p class="free">Free</p>
        <div>
          <div class="price">
            <p class="amount"><span>$ </span>5</p>
            <p class="per-month">/ mo</p>
          </div>
        </div>
        <div>
          <div class="price">
            <p class="amount"><span>$ </span>5</p>
            <p class="per-month">/ mo</p>
          </div>
        </div>
        <div>
          <div class="price">
            <p class="amount"><span>$ </span>5</p>
            <p class="per-month">/ mo</p>
          </div>
        </div>
      </div>

      <div class="table-row">
        <p class="hint">1 Element Owned</p>
        <p>--</p>
        <p>--</p>
        <div>
          <div class="price">
            <p class="amount"><span>$ </span>4</p>
            <p class="per-month">/ mo</p>
          </div>
          <p class="discount">- 20%</p>
        </div>
        <div>
          <div class="price">
            <p class="amount"><span>$ </span>4</p>
            <p class="per-month">/ mo</p>
          </div>
          <p class="discount">- 20%</p>
        </div>
        <div>
          <div class="price">
            <p class="amount"><span>$ </span>4</p>
            <p class="per-month">/ mo</p>
          </div>
          <p class="discount">- 20%</p>
        </div>
      </div>

      <div class="table-row">
        <p class="hint">2 Elements Owned</p>
        <p>--</p>
        <p>--</p>
        <div>
          <div class="price">
            <p class="amount"><span>$ </span>3</p>
            <p class="per-month">/ mo</p>
          </div>
          <p class="discount">- 40%</p>
        </div>
        <div>
          <div class="price">
            <p class="amount"><span>$ </span>3</p>
            <p class="per-month">/ mo</p>
          </div>
          <p class="discount">- 40%</p>
        </div>
        <div>
          <div class="price">
            <p class="amount"><span>$ </span>3</p>
            <p class="per-month">/ mo</p>
          </div>
          <p class="discount">- 40%</p>
        </div>
      </div>

      <div class="table-row">
        <p class="hint">All Elements</p>
        <p>--</p>
        <p>--</p>
        <td colspan="3">
          <div class="price">
            <p class="amount"><span>$ </span>12</p>
            <p class="per-month">/ mo</p>
          </div>
          <p class="discount">- 20%</p>
        </td>
      </div>
    </div>


    <div class="spacer h5"></div>

  </div>
</template>

<script>
  export default {
    name: 'Pricing',
    components: {

    },
    data() {
      return {

      }
    },

    mounted() {
      this.$store.dispatch('access')
    }
  }
</script>

<style scoped lang="scss">

  .pricing-cards-container {
    @include flex($justify: center);
    flex-wrap: wrap;
  }

  .pricing-card {
    @include flex($direction: column);
    margin: 1rem;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: bg(20%);

    img {
      height: 60px;
    }

    h4 {
      text-transform: uppercase;
      font-weight: 300;
    }
  }


  .description {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }

  .highlight {
    color: $colorA;
  }

  a {
    color: $colorB;
    font-weight: 600;

    &:hover {
      color: $colorA;
    }
  }

  .writings {
    color: $writings-color;
  }
  .wikis {
    color: $wiki-color;
  }
  .timelines {
    color: $timelines-color;
  }
  .maps {
    color: $maps-color;
  }
  .languages {
    color: $languages-color;
  }

  .price {
    @include flex($justify: center, $align: flex-start);

    .amount {
      color: #16c994;
      font-size: 1.5rem;
      font-weight: 600;

      span {
        position: relative;
        top: -0.5em;
        color: #10c498;
        font-size: 1rem;
      }
    }

    .per-month {
      margin-top: 0.5rem;
      margin-left: 0.25rem;
      color: neutral(65%);
      font-size: 0.8rem;
    }
  }

  .discount {
    color: #e3c168;
    font-size: 0.8rem;
    // font-weight: 600;
    text-align: center;
    letter-spacing: 0.5px;
  }

  .free {
    color: #14e3a5;
  }


  .pricing-table {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: bg(15%);

    .element {
      @include flex($direction: column);
    }

    img {
      height: 60px;
    }

    .table-row > *:not(:last-child) {
      border-right: 1px solid #333;
    }

    /deep/ .icon {
      display: flex;
      justify-content: center;
      color: $colorB;
    }

    .table-row > * {
      padding: 1.5rem;
      text-align: center;
    }
  }

</style>
