<template>
  <div>

    <div class="spacer"></div>
    <h1>Visualization of Scrybe</h1>
    <div class="spacer h5"></div>


    <div class="diagram">

      <div class="module-container client-container">
        <div class="module" id="client">
          <Icon name="user" size="1.5rem" />
        </div>
        <p class="hint">This is you</p>
        <Bubble right :visible="bubble.module == 'client'">
          <p>{{ bubble.message }}</p>
        </Bubble>
      </div>

      <div class="module-container server-container">
        <div class="module" id="server">
          <Icon name="server" size="1.5rem" />
        </div>
        <p class="hint">Server</p>
        <Bubble left :visible="bubble.module == 'server'">
          <p>{{ bubble.message }}</p>
        </Bubble>
      </div>

      <div class="module-container database-container">
        <div class="module" id="database">
          <Icon name="database" size="1.5rem" />
        </div>
        <p class="hint">Database</p>
        <Bubble right :visible="bubble.module == 'database'">
          <p>{{ bubble.message }}</p>
        </Bubble>
      </div>

      <div class="module-container media-storage-container">
        <div class="module" id="media-storage">
          <Icon name="hdd" size="1.5rem" />
        </div>
        <p class="hint">Media Storage</p>
        <Bubble left :visible="bubble.module == 'media storage'">
          <p>{{ bubble.message }}</p>
        </Bubble>
      </div>

      <div class="module-container cdn-container">
        <div class="module" id="cdn">
          <Icon name="download" size="1.5rem" />
        </div>
        <p class="hint">CDN</p>
        <Bubble left :visible="bubble.module == 'cdn'">
          <p>{{ bubble.message }}</p>
        </Bubble>
      </div>

      <div class="module-container stripe-container">
        <div class="module" id="stripe">
          <Icon name="credit card" size="1.5rem" />
        </div>
        <p class="hint">Stripe</p>
        <Bubble right :visible="bubble.module == 'stripe'">
          <p>{{ bubble.message }}</p>
        </Bubble>
      </div>

      <div class="module-container sendgrid-container">
        <div class="module" id="sendgrid">
          <Icon name="bell" size="1.5rem" />
        </div>
        <p class="hint">SendGrid</p>
        <Bubble right :visible="bubble.module == 'sendgrid'">
          <p>{{ bubble.message }}</p>
        </Bubble>
      </div>




      <div class="api scrybe" :style="`opacity: ${scrybe_api.opacity};`">
        <div class="point" :style="`bottom: ${scrybe_api.point.progress}%; opacity: ${scrybe_api.point.opacity};`"></div>
      </div>

      <div class="api database" :style="`opacity: ${database_api.opacity};`">
        <div class="point" :style="`bottom: ${database_api.point.progress}%; opacity: ${database_api.point.opacity};`"></div>
      </div>

      <div class="api media-storage" :style="`opacity: ${media_storage_api.opacity};`">
        <div class="point" :style="`bottom: ${media_storage_api.point.progress}%; opacity: ${media_storage_api.point.opacity};`"></div>
      </div>

      <div class="api scrybe-media" :style="`opacity: ${scrybe_media_api.opacity};`">
        <div class="point" :style="`bottom: ${scrybe_media_api.point.progress}%; opacity: ${scrybe_media_api.point.opacity};`"></div>
      </div>

      <div class="api media-cdn" :style="`opacity: ${media_cdn_api.opacity};`">
        <div class="point" :style="`bottom: ${media_cdn_api.point.progress}%; opacity: ${media_cdn_api.point.opacity};`"></div>
      </div>

    </div>


    <div class="spacer h4"></div>
    <div class="row spaced">
      <template v-if="!playing">
        <Button
          label="Login"
          @click.native="loginAnimation"
        />
        <Button
          label="Load Map"
          @click.native="loadMapAnimation"
        />
      </template>

      <Button v-else
        label="Stop"
        class="delete"
        @click.native="stopAnimation"
      />
    </div>


  </div>
</template>

<script>
  import Bubble from './AppDiagramBubble.vue'

  export default {
    name: 'AppDiagram',
    components: {
      Bubble
    },

    data() {
      return {
        playing: false,

        bubble: {
          module: '',
          message: ''
        },

        scrybe_api: { opacity: 0, point: { progress: 0, opacity: 0 } },
        database_api: { opacity: 0, point: { progress: 0, opacity: 0 } },
        media_storage_api: { opacity: 0, point: { progress: 0, opacity: 0 } },
        scrybe_media_api: { opacity: 0, point: { progress: 0, opacity: 0 } },
        media_cdn_api: { opacity: 0, point: { progress: 100, opacity: 0 } }
      }
    },

    methods: {

      clearBubble() {
        this.bubble = { module: '', message: '' }
      },

      reset() {
        this.clearBubble()
        this.scrybe_api = { opacity: 0, point: { progress: 0, opacity: 0 } }
        this.database_api = { opacity: 0, point: { progress: 0, opacity: 0 } }
        this.media_storage_api = { opacity: 0, point: { progress: 0, opacity: 0 } }
        this.scrybe_media_api = { opacity: 0, point: { progress: 0, opacity: 0 } }
        this.media_cdn_api = { opacity: 0, point: { progress: 100, opacity: 0 } }
      },

      stopAnimation() {
        this.playing = false;
        this.reset()
      },



      pause(s) {
        if (!this.playing) return;
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(true)
          }, s * 1000)
        })
      },



      async loginAnimation() {
        this.playing = true;

        this.bubble = { module: 'client', message: 'Let me in. Here\'s my email and password.' }
        await this.pause(3);

        this.clearBubble();
        this.scrybe_api.opacity = 1;
        await this.pause(1);

        this.scrybe_api.point.opacity = 1;
        this.scrybe_api.point.progress = 100;
        await this.pause(2);

        this.bubble = { module: 'server', message: 'Hmm, let me check.' }
        await this.pause(2);

        this.clearBubble();
        this.database_api.opacity = 1;
        await this.pause(1);

        this.database_api.point.opacity = 1;
        this.database_api.point.progress = 100;
        await this.pause(1);

        this.bubble = { module: 'database', message: 'Yep, this guy\'s legit.' }
        await this.pause(2);

        this.clearBubble();
        this.database_api.point.progress = 0;
        await this.pause(1);

        this.database_api.point.opacity = 0;
        this.database_api.opacity = 0;
        this.bubble = { module: 'server', message: 'Ok, you\'re in. Here\'s your ID card.' }
        await this.pause(3);

        this.clearBubble();
        this.scrybe_api.point.progress = 0;
        await this.pause(2);

        this.scrybe_api.point.opacity = 0;
        this.scrybe_api.opacity = 0;

        this.playing = false;
      },



      async loadMapAnimation() {
        this.playing = true;

        this.bubble = { module: 'client', message: 'Give me the map called "abc".' }
        await this.pause(3);

        this.clearBubble();
        this.scrybe_api.opacity = 1;
        await this.pause(1);

        this.scrybe_api.point.opacity = 1;
        this.scrybe_api.point.progress = 100;
        await this.pause(2);

        this.bubble = { module: 'server', message: 'I need a map! Name is "abc".' }
        await this.pause(2);

        this.clearBubble();
        this.database_api.opacity = 1;
        await this.pause(1);

        this.database_api.point.opacity = 1;
        this.database_api.point.progress = 100;
        await this.pause(1);

        this.bubble = { module: 'database', message: 'Here you go, sir.' }
        await this.pause(2);

        this.clearBubble();
        this.database_api.point.progress = 0;
        await this.pause(1);

        this.database_api.point.opacity = 0;
        this.database_api.opacity = 0;
        this.bubble = { module: 'server', message: 'This is a private map. Let\'s see some ID...' }
        await this.pause(3);

        this.clearBubble();
        await this.pause(1);

        this.bubble = { module: 'server', message: 'Looks like you\'re the owner. Here you are.' }
        await this.pause(3);

        this.clearBubble();
        this.scrybe_api.point.progress = 0;
        await this.pause(2);

        this.scrybe_api.point.opacity = 0;
        this.scrybe_api.opacity = 0;
        await this.pause(1);

        this.bubble = { module: 'client', message: 'I need this image.' }
        await this.pause(2);

        this.clearBubble();
        this.scrybe_media_api.opacity = 1;
        await this.pause(1);

        this.scrybe_media_api.point.opacity = 1;
        this.scrybe_media_api.point.progress = 100;
        await this.pause(1);

        this.bubble = { module: 'media storage', message: 'Here you go.' }
        await this.pause(2);

        this.clearBubble();
        this.media_cdn_api.opacity = 1;
        await this.pause(1);

        this.scrybe_media_api.point.opacity = 1;
        this.scrybe_media_api.point.progress = 0;
        this.media_cdn_api.point.opacity = 1;
        this.media_cdn_api.point.progress = 0;
        await this.pause(1);

        this.scrybe_media_api.point.opacity = 0;
        this.scrybe_media_api.opacity = 0;
        await this.pause(1);

        this.bubble = { module: 'cdn', message: 'I\'ve got your image here, sir. Standing by.' }
        await this.pause(3);

        this.clearBubble();
        this.media_cdn_api.point.opacity = 0;
        this.media_cdn_api.opacity = 0;

        this.playing = false;
      }

    },

    mounted() {
      // const server = document.getElementById('server')
      // console.log(server.getBoundingClientRect());
    }
  }
</script>

<style scoped lang="scss">

  .diagram {
    position: relative;
    width: 540px;
    height: 540px;
    margin: 0 auto;
    // background-color: #333;
  }

  .module-container {
    position: absolute;
    @include flex($direction: column);

    &.client-container {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
    }

    &.server-container {
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.database-container {
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.media-storage-container {
      top: 35%;
      left: 0;
      transform: translate(-50%, -50%);
    }

    &.cdn-container {
      top: 75%;
      left: 0;
      transform: translate(-50%, -50%);
    }

    &.stripe-container {
      top: 75%;
      right: 0;
      transform: translate(50%, -50%);
    }

    &.sendgrid-container {
      top: 35%;
      right: 0;
      transform: translate(50%, -50%);
    }
  }

  .module {
    @include flex($justify: center);
    width: 60px;
    height: 60px;
    border: 2px solid #aaa;
    border-radius: 0.25rem;
    color: #fff;
  }

  .point {
    position: absolute;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #ccc;
    transform: translate(-50%, 50%);
  }

  .api {
    position: absolute;
    width: 2px;
    background-color: #555;
    transition: opacity 0.3s;

    .point {
      bottom: 0;
      transition: opacity 0.3s, bottom 1s;
    }

    &.scrybe {
      top: calc(35% + 50px);
      left: 50%;
      height: 240px;
      .point { transition: opacity 0.3s, bottom 2s; }
    }

    &.database {
      top: 50px;
      left: 50%;
      height: 80px;
    }

    &.media-storage {
      top: 90px;
      left: 133px;
      height: 180px;
      transform: rotate(90deg);
    }

    &.scrybe-media {
      top: 200px;
      left: 133px;
      height: 320px;
      transform: rotate(-40deg);
    }

    &.media-cdn {
      top: calc(35% + 50px);
      left: 0;
      height: 110px;
    }
  }

  .hint {
    text-align: center;
    text-transform: uppercase;
  }

</style>
