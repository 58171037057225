<template>
  <div class="link-dropdown">
    <p>Character</p>
    <p>Timeline Event</p>
    <p>Map</p>
    <p>Location</p>
  </div>
</template>


<script>
  export default {
    name: 'LinkDropdown',
  }
</script>


<style scoped lang="scss">

  .link-dropdown {
    position: absolute;
    top: 1.5em;
    left: -0.75rem;
    padding: 0.75rem;
    background-color: #000;
    border: 1px solid bg(30%);
    font-size: 0.9rem;
    z-index: 9;
  }

</style>
