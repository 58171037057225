<template>
  <div class="checkbox">
    <div
      :class="`box ${checked ? 'checked' : ''}`"
      :style="`background-color: ${checked ? (color || '#815bf0') : 'transparent'};`"
      @click="toggleCheck"
    >
      <Icon name="check" size="1rem"
        :class="`checkbox-icon ${checked ? 'icon-checked' : ''}`"/>
    </div>
    <p style="user-select: none;">{{ label }}</p>
  </div>
</template>

<script>
  import Icon from './Icon.vue';

  export default {
    name: 'Checkbox',
    props: {
      label: String,
      checked: Boolean,
      color: [String]
    },
    components: {
      Icon
    },
    methods: {
      toggleCheck() {
        this.$emit('checkbox-toggle');
      }
    }
  }
</script>

<style scoped lang="scss">

  .checkbox {
    @include flex();

    p {
      margin-left: 0.75rem;
    }
  }

  .box {
    @include flex($justify: center);
    width: 1.5rem;
    height: 1.5rem;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: transparent;
    border: 2px solid rgba(255,255,255,.5);
    transition: background 0.15s, border 0.15s;

    &:hover {
      cursor: pointer;
    }

    &.checked {
      background-color: #815bf0;
      border: 2px solid transparent;
    }
  }

  .checkbox-icon {
    color: #000;
    opacity: 0;
    transition: opacity 0.15s;

    &.icon-checked {
      opacity: 1;
    }
  }

</style>
