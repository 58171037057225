

import binaryData from './timeline_binary_data';
let timeline = binaryData;

let allNodes = [];
let test = {};
let testStart = null;
let testEnd = null;
let previousTestEnd = null;

const min = 3 * 365;
const max = 15 * 365;



function addNodeToArray(array, node) {
  let n = {
    title: node.title,
    universalDay: node.universalDay
  }
  array.push(n)

  if (node.right) addNodeToArray(array, node.right)
  if (node.left) addNodeToArray(array, node.left)
}

function binaryTreeToArray(root) {
  let array = []
  addNodeToArray(array, root)

  return array
}

function recursiveSearch(node, result, min, max) {
  if (node.universalDay <= max && node.universalDay >= min) {
    result.push({
      title: node.title,
      universalDay: node.universalDay
    })
  }

  // if not lower than min, search left
  if (node.left && node.universalDay >= min) {
    recursiveSearch(node.left, result, min, max)
  }
  // if not higher than max, search right
  if (node.right && node.universalDay <= max) {
    recursiveSearch(node.right, result, min, max)
  }

  nodeSearched()
}

function nodeSearched(title) {
  testEnd = Date.now().toString();
  // console.log(testEnd);
}

function arraySearch(array, min, max) {
  if (!array) return;
  let nodesInRange = []
  array.forEach(node => {
    if (node.universalDay >= min && node.universalDay <= max) {
      nodesInRange.push(node)
    }
    arraySearched()
  });
}

function arraySearched() {
  testEnd = Date.now().toString();
}

function testBinarySpeed() {
  const numTimes = 1000000;

  console.log('');
  console.log(`Testing binary ${numTimes} times...`);

  testStart = Date.now().toString();

  const root = timeline?.lines?.[0]?.root;
  let result = []
  for (let i = 0; i < numTimes; i++) {
    recursiveSearch(root, result, min, max)
  }
}

function testArraySpeed() {
  const numTimes = 1000000;

  console.log('');
  console.log(`Testing array ${numTimes} times...`);

  testStart = Date.now().toString();

  let result = []
  for (let i = 0; i < numTimes; i++) {
    arraySearch(allNodes, min, max)
  }
}

function checkIfDone() {
  let end = parseInt(testEnd);
  let previousEnd = parseInt(previousTestEnd);
  console.log(end, previousEnd);


  let isDone = end == previousEnd
  console.log('check');

  if (isDone) {
    console.log('done');
    console.log('start', testStart);
    console.log('end', testEnd);
    let difference = testEnd - testStart
    console.log('difference', difference);
    console.log('time', difference / 1000);
  }
  else {
    previousTestEnd = testEnd
    window.setTimeout(() => {
      checkIfDone()
    }, 1000)
  }
}




export default function() {
  const root = timeline?.lines?.[0]?.root;

  allNodes = binaryTreeToArray(root);
  console.log('all nodes', allNodes);

  console.log('min', min, 'max', max);

  let result = []
  recursiveSearch(root, result, min, max)

  console.log('result', result);



  // checkIfDone()
  // testBinarySpeed()
  // testArraySpeed()
}
