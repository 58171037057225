<template>
  <div
    ref="billboard"
    class="billboard"
    :style="`height: ${height + 60}px;`"
  >

    <div class="toolbar floating">
      <Button
        icon="left-arrow"
        class="large-button dark"
        @click.native="previousSlide"
      />
      <h1 class="title">{{ title }}</h1>
      <Button
        icon="right-arrow"
        class="large-button dark"
        @click.native="nextSlide"
      />
    </div>

    <div class="slides-container">
      <div
        ref="slides"
        class="slides"
      >
        <Slide v-for="(slot, num, index) in $slots"
          :position="circularPosition(index)"
          :slideWidth="slideWidth"
          :border="border"
          :firstInvisibleIndex="borderIndex"
          :key="num"
        >
          <slot :name="num"></slot>
        </Slide>
      </div>
    </div>

  </div>
</template>


<script>
  export default {
    name: 'Billboard',

    props: {
      title: String,
      height: Number,
      slideWidth: Number,
      timer: [Number]
    },

    data() {
      return {
        interval: null,
        slide: 0,
        halfWidth: 0
      }
    },

    computed: {
      border() {
        return this.halfWidth + (this.slideWidth / 2)
      },

      borderIndex() {
        return Math.ceil(this.border / this.slideWidth)
      },

      numSlides() {
        return Object.keys(this.$slots).length
      },

      // previousButtonStyle() {
      //   return `transform: translateX(calc(-100% - 20px));`
      // },
      //
      // nextButtonStyle() {
      //   return `transform: translateX(calc(20px);`
      // }
    },

    methods: {
      modulus(num, mod) {
        // If number is positive, mod is fine
        // If number is negative, subtract remainder of mod
        // from right side, not left side
        // e.g.               -10 mod 8        -17 mod 8        -8 mod 8
        // mod of abs:         10 mod 8 = 2     17 mod 8 = 1     8 mod 8 = 0
        // remainder of mod:   6                7                8
        // (6, 7, 0) <- 2 from right side
        if (num >= 0) {
          return num % mod
        }
        else {
          return (mod - (Math.abs(num) % mod)) % mod
        }
      },

      circularPosition(index) {
        // Get each slide's position according to index
        const slideCircularPosition = this.modulus(index - this.slide, this.numSlides)
        // Adjust to center based on halfway point
        const circularPosition = slideCircularPosition - this.numSlides / 2
        // Slides will now wrap around from left to right and vice versa
        return Math.ceil(circularPosition)
      },

      turnOnTimer() {
        if (!this.timer || isNaN(this.timer)) return;

        this.interval = window.setInterval(() => {
          this.iterate()
        }, this.timer * 1000)
      },

      turnOffTimer() {
        if (this.interval) {
          window.clearInterval(this.interval)
          this.interval = null
        }
      },

      iterate() {
        // console.log('iterate', Date.now());
        this.slide++;
      },

      nextSlide() {
        this.slide++;
        this.turnOffTimer()
      },

      previousSlide() {
        this.slide--;
        this.turnOffTimer()
      },
    },

    mounted() {
      this.turnOnTimer()
      this.halfWidth = this.$refs.slides?.clientWidth / 2
    },

    destroyed() {
      this.turnOffTimer()
    }
  }
</script>


<style scoped lang="scss">

  .billboard {
    position: relative;
    @include flex($direction: column);
    width: 100%;
    max-width: 1000px;
    height: 400px;
    margin: auto;
    // background-color: rgba(255,251,227,.1);
  }

  .toolbar {
    position: absolute;
    top: 0;
    left: 50%;
    @include flex($justify: center);
    transform: translateX(-50%);
    border-radius: 0.5rem;
    background-color: bg(18%);
    overflow: hidden;
    z-index: 9;
  }

  .title {
    font-size: 1rem;
    padding: 0 1rem;
    letter-spacing: 1px;
  }

  .large-button {
    padding: 1rem !important;
    border-radius: 0 !important;
  }

  .slides-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .slides {
    position: relative;
    @include flex();
    height: calc(100% - 60px);
    width: 100%;
    top: 60px;
    transform: translateX(50%);
  }

</style>
