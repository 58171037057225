<template>
  <div v-if="this.alert" @click="$emit('dismiss')"
    :class="`alert ${alert ? alert.type : 'neutral'} ${!alert ? 'hidden': ''}`">
    <Icon :name="switchIcon" size="20px" />
    <div class="text">
      <h4>{{ alert.title }}</h4>
      <p>{{ alert.message }}</p>
    </div>
    <Icon name="x thin" size="12px" />
  </div>
</template>


<script>
  import Icon from './Icon.vue';

  export default {
    name: 'Alert',
    components: {
      Icon
    },
    props: {
      alert: Object
    },
    computed: {
      switchIcon() {
        if (!this.alert || !this.alert.type) return '';
        switch (this.alert.type) {
          case 'success':
            return 'check';
          case 'error':
            return 'no';
          case 'warning':
            return 'exclamation';
          default:
            return '';
        }
      }
    }
  }
</script>


<style scoped lang="scss">

  .alert {
    position: relative;
    @include flex();
    margin: 1rem;
    padding: 0.75rem 1.25rem;
    border-radius: 0.5rem;
    color: #def;
    border: 1px solid #def;
    user-select: none;
    transition: all 0.15s;

    .icon:first-child {
      margin-right: 1rem;
    }

    .icon:last-child {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      margin-left: 1rem;
      opacity: 0.75;
    }

    .text {
      @include flex($direction: column, $align: flex-start);
      line-height: 1.5em;
      margin-right: 1rem;

      p {
        font-size: 0.9rem;
        opacity: 0.75;
        white-space: pre-line;
      }
    }

    &.success {
      color: $alert-success-color;
      border-color: rgba($alert-success-color, 0.5);
    }

    &.warning {
      color: $alert-warning-color;
      border-color: rgba($alert-warning-color, 0.5);
    }

    &.error {
      color: $alert-error-color;
      border-color: rgba($alert-error-color, 0.5);
    }

    &:hover {
      cursor: pointer;
      opacity: 0.65;
    }

    &.hidden {
      display: none;
    }
  }

</style>
