<template>
  <div class="page">

    <div class="spacer"></div>
    <h1>Create New Language</h1>
    <div class="spacer h5"></div>


    <div class="inputs table centered">

      <div class="table-row">
        <p>Name <span class="required">*</span></p>
        <input type="text" @input="(e) => name = e.target.value" />
      </div>

    </div>


    <div class="spacer h3"></div>

    <div class="row">
      <Button
        label="Back"
        icon="left-arrow"
        iconSize="12px"
        style="margin-right: 1rem;"
        @click.native="$router.back()"
      />
      <Button
        label="Create Language"
        class="primary"
        @click.native="createLanguage"
      />
    </div>

    <div class="spacer"></div>

    <Alert :alert="alert" @dismiss="alert = null" />

    <div class="spacer h5"></div>

  </div>
</template>

<script>
  export default {
    name: 'NewLanguage',
    components: {

    },
    data() {
      return {
        alert: null,

        name: null
      }
    },

    methods: {
      createLanguage() {
        let newLanguage = {
          title: this.name,
          worldID: this.$store.state.user?.currentWorldID
        }

        this.$store.dispatch('newLanguage', newLanguage).then(response => {
          console.log(response);
          if (response.status === 201) {
            this.$store.dispatch('fetchCurrentUserWorld')
            if (response?.data?.id) {
              this.$router.push('/language/' + response.data.id)
            }
            else {
              this.$router.push('/creation')
            }
          }
        });
      }
    }
  }
</script>

<style scoped lang="scss">

  .inputs {
    width: 100%;
    max-width: 600px;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: bg(20%);
  }

  .required {
    color: #ff2553;
  }

</style>
