<template>
  <div :class="`select-container ${isOpen ? 'open' : 'closed'}`"
    @click="isOpen = !isOpen"
  >

    <div class="selected-item"
      :style="selectedItem && selectedItem.color ? `color: ${selectedItem.color};` : ''"
    >
      <Icon v-if="selectedItem && selectedItem.icon"
        :name="selectedItem.icon"
        :size="iconSize || '1rem'"
        style="margin-right: 0.75rem;"
      />
      <p>{{ selectedItem ? selectedItem.label : '--' }}</p>
    </div>

    <Icon :name="isOpen ? 'less-arrow' : 'more-arrow'" size="12px" />

    <div v-if="isOpen" class="options">
      <div v-for="(option, index) in options"
        class="option-item"
        :style="optionItemStyle(option)"
        @click.stop="select(index)"
      >
        <Icon v-if="option.icon"
          :name="option.icon"
          :size="iconSize || '1rem'"
          style="margin-right: 0.75rem;"
        />
        <p v-if="option.label">{{ option.label }}</p>
      </div>
    </div>

  </div>
</template>

<script>
  import Icon from './Icon.vue';

  export default {
    name: 'Select',
    components: {
      Icon
    },
    props: {
      options: Array,
      optionsStyle: [String],
      value: [String],
      iconSize: [String],
      strict: [Boolean]
    },
    computed: {
      selectedItem() {
        if (this.selectedIndex == null) return;
        return this.options[this.selectedIndex];
      }
    },
    data() {
      return {
        isOpen: false,
        selectedIndex: null
      }
    },
    methods: {
      optionItemStyle(option) {
        let string = '';
        if (option.color) string += `color: ${option.color};`
        if (this.optionsStyle) string += this.optionsStyle;
        return string;
      },

      select(index) {
        this.selectedIndex = index;
        this.isOpen = false;
        this.$emit('select', this.options[index].value || this.options[index].icon);
      },

      findSelectedIndex() {
        if (this.value == null) return;


        let index = this.options.findIndex(option => option.value == this.value);

        if (index == -1) {
          index = this.options.findIndex(option => option.icon == this.value);
        }

        if (index == -1) return;

        this.selectedIndex = index;
      }
    },

    mounted() {
      this.findSelectedIndex();
    },

    updated() {
      if (this.strict) {
        this.findSelectedIndex();
      }
    }
  }
</script>

<style scoped lang="scss">

  .select-container {
    position: relative;
    @include flex($justify: space-between);
    padding: 0.75rem 1rem;
    border-radius: 4px;
    color: $colorA;
    background-color: #1c1d21;
    user-select: none;

    &.open {
      border-radius: 4px 4px 0 0;
    }

    .selected-item {
      @include flex();
      color: $colorA;

      p, .icon {
        color: inherit;
      }
    }

    .options {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: 400px;
      padding-top: 0;
      border-radius: 0 0 4px 4px;
      color: #fff;
      background-color: #1c1d21;
      // border-top: 1px solid #333;
      overflow-y: auto;
      z-index: 999 !important;

      .option-item {
        @include flex();
        padding: 0.75rem 1rem;
      }
      .option-item:hover {
        color: $colorA;
        background-color: #131416;
      }

      p, .icon {
        color: inherit;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

</style>
