<template>
  <div class="page">

    <h3>Calendar</h3>

    <div class="spacer h5"></div>



    <h4>Months</h4>
    <div class="spacer"></div>

    <div class="inputs table floating">
      <div class="table-row" v-for="(month, index) in calendar.months">
        <div>
          <input v-if="editingMonth == index" type="text" :value="month.name"
            @input="(e) => calendar.months[index].name = e.target.value"
          />
          <p v-else>{{ month.name }}</p>
        </div>
        <div>
          <div class="row">
            <input v-if="editingMonth == index" type="number" :value="month.numDays"
              @input="(e) => calendar.months[index].numDays = parseInt(e.target.value)"
            />
            <p v-else>{{ unknownNumDays ? '???' : month.numDays }} days</p>
            <Button v-if="editingMonth == null || editingMonth == index"
              class="primary"
              style="margin-left: auto;"
              :icon="editingMonth == index ? 'check' : 'edit'"
              @click.native="editingMonth = (editingMonth == index ? null : index)"
            />
            <Button v-if="editingMonth == null || editingMonth == index"
              class="delete"
              icon="x"
              @click.native="deleteMonth(index)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="spacer"></div>
    <Button class="primary"
      icon="plus"
      label="Add Month"
      @click.native="addMonth"
    />
    <div class="spacer"></div>


    <Checkbox
      label="Unknown Length for All Months"
      color="#14cb7e"
      :checked="unknownNumDays"
      @checkbox-toggle="toggleUnknown"
    />

    <div class="all-months row">
      <p>Make all months</p>
      <input type="number"
        @input="(e) => numDaysAll = e.target.value"
        style="width: 120px;"
      />
      <p>days</p>
      <Button label="Apply"
        style="display: inline-flex; white-space: pre;"
        @click.native="applyAllMonths"
      />
    </div>


    <div class="spacer h3"></div>

    <div class="visual-calendar">
      <div class="year">
        <div class="year-left"></div>
        <p>1 year = {{ totalDays }} days</p>
        <div class="year-right"></div>
      </div>

      <div class="months">
        <div v-for="(month, index) in calendar.months"
          class="month"
          :style="formatMonthWidth(month)"
        >
          <div class="month-bar"
            @mouseenter="monthInfoVisible = index"
            @mouseleave="monthInfoVisible = null"
          >
          </div>
          <div v-if="monthInfoVisible == index" class="month-info floating">
            <p class="month-name">{{ month.name }}</p>
            <p>{{ unknownNumDays ? '???' : month.numDays }} days</p>
          </div>
        </div>
      </div>
    </div>

    <div class="spacer h5"></div>


    <Button
      label="Save Calendar"
      icon="check"
      class="primary"
      @click.native="saveCalendar"
    />


    <!-- <div class="circle"></div> -->


    <div class="spacer h5"></div>
    <div class="spacer h5"></div>


  </div>
</template>

<script>
  import exampleTimeline from './example_world_timeline';

  export default {
    name: 'Calendar',

    data() {
      return {
        calendar: null,
        editingMonth: null,
        monthInfoVisible: null,

        unknownNumDays: false,
        numDaysAll: 0
      }
    },

    computed: {
      totalDays() {
        if (this.unknownNumDays) return '???';
        if (!this.calendar.months.length) return '???';
        if (this.calendar.months.length <= 1) return this.calendar.months[0].numDays;
        return this.calendar.months.reduce((x, i) => (isNaN(x) ? x.numDays : x) + i.numDays)
      }
    },

    methods: {
      formatMonthWidth(month) {
        if (this.unknownNumDays) return 'width: 100%';
        let totalDays = this.calendar.months.reduce((x, i) => (isNaN(x) ? x.numDays : x) + i.numDays)
        return `width: ${Math.round((month.numDays / totalDays) * 10000) / 100}%;`
      },

      addMonth() {
        this.calendar.months.push({ name: 'New Month', numDays: 0 });
        this.editingMonth = this.calendar.months.length - 1;
      },

      deleteMonth(index) {
        this.calendar.months.splice(index, 1);
        this.editingMonth = null;
      },

      toggleUnknown() {
        this.unknownNumDays = !this.unknownNumDays;
      },

      applyAllMonths() {
        if (!this.numDaysAll || isNaN(parseInt(this.numDaysAll))) return;
        this.calendar.months.forEach(month => {
          month.numDays = parseInt(this.numDaysAll)
        });
      },
    },

    mounted() {
      this.calendar = exampleTimeline.calendars[0]
      console.log(this.calendar);
    }
  }
</script>

<style scoped lang="scss">

  .inputs {
    width: 400px;
    border-radius: 0.25rem;
    background-color: bg(20%);
  }

  .row {
    p {
      white-space: pre;
    }

    > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .category {
    // @include flex($direction: column, $justify: flex-start, $align: flex-start);
    padding-top: 0.7rem;
    padding-right: 1rem;
    vertical-align: top !important;

    p {
      color: $colorA;
    }
  }

  .total {
    margin-top: 1rem;

    span {
      color: $colorA;
    }
  }

  .all-months {
    margin: 1rem 0;
    width: 400px;
  }

  .visual-calendar {
    width: 600px;

    .year {
      @include flex();
      text-align: center;
      margin-bottom: 0.5rem;

      p {
        padding: 0 0.75rem;
        white-space: pre;
      }

      .year-left, .year-right {
        @include flex();
        width: 100%;
        height: 1px;
        background-color: #fff;
      }

      .year-left::before, .year-right::after {
        content: '';
        width: 1px;
        height: 11px;
        background-color: #fff;
      }

      .year-right::after {
        margin-left: auto;
      }
    }

    .ages, .months {
      @include flex();
    }

    .age {
      margin: 2px;
      height: 8px;
      background-color: $colorA;
    }

    .month {
      position: relative;
      width: 100%;
      margin: 2px;
    }

    .month-bar {
      width: 100%;
      height: 8px;
      background-color: $colorB;

      &:hover {
        cursor: pointer;
        transform: scale(1, 1.5);
      }
    }
  }

  .month-info {
    position: absolute;
    top: 1rem;
    left: 50%;
    @include flex($direction: column);
    width: 120px;
    height: 90px;
    padding: 1rem;
    border-radius: 0.25rem;
    background-color: bg(20%);
    transform: translateX(-50%);
    z-index: 99;

    .month-name {
      color: $colorA;
    }
  }

  .circle {
    width: 400px;
    height: 400px;
    border: 30px solid $colorA;
    border-radius: 100%;
  }

</style>
