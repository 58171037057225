<template>
  <div class="page">

    <div class="spacer"></div>
    <h1>Create New Map</h1>
    <div class="spacer h5"></div>

    <div class="inputs table centered">
      <div class="table-row">
        <p>Title <span class="required">*</span></p>
        <input type="text" @input="(e) => title = e.target.value" />
      </div>

      <div class="table-row">
        <p>Description</p>
        <textarea @input="(e) => description = e.target.value" />
      </div>

      <div class="table-row">
        <p>Image <span class="required">*</span></p>
        <div>
          <Button
            label="Generate Map"
            icon="plus"
            class="primary inline"
            style="margin: 0.75rem 0;"
            @click.native="$router.push('/generate-map')"
          />
          <p class="hint">Or upload your own:</p>
          <div class="image-preview">
            <div v-if="imagePreview || generatedMap"
              class="image"
              @click="removeImage"
            >
              <img v-if="imagePreview" :src="imagePreview" />
              <img v-else :src="generatedMap" />
              <Icon name="x" size="1.5rem" />
            </div>
            <div v-else class="add-image-box" @click="openImageSelect" >
              <Icon name="plus" size="1.5rem" />
            </div>
            <input type="file" name="map" id="imageFileSelect" @change="selectImage" />
          </div>
        </div>
      </div>
    </div>


    <div class="spacer"></div>

    <div class="row">
      <Button
        label="Back"
        icon="left-arrow"
        iconSize="12px"
        style="margin-right: 1rem;"
        @click.native="$router.back()"
      />
      <Button
        label="Create Map"
        class="primary"
        @click.native="createMap"
      />
    </div>

    <Alert :alert="alert" @dismiss="alert = null" />

    <div class="spacer h5"></div>

  </div>
</template>

<script>
  export default {
    name: 'NewMap',

    computed: {
      generatedMap() {
        return this.$store.state.generatedMap
      }
    },

    data() {
      return {
        title: '',
        description: '',
        image: null,
        imageName: null,
        imagePreview: null,
        alert: null
      }
    },

    methods: {

      openImageSelect() {
        let fileSelect = document.getElementById('imageFileSelect');
        fileSelect.click();
      },

      selectImage() {
        this.image = event.target.files[0];
        this.imageName = this.image.name;
        this.loadImagePreview(this.image);
      },

      loadImagePreview(imageFile) {
        let fileReader = new FileReader();
        fileReader.onload = () => {
          this.imagePreview = fileReader.result;
        }
        fileReader.readAsDataURL(imageFile);
      },

      removeImage() {
        this.image = null;
        this.imageName = null;
        this.imagePreview = null;
        this.$store.commit('clearGeneratedMap')
      },


      async createMap() {
        if (!this.title.trim()) {
          this.alert = {
            type: 'warning',
            message: 'Please enter a name for the map.'
          }
          return;
        }
        if (!this.image) {
          this.alert = {
            type: 'warning',
            message: 'Please provide an image.'
          }
          return;
        }

        let formData = new FormData();
        formData.append('title', this.title);
        formData.append('worldID', this.$store.state.world?._id);
        if (this.description.trim()) formData.append('description', this.description);
        formData.append('map', this.image, this.imageName);

        this.$store.dispatch('newMap', formData).then(response => {
          console.log(response);
          if (response.status === 201) {
            this.$store.dispatch('fetchCurrentUserWorld')
            this.alert = {
              type: 'success',
              message: 'Map created.'
            }
            if (response?.data?.id) {
              this.$router.push('/map/' + response.data.id);
            }
          }
          else {
            this.alert = {
              type: 'error',
              message: 'Problem creating new map.'
            }
          }
        });
      }
    },

    async mounted() {
      if (this.generatedMap) {
        this.image = await fetch(this.generatedMap).then(r => r.blob())
        this.imageName = 'generated_map.png'
      }
    }
  }
</script>

<style scoped lang="scss">

  .inputs {
    width: 100%;
    max-width: 600px;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: bg(20%);
  }

  .required {
    color: #ff2553;
  }

  h4 {
    margin-bottom: 0.5rem;
    text-align: center;
  }

  .buttons {
    @include flex($justify: center);

    > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .image-preview {
    @include flex();
    max-width: 680px;
    flex-wrap: wrap;
  }

  .image, .add-image-box {
    width: 240px;
    height: 240px;
    margin-top: 1rem;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .image img {
    width: 100%;
    height: 100%;
    transition: opacity 0.15s;

    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }

  .image {
    @include flex($justify: center);
    .icon {
      position: absolute;
      pointer-events: none;
      color: #ec4e4e;
      opacity: 0;
      transition: opacity 0.15s;
      filter: drop-shadow(0 0 16px #000);
    }

    &:hover {
      .icon {
        opacity: 1;
      }
    }
  }

  .add-image-box {
    @include flex($justify: center);
    color: #999;
    border: 1px solid #999;
    transition: color 0.15s, border 0.15s;

    &:hover {
      cursor: pointer;
      color: $colorA;
      border: 1px solid $colorA;
    }
  }

  #imageFileSelect {
    display: none;
  }

</style>
