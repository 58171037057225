<template>
  <div class="terms-of-service">

    <h1 class="colorful-title">Terms Of Service Agreement</h1>
    <div class="spacer h1"></div>
    <p class="hint" style="text-align: center;">Last Updated: April 5, 2022</p>
    <div class="spacer"></div>


    <ul class="table-of-contents">
      <li><a @click="goToSection('sectionA')">Section A: Content Policy</a></li>
      <li><a @click="goToSection('sectionB')">Section B: API</a></li>
    </ul>


    <h3>Introduction</h3>

    <p>This Scrybe Terms of Service Agreement (“Agreement”) is a legal agreement between Scrybe (“Scrybe”, “us”, or “we”) and any user (“you”, “your”, or “user”) who registers for a Scrybe account using the Register page. This Agreement describes the terms and conditions that apply to your use of Scrybe's services.</p>
    <p>You may not access or use any of Scrybe's services unless you agree to abide by all of the terms and conditions in this Agreement.</p>



    <h3 ref="sectionA">Section A: Content Policy</h3>
    <p class="hint">This section covers uploaded media content and community guidelines.</p>

    <h4>1. Rules For All Content</h4>

    <p>You agree to submit to the following when uploading or posting any content on Scrybe (including but not limited to: public and private world elements, community posts, comments, and private messages):</p>
    <ul>
      <li>The content is not pornographic, obscene, extremely violent or gory in nature.</li>
      <li>The content does not incite violence (political or otherwise), threaten other human beings in any way, or report breaking the laws of your local country or encourage others to do so.</li>
    </ul>

    <p class="highlight">Any illegal activities performed by a Scrybe user will be saved and reported to the proper authorities, as required by law, and the user will be banned from accessing or using Scrybe's services.</p>

    <p>Threats of harm to self or others will also be reported to police, and may also result in denial of service.</p>

    <h4>2. Rules For Publically Visible Content</h4>

    <p>For content which can be seen publically by users other than the user who has uploaded or posted it, you agree to submit to the following:</p>
    <ul>
      <li>The content does not violate copyright, and you are either the owner or have explicit permission from the owner to replicate and post the content.</li>
    </ul>

    <p>Note: Sharing or linking private content (content that can only be seen by the user who uploaded or posted it) puts it in the domain of Publically Visible Content, and as such is subject to the terms in this section (2).</p>



    <h3 ref="sectionB">Section B: API</h3>

    <p>1. You agree to only use Scrybe's frontend interface when accessing data stored on Scrybe or performing any interactions which duplicate, alter, or erase that data.</p>
    <p>2. We currently do not have a public API available to third parties, and any access to Scrybe's API other than as described above in (1) may result in denial of service for the accessor.</p>
    <p>If you have a request for additional features or public API integration, please contact us via the <router-link to="/support">Support</router-link> page.</p>



    <div class="spacer h5"></div>

  </div>
</template>

<script>
  export default {
    name: 'TermsOfService',

    methods: {
      goToSection(name) {
        const element = this.$refs[name];
        if (!element) return;

        element.scrollIntoView()
      }
    }
  }
</script>

<style scoped lang="scss">

  .terms-of-service {
  }

  h1.colorful-title {
    font-size: 2rem;
  }

  h2 {
    text-align: center;
  }

  h3 {
    color: $colorA;
    font-weight: 300;
    margin-top: 5rem;
    margin-bottom: 1rem;
  }

  h4 {
    color: $colorB;
    margin-top: 2rem;
    margin-bottom: 0.75rem;
  }

  p {
    margin-bottom: 1rem;
  }

  ul {
    margin-bottom: 1.5rem;
    padding-left: 1rem;
    list-style-position: inside;

    li {
      margin: 0.75rem 0;
      line-height: 1.5em;
      color: neutral(80%);
    }
  }

  .table-of-contents {
    padding-left: 0;

    li {
      margin: 0.5rem 0;
      color: neutral(60%);

      a {
        color: $colorB;
        font-weight: 600;

        &:hover {
          cursor: pointer;
          color: $colorA;
        }
      }
    }
  }

  .highlight {
    color: $colorD;
    font-weight: 600;

    &.green {
      color: $colorA;
    }
  }

</style>
