<template>
  <div
    class="word-processor"
  >

    <div class="toolbar" @mouseup.stop="">
      <Button label="T" @mouseup.native="title" />
      <Button label="S" @mouseup.native="subtitle" />
      <Button label="B" @mouseup.native="bold" />
    </div>


    <div
      class="nodes-container"
      id="nodes"
    >
      <span>Hello there.</span>
    </div>
  </div>
</template>

<script>
  import LinkDropdown from '../../LinkDropdown.vue'

  const MAX_UNDO_HISTORY = 10

  let quill

  export default {
    name: 'WordProcessor',
    components: {
      LinkDropdown
    },

    props: {
      content: [Array],
      currentSceneIndex: [Number]
    },

    data() {
      return {
        nodes: [
          {
            display: 'block',
            style: '',
            content: 'This is a   line that is really long so hopefully it reaches the edge of the page and continues on the following line. Then we will continue even further to get a third line in the same node so we can explore this more.\n'
          },
          {
            display: 'inline',
            style: '',
            content: 'This is '
          },
          {
            display: 'inline',
            style: 'bold',
            content: 'bold'
          },
          {
            display: 'inline',
            style: '',
            content: ' text.\n'
          },
          {
            display: 'block',
            style: '',
            content: 'A line now\n'
          },
          {
            display: 'block',
            style: '',
            content: '\n'
          },
          {
            display: 'block',
            style: '',
            content: 'Another line now'
          }
        ],

        // currentNodeIndex: 0,
        currentCharIndex: 0,

        undoHistory: [],
        currentHistoryIndex: 0
      }
    },

    watch: {
      currentSceneIndex: function(newValue) {
        console.log(this.cursorChar);
        this.cursorLine = null;
        this.cursorChar = null;
        this.selection = {
          startNode: null,
          startChar: null,
          endNode: null,
          endChar: null
        }
      },

      content: function(newContent, oldContent) {
        // this.nodes = this.import(newContent);
      }
    },

    computed: {
      previousNode() {
        if (this.currentNodeIndex <= 0) {
          return null
        }
        return this.nodes[this.currentNodeIndex - 1]
      },

      nextNode() {
        if (!this.nodes?.length || this.currentNodeIndex >= this.nodes.length - 1) {
          return null
        }
        return this.nodes[this.currentNodeIndex + 1]
      },

      currentNode() {
        return this.nodes[this.currentNodeIndex()]
      },
    },


    methods: {
      currentNodeElement() {
        const node = window.getSelection().extentNode
        return node.tagName != 'SPAN' ? node.parentElement : node
      },
      currentNodeIndex() {
        return Array.prototype.indexOf.call(this.currentNodeElement().parentElement.children, this.currentNodeElement())
      },

      // Nodes

      newNode(newChars) {
        return {
          style: '',
          content: ''
        }
      },

      bold() {
        event.stopPropagation();
        const { baseNode, baseOffset, extentNode, extentOffset } = window.getSelection()
        if (baseNode == extentNode && baseOffset == extentOffset) {
          this.splitAndInsertNode({
            display: 'inline',
            style: 'bold'
          })
        }
        else if (baseNode == extentNode) {
          this.splitAndInsertNode({
            start: baseOffset < extentOffset ? baseOffset : extentOffset,
            end: extentOffset > baseOffset ? extentOffset : baseOffset,
            display: 'inline',
            style: 'bold'
          })
        }
        // this.$emit('updated', this.export());
      },
      title() {
        event.stopPropagation();
        this.nodes[this.cursorLine].style = this.nodes[this.cursorLine].style == 'title' ? '' : 'title';
        // this.$emit('updated', this.export());
      },
      subtitle() {
        event.stopPropagation();
        this.nodes[this.cursorLine].style = this.nodes[this.cursorLine].style == 'subtitle' ? '' : 'subtitle';
        // this.$emit('updated', this.export());
      },

      export() {
        let exportNodes = [];
        this.nodes.forEach(node => {
          exportNodes.push({
            content: node.chars.join(''),
            style: node.style
          });
        });
        return exportNodes;
      },

      appendHistory() {
        // Reset
        if (this.currentHistoryIndex < this.undoHistory.length - 1) {
          this.undoHistory = []
        }

        if (this.undoHistory.length >= MAX_UNDO_HISTORY) {
          this.undoHistory.shift()
        }

        const savedNodes = JSON.parse(JSON.stringify(this.nodes))
        this.undoHistory.push(savedNodes)
        this.currentHistoryIndex = this.undoHistory.length - 1
      },

      undo() {
        if (this.undoHistory?.length && this.currentHistoryIndex > 0) {
          this.currentHistoryIndex--
          console.log(JSON.parse(JSON.stringify(this.undoHistory[this.currentHistoryIndex])));
          this.nodes = [...this.undoHistory[this.currentHistoryIndex]]
        }
      },

      redo() {
        if (this.undoHistory?.length && this.currentHistoryIndex < this.undoHistory.length - 1) {
          this.currentHistoryIndex++
          console.log(JSON.parse(JSON.stringify(this.undoHistory[this.currentHistoryIndex])));
          this.nodes = [...this.undoHistory[this.currentHistoryIndex]]
        }
      },


      // Key Presses

      setSelection(nodeIndex, charIndex) {
        const selection = window.getSelection()
        const range = document.createRange()
        const nodes = document.getElementById('nodes')
        range.setStart(nodes.children[nodeIndex], charIndex)
        selection.removeAllRanges()
        selection.addRange(range)
      },

      splitNodeAtCursor() {
        const charIndex = window.getSelection().extentOffset
        const before = this.currentNode.content.substring(0, charIndex)
        const after = this.currentNode.content.substring(charIndex)

        this.nodes[this.currentNodeIndex()].content = before

        const newNode = {
          display: 'block',
          style: this.currentNode.style,
          content: after || ''
        }
        this.nodes.splice(this.currentNodeIndex() + 1, 0, newNode)

        this.setSelection(this.currentNodeIndex() + 1, 0)
      },

      splitAndInsertNode(options) {
        const charIndex = window.getSelection().extentOffset
        const before = this.currentNode.content.substring(0, options?.start ?? charIndex)
        const after = this.currentNode.content.substring(options?.end ?? charIndex)
        const middle = this.currentNode.content.substring(options?.start, options?.end)

        if (options?.display) {
          this.nodes[this.currentNodeIndex()].display = options.display
        }
        this.nodes[this.currentNodeIndex()].content = before

        const newNode = {
          display: options?.display || 'block',
          style: options?.style || '',
          content: options?.end ? middle : ''
        }
        this.nodes.splice(this.currentNodeIndex() + 1, 0, newNode)

        if (after) {
          const afterNode = {
            display: options?.display || 'block',
            style: this.currentNode.style,
            content: after
          }
          this.nodes.splice(this.currentNodeIndex() + 2, 0, afterNode)
        }

        console.log(this.currentNodeIndex());
        this.setSelection(this.currentNodeIndex() + 1, 0)
      },

      onInput() {
        // event.preventDefault()
        event.stopPropagation()


        const { key, metaKey, shiftKey } = event

        if (metaKey && key == 'l') {
          event.preventDefault()
          console.log(JSON.parse(JSON.stringify(this.nodes)));
          return
        }

        if (metaKey && shiftKey && key == 'z') {
          this.redo()
          return
        }
        if (metaKey && key == 'z') {
          this.undo()
          return
        }

        if (metaKey && key == 'a') {
          this.createNodeAtCursor({
            style: 'link',
            content: ''
          })
          return
        }


        switch (key) {
          case 'Enter':
            event.preventDefault()
            this.splitNodeAtCursor()
            break
        }


        // this.$emit('updated', this.export());
      }
    },

    created() {
      // this.nodes = this.content
      this.undoHistory = [JSON.parse(JSON.stringify(this.nodes))]
    },

    mounted() {
      window.addEventListener('keydown', this.keyDownOutside);

      const Parchment = Quill.import('parchment')
      class ScrybeLink extends Parchment.Inline {
        // static create(value) {
        //   console.log('create');
        //   let node = super.create()
        //   node.setAttribute('href', value);
        //   node.setAttribute('target', '_blank');
        //   // node.setAttribute('title', node.textContent);
        //   return node;
        // }
        //
        // static formats(domNode) {
        //   console.log('formats static');
        //   return domNode.getAttribute('href') || true;
        // }

        // format(name, value) {
        //   console.log('format');
        //   if (name === 'scrybe-link' && value) {
        //     this.domNode.setAttribute('href', value);
        //   } else {
        //     super.format(name, value);
        //   }
        // }
        //
        // formats() {
        //   console.log('formats');
        //   let formats = super.formats();
        //   formats['scrybe-link'] = ScrybeLink.formats(this.domNode);
        //   return formats;
        // }
      }
      ScrybeLink.blotName = 'scrybe-link';
      ScrybeLink.tagName = 'SPAN';
      Quill.register(ScrybeLink, true)

      console.log(Quill.imports);

      quill = new Quill('#nodes', {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, false] }],
            [{ font: [] }],
            ['bold', 'italic', 'scrybe-link', 'link', 'strike'],
            [{ align: [] }],
            ['blockquote']
          ]
        },
        theme: 'bubble'
      })
    },

    destroyed() {
      window.removeEventListener('keydown', this.keyDownOutside);
    }
  }
</script>

<style scoped lang="scss">

  .word-processor {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.25rem;
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background-color: rgba(0,0,0,.2);

    &:hover {
      cursor: text;
    }
  }

  .toolbar {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    > *:not(:last-child) {
      margin-right: 0.25rem;
    }

    &:hover {
      cursor: auto;
    }
  }

  .nodes-container {
    position: relative;
    outline: none;
  }

  .cursor-input {
    position: absolute;
    margin: 0;
    padding: 0;
    // height: 1px;
    width: 1px;
    background: none;
    border: 0;
    overflow: visible;
    opacity: 0;
  }

  .cursor-caret-container {
    position: relative;
    display: inline-block;
    height: 1em;
    line-height: 1em;
  }

  .cursor-caret {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    align-self: center;
    bottom: -2px;
    height: 100%;
    width: 1px;
    contain: strict;
    background-color: $colorA;
  }

  .node {
    position: relative;
    display: inline;
    white-space: pre-wrap;

    span {
      line-height: 2rem;
    }

    &.block {
      display: block;
    }
    &.inline {
      display: inline;
    }

    &.bold {
      font-weight: 600;
    }
    &.title {
      margin-bottom: 0.25rem;
      font-size: 1.25rem;
      text-align: center;
      font-weight: 400;
    }
    &.subtitle {
      margin-bottom: 0.25rem;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
      span {
        color: rgba(209,222,255,.5);
      }
    }

    &.link {
      span {
        font-weight: 600;
        color: #edbb6f;
      }
    }

    &:hover {
      cursor: text;
    }
  }

  #click-low, #click-high, #next-line {
    position: absolute !important;
    top: 0;
    left: 0;
    pointer-events: none !important;
    opacity: 0 !important;
  }




  /deep/ .ql-container {

    .ql-toolbar {
      background-color: #000;
      border-radius: 22px;

      .ql-formats {
        button {
          // all: unset;
          width: 44px;
          height: 44px;
          background-color: unset;
          fill: #aaa;
          border: 0 !important;

          svg {
            width: 100%;
            height: 100%;
          }

          &:hover {
            fill: #fff;
          }
        }

        .ql-strike {
          .ql-stroke {
            stroke: #fff;
          }
        }

        .ql-bold, .ql-link, .ql-italic, .ql-underline {
          fill: unset !important;
          stroke: #aaa;

          &:hover {
            stroke: #fff;
          }
        }

        .ql-picker {
          width: 44px;
          height: 44px;
          stroke: #fff;

          .ql-picker-label {
            height: 100%;
            width: 100%;

            svg {
              height: 28px;
              width: 28px;
            }
          }

          .ql-picker-options {
            svg {
              width: 32px;
              height: 32px;
            }
          }
        }
      }
    }

    .ql-tooltip {
      position: absolute;
      // top: 0;
      // left: 0;

      &.ql-hidden {
        display: none;
      }
    }

    .ql-tooltip-editor {
      display: none;
    }

    .ql-tooltip-arrow {
      content: "";
      position: absolute;
      top: -6px;
      left: 50%;
      display: block;
      margin-left: -6px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #000;
    }
  }

</style>
