
import Vue from 'vue'
import { api, apiFile, getIP, encrypt, publicImageURL } from '@/utility.js'



export const userMutations = {

  loadUser(state, user) {
    // let formattedUser = { ...user };
    // if (user.purchasedProducts) {
    //   let permissions = {}
    //   for (let productID in user.purchasedProducts) {
    //     let name = products.name(productID)
    //     permissions[name] = user.purchasedProducts[productID]
    //   }
    //   formattedUser.permissions = permissions
    // }
    state.user = user;
    state.userFetched = true;
  },

  clearUser(state) {
    state.user = null;
  },

  changeTheme(state, theme) {
    state.theme = theme;
  },

  loadAvatar(state, data) {
    Vue.set(state.avatars, data.id, data.avatar);
  },

  updateFollowingWorlds(state, followingWorlds) {
    state.user.following.worlds = followingWorlds;
  },

  loadLoginHistory(state, loginHistory) {
    state.user.loginHistory = loginHistory;
  },



  stepTutorial(state, step) {
    state.user.tutorial = step;
  }

}


export const userActions = {

  // AUTH

  async register({ commit, state }, params) {
    return await api('POST', 'register', params);
  },

  async activateUser({ commit, state }, code) {
    return await api('GET', 'activate/' + code);
  },

  async login({ commit, state }, params) {
    const location = await getIP()

    let encryptedData = encrypt({ ...params, location });
    let response = await api('POST', 'login', encryptedData);

    if (response.status === 200 && response.data) {
      window.localStorage.setItem('token', response.data.token);
      commit('loadUser', response.data.user);
    }
    return response;
  },

  async logout({ commit, state }) {
    window.localStorage.removeItem('token');
    commit('clearUser');
  },

  async forgotPassword({ commit, state }, params) {
    return await api('POST', 'forgotPassword', params);
  },

  async validateResetPasswordCode({ commit, state }, code) {
    return await api('POST', 'validateResetPasswordCode', { code });
  },

  async resetPassword({ commit, state }, params) {
    return await api('POST', 'resetPassword', params);
  },

  async sendTestEmail({ commit, state }) {
    return await api('POST', 'testEmail', { arbitraryCode: 7654321 });
  },


  // USERS

  async getMe({ commit, state }) {
    let response = await api('POST', 'me');
    if (response.status === 200 && response.data) {
      commit('loadUser', response.data);
      commit('loadNotifications', response.data.notifications);
      if (!response.data?.tutorial) commit('stepTutorial', 0);
    }
    return response;
  },

  async fetchUserProfile({ commit, state }, id) {
    return await api('GET', 'user/profile/' + id);
  },

  async fetchUserAvatar({ commit, state }, id) {
    let response = await api('GET', 'user/avatar/' + id);

    // let userAvatar = response.data;
    // if (!userAvatar || !userAvatar.avatarImage) {
    //   console.error('No avatar data or image url found. Avatar not added.');
    //   return;
    // }
    //
    // if (!isCustomImage(userAvatar.avatarImage)) {
    //   userAvatar.imageFile = userAvatar.avatarImage;
    // }
    // else {
    //   let image = await fetch(publicImageURL('avatar', userAvatar.avatarImage));
    //   let blob = await image.blob();
    //   let file = new File([blob], userAvatar.avatarImage, {
    //     type: blob.type
    //   });
    //   userAvatar.imageFile = file;
    // }

    if (response.status == 200) {
      commit('loadAvatar', {
        id,
        avatar: response?.data
      });
    }

    return response;
  },

  async updateAvatarImage({ commit, state }, params) {
    return await apiFile('POST', 'user/updateAvatar', params);
  },

  async clearAvatarImage({ commit, state }) {
    return await apiFile('POST', 'user/clearAvatar', {});
  },

  async updateUserInfo({ commit, state }, params) {
    return await api('PUT', 'user/updateUser', params);
  },

  async toggleFollowWorld({ commit, state }, worldID) {
    let response = await api('POST', 'user/toggleFollowWorld', { worldID });
    if (response.status === 200 && response.data) {
      commit('updateFollowingWorlds', response.data?.worlds);
    }
    return response;
  },

  async fetchLoginHistory({ commit, state }) {
    let response = await api('GET', 'loginHistory');
    if (response.status === 200 && response.data) {
      commit('loadLoginHistory', response.data);
    }
    return response;
  },

  async fetchFollowingWorlds({ commit, state }) {
    return await api('GET', 'followingWorlds');
  },

  async fetchMedia({ commit, state }) {
    return await api('POST', 'user/media');
  },

  async deleteMedia({ commit, state }, id) {
    return await api('DELETE', 'user/media/' + id);
  },

  async fetchSubscriptionHistory({ commit, state }) {
    return await api('POST', 'user/subscriptionHistory', {});
  },

  async fetchInvitations({ commit, state }) {
    return await api('POST', 'user/invitations', {});
  },



  async stepTutorial({ commit, state }, step) {
    let response = await api('POST', 'user/stepTutorial', { step });
    if (response.status === 200) {
      commit('stepTutorial', step)
    }
    return response;
  },

}
