<template>
  <div class="loading-bar" :style="formatStyle()">
    <div :class="`inner-fill ${progressClass}`" :style="`width: ${progressPercent}%; ${formatStyle()}`"></div>
  </div>
</template>

<script>
  export default {
    name: 'LoadingBar',

    props: {
      height: [Number, String],
      progress: [Number, String],
      colorByAmount: [Boolean]
    },

    computed: {
      progressPercent() {
        if (this.progress > 100) return 100
        if (this.progress < 0) return 0
        return this.progress
      },

      progressClass() {
        if (!this.colorByAmount) return ''

        if (this.progress > 90) return 'ninety-percent'
        if (this.progress > 70) return 'seventy-percent'
      }
    },

    methods: {
      formatStyle() {
        return `height: ${this.height}px; border-radius: ${parseInt(this.height) / 2}px;`
      }
    }
  }
</script>

<style scoped lang="scss">

  .loading-bar {
    position: relative;
    width: 300px;
    background-color: #000;

    .inner-fill {
      position: absolute;
      top: 0;
      left: 0;
      width: 100px;
      background-color: $colorA;
      z-index: 9;

      &.seventy-percent {
        background-color: $alert-warning-color;
      }
      &.ninety-percent {
        background-color: $alert-error-color;
      }
    }
  }

</style>
