<template>
  <div class="tab-select">
    <div v-for="(tab, index) in tabs" @click="selectTab(tab, index)"
      :class="`tab ${selectedIndex === index ? ' selected' : ''}`">
      <span>{{ tab }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TabSelect',
    props: {
      tabs: Array,
      selectedTab: [String]
    },

    data() {
      return {
        selectedIndex: 0
      }
    },

    watch: {
      'selectedTab': function(tab) {
        this.updateSelectedTab()
      }
    },

    methods: {
      updateSelectedTab() {
        if (this.selectedTab) {
          const tabIndex = this.tabs.findIndex(tab => tab == this.selectedTab)
          if (tabIndex != -1) {
            this.selectedIndex = tabIndex
          }
        }
      },

      selectTab(tab, index) {
        this.selectedIndex = index;
        this.$emit('select', tab);
      }
    },

    mounted() {
      this.updateSelectedTab()
    }
  }
</script>

<style scoped lang="scss">

  .tab-select {
    @include flex();
    border-radius: 0.25rem;
    overflow: hidden;
  }

  .tab {
    padding: 0.5rem 1.5rem;
    font-size: 0.8rem;
    font-weight: 600;
    background-color: bg(25%);
    text-transform: capitalize;

    span {
      color: #ccc;
    }

    &:hover {
      cursor: pointer;
      background-color: bg(30%);

      span {
        color: #fff;
      }
    }

    &.selected {
      background-color: $primary;

      span {
        color: #fff;
      }

      &:hover {
        background-color: $primary-hover;
      }
    }
  }

</style>
