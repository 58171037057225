<template>
  <div>

    <Loader v-if="loading" />

    <template v-else>
      <div class="spacer"></div>
      <h2>Media</h2>
      <div class="spacer"></div>

      <div class="media-usage">
        <p>Used {{ usageInMB }} MB / 5 MB</p>
        <LoadingBar height="8" :progress="usageInMB * 20" colorByAmount />
      </div>

      <div class="spacer"></div>

      <p v-if="!media.length">You have no uploaded media.</p>
      <template v-else>

        <div class="note hint">
          <p>Active media marked with a <Icon
            name="check"
            size="0.65rem"
            class="media-icon inline active"
            /> icon are currently being used somewhere on Scrybe and cannot be deleted.</p>
          <div class="spacer h1"></div>
          <p>Remove the image from where it's being used, and then come back to delete it.</p>
        </div>
        <div class="spacer"></div>

        <div class="media-list">
          <div v-for="image in media" class="media">
            <img :src="publicImageURL(image.type, image.name, user._id)" />
            <Button
              icon="delete"
              class="delete-media delete"
              @click.native="deletingMedia = image"
              :disabled="image.status != 'inactive'"
            />
            <Icon
              :name="image.status == 'active' ? 'check' : 'x'"
              size="0.65rem"
              :class="`media-icon ${image.status}`"
            />
          </div>
        </div>
        
      </template>
    </template>


    <ModalContainer v-if="deletingMedia">
      <h4>Delete this media?</h4>

      <div class="spacer h1"></div>
      <div class="media">
        <img :src="publicImageURL(deletingMedia.type, deletingMedia.name, user._id)" />
      </div>
      <div class="spacer h1"></div>
      <Message
        type="warning"
        text="This cannot be undone."
      />
      <div class="spacer h1"></div>

      <div class="row spaced">
        <Button
          label="Yes"
          class="primary"
          @click.native="deleteMedia"
        />
        <Button
          label="No"
          class="delete"
          @click.native="deletingMedia = null"
        />
      </div>
    </ModalContainer>

  </div>
</template>

<script>
  export default {
    name: 'Media',

    computed: {
      user() {
        return this.$store.state.user || {}
      },

      usage() {
        return this.media.length ?
          this.media.map(image => image?.metadataAfter?.size).reduce((previous, current) => previous + current)
          : 0
      },

      usageInMB() {
        return Math.round(this.totalSize * 100 / 1024 / 1024) / 100
      }
    },

    methods: {
      imageURL(image) {
        return `https://media.scrybe-app.com/${image.type}/${image.name}`
      },

      fetchMedia() {
        this.$store.dispatch('fetchMedia').then(response => {
          if (response?.status == 200) {
            this.media = response?.data?.media
            this.totalSize = response?.data?.totalSize || 0
          }
          this.loading = false;
        })
      },

      deleteMedia() {
        this.$store.dispatch('deleteMedia', this.deletingMedia?._id).then(response => {
          console.log(response);
          if (response?.status == 200) {
            this.fetchMedia()
          }
        })
        this.deletingMedia = null
      }
    },

    data() {
      return {
        loading: true,
        media: [],
        totalSize: 0,
        deletingMedia: null
      }
    },

    created() {
      this.fetchMedia()
    }
  }
</script>

<style scoped lang="scss">

  .media-usage {
    p {
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
      text-align: center;
    }
  }

  .note {
    width: 100%;
    max-width: 600px;
    text-align: center;
  }

  .media-list {
    @include flex($justify: center);
    flex-wrap: wrap;
    width: 100%;
    max-width: 1080px;
    padding: 0 2.5rem;
  }

  .media {
    position: relative;
    margin: 0.25rem;
    width: 120px;
    height: 120px;

    img {
      width: 100%;
      height: 100%;
    }

    .delete-media {
      position: absolute;
      top: 0;
      right: 0;
      display: none;
      transform: translate(25%, -25%);
    }

    &:hover {
      .delete-media {
        display: block;
      }
    }
  }

  .media-icon {
    display: inline-block;
    padding: 0.35rem;
    border-radius: 100%;
    color: #fff;
    background-color: rgba(0,0,0,.5);
    border: 1px solid #fff;

    &:not(.inline) {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-40%, -40%);
    }

    &.active {
      color: $alert-success-color;
      border-color: $alert-success-color;
    }

    &.inactive {
      color: $alert-error-color;
      border-color: $alert-error-color;
    }
  }

</style>
