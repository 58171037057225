<template>
  <div
    :class="`avatar ${this.horizontal ? 'horizontal' : ''} ${this.postType ? this.postType : ''}`"
    @click="$router.push('/user/' + userID)"
  >
    <template v-if="userInfo">
      <img :src="publicImageURL('avatar', userInfo.avatarImage, userID)" />
      <h4>{{ userInfo.name }}</h4>
    </template>

    <template v-else>
      <div class="loader-container">
        <Loader size="32px" />
      </div>
      <TextLoader />
    </template>

  </div>
</template>


<script>
  export default {
    name: 'Avatar',

    props: {
      userID: String,
      postType: [String],
      horizontal: [Boolean]
    },

    watch: {
      userID: function(oldValue, newValue) {
        this.loadAvatar();
      }
    },

    computed: {
      userInfo() {
        return this.$store.state.avatars[this.userID]
      },
    },

    created() {
      // If we have no avatar, fetch it
      if (!this.$store.state.avatars[this.userID]) {
        // console.log('NO avatar info for user: ', this.userID);
        this.$store.dispatch('fetchUserAvatar', this.userID).then(response => {
          // this.userInfo = response.data;
          // if (!this.isCustomImage(this.userInfo.avatarImage)) {
          //   this.imageFile = require(`@/assets/${this.userInfo.avatarImage}`);
          // }
          // else this.loadImageFile(this.userInfo.imageFile);
        })
      }
      // If we have the avatar, use it
      // else {
      //   console.log('YES we have avatar info for user: ', this.userID);
      //   this.userInfo = this.$store.state.avatars[this.userID];
      //   if (!this.isCustomImage(this.userInfo.avatarImage)) {
      //     this.imageFile = require(`@/assets/${this.userInfo.avatarImage}`);
      //   }
      //   else this.loadImageFile(this.userInfo.imageFile);
      // }
    },
  }
</script>


<style scoped lang="scss">

  .avatar {
    @include flex($direction: column, $align: center);
    width: $post-avatar-container-width;

    img {
      width: $post-avatar-size;
      height: $post-avatar-size;
      border-radius: 100%;
      transition: box-shadow 0.15s;
    }

    &.reply img {
      width: $post-reply-avatar-size;
      height: $post-reply-avatar-size;
    }

    .text-loader-container {
      margin-top: 0.25rem;
    }

    h4, h5 {
      // white-space: nowrap;
      margin-top: 0.25rem;
      text-align: center;
    }

    h4 {
      color: #ccc;
      font-size: 0.9rem;
      font-weight: 300;
      transition: color 0.15s;
    }

    h5 {
      color: $post-avatar-rank-color;
      font-size: 0.75rem;
      font-weight: 600;
    }

    &:hover {
      cursor: pointer;

      h4 {
        color: $colorA;
      }
      img {
        box-shadow: 0 0 16px rgba($colorA,.25), 0 0 12px -8px $colorA;
      }
    }

    &.horizontal {
      flex-direction: row;
      width: unset;

      img {
        width: $horizontal-avatar-size;
        height: $horizontal-avatar-size;
      }

      h4 {
        margin-left: 0.75rem;
        font-size: 1rem;
      }
    }
  }

  .loader-container {
    position: relative;
    width: $post-avatar-size;
    height: $post-avatar-size;
    @include flex($direction: column, $justify: center);
  }

</style>
