<template>
  <div class="ornament-border" :style="`--borderWidth: ${width || '80px'}; border-image-source: url(${require('@/assets/border_ornament_test9.svg')});`"></div>
</template>

<script>
  export default {
    name: 'OrnamentBorder',
    props: {
      width: String
    }
  }
</script>

<style scoped>

  .ornament-border {
    --restingOpacity: 0.35;
    --borderWidth: 80px;

    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - (var(--borderWidth) * 2));
    width: calc(100% - (var(--borderWidth) * 2));
    box-sizing: content-box;
    margin: auto !important;
    border: var(--borderWidth) solid transparent;
    border-image-slice: 300;
    border-image-repeat: round;
    background-clip: content-box;
    z-index: 2;
    pointer-events: none;
    mask-image: linear-gradient(5deg, rgba(0,0,0,var(--restingOpacity)) 20%, black 30%, rgba(0,0,0,var(--restingOpacity)) 40%, rgba(0,0,0,var(--restingOpacity)) 60%, black 70%, rgba(0,0,0,var(--restingOpacity)) 80%);
    mask-size: 100% 600%;
    mask-position: center top;
    animation-name: gradientCycle;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes gradientCycle {
    0% {
      mask-position: center top;
    }
    100% {
      mask-position: center bottom;
    }
  }

</style>
