<template>
  <div
    class="word-processor"
    @mousedown="onOutsideMouseDown"
    @mouseup="onOutsideMouseUp"
  >

    <div class="toolbar" @mouseup.stop="">
      <Button label="T" @mouseup.native="title" />
      <Button label="S" @mouseup.native="subtitle" />
      <Button label="B" @mouseup.native="bold" />
    </div>


    <div class="nodes-container" id="nodes">
      <div
        v-for="(node, nodeIndex) in nodes"
        :class="'node ' + node.style"
        @mouseup="onNodeMouseUp(nodeIndex)"
      >
        <template v-for="chunk in splitNodeContent(nodeIndex)">

          <div v-if="chunk.type == 'cursor'"
            class="cursor-caret-container"
          >
            <input
              ref="cursor"
              class="cursor-input"
              tabindex="0"
              @keydown.prevent="onInput"
            />
            <div class="cursor-caret"></div>
          </div>


          <span
            v-else
          >{{ chunk.content || '&ZeroWidthSpace;' }}</span>

        </template>

        <LinkDropdown v-if="node.style == 'link' && currentNodeIndex == nodeIndex" />
      </div>

      <div id="click-low" class="node"><span></span></div>
      <div id="click-high" class="node"><span></span></div>
      <div id="next-line" class="node"><span></span></div>
    </div>
  </div>
</template>

<script>
  import LinkDropdown from '../../LinkDropdown.vue'

  const MAX_UNDO_HISTORY = 10

  export default {
    name: 'WordProcessor',
    components: {
      LinkDropdown
    },

    props: {
      content: [Array],
      currentSceneIndex: [Number]
    },

    data() {
      return {
        nodes: [
          {
            style: '',
            content: 'This is a   line that is really long so hopefully it reaches the edge of the page and continues on the following line. Then we will continue even further to get a third line in the same node so we can explore this more.\n'
          },
          {
            style: '',
            content: 'This is '
          },
          {
            style: 'bold',
            content: 'bold'
          },
          {
            style: '',
            content: ' text.\n'
          },
          {
            style: '',
            content: 'A line now\n'
          },
          {
            style: '',
            content: '\n'
          },
          {
            style: '',
            content: 'Another line now'
          }
        ],

        selectionMouseDownEvent: null,

        selection: null,

        currentNodeIndex: 0,
        currentCharIndex: 0,

        undoHistory: [],
        currentHistoryIndex: 0
      }
    },

    watch: {
      currentSceneIndex: function(newValue) {
        console.log(this.cursorChar);
        this.cursorLine = null;
        this.cursorChar = null;
        this.selection = {
          startNode: null,
          startChar: null,
          endNode: null,
          endChar: null
        }
      },

      currentNodeIndex: function(newValue) {
        // console.log('currentNodeIndex', newValue);
      },

      currentCharIndex: function(newValue) {
        // console.log('currentCharIndex', newValue);
      },

      content: function(newContent, oldContent) {
        // this.nodes = this.import(newContent);
      }
    },

    computed: {
      previousNode() {
        if (this.currentNodeIndex <= 0) {
          return null
        }
        return this.nodes[this.currentNodeIndex - 1]
      },

      currentNode() {
        return this.nodes[this.currentNodeIndex]
      },

      nextNode() {
        if (!this.nodes?.length || this.currentNodeIndex >= this.nodes.length - 1) {
          return null
        }
        return this.nodes[this.currentNodeIndex + 1]
      }
    },


    methods: {

      splitNodeContent(nodeIndex) {
        const content = this.nodes[nodeIndex]?.content || ''

        if (this.currentNodeIndex == nodeIndex) {
          return [
            {
              type: 'content',
              content: content.substring(0, this.currentCharIndex)
            },
            {
              type: 'cursor',
              content: ''
            },
            {
              type: 'content',
              content: content.substring(this.currentCharIndex)
            }
          ]
        }

        return [{
          type: 'content',
          content
        }]
      },

      async focusInput() {
        await this.$nextTick()
        this.$refs.cursor[0].focus()
        return true
      },

      // Nodes

      newNode(newChars) {
        return {
          style: '',
          content: ''
        }
      },

      bold() {
        event.stopPropagation();
        this.nodes[this.cursorLine].style = this.nodes[this.cursorLine].style == 'bold' ? '' : 'bold';
        // this.$emit('updated', this.export());
      },
      title() {
        event.stopPropagation();
        this.nodes[this.cursorLine].style = this.nodes[this.cursorLine].style == 'title' ? '' : 'title';
        // this.$emit('updated', this.export());
      },
      subtitle() {
        event.stopPropagation();
        this.nodes[this.cursorLine].style = this.nodes[this.cursorLine].style == 'subtitle' ? '' : 'subtitle';
        // this.$emit('updated', this.export());
      },

      deleteSelection() {
        const { startNode, startChar, endNode, endChar } = this.selection;

        const before = this.nodes[startNode].content.substring(0, startChar)
        const after = this.nodes[endNode].content.substring(endChar)
        this.nodes[startNode].content = before + after

        if (startNode != endNode) {
          // Delete all other nodes up through end node
          this.nodes = this.nodes.filter((node, index) => index <= startNode || index > endNode)
        }

        this.selection = null
        this.currentNodeIndex = startNode
        this.currentCharIndex = startChar
        this.focusInput()
        // this.$emit('updated', this.export());
      },

      export() {
        let exportNodes = [];
        this.nodes.forEach(node => {
          exportNodes.push({
            content: node.chars.join(''),
            style: node.style
          });
        });
        return exportNodes;
      },

      appendHistory() {
        // Reset
        if (this.currentHistoryIndex < this.undoHistory.length - 1) {
          this.undoHistory = []
        }

        if (this.undoHistory.length >= MAX_UNDO_HISTORY) {
          this.undoHistory.shift()
        }

        const savedNodes = JSON.parse(JSON.stringify(this.nodes))
        this.undoHistory.push(savedNodes)
        this.currentHistoryIndex = this.undoHistory.length - 1
      },

      undo() {
        if (this.undoHistory?.length && this.currentHistoryIndex > 0) {
          this.currentHistoryIndex--
          console.log(JSON.parse(JSON.stringify(this.undoHistory[this.currentHistoryIndex])));
          this.nodes = [...this.undoHistory[this.currentHistoryIndex]]
        }
      },

      redo() {
        if (this.undoHistory?.length && this.currentHistoryIndex < this.undoHistory.length - 1) {
          this.currentHistoryIndex++
          console.log(JSON.parse(JSON.stringify(this.undoHistory[this.currentHistoryIndex])));
          this.nodes = [...this.undoHistory[this.currentHistoryIndex]]
        }
      },


      // Key Presses

      splitString(string, index) {
        return {
          before: (string || '').substring(0, index || 0),
          after: (string || '').substring(index || 0)
        }
      },

      appendEmptyNode() {
        this.nodes = [...this.nodes, {
          style: '',
          content: ''
        }]
      },

      splitNodeAtCursor() {
        let newNodes = [...this.nodes]
        newNodes.splice(this.currentNodeIndex + 1, 0, {
          style: this.currentNode.style,
          content: ''
        })

        if (this.currentCharIndex < this.currentNode.content.length) {
          const { before, after } = this.splitString(this.currentNode.content, this.currentCharIndex)
          newNodes[this.currentNodeIndex].content = before + '\n'
          newNodes[this.currentNodeIndex + 1].content = after
        }

        this.nodes = newNodes
        this.currentNodeIndex++
        this.currentCharIndex = 0
      },

      createNodeAtCursor(node) {
        let newNodes = [...this.nodes]
        const current = this.currentNode
        const before = current.content.substring(0, this.currentCharIndex)
        const after = current.content.substring(this.currentCharIndex)

        if (!before) {
          newNodes.splice(this.currentNodeIndex, 1, node)
        }
        else {
          this.nodes[this.currentNodeIndex].content = before
          newNodes.splice(this.currentNodeIndex + 1, 0, node)
          this.currentNodeIndex++
          this.currentCharIndex = 0
        }

        if (after) {
          newNodes.splice(this.currentNodeIndex + 1, 0, {
            style: current.style,
            content: after
          })
        }
        this.nodes = newNodes
      },

      removeNode(index) {
        let newNodes = [...this.nodes]
        newNodes.splice(index, 1)
        this.nodes = newNodes
      },

      mergeNodes(index1, index2) {
        const node1 = this.nodes[index1]
        const node2 = this.nodes[index2]

        let newNodes = [...this.nodes]
        newNodes.splice(index1, 1)
        newNodes[index1].content = node1.content + node2.content
        this.nodes = newNodes

        this.currentCharIndex = node1.content.length
        this.currentNodeIndex--
      },

      async goToNextLine() {
        const node = document.getElementById('nodes').children[this.currentNodeIndex]
        node.class += ' ' + this.nodes[this.currentNodeIndex].style
        console.log(node.offsetHeight);
        // const style = window.getComputedStyle(node)
        // console.log(style);

        let targetNodeIndex = this.currentNodeIndex
        let targetCharIndex = this.currentCharIndex

        // Add one character in case of next line
        let lineText = this.nodes[this.currentNodeIndex].content.substring(0, this.currentCharIndex) + 'a'
        let iterations = 0

        const nextLine = document.getElementById('next-line')

        nextLine.innerText = lineText
        console.log('set line text', lineText);
        await this.$nextTick()

        const startingHeight = nextLine.clientHeight
        console.log('starting height', nextLine.offsetHeight);



        let doneSearching = false

        while (!doneSearching) {
          if (iterations > 1000) break
          iterations++

          // Split string and get next word
          const before = this.nodes[targetNodeIndex].content.substring(0, targetCharIndex)
          const after = this.nodes[targetNodeIndex].content.substring(targetCharIndex)
          const nextWord = after.split(' ')[0]
          console.log('nextWord', after.split(' '));

          // If new line, we're done
          if (nextWord == '\n') {
            console.log('found new line. break.');
            targetCharIndex++
            break
          }

          // Set hidden element text
          lineText = before + nextWord
          nextLine.innerText = lineText
          await this.$nextTick()

          // Check if height has changed
          const currentHeight = nextLine.clientHeight
          console.log(iterations, nextLine.offsetHeight, 'testing: ', lineText);

          if (currentHeight != startingHeight) {
            console.log('height has changed. break.');
            break
          }

          // Move forward by next word plus space
          targetCharIndex += nextWord.length + 1

          // End of node, move to next node
          if (targetCharIndex >= this.nodes[targetNodeIndex].content.length) {
            if (targetNodeIndex >= this.nodes.length) {
              console.log('end of nodes. break.');
              break
            }
            targetNodeIndex++
            targetCharIndex = 0
          }
        }

        const newLineCharIndex = this.currentNodeIndex + targetCharIndex
        if (newLineCharIndex > this.nodes[targetNodeIndex])

        this.currentNodeIndex = targetNodeIndex
        this.currentCharIndex = targetCharIndex
      },

      onInput() {
        event.preventDefault()
        event.stopPropagation()

        const { key, metaKey, shiftKey } = event

        if (metaKey && key == 'l') {
          console.log('node', this.currentNodeIndex, 'char', this.currentCharIndex);
          console.log(JSON.parse(JSON.stringify(this.nodes)));
          console.log(JSON.parse(JSON.stringify(this.undoHistory)));
          console.log(this.currentHistoryIndex);
          return
        }

        if (metaKey && shiftKey && key == 'z') {
          this.redo()
          return
        }
        if (metaKey && key == 'z') {
          this.undo()
          return
        }

        if (metaKey && key == 'a') {
          this.createNodeAtCursor({
            style: 'link',
            content: ''
          })
          this.focusInput()

          return
        }

        switch (key) {
          case 'ArrowRight':
            // Next Character
            if (this.currentCharIndex < this.currentNode.content.length - 1) {
              this.currentCharIndex++
            }
            // Or, Next Line
            else if (this.currentNodeIndex < this.nodes.length - 1) {
              this.currentNodeIndex++
              this.currentCharIndex = 0
            }

            break

          case 'ArrowLeft':
            // Previous Character
            if (this.currentCharIndex > 0) {
              this.currentCharIndex--
            }
            // Or, Previous Line
            else if (this.currentNodeIndex > 0) {
              this.currentCharIndex = this.previousNode.content?.length ? this.previousNode.content?.length - 1 : 0
              this.currentNodeIndex--
            }
            break

          case 'ArrowDown':
            this.goToNextLine()
            break

          case 'Enter':
            if (this.currentNode.style == 'link') {
              if (!this.nextNode) {
                this.appendEmptyNode()
              }
              this.currentNodeIndex++
              this.currentCharIndex = 0
              break
            }

            this.splitNodeAtCursor()
            break

          case 'Backspace':
            console.log(JSON.parse(JSON.stringify(this.selection)));

            if (this.currentNodeIndex <= 0 && this.currentCharIndex <= 0) {
              break
            }

            if (this.currentCharIndex <= 0) {
              const previousContent = this.nodes[this.currentNodeIndex - 1].content || ''
              this.nodes[this.currentNodeIndex - 1].content = previousContent.substring(0, previousContent.length - 1)

              if (!this.previousNode.content?.length) {
                this.removeNode(this.currentNodeIndex - 1)
                this.currentNodeIndex--

                if (this.previousNode && this.previousNode.style == this.currentNode.style) {
                  this.mergeNodes(this.currentNodeIndex - 1, this.currentNodeIndex)
                }
              }
            }
            else {
              const before = this.currentNode.content.substring(0, this.currentCharIndex - 1)
              const after = this.currentNode.content.substring(this.currentCharIndex)
              this.nodes[this.currentNodeIndex].content = before + after
              this.currentCharIndex--
            }
            this.appendHistory()
            break

          default:
            if (key?.length == 1) {
              if (this.previousNode && this.currentCharIndex <= 0) {
                this.nodes[this.currentNodeIndex - 1].content += key
              }
              else {
                const before = this.currentNode.content.substring(0, this.currentCharIndex)
                const after = this.currentNode.content.substring(this.currentCharIndex)
                this.nodes[this.currentNodeIndex].content = before + key + after
                this.currentCharIndex++
              }
              this.appendHistory()
            }
            break
        }

        // else if (key === 'ArrowUp') {
        //   if (this.cursorLine === 0) return;
        //   let previousLine = this.nodes[this.cursorLine - 1];
        //   if (this.cursorChar > previousLine.chars.length - 1) {
        //     this.cursorChar = previousLine.chars.length - 1;
        //   }
        //   this.cursorLine--;
        // }
        //
        // else if (key === 'ArrowDown') {
        //   if (this.cursorLine === this.nodes.length - 1) return;
        //   let nextLine = this.nodes[this.cursorLine + 1];
        //   if (this.cursorChar > nextLine.chars.length - 1) {
        //     this.cursorChar = nextLine.chars.length - 1;
        //   }
        //   this.cursorLine++;
        // }

        this.focusInput()
        // this.$emit('updated', this.export());
      },

      keyDownOutside() {
        if (event.key === 'Backspace') {
          console.log(JSON.parse(JSON.stringify(this.selection)));
          this.deleteSelection();
          // this.$emit('updated', this.export());
        }
      },



      // Clicks

      async findClickedCharacter(event, nodeIndex) {
        // Get style for more specific average
        const node = this.nodes[nodeIndex]
        const low_el = document.getElementById('click-low')
        const high_el = document.getElementById('click-high')
        low_el.class += ' ' + node.style
        high_el.class += ' ' + node.style


        const average_char_length = 8
        const offset = event.offsetX

        const starting_num_chars = offset / average_char_length


        let low = Math.floor(starting_num_chars)
        let high = Math.ceil(starting_num_chars)
        if (low == high) high++

        let low_width = -100
        let high_width = -100
        let iterations = 0
        const MAX_ITERATIONS = 100

        // Offset should be between low and high
        while (low_width > offset || high_width < offset) {
          iterations++

          low_el.children[0].innerText = node.content.substring(0, low)
          high_el.children[0].innerText = node.content.substring(0, high)

          await this.$nextTick()

          low_width = low_el.getBoundingClientRect().width
          high_width = high_el.getBoundingClientRect().width

          if (low_width > offset) {
            low--
            high--
          }
          else if (high_width < offset) {
            low++
            high++
          }

          if (iterations >= MAX_ITERATIONS) break
        }
        console.log('iterations', iterations);

        const low_distance = Math.abs(offset - low_width)
        const high_distance = Math.abs(offset - high_width)

        console.log('offset', event.layerX, 'RESULT', high_distance < low_distance ? high : low);
        return high_distance < low_distance ? high : low
      },

      async nodeClicked(nodeIndex) {
        console.log('nodeClicked');
        event.stopPropagation()

        const clickedCharIndex = await this.findClickedCharacter(event, nodeIndex)

        this.currentNodeIndex = nodeIndex
        this.currentCharIndex = clickedCharIndex

        // await this.focusInput()
      },

      outsideClicked() {
        console.log('outsideClicked');
        this.currentNodeIndex = this.nodes.length - 1
        const content = this.nodes[this.currentNodeIndex].content
        this.currentCharIndex = content.length ? content.length - 1 : 0

        this.focusInput()
      },


      async onNodeMouseUp(nodeIndex) {
        console.log('onNodeMouseUp');
        event.stopPropagation();

        const down = this.selectionMouseDownEvent
        const up = event

        this.setSelectionOrCursor(down, up)
      },

      onOutsideMouseDown() {
        console.log('onOutsideMouseDown');

        this.selectionMouseDownEvent = event

        // Clear cursor
        // Otherwise, text nodes will change
        // and selection won't work
        this.currentNodeIndex = null
        this.currentCharIndex = null
      },

      onOutsideMouseUp() {
        console.log('onOutsideMouseUp');
        event.stopPropagation();

        this.outsideClicked()
      },



      async setSelectionOrCursor(down, up) {

        await this.$nextTick()

        // ========================================
        // GET SELECTION

        const selection = window.getSelection()
        console.log(selection);

        const baseTextEl = selection.baseNode
        const extentTextEl = selection.extentNode
        const baseNodeEl = baseTextEl.parentElement
        const extentNodeEl = extentTextEl.parentElement

        const nodesContainer = baseNodeEl.parentElement.parentElement
        const nodes = nodesContainer.children

        const baseNodeIndex = Array.prototype.indexOf.call(nodes, baseNodeEl.parentElement)
        const baseCharIndex = selection.baseOffset
        const extentNodeIndex = Array.prototype.indexOf.call(nodes, extentNodeEl.parentElement)
        const extentCharIndex = selection.extentOffset


        // ========================================
        // NORMAL CLICK, SET CURSOR POSITION

        if (baseNodeIndex == extentNodeIndex && baseCharIndex == extentCharIndex) {
          this.currentNodeIndex = baseNodeIndex
          this.currentCharIndex = baseCharIndex
          this.focusInput()
          return
        }


        // ========================================
        // SET START AND END IN ORDER

        let startTextEl, startNode, startChar, endTextEl, endNode, endChar;

        // Top first
        if (baseNodeIndex < extentNodeIndex) {
          startTextEl = baseTextEl
          startNode = baseNodeIndex
          startChar = baseCharIndex
          endTextEl = extentTextEl
          endNode = extentNodeIndex
          endChar = extentCharIndex
        }
        // Bottom first
        else if (baseNodeIndex > extentNodeIndex) {
          startTextEl = extentTextEl
          startNode = extentNodeIndex
          startChar = extentCharIndex
          endTextEl = baseTextEl
          endNode = baseNodeIndex
          endChar = baseCharIndex
        }
        // Same line
        else {
          startTextEl = baseTextEl
          endTextEl = extentTextEl
          startNode = baseNodeIndex
          endNode = extentNodeIndex
          startChar = Math.min(baseCharIndex, extentCharIndex)
          endChar = Math.max(baseCharIndex, extentCharIndex)
        }

        const isNotSelection = startNode == endNode && startChar == endChar
        if (!isNotSelection) {
          this.selection = { startNode, startChar, endNode, endChar }
        }
        else {
          this.selection = null
        }


        // ========================================
        // DON'T SET CURSOR,
        // IT WILL CHANGE TEXT NODE STRUCTURE
        // AND RUIN SELECTION RANGE

        // this.currentNodeIndex = extentNodeIndex
        // this.currentCharIndex = extentCharIndex

        return { startNode, startChar, endNode, endChar }
      }
    },

    created() {
      // this.nodes = this.content
      this.undoHistory = [JSON.parse(JSON.stringify(this.nodes))]
    },

    mounted() {
      window.addEventListener('keydown', this.keyDownOutside);
      this.focusInput()
    },

    destroyed() {
      window.removeEventListener('keydown', this.keyDownOutside);
    }
  }
</script>

<style scoped lang="scss">

  .word-processor {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.25rem;
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background-color: rgba(0,0,0,.2);

    &:hover {
      cursor: text;
    }
  }

  .toolbar {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    > *:not(:last-child) {
      margin-right: 0.25rem;
    }

    &:hover {
      cursor: auto;
    }
  }

  .nodes-container {
    position: relative;
  }

  .cursor-input {
    position: absolute;
    margin: 0;
    padding: 0;
    // height: 1px;
    width: 1px;
    background: none;
    border: 0;
    overflow: visible;
    opacity: 0;
  }

  .cursor-caret-container {
    position: relative;
    display: inline-block;
    height: 1em;
    line-height: 1em;
  }

  .cursor-caret {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    align-self: center;
    bottom: -2px;
    height: 100%;
    width: 1px;
    contain: strict;
    background-color: $colorA;
  }

  .node {
    position: relative;
    display: inline;
    white-space: pre-wrap;

    span {
      line-height: 2rem;
    }

    &.bold {
      font-weight: 600;
    }
    &.title {
      margin-bottom: 0.25rem;
      font-size: 1.25rem;
      text-align: center;
      font-weight: 400;
    }
    &.subtitle {
      margin-bottom: 0.25rem;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
      span {
        color: rgba(209,222,255,.5);
      }
    }

    &.link {
      span {
        font-weight: 600;
        color: #edbb6f;
      }
    }

    &:hover {
      cursor: text;
    }
  }

  #click-low, #click-high, #next-line {
    position: absolute !important;
    top: 0;
    left: 0;
    pointer-events: none !important;
    opacity: 0 !important;
  }

  // .last-clicked {
  //   position: absolute;
  //   width: 8px;
  //   height: 8px;
  //   border-radius: 100%;
  //   background-color: #ff46d1;
  //   transform: translate(-50%, -50%);
  // }

</style>
