<template>
  <footer>

    <div class="links">
      <router-link class="logo-container" to="/" exact>
        <img class="logo" :src="logo" />
      </router-link>
      <div class="list">
        <h4>Products</h4>
        <router-link to="/world/example">Demo</router-link>
        <router-link to="/pricing">Pricing</router-link>
      </div>
      <div class="list">
        <h4>Resources</h4>
        <router-link to="/updates">Updates & Fixes</router-link>
        <router-link to="/help/terms-of-service">Terms of Service</router-link>
        <router-link to="/help/privacy-policy">Privacy Policy</router-link>
        <router-link to="/help/faq">FAQ</router-link>
      </div>
    </div>

  </footer>
</template>

<script>
  export default {
    name: 'Footer',
    components: {

    },
    data() {
      return {

      }
    },

    computed: {
      logo() {
        return this.theme() === 'dark' ?
          'https://media.scrybe-app.com/assets/logo/logo_text_vertical_dark.png' :
          'https://media.scrybe-app.com/assets/logo/logo_text_vertical_light.png'
      }
    }
  }
</script>

<style scoped lang="scss">

  footer {
    position: relative;
    width: 100vw;
    height: $footer-height;
    background-color: bg(0%);
    overflow: hidden;
    z-index: 99;
  }

  .logo-container {
    @include flex($justify: center);
    flex-shrink: 0;
    margin: 0;
    padding: 0 4rem;
    transition: opacity 0.3s;

    .logo {
      height: 100px;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .links {
    @include flex($justify: center, $align: flex-start);
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 4rem;

    .list {
      padding: 0 4rem;
      @include flex($direction: column, $align: flex-start);
    }
  }

  h4 {
    margin-bottom: 1rem;
    font-weight: 300;
  }

  a {
    margin: 0.4rem 0;
    color: $colorB;
    font-size: 0.9rem;
    font-weight: 600;

    &:hover {
      cursor: pointer;
      color: $colorA;
    }
  }

</style>
