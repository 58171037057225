<template>
  <div class="creation-item">
    <div v-if="iconName" class="icon">
      <Icon :name="iconName" />
    </div>
    <div class="text">
      <p>{{ data.title }}</p>
      <h6>{{ formatDate(data.lastUpdated, '&mon &dd, &year') }}</h6>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CreationItem',
    components: {

    },
    props: {
      data: Object
    },
    computed: {
      iconName() {
        if (this.data.category === 'characters') {
          return 'wizard'
        }
        else if (this.data.category === 'places') {
          return 'mountain'
        }
      }
    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped lang="scss">

  .creation-item {
    @include flex();
    margin: 0.25rem 0;
    padding: 1rem 1.25rem;
    border-radius: 0.25rem;
    background-color: bg(15%);

    .text {
      @include flex($direction: column, $align: flex-start);
    }

    h6 {
      color: $colorB;
    }

    &:hover {
      cursor: pointer;
      background-color: bg(10%);
    }
  }

  .icon {
    margin-right: 0.5rem;
    color: $colorA;
  }

</style>
