<template>
  <div>

    <Loader v-if="loading" />

    <template v-else>
      <div class="spacer"></div>
      <h2>Following</h2>
      <div class="spacer"></div>

      <div class="following-worlds">
        <!-- <div v-for="world in followingWorlds"
          class="world floating"
          @click="$router.push('/world/' + world._id)"
        >
          <img :src="world.headerImage" />
          <div class="world-info">
            <h3>{{ world.name }}</h3>
            <div class="spacer h1"></div>
            <h6>Last Updated</h6>
            <h5>{{ formatDate(world.lastUpdated, '&weekday, &month &dd, &year') }}</h5>
          </div>
        </div> -->
        <WorldPreview
          v-for="world in followingWorlds"
          :worldID="world._id"
          @click.native="$router.push('/world/' + world._id)"
        />
      </div>

      <p v-if="!followingWorldsExist">You are not following any worlds.</p>

    </template>

  </div>
</template>

<script>
  import WorldPreview from '../../World/WorldPreview.vue'

  export default {
    name: 'Following',
    components: {
      WorldPreview
    },

    data() {
      return {
        loading: true,
        followingWorlds: null
      }
    },

    computed: {
      followingWorldsExist() {
        if (!this.loading && !this.followingWorlds?.length) return false;
        return true;
      }
    },

    created() {
      this.$store.dispatch('fetchFollowingWorlds').then(response => {
        this.loading = false;
        this.followingWorlds = response?.data;
      })
    }
  }
</script>

<style scoped lang="scss">

  .following-worlds {
    @include flex($justify: center);
    flex-wrap: wrap;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 0 3rem;
  }

  .world {
    width: 400px;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    background-color: bg(20%);

    &:hover {
      cursor: pointer;
      background-color: bg(25%);
    }

    img {
      width: 100%;
      height: 200px;
    }
  }

  .world-info {
    @include flex($direction: column);
    padding: 2rem;

    h3 {
      color: $colorA;
    }
    h5 {
      color: $colorB;
    }
    h6 {
      color: neutral(75%);
    }
  }

</style>
