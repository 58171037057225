import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { appMutations, appActions } from './app'
import { userMutations, userActions } from './user'
import { notificationMutations, notificationActions } from './notifications'
import { paymentMutations, paymentActions } from './payment'

import { worldMutations, worldActions } from './world'

import { writingMutations, writingActions } from './creation/writing'
import { wikiMutations, wikiActions } from './creation/wiki'
import { timelineMutations, timelineActions } from './creation/timeline'
import { mapMutations, mapActions } from './creation/maps'
import { languageMutations, languageActions } from './creation/language'

import { postMutations, postActions } from './community/posts'
import { forumMutations, forumActions } from './community/forum'


export default new Vuex.Store({

  state: {

    loading: false,
    scrollLocked: false,
    feedbackAlertVisible: true,

    user: null,
    userFetched: false,
    theme: 'dark',
    notifications: null,

    avatars: {},

    worldBrowser: [],

    world: null,

    writings: null,

    wikiPages: null,

    timelines: null,

    maps: null,
    generatedMap: null,

    discover: {
      total: 0,
      posts: []
    },
    following: {
      total: 0,
      posts: []
    },

    // forum: {
    //   currentSubforum: {
    //     name: 'discussions',
    //     threads: null
    //   },
    //   currentThread: {
    //     _id: null,
    //     title: null,
    //     posts: null
    //   }
    // },

    languages: null

  },

  mutations: {
    ...appMutations,
    ...userMutations,
    ...notificationMutations,
    ...paymentMutations,

    ...worldMutations,

    ...writingMutations,
    ...wikiMutations,
    ...timelineMutations,
    ...mapMutations,
    ...languageMutations,

    ...postMutations,
    ...forumMutations,
  },

  actions: {
    ...appActions,
    ...userActions,
    ...notificationActions,
    ...paymentActions,

    ...worldActions,

    ...writingActions,
    ...wikiActions,
    ...timelineActions,
    ...mapActions,
    ...languageActions,

    ...postActions,
    ...forumActions,
  },

  modules: {

  }

})
