<template>
  <div class="toggle-container" @click="toggleSwitch">
    <!-- <svg class="mask" viewBox="0 0 120 40">
      <path d="M 30 0   C 45 0 45 5 60 5   C 75 5 75 0 90 0   C 120 0 120 40 90 40   C 75 40 75 35 60 35   C 45 35 45 40 30 40   C 0 40 0 0 30 0" />
    </svg> -->
    <svg :class="`bg ${isOn ? 'on' : 'off'}`" viewBox="0 0 120 40">
      <path d="M 30 0   C 45 0 50 5 60 5   C 70 5 75 0 90 0   C 120 0 120 40 90 40   C 75 40 70 35 60 35   C 50 35 45 40 30 40   C 0 40 0 0 30 0" />
    </svg>
    <svg :class="`inset-shadow ${isOn ? 'on' : 'off'}`" viewBox="0 0 120 40">
      <path d="M 30 0   C 45 0 50 5 60 5   C 70 5 75 0 90 0   C 120 0 120 40 90 40   C 75 40 70 35 60 35   C 50 35 45 40 30 40   C 0 40 0 0 30 0" />
    </svg>
    <div :class="`switch ${isOn ? 'on' : 'off'} ${startingPosition} ${clickedDirection}`">
      <Icon :class="`toggleIcon on ${isOn ? 'appear' : 'disappear'}`" name="moon" size="18px" />
      <Icon :class="`toggleIcon off ${isOn ? 'disappear' : 'appear'}`" name="sun" size="18px" />
    </div>
  </div>
</template>

<script>
  import Icon from './Icon.vue';

  export default {
    name: 'ThemeToggle',
    components: {
      Icon
    },
    props: {
      isOn: true
    },
    data() {
      return {
        icon: 'moon',
        startingPosition: 'startRight',
        clickedDirection: null,
      }
    },
    methods: {
      toggleOn() {
        this.clickedDirection = 'goRight';
        this.$emit('on');
      },
      toggleOff() {
        this.clickedDirection = 'goLeft';
        this.$emit('off');
      },
      toggleSwitch() {
        if (!this.clickedDirection) {
          if (this.startingPosition === 'startLeft') this.toggleOn();
          else if (this.startingPosition === 'startRight') this.toggleOff();
        }
        else {
          if (this.clickedDirection === 'goLeft') this.toggleOn();
          else if (this.clickedDirection === 'goRight') this.toggleOff();
        }
      }
    },
    mounted() {
      this.startingPosition = this.isOn ? 'startRight' : 'startLeft';
    }
  }
</script>

<style scoped lang="scss">

  .toggle-container {
    --edge: 6px;
    --width: 90px;
    --height: 30px;
    --iconSize: 36px;

    position: relative;
    width: var(--width);
    height: var(--height);

    &:hover {
      cursor: pointer;
    }


    svg {
      position: absolute;
      height: var(--height);
      fill: #000;
      transition: fill 0.5s;
      // transform: rotate(180deg);

      // &.mask {
      //   fill: rgba(0,0,0,0);
      //   z-index: -1;
      // }
      &.inset-shadow {
        opacity: 0.35;
        z-index: -2;
        filter: blur(8px);
        &.on {
          fill: #62b7ff;
        }
        &.off {
          fill: #262b32;
        }
      }
      &.bg {
        z-index: -1;
        &.on {
          fill: #171717;
        }
        &.off {
          fill: #fff;
        }
      }

    }
    .switch {
      position: absolute;
      top: calc((var(--height) - var(--iconSize)) / 2);
      display: flex;
      justify-content: center;
      align-items: center;
      width: var(--iconSize);
      height: var(--iconSize);
      border-radius: 100%;
      box-sizing: border-box;
      background-color: #b6cbbb;
      z-index: 2;
      transition: background 0.5s, border 0.5s, box-shadow 0.5s;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;

      .toggleIcon {
        position: absolute;
        opacity: 1;
        transition: fill 0.5s;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;

        &.on {
          fill: #def8ff;
        }
        &.off {
          fill: #fcc500;
        }

        &.appear {
          animation-name: IconAppear;
        }
        &.disappear {
          animation-name: IconDisappear;
        }
      }

      &.startLeft {
        left: var(--edge);
      }
      &.startRight {
        left: calc(var(--width) - var(--height) - var(--edge));
      }

      &.on {
        background-color: #2b2d42;
        border: 1px solid #d1f0ff;
        box-shadow: 0 0 6px rgba(#fff,.75);
      }
      &.off {
        background-color: #f1f7ff;
        border: 1px solid #adcbe6;
        box-shadow: 0 0 6px rgba(#212b32,.25);
      }

      &.goLeft {
        animation-name: rightToLeft;
      }
      &.goRight {
        animation-name: leftToRight;
      }
    }
  }

  @keyframes leftToRight {
    0% {
      width: var(--iconSize);
      height: var(--iconSize);
      left: var(--edge);
    }
    50% {
      width: calc(var(--iconSize) - 5px);
      height: calc(var(--iconSize) - 5px);
      transform: translateY(2.5px);
    }
    100% {
      width: var(--iconSize);
      height: var(--iconSize);
      left: calc(var(--width) - var(--iconSize) - var(--edge));
    }
  }
  @keyframes rightToLeft {
    0% {
      width: var(--iconSize);
      height: var(--iconSize);
      left: calc(var(--width) - var(--iconSize) - var(--edge));
    }
    50% {
      width: calc(var(--iconSize) - 5px);
      height: calc(var(--iconSize) - 5px);
      transform: translateY(2.5px);
    }
    100% {
      width: var(--iconSize);
      height: var(--iconSize);
      left: var(--edge);
    }
  }

  @keyframes IconAppear {
    0% {
      width: 10px;
      opacity: 0;
    }
    50% {
      width: 10px;
      opacity: 0;
    }
    100% {
      width: 18px;
      opacity: 1;
    }
  }
  @keyframes IconDisappear {
    0% {
      width: 18px;
      opacity: 1;
    }
    50% {
      width: 10px;
      opacity: 0;
    }
    100% {
      width: 10px;
      opacity: 0;
    }
  }

</style>
