<template>
  <div
    ref="slide"
    :class="`slide ${isInView ? 'in-view' : ''} ${shouldRender ? 'visible' : ''}`"
    :style="styleString"
  >
    <slot></slot>
  </div>
</template>


<script>
  export default {
    name: 'Slide',

    props: {
      position: Number,
      slideWidth: Number,
      border: Number,
      firstInvisibleIndex: Number
    },

    watch: {
      slide: function(newValue, oldValue) {
        // this.offsetIndex += newValue - oldValue;
        // if (newValue < this.leftVisibleIndex) {
        //   // Gone off screen left, move to right
        //   this.offsetIndex += this.numSlides
        // }
        // if (newValue > this.rightVisibleIndex) {
        //   this.offsetIndex -= this.numSlides
        // }
      },
    },

    data() {
      return {
        offsetIndex: 0
      }
    },

    computed: {
      offset() {
        return this.position * this.slideWidth
      },

      styleString() {
        return `left: ${this.offset}px; transform: translateX(-50%) scale(${this.scale(0.1)}); z-index: ${this.scale(0.1) * 10}; opacity: ${this.scale(0.25)};`
      },

      isInView() {
        return (this.position > this.firstInvisibleIndex * -1) && (this.position < this.firstInvisibleIndex)
      },

      shouldRender() {
        return (this.position >= this.firstInvisibleIndex * -1) && (this.position <= this.firstInvisibleIndex)
      }
    },

    methods: {
      scale(num = 1) {
        const s = 1 / num
        return (s - Math.abs(this.position)) / s
      },
    },
  }
</script>


<style scoped lang="scss">

  .slide {
    position: absolute;
    opacity: 1;
    transition: left 0.5s, transform 0.5s, opacity 0.5s;

    &:not(.visible) {
      display: none !important;
      user-select: none !important;
      pointer-events: none !important;
    }

    &:not(.in-view) {
      opacity: 0 !important;
      user-select: none !important;
      pointer-events: none !important;
    }
  }

</style>
