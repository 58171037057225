<template>
  <div :style="`backgroundImage: url(${image});`">
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
  export default {
    name: 'Banner',
    props: {
      title: String,
      image: String
    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped lang="scss">

  div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 280px;
    margin-bottom: 2rem;
    background-size: cover;
    background-position: center 90%;
    background-repeat: no-repeat;

    h1 {
      padding: 1rem 2rem;
      color: #fff;
      font-family: 'Raleway', sans-serif;
      font-size: 3rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      z-index: 3;
      filter: drop-shadow(0 0 8px rgba(255,255,255,.75));
      // backdrop-filter: blur(6px);
      user-select: none;
    }
  }

</style>
