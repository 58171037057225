<template>
  <div
    :class="`tutorial-hint floating ${visible ? 'visible' : ''} ${left ? 'left' : ''} ${right ? 'right' : ''}`"
  >
    <div class="hint-triangle"></div>
    <div style="margin-right: 0.75rem;">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppDiagramBubble',

    props: {
      left: [Boolean],
      right: [Boolean],
      visible: [Boolean]
    }
  }
</script>

<style scoped lang="scss">

  .tutorial-hint-container {
    width: 100%;
    height: 0px;
  }

  .tutorial-hint {
    position: absolute;
    top: -1rem;
    left: 100%;
    transform: translate(0, -100%);
    @include flex();
    width: 200px;
    padding: 1rem 1.25rem;
    border-radius: 0.25rem;
    background-color: #216e58;
    text-align: left;
    opacity: 0;
    transition: all 0.3s;
    z-index: 999 !important;

    .title {
      color: $colorD;
      text-align: center;
    }

    ul, ul li {
      list-style-position: inside;
      margin: 0;
      padding: 0;
    }

    p, ul, ul li {
      font-size: 0.9rem;
      line-height: 1.5em;
    }

    &.visible {
      opacity: 1;
    }
  }

  .hint-triangle {
    position: absolute;
    left: -12px;
    bottom: -15px;
    border: 10px solid transparent;
    border-bottom: 16px solid #216e58;
    transform: rotate(-135deg);
  }


  .tutorial-hint.left {
    left: calc(100% + 1rem);
    right: unset;

    .hint-triangle {
      left: -12px;
      right: unset;
      transform: rotate(-135deg);
    }
  }

  .tutorial-hint.right {
    right: calc(100% + 1rem);
    left: unset;

    .hint-triangle {
      right: -12px;
      left: unset;
      transform: rotate(135deg);
    }
  }

</style>
