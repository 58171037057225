<template>
  <div>
    <!-- <Banner title="Inspiration" :image="require('@/assets/inspiration_bg.jpg')" /> -->
    <MyCollections />
  </div>
</template>

<script>
  import MyCollections from './MyCollections.vue';

  export default {
    name: 'InspirationHome',
    components: {
      MyCollections
    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped lang="scss">

</style>
