<template>
  <div class="pagination">
    <Button
      icon="left-arrow"
      class="large-button dark"
      :disabled="page <= 0"
      @click.native="previousPage"
    />
    <!-- <p>{{ (page * limit) + 1 }} - {{ (page * limit) + limit > total ? total : (page * limit) + limit }} of {{ total }}</p> -->
    <p>Page {{ page + 1 }} of {{ Math.ceil(total / limit) }}</p>
    <Button
      icon="right-arrow"
      class="large-button dark"
      :disabled="(page * limit) + limit >= total"
      @click.native="nextPage"
    />
  </div>
</template>


<script>
  export default {
    name: 'Pagination',

    props: {
      page: Number,
      limit: Number,
      total: Number
    },

    methods: {
      params(page) {
        return {
          page,
          skip: page * this.limit,
          limit: this.limit
        }
      },

      previousPage() {
        if (this.page > 0) {
          this.$emit('update', this.params(this.page - 1))
        }
      },

      nextPage() {
        if ((this.page * this.limit) + this.limit < this.total) {
          this.$emit('update', this.params(this.page + 1))
        }
      },
    },
  }
</script>


<style scoped lang="scss">

  .pagination {
    @include flex($justify: center);
    display: inline-flex;
    border-radius: 0.5rem;
    background-color: bg(18%);
    overflow: hidden;

    p {
      font-family: 'Cinzel Decorative', cursive;
      font-size: 0.9rem;
      padding: 0 1rem;
      // letter-spacing: 1px;
    }
  }

  .large-button {
    padding: 1rem !important;
    border-radius: 0 !important;
  }

</style>
