<template>
  <div>

    <Loader v-if="loading" />

    <template v-else>

      <div class="spacer"></div>
      <h1 class="title colorful-title">Manage Subscription</h1>
      <div class="spacer"></div>
      <div class="description">
        <p class="hint">All payment on Scrybe is handled by <a href="https://stripe.com" target="_blank">Stripe</a>, the world's most trusted online payment service. We don't touch any credit card details or sensitive personal information.</p>
      </div>
      <div class="spacer"></div>


      <SubscriptionStatus
        :subscription_status="user.subscription_status"
        :current_plan="current_plan"
      />


      <CoolButton
        :label="user.subscription_status == 'active' ? 'Edit Subscription' : 'Subscribe'"
        type="compact"
        animation="infinite"
        textColor="#91fabb"
        bgColor="rgb(61,218,143)"
        @click.native="$router.push('/subscribe')"
      />


      <template v-if="current_plan && user.subscription_status == 'active'">
        <div class="spacer h4"></div>

        <div class="customer-portal floating">
          <p class="hint">Use the customer portal, hosted at Stripe, to update your card details or cancel your subscription.</p>
          <div class="spacer"></div>
          <div class="row spaced">
            <Button
              label="Update Card Details"
              class="secondary inline"
              @click.native="openPortal"
            />
            <Button v-if="!current_plan.cancel_at"
              label="Cancel Subscription"
              class="delete inline"
              @click.native="openPortal"
            />
            <Button v-else
              label="Undo Cancel Subscription"
              class="primary inline"
              @click.native="openPortal"
            />
          </div>
        </div>
      </template>

      <p v-if="error" class="error">{{ error }}</p>



      <div class="spacer h5"></div>
      <h3>Subscription History</h3>

      <template v-if="!subscription_history.length">
        <div class="spacer h1"></div>
        <p class="hint">You have no subscription history.</p>
      </template>

      <div v-else class="update-timeline">
        <div v-for="(update, index) in subscription_history"
          :class="`update ${formatUpdateClass(index)}`"
        >
          <h6>{{ formatDate(update.created * 1000, '&weekday, &month &dd, &year | &hour:&min') }}</h6>

          <div class="row space-between">
            <div class="info">
              <h4 class="capitalize">{{ update.type }}</h4>
              <!-- <p class="hint capitalize">Source: {{ update.trigger }}</p> -->
              <!-- <p class="capitalize">Status: {{ update.status }}</p> -->
            </div>
            <h3 v-if="update.type != 'cancelled' && update.type != 'expired'">{{ price(update.amount_paid) }}</h3>
          </div>


          <template v-if="update.type == 'cancelled'">
            <p>Will expire on <span class="period">{{ formatDate(update.period_end * 1000, '&month &dd, &year') }}</span></p>
          </template>

          <template v-else>
            <div v-for="item in (update.lines || [])"
              class="subscription-line-item"
            >
              <p class="hint">{{ item.name }}</p>
              <p>for <span class="period">{{ formatDate(item.period_start * 1000, '&month &dd, &year') }} - {{ formatDate(item.period_end * 1000, '&month &dd, &year') }}</span></p>
            </div>
          </template>


          <div class="spacer h1"></div>
          <a
            v-if="update.hosted_invoice_url"
            :href="update.hosted_invoice_url"
            target="_blank"
            style="display: inline-block;"
          >
            <Button
              label="View Invoice"
              class="primary"
            />
          </a>
        </div>
      </div>

      <div class="spacer h5"></div>

    </template>
  </div>
</template>

<script>
  import SubscriptionStatus from './SubscriptionStatus.vue'

  export default {
    name: 'ManageSubscription',
    components: {
      SubscriptionStatus
    },

    computed: {
      user() {
        return this.$store.state.user;
      },

      subscription_history() {
        return this.subscription_history_data?.length ?
          this.subscription_history_data.map(update => update?.info).reverse()
          : []
      },

      current_plan() {
        if (!this.subscription_history[0]) {
          return null
        }

        const latest_item = this.subscription_history[0]
        const items = latest_item.lines?.length ?
          latest_item.lines.map(line => line.name) :
          ['No items']
        console.log('latest_item', JSON.parse(JSON.stringify(latest_item)));


        // not yet
        // return null

        // will cancel
        // return {
        //   items,
        //   elements: Object.keys(latest_item.permissions || {}),
        //   total: 500,
        //   has_paid: latest_item.invoice_status == 'paid',
        //   last_payment: latest_item.period_start,
        //   next_payment: latest_item.period_end,
        //   cancel_at: 1665306870
        // }

        // cancelled
        // return {
        //   items: ['(No items)'],
        //   elements: Object.keys(latest_item.permissions || {}),
        //   total: 0,
        //   has_paid: latest_item.invoice_status == 'paid',
        //   last_payment: latest_item.period_start,
        //   next_payment: latest_item.period_end,
        //   cancel_at: 1665306870,
        //   canceled_at: 1665306870
        // }

        return {
          items,
          elements: Object.keys(latest_item.permissions || {}),
          total: latest_item.total,
          has_paid: latest_item.invoice_status == 'paid',
          last_payment: latest_item.period_start,
          next_payment: latest_item.period_end,
          cancel_at: latest_item.cancel_at,
          canceled_at: latest_item.canceled_at
        }
      }
    },

    data() {
      return {
        loading: true,
        error: null,
        subscription_history_data: [],
        invoices: []
      }
    },

    methods: {
      formatUpdateClass(index) {
        if (this.subscription_history?.length <= 1) {
          return 'only'
        }
        if (index == 0) {
          return 'first'
        }
        else if (index == this.subscription_history?.length - 1) {
          return 'last'
        }
      },

      price(num) {
        return `$${(num / 100).toFixed(2)}`
      },

      openPortal() {
        this.$store.dispatch('openCustomerPortal').then(response => {
          console.log(JSON.parse(JSON.stringify(response)));
          this.loading = false;
          if (response.status == 200 && response?.data?.url) {
            window.location.href = response.data.url
          }
          else {
            this.error = response?.data?.error;
          }
        })
      },

      test(endpoint) {
        this.$store.dispatch('testStripe', endpoint).then(response => {
          console.log(response);
        })
      }
    },

    created() {
      this.$store.dispatch('fetchSubscriptionHistory').then(response => {
        console.log(JSON.parse(JSON.stringify(response)));
        this.loading = false;
        if (response.status == 200 && response?.data?.length) {
          this.subscription_history_data = response?.data;
        }
        else {
          this.error = response?.data?.error;
        }
      })
    }
  }
</script>

<style scoped lang="scss">

  a {
    color: $colorB;
    font-weight: 600;

    &:hover {
      color: $colorA;
    }
  }

  .title {
    font-size: 2rem;
  }

  .description {
    max-width: 600px;
    margin: 0 3rem;
    text-align: center;
  }

  .customer-portal {
    @include flex($direction: column);
    max-width: 540px;
    padding: 2rem;
    background-color: #000;
    text-align: center;
  }

  .invoices-container {
    @include flex($direction: column);
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
    padding: 0 3rem;
  }

  .invoice {
    @include flex($justify: space-between);
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem 1.5rem;
    border-radius: 0.25rem;
    background-color: bg(20%);

    > p {
      color: neutral(70%);
      font-size: 0.9rem;
    }
  }

  .info {
    @include flex($direction: column, $align: flex-start);

    .invoice-id {
      margin-bottom: 0.75rem;
      color: neutral(60%);
    }
    .price {
      margin-bottom: 0.25rem;
      color: $colorA;
      font-weight: 300;
    }
    .date {
      color: $colorB;
      font-weight: 300;
    }
    .line-item {
      @include flex($justify: space-between, $align: center);
    }
  }

  .error {
    color: $alert-error-color;
  }

  .update-timeline {
    position: relative;
    padding: 3rem;
    padding-top: 2rem;
  }

  .update {
    position: relative;
    padding: 2rem 0;
    padding-left: 3rem;
    min-width: 450px;

    .info {
      @include flex($direction: column, $align: flex-start);
    }

    .period {
      color: $colorD;
      font-size: 0.9rem;
    }

    h3 {
      color: $colorA;
    }

    ul {
      margin: 0;
      padding-left: 2rem;

      li {
        padding-top: 0.25rem;
        font-size: 0.9rem;
        opacity: 0.65;
      }
    }

    h6 {
      padding-bottom: 0.5rem;
      color: $colorB;
      font-size: 0.8rem;
      font-weight: 300;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
      background-color: rgba($colorC, 0.25);
      transform: translateX(-50%);
    }

    &.first::before {
      top: unset;
      bottom: 0;
      height: 50%;
    }

    &.last::before {
      height: 50%;
    }

    &.only::before {
      height: 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: $colorA;
      transform: translate(-50%, -50%);
    }

    &.gap {
      padding-top: 5rem;
      padding-bottom: 5rem;

      &::before {
        background-color: rgba($colorC, 0.75);
        width: 2px;
      }

      &::after {
        opacity: 0;
      }
    }
  }

  .capitalize {
    text-transform: capitalize;
  }


  @media screen and (max-width: $breakpoint-small) {
    .update {
      min-width: unset;
    }
  }

</style>
