
export default {

  mode: process.env.VUE_APP_MODE, // development, staging, production

  developmentServer: 'http://localhost:8777',
  stagingServer: 'http://167.172.139.55',
  productionServer: 'https://scrybe-app.com',

  notificationSocket: {
    development: 'ws://localhost:9777/notifications',
    staging: 'ws://167.172.139.55:9777/notifications',
    production: 'ws://scrybe-app.com:9777/notifications'
  }

}
