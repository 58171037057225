<template>
  <div class="folder-container">

    <div class="folder"
      @click="isOpen = !isOpen"
    >
      <Icon
        :name="isOpen ? 'less-arrow' : 'more-arrow'"
        size="16px"
        class="arrow"
      />

      <input v-if="renamingWriting && renamingWriting._id == folder._id"
        type="text"
        :value="folder.title"
        @input="(e) => $emit('rename-input', e.target.value)"
        @click.stop=""
      />
      <p v-else>{{ folder.title }}</p>

      <div class="directory-actions">
        <Button
          label="New Writing"
          icon="new file"
          class="dark"
          @click.native.stop="createWriting(false)"
        />
        <Button
          label="New Folder"
          icon="new folder"
          class="dark"
          @click.native.stop="createWriting(true)"
        />
        <template v-if="editing">
          <template v-if="renamingWriting && renamingWriting._id == folder._id">
            <Button
              icon="check"
              class="primary"
              style="margin-left: auto;"
              @click.native.stop="$emit('rename')"
            />
            <Button v-if="renamingWriting"
              icon="x"
              class="delete"
              style="margin-left: 0.5rem;"
              @click.native.stop="$emit('cancel-rename')"
            />
          </template>
          <template v-else>
            <Button
              icon="edit"
              style="margin-left: auto;"
              @click.native.stop="$emit('click-rename', folder)"
            />
            <Button
              icon="delete"
              class="delete"
              style="margin-left: 0.5rem;"
              @click.native.stop="$emit('click-delete', folder)"
            />
          </template>
        </template>
      </div>
    </div>


    <div v-if="isOpen" class="folder-content">


      <div v-if="!children.length" class="no-content">
        <p>This folder is empty.</p>
      </div>

      <template v-for="item in children">

        <Folder v-if="item.isFolder"
          :folder="item"
          :editing="editing"
          :renamingWriting="renamingWriting"
          @click-rename="(writing) => $emit('click-rename', writing)"
          @rename-input="(input) => $emit('rename-input', input)"
          @rename="$emit('rename')"
          @cancel-rename="$emit('cancel-rename')"
          @click-delete="(writing) => $emit('click-delete', writing)"
          @create-writing="(params) => $emit('create-writing', params)"
        />

        <div v-else
          class="item"
          @click="clickItem(item._id)"
        >
          <div class="info">
            <input v-if="renamingWriting && renamingWriting._id == item._id"
              type="text"
              :value="item.title"
              @input="(e) => $emit('rename-input', e.target.value)"
            />
            <p v-else>{{ item.title }}</p>
            <h6>{{ formatDate(item.lastUpdated, '&mon &dd, &year') }}</h6>
          </div>
          <template v-if="editing">
            <template v-if="renamingWriting && renamingWriting._id == item._id">
              <Button
                icon="check"
                class="primary"
                style="margin-left: auto;"
                @click.native="$emit('rename')"
              />
              <Button
                icon="x"
                class="delete"
                style="margin-left: 0.5rem;"
                @click.native="$emit('cancel-rename', item)"
              />
            </template>
            <template v-else>
              <Button
                icon="edit"
                style="margin-left: auto;"
                @click.native="$emit('click-rename', item)"
              />
              <Button
                icon="delete"
                class="delete"
                style="margin-left: 0.5rem;"
                @click.native="$emit('click-delete', item)"
              />
            </template>
          </template>
        </div>

      </template>
    </div>

  </div>
</template>

<script>
  import Folder from './Folder.vue'

  export default {
    name: 'Folder',
    components: {
      Folder
    },

    props: {
      folder: Object,
      editing: [Boolean],
      renamingWriting: [Object]
    },

    data() {
      return {
        isOpen: false
      }
    },

    computed: {
      children() {
        return this.folder?.children ?
          Object.values(this.folder.children) : []
      }
    },

    methods: {
      clickItem(id) {
        if (!this.editing) this.$router.push('/writing/' + id)
      },

      createWriting(isFolder) {
        this.$emit('create-writing', {
          parentID: this.folder._id,
          isFolder: isFolder
        })
      }
    }
  }
</script>

<style scoped lang="scss">

  .folder-container {
    width: 100%;
  }

  .directory-actions {
    @include flex();
    margin-left: auto;

    > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .folder {
    @include flex($justify: space-between);
    width: 100%;
    margin: 0.25rem 0;
    padding: 1rem;
    background-color: bg(25%);

    .info {
      @include flex($direction: column, $align: flex-start);
    }

    h6 {
      color: $colorB;
    }

    &:hover {
      cursor: pointer;
      background-color: bg(30%);

      p {
        color: $colorA;
      }
    }
  }

  .arrow {
    color: $colorA;
    margin-right: 0.75rem;
  }

  .no-content {
    padding: 0.75rem;
    background-color: bg(25%);
  }

  .folder-content {
    margin-top: 0.5rem;
    margin-left: 2rem;
    border-left: 2px solid #888;
  }

  .item {
    @include flex($justify: space-between);
    width: 100%;
    margin: 0.25rem 0;
    padding: 1rem;
    background-color: bg(25%);

    .info {
      @include flex($direction: column, $align: flex-start);
    }

    h6 {
      color: $colorB;
    }

    &:hover {
      cursor: pointer;
      background-color: bg(30%);

      p {
        color: $colorA;
      }
    }
  }

</style>
