<template>
  <nav>
    <div :class="checkActive('Dashboard')"
      @click="$router.push('/dashboard')">
      <Icon name="home" size="1.25rem" />
      <p>Home</p>
    </div>
    <div :class="checkActive('UserSettings')"
      @click="$router.push('/dashboard/settings')">
      <Icon name="user-settings" size="1.25rem" />
      <p>Account Settings</p>
    </div>
    <div :class="checkActive('LoginHistory')"
      @click="$router.push('/dashboard/login-history')">
      <Icon name="server" size="1.25rem" />
      <p>Login History</p>
    </div>
    <div :class="checkActive('Following')"
      @click="$router.push('/dashboard/following')">
      <Icon name="eye flat" size="1.25rem" />
      <p>Following</p>
    </div>
    <div :class="checkActive('Invitations')"
      @click="$router.push('/dashboard/invitations')">
      <Icon name="mail" size="1.25rem" />
      <p>Invitations</p>
    </div>
    <div :class="checkActive('NotificationHistory')"
      @click="$router.push('/dashboard/notification-history')">
      <Icon name="bell" size="1.25rem" />
      <p>Notification History</p>
    </div>
    <div :class="checkActive('ManageSubscription')"
      @click="$router.push('/dashboard/manage-subscription')">
      <Icon name="credit card" size="1.25rem" />
      <p>Manage Subscription</p>
    </div>
    <div :class="checkActive('Violations')"
      @click="$router.push('/dashboard/violations')">
      <Icon name="exclamation" size="1.25rem" />
      <p>Violations</p>
    </div>
    <div :class="checkActive('Media')"
      @click="$router.push('/dashboard/media')">
      <Icon name="database" size="1.25rem" />
      <p>Media</p>
    </div>
  </nav>
</template>

<script>
  export default {
    name: 'Dashboard',
    methods: {
      checkActive(name) {
        if (this.$route.name == name) return 'menu-button active'
        return 'menu-button'
      }
    }
  }
</script>

<style scoped lang="scss">

  nav {
    position: absolute;
    @include flex($direction: column);
    top: 2rem;
    left: 0;
    border-radius: 0 0.5rem 0.5rem 0;
    background-color: #2e3034;
    z-index: 9;

    .menu-button {
      position: relative;
      padding: 1.25rem;
      border-radius: 0 0.5rem 0.5rem 0;
      color: #778190;
      transition: color 0.15s, background 0.15s, transform 0.15s, box-shadow 0.25s;

      p {
        position: absolute;
        top: 50%;
        left: 5rem;
        color: $colorA;
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 1em;
        text-transform: uppercase;
        letter-spacing: 0.25px;
        white-space: nowrap;
        pointer-events: none;
        transform: translateY(-50%) translateX(12px);
        opacity: 0;
        transition: opacity 0.3s, transform 0.3s;
        user-select: none;
      }

      &.active {
        color: $colorB;
      }

      &:hover {
        cursor: pointer;
        color: $colorA;
        background-color: #454750;
        box-shadow: -2px 2px 12px -6px rgba(0,0,0,.5);
        transform: translateX(4px);

        p {
          transform: translateY(-50%) translateX(0);
          opacity: 1;
        }
      }
    }
  }

</style>
