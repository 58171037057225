<template>
  <div :class="`async-image ${!isLoaded ? 'image-loading' : ''}`">
    <img
      :src="src"
      @load="loaded"
    />

    <div class="image-loader-container">
      <div class="image-loader"></div>
    </div>
  </div>
</template>


<script>
  export default {
    name: 'AsyncImage',
    props: {
      src: String
    },

    data() {
      return {
        isLoaded: false
      }
    },

    methods: {
      loaded() {
        this.isLoaded = true
      }
    }
  }
</script>


<style scoped lang="scss">

  .async-image {
    position: relative;

    img {
      all: inherit;
    }

    .image-loader-container {
      display: none;
      width: 100%;
      height: 100%;
    }

    .image-loader {
      --restingOpacity: 0.25;

      display: inherit;
      width: 100%;
      height: 100%;
      border-radius: 0.5em;
      background-color: rgba(204,236,255,.1);
      mask-image: linear-gradient(120deg, rgba(0,0,0,var(--restingOpacity)) 20%, black 30%, rgba(0,0,0,var(--restingOpacity)) 40%, rgba(0,0,0,var(--restingOpacity)) 60%, black 70%, rgba(0,0,0,var(--restingOpacity)) 80%);
      mask-image: linear-gradient(120deg, rgba(0,0,0,var(--restingOpacity)) 20%, black 30%, rgba(0,0,0,var(--restingOpacity)) 40%, rgba(0,0,0,var(--restingOpacity)) 60%, black 70%, rgba(0,0,0,var(--restingOpacity)) 80%);
      mask-size: 600% 100%;
      mask-position: center top;
      animation-name: loadingImage;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    &.image-loading {
      img {
        display: none;
      }
      .image-loader-container {
        display: block;
      }
    }
  }

  @keyframes loadingImage {
    0% {
      mask-position: left center;
    }
    100% {
      mask-position: right center;
    }
  }

</style>
