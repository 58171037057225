<template>
  <div :class="`map-page ${newPointModeOn ? 'newPointMode' : ''}`">

    <BackButton
      style="top: 2rem; left: 2rem;"
      @click.native="$router.back()"
    />

    <div class="buttons">
      <Button
        label="New Point"
        icon="plus"
        type="compact"
        :class="`new-point-button ${newPointModeOn ? 'primary' : ''}`"
        @click.native.stop="newPointModeOn = !newPointModeOn"/>
    </div>

    <Hint
      title="Coming Soon"
      icon="more-arrow"
      style="position: absolute; top: 2rem; left: 50%; transform: translateX(-50%); z-index: 99;"
      origin="center bottom"
    >
      <h4 class="title">Coming Soon</h4>
      <div class="spacer h1"></div>
      <ul>
        <li>Images</li>
        <li>Areas & Borders</li>
      </ul>
    </Hint>


    <div id="map"
      @mousedown.stop="onMouseDown"
      @mouseup.stop="isDragging = false"
      @mouseleave.stop="isDragging = false"
      @mouseenter="onDrag"
      @mousemove="onDrag"
    >
      <div id="map-image-container"
        :style="mapImageTransform"
        @mouseenter="onCursorMove"
        @mousemove="onCursorMove"
      >

        <img id="map-image"
          :src="mapData ? publicImageURL('maps', mapData.image) : ''" />

        <div class="map-point"
          v-for="point in points"
          :style="pointTransform(point)"
          @mouseenter="onMouseEnterMarker(point)"
          @mouseleave="onMouseLeaveMarker"
          @click="clickMarker(point)"
        >
          <Icon class="marker"
            :name="point.icon"
            size="24px"
            :style="markerTransform(point)"/>
          <div class="shadow"
            :style="markerTransform(point)"></div>
        </div>

        <div class="point-info"
          v-if="selectedPoint && !editing"
          :style="selectedPoint ? infoTransform(selectedPoint) : ''"
        >
          <h4>{{ selectedPoint.name }}</h4>
          <p>{{ selectedPoint.description }}</p>
          <h6>Click to edit</h6>
        </div>

        <div class="editing-point-info"
          v-if="selectedPoint && editing"
          :style="selectedPoint ? infoTransform(selectedPoint) : ''"
          @mousedown.stop=""
        >
          <div class="row">
            <ColorPicker
              :color="selectedPoint.color"
              @color-picked="(color) => changeColor(color, selectedPoint)" />
            <Select
              class="icon-dropdown"
              :value="selectedPoint.icon"
              :options="iconListWithColor"
              optionsStyle="display: flex; justify-content: center; padding: 1.25rem 1rem;"
              @select="(icon) => changeIcon(icon, selectedPoint)"
              @wheel.native.stop=""
              @mousedown.stop="" />
            <Button icon="delete"
              @click.native="deletePointModalVisible = true" />
          </div>

          <input
            :value="selectedPoint.name"
            @change="(e) => changeName(e, selectedPoint)"
            @mousedown.stop="" />
          <input
            :value="selectedPoint.description"
            @change="(e) => changeDescription(e, selectedPoint)"
            @mousedown.stop="" />
          <Button label="Done"
            icon="check"
            type="inline"
            @click.native="doneEditing" />
        </div>

      </div>
    </div>

    <Modal v-if="deletePointModalVisible"
      message="Delete this point?"
      :buttons="['yes', 'no']"
      @modal-yes="deletePoint(selectedPoint)"
      @modal-no="deletePointModalVisible = false"
    />

  </div>
</template>

<script>
  import { pause } from '@/utility';
  import iconList from './iconList'

  export default {
    name: 'Map',
    components: {
    },
    data() {
      return {
        mapData: null,
        iconList: iconList,

        map: null,
        dimensions: {
          width: 1,
          height: 1
        },

        zoom: 1,
        maxZoom: 3,
        minZoom: 0.5,
        zoomStep: 0.01,

        cursor: {
          x: 0,
          y: 0
        },

        isDragging: false,
        displace: {
          x: 0,
          y: 0
        },
        displaceBorder: 100,

        newPointModeOn: false,
        points: null,
        selectedPoint: null,
        editing: false,

        deletePointModalVisible: false
      }
    },

    computed: {
      isAuthor() {
        if (this.mapData &&
          this.$store.state.user &&
          this.mapData.authorID == this.$store.state.user._id) {
          return true;
        }
        return false;
      },

      halfWidth() {
        return this.dimensions ? this.dimensions.width / 2 : 0;
      },
      halfHeight() {
        return this.dimensions ? this.dimensions.height / 2 : 0;
      },

      halfOverflow() {
        return {
          x: this.overflow.x / 2,
          y: this.overflow.y / 2
        }
      },

      leftEdge() {
        return -1 * (this.halfOverflow.x + this.displaceBorder);
      },
      rightEdge() {
        return this.halfOverflow.x + this.displaceBorder;
      },
      topEdge() {
        return -1 * (this.halfOverflow.y + this.displaceBorder);
      },
      bottomEdge() {
        return this.halfOverflow.y + this.displaceBorder;
      },

      currentMapWidth() {
        return this.dimensions.width * this.zoom;
      },
      currentMapHeight() {
        return this.dimensions.height * this.zoom;
      },

      overflow() {
        if (!this.dimensions) return;
        const { width, height } = this.dimensions;
        const map = document.getElementById('map');

        let currentWidth = width * this.zoom;
        let currentHeight = height * this.zoom;
        let x = currentWidth - map.clientWidth;
        let y = currentHeight - map.clientHeight;
        if (x < 0) x = 0;
        if (y < 0) y = 0;

        return { x, y }
      },

      mapImageTransform() {
        if (!this.dimensions) return;
        const { zoom, displace } = this;
        const { width, height } = this.dimensions;

        let cursor = 'default';
        if (this.newPointModeOn) cursor = 'crosshair';
        else {
          if (this.isDragging) cursor = 'grabbing';
          else cursor = 'grab';
        }

        return `width: ${zoom * width}px;` +
          ` height: ${zoom * height};` +
          ` transform: translateX(calc(-50% + ${-displace.x}px)) translateY(calc(-50% + ${-displace.y}px));` +
          ` cursor: ${cursor};`;
      },

      iconListWithColor() {
        return this.iconList.map(icon => { return {...icon, color: this.selectedPoint.color || '#fff' } })
      }
    },

    methods: {
      loadMap() {
        let image = new Image();
        image.src = this.publicImageURL('maps', this.mapData.image);

        setTimeout(() => {
          this.dimensions = {
            width: image.width,
            height: image.height
          }
        }, 500);
      },

      iconDisplace(point) {
        let i = this.iconList.find(item => item.icon == point.icon);
        if (i) return i.displace;
      },

      pointTransform(point) {
        return `left: ${point.x * 100}%; top: ${point.y * 100}%;`;
      },

      infoTransform(point) {
        return this.pointTransform(point) +
          ` transform: translate(-50%, calc(-100% - 1.5rem + ${this.iconDisplace(point)}px))`;
      },

      markerTransform(point) {
        return `color: ${point.color};` +
          ` transform: translateY(${this.iconDisplace(point)}px);`;
      },

      onScroll() {
        event.stopPropagation();

        const {
          minZoom,
          maxZoom,
          zoomStep,
          zoom,
          displace,
          displaceBorder,
          overflow,
          halfOverflow,
          cursor,
          leftEdge,
          rightEdge,
          topEdge,
          bottomEdge
        } = this;

        let zoomAmount = -event.deltaY * zoom / 1000;
        this.zoom += zoomAmount;

        if (this.zoom > maxZoom) this.zoom = maxZoom;
        else if (this.zoom < minZoom) this.zoom = minZoom;
        else {
          // Center on cursor while zooming in
          if (event.deltaY < 0) {
            let fromCenter = {
              x: cursor.x - 0.5,
              y: cursor.y - 0.5
            }
            let widthIncrease = zoomAmount * this.dimensions.width;
            let heightIncrease = zoomAmount * this.dimensions.height;
            this.displace.x += fromCenter.x * widthIncrease;
            this.displace.y += fromCenter.y * heightIncrease;
          }
          // Center on map while zooming out
          else {
            if (overflow.x) {
              let x = displace.x;
              if (x < leftEdge) x = leftEdge;
              if (x > rightEdge) x = rightEdge;

              let fromCenter = x / (overflow.x + displaceBorder * 2);
              let widthIncrease = zoomAmount * this.dimensions.width;
              this.displace.x += fromCenter * widthIncrease;
            }
            if (overflow.y) {
              let y = displace.y;
              if (y < topEdge) y = topEdge;
              if (y > bottomEdge) y = bottomEdge;

              let fromCenter = y / (overflow.y + displaceBorder * 2);
              let heightIncrease = zoomAmount * this.dimensions.height;
              this.displace.y += fromCenter * heightIncrease;
            }
          }
        }

        this.onCursorMove();
        this.limitBounds();
      },

      onCursorMove() {
        const { zoom, displace } = this;

        let xLocation = event.offsetX;
        let xPercent = xLocation / this.currentMapWidth;

        let yLocation = event.offsetY;
        let yPercent = yLocation / this.currentMapHeight;

        this.cursor = {
          x: xPercent,
          y: yPercent
        }
      },

      onMouseDown() {
        if (this.newPointModeOn) {
          this.addPoint(this.cursor.x, this.cursor.y);
        }
        else {
          this.isDragging = true;
          this.onDrag();
        }
      },

      onDrag() {
        if (!this.isDragging) return;
        if (this.zoom <= this.minZoom) return;

        this.displace.x -= event.movementX;
        this.displace.y -= event.movementY;

        this.limitBounds();
      },

      limitBounds() {
        const {
          leftEdge,
          rightEdge,
          topEdge,
          bottomEdge
        } = this;

        if (!this.overflow.x) {
          this.displace.x = 0;
        }
        else {
          if (this.displace.x < leftEdge) {
            // console.log('left');
            this.displace.x = leftEdge;
          }
          else if (this.displace.x > rightEdge) {
            // console.log('right');
            this.displace.x = rightEdge;
          }
        }

        if (!this.overflow.y) {
          this.displace.y = 0;
        }
        else {
          if (this.displace.y < topEdge) {
            // console.log('top');
            this.displace.y = topEdge;
          }
          else if (this.displace.y > bottomEdge) {
            // console.log('bottom');
            this.displace.y = bottomEdge;
          }
        }
      },

      loadPoints() {
        if (!this.mapData.points || !this.mapData.points.length) return;

        this.points = {};
        this.mapData.points.forEach(point => {
          this.points[point._id] = point;
        });

        console.log(this.points);
      },

      addPoint(x, y) {
        let points;
        if (!this.points) points = {};
        else points = { ...this.points };

        let randomID = Math.round(Math.random() * 99999999).toString(16);
        let newPoint = {
          _id: randomID,
          x,
          y,
          icon: 'pin',
          color: '#ff5444',
          name: 'Name',
          description: 'Description'
        };

        points[randomID] = newPoint;
        this.points = points;

        this.selectedPoint = newPoint;
        this.editing = true;
        this.newPointModeOn = false;
      },

      deletePoint(point) {
        delete this.points[point._id];

        this.selectedPoint = false;
        this.deletePointModalVisible = false;
        this.editing = false;

        this.$store.dispatch('updateMapPoints', {
          mapID: this.mapData._id,
          points: this.points
        });
      },

      onMouseEnterMarker(point) {
        if (!this.editing) this.selectedPoint = point;
      },

      onMouseLeaveMarker() {
        if (!this.editing) this.selectedPoint = null;
      },

      clickMarker(point) {
        if (!this.editing) this.editing = true;
      },

      changeIcon(icon, point) {
        point.icon = icon;
      },

      changeColor(color, point) {
        point.color = color;
      },

      changeName(e, point) {
        point.name = e.target.value;
      },

      changeDescription(e, point) {
        point.description = e.target.value;
      },

      doneEditing() {
        this.editing = false;
        this.selectedPoint = null;
        this.$store.dispatch('updateMapPoints', {
          mapID: this.mapData._id,
          points: this.points
        });
      },

      onClick() {
      }
    },

    created() {
      this.$store.dispatch('fetchMap', this.$route.params.id).then(response => {
        if (response.status === 200) {
          this.mapData = response.data;
          this.loadPoints();
        }
        this.loadMap();
      });
    },

    mounted() {
      window.addEventListener('wheel', this.onScroll);
      window.addEventListener('click', this.onClick);
    },
    destroyed() {
      window.removeEventListener('wheel', this.onScroll);
      window.removeEventListener('click', this.onClick);
    }
  }
</script>

<style scoped lang="scss">

  .map-page {
    position: fixed;
    width: 100vw;
    height: calc(100vh - #{$navigation-height});
    overflow: hidden;

    &.newPointMode {
      cursor: crosshair;
    }
  }

  .back-button {
    top: 2rem;
    left: 2rem;
  }

  .buttons {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;

    .new-point-button {
      top: 2rem;
      right: 2rem;
      &.on {
        background-color: $colorC;
      }
    }
  }

  #map {
    height: 100%;
    width: 100%;
  }

  #map-image-container {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    user-select: none;
    cursor: grab;
  }

  #map-image {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .map-point {
    position: absolute;
    transform: translate(-50%, -50%);

    .marker {
      position: relative;
      color: #ff5444;
      z-index: 2;
    }

    .shadow {
      position: absolute;
      top: 10px;
      left: -2px;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: #000;
      filter: blur(8px);
      opacity: 0.75;
      z-index: 1;
    }

    &:hover {
      cursor: pointer;

      .marker {
        color: #fff !important;
        filter: drop-shadow(0 0 8px #fff);
      }
    }
  }

  .point-info, .editing-point-info {
    position: absolute;
    left: 50%;
    display: block;
    padding: 1.25rem 1.5rem;
    border-radius: 4px;
    background-color: rgba(56,47,44,.8);
    text-align: center;
    transform: translate(-50%, calc(-100% - 1.5rem));
    z-index: 3;

    .icon-dropdown {
      width: 70px;
      margin: auto;
    }

    > *:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    h4 {
      font-family: 'Raleway', 'Avenir', sans-serif;
      font-size: 0.9rem;
      font-weight: 600;
      color: #fff;
      text-transform: uppercase;
      text-shadow: 0 0 2px #000, 0 0 8px #000;
      white-space: pre;
    }

    p {
      font-size: 0.8rem;
      color: #bbb;
    }

    h6 {
      padding-top: 0.5rem;
      color: $colorA;
    }

    input {
      width: 100%;
    }
  }

</style>
