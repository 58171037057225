<template>
  <div class="page">
    <div class="spacer h3"></div>

    <h1 class="colorful-title" style="font-size: 1.75rem;">Updates & Fixes</h1>

    <div class="spacer h5"></div>


    <div class="update-timeline">

      <div class="update first">
        <h6>2022</h6>
        <h4>Beta Release</h4>
      </div>

      <div class="update">
        <h6>April 15, 2022</h6>
        <p>Added Pricing page</p>
        <p>Implemented Media resizing on upload to improve efficiency</p>
      </div>

      <div class="update">
        <h6>April 11, 2022</h6>
        <p>Minor UI/UX fixes across platform</p>
        <p>Fixes to Login / Register inputs</p>
      </div>

      <div class="update">
        <h6>March 4, 2022</h6>
        <p>Implemented cloud storage for Media</p>
        <p>Added Media usage section in Dashboard</p>
        <p>Added Help / FAQ section</p>
        <p>Added Element privacy option</p>
        <p>Redesigned navigation bar</p>
        <p>Added user violations section</p>
      </div>

      <div class="update">
        <h6>February 28, 2022</h6>
        <p>Resolved inconsistencies between Element APIs</p>
        <p>Fixed Create World not updating worlds</p>
        <p>Fixed sections not updating in <span class="wikis">Wikis</span></p>
      </div>

      <div class="update">
        <h6>February 7, 2022</h6>
        <p>Reworked Map Generator</p>
        <p>Reworked <span class="languages">Languages</span></p>
        <ul>
          <li>Added runes</li>
          <li>Added key mapping for rune typing</li>
        </ul>
      </div>

      <div class="update">
        <h6>February 3, 2022</h6>
        <p>Fixed <span class="timelines">Timelines</span> lines, grid, and touch events</p>
        <p>Added zones</p>
      </div>

      <div class="update">
        <h6>February 2, 2022</h6>
        <p>Reworked <span class="maps">Maps</span> and <span class="timelines">Timelines</span></p>
        <p>Added multiple lines to <span class="timelines">Timelines</span></p>
      </div>

      <div class="update">
        <h6>January 14, 2022</h6>
        <p>Added support for touch screens on <span class="maps">Maps</span> and <span class="timelines">Timelines</span></p>
      </div>

      <div class="update">
        <h6>January 12, 2022</h6>
        <p>Added calendar to <span class="timelines">Timelines</span></p>
      </div>

      <div class="update">
        <h6>December 27, 2021</h6>
        <p>Added introduction & tutorial hints for new users</p>
      </div>

      <div class="update">
        <h6>December 15, 2021</h6>
        <p>Opened site for testing</p>
      </div>

      <div class="update">
        <h6>December 14, 2021</h6>
        <p>Fixed push notifications</p>
      </div>

      <div class="update">
        <h6>December 8, 2021</h6>
        <p>Restructured World Element menus and World UI design</p>
        <p>Added Manage Worlds page</p>
        <p>Rennovated <span class="writings">Writings</span> folder structure</p>
      </div>

      <div class="update">
        <h6>November 27, 2021</h6>
        <p>Integrated Stripe for payments</p>
        <p>Added payment history and subscriptions</p>
        <p>Added follow option to Worlds</p>
      </div>

      <div class="update">
        <h6>November 19, 2021</h6>
        <p>Restructured Elements data into Worlds structure</p>
      </div>

      <div class="update gap">
        <h6>July - November 2021</h6>
        <p>Rested due to burnout 🥵</p>
      </div>

      <div class="update">
        <h6>July 8, 2021</h6>
        <p>Created Worlds structure</p>
        <p>Added example (demo) World</p>
        <p>Added global UI components</p>
      </div>

      <div class="update">
        <h6>June 16, 2021</h6>
        <p>Added customizable map points</p>
        <p>Created unified color theme</p>
        <p>Added creation cycle</p>
        <p>Added user login history</p>
      </div>

      <div class="update">
        <h6>June 11, 2021</h6>
        <p>Created proprietary <span class="maps">Maps</span> from the ground up</p>
      </div>

      <div class="update">
        <h6>June 4, 2021</h6>
        <p>Created <span class="languages">Languages</span></p>
        <p>Fixed Elements attachments in Posts</p>
      </div>

      <div class="update">
        <h6>May 27, 2021</h6>
        <p>Fixed content input in <span class="writings">Writings</span></p>
        <p>Fixes in Map Generator</p>
      </div>

      <div class="update">
        <h6>May 19, 2021</h6>
        <p>Attempted to create <span class="maps">Maps</span> using Leaflet plugin (abandoned)</p>
        <p>Added Map Generator</p>
      </div>

      <div class="update">
        <h6>May 13, 2021</h6>
        <p>Implemented TSL (SSL)</p>
      </div>

      <div class="update">
        <h6>May 8, 2021</h6>
        <p>Added maintenance page</p>
      </div>

      <div class="update">
        <h6>May 6, 2021</h6>
        <p>Created <span class="timelines">Timelines</span></p>
      </div>

      <div class="update">
        <h6>April 16, 2021</h6>
        <p>Added images to <span class="wikis">Wikis</span></p>
        <p>Updated tab icon and title</p>
      </div>

      <div class="update">
        <h6>April 9, 2021</h6>
        <p>Created name "Scrybe" and added logo</p>
        <p>Implemented activation codes & confirmation email for registration</p>
        <p>Redesigned landing page</p>
      </div>

      <div class="update">
        <h6>April 1, 2021</h6>
        <p>Added caching for user avatar images</p>
      </div>

      <div class="update">
        <h6>March 31, 2021</h6>
        <p>Security updates for user authentication</p>
        <p>Added customizable background to <span class="writings">Writings</span> and <span class="wikis">Wikis</span></p>
        <p>Added editable user info in Dashboard</p>
        <p>Added Support section</p>
        <p>Implemented image uploads</p>
        <p>Added comments on posts</p>
      </div>

      <div class="update">
        <h6>March 25, 2021</h6>
        <p>Added landing page</p>
        <p>Created <span class="wikis">Wikis</span></p>
        <p>Redesigned <span class="writings">Writings</span></p>
      </div>

      <div class="update">
        <h6>February 19, 2021</h6>
        <p>Added users & posts</p>
        <ul>
          <li>Login</li>
          <li>Register</li>
          <li>Dashboard</li>
          <li>User Avatar</li>
          <li>Posts</li>
          <li>Notifications</li>
        </ul>
        <div class="spacer h1"></div>
        <p>Created <span class="writings">"Writing Room"</span></p>
      </div>

      <div class="update last">
        <h6>November 20, 2020</h6>
        <p>Project Started</p>
      </div>

    </div>

    <div class="spacer h5"></div>

  </div>
</template>


<script>
  export default {
    name: 'Updates',
  }
</script>


<style scoped lang="scss">

  .update-timeline {
    position: relative;
  }

  .update {
    position: relative;
    padding: 2rem 0;
    padding-left: 3rem;

    h4 {
      color: $colorD;
      font-weight: 400;
      text-transform: uppercase;
    }

    ul {
      margin: 0;
      padding-left: 2rem;

      li {
        padding-top: 0.25rem;
        font-size: 0.9rem;
        opacity: 0.65;
      }
    }

    h6 {
      padding-bottom: 0.5rem;
      color: $colorB;
      font-size: 0.8rem;
      font-weight: 300;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
      background-color: rgba($colorC, 0.25);
      transform: translateX(-50%);
    }

    &.first::before {
      top: unset;
      bottom: 0;
      height: 50%;
    }

    &.last::before {
      height: 50%;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: $colorA;
      transform: translate(-50%, -50%);
    }

    &.gap {
      padding-top: 5rem;
      padding-bottom: 5rem;

      &::before {
        background-color: rgba($colorC, 0.75);
        width: 2px;
      }

      &::after {
        opacity: 0;
      }
    }
  }


  .writings, .wikis, .timelines, .maps, .languages {
    font-weight: 600;
  }
  .writings {
    color: $writings-color;
  }
  .wikis {
    color: $wiki-color;
  }
  .timelines {
    color: $timelines-color;
  }
  .maps {
    color: $maps-color;
  }
  .languages {
    color: $languages-color;
  }

</style>
