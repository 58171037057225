var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"permissions floating"},[_vm._m(0),_c('div',{staticClass:"table"},[_c('div',{staticClass:"table-row"},[_vm._m(1),_c('div',[_c('div',{staticClass:"row"},[_c('Icon',{staticClass:"yes",attrs:{"name":"check"}})],1)]),_c('p',{staticClass:"expires-on"},[_vm._v("Forever")])]),_c('div',{staticClass:"table-row"},[_vm._m(2),_c('div',[_c('div',{staticClass:"row"},[_c('Icon',{staticClass:"yes",attrs:{"name":"check"}})],1)]),_c('p',{staticClass:"expires-on"},[_vm._v("Forever")])]),_c('div',{staticClass:"table-row"},[_vm._m(3),_c('div',[_c('div',{staticClass:"row"},[_c('Icon',{class:_vm.hasPermission('timelines') ? 'yes' : 'no',attrs:{"name":_vm.hasPermission('timelines') ? 'check' : 'x'}})],1)]),_c('p',{staticClass:"expires-on"},[_vm._v(_vm._s(_vm.permissions['timelines'] ? `Until ${_vm.date(_vm.permissions['timelines'] * 1000)}` : '--'))])]),_c('div',{staticClass:"table-row"},[_vm._m(4),_c('div',[_c('div',{staticClass:"row"},[_c('Icon',{class:_vm.hasPermission('maps') ? 'yes' : 'no',attrs:{"name":_vm.hasPermission('maps') ? 'check' : 'x'}})],1)]),_c('p',{staticClass:"expires-on"},[_vm._v(_vm._s(_vm.permissions['maps'] ? `Until ${_vm.date(_vm.permissions['maps'] * 1000)}` : '--'))])]),_c('div',{staticClass:"table-row"},[_vm._m(5),_c('div',[_c('div',{staticClass:"row"},[_c('Icon',{class:_vm.hasPermission('languages') ? 'yes' : 'no',attrs:{"name":_vm.hasPermission('languages') ? 'check' : 'x'}})],1)]),_c('p',{staticClass:"expires-on"},[_vm._v(_vm._s(_vm.permissions['languages'] ? `Until ${_vm.date(_vm.permissions['languages'] * 1000)}` : '--'))])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v("Your Permissions")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"element"},[_c('img',{attrs:{"src":"https://media.scrybe-app.com/assets/elements/writing_icon.png"}}),_c('p',{staticClass:"writings"},[_vm._v("Writings")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"element"},[_c('img',{attrs:{"src":"https://media.scrybe-app.com/assets/elements/wiki_icon.png"}}),_c('p',{staticClass:"wikis"},[_vm._v("Wikis")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"element"},[_c('img',{attrs:{"src":"https://media.scrybe-app.com/assets/elements/timeline_icon.png"}}),_c('p',{staticClass:"timelines"},[_vm._v("Timelines")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"element"},[_c('img',{attrs:{"src":"https://media.scrybe-app.com/assets/elements/map_icon.png"}}),_c('p',{staticClass:"maps"},[_vm._v("Maps")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"element"},[_c('img',{attrs:{"src":"https://media.scrybe-app.com/assets/elements/empty_icon.png"}}),_c('p',{staticClass:"languages"},[_vm._v("Languages")])])])
}]

export { render, staticRenderFns }