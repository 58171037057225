<template>
  <table class="table-container">

    <thead>
      <tr class="row header">

        <td v-for="(header, index) in headers"
          :class="`cell${highlightedIndex === index ? ' highlight' : ''} header`">
          <h4>{{ header.label }}</h4>
        </td>

        <td v-if="!readonly" class="cell button">
          <Button label="Add"
            type="compact"
            color="#5bebb3"
            icon="plus"
            iconSize="12px"
            fontSize="12px"
            @click.native="$emit('add')"/>
        </td>

      </tr>
    </thead>

    <tbody>
      <tr class="row" v-for="(row, rowIndex) in rows">

        <td v-for="(header, columnIndex) in headers"
          :class="`cell${highlightedIndex === columnIndex ? ' highlight' : ''}
            ${editingRow === rowIndex && editingColumn === columnIndex ? ' editing' : ''}`"
          @click="selectCell(rowIndex, columnIndex, row[header.value], header.input)"
        >

          <input v-if="editingRow === rowIndex &&
                       editingColumn === columnIndex &&
                       header.input === 'text'"
            type="text" class="cell-input" ref="cellInput"
            :value="row[header.value]"
            @input="(e) => editingValue = e.target.value" />

          <Select v-if="editingRow === rowIndex &&
                       editingColumn === columnIndex &&
                       header.input === 'select'"
            class="cell-input"
            :options="header.options"
            :value="row[header.value]"
            @select="onSelect" />

          <p v-if="editingRow !== rowIndex || editingColumn !== columnIndex"
            :style="formatCellStyle(row, header)"
          >{{ header.input !== 'select' ? row[header.value] : labelFromValue(row[header.value], header.options) }}</p>

        </td>

        <td v-if="!readonly" class="cell button">
          <Button
            label=""
            class="inline"
            color="#eb5a7d"
            icon="x"
            iconSize="12px"
            fontSize="12px"
            @click.native="$emit('delete', rowIndex)"/>
        </td>

      </tr>
    </tbody>

  </table>
</template>

<script>
  import Button from './Button.vue';
  import Select from './Select.vue';

  export default {
    name: 'Table',
    components: {
      Button,
      Select
    },
    props: {
      headers: Array,
      rows: Array,
      highlightedIndex: Number,
      readonly: [Boolean]
    },
    data() {
      return {
        editingRow: null,
        editingColumn: null,
        editingValue: null,
        previousValue: null
      }
    },
    methods: {
      formatCellStyle(row, header) {
        let styleString = '';
        if (row.formatter) styleString += row.formatter(header.value, row[header.value]);

        if (header.options) {
          let selectedIndex = header.options.findIndex(option => option.value == row[header.value]);
          if (selectedIndex !== -1 && header.options[selectedIndex].color) {
            styleString += `color: ${header.options[selectedIndex].color};`;
          }
        }

        return styleString;
      },

      selectCell(row, column, value, inputType) {
        if (this.readonly) return;
        event.stopPropagation();
        if (this.editingRow !== row || this.editingColumn !== column) this.saveChangesIfAny();

        this.editingRow = row;
        this.editingColumn = column;
        this.previousValue = value;
        this.editingValue = value;

        if (inputType === 'text') this.$nextTick(() => this.$refs.cellInput[0].focus());
      },

      saveChangesIfAny() {
        if (this.editingRow != null && this.editingColumn != null &&
          this.editingValue != null && this.previousValue != null &&
          this.editingValue.trim() &&
          this.editingValue.trim() != this.previousValue.trim()) {

          this.$emit('cell-changed', {
            index: this.editingRow,
            header: this.headers[this.editingColumn].value,
            value: this.editingValue
          });
        }
      },

      onSelect(value) {
        event.stopPropagation();
        this.editingValue = value;
      },

      onClick() {
        this.saveChangesIfAny();
        this.editingRow = null;
        this.editingColumn = null;
        this.editingValue = null;
      },

      labelFromValue(value, options) {
        if (!options || !options.length) return '(No Options)';
        let index = options.findIndex(option => option.value == value);
        if (index === -1) return '(No Label)';
        else return options[index].label;
      }
    },
    mounted() {
      window.addEventListener('click', this.onClick);
    },
    destroyed() {
      window.removeEventListener('click', this.onClick);
    }
  }
</script>

<style scoped lang="scss">

  .table-container {
    position: relative;
    display: table;
    table-layout: fixed;
    border-spacing: 0;
    width: 100%;
    max-width: 1200px;
    padding-top: 0.5rem;
    padding: 1rem;
    border-radius: 4px;
    background-color: #282a2d;
    box-shadow: 0 4px 16px -8px rgba(0,0,0,.25), 0 20px 40px -30px rgba(0,0,0,.5);
  }

  .row {
    display: table-row;
  }

  .cell {
    display: table-cell;
    padding: 1rem;
    border-bottom: 1px solid #34353b;
    text-align: left;

    &:not(.header):not(.button):hover {
      cursor: pointer;
    }

    h4 {
      font-size: 0.8rem;
      font-weight: 600;
      text-align: left;
      opacity: 0.4;
    }

    p {
      display: table-cell;
      font-size: 0.9rem;
      font-weight: 400;
    }

    &.highlight > * {
      color: #52da99;
    }

    &.editing {
      padding: 0.25rem;
    }

    &.button {
      width: 100px;
      padding: 0.25rem;
      text-align: right;
    }
  }

  .cell-input {
    border: 0;
    font-size: 0.9rem;
  }

</style>
