<template>
  <div class="book-icon-library-container">
    <div v-for="shelf in shelves" class="book-icon-shelf"
      :style="`top: ${shelf.top}px; opacity: ${shelf.opacity}; transform: translateY(${scrollY * shelf.scrollFactor}px);`">
      <div class="shelf-left" :style="`transform: scale(${shelf.size});`">
        <BookIcon v-for="book in shelf.left"
          :name="book.name"
          :size="0.5"
          :rotation="book.rotation"
          :colorA="book.colorA"
          :colorB="book.colorB" />
      </div>
      <div class="shelf-right" :style="`transform: scale(${shelf.size});`">
        <BookIcon v-for="book in shelf.right"
          :name="book.name"
          :size="0.5"
          :rotation="book.rotation"
          :colorA="book.colorA"
          :colorB="book.colorB" />
      </div>
    </div>
  </div>
</template>

<script>
  import BookIcon from './BookIcon.vue';

  export default {
    name: 'BookIconShelves',
    components: {
      BookIcon
    },
    props: {
      shelves: Array
    },
    data() {
      return {
        scrollY: 0,

        // shelves: [
        //   {
        //     top: 60,
        //     scrollFactor: 0.1,
        //     size: 1,
        //     opacity: 0.5,
        //     left: [
        //       { name: 'plain big', rotation: 0 },
        //       { name: 'space narrow', rotation: 0 },
        //       { name: 'plain small', rotation: 0 },
        //       { name: 'deco small', rotation: -10 },
        //     ],
        //     right: [
        //       { name: 'elegant narrow', rotation: 10 },
        //       { name: 'deco small', rotation: 0 },
        //       { name: 'plain big', rotation: 0 },
        //       { name: 'elegant wide', rotation: 0 },
        //     ],
        //   },
        //   {
        //     top: 210,
        //     scrollFactor: 0.2,
        //     size: 0.8,
        //     opacity: 0.3,
        //     left: [
        //       { name: 'elegant narrow', rotation: 0 },
        //       { name: 'plain small', rotation: 0 },
        //     ],
        //     right: [
        //       { name: 'plain big', rotation: 0 },
        //       { name: 'plain small', rotation: 10 },
        //       { name: 'deco narrow', rotation: 0 },
        //     ],
        //   },
        //   {
        //     top: 450,
        //     scrollFactor: 0.1,
        //     size: 1,
        //     opacity: 0.5,
        //     left: [
        //       { name: 'elegant narrow', rotation: 0 },
        //       { name: 'plain small', rotation: 0 },
        //     ],
        //     right: [
        //       { name: 'plain big', rotation: 0 },
        //       { name: 'plain small', rotation: 10 },
        //       { name: 'deco narrow', rotation: 0 },
        //     ],
        //   },
        //   {
        //     top: 600,
        //     scrollFactor: 0.15,
        //     size: 0.9,
        //     opacity: 0.4,
        //     left: [
        //       { name: 'plain narrow', rotation: 0 },
        //       { name: 'elegant wide', rotation: 0 },
        //       { name: 'plain small', rotation: -10 },
        //     ],
        //     right: [
        //       { name: 'deco small', rotation: 0 },
        //       { name: 'elegant narrow', rotation: 0 },
        //       { name: 'space narrow', rotation: 0 },
        //       { name: 'plain small', rotation: 0 },
        //       { name: 'plain big', rotation: 0 },
        //       { name: 'plain big', rotation: 0 },
        //     ],
        //   },
        // ]
      }
    },
    methods: {
      onScroll() {
        this.scrollY = window.scrollY;
      }
    },
    mounted() {
      this.scrollY = window.scrollY;
      window.addEventListener('scroll', this.onScroll);
    },
    destroyed() {
      window.removeEventListener('scroll', this.onScroll);
    }
  }
</script>

<style scoped lang="scss">

  .book-icon-library-container {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .book-icon-shelf {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    max-width: 1200px;
    padding: 0 5rem;
    box-sizing: border-box;
    opacity: 0.25;

    .shelf-left, .shelf-right {
      display: flex;
      align-items: baseline;
      // animation-name: float;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
    .shelf-left {
      transform-origin: bottom right;
    }
    .shelf-right {
      transform-origin: bottom left;
    }
  }

  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0px);
    }
  }

</style>
