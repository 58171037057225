<template>
  <div class="user">

    <template v-if="user">
      <Notifications />
      <router-link v-if="user"
        to="/dashboard"
        :class="theme('my-avatar')"
      >
        <img :src="publicImageURL('avatar', user.avatarImage, user._id)" />
        <p>{{ $store.state.user.name }}</p>
      </router-link>
    </template>

    <template v-else>
      <router-link class="login" to="/login">Login</router-link>
      <!-- <router-link class="register" to="/register">Register</router-link> -->
      <CoolButton
        type="compact oval"
        label="Register"
        animation="infinite"
        textColor="#55ffc7"
        bgColor="rgb(30,250,131)"
        selected
        @click.native="$router.push('/register')"
      />

    </template>

  </div>
</template>

<script>
  import Notifications from './Notifications.vue';

  export default {
    name: 'TopNavigationUser',
    components: {
      Notifications
    },

    computed: {
      user() {
        return this.$store.state.user
      },

      // avatarImage() {
      //   let baseURL = window.location.origin;
      //   if (window.location.hostname === 'localhost' && window.location.port == '7700') {
      //     baseURL = 'http://localhost:5000';
      //   }
      //   let filename = this.$store.state.user && this.$store.state.user.avatarImage ?
      //     this.$store.state.user.avatarImage :
      //     'user1.jpg';
      //
      //   if (!this.isCustomImage(filename)) {
      //     return require(`@/assets/${filename}`);
      //   }
      //   return baseURL + '/images/avatar/' + filename;
      // }
    }
  }
</script>

<style scoped lang="scss">

  .my-avatar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 4px;
    border-radius: calc((#{$my-avatar-img-size} + 8px + 1px) / 2);
    background-color: bg(15%);
    // background-color: #212325;
    border: 1px solid transparent;
    transition: background 0.3s;
    user-select: none;

    img {
      width: $my-avatar-img-size;
      height: $my-avatar-img-size;
      border-radius: 100%;
      object-fit: cover;
    }

    p {
      margin-left: 0.75rem;
      padding-right: 1rem;
      color: $colorA;
      font-size: 0.9rem;
      font-weight: 600;
      transition: color 0.3s;
    }

    &:hover {
      cursor: pointer;
      background-color: bg(30%);
    }
  }

  .login, .register {
    padding: 0.5rem 1.5rem;
    border-radius: calc(1em + 0.25rem);
    color: neutral(65%);
    background-color: bg(15%);
    font-size: 0.8rem;
    font-weight: 600;
    transition: color 0.3s, background 0.3s;
    user-select: none;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    &:hover {
      color: $colorA;
      background-color: bg(20%);
    }
  }

  .register {
    color: #fff;
    background-color: $primary;

    &:hover {
      background-color: bg(0%);
    }
  }

  @media screen and (max-width: $breakpoint-medium) {
    p {
      display: none;
    }
    .my-avatar:hover {
      opacity: 0.7;
    }
  }

</style>
