
import Help from '../components/Help/Help.vue'
import HelpHome from '../components/Help/HelpHome.vue'
import FAQ from '../components/Help/FAQ.vue'
import TermsOfService from '../components/Help/TermsOfService.vue'
import PrivacyPolicy from '../components/Help/PrivacyPolicy.vue'
import StoringPasswords from '../components/Help/Articles/StoringPasswords.vue'
import InappropriateContent from '../components/Help/Articles/InappropriateContent.vue'

export default [
  { path: '/help', name: 'Help', component: Help, children: [
    { path: '', name: 'HelpHome', component: HelpHome },
    { path: 'faq', name: 'FAQ', component: FAQ },
    { path: 'terms-of-service', name: 'TermsOfService', component: TermsOfService },
    { path: 'privacy-policy', name: 'PrivacyPolicy', component: PrivacyPolicy },
    { path: 'storing-passwords', name: 'StoringPasswords', component: StoringPasswords },
    { path: 'inappropriate-content', name: 'InappropriateContent', component: InappropriateContent },
  ] },
]
