<template>
  <div class="modal-container" @click.self="">
    <div class="modal">

      <h4>{{ message || 'Please enter the URL below.' }}</h4>
      <div class="spacer"></div>
      <input type="text" placeholder="URL" @input="onInput" />
      <div class="spacer"></div>
      <Alert :alert="alert" @dismiss="alert = null" />

      <div v-if="buttons" class="modal-buttons">
        <Button
          v-for="(button, index) in buttons"
          :label="button"
          :class="button"
          :key="index"
          @click.native="confirm(button)"
        />
      </div>
      <div v-else class="modal-buttons">
        <Button label="confirm" class="confirm" @click.native="confirm('confirm')" />
        <Button label="cancel" class="cancel" @click.native="$emit(`modal-cancel`)" />
      </div>

    </div>
  </div>
</template>


<script>
  import Button from './Button.vue';
  import Alert from './Alert.vue';

  export default {
    name: 'ModalURLInput',
    components: {
      Button,
      Alert
    },
    props: {
      message: [String],
      buttons: [Array]
    },
    data() {
      return {
        input1: '',
        alert: null
      }
    },
    methods: {
      onInput() {
        this.input1 = event.target.value;
        this.alert = null;
      },

      confirm(button) {
        if (button == 'cancel') {
          this.$emit('modal-cancel')
        }

        if (!this.input1?.length) {
          this.alert = { type: 'warning', message: 'Please enter a URL.' };
          return;
        }

        this.$emit(`modal-${button}`, [this.input1]);
      }
    }
  }
</script>


<style scoped lang="scss">

  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    @include flex($justify: center);
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    z-index: 999;

    .modal {
      position: absolute;
      @include flex($direction: column);
      padding: 2rem;
      border-radius: 1rem;
      color: #fff;
      background-color: rgba(0,0,0,.75);
      border: 1px solid rgba(255,255,255,.25);
      z-index: 9999;

      h4 {
        font-size: 1.25rem;
        white-space: pre-wrap;
      }

      .modal-buttons {
        @include flex($justify: center);

        .confirm {
          color: #5edaad;
        }

        .cancel {
          color: #df4b6e;
        }

        > *:not(:last-child) {
          margin-right: 1.5rem;
        }
      }
    }
  }

  input {
    margin: 0.5rem 0;
    width: 400px;
  }

</style>
