<template>
  <div class="search-bar">

    <input
      type="text"
      id="search-input"
      placeholder="Search Worlds"
      :value="searchText"
      @input="(e) => searchText = e.target.value"
      @keydown="onKeyDown"
      @focus="buttonsVisible = true"
      @blur="buttonsVisible = false"
    />

    <div v-if="searchText && searchText.trim()"
      :class="`buttons ${buttonsVisible ? 'visible' : ''}`"
    >
      <Button
        class="compact clear"
        mini
        label=""
        icon="x thin"
        iconSize="1rem"
        @mousedown.native.prevent="clearSearch"
      />
      <Button
        class="compact"
        label="Search"
        icon="check"
        @mousedown.native.prevent="search"
      />
    </div>

  </div>
</template>

<script>
  export default {
    name: 'SearchBar',

    props: {
      searchCleared: [Boolean]
    },

    watch: {
      searchCleared: function(newValue) {
        this.searchText = null;
      }
    },

    data() {
      return {
        buttonsVisible: false,
        searchText: null
      }
    },

    methods: {
      onKeyDown() {
        if (event.key == 'Enter') {
          this.search()
        }
      },

      search() {
        if (!this.searchText?.trim()) {
          return;
        }

        const searchInput = document.getElementById('search-input');
        searchInput.blur()

        this.$emit('search', this.searchText)
      },

      clearSearch() {
        this.searchText = null;
        this.$emit('clear')
      }
    }
  }
</script>

<style scoped lang="scss">

  .search-bar {
    position: relative;
    width: 100%;
  }

  #search-input {
    height: 3rem;
    padding: 0 1.75rem;
    border-radius: 1.5rem;
    border: 0 !important;
    background-color: rgba(0,0,0,.5);
    font-size: 0.9rem;

    &:focus {
      background-color: rgb(0,0,0);
    }
  }

  .buttons {
    position: absolute;
    top: 50%;
    right: 1rem;
    display: inline-flex;
    transform: translateY(-50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;

    &.visible {
      opacity: 1;
      pointer-events: auto;
    }

    > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

</style>
