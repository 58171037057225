


// _id: '000',
// title: 'Point A',
// year: 10,
// month: 'January',
// day: 12,
// universalDay: 3662


export default {

  title: 'Binary Tree Timeline',
  _id: '61f8d300d27a3d2ae4b73858',
  authorID: '608b1a9b33c4442b52b8a6cd',
  worldID: '60eebcaba489906664df3d6b',

  start: 5,
  end: 500,

  lines: [
    {
      _id: 'aaa',
      name: 'Binary Tree Line',
      root: {
        title: 'root',
        universalDay: 3662,
        left: {
          title: 'B',
          universalDay: 1240,
          left: {
            title: 'C',
            universalDay: 932
          },
          right: {
            title: 'D',
            universalDay: 1412
          }
        },
        right: {
          title: 'E',
          universalDay: 6355,
          left: {
            title: 'F',
            universalDay: 5433
          },
          right: {
            title: 'G',
            universalDay: 7120
          }
        }
      }
    }
  ],

  levels: []

}
