
import { api } from '@/utility.js'


export const writingMutations = {

  newWriting(state, writing) {
    if (!state.world) return;

    if (!state.world.writings) state.world.writings = {};
    state.world.writings[writing._id] = writing;
  },

  constructWritingsTree(state, writings) {
    if (!state.world) return;
    if (!writings?.length) {
      state.world.writings = [];
      return;
    }

    let root = {};
    writings.forEach(writing => {
      root[writing._id] = writing;
    });

    // console.log('root', JSON.parse(JSON.stringify(root)));

    // Get all non-root folders and the IDs of their children
    let folders = {};
    for (let id in root) {
      const parentID = root[id].parentID;
      if (!parentID) continue;
      if (!folders[parentID]) folders[parentID] = [];
      folders[parentID].push(root[id]._id);
    }

    // For each parent that exists, get all their children
    for (let folderID in folders) {
      const folder = folders[folderID];

      let children = {};
      for (let childID of folder) {
        if (!root[folderID]) root[childID].parentID = null;
        children[childID] = root[childID];
      }
      if (!root[folderID]) continue;
      root[folderID].children = children;
    }

    // Remove all children with a parentID,
    // AKA: All duplicate writings that haven't been moved,
    // Leaving only root writings and folders
    for (let id in root) {
      if (root[id].parentID) delete root[id];
    }

    state.world.writings = Object.values(root);
  },

  loadWritings(state, writings) {
    if (!state.world) return;

    state.world.writings = writings;
  },

  loadCurrentWriting(state, writing) {
    state.currentWriting = writing;
  },

  // renameWriting(state, data) {
  //   let index = state.world.writings.findIndex(writing => writing._id == data.writingID)
  //   state.world.writings[index].title = data.title;
  // },
  //
  // deleteWriting(state, data) {
  //   let newWritings = state.world.writings.filter(writing => writing._id != data.writingID)
  //   state.world.writings = newWritings;
  // },

}


export const writingActions = {

  async fetchWritings({ commit, state }, worldID) {
    return await api('POST', 'creation/writings', { worldID });
  },

  async refreshWritings({ commit, state }) {
    let response = await api('POST', 'creation/writings', { worldID: state.user?.currentWorldID });
    if (response.status == 200 && response?.data) {
      commit('constructWritingsTree', response.data)
    }
    return response;
  },

  async fetchWriting({ commit, state }, id) {
    return await api('GET', 'creation/writing/' + id);
  },

  async newWriting({ commit, state }, writing) {
    let response = await api('POST', 'creation/newWriting', writing);
    if (response.status == 201 && response?.data) {
      commit('newWriting', response.data);
    }
    return response;
  },

  async updateWriting({ commit, state }, data) {
    return await api('PUT', 'creation/updateWriting', data);
  },

  async newWritingFolder({ commit, state }, folder) {
    return await api('POST', 'creation/newWritingFolder', folder);
  },

  async renameWriting({ commit, state }, data) {
    return await api('PUT', 'writing/rename', data);
  },

  async deleteWriting({ commit, state }, data) {
    return await api('POST', 'writing/delete', data);
  },

}
