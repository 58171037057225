
import { api } from '@/utility.js'
import config from '@/config/config'

let socket;

export const notificationMutations = {

  openNotificationSocket(state, store) {
    const io = require('socket.io-client');
    socket = io(config.notificationSocket[config.mode], {
      reconnectionDelayMax: 10000,
      auth: {
        'userID': state.user?._id
      }
    })
    socket.on('connect', () => {
    })
    socket.on('new notification', (notification) => {
      store.commit('pushNotification', notification);
    })
  },

  loadNotifications(state, notifications) {
    if (notifications && notifications.length) {
      state.notifications = notifications.reverse();
    }
  },

  pushNotification(state, notification) {
    if (!state.notifications) state.notifications = [];
    state.notifications.unshift(notification);
  },

  readNotification(state, id) {
    let index = state.notifications.findIndex(n => n._id == id);
    state.notifications[index].read = true;
    // socket.emit('read notification', id);
  }

}


export const notificationActions = {

  async newNotification({ commit, state }, notification) {
    return await api('POST', 'notifications/newNotification', notification);
  },

  async readNotification({ commit, state }, id) {
    commit('readNotification', id);
    return await api('POST', 'notifications/readNotification/' + id);
  }

}
