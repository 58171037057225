
export default {


  name: 'Middle-Earth',
  description: `"Middle-earth", or Endor in Quenya (Ennor in Sindarin) - and in The Book of Lost Tales the Great Lands - are the names used for the habitable parts of Arda after the final ruin of Beleriand, east across the Belegaer from Aman.

    This continent was north of the Hither Lands shown in the Ambarkanta, and west of the East Sea; and throughout the First and Second Ages it underwent dramatic geographical changes, caused by Iluvatar.`,
  headerImage: 'https://i.pinimg.com/originals/72/9f/8b/729f8bdb794cd248d5fd0bf7ac391d9f.jpg',


  writings: [
    {
      _id: 'a32df19234ad1a',
      authorID: 'd82a3c23',
      createdOn: '2021-06-02T08:06:20.888Z',
      lastUpdated: '2021-06-02T08:06:20.888Z',
      visibility: 'public',
      parentID: null,
      title: 'Chapter 1: A Long-Expected Party',
      scenes: [
        {
          backgroundImage: 'https://www.theonering.com/wp-content/uploads/2020/11/gandalf-comes-to-hobbiton-john-howe.jpg',
          overlayColor: '#46534f',
          overlayOpacity: 0.5,
          content: [
            {
              content: 'Chapter 1',
              style: 'font-size: 20px; text-align: center;'
            },
            {
              content: 'A Long-Expected Party',
              style: 'font-size: 24px; text-transform: uppercase; text-align: center; margin-bottom: 2em;'
            },
            {
              content: 'When Mr. Bilbo Baggins of Bag End announced that he would shortly be celebrating his eleventy-first birthday with a party of special magnificence, there was much talk and excitement in Hobbiton.',
              style: ''
            },
            {
              content: 'Bilbo was very rich and very peculiar, and had been the wonder of the Shire for sixty years, ever since his remarkable disappearance and unexpected return. The riches he had brought back from his travels had now become a local legend, and it was popularly believed, whatever the old folk might say, that the Hill at Bag End was full of tunnels stuffed with treasure. And if that was not enough for fame, there was also his prolonged vigour to marvel at. Time wore on, but it seemed to have little effect on Mr. Baggins. At ninety he was much the same as at fifty. At ninety-nine they began to call him well-preserved; but unchanged would have been nearer the mark. There were some that shook their heads and thought this was too much of a good thing; it seemed unfair that anyone should possess (apparently) perpetual youth as well as (reputedly) inexhaustible wealth.',
              style: ''
            },
            {
              content: 'It will have to be paid for,’ they said. ‘It isn’t natural, and trouble will come of it!’',
              style: ''
            },
            {
              content: 'But so far trouble had not come; and as Mr. Baggins was generous with his money, most people were willing to forgive him his oddities and his good fortune. He remained on visiting terms with his relatives (except, of course, the Sackville-Bagginses), and he had many devoted admirers among the hobbits of poor and unimportant families. But he had no close friends, until some of his younger cousins began to grow up.',
              style: ''
            },
            {
              content: 'The eldest of these, and Bilbo’s favourite, was young Frodo Baggins. When Bilbo was ninety-nine he adopted Frodo as his heir, and brought him to live at Bag End; and the hopes of the Sackville-Bagginses were finally dashed. Bilbo and Frodo happened to have the same birthday, September 22nd. ‘You had better come and live here, Frodo my lad,’ said Bilbo one day; ‘and then we can celebrate our birthday-parties comfortably together.’ At that time Frodo was still in his tweens, as the hobbits called the irresponsible twenties between childhood and coming of age at thirty-three.',
              style: ''
            },
            {
              content: 'Twelve more years passed. Each year the Bagginses had given very lively combined birthday-parties at Bag End; but now it was understood that something quite exceptional was being planned for that autumn. Bilbo was going to be eleventy-one, 111, a rather curious number, and a very respectable age for a hobbit (the Old Took himself had only reached 130); and Frodo was going to be thirty-three, 33, an important number: the date of his ‘coming of age’.',
              style: ''
            },
          ],
        }
      ]
    }
  ],




  wikis: [
    {
      _id: 'b1928a09384',
      authorID: 'd82a3c23',
      parentID: null,
      createdOn: '2021-06-02T08:06:20.888Z',
      lastUpdated: '2021-06-02T08:06:20.888Z',
      visibility: 'public',

      category: 'characters',
      title: 'Galadriel',
      subtitle: `Galadriel was the "Lady" of the woods of Lothlórien, which she ruled with Celeborn her husband.

        She was one of the greatest of the Elves in Middle-earth, surpassing nearly all others in beauty, knowledge, and power. She bore Nenya, one of the three Elven rings of power. J.R.R. Tolkien thought of her, along with Gil-galad the Elven-king, as one of the mightiest and fairest of all the Elves left in Middle-earth in the Third Age.

        She was the only daughter and youngest child of Finarfin, prince of the Ñoldor and of Eärwen, whose cousin was Lúthien. Her elder brothers were Finrod Felagund, Orodreth, Angrod, and Aegnor. Galadriel was a niece of Fëanor, the most important Elf of the early First Age.`,
      backgroundImage: 'https://i.pinimg.com/originals/eb/a9/ed/eba9ed0e64e7e6c33fd5e9d5447a672d.jpg',
      images: [
        'https://i.pinimg.com/originals/90/3c/53/903c535f7dcd9da95d649e9bf5f266f2.jpg',
        'https://cdnb.artstation.com/p/assets/images/images/005/596/931/large/raoul-vitale-galadriel.jpg?1492312476',
        'https://cdnb.artstation.com/p/assets/images/images/009/141/225/large/jerry-vanderstelt-galadriels-blessing.jpg?1517349957',
        'https://www.spiderwebart.com/images/art/114633a.jpg',
        'https://cdna.artstation.com/p/assets/images/images/022/312/652/medium/livia-de-simone-galadriel-nuova-copy.jpg?1574943966'
      ],
      headerFields: [
        {
          field: 'Race',
          value: 'Elves'
        },
        {
          field: 'Height',
          value: '6\'4\" (193 cm)'
        },
        {
          field: 'Hair',
          value: 'Platinum'
        },
        {
          field: 'Eyes',
          value: 'Grey'
        },
        {
          field: 'Culture',
          value: `Ñoldor (House of Finarfin)
            Falmari (House of Olwë)`
        },
        {
          field: 'Other Names',
          value: 'Alatáriel, Artanis, Nerwen'
        },
        {
          field: 'Titles',
          value: `Princess of the Ñoldor
            Lady of Lórien, Lady of the Galadhrim`
        },
        {
          field: 'Birth',
          value: 'YT 1362'
        },
        {
          field: 'Rule',
          value: 'TA 1981 – 3021 (as a Lady; 1040 years)'
        },
        {
          field: 'Death',
          value: 'September 29, TA 3021 (sailed west; aged 8,374)'
        },
        {
          field: 'Spouse',
          value: 'Celeborn'
        },
        {
          field: 'Children',
          value: 'Celebrían'
        },
        {
          field: 'Weapon',
          value: 'Nenya (ring), elf-magic'
        }
      ],
      sections: [
        {
          title: 'History',
          content: `Galadriel was born in Valinor in the Years of the Trees, before the First Age had even begun. Much of Galadriel's story is confusing, and there are several distinct tales told about her collected in the Unfinished Tales. According to the older account, used in the published Silmarillion, Galadriel was an eager participant and leader in the rebellion of the Ñoldor and their flight from Valinor due to her desire to one day rule over a patch of Middle-earth herself.

            She was eager to see Middle-earth, having heard of it from Fëanor, and wanted to rule a realm of her own. She swore no oaths and was like minded with her cousin Fingon, son of Fingolfin.

            However, separated from Fëanor and his kin, she and her people did not take part in the Kinslaying at Alqualondë. They followed Fingolfin instead and crossed the Helcaraxë in the far north.`
        },
        {
          title: 'Character',
          content: `The Lady Galadriel - "Lady of Light" - grandmother of Lady Arwen, was an Elven Queen of extraordinary beauty, with her timeless features and golden river of hair.

            Galadriel was highly praised for her beauty, particularly that of her hair, which was a deep and radiant gold, shot with silver. It was said by the elves of Tirion to have ensnared the light of the two trees, Telperion and Laurelin, and possibly to have inspired the creation of the Silmarils by Fëanor. She was also the tallest of elf-women, standing at around 6 feet 4 inches, or 193 centimeters. Because of her wisdom and power, she had very important roles throughout the history of Middle-earth, especially during her time in Eregion and during the War of the Ring. She had a penchant for dressing in the purest white.
            She was known, especially in her younger days, as being quite prideful. However, by the end of the Third Age, that pride had been tempered a great deal by age.`
        },
        {
          title: 'Powers',
          content: `She was, however, no mere fey being, but a lady of great power. She bore one of the Great Rings - Nenya - the Ring of Adamant, and with the One Ring in her grasp as well, she would be a mighty match for the Dark Lord Sauron.

            Galadriel possessed a tremendous amount of magical powers, and was said to be the greatest of the Ñoldor after Fëanor.

            When she still lived in the Undying Lands, she had been a pupil of Yavanna and Aulë, and she later became a friend of Melian the Maia. Hence, it can be deduced that she had learned her powers from these three illustrious personages, though the nature of her magic is still not well-understood.

            The vast majority of Galadriel's powers stemmed from the legendary artifact she controlled: Nenya, the Ring of Water. In some tales, she was also depicted with the powers of communicating over vast distances, predicting the future, and even cloaking her mind from other tremendously powerful beings (she once stated that Sauron could not perceive her mind, though she could perceive his). It is also possible that her powers of divination were either derived from or accentuated by her mirror, which could reveal events of the past, present, and future.`
        },
        {
          title: 'Etymology',
          content: `Galadriel was originally named Artanis ("noble woman") which was her Father-name and Nerwen ("man-maiden"), which was her Mother-name (referring to her height and strength). Galadriel is the Sindarinized version of Telerin Quenya Alatáriel, the name given to her by her lover Celeborn, meaning "maiden crowned with a garland of bright radiance", which referred to her hair.

            The name Galadhriel was used outside Lórien by the people who did not know the ancient days and Galadriel's history, confusing her name with the Elvish word galadh ("tree") and the name of the Galadhrim, the people of Lórien.`
        }
      ]
    },

    {
      _id: 'a783400abdda',
      authorID: 'd82a3c23',
      parentID: null,
      createdOn: '2021-06-02T08:06:20.888Z',
      lastUpdated: '2021-06-02T08:06:20.888Z',
      visibility: 'public',

      category: 'places',
      title: 'Minas Tirith',
      subtitle: `Minas Tirith, formerly and eventually again known as Minas Anor, was from the year TA 1640 the capital city of Gondor.

        Many important events took place in and in front of the city, such as the Battle of the Pelennor Fields and the coronation of Aragorn II Elessar.`,
      backgroundImage: 'https://i.pinimg.com/originals/eb/a9/ed/eba9ed0e64e7e6c33fd5e9d5447a672d.jpg',
      images: [
        'https://i.pinimg.com/originals/7c/24/ee/7c24ee4c7935851ef8dc333de9e94a90.jpg',
        'https://c4.wallpaperflare.com/wallpaper/586/440/883/digital-art-artwork-the-lord-of-the-rings-minas-tirith-wallpaper-preview.jpg',
        'https://render.fineartamerica.com/images/rendered/search/poster/images/artworkimages/medium/3/minas-tirith-aneta-soukalova.jpg',
        'https://cdna.artstation.com/p/assets/images/images/006/406/926/large/carlo-salandanan-minas-tirith.jpg?1498368730'
      ],
      headerFields: [
        {
          field: 'Other names',
          value: 'Minas Anor'
        },
        {
          field: 'Location',
          value: 'Easternmost point of the White Mountains, close to the Anduin'
        },
        {
          field: 'Regions',
          value: 'Gondor'
        },
        {
          field: 'Inhabitants',
          value: 'Men of Gondor'
        },
        {
          field: 'Governance',
          value: `King of Gondor
            Steward of Gondor`
        },
        {
          field: 'Founded/Built',
          value: 'SA 3320'
        },
      ],
      sections: [
        {
          title: 'History',
          content: `The city was built to guard against the men of the White Mountains in the year SA 3320 by the Faithful, those Númenóreans who escaped the destruction of Númenor ten years prior. It was at that time named Minas Anor, meaning "Tower of the Sun". It served as the home of Anárion, as Minas Ithil, built to guard against Mordor, became the dwelling-place of Isildur. The South-kingdom, however, was jointly ruled from Osgiliath by both brothers under the overlordship of Elendil.

            In the year SA 3429, Sauron invaded Gondor, capturing Minas Ithil and forcing Isildur to flee. Anárion successfully defended Osgiliath and retook Minas Ithil, driving Sauron back for a time, but in the following War of the Last Alliance, Anárion was slain and rule of Gondor passed to his descendants, who would continue to rule from Osgiliath. Before departing for the north, Isildur planted the White Tree of Gondor in the Court of the Fountain in memory of his slain brother.

            King Ostoher rebuilt the city in TA 420, establishing his summer court there. Gradually, it eclipsed Osgiliath in importance as the original capital slowly declined into ruin, and in TA 1640 King Tarondor permanently moved the King's House to Minas Anor. In the year TA 2002, the city of Minas Ithil on the borders of Mordor was captured by the Ringwraiths, and renamed Minas Morgul. It was then that Minas Anor was renamed Minas Tirith, meaning "Tower of Guard". Soon afterwards, the last king of Gondor, Eärnur, was slain in the Morgul Vale, and rulership of Gondor passed to the Stewards. It was also around this time that the Rammas Echor, the great wall encircling the rich farmlands and suburbs of the city, was built.`
        },
        {
          title: 'Layout',
          content: `Minas Tirith was surrounded by the Rammas Echor, a large ringwall encircling the city and the Pelennor Fields. This wall was built after Minas Ithil fell and was renamed Minas Morgul. It was repaired by Ecthelion II and Denethor II before the War of the Ring, but it was too long to adequately manned to defend the city from the Dark Lord Sauron's legions of Uruk-hai from Mordor. The city itself lay on a hill beneath Mount Mindolluin, which rose above the Citadel of Minas Tirith, by a height of a couple of thousand feet. Mount Mindolluin was where Aragorn found the seedling of the White Tree.

            The city was divided into seven one-hundred-foot high levels, each surrounded by white walls, except the first wall, the Othram, which had the same kind of black stone as Orthanc as a face. The gates connecting the levels did not lie behind one another in a line, but faced in alternating directions. Passwords were required for each gate. A spur of rock, whose summit was level with the city's uppermost tier, jutted out from the front of the city in an easterly direction, dividing all but the first and seventh levels in two. Each level was scattered with many alleys, narrow passageways, and full-fledged houses. On the sixth level were located the Houses of Healing, surrounded by pleasant gardens. Finally, within the seventh wall, was the Citadel of Minas Tirith, with the White Tower of Ecthelion - three hundred feet high, so that its apex was one thousand feet above the plain. The citadel contained armouries, barracks and mess halls as well as the residence of Gondor's rulers and the feast hall Merethrond. The Court of the Fountain was directly to the west of the citadel gate.`
        }
      ]
    }
  ],




  // 'January': 30,
  // 'February': 28,
  // 'March': 31,
  // 'April': 30,
  // 'May': 31,
  // 'June': 30,
  // 'July': 31,
  // 'August': 31,
  // 'September': 30,
  // 'October': 31,
  // 'November': 30,
  // 'December': 31

  calendars: {
    'a4b81': {
      id: 'a4b81',
      name: 'Human Calendar',

      months: [
        { name: 'January', numDays: 31 },
        { name: 'February', numDays: 28 },
        { name: 'March', numDays: 31 },
        { name: 'April', numDays: 30 },
        { name: 'May', numDays: 31 },
        { name: 'June', numDays: 30 },
        { name: 'July', numDays: 31 },
        { name: 'August', numDays: 31 },
        { name: 'September', numDays: 30 },
        { name: 'October', numDays: 31 },
        { name: 'November', numDays: 30 },
        { name: 'December', numDays: 31 },
      ],

      days: [
        {
          name: 'AM',
          numHours: 12
        },
        {
          name: 'PM',
          numHours: 12
        }
      ]
    }
  },

  timelines: [
    {
      _id: 'f8a99df00asd',
      authorID: 'd82a3c23',
      createdOn: '2021-06-02T08:06:20.888Z',
      description: 'This is a timeline',
      lastUpdated: '2021-06-02T08:06:20.888Z',
      visibility: 'public',
      start: 2995,
      end: 3020,
      backgroundImage: 'https://i.pinimg.com/originals/72/9f/8b/729f8bdb794cd248d5fd0bf7ac391d9f.jpg',
      units: [
        {
          _id: 'aaa333',
          name: 'year',
          units: 0,
        },
        {
          _id: 'bbb444',
          name: 'month',
          calendar: {
            'bbb456': 'January',
            'bbb678': 'February',
            'bbb890': 'March',
            'bbb123': 'April',
            'bbb234': 'May',
            'bbb345': 'June',
            'bbb654': 'July',
            'bbb876': 'August',
            'bbb987': 'September',
            'bbb098': 'October',
            'bbb321': 'November',
            'bbb432': 'December'
          },
          subsetOf: {
            'aaa333': 12
          },
        },
        {
          _id: 'ccc555',
          name: 'day',
          calendar: {
            'ccc123': 'Sunday',
            'ccc234': 'Monday',
            'ccc345': 'Tuesday',
            'ccc456': 'Wednesday',
            'ccc567': 'Thursday',
            'ccc678': 'Friday',
            'ccc789': 'Saturday'
          },
          subsetOf: {
            'bbb456': 30,
            'bbb678': 28,
            'bbb890': 31,
            'bbb123': 30,
            'bbb234': 31,
            'bbb345': 30,
            'bbb654': 31,
            'bbb876': 31,
            'bbb987': 30,
            'bbb098': 31,
            'bbb321': 30,
            'bbb432': 31
          }
        }
      ],
      zones: {
        'default': {
          _id: 'default',
          name: 'Default',
          start: 2995,
          end: 3020
        }
      },
      lines: [
        {
          _id: 'a53aj98a',
          name: 'Main Line',
          nodes: [
            {
              _id: '193af02',
              parent: null,
              year: 3001,
              month: 'December',
              day: 31,
              date: {
                'aaa333': 3001
              },
              subtitle: 'Start of LOTR',
              title: 'Bilbo Leaves The Shire',
              image: 'https://i.pinimg.com/originals/be/e0/43/bee043dd4812f60f568ec020e78b5927.jpg'
            },
            {
              _id: '0734aad',
              parent: null,
              year: 3009,
              date: {
                'aaa333': 3009
              },
              subtitle: '',
              title: 'Aragorn Captures Gollum'
            },
            {
              _id: 'ad141ad',
              parent: null,
              type: 'range',
              start: {
                year: 3018
              },
              end: {
                year: 3021
              },
              subtitle: '',
              title: 'The Quest to Destroy the Ring',
              image: 'https://compote.slate.com/images/afc56e43-599c-4a26-9d77-bf5aac8e2fd3.jpg'
            },
          ]
        },
        {
          _id: 'b7a63da1',
          name: 'Other',
          nodes: [
            {
              _id: '193af02',
              parent: null,
              year: 3014,
              date: {
                'aaa333': 3014
              },
              subtitle: '',
              title: 'Saruman Begins Influencing Theoden',
              image: 'https://i.redd.it/z1bit1mcscd51.jpg'
            },
          ]
        },
      ],
      levels: [
        {
          label: 'Year',
          name: 'Year',
          nodes: [
            {
              id: '193af02',
              parent: null,
              position: 3001,
              date: {
                'aaa333': 3001
              },
              subtitle: 'Start of LOTR',
              title: 'Bilbo Leaves The Shire',
              image: 'https://i.pinimg.com/originals/be/e0/43/bee043dd4812f60f568ec020e78b5927.jpg'
            },
            {
              id: '0734aad',
              parent: null,
              position: 3009,
              date: {
                'aaa333': 3009
              },
              subtitle: '',
              title: 'Aragorn Captures Gollum'
            },
            {
              id: 'ad141ad',
              parent: null,
              type: 'range',
              start: 3018,
              end: 3021,
              subtitle: '',
              title: 'The Quest to Destroy the Ring',
              image: 'https://compote.slate.com/images/afc56e43-599c-4a26-9d77-bf5aac8e2fd3.jpg'
            },
          ],
          units: 0
        },
        {
          label: 'Day',
          name: 'Day',
          nodes: [
            {
              id: '4123aa73',
              parent: 'ad141ad',
              position: 3018,
              date: {
                'aaa333': 3018,
                'bbb444': 3
              },
              subtitle: 'Frodo leaves The Shire',
              title: 'Ringwraiths Sent to Find the One Ring'
            },
            {
              id: '91298asd',
              parent: 'ad141ad',
              position: 3021,
              subtitle: 'End of LOTR',
              title: 'The One Ring Is Destroyed'
            },
            {
              id: '761231da',
              parent: 0,
              position: 60,
              subtitle: '...',
              title: 'Point C'
            },
          ],
          units: 365,
        }
      ],
      title: 'Third Age'
    }
  ],




  maps: [
    {
      _id: 'ajd81238123a',
      authorID: 'd82a3c23',
      createdOn: '2021-06-02T08:06:20.888Z',
      description: 'This is a map',
      image: 'https://i.pinimg.com/originals/72/9f/8b/729f8bdb794cd248d5fd0bf7ac391d9f.jpg',
      lastUpdated: '2021-06-02T08:06:20.888Z',
      title: 'Middle Earth',
      visibility: 'public',
      points: [
        {
          _id: '812723',
          color: '#4bd4ff',
          description: 'The White City, capitol of Gondor. A seven-tiered fortress city that keeps the forces of Mordor at bay.',
          icon: 'castle',
          name: 'Minas Tirith',
          x: 0.6163838220896496,
          y: 0.6082509778871625
        },
        {
          _id: '991231',
          color: '#cc3030',
          description: 'The volcano where the One Ring was forged by Sauron, and the place where it was destroyed at the end of the Third Age.',
          icon: 'mountain',
          name: 'Mount Doom',
          x: 0.7077592679999446,
          y: 0.5778348377245137,
          images: [
            'https://upload.wikimedia.org/wikipedia/en/6/6d/Mount_Doom_%28Tolkien%29.jpg'
          ]
        },
        {
          _id: '123111',
          color: '#f6be6b',
          description: 'The capitol city of Rohan, the home of the horse lords.',
          icon: 'castle',
          name: 'Edoras',
          x: 0.5192631565708031,
          y: 0.5385258998785156,
          images: [
            'https://64.media.tumblr.com/e42b92e38dd086fcee9cece065ef17be/tumblr_pq39k9OPSg1xupmjao1_1280.jpg',
            'https://cdn-www.comingsoon.net/assets/uploads/2018/11/Edoras-618x360.jpg'
          ]
        },
        {
          _id: '412322',
          color: '#383c40',
          description: 'The ancient fortress tower repurposed by Saruman and taken back by the Ents during the Third Age.',
          icon: 'rook',
          name: 'Isengard',
          x: 0.48946573767937507,
          y: 0.4641344662266195,
          images: [
            'http://www.councilofelrond.com/albums/album13/Z_Orthanc_03.jpg'
          ]
        },
        {
          _id: '623411',
          color: '#78d043',
          description: 'A bustling hobbit city located in The Shire, famous as the home of the hobbits who won the War of the Ring.',
          icon: 'home',
          name: 'Hobbiton',
          x: 0.35991078004938865,
          y: 0.25848369906133645
        },
        {
          _id: '515432',
          color: '#6ff3f7',
          description: 'The elven port city that transported ships to Valinor.',
          icon: 'bell',
          name: 'The Grey Havens',
          x: 0.2912771848343241,
          y: 0.26958743937235113
        },
        {
          _id: '974213',
          color: '#f5ca73',
          description: 'The grand dwarven city under the Lonely Mountain, built by King Thrain.',
          icon: 'mountain',
          name: 'Erebor',
          x: 0.6737610969645711,
          y: 0.21809094647291447
        },
        {
          _id: '555123',
          color: '#5dd573',
          description: 'The elven city hidden in the forest of Lorien, where the lady Galadriel dwelled during the Third Age.',
          icon: 'castle',
          name: 'Lothlorien',
          x: 0.5517409158805971,
          y: 0.37448166203928646,
          images: [
            'https://www.mdislander.com/wp-content/uploads/sites/5/2015/12/lothlorien-2.jpg',
            'http://images2.fanpop.com/image/photos/9500000/Lothl-rien-lothlorien-9548722-1205-518.jpg',
            'http://pm1.narvii.com/6319/ae051076b6e3469e98152f9bf2d832af83e77696_00.jpg'
          ]
        },
        {
          _id: '412331',
          color: '#63dbec',
          description: 'A small valley guarded by the elves, wherein lies The House of Elrond.',
          icon: 'home',
          name: 'Rivendell',
          x: 0.5115325732897604,
          y: 0.25819104646966484
        },
        {
          _id: '211152',
          color: '#d04138',
          description: 'A hill at whose summit lies the Seat of Seeing, famous for the Uruk-hai attack which resulted in the Breaking of the Fellowship.',
          icon: 'swords',
          name: 'Amon Hen',
          x: 0.5869828833952672,
          y: 0.4868364749517537
        },
      ]
    }
  ],




  languages: [
    {
      _id: 'd982a3943',
      createdOn: '2021-06-02T08:06:20.888Z',
      lastUpdated: '2021-06-02T08:06:20.888Z',
      visibility: 'public',
      title: 'Sindarin',
      words: [
        {
          word: 'mellock',
          meaning: 'friend',
          type: 'noun'
        },
        {
          word: 'ada',
          meaning: 'father',
          type: 'noun'
        },
        {
          word: 'barad',
          meaning: 'tower',
          type: 'noun'
        },
        {
          word: 'ndengina',
          meaning: 'kill',
          type: 'verb'
        },
        {
          word: 'tar',
          meaning: 'high',
          type: 'adjective'
        },
        {
          word: 'vala',
          meaning: 'mighty',
          type: 'adjective'
        },
        {
          word: 'taur',
          meaning: 'forest',
          type: 'noun'
        },
        {
          word: 'dagor',
          meaning: 'battle',
          type: 'noun'
        },
        {
          word: 'dol',
          meaning: 'head',
          type: 'noun'
        },
        {
          word: 'amon',
          meaning: 'mountain, hill',
          type: 'noun'
        },
        {
          word: 'emyn',
          meaning: 'hills, plural of "amon"',
          type: 'noun'
        },
      ]
    }
  ]

}
