<template>
  <div
    :class="`button-component ${mini ? 'mini' : ''} ${disabled ? 'disabled' : ''}`"
    :style="buttonStyle"
  >
    <Icon v-if="icon"
      :name="icon"
      :size="iconSize || '12px'"
      :style="`color: ${color}; ${label ? '' : 'margin: 0;'}`" />
    <span :style="textStyle">{{ label }}</span>
  </div>
</template>


<script>
  import Icon from './Icon.vue';

  export default {
    name: '',
    components: {
      Icon
    },
    props: {
      label: String,
      icon: String,
      iconSize: String,
      fontSize: [String],
      color: [String],
      bgColor: [String],
      disabled: [Boolean],
      mini: [Boolean]
    },

    computed: {
      buttonStyle() {
        let string = '';

        if (!this.label && !this.mini) string += 'padding: 12px;';
        if (this.bgColor) string += ` background-color: ${this.bgColor};`;

        return string;
      },

      textStyle() {
        let string = '';

        string += `color: ${this.color};`;
        if (this.fontSize) {
          string += ` font-size: ${this.fontSize}; line-height: ${this.fontSize};`;
        }

        return string;
      }
    }
  }
</script>


<style scoped lang="scss">

  .button-component {
    @include flex($direction: row, $justify: center, $align: center);
    padding: 0.75rem 1.25rem;
    color: #fff;
    // background-color: #202329;
    background-color: bg(20%);
    border-radius: 6px;
    transition: background 0.15s, color 0.15s;
    user-select: none;
    z-index: 9;

    .icon {
      margin-right: 0.5rem;
    }

    span {
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      text-transform: capitalize;
      white-space: pre;
      transition: all 0.15s;
    }

    &.compact {
      flex-direction: row;
      padding: 0.5rem 1rem;
    }

    &.mini {
      flex-direction: row;
      padding: 0.5rem;
    }

    &.inline {
      display: inline-flex;
    }



    &:not(.disabled) {
      &:hover {
        cursor: pointer;
        color: $colorA;
        background-color: bg(30%);

        span {
          color: $colorA;
        }
      }
    }

    &.disabled {
      opacity: 0.65;
      background-color: bg(10%);
      pointer-events: none;
    }



    &.primary {
      color: #fff !important;
      background-color: #078658;

      span {
        color: #fff !important;
      }

      &:not(.disabled):hover {
        background-color: #0f9967;
      }

      &.disabled {
        background-color: #326554;
        // background-color: #20a87b;
      }
    }

    &.secondary {
      color: #fff !important;
      background-color: #4b44bb;

      span {
        color: #fff !important;
      }

      &:not(.disabled):hover {
        background-color: #16996e;
      }

      &.disabled {
        background-color: #3c3798;
      }
    }

    &.delete {
      color: #fff !important;
      background-color: #811724;

      span {
        color: #fff !important;
      }

      &:not(.disabled):hover {
        background-color: #90202d;
      }

      &.disabled {
        background-color: #421a1f;
      }
    }

    &.help {
      color: #fff !important;
      background-color: #c68c0b;

      span {
        color: #fff !important;
      }

      &:not(.disabled):hover {
        background-color: #dda11e;
      }

      &.disabled {
        background-color: #a77e25;
      }
    }

    &.vertical {
      flex-direction: column;

      .icon {
        margin-right: 0;
      }

      span {
        margin-top: 0.5rem;
        text-align: center;
        white-space: pre-wrap;
      }
    }

    &.dark {
      background-color: bg(10%);

      &:not(.disabled):hover {
        background-color: bg(15%);
      }
    }

    &.clear {
      background-color: transparent;

      &:not(.disabled):hover {
        background-color: bg(30%);
      }
    }
  }

</style>
