<template>
  <div class="user-profile">

    <div v-if="!user">
      <Loader />
    </div>

    <div v-else class="page">
      <div class="spacer"></div>

      <div class="info floating">
        <div class="header">
          <img class="avatar-image" :src="`https://media.scrybe-app.com/avatar/${user.avatarImage}`" />
          <h2>{{ user.name }}</h2>
        </div>
        <p>Member since <span class="highlight">{{ formatDate(user.dateJoined, '&month &dd, &year') }}</span></p>
      </div>

      <div class="spacer h4"></div>

      <template v-if="user.worlds">
        <h3>Worlds:</h3>
        <div class="spacer h1"></div>
        <div class="worlds">
          <WorldPreview
            v-for="world in user.worlds"
            :worldID="world._id"
            class="world"
          />
        </div>
      </template>

      <div class="spacer h4"></div>

      <template v-if="user.posts">
        <h3>Posts:</h3>
        <div class="posts">
          <Post
            v-for="post in user.posts"
            :post="post"
          />
        </div>
      </template>
    </div>

  </div>
</template>

<script>
  import WorldPreview from '../World/WorldPreview.vue';
  import Post from '../Community/Posts/Post.vue';

  export default {
    name: 'Profile',
    components: {
      WorldPreview,
      Post
    },
    
    data() {
      return {
        user: null
      }
    },

    created() {
      this.$store.dispatch('fetchUserProfile', this.$route.params.id).then(response => {
        this.user = response.data;
      });
    }
  }
</script>

<style scoped lang="scss">

  .user-profile {
    margin-bottom: 8rem;
  }

  .info {
    @include flex($direction: column);
    width: 600px;
    padding: 2rem;
    background-color: bg(20%);
    border-radius: 0.25rem;
  }

  .header {
    @include flex();
    margin-bottom: 2rem;

    h2 {
      color: $colorA;
    }

    .avatar-image {
      width: 80px;
      height: 80px;
      margin-right: 1.5rem;
      border-radius: 100%;
    }
  }

  .highlight {
    color: $colorB;
    font-weight: 600;
  }

  .worlds {
    @include flex($justify: center);
    flex-wrap: wrap;
    width: 100%;
    max-width: calc(900px + 4rem);
    padding: 0 2rem;
    box-sizing: border-box;

    .world {
      margin: 1rem;
    }
  }

  .posts {
    width: 100%;
    max-width: calc(900px + 4rem);
    padding: 0 2rem;
    box-sizing: border-box;
  }

</style>
