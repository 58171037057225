import { render, staticRenderFns } from "./FeaturedWorlds.vue?vue&type=template&id=c4ff501c&scoped=true"
import script from "./FeaturedWorlds.vue?vue&type=script&lang=js"
export * from "./FeaturedWorlds.vue?vue&type=script&lang=js"
import style0 from "./FeaturedWorlds.vue?vue&type=style&index=0&id=c4ff501c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4ff501c",
  null
  
)

export default component.exports