<template>
  <div>
    <div class="spacer"></div>
    <h2>Account Settings</h2>

    <div class="spacer h3"></div>

    <div class="avatar-image">
      <img :src="publicImageURL('avatar', user.avatarImage, user._id)" />
      <Button v-if="user.avatarImage != 'default_avatar.png'"
        icon="x"
        class="delete"
        @click.native="removeImage"
      />
    </div>
    <input type="file" name="avatar" id="avatarFileSelect" @change="selectImage" />

    <div class="spacer h1"></div>
    <Button
      label="Upload Image"
      @click.native="openImageSelect"
    />

    <div class="spacer h5"></div>

    <div class="settings-grid">
      <p class="date-joined">Member Since</p>
      <p>{{ formatDate(user.dateJoined, '&weekday, &month &dd, &year') }}</p>
      <p :class="name != user.name ? 'changed' : ''">Name</p>
      <input type="text" :value="name" @input="(e) => { this.alert = null; name = e.target.value; }" />
      <p :class="email != user.email ? 'changed' : ''">Email</p>
      <input type="text" :value="email" @input="(e) => { this.alert = null; email = e.target.value }" />
    </div>

    <Modal v-if="modalVisible"
      :message="modalMessage" :buttons="['submit', 'cancel']"
      :input="modalInput" :exactMatch="modalExactMatch"
      @modal-submit="updateConfirmed" @modal-cancel="modalVisible = false"/>

    <div class="spacer h5"></div>

    <CoolButton class="update-button" type="compact"
      label="Update" animation="infinite"
      textColor="#66f1d0" bgColor="rgb(102,241,208)"
      @click.native="update" />

    <Alert :alert="alert" @dismiss="alert = null" />

    <div class="spacer h5"></div>
  </div>
</template>

<script>
  import { validateEmail, validatePassword } from '@/utility';

  export default {
    name: 'UserSettings',

    computed: {
      user() {
        return this.$store.state.user || {};
      }
    },

    data() {
      return {
        name: '',
        email: '',

        modalVisible: false,
        modalMessage: null,
        modalInput: null,
        modalExactMatch: null,

        changedParams: null,
        alert: null
      }
    },

    methods: {
      openImageSelect() {
        let fileSelect = document.getElementById('avatarFileSelect');
        fileSelect.click();
      },

      async selectImage() {
        this.loading = true;

        let newImage = event.target.files[0];
        let formData = new FormData();
        formData.append('firstName', 'Jimmy');
        formData.append('avatar', newImage, newImage.name);

        const response = await this.$store.dispatch('updateAvatarImage', formData)
        await this.$store.dispatch('getMe')

        this.loading = false;
        if (response.status == 201) {
          this.alert = { type: 'success', message: 'Avatar image updated successfully.' }
        }
        else {
          this.alert = { type: 'error', message: response?.error }
        }
      },

      async removeImage() {
        this.loading = true;

        const response = await this.$store.dispatch('clearAvatarImage')
        await this.$store.dispatch('getMe')

        this.loading = false;
        if (response.status == 201) {
          this.alert = { type: 'success', message: 'Avatar image removed successfully.' }
        }
        else {
          this.alert = { type: 'error', message: response?.error }
        }
      },

      update() {
        let changedParams = {};
        if (this.name !== this.user.name) {
          changedParams.name = this.name;
        }
        if (this.email !== this.user.email) {
          changedParams.email = this.email;
        }

        if (!Object.keys(changedParams).length) {
          this.alert = {
            type: 'warning',
            message: 'No changes made.'
          }
          return;
        }
        this.changedParams = changedParams;

        if (changedParams.email) {
          if (!validateEmail(this.email)) {
            this.alert = {
              type: 'warning',
              message: 'Please enter a valid email address.'
            }
            return;
          }
          this.modalMessage = 'Please re-enter your new email for confirmation.';
          this.modalInput = 'new.email@example.com';
          this.modalExactMatch = this.email;
          this.modalVisible = true;
        }
        else {
          this.updateConfirmed(null);
        }
      },

      updateConfirmed(input) {
        this.modalVisible = false;

        this.$store.dispatch('updateUserInfo', this.changedParams).then(response => {
          console.log(response);
          if (response.status === 201) {
            this.alert = {
              type: 'success',
              message: 'Updated successfully.'
            }
            this.$store.dispatch('getMe');
          }
          else {
            this.alert = {
              type: 'error',
              message: response.data.error
            }
          }
          this.changedParams = null;
          this.modalMessage = null;
          this.modalInput = null;
          this.modalExactMatch = null;
        });
      }
    },
    mounted() {
      this.name = this.user.name;
      this.email = this.user.email;
    }
  }
</script>

<style scoped lang="scss">

  .avatar-image {
    position: relative;
    width: 180px;
    height: 180px;
    margin: 1rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }

    .button-component {
      position: absolute;
      top: 0;
      right: 0;
      color: #ec4e4e;
      opacity: 0;
      transition: opacity 0.15s;
      filter: drop-shadow(0 0 16px #000);
    }

    &:hover {
      .button-component {
        opacity: 1;
      }
    }
  }

  #avatarFileSelect {
    display: none;
  }

  .date-joined {
    color: #5c76ff !important;
  }

  .settings-grid {
    @include grid($columns: 1fr 3fr, $gap: 1.5rem);
    width: 60%;
    max-width: 600px;

    > *:nth-child(odd) {
      color: $colorA;
      font-size: 0.9rem;
      font-weight: 600;
      text-align: right;
    }
    > *:nth-child(even) {
      text-align: left;
    }

    .changed::before {
      content: '';
      display: inline-block;
      margin-bottom: calc(0.5em - 4px);
      margin-right: 1rem;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: $colorD;
    }
  }

</style>
