<template>
  <div class="page">

    <h3>Maps</h3>

    <Button label="Back" class="back-button" icon="back-arrow"
      @click.native="$router.push('/creation')" />

    <div class="my-maps">
      <div class="spacer h1"></div>

      <h5 v-if="!maps">No maps saved.</h5>
      <div v-else class="map-list">
        <div v-for="map in maps" class="map-list-item"
          @click="$router.push('map/' + map._id)">
          <p>{{ map.name }}</p>
          <h6>{{ map.description }}</h6>
          <div class="auto-space"></div>
          <img :src="publicImageURL('maps', map.image)" />
          <Button label=""
            icon="x"
            iconSize="12px"
            class="delete-button"
            @click.native.stop="deleteMap(map)" />
        </div>
      </div>

      <div class="spacer h1"></div>

      <Button
        label="New Map"
        icon="plus"
        @click.native="$router.push('/newmap')"/>
      <div class="spacer h1"></div>
      <Button
        label="Generate Map"
        icon="plus"
        @click.native="$router.push('/generate-map')"/>
    </div>

    <Modal v-if="deleteMapVisible"
      :message='`Delete map "${editingMap && editingMap.name}"?`'
      :buttons="['yes', 'no']"
      @modal-yes="confirmDelete"
      @modal-no="cancelDelete" />

  </div>
</template>

<script>
  export default {
    name: 'MyMaps',
    components: {
    },
    data() {
      return {
        deleteMapVisible: false,
        editingMap: null
      }
    },
    computed: {
      maps() {
        if (!this.$store.state.maps) return;
        return Object.values(this.$store.state.maps);
      }
    },
    methods: {
      deleteMap(map) {
        this.deleteMapVisible = true;
        this.editingMap = map;
      },

      cancelDelete() {
        this.deleteMapVisible = false
        this.editingMap = null;
      },

      confirmDelete() {
        this.$store.dispatch('deleteMap', this.editingMap._id).then(response => {
          console.log(response);
        });
        this.deleteMapVisible = false;
        this.editingMap = null;

        // this.$store.dispatch('deleteWiki', id).then(response => {
        //   this.loading = true;
        //   this.$store.dispatch('fetchWikis').then(response => {
        //     this.loading = false
        //   });
        // });
      },
    },
    mounted() {
      this.$store.dispatch('fetchMaps');
    }
  }
</script>

<style scoped lang="scss">

  h3 {
    margin-top: 0.5em;
    margin-bottom: 3rem;
    text-transform: uppercase;
  }

  .my-maps {
    --imageSize: 240px;

    width: 100%;
    text-align: center;

    h5 {
      color: $colorB;
    }

    .button-component {
      margin: auto;
      max-width: 200px;
    }
  }

  .map-list {
    @include flex($justify: center, $align: stretch);
    flex-wrap: wrap;
    max-width: 1200px;
    margin: auto;
  }

  .map-list-item {
    position: relative;
    @include flex($direction: column, $align: flex-start);
    width: calc(var(--imageSize) + 1.5rem);
    margin: 0.5rem;
    padding: 0.75rem;
    border-radius: 0.25rem;
    background-color: rgba(0,0,0,.25);
    text-align: left;
    transition: background 0.3s;

    h6 {
      font-size: 0.9rem;
      font-weight: 300;
      opacity: 0.75;
    }

    .auto-space {
      height: 1rem;
      margin-top: auto;
    }

    img {
      width: var(--imageSize);
      height: var(--imageSize);
      object-fit: cover;
      object-position: center;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba($colorA,.25);

      .delete-button {
        opacity: 1;
      }
    }
  }

  .delete-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    opacity: 0;
    transition: opacity 0.3s;
  }

</style>
