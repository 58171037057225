<template>
  <div class="details">

    <div class="label" @click="toggle">
      <Icon
        :name="open ? 'less-arrow' : 'more-arrow'"
        style="margin-right: 0.5rem;"
      />
      <p>{{ label }}</p>
    </div>

    <div ref="contentContainer" class="content-container">
      <div ref="content" class="content">
        <slot></slot>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'Details',
    props: {
      label: [String]
    },

    data() {
      return {
        open: false,
        contentHeight: 0
      }
    },

    methods: {
      toggle() {
        this.open = !this.open;
        if (this.open) {
          console.log(this.contentHeight);
          this.$refs.contentContainer.style.height = `${this.contentHeight}px`;
        }
        else {
          this.$refs.contentContainer.style.height = '0px';
        }
      }
    },

    mounted() {
      const content = this.$refs.content;
      this.contentHeight = content.clientHeight;
    }
  }
</script>

<style scoped lang="scss">

  .label {
    @include flex();
    padding: 0.5rem 0;
    user-select: none;

    p {
      color: inherit;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .content-container {
    width: 100%;
    height: 0px;
    overflow: hidden;
    transition: height 0.3s;
  }

  .content {
    padding-bottom: 1rem;
  }

</style>
