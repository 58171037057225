<template>
  <Button v-if="normal"
    label="Back"
    icon="left-arrow"
    class="inline"
    style="margin-right: auto;"
  />
  <CoolButton v-else
    label="Back"
    class="back-button"
    type="compact"
    animation="infinite"
    icon="back-arrow"
    textColor="#ffdc82"
    bgColor="rgb(244,186,75)"
    touchClass="selected"
  />
</template>

<script>
  import CoolButton from './CoolButton.vue';

  export default {
    name: 'BackButton',
    components: {
      CoolButton
    },
    props: {
      normal: [Boolean]
    }
  }
</script>

<style scoped lang="scss">

  .back-button {
    position: absolute;
  }

</style>
