<template>
  <div :class="`help ${$route.name != 'HelpHome' ? 'child-route' : ''}`">

    <div
      v-if="$route.name != 'HelpHome'"
      class="help-panels"
    >
      <HelpMenu />
      <router-view class="help-router-view" />
      <Footer />
    </div>

    <template v-else>
      <router-view />
      <Footer />
    </template>

  </div>
</template>

<script>
  import HelpMenu from './HelpMenu.vue';
  import Footer from '../Navigation/Footer.vue';

  export default {
    name: 'HelpHome',
    components: {
      HelpMenu,
      Footer
    },

    mounted() {
      console.log(this.$route);
    }
  }
</script>

<style scoped lang="scss">

  .help {
    position: relative;

    &.child-route {
      height: calc(100vh - #{$navigation-height});
      max-height: calc(100vh - #{$navigation-height});
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .description {
    max-width: 480px;
    margin: 0 auto;
    padding: 0 2rem;
    text-align: center;
  }

  a {
    color: $colorB;

    &:hover {
      cursor: pointer;
      color: $colorA;
    }
  }

  .help-panels {
    position: relative;
    @include flex($direction: column);
    max-width: 980px;
    margin: 0 auto;
  }

  .help-router-view-container {
    width: 100%;
  }

  .help-router-view {
    display: block;
    width: 100%;
    padding: 4rem;
    margin-top: 2rem;
    margin-bottom: 10rem;
    margin-left: auto;
    margin-right: 2rem;
  }

</style>
