<template>
  <div class="landing-section" id="timelines">

    <div class="section-bg">
      <img class="bg-image" src="https://media.scrybe-app.com/assets/landing/timelines_background.jpeg" />
    </div>


    <h1>Timelines</h1>

    <div class="section-content">

      <div class="showcase-image">
        <AsyncImage src="https://media.scrybe-app.com/assets/landing/timelines_screenshot.png" />
      </div>

      <div class="content-header">
        <h2>Visualize your story across time.</h2>
        <p>Getting a bird's eye view of time has never been easier. Add events from your story, set dates, and drag them around.</p>
        <br>
        <p>Timelines features a custom-built, dynamic interface that lets you scroll and zoom across time effortlessly.</p>
      </div>

      <div class="points">
        <div>
          <h4><Icon name="solar system" size="1rem" />Multiple Lines</h4>
          <p>Visualize multiple chains of events by putting them all on the same Timeline. Add as many lines as you want, and freely move events between them.</p>
        </div>
        <div>
          <h4><Icon name="eye flat" size="1rem" />Look at the Big Picture, or the Small One</h4>
          <p>Set custom windows of time, called "zones", and switch between them with a click of a button. Whether it's a frame of 1 day or 500 years, it's up to you.</p>
        </div>
        <div>
          <h4><Icon name="link" size="1rem" />Connect Your World (Coming Soon!)</h4>
          <p>Link your Timeline events to other Elements of your world, such as Maps or Wiki pages, allowing you to navigate between them without distracting menus.</p>
        </div>
      </div>

    </div>

  </div>
</template>


<script>
  export default {
    name: 'LandingTimelines',
  }
</script>


<style scoped lang="scss">

  #timelines {
    margin-bottom: -60px;
    padding-top: 10rem;
    padding-bottom: 14rem;

    .icon {
      color: $timelines-color;
    }

    .section-bg {
      // background-color: #142b26;
      background-color: #2e1927;
      clip-path: polygon(0 60px, 100% 0, 100% 100%, 0 calc(100% - 60px));
      .bg-image {
        mask-image: radial-gradient(circle at center right, rgba(0,0,0,.75), rgba(0,0,0,.25) 25%, rgba(0,0,0,0) 75%);
      }
    }

    .section-content{
      grid-template-areas:
        "image header"
        "image points";
    }

    .content-header {
      text-align: right;
      margin-left: auto;
    }

    .showcase-image .async-image {
      width: 600px;
      height: 420px;
      transform: perspective(25cm) rotate3d(3, 5, -1, 20deg);
    }

    .points {
      text-align: right;

      h4 {
        justify-content: flex-end;
      }

      > * {
        margin-left: auto;
      }
    }
  }

</style>
