<template>
  <div class="help-home">

    <div class="spacer h3"></div>
    <h1 class="colorful-title">Help</h1>
    <div class="spacer"></div>
    <p class="description">If you have any questions or concerns, please feel free to contact us via our <router-link to="/support">Support</router-link> page.</p>
    <div class="spacer"></div>

    <div class="row">
      <HelpMenu />
    </div>

  </div>
</template>

<script>
  import HelpMenu from './HelpMenu.vue';

  export default {
    name: 'HelpHome',
    components: {
      HelpMenu
    }
  }
</script>

<style scoped lang="scss">

  .help-home {
    position: relative;
    min-height: calc(100vh - #{$navigation-height} - #{$footer-height});
  }

  .description {
    max-width: 480px;
    margin: 0 auto;
    padding: 0 2rem;
    text-align: center;
  }

  a {
    color: $colorB;

    &:hover {
      cursor: pointer;
      color: $colorA;
    }
  }

</style>
