import { render, staticRenderFns } from "./NewWriting.vue?vue&type=template&id=cace076c&scoped=true"
import script from "./NewWriting.vue?vue&type=script&lang=js"
export * from "./NewWriting.vue?vue&type=script&lang=js"
import style0 from "./NewWriting.vue?vue&type=style&index=0&id=cace076c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cace076c",
  null
  
)

export default component.exports