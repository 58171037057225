<template>
  <div class="key-mapping">

    <h4>Key Mapping</h4>

    <div class="spacer"></div>

    <div class="keys floating">
      <div class="headers grid-row left">
        <p>Rune</p>
        <p>Key</p>
      </div>

      <div v-for="key in keyList" class="grid-row left">

        <div class="rune-container" @click="clickRune(key)">
          <img v-if="key.rune"
            :class="`rune ${inverted ? 'inverted' : ''}`"
            :src="publicImageURL('rune', keyMap[key.key].rune, userID)"
          />
          <h4 v-else>?</h4>

          <div v-if="selectedRune && selectedRune._id == key._id"
            class="select-rune"
            @click.stop=""
          >
            <p v-if="!runeImages.length">No rune images.</p>
            <div class="rune-images">
              <img v-for="rune in runeImages"
                :class="inverted ? 'inverted' : ''"
                :src="publicImageURL('rune', rune, userID)"
                @click="selectRune(key, rune)"
              />
            </div>
            <div class="spacer h1"></div>
            <Button
              label="Add Rune Image"
              icon="plus"
              class="primary"
              @click.native.stop="openRuneFileSelect"
            />
            <input type="file" name="rune" id="runeFileSelect" @change="selectImage" />
          </div>
        </div>

        <template>
          <div v-if="selectedKey == key.key" class="key-input row spaced">
            <div>
              <p v-if="error" class="key text error">{{ error }}</p>
              <template v-else>
                <p v-if="newKey" class="key success">{{ newKey }}</p>
                <p v-else class="key text success">Press a key...</p>
              </template>
            </div>
            <Button
              icon="check"
              :class="`primary ${!error && newKey ? '' : 'disabled'}`"
              @click.native="updateKey"
            />
            <Button
              icon="x"
              class="delete"
              @click.bubble.native="cancelUpdateKey"
            />
          </div>
          <div v-else class="key-container" @click="clickKey(key.key)">
            <div class="row">
              <p class="key">{{ key.key }}</p>
              <Button v-if="isAuthor"
                icon="delete"
                class="delete"
                style="margin-left: auto;"
                @click.bubble.native.stop="deletingKey = key"
              />
            </div>
          </div>
        </template>

      </div>
    </div>

    <div class="spacer"></div>

    <Button v-if="isAuthor"
      label="Add Key"
      icon="plus"
      class="primary"
      @click.native="addKey"
    />

    <div class="spacer"></div>

    <div class="writing-input">
      <input type="text" @focus="readyInput" @blur="removeInput" />
    </div>

    <ModalContainer v-if="deletingKey">
      <p>Delete "{{ deletingKey.key }}"?</p>
      <div class="spacer"></div>
      <div class="row spaced">
        <Button
          label="Cancel"
          @click.native="deletingKey = null"
        />
        <Button
          label="Delete"
          class="delete"
          @click.native="deleteKey"
        />
      </div>
    </ModalContainer>

  </div>
</template>

<script>
  export default {
    name: 'KeyMapping',

    props: {
      isAuthor: [Boolean],
      inverted: [Boolean]
    },

    data() {
      return {
        output: [],
        selectedKey: null,
        selectedRune: null,
        newKey: null,
        error: null,
        deletingKey: null
      }
    },

    computed: {
      language() {
        return this.$store.state.languages?.[this.$route.params.id] || {}
      },

      keyMap() {
        return this.language.keyMap || {}
      },

      keyList() {
        if (!Object.keys(this.keyMap)?.length) return []
        let keys = {}
        Object.keys(this.keyMap).forEach(keyName => {
          let key = this.keyMap[keyName]
          key.key = keyName;
          keys[key.order] = key
        });

        return Object.values(keys)
      },

      runeImages() {
        return this.language?.runeImages || []
      },

      userID() {
        return this.$store.state.user?._id
      }
    },

    methods: {
      clickRune(key) {
        if (!this.isAuthor) return;
        this.selectedRune = this.selectedRune ? null : key
      },

      openRuneFileSelect() {
        let fileSelect = document.getElementById('runeFileSelect');
        fileSelect.click();
      },

      selectImage() {
        let newImage = event.target.files[0];
        let formData = new FormData();
        formData.append('rune', newImage, newImage.name);
        formData.append('languageID', this.$route.params.id);

        this.$store.dispatch('uploadRuneImage', formData).then(response => {
          console.log(response);
          if (response.status == 201) {
            this.$store.dispatch('fetchLanguage', this.$route.params.id);
          }
        });
      },

      selectRune(key, rune) {
        let newKey = { ...key }
        newKey.rune = rune;
        let newKeys = { ...this.keyMap }
        this.$set(newKeys, key.key, newKey);
        this.$store.dispatch('updateKeyMap', {
          languageID: this.$route.params.id,
          keyMap: newKeys
        }).then(response => {
          console.log(response);
        });
        this.selectedRune = null;
      },

      addKey() {
        if (this.keyMap['new key']) return;

        let newKeys = { ...this.keyMap }

        let randomID = Math.round(Math.random() * 99999999).toString(16);
        this.$set(newKeys, 'new key', {
          _id: randomID,
          order: this.keyList.length,
          rune: null
        });
        this.$store.dispatch('updateKeyMap', {
          languageID: this.$route.params.id,
          keyMap: newKeys
        }).then(response => {
          if (response.status == 200) {
            this.$store.dispatch('fetchLanguage', this.$route.params.id);
          }
        });
      },

      clickKey(key) {
        if (!this.isAuthor) return;
        this.selectedKey = key;
        this.readyInput();
      },

      readyInput() {
        window.addEventListener('keydown', this.onKeyDown);
      },
      removeInput() {
        window.removeEventListener('keydown', this.onKeyDown);
      },

      updateKey() {
        if (!this.newKey) return;

        this.$emit('key-map-updated');

        let newKeys = { ...this.keyMap }

        this.$set(newKeys, this.newKey, this.keyMap[this.selectedKey]);
        delete this.$delete(newKeys, this.selectedKey);
        this.$store.dispatch('updateKeyMap', {
          languageID: this.$route.params.id,
          keyMap: newKeys
        });

        this.selectedKey = null;
        this.newKey = null;
        this.error = null;
        this.removeInput();
      },

      cancelUpdateKey() {
        this.selectedKey = null;
        this.newKey = null;
        this.error = null;
        this.removeInput();
      },

      onKeyDown() {
        let key = event.key;
        let code = event.code;

        if (code == 'Backspace') {
          if (!this.newKey) {
            this.error = 'Backspace not allowed.'
            return;
          }
          else {
            this.newKey = this.newKey.substring(0, this.newKey.length - 1)
            return;
          }
        }

        if (code == 'Space') {
          event.preventDefault()
          this.error = 'Space not allowed.'
          return;
        }

        if (!this.newKey) this.newKey = ''
        this.newKey += key;
        this.error = null;

        if (this.keyMap[this.newKey]) {
          this.error = `"${this.newKey}" already in use.`
          return;
        }
      },

      deleteKey() {
        let newKeys = { ...this.keyMap }

        this.$delete(newKeys, this.deletingKey.key);
        this.$store.dispatch('updateKeyMap', {
          languageID: this.$route.params.id,
          keyMap: newKeys
        }).then(response => {
          if (response.status == 200) {
            this.$store.dispatch('fetchLanguage', this.$route.params.id);
          }
          this.deletingKey = null;
        });
      },
    }
  }
</script>

<style scoped lang="scss">

  .key-mapping {
    @include flex($direction: column);
    width: 100%;
    max-width: 400px;
  }

  .headers > * {
    padding: 0.5rem 1rem;
  }

  .keys {
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: bg(20%);
    border-spacing: 0;
    text-align: center;

    .grid-row {
      border-bottom: 1px solid #504d49;

      > *:not(:first-child) {
        border-left: 1px solid #8f7e68;
      }
    }

    .rune {
      height: 28px;
    }
  }

  .key-container {
    width: 100%;
    padding: 0.5rem 1rem;

    .row {
      justify-content: flex-start;
    }

    &:hover {
      cursor: pointer;

      .key {
        color: $caution;
        border: 1px solid $caution;
      }
    }
  }

  .key {
    display: inline;
    padding: 0.25rem 0.5rem;
    border-radius: 6px;
    color: $caution-disabled;
    font-family: 'Menlo', monospace;
    font-size: 0.8rem;
    line-height: 1.25em;
    border: 1px solid $caution-disabled;

    &.success {
      color: $alert-success-color;
      border: 1px solid $alert-success-color;
    }

    &.error {
      color: $alert-error-color;
      border: 1px solid $alert-error-color;
    }

    &.text {
      font-family: 'Quicksand', Avenir, sans-serif;
      white-space: pre;
    }
  }

  .key-input {
    justify-content: flex-start;
    padding: 0.5rem;
  }

  .rune-container {
    position: relative;
    padding: 0 1rem;

    &:hover {
      cursor: pointer;
      background-color: rgba(255,255,255,.1);
    }
  }

  .rune {
    vertical-align: middle;
  }

  .select-rune {
    position: absolute;
    @include flex($direction: column);
    flex-wrap: wrap;
    top: -0.5rem;
    left: 50%;
    width: 300px;
    padding: 0.75rem;
    border-radius: 0.25rem;
    transform: translate(-50%, -100%);
    background-color: #000;

    &:hover {
      cursor: auto;
    }
  }

  #runeFileSelect {
    display: none;
  }

  .rune-images {
    @include flex($justify: center);
    flex-wrap: wrap;

    img {
      height: 48px;
      padding: 0.25rem;

      &:hover {
        cursor: pointer;
        background-color: rgba(255,255,255,.15);
      }
    }
  }

  .writing-input {
    width: 300px;
  }

</style>
