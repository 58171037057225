<template>
  <div :class="`hint-component floating ${small && 'small'}`"
    :style="bgColor && `background-color: ${bgColor};`"
  >
    <Icon
      :name="icon || 'question'"
      :size="small ? '12px' : '16px'"
      :style="color && `color: ${color};`"
    />
    <p v-if="title"
      class="title"
      :style="color && `color: ${color};`"
    >{{ title }}</p>

    <div :class="`content floating ${origin || 'center bottom'}`">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Hint',
    props: {
      type: [String],
      origin: [String],
      icon: [String],
      title: [String],
      bgColor: [String],
      color: [String],
      textColor: [String],
      small: [Boolean],
    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped lang="scss">

  .hint-component {
    position: relative;
    @include flex($justify: center);
    // width: 40px;
    // height: 40px;
    padding: 0.75rem;
    border-radius: calc(1rem + 1em);
    color: $colorD;
    background-color: #957338;
    transition: background 0.3s;

    > .title {
      margin-left: 6px;
      // font-family: 'Cinzel Decorative', cursive;
      font-size: 0.75rem;
      font-weight: 300;
      text-transform: uppercase;
      line-height: 0.75rem;
      color: $colorD;
    }

    &.small {
      padding: 0.5rem;
    }

    &:hover {
      cursor: pointer;
      background-color: #b48a42;

      .content {
        opacity: 1;

        &.left {
          right: calc(100% + 1rem);
        }

        &.right {
          left: calc(100% + 1rem);
        }

        &.bottom {
          top: calc(40px + 1.5rem);
        }

        &.top {
          bottom: calc(40px + 1.5rem);
        }
      }
    }
  }

  .content {
    position: absolute;
    width: 240px;
    padding: 1.5rem;
    border-radius: 0.25rem;
    background-color: bg(20%);
    text-align: left;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
    z-index: 99;

    .title {
      color: $colorD;
      text-align: center;
    }

    ul, ul li {
      list-style-position: inside;
      margin: 0;
      padding: 0;
    }

    p, ul, ul li {
      font-size: 0.9rem;
    }

    &.center {
      left: 50%;
      transform: translateX(-50%);
    }

    &.left {
      right: 100%;
    }

    &.right {
      left: 100%;
    }

    &.bottom {
      top: calc(40px + 0.5rem);
    }

    &.top {
      bottom: calc(40px + 0.5rem);
    }
  }

</style>
