<template>
  <div class="page">

    <div class="spacer"></div>
    <h1>Create New Timeline</h1>
    <div class="spacer h5"></div>


    <div class="inputs">
      <div class="table centered">
        <div class="table-row">
          <p>Title <span class="required">*</span></p>
          <input type="text" @input="(e) => title = e.target.value" />
        </div>

        <div class="table-row">
          <p>Description</p>
          <textarea @input="(e) => description = e.target.value" />
          </div>

        <div class="table-row">
          <p>Start Year <span class="required">*</span></p>
          <input type="number" :value="start" @input="(e) => start = parseInt(e.target.value)" />
        </div>

        <div class="table-row">
          <p>End Year <span class="required">*</span></p>
          <input type="number" :value="end" @input="(e) => end = parseInt(e.target.value)" />
        </div>
      </div>
      <p class="note">Note: Additional timeframes (Start and End years) can be added at any time.</p>
    </div>



    <div class="spacer h3"></div>

    <div class="row">
      <Button
        label="Back"
        icon="left-arrow"
        iconSize="12px"
        style="margin-right: 1rem;"
        @click.native="$router.back()"
      />
      <Button
        label="Create Timeline"
        class="primary"
        @click.native="createTimeline"
      />
    </div>

    <div class="spacer"></div>

    <Alert :alert="alert" @dismiss="alert = null" />

    <div class="spacer h5"></div>

  </div>
</template>

<script>
  export default {
    name: 'NewTimeline',
    components: {

    },
    data() {
      return {
        alert: null,

        title: null,
        description: null,
        start: 0,
        end: 100
      }
    },

    methods: {
      createTimeline() {
        if (!this.title) {
          this.alert = {
            type: 'warning',
            message: 'Please enter a title.'
          }
          return;
        }

        let newTimeline = {
          worldID: this.$store.state.world?._id,
          title: this.title,
          description: this.description || '',
          backgroundImage: null,
          zones: {
            'default': {
              _id: 'default',
              name: 'Default',
              start: this.start,
              end: this.end
            }
          },
        }

        this.$store.dispatch('newTimeline', newTimeline).then(response => {
          console.log(response);
          if (response.status === 201) {
            this.$store.dispatch('fetchCurrentUserWorld')
            if (response?.data?.id) {
              this.$router.push('/timeline/' + response.data.id)
            }
            else {
              this.$router.push('/creation')
            }
          }
        });
      }
    }
  }
</script>

<style scoped lang="scss">

  .inputs {
    width: 100%;
    max-width: 600px;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: bg(20%);

    .table {
      width: 100%;
    }
  }

  .required {
    color: #ff2553;
  }

  .note {
    margin: 1rem 0;
    color: $colorD;
    font-size: 0.9rem;
    text-align: center;
  }

</style>
