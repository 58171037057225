<template>
  <div class="decorative-title">
    <div class="title-container">
      <slot></slot>
    </div>
  </div>
</template>


<script>
  export default {
    name: 'DecorativeTitle',

    props: {
      color: [String]
    }
  }
</script>


<style scoped lang="scss">

  .decorative-title {
    position: relative;
    @include flex($justify: center);

    .title-container {
      position: relative;
      background-color: #000;
      padding: 1.5rem;
      box-sizing: border-box;
      font-family: 'Cinzel Decorative', cursive;
      text-transform: uppercase;
      user-select: none;

      * {
        color: $colorD;
      }

      h1 {
        font-weight: 400;
        font-size: 1.75rem;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        box-shadow: inset 0 0 0 2px $colorD;
      }

      &::after {
        content: '';
        position: absolute;
        top: 45%;
        left: 0;
        width: 100%;
        height: 55%;
        box-sizing: border-box;
        background-color: darken($colorD, 50%);
        mix-blend-mode: multiply;
        opacity: 0.65;
        // clip-path: polygon(0 30%, 100% 0, 100% 100%, 0 100%);
      }
    }


    &.gold {
      .title-container {
        color: $colorD;
        &::before {
          box-shadow: inset 0 0 0 2px $colorD;
        }
        &::after {
          background-color: darken($colorD, 50%);
        }
      }
    }

    &.green {
      .title-container {
        * {
          color: $colorA;
        }
        &::before {
          box-shadow: inset 0 0 0 2px $colorA;
        }
        &::after {
          background-color: darken($colorA, 50%);
        }
      }
    }

    &.blue {
      .title-container {
        * {
          color: $colorC;
        }
        &::before {
          box-shadow: inset 0 0 0 2px $colorC;
        }
        &::after {
          background-color: darken($colorC, 50%);
        }
      }
    }

    &.purple {
      .title-container {
        * {
          color: #754de7;
        }
        &::before {
          box-shadow: inset 0 0 0 2px #754de7;
        }
        &::after {
          background-color: darken(#754de7, 50%);
        }
      }
    }

  }

</style>
