<template>
  <div
    :class="formatClass"
    :style="formatStyle"
  >
    <span v-if="label">
      <Icon v-if="icon"
        :name="icon" :size="iconSize"
        style="margin-right: 0.5rem;" />{{ label }}
      <Icon v-if="iconAfter"
        :name="iconAfter" :size="iconSize"
        style="margin-left: 0.5rem;" />
    </span>

    <Icon v-if="!label && icon" class="no-label-icon"
      :name="icon" :size="iconSize" />

    <div class="button-base-inner" :style="image ? `background-image: url(${image}); filter: saturate(50%) contrast(75%) brightness(75%);` : ''"></div>
    <div class="button-border blur" :style="formatGradient(bgColor)"></div>
    <div class="button-border" :style="formatGradient(bgColor)"></div>
    <div v-if="styleHasBorder(type)" class="button-base-outer"></div>

  </div>
</template>

<script>
  import Icon from './Icon.vue';

  export default {
    name: 'CoolButton',
    components: {
      Icon
    },
    props: {
      type: [String],
      label: String,
      textColor: String,
      bgColor: String,
      image: [String],
      selected: [Boolean],
      hoverAnimation: [Boolean],
      touchClass: [String],
      animation: [String],
      icon: [String],
      iconAfter: [String],
      iconSize: [String]
    },

    data() {
      return {
        touchScreen: null
      }
    },

    computed: {
      formatClass() {
        let options = [
          'button-container',
          this.type || 'button',
          this.animation,
          this.selected ? 'selected' : '',
          this.hoverAnimation ? 'hoverAnimation' : '',
          this.touchScreen
        ]
        return options.join(' ')
      },

      formatStyle() {
        const CIRCLE_PADDING = 1; // rem
        let circleSize = `calc(${CIRCLE_PADDING * 2}rem + ${this.iconSize})`;
        let options = [
          `--colorA: ${this.textColor};`,
          this.type === 'circle' ? `height: ${circleSize}; width: ${circleSize};` : ''
        ]
        return options.join(' ')
      }
    },

    methods: {
      styleHasBorder(string) {
        if (!string) return true;
        if (string.includes('compact') || string.includes('circle')) return false;
        else if (string.includes('menu')) return false;
        else return true;
      },

      formatGradient(color) {
        let rgb = color.substring(4, color.length - 1);

        // Repeating
        if (this.animation === 'infinite') {
          return `background: linear-gradient(75deg, rgba(${rgb},0.25) 20%, rgba(${rgb},1) 45%, rgba(${rgb},0.25) 55%, rgba(${rgb},0.25) 65%, rgba(${rgb},1) 75%, rgba(${rgb},0.25) 85%);`;
        }

        // One Time
        return `background: linear-gradient(75deg, rgba(${rgb},0.25) 20%, rgba(${rgb},1) 65%, rgba(${rgb},0.25) 90%);`;
      }
    },
    mounted() {
      const isTouchScreen = navigator.maxTouchPoints;
      if (isTouchScreen) this.touchScreen = this.touchClass;
    }
  }
</script>

<style scoped lang="scss">

  .button-container {
    --marginX: 10px;
    --marginY: 10px;
    --colorA: #32cca2;
    --borderWidth: 1px;

    position: relative;
    display: inline-flex;
    flex-direction: column;
    padding: 1.25rem 2rem;
    border-radius: 0.5rem;
    background-color: transparent;
    transition: all 0.3s;
    box-sizing: border-box;
    user-select: none;
    z-index: 1;

    span {
      display: inline-flex;
      align-items: center;
      margin: auto;
      color: rgba(255,255,255,.5);
      font-size: 0.9rem;
      font-weight: 600;
      z-index: 3;
      transition: all 0.3s;
      text-transform: uppercase;
    }

    &.compact {
      span {
        color: var(--colorA);
        // opacity: 0.75;
      }
    }

    .no-label-icon {
      color: rgba(255,255,255,.5);
      transition: color 0.3s, opacity 0.3s;
    }

    &.menu {
      span {
        color: rgba(255,255,255,.6);
        text-shadow: 0 0 8px rgba(0,0,0,.5), 0 0 4px rgba(0,0,0,1);
      }
    }

    &.selected {
      span {
        color: var(--colorA);
        opacity: 1;
      }
      .button-border {
        opacity: 1;
        background-color: var(--colorA);
      }
      .button-base-inner {
        opacity: 1;
        background-color: var(--colorA);
        filter: brightness(30%) grayscale(25%);
      }

      &:hover {
        span {
          color: #fff;
        }
        .button-base-inner {
          filter: brightness(40%) grayscale(15%);
        }
      }
    }

    &:hover {
      cursor: pointer;

      span {
        color: var(--colorA);
        opacity: 1;
        text-shadow: 0 0 4px var(--colorA);
      }

      .no-label-icon {
        color: var(--colorA);
        opacity: 1;
      }

      .button-base-inner {
        opacity: 1;
      }

      .button-border {
        opacity: 1;
        animation-name: borderTraceIn;
      }
    }

    &.hoverAnimation {
      cursor: pointer;

      span {
        color: var(--colorA);
        opacity: 1;
      }

      .no-label-icon {
        color: var(--colorA);
        opacity: 1;
      }

      .button-base-inner {
        opacity: 1;
      }

      .button-border {
        opacity: 1;
        animation-name: borderTraceIn;
      }
    }


    &.menu {
      @include flex($direction: column, $justify: center);
      width: 160px;
      height: 160px;

      span {
        margin: 0;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
      }
    }

    &.menu.small {
      display: inline-flex;
      padding: 1rem;
      width: 120px;
      height: 120px;

      span {
        font-size: 0.75rem;
      }
    }

    &.compact, &.circle {
      .button-base-inner {
        box-shadow: none;
      }
    }

    &.button {
      white-space: nowrap;
    }

    &.menu {
      --marginX: 0px;
      --marginY: 0px;
      box-shadow: 0 8px 20px -12px rgba(0,0,0,.5), 0 20px 40px -30px rgba(0,0,0,.75);
    }

    &.dark {
      .button-base-outer, .button-base-inner {
        background-color: bg(10%);
      }
    }
  }

  .button-base-outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    border-radius: 0.5rem;
    background-color: bg(25%);
    box-shadow: inset 0 0 8px rgba(0,0,0,.25);
    filter: drop-shadow(2px 2px 4px rgba(0,0,0,.5));
    z-index: -3;
  }

  .button-base-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - (var(--marginX) * 2));
    height: calc(100% - (var(--marginY) * 2));
    margin: var(--marginY) var(--marginX);
    border-radius: 0.5rem;
    box-sizing: border-box;
    background-color: bg(25%);
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 8px rgba(0,0,0,.5);
    z-index: -1;
    transition: all 0.3s;
  }

  .button-border {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - (var(--marginX) * 2) + (var(--borderWidth) * 2));
    height: calc(100% - (var(--marginY) * 2) + (var(--borderWidth) * 2));
    margin: calc(var(--marginY) - var(--borderWidth)) calc(var(--marginX) - var(--borderWidth));
    box-sizing: border-box;
    border-radius: 0.5rem;
    z-index: -2;
    border: 2px solid transparent;
    background-size: 1200% 1200% !important;
    background-position: right center;
    opacity: 0;
    transition: border 0.25s, opacity 0.25s;
    animation-timing-function: cubic-bezier();
    animation-duration: 1.25s;
    animation-iteration-count: 1;
  }

  .blur {
    filter: blur(6px);
  }

  .button-container.infinite {
    .button-border {
      animation-timing-function: linear;
      animation-duration: 2.5s;
      animation-iteration-count: infinite;
    }
  }

  .button-container.compact, .button-container.circle {
    --marginX: 0px;
    --marginY: 0px;
    padding: 0.75rem 1.5rem;
    box-shadow: 0 2px 16px -8px rgba(0,0,0,.5), 0 10px 20px -10px rgba(0,0,0,.25);
  }

  .button-container.no-label {
    padding: 1.25rem;
  }

  .button-container.circle {
    @include flex($justify: center);
    &, .button-base-inner, .button-border {
      border-radius: 100%;
    }
  }

  .button-container.oval {
    padding: 0.5rem 1.5rem;

    &, .button-base-inner, .button-border {
      border-radius: calc(1em + 0.5rem);
    }

    span {
      font-size: 0.8rem;
      text-transform: none;
    }
  }

  @keyframes borderTraceIn {
    0% {
      background-position: right center;
    }
    100% {
      background-position: left center;
    }
  }

</style>
