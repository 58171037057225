<template>
  <div class="page">

    <div class="spacer"></div>
    <h2>Appearance</h2>

    <div class="spacer h3"></div>

    <div class="preview" :style="`background-color: ${backgroundColor};`">
      <h4 :style="`color: ${primaryColor};`">A bold statement.</h4>
      <p :style="`color: ${secondaryColor};`">I am a paragraph describing many things...</p>
      <div v-if="showDraftSaved" class="spacer"></div>
      <p v-if="showDraftSaved">Draft Saved.</p>
    </div>

    <div class="menu">
      <ColorPicker label="Primary" :color="primaryColor" @color-picked="(color) => primaryColor = color" />
      <ColorPicker label="Secondary" :color="secondaryColor" @color-picked="(color) => secondaryColor = color" />
      <ColorPicker label="Background" :color="backgroundColor" @color-picked="(color) => backgroundColor = color" />
      <Checkbox label="Show Draft Saved" :checked="showDraftSaved" @checkbox-toggle="(checked) => showDraftSaved = !showDraftSaved" />
    </div>

  </div>
</template>

<script>
  export default {
    name: 'Appearance',

    data() {
      return {
        primaryColor: '#60e0be',
        secondaryColor: '#b1c0da',
        backgroundColor: '#1a1a1a',

        showDraftSaved: true
      }
    }
  }
</script>

<style scoped lang="scss">

  .preview {
    margin: 2rem;
    padding: 2rem;
    border-radius: 0.5rem;
  }

  .menu {
    @include flex($direction: column, $align: flex-start);

    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

</style>
