<template>
  <div :class="`glowing-pattern ${blur ? 'blur' : ''}`">
    <div :class="`pattern ${pattern || 'top-right-corner-threads'} ${animate ? 'animate' : ''}`"></div>
    <!-- <div class="pattern line4"></div> -->
  </div>
</template>


<script>
  export default {
    name: 'GlowingPattern',

    props: {
      pattern: [String],
      animate: [Boolean],
      blur: [Boolean]
    }
  }
</script>


<style scoped lang="scss">

  .glowing-pattern {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    margin: 0 auto;
    // background-color: rgba(255,255,255,.05);
    z-index: -2;
    overflow: hidden;
    pointer-events: none;
    user-select: none;

    &.blur {
      filter: blur(4px) contrast(1.2) brightness(1.25);
    }
  }

  .pattern {
    position: absolute;
    top: 0;
    left: 0;
    width: 1000px;
    height: 1000px;
    background-image: url('https://images.unsplash.com/photo-1517999144091-3d9dca6d1e43?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=927&q=80');
    background-size: auto;
    background-position: center center;
    filter: brightness(1.5) sepia(1);

    &.animate {
      animation: movingBG 30s infinite -8s;
      animation-timing-function: linear;
    }

    &.line1 {
      width: 100px;
      height: 100px;
      top: 50px;
      left: unset;
      right: 50px;
      transform: scale(2);
      clip-path: path('M0,0  C0,0 25,2 50,50  C 50,50 75,98 100,100  C 100,100 75,97.5 50,50  C 50,50 25,2 0,0');
    }

    &.line2 {
      width: 100px;
      height: 100px;
      top: 100px;
      left: unset;
      right: 100px;
      transform: scale(3);
      clip-path: path('M0,0  C0,0 25,2 50,50  C 50,50 75,98 100,100  C 100,100 75,97.5 50,50  C 50,50 25,2 0,0');
    }

    &.line4 {
      width: 1000px;
      height: 1000px;
      top: 0;
      left: unset;
      right: 0;
      clip-path: path('
        M 800,0
        C 900,500 200,0 0,1000
        C 201,1 900,500 801,1
        M 870,0
        C 850,450 200,0 200,1000
        C 201,1 850,450 871,1
        M 940,0
        C 800,400 200,0 400,1000
        C 201,1 800,400 941,1
        Z
      ');
    }

    &.top-right-corner-threads {
      width: 1000px;
      height: 1000px;
      left: unset;
      top: 0;
      right: 0;
      // transform: scale(0.5);
      clip-path: path('
        M 200,0
        C 100,500 800,300 1000,600
        C 801,299 100,500 201,0
        M 130,0
        C 150,450 850,250 1000,700
        C 851,249 150,450 131,0
        M 60,0
        C 200,400 900,200 1000,900
        C 901,199 200,400 61,0
        Z
      ');
    }

    &.line3 {
      width: 1000px;
      height: 1000px;
      top: 0;
      left: unset;
      right: 0;
      transform: scale(1);
      clip-path: path('
        M 1000 0
        C 800 100 800 200 800 400
        C 800 600 760 700 690 700
        C 610 700 610 590 690 590
        C 610 590 610 700 690 701
        C 760 700 800 600 801 400
        C 800 200 800 100 1000 1
        M 1000 40
        C 800 100 800 209 820 410
        C 830 670 790 740 690 740
        C 630 740 580 690 580 630
        C 580 480 480 520 480 560
        C 480 600 530 610 530 570
        C 530 610 480 600 479 560
        C 480 520 580 480 579 630
        C 580 690 630 740 690 741
        C 790 740 830 670 821 410
        C 800 209 800 100 1000 41
      ');
      z-index: 99;
      background-color: #fff;
    }
  }

  @keyframes movingBG {
    0% { background-position: top left; }
    100% { background-position: bottom right; }
  }

</style>
