<template>
  <div
    class="word-processor"
    @mousedown="onOutsideMouseDown"
    @mouseup="onOutsideMouseUp"
  >

    <div class="toolbar"
      @mousedown.stop=""
      @mouseup.stop=""
    >
      <Button
        icon="heading"
        :class="`title ${currentLineStyle == 'title' ? 'primary' : 'dark'}`"
        @mouseup.bubble.native="title"
      />
      <Button
        icon="h2"
        :class="`subtitle ${currentLineStyle == 'subtitle' ? 'primary' : 'dark'}`"
        @mouseup.bubble.native="subtitle"
      />
      <Button
        icon="bold"
        :class="`bold ${currentLineStyle == 'bold' ? 'primary' : 'dark'}`"
        @mouseup.bubble.native="bold"
      />
      <ColorPicker
        @color-picked="color"
      />
    </div>



    <div id="nodes" class="nodes-container">

      <div v-for="(node, lineIndex) in nodes"
        :class="`node ${node.style}`"
        :style="node.color ? `color: ${node.color};` : ''"
      >
        <template v-for="(char, charIndex) in node.chars">

          <span>{{ char || '&ZeroWidthSpace;' }}</span>


          <div
            v-if="cursorLine === lineIndex && cursorChar === charIndex"
            class="cursor-caret-container"
          >
            <input
              ref="cursor"
              class="cursor-input"
              tabindex="0"
              @keydown.prevent="onInput"
            />
            <div
              id="cursor"
              :class="`cursor-caret animated ${hasSelection ? 'selection' : ''}`"
            ></div>
          </div>

        </template>
      </div>

    </div>

  </div>
</template>

<script>
  export default {
    name: 'WordProcessor',
    components: {
    },
    props: {
      content: [Array],
      currentSceneIndex: [Number]
    },
    data() {
      return {
        nodes: [{
          style: '',
          color: '',
          chars: ['\u200B']
        }],

        cursorLine: null,
        cursorChar: null,

        selection: {
          startLine: null,
          startChar: null,
          endLine: null,
          endChar: null
        },

        mouseDownEvent: null
      }
    },

    watch: {
      currentSceneIndex: function(newValue) {
        this.cursorLine = null
        this.cursorChar = null
        this.selection = {
          startLine: null,
          startChar: null,
          endLine: null,
          endChar: null
        }
      },

      content: function(newContent, oldContent) {
        this.nodes = this.import(newContent)
      }
    },

    computed: {
      hasSelection() {
        const hasNoSelection = this.selection?.startLine == this.selection?.endLine
          && this.selection?.startChar == this.selection?.endChar
        return !hasNoSelection
      },

      currentLineStyle() {
        return this.cursorLine != null ? this.nodes[this.cursorLine].style : ''
      }
    },


    methods: {

      logNodes(newNodes) {
        let nodes = newNodes ? newNodes : this.nodes
        console.groupCollapsed('%cnodes', 'color: #2275d6');
        // console.log(`line %c${index}%c: ${chars}`, 'color: #ec55b4', '');
        console.table([...nodes.map(node => node.chars)])
        console.groupEnd();
      },

      newNode(newChars) {
        return {
          style: '',
          chars: newChars && newChars.length ? ['\u200B', ...newChars] : ['\u200B']
        }
      },

      bold() {
        event.stopPropagation()
        this.nodes[this.cursorLine].style = this.nodes[this.cursorLine].style == 'bold' ? '' : 'bold'
        this.$emit('updated', this.export())
      },
      title() {
        event.stopPropagation()
        this.nodes[this.cursorLine].style = this.nodes[this.cursorLine].style == 'title' ? '' : 'title'
        this.$emit('updated', this.export())
      },
      subtitle() {
        event.stopPropagation()
        this.nodes[this.cursorLine].style = this.nodes[this.cursorLine].style == 'subtitle' ? '' : 'subtitle'
        this.$emit('updated', this.export())
      },
      color(c) {
        event.stopPropagation()
        this.$set(this.nodes[this.cursorLine], 'color', c)
        // this.$emit('updated', this.export());
      },

      deleteSelection() {
        const { startLine, startChar, endLine, endChar } = this.selection

        if (startLine === endLine) {
          this.nodes[startLine].chars.splice(startChar, endChar - startChar + 1)
        }
        else {
          // Get unselected chars from start line and end line
          let startLineChars = this.nodes[startLine].chars.slice(0, startChar)
          let endLineChars = this.nodes[endLine].chars.slice(endChar + 1)
          // Combine both on start line
          this.nodes[startLine].chars = [...startLineChars, ...endLineChars]
          // Remove all selected lines after start line (including end line)
          this.nodes.splice(startLine + 1, endLine - startLine)
        }

        this.cursorLine = startLine
        this.cursorChar = startChar === 0 ? 0 : startChar - 1

        this.collapseSelection()
      },

      export() {
        let exportNodes = [];
        this.nodes.forEach(node => {
          exportNodes.push({
            content: node.chars.join(''),
            style: node.style
          });
        });
        return exportNodes
      },

      import(data) {
        let nodes = [];
        if (!data || !data.length || typeof data !== 'object') {
          nodes = [this.newNode()]
        }
        else {
          data.forEach(line => {
            nodes.push({
              chars: line.content.split(''),
              style: line.style
            })
          })
        }
        return nodes
      },

      resetCursorAnimation() {
        const cursor = document.getElementById('cursor')
        cursor.style.animation = 'none'
        cursor.offsetHeight // trigger DOM update
        cursor.style.animation = null
      },



      async focusInput() {
        await this.$nextTick()
        if (this.$refs.cursor[0]) this.$refs.cursor[0].focus()
        return true
      },

      async onInput() {
        event.preventDefault()
        event.stopPropagation()

        this.resetCursorAnimation()

        let key = event.key


        switch (key) {
          case 'Enter':
            if (this.cursorChar === this.nodes[this.cursorLine].length - 1) {
              this.nodes.splice(this.cursorLine + 1, 0, this.newNode())
            }
            else {
              let newLineChars = this.nodes[this.cursorLine].chars.slice(this.cursorChar + 1)
              this.nodes[this.cursorLine].chars.splice(this.cursorChar + 1)
              this.nodes.splice(this.cursorLine + 1, 0, this.newNode(newLineChars))
            }
            this.cursorLine++
            this.cursorChar = 0
            break

          case 'Backspace':
            if (this.hasSelection) {
              this.deleteSelection()
              break
            }

            if (this.cursorLine === 0 && this.cursorChar === 0) return
            else if (this.cursorChar === 0) {
              // Combine both lines
              // Slice(1) to remove invisible char at index 0
              let previousLineChars = this.nodes[this.cursorLine - 1].chars
              this.nodes[this.cursorLine - 1].chars = [...previousLineChars, ...this.nodes[this.cursorLine].chars.slice(1)]
              // Remove current line
              this.nodes.splice(this.cursorLine, 1)
              // Put cursor at end of previous line chars
              this.cursorLine--
              this.cursorChar = previousLineChars.length - 1
            }
            else {
              this.nodes[this.cursorLine].chars.splice(this.cursorChar, 1)
              this.cursorChar--
            }
            break

          case 'ArrowUp':
            if (this.cursorLine === 0) return
            let previousLine = this.nodes[this.cursorLine - 1]
            if (this.cursorChar > previousLine.chars.length - 1) {
              this.cursorChar = previousLine.chars.length - 1
            }
            this.cursorLine--
            break

          case 'ArrowDown':
            if (this.cursorLine === this.nodes.length - 1) return
            let nextLine = this.nodes[this.cursorLine + 1]
            if (this.cursorChar > nextLine.chars.length - 1) {
              this.cursorChar = nextLine.chars.length - 1
            }
            this.cursorLine++
            break

          case 'ArrowRight':
            let currentLine = this.nodes[this.cursorLine]
            if (this.cursorLine === this.nodes.length - 1 && this.cursorChar === currentLine.chars.length - 1) return
            if (this.cursorChar === currentLine.chars.length - 1) {
              this.cursorLine++
              this.cursorChar = 0
            }
            else {
              this.cursorChar++
            }
            break

          case 'ArrowLeft':
            if (this.cursorLine === 0 && this.cursorChar === 0) return
            if (this.cursorChar === 0) {
              let previousLine = this.nodes[this.cursorLine - 1]
              this.cursorLine--
              this.cursorChar = previousLine.chars.length - 1
            }
            else {
              this.cursorChar--
            }
            break

          default:
            if (key.length > 1) return
            let lastNode = this.nodes[this.cursorLine]

            lastNode.chars.splice(this.cursorChar + 1, 0, key)
            this.cursorChar++
            break
        }

        await this.focusInput()
        this.$emit('updated', this.export())
      },

      keyDownOutside() {
        if (event.key === 'Backspace') {
          this.$emit('updated', this.export())
        }
      },



      // Clicks

      onOutsideMouseDown() {
        this.mouseDownEvent = event

        this.cursorLine = null
        this.cursorChar = null
      },

      onOutsideMouseUp() {
        event.stopPropagation()

        this.calcSelectionAndSetCursor()
      },

      collapseSelection() {
        let { startLine, startChar, endLine, endChar } = this.selection

        if (!startLine == null || !startChar == null) {
          startLine = this.nodes?.length - 1
          startChar = this.nodes[startLine]?.chars?.length - 1
        }

        this.selection = {
          startLine,
          startChar,
          endLine: startLine,
          endChar: startChar
        }

        const selection = window.getSelection()
        const range = document.createRange()
        const line = document.getElementById('nodes').children[startLine]
        const char = line.children[startChar]
        range.setStart(char, 0)
        range.setEnd(char, 0)
        selection.removeAllRanges()
        selection.addRange(range)
      },

      async calcSelectionAndSetCursor() {
        const selection = window.getSelection();
        const baseEl = selection.baseNode.parentElement;
        const extentEl = selection.extentNode.parentElement;

        const baseLine = Array.prototype.indexOf.call(baseEl.parentElement.parentElement.children, baseEl.parentElement)
        const baseChar = Array.prototype.indexOf.call(baseEl.parentElement.children, baseEl)
        const extentLine = Array.prototype.indexOf.call(extentEl.parentElement.parentElement.children, extentEl.parentElement)
        const extentChar = Array.prototype.indexOf.call(extentEl.parentElement.children, extentEl)

        let startLine, startChar, endLine, endChar;

        if (baseLine < extentLine) {
          startLine = baseLine;
          startChar = baseChar;
          endLine = extentLine;
          endChar = extentChar;
        }
        // Bottom up
        else if (baseLine > extentLine) {
          startLine = extentLine;
          startChar = extentChar;
          endLine = baseLine;
          endChar = baseChar;
        }
        // Same line
        else {
          startLine = baseLine;
          endLine = extentLine;
          startChar = Math.min(baseChar, extentChar);
          endChar = Math.max(baseChar, extentChar);
        }

        this.selection = { startLine, startChar, endLine, endChar };

        this.cursorLine = extentLine
        this.cursorChar = extentChar
        await this.focusInput()


        const isClick = startLine == endLine && startChar == endChar
        if (!isClick) {
          const range = document.createRange()
          const isForwardSelection = baseLine <= extentLine && baseChar <= extentChar
          const startEl = isForwardSelection ? baseEl : extentEl
          const endEl = isForwardSelection ? extentEl : baseEl
          range.setStart(startEl.childNodes[0], 0)
          range.setEnd(endEl.childNodes[0], 1)
          selection.removeAllRanges()
          selection.addRange(range)
        }
      }
    },


    created() {
      this.nodes = this.import(this.content);
    },
    mounted() {
      window.addEventListener('keydown', this.keyDownOutside);
    },
    destroyed() {
      window.removeEventListener('keydown', this.keyDownOutside);
    }
  }
</script>

<style scoped lang="scss">

  .word-processor {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.25rem;
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background-color: rgba(0,0,0,.2);

    &:hover {
      cursor: text;
    }
  }

  .toolbar {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    > *:not(:last-child) {
      margin-right: 0.25rem;
    }

    &:hover {
      cursor: auto;
    }

    .button-component {
      padding: 0.5rem 1rem;
    }

    /deep/ .title span {
      font-family: 'Cinzel Decorative', sans-serif;
      font-weight: 400;
    }
    /deep/ .subtitle span {
      color: #ccc;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
    /deep/ .bold span {
      color: #ccc;
      font-family: 'Quicksand', Avenir, sans-serif;
      font-weight: 800;
      // text-transform: uppercase;
    }
  }

  .cursor-input {
    position: absolute;
    margin: 0;
    padding: 0;
    // height: 1px;
    width: 1px;
    background: none;
    border: 0;
    overflow: visible;
    opacity: 0;
  }

  .cursor-caret-container {
    position: relative;
    display: inline-block;
    line-height: 0;
  }

  .cursor-caret {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    align-self: center;
    bottom: -0.2em;
    height: 1.2em;
    width: 1.5px;
    contain: strict;
    background-color: $colorA;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;

    &.animated {
      animation-name: cursorBlink;
    }

    &.selection {
      background-color: #f63065;
      animation-name: none;
      opacity: 0;
    }
  }

  @keyframes cursorBlink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }


  .node {
    position: relative;
    display: block;
    white-space: pre;
    color: #fff;
    // flex-wrap: wrap;

    span {
      color: inherit;
    }

    &.title {
      margin-bottom: 0.25rem;
      font-family: 'Cinzel Decorative', Avenir, sans-serif;
      font-size: 1.5rem;
      text-align: center;
      font-weight: 400;
    }

    &.subtitle {
      margin-bottom: 0.25rem;
      color: #94acd7;
      font-size: 0.9rem;
      font-weight: 600;
      text-align: center;
      letter-spacing: 0.25px;
      text-transform: uppercase;
    }

    &.bold {
      font-weight: 600;
    }

    &:hover {
      cursor: text;
    }
  }

</style>
