<template>
  <div>

    <Loader v-if="loading" />

    <template v-else>
      <div class="spacer"></div>
      <h2>World Invitations</h2>
      <div class="spacer"></div>

      <p v-if="!invitations.length">You have no world invitations.</p>

      <div class="invitations">
        <router-link
          v-for="invitation in invitations"
          :to="'/invitation/' + invitation._id"
        >
          <div class="invitation">
            <p class="hint">{{ formatDate(invitation.date) }}</p>
            <p>
              <router-link :to="'/user/' + invitation.author._id">{{ invitation.author.name }}</router-link>
              invited you to
              <router-link :to="'/world/' + invitation.world._id">{{ invitation.world.name }}</router-link>
            </p>
            <br/>
            <p v-if="invitation.acceptedOn"><span class="success">Accepted</span> on {{ formatDate(invitation.acceptedOn) }}</p>
            <p v-else-if="invitation.rejectedOn"><span class="error">Rejected</span> on {{ formatDate(invitation.rejectedOn) }}</p>
            <p v-else>Pending</p>
          </div>
        </router-link>
      </div>

    </template>

  </div>
</template>

<script>
import Avatar from '../Avatar.vue'
import WorldPreview from '../../World/WorldPreview.vue'

  export default {
    name: 'Invitations',
    components: {
      Avatar,
      WorldPreview
    },

    computed: {
      user() {
        return this.$store.state.user || {}
      }
    },

    data() {
      return {
        loading: true,
        invitations: []
      }
    },

    created() {
      this.$store.dispatch('fetchInvitations').then(response => {
        console.log(JSON.parse(JSON.stringify(response)));
        if (response.status == 200) {
          this.invitations = response?.data
        }
        this.loading = false
      })
    }
  }
</script>

<style scoped lang="scss">

  .invitations {
    @include flex();
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
  }

  .invitation {
    @include flex($direction: column, $align: flex-start);
    width: 100%;
    padding: 2rem;
    border-radius: 0.25rem;
    background-color: bg(20%);

    a {
      color: $colorB;

      &:hover {
        color: $colorA;
      }
    }
  }

</style>
