<template>
  <div class="new-point-container">
    <div class="new-point">

      <h4>Add New Line</h4>

      <div class="inputs">
        <input type="text" placeholder="Title" @input="inputTitle" />
      </div>

      <Alert :alert="alert" @dismiss="alert = null" />

      <div class="buttons">
        <Button label="Add" color="#5edaad" @click.native="add" />
        <Button label="Cancel" color="#df4b6e" @click.native="$emit('cancel')" />
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'NewLine',

    data() {
      return {
        title: null,
        alert: null
      }
    },

    methods: {
      inputTitle() {
        this.title = event.target.value;
        this.alert = null;
      },

      add() {
        if (!this.title.trim()) {
          this.alert = { type: 'warning', message: 'Please enter a title.' };
          return;
        }

        this.$emit('add', this.title);
      }
    }
  }
</script>

<style scoped lang="scss">

  .new-point-container {
    position: fixed;
    top: 0;
    left: 0;
    @include flex($justify: center);
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    z-index: 999;

    .new-point {
      position: absolute;
      @include flex($direction: column);
      padding: 2rem;
      border-radius: 1rem;
      color: #fff;
      background-color: rgba(0,0,0,.75);
      border: 1px solid rgba(255,255,255,.25);
      z-index: 9999;
    }
  }

  .buttons {
    @include flex($justify: center);

    > *:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  .inputs {
    width: 240px;
    margin: 2rem 0;

    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

</style>
