<template>
  <div class="new-point-container">
    <div class="new-point">

      <h4>Add New Point</h4>

      <div class="inputs">
        <input type="number" placeholder="Year" :value="year" @input="inputYear" />
        <input type="text" placeholder="Month" :value="month" @input="inputMonth" />
        <input type="number" placeholder="Day" :value="day" @input="inputDay" />
        <input type="text" placeholder="Title" @input="inputTitle" />
      </div>

      <Alert :alert="alert" @dismiss="alert = null" />

      <div class="buttons">
        <Button label="Add" color="#5edaad" @click.native="add" />
        <Button label="Cancel" color="#df4b6e" @click.native="$emit('cancel')" />
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'NewPoint',
    components: {
    },
    props: {
      year: Number,
      month: [String],
      day: [Number]
    },
    data() {
      return {
        point: {
          year: null,
          month: null,
          day: null,
          title: null
        },

        alert: null
      }
    },
    methods: {
      inputYear() {
        this.point.year = event.target.value;
        this.alert = null;
      },

      inputMonth() {
        this.point.month = event.target.value;
        this.alert = null;
      },

      inputDay() {
        this.point.day = event.target.value;
        this.alert = null;
      },

      inputTitle() {
        this.point.title = event.target.value;
        this.alert = null;
      },

      add() {
        if (this.point.year == null || !this.point.title.trim()) {
          this.alert = { type: 'warning', message: 'Please enter all fields.' };
          return;
        }

        this.$emit('add', this.point);
      }
    },

    mounted() {
      this.point.year = this.year;
      this.point.month = this.month;
      this.point.day = this.day;
    }
  }
</script>

<style scoped lang="scss">

  .new-point-container {
    position: fixed;
    top: 0;
    left: 0;
    @include flex($justify: center);
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    z-index: 999;

    .new-point {
      position: absolute;
      @include flex($direction: column);
      padding: 2rem;
      border-radius: 1rem;
      color: #fff;
      background-color: rgba(0,0,0,.75);
      border: 1px solid rgba(255,255,255,.25);
      z-index: 9999;
    }
  }

  .buttons {
    @include flex($justify: center);

    > *:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  .inputs {
    width: 240px;
    margin: 2rem 0;

    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

</style>
