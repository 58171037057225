<template>
  <div class="element-link">

    <Icon v-if="elementIcon" :name="elementIcon" />

    <div v-if="isLoading" class="loading-link-text">
      <TextLoader />
    </div>

    <router-link
      v-else
      :to="`/${element}/${id}`"
    >{{ elementName }}</router-link>

  </div>
</template>


<script>
  export default {
    name: 'ElementLink',

    props: {
      element: String,
      id: String
    },

    computed: {
      elementIcon() {
        switch (this.element) {
          case 'writing':
            return 'book'
          case 'wiki':
            return 'wizard'
          case 'timeline':
            return 'time'
          case 'map':
            return 'map'
          case 'language':
            return ''
          default:
            return ''
        }
      }
    },

    data() {
      return {
        isLoading: true,
        elementName: ''
      }
    },

    async created() {
      this.isLoading = true
      const response = await this.$store.dispatch('fetchElementLink', {
        element: this.element,
        id: this.id
      })
      if (response?.status == 200) {
        this.$set(this, 'elementName', response.data?.title)
      }
      this.isLoading = false
    }
  }
</script>


<style scoped lang="scss">

  .element-link {
    @include flex();
    color: $colorB;
    flex-wrap: nowrap;
    white-space: pre;
    user-select: none;

    .icon {
      margin-right: 0.5rem;
    }

    a {
      color: inherit;
      // font-family: 'Cinzel Decorative', sans-serif;
      font-weight: 600;
    }

    &:hover {
      color: $colorA;
    }
  }

  .loading-link-text {
    width: 80px;
    font-size: 1rem;
  }

</style>
