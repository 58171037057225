<template>
  <div v-if="$store.state.user" class="new-post">

    <textarea
      @input="inputChanged"
      @focus="inputChanged"
    >{{ editingText }}</textarea>
    <DraftSaved :lastInput="lastInput" />

    <div class="spacer h1"></div>

    <div class="attachments-container column">
      <p class="attachment-label">Attach A Creation</p>
      <div class="spacer h1"></div>

      <div class="attachment-list">
        <div v-for="attachment in attachments" class="attachment row">
          <Icon :name="formatAttachmentIcon(attachment.type)" />
          <p>{{ attachment.title }}</p>
          <Button
            icon="x"
            iconSize="0.75rem"
            color="#c73f50"
            @click.native="removeAttachment(attachment._id)" />
        </div>

        <div v-if="attachments" class="spacer h1"></div>

        <div class="row spaced">
          <Button
            label="Select Element"
            @click.native="selectElementVisible = true"
          />
          <Button
            label="Writing"
            icon="book"
            iconSize="1.25rem"
            @click.native="openWritings" />
          <Button
            label="Wiki"
            icon="books"
            iconSize="1.25rem"
            @click.native="openWikis" />
          <Button
            label="Timeline"
            icon="time"
            iconSize="1.25rem"
            @click.native="openTimelines" />
          <Button
            label="Map"
            icon="map"
            iconSize="1.25rem"
            @click.native="openMaps" />
        </div>
      </div>
    </div>

    <div class="spacer h1"></div>

    <div class="row spaced">
      <Button label="Post" @click.native="submitNewPost" />
      <Button label="Cancel" @click.native="$emit('close')" />
      <Button label="Clear" @click.native="openModal" />
    </div>

    <SelectElement
      v-if="selectElementVisible"
      @close="() => selectElementVisible = false"
      @select="(element) => { selectAttachment('writing', element) }"
    />

    <SelectCreationElement
      v-if="writingCollectionVisible"
      header="Select Writing"
      icon="book"
      @close="() => writingCollectionVisible = false"
    >
      <SelectWritingItem
        v-for="writing in fromStore('writings')"
        :data="writing"
        @select="(writing) => { selectAttachment('writing', writing) }" />
    </SelectCreationElement>

    <SelectCreationElement
      v-if="wikiCollectionVisible"
      header="Select Wiki"
      icon="books"
      @close="() => wikiCollectionVisible = false"
    >
      <div class="creation-list">
        <div v-for="wiki in fromStore('wikis')"
          class="creation-item"
          @click="selectAttachment('wiki', wiki)"
        >
          <Icon :name="formatWikiIcon(wiki.category)" />
          <p>{{ wiki.title }}</p>
        </div>
      </div>
    </SelectCreationElement>

    <SelectCreationElement
      v-if="timelineCollectionVisible"
      header="Select Timeline"
      icon="time"
      @close="() => timelineCollectionVisible = false"
    >
      <div class="creation-list">
        <div v-for="timeline in fromStore('timelines')"
          class="creation-item"
          @click="selectAttachment('timeline', timeline)"
        >
          <p>{{ timeline.title }}</p>
        </div>
      </div>
    </SelectCreationElement>

    <SelectCreationElement
      v-if="mapCollectionVisible"
      header="Select Map"
      icon="map"
      @close="() => mapCollectionVisible = false"
    >
      <div class="creation-list">
        <div v-for="map in fromStore('maps')"
          class="creation-item"
          @click="selectAttachment('map', map)"
        >
          <p>{{ map.title }}</p>
        </div>
      </div>
    </SelectCreationElement>

    <Alert :alert="alert" @dismiss="alert = null" />

    <Modal v-if="modalOpen"
      @modal-confirm="clearPostProgress"
      @modal-cancel="closeModal" />

  </div>
</template>


<script>
  import SelectWritingItem from './SelectWritingItem.vue';
  import SelectCreationElement from './SelectCreationElement.vue';
  import SelectElement from './SelectElement.vue';

  export default {
    name: 'NewPost',
    components: {
      SelectWritingItem,
      SelectCreationElement,
      SelectElement,
    },
    props: {
      type: String
    },
    data() {
      return {
        editingText: '',
        feedbackOption: 1,
        postType: 'text',
        attachments: [],

        lastInput: null,
        alert: null,

        modalOpen: false,
        selectElementVisible: false,
        writingCollectionVisible: false,
        wikiCollectionVisible: false,
        timelineCollectionVisible: false,
        mapCollectionVisible: false,
      }
    },

    methods: {

      fromStore(collection) {
        if (!this.$store.state.world?.[collection]) return;
        return Object.values(this.$store.state.world?.[collection]);
      },

      openWritings() {
        // this.$store.dispatch('fetchWritings');
        this.writingCollectionVisible = true;
      },
      openWikis() {
        // this.$store.dispatch('fetchWikis');
        this.wikiCollectionVisible = true;
      },
      openTimelines() {
        // this.$store.dispatch('fetchMyTimelines');
        this.timelineCollectionVisible = true;
      },
      openMaps() {
        // this.$store.dispatch('fetchMaps');
        this.mapCollectionVisible = true;
      },

      selectAttachment(type, attachment) {
        this.attachments.push({
          _id: attachment._id,
          type: type,
          title: attachment.title || attachment.name
        });
        this[type + 'CollectionVisible'] = false;
      },

      formatAttachmentIcon(type) {
        if (type === 'writing') return 'book';
        else if (type === 'wiki') return 'books';
        else if (type === 'timeline') return 'time';
        else if (type === 'map') return 'map';
        else return '';
      },

      formatWikiIcon(category) {
        if (category === 'characters') return 'witch hat';
        else if (category === 'places') return 'mountain';
        else if (category === 'magic') return 'magic';
        else if (category === 'events') return 'stopwatch';
        else return '';
      },

      removeAttachment(id) {
        this.attachments = this.attachments.filter(attachment => attachment._id != id);
      },

      inputChanged() {
        this.editingText = event.target.value;
        window.localStorage.setItem('new-post-editing', event.target.value);

        let height = window.getComputedStyle(event.target).height;
        if (event.target.scrollHeight >= parseInt(height)) {
          event.target.style.height = event.target.scrollHeight + 'px';
        }
        else {
          event.target.style.height = '80px';
        }
        this.lastInput = Date.now();
      },

      clearPostProgress() {
        window.localStorage.removeItem('new-post-editing');
        this.editingText = '';
        this.closeModal();
      },

      submitNewPost() {
        if (!this.editingText.trim()) {
          this.alert = {
            type: 'error',
            message: 'No post content.'
          }
          return;
        }

        let actionName = 'submitNewPost';
        let params = {
          authorID: this.$store.state.user._id,
          type: this.selectedPostType,
          feedback: this.feedbackOption,
          content: this.editingText,
          links: this.attachments
        };

        if (this.type === 'forum') {
          actionName = 'submitNewForumPost'
          params = {
            userID: this.$store.state.user._id,
            postType: 'text',
            content: this.editingText
          };
        }
        this.$store.dispatch(actionName, params)
          .then(response => {
            console.log("response:", response);
            if (response.status === 201) {
              this.clearPostProgress();
              this.$emit('success');
            }
            else {
              this.alert = {
                type: 'error',
                message: response.data
              }
            }
          });
      },

      openModal() {
        this.modalOpen = true;
      },

      closeModal() {
        this.modalOpen = false;
      }
    },

    created() {
      this.editingText = window.localStorage.getItem('new-post-editing') || '';
    }
  }
</script>


<style scoped lang="scss">

  .new-post {
    @include flex($direction: column);
    max-width: 600px;
    margin: 1rem auto;

    textarea {
      height: 80px;
      max-height: 300px;
    }

    > *:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .attachments-container {
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #16171a;
  }

  .attachment-label {
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    color: $colorB;
  }

  .attachment {
    padding: 0.5rem;

    .icon {
      color: $colorA;
    }

    p {
      margin-right: auto;
      color: $colorA;
      font-size: 0.9rem;
      font-weight: 600;
    }

    &:not(:first-child) {
      border-top: 1px solid #33363c;
    }
  }

  .creation-list {
    width: 100%;
  }

  .creation-item {
    @include flex();
    width: 100%;
    padding: 0.75rem 1.5rem;

    &:not(:last-child) {
      border-bottom: 1px solid #333;
    }

    .icon {
      margin-right: 1rem;
    }

    p, .icon {
      color: #fff;
    }

    &:hover {
      cursor: pointer;
      background-color: #212225;

      p, .icon {
        color: $colorA;
      }
    }
  }

</style>
