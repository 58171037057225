<template>
  <div v-if="visible && isCurrentStep"
    class="tutorial-hint floating"
    :style="formatTransform"
  >
    <div class="hint-triangle" :style="triangleTransform"></div>
    <div style="margin-right: 0.75rem;">
      <slot></slot>
    </div>
    <Button
      label="OK"
      @click.native="clickOK()"
      style="margin-left: auto;"
    />
  </div>
</template>

<script>
  export default {
    name: 'TutorialHint',
    props: {
      step: Number,
      origin: [String]
    },

    data() {
      return {
        visible: true
      }
    },

    computed: {
      isCurrentStep() {
        if (this.step == -1) return true
        return this.$store.state.user?.tutorial == this.step - 1
      },

      formatTransform() {
        const origin = this.origin || 'center bottom'

        let top = 'unset'
        let bottom = 'unset'
        let left = 'unset'
        let right = 'unset'
        let translateX = '0'
        let translateY = '0'

        if (origin.includes('center')) {
          top = '50%';
          left = '50%';
          translateX = '-50%';
          translateY = '-50%';
        }

        if (origin.includes('top')) {
          bottom = 'unset';
          top = '-1.5rem';
          translateY = '-100%';
        }
        if (origin.includes('bottom')) {
          top = 'unset';
          bottom = '-1.5rem';
          translateY = '100%';
        }
        if (origin.includes('left')) {
          right = 'unset';
          left = '-1.5rem';
          translateX = '-100%';
        }
        if (origin.includes('right')) {
          left = 'unset';
          right = '-1.5rem';
          translateX = '100%';
        }

        if ((origin.includes('top') || origin.includes('bottom')) && origin.includes('left')) {
          left = '1rem';
          translateX = '0';
        }
        if ((origin.includes('top') || origin.includes('bottom')) && origin.includes('right')) {
          right = '1rem';
          translateX = '0';
        }

        let string = `top: ${top}; bottom: ${bottom}; left: ${left}; right: ${right}; transform: translate(${translateX}, ${translateY});`
        return string
      },


      triangleTransform() {
        const origin = this.origin || 'center bottom'

        let top = 'unset'
        let bottom = 'unset'
        let left = 'unset'
        let right = 'unset'
        let translateX = '0'
        let translateY = '0'
        let rotate = '0'

        if (origin.includes('center')) {
          top = '50%';
          left = '50%';
          translateX = '-50%';
          translateY = '-50%';
        }

        if (origin.includes('left')) {
          left = 'unset';
          right = '-4px';
          translateX = '100%';
          rotate = '90';
        }
        if (origin.includes('right')) {
          right = 'unset';
          left = '-4px';
          translateX = '-100%';
          rotate = '270';
        }
        if (origin.includes('top')) {
          top = 'unset';
          bottom = '1px';
          translateY = '100%';
          rotate = '180';
        }
        if (origin.includes('bottom')) {
          bottom = 'unset';
          top = '1px';
          translateY = '-100%';
          rotate = '0';
        }

        if ((origin.includes('top') || origin.includes('bottom')) && origin.includes('left')) {
          right = 'unset';
          left = '1rem';
          translateX = '0';
        }
        if ((origin.includes('top') || origin.includes('bottom')) && origin.includes('right')) {
          left = 'unset';
          right = '1rem';
          translateX = '0';
        }

        let string = `top: ${top}; bottom: ${bottom}; left: ${left}; right: ${right}; transform: translate(${translateX}, ${translateY}) rotate(${rotate}deg);`
        return string
      }
    },

    methods: {
      clickOK() {
        console.log('ok');
        this.$store.dispatch('stepTutorial', this.step);
        this.visible = false;
      }
    }
  }
</script>

<style scoped lang="scss">

  .tutorial-hint-container {
    width: 100%;
    height: 0px;
  }

  .tutorial-hint {
    position: absolute;
    @include flex();
    width: 400px;
    padding: 1.5rem;
    border-radius: 0.25rem;
    background-color: #017c5b;
    text-align: left;
    opacity: 1;
    transform: unset;
    transition: all 0.3s;
    z-index: 99;

    .title {
      color: $colorD;
      text-align: center;
    }

    ul, ul li {
      list-style-position: inside;
      margin: 0;
      padding: 0;
    }

    p, ul, ul li {
      font-size: 0.9rem;
    }
  }

  .hint-triangle {
    position: absolute;
    border: 10px solid transparent;
    border-bottom: 20px solid #017c5b;
  }

</style>
