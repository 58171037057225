<template>
  <div class="page">
    <div class="spacer h3"></div>


    <h1
      class="colorful-title glow subscribe-title"
      data-glow-text="Subscribe"
    >{{ hasOwnedProducts ? 'Edit Subscription' : 'Subscribe' }}</h1>



    <template v-if="Object.keys(ownedProducts).length">
      <div class="spacer"></div>
      <div class="total floating">
        <div class="cart-items">

          <h3>Current Subscription</h3>

          <div v-for="product in Object.keys(ownedProducts)"
            class="item"
          >
            <p>{{ product }}</p>
            <div class="price">
              <span class="currency-sign">$</span>
              <span class="primary">{{ displayPrice(currentSubscriptionPrices[product]).primary }}</span>
              <span class="secondary">{{ displayPrice(currentSubscriptionPrices[product]).secondary }}</span>
            </div>
          </div>
        </div>

        <div class="horizontal-line">
          <h5>Total</h5>
        </div>

        <div class="price-container">
          <div class="price">
            <span class="currency-sign">$</span>
            <span class="primary">{{ displayPrice(currentSubscriptionTotal).primary }}</span>
            <span class="secondary">{{ displayPrice(currentSubscriptionTotal).secondary }}</span>
          </div>
          <div class="divider"></div>
          <p class="unit">month</p>
        </div>

      </div>
    </template>



    <template v-if="hasOwnedProducts && !Object.keys(selectedProducts).length">
      <div class="spacer"></div>
      <div class="total floating">
        <div class="cart-items">
          <h6>Select any of the items below.</h6>
        </div>

        <div class="horizontal-line">
          <h5>Or</h5>
        </div>
        <div class="spacer h1"></div>

        <CoolButton
          type="compact"
          label="Cancel Subscription"
          iconAfter="right-arrow"
          iconSize="12px"
          animation="infinite"
          textColor="#ff8c8c"
          bgColor="rgb(255,88,88)"
          @click.native="$router.push('/dashboard/manage-subscription')"
        />
      </div>
    </template>



    <template v-if="Object.keys(selectedProducts).length && (removedProducts.length || addedProducts.length)">
      <div class="spacer"></div>
      <div class="total floating">
        <div class="cart-items">

          <h3>New Subscription</h3>

          <div v-for="product in removedProducts"
            class="item remove"
          >
            <p>- {{ product }}</p>
            <div class="price">
              <span class="currency-sign">$</span>
              <span class="primary">{{ displayPrice(0).primary }}</span>
              <span class="secondary">{{ displayPrice(0).secondary }}</span>
            </div>
          </div>

          <div v-for="product in addedProducts"
            class="item add"
          >
            <p>+ {{ product }}</p>
            <div class="price">
              <span class="currency-sign">$</span>
              <span class="primary">{{ displayPrice(basePrices[product]).primary }}</span>
              <span class="secondary">{{ displayPrice(basePrices[product]).secondary }}</span>
            </div>
          </div>


          <template v-if="total != subtotal">
            <div class="spacer"></div>
            <div class="item">
              <p>Subtotal</p>
              <div class="price">
                <span class="currency-sign">$</span>
                <span class="primary">{{ displayPrice(subtotal).primary }}</span>
                <span class="secondary">{{ displayPrice(subtotal).secondary }}</span>
              </div>
            </div>

            <div class="item discount">
              <p>{{ productTier(selectedProducts) }}</p>
              <div class="price">
                <span class="currency-sign">$</span>
                <span class="primary">{{ displayPrice(total - subtotal).primary }}</span>
                <span class="secondary">{{ displayPrice(total - subtotal).secondary }}</span>
              </div>
            </div>
          </template>

        </div>



        <div class="horizontal-line">
          <h5 v-if="Object.keys(ownedProducts).length">Starting Next Month</h5>
          <h5 v-else>Total</h5>
        </div>

        <div class="price-container">
          <div class="price">
            <span class="currency-sign">$</span>
            <span class="primary">{{ displayPrice(total).primary }}</span>
            <span class="secondary">{{ displayPrice(total).secondary }}</span>
          </div>
          <div class="divider"></div>
          <p class="unit">month</p>
        </div>


        <template v-if="Object.keys(ownedProducts).length && !dueNow">
          <div class="spacer"></div>
          <CoolButton
            type="compact"
            label="Confirm"
            iconAfter="right-arrow"
            iconSize="12px"
            animation="infinite"
            textColor="#78ffc6"
            bgColor="rgb(48,215,140)"
            @click.native="confirmDowngradeModalVisible = true"
          />
        </template>

        <template v-if="!Object.keys(ownedProducts).length">
          <div class="spacer"></div>
          <CoolButton
            type="compact"
            label="Checkout"
            iconAfter="right-arrow"
            iconSize="12px"
            animation="infinite"
            textColor="#78ffc6"
            bgColor="rgb(48,215,140)"
            @click.native="goToCheckout"
          />
        </template>

      </div>
    </template>



    <template v-if="prorationDetails.length">
      <div class="spacer"></div>
      <div class="total floating">
        <div class="cart-items">
          <h3>Difference</h3>

          <div v-for="line in prorationDetails"
            class="item"
          >
            <p>{{ line.description }}</p>
            <!-- <h6>{{ formatDate(line.period.start * 1000, '&mon &dd, &year') }} - {{ formatDate(line.period.end * 1000, '&mon &dd, &year') }}</h6> -->
            <div class="price">
              <span class="currency-sign">$</span>
              <span class="primary">{{ displayPrice(line.amount / 100).primary }}</span>
              <span class="secondary">{{ displayPrice(line.amount / 100).secondary }}</span>
            </div>
          </div>
        </div>
        <div class="spacer"></div>


        <div class="horizontal-line">
          <h5>Due Now</h5>
        </div>

        <div class="price-container due-now">
          <div class="price">
            <span class="currency-sign">$</span>
            <span class="primary">{{ displayPrice(dueNow).primary }}</span>
            <span class="secondary">{{ displayPrice(dueNow).secondary }}</span>
          </div>
        </div>


        <template v-if="dueNow > 0">
          <div class="spacer"></div>
          <CoolButton
            type="compact"
            label="Confirm"
            iconAfter="right-arrow"
            iconSize="12px"
            animation="infinite"
            textColor="#78ffc6"
            bgColor="rgb(48,215,140)"
            @click.native="confirmUpgradeModalVisible = true"
          />
        </template>
      </div>
    </template>



    <template v-if="!removedProducts.length && !addedProducts.length">
      <div class="spacer"></div>
      <div class="total floating">
        <div class="cart-items">
          <h6>Select any of the items below.</h6>
        </div>
      </div>
    </template>



    <ModalContainer v-if="confirmUpgradeModalVisible">
      <div class="total floating">
        <div class="cart-items">
          <h3>Upgrade Details</h3>

          <div v-for="line in prorationDetails"
            class="item"
          >
            <p>{{ line.description }}</p>
            <!-- <h6>{{ formatDate(line.period.start * 1000, '&mon &dd, &year') }} - {{ formatDate(line.period.end * 1000, '&mon &dd, &year') }}</h6> -->
            <div class="price">
              <span class="currency-sign">$</span>
              <span class="primary">{{ displayPrice(line.amount / 100).primary }}</span>
              <span class="secondary">{{ displayPrice(line.amount / 100).secondary }}</span>
            </div>
          </div>
        </div>
        <div class="spacer"></div>

        <div class="horizontal-line">
          <h5>Due Now</h5>
        </div>

        <div class="price-container due-now">
          <div class="price">
            <span class="currency-sign">$</span>
            <span class="primary">{{ displayPrice(dueNow).primary }}</span>
            <span class="secondary">{{ displayPrice(dueNow).secondary }}</span>
          </div>
        </div>
      </div>


      <div v-if="loading" class="spacer h4">
        <Loader size="60px" />
      </div>

      <template v-else>
        <p>You will be charged this amount now. Is this ok?</p>
        <div class="spacer"></div>
        <div class="row center spaced">
          <Button
            label="Close"
            @click.native="confirmUpgradeModalVisible = false"
          />
          <Button
            label="Confirm Upgrade"
            class="primary"
            @click.native="updateSubscription('upgrade')"
          />
        </div>
      </template>

    </ModalContainer>



    <ModalContainer v-if="confirmDowngradeModalVisible">
      <div class="total floating">
        <div class="cart-items">

          <h3>New Subscription</h3>

          <div v-for="product in Object.keys(selectedProducts)"
            class="item"
          >
            <p>{{ product }}</p>
            <div class="price">
              <span class="currency-sign">$</span>
              <span class="primary">{{ displayPrice(basePrices[product]).primary }}</span>
              <span class="secondary">{{ displayPrice(basePrices[product]).secondary }}</span>
            </div>
          </div>

          <div v-for="product in removedProducts"
            class="item remove"
          >
            <p>- {{ product }}</p>
            <div class="price">
              <span class="currency-sign">$</span>
              <span class="primary">{{ displayPrice(0).primary }}</span>
              <span class="secondary">{{ displayPrice(0).secondary }}</span>
            </div>
          </div>

          <template v-if="total != subtotal">
            <div class="spacer"></div>
            <div class="item">
              <p>Subtotal</p>
              <div class="price">
                <span class="currency-sign">$</span>
                <span class="primary">{{ displayPrice(subtotal).primary }}</span>
                <span class="secondary">{{ displayPrice(subtotal).secondary }}</span>
              </div>
            </div>

            <div class="item discount">
              <p>{{ productTier(selectedProducts) }}</p>
              <div class="price">
                <span class="currency-sign">$</span>
                <span class="primary">{{ displayPrice(total - subtotal).primary }}</span>
                <span class="secondary">{{ displayPrice(total - subtotal).secondary }}</span>
              </div>
            </div>
          </template>


          <template v-if="total != subtotal">
            <div class="spacer"></div>
            <div class="item">
              <p>Subtotal</p>
              <div class="price">
                <span class="currency-sign">$</span>
                <span class="primary">{{ displayPrice(subtotal).primary }}</span>
                <span class="secondary">{{ displayPrice(subtotal).secondary }}</span>
              </div>
            </div>

            <div class="item discount">
              <p>{{ productTier(selectedProducts) }}</p>
              <div class="price">
                <span class="currency-sign">$</span>
                <span class="primary">{{ displayPrice(total - subtotal).primary }}</span>
                <span class="secondary">{{ displayPrice(total - subtotal).secondary }}</span>
              </div>
            </div>
          </template>

        </div>



        <div class="horizontal-line">
          <h5 v-if="Object.keys(ownedProducts).length">Starting Next Month</h5>
          <h5 v-else>Total</h5>
        </div>

        <div class="price-container">
          <div class="price">
            <span class="currency-sign">$</span>
            <span class="primary">{{ displayPrice(total).primary }}</span>
            <span class="secondary">{{ displayPrice(total).secondary }}</span>
          </div>
          <div class="divider"></div>
          <p class="unit">month</p>
        </div>
      </div>


      <div v-if="loading" class="spacer h4">
        <Loader size="60px" />
      </div>

      <template v-else>
        <p>You will not be charged now. Is this ok?</p>
        <div class="spacer"></div>
        <div class="row center spaced">
          <Button
            label="Close"
            @click.native="confirmDowngradeModalVisible = false"
          />
          <Button
            label="Confirm Downgrade"
            class="primary"
            @click.native="updateSubscription('downgrade')"
          />
        </div>
      </template>

    </ModalContainer>





    <div class="spacer"></div>
    <Alert :alert="alert" @dismiss="alert = null" />
    <div class="spacer h3"></div>






    <!-- <div class="spacer"></div>
    <p style="opacity: 0.5;">Select any of the items below.</p>
    <div class="spacer"></div> -->

    <div class="subscription-container">


      <!-- WRITINGS -->
      <div :class="`subscription-item floating ${hasProduct('writings') ? 'owned' : ''} ${selectedProducts['writings'] ? 'selected' : ''}`"
        @click.bubble="selectProduct('writings')"
      >
        <div class="bg-container">
          <div class="texture-bg fantasy"></div>
        </div>

        <div class="card-image writings"></div>

        <template v-if="selectedProducts['writings']">
          <div class="border-glow"></div>
          <div class="checkmark">
            <Icon name="check" />
          </div>
        </template>

        <template v-if="hasProduct('writings')">
          <div class="checkmark text">
            <Icon name="check" />
            <h6>Owned</h6>
          </div>
        </template>

        <img src="https://media.scrybe-app.com/assets/elements/writing_icon.png" />

        <h1 class="">Writings</h1>
        <p class="description">Immerse yourself in your writing and create an immersive reading experience for your readers.</p>
        <div style="margin: auto;"></div>

        <div class="price-container">
          <p class="unit free">FREE</p>
        </div>
      </div>



      <!-- WIKIS -->
      <div :class="`subscription-item floating ${hasProduct('wikis') ? 'owned' : ''} ${selectedProducts['wikis'] ? 'selected' : ''}`"
        @click.bubble="selectProduct('wikis')"
      >
        <div class="bg-container">
          <div class="texture-bg fantasy"></div>
        </div>

        <div class="card-image wikis"></div>

        <template v-if="selectedProducts['wikis']">
          <div class="border-glow"></div>
          <div class="checkmark">
            <Icon name="check" />
          </div>
        </template>

        <template v-if="hasProduct('wikis')">
          <div class="checkmark text">
            <Icon name="check" />
            <h6>Owned</h6>
          </div>
        </template>

        <img src="https://media.scrybe-app.com/assets/elements/wiki_icon.png" />

        <h1 class="">Wiki</h1>
        <p class="description">Organize your own worldbuilding library by categorizing information about your world and linking it across the site.</p>
        <div style="margin: auto;"></div>

        <div class="price-container">
          <p class="unit free">FREE</p>
        </div>
      </div>



      <!-- MAPS -->
      <div :class="`subscription-item floating ${selectedProducts['maps'] ? 'selected' : ''}`"
        @click.bubble="selectProduct('maps')"
      >
        <div class="bg-container">
          <div class="texture-bg fantasy"></div>
        </div>

        <div class="card-image maps"></div>

        <template v-if="selectedProducts['maps']">
          <div class="border-glow"></div>
          <div v-if="!ownedProducts['maps']" class="checkmark">
            <Icon name="check" />
          </div>
        </template>

        <template v-if="hasProduct('maps')">
          <div class="checkmark text">
            <Icon name="check" />
            <h6>Owned</h6>
          </div>
        </template>

        <img src="https://media.scrybe-app.com/assets/elements/map_icon.png" />

        <h1 class="">Maps</h1>
        <p class="description">Create interactive maps using your own images or generate a map in seconds to kick start your ideas.</p>
        <div style="margin: auto;"></div>

        <div class="price-container">
          <div class="price">
            <span class="currency-sign">$</span>
            <span class="primary">{{ basePrices.maps }}</span>
          </div>
          <div class="divider"></div>
          <p class="unit">month</p>
        </div>
      </div>



      <!-- TIMELINES -->
      <div :class="`timelines-card subscription-item floating ${selectedProducts['timelines'] ? 'selected' : ''}`"
        @click.bubble="selectProduct('timelines')"
      >
        <div class="bg-container">
          <div class="texture-bg fantasy"></div>
        </div>

        <div class="card-image timelines"></div>

        <template v-if="selectedProducts['timelines']">
          <div class="border-glow"></div>
          <div v-if="!ownedProducts['timelines']" class="checkmark">
            <Icon name="check" />
          </div>
        </template>

        <template v-if="hasProduct('timelines')">
          <div class="checkmark text">
            <Icon name="check" />
            <h6>Owned</h6>
          </div>
        </template>

        <img src="https://media.scrybe-app.com/assets/elements/timeline_icon.png" />

        <h1 class="">Timelines</h1>
        <p class="description">Keep a bird's eye view on your story by creating interactive timelines that use your own calendar and dating systems.</p>
        <div style="margin: auto;"></div>

        <div class="price-container">
          <div class="price">
            <span class="currency-sign">$</span>
            <span class="primary">{{ basePrices.timelines }}</span>
          </div>
          <div class="divider"></div>
          <p class="unit">month</p>
        </div>
      </div>



      <!-- LANGUAGES -->
      <div :class="`subscription-item floating owned ${selectedProducts['languages'] ? 'selected' : ''}`"
      >
        <div class="bg-container">
          <div class="texture-bg fantasy"></div>
        </div>

        <div class="card-image languages"></div>

        <template v-if="selectedProducts['languages']">
          <div class="border-glow"></div>
          <div class="checkmark">
            <Icon name="check" />
          </div>
        </template>

        <template v-if="hasProduct('languages')">
          <div class="checkmark text">
            <Icon name="check" />
            <h6>Owned</h6>
          </div>
        </template>

        <img src="https://media.scrybe-app.com/assets/elements/empty_icon.png" />

        <h1 class="">Languages</h1>
        <p class="description">Brainstorm ideas for your fictional languages and construct a cohesive system piece by piece.</p>
        <div style="margin: auto;"></div>

        <div class="price-container">
          <div class="coming-soon">
            <!-- <Icon name="stopwatch" size="20px" /> -->
            <h5>Coming Soon</h5>
          </div>
        </div>
      </div>

    </div>

    <div class="spacer h5"></div>

  </div>
</template>

<script>
  export default {
    name: 'Subscribe',

    data() {
      return {
        alert: null,
        ownedProducts: {},
        selectedProducts: {},
        prices: {},
        prorationPreview: {},
        confirmUpgradeModalVisible: false,
        confirmDowngradeModalVisible: false,
        loading: false
      }
    },

    computed: {
      user() {
        return this.$store.state.user;
      },

      permissions() {
        return this.user?.permissions || {}
      },

      basePrices() {
        const prices = this.prices?.['1 Element'] || {}

        let basePrices = {}
        Object.keys(prices).forEach(product => {
          basePrices[product] = (prices[product]?.unit_amount || 0) / 100
        })
        return basePrices
      },

      removedProducts() {
        const products = Object.keys(this.ownedProducts)
        if (!products?.length) return []
        return products.filter(product => !this.selectedProducts[product])
      },

      addedProducts() {
        const products = Object.keys(this.selectedProducts)
        if (!products?.length) return []
        return products.filter(product => !this.ownedProducts[product])
      },

      prorationDetails() {
        if (!this.prorationPreview?.lines?.length) return []
        return this.prorationPreview.lines
      },

      subtotal() {
        return Object.keys(this.selectedProducts).reduce((total, product) => {
          const price = this.basePrices[product] || 0
          return total + price
        }, 0)
      },

      total() {
        return Object.keys(this.selectedProducts).reduce((total, product) => {
          const price = this.prices?.[this.productTier(this.selectedProducts)]?.[product]?.unit_amount
          return total + (price ? price / 100 : 0)
        }, 0)
      },

      currentSubscriptionPrices() {
        const prices = this.prices?.[this.productTier(this.ownedProducts)] || {}

        let currentPrices = {}
        Object.keys(this.ownedProducts).forEach(product => {
          currentPrices[product] = (prices[product]?.unit_amount || 0) / 100
        })
        return currentPrices
      },

      currentSubscriptionTotal() {
        return Object.values(this.currentSubscriptionPrices).reduce((total, price) => total + price, 0)
      },

      hasOwnedProducts() {
        return !!Object.keys(this.ownedProducts)?.length
      },

      isUpgrade() {
        const difference = this.total - this.currentSubscriptionTotal
        if (this.currentSubscriptionTotal && difference > 0) {
          return true
        }
        return false
      },

      dueNow() {
        let amount = (this.prorationPreview?.amount_due || 0) / 100
        if (amount < 0) amount = 0
        return amount
      }
    },

    methods: {
      hasProduct(product) {
        if (product == 'writings' || product == 'wikis') return true
        return !!this.ownedProducts[product]
      },

      selectProduct(product) {
        if (product == 'writings' || product == 'wikis' || product == 'languages') return;

        if (!this.selectedProducts[product]) {
          this.$set(this.selectedProducts, product, true)
        }
        else {
          this.$delete(this.selectedProducts, product)
        }

        if (this.isUpgrade) {
          this.$store.dispatch('previewProration', {
            new_items: Object.keys(this.selectedProducts),
            proration_date: Math.floor(Date.now() / 1000)
          }).then(response => {
            console.log(JSON.parse(JSON.stringify(response)));
            if (response?.status == 200) {
              let data = response?.data?.invoice
              data.lines = response?.data?.invoice?.lines?.data
              this.prorationPreview = data
            }
          })
        }
        else {
          this.prorationPreview = {}
        }
      },

      productTier(products) {
        switch (Object.keys(products)?.length) {
          case 3:
            return '3 Elements'
          case 2:
            return '2 Elements'
          default:
            return '1 Element'
        }
      },

      displayPrice(n) {
        if (isNaN(n)) {
          return {
            primary: '--',
            secondary: ''
          }
        }

        const string = n.toString().split('.')
        let decimal = string[1] || 0
        if (parseInt(decimal) < 10) {
          decimal = '0' + decimal
        }

        return {
          primary: string[0],
          secondary: '.' + decimal
        }
      },

      goToCheckout() {
        let selectedProductsArray = Object.values(this.selectedProducts).filter(bool => bool == true);
        if (!selectedProductsArray?.length) {
          this.alert = {
            type: 'warning',
            message: 'No products selected.'
          }
          return;
        }

        let items = [];
        for (let product in this.selectedProducts) {
          if (this.selectedProducts[product]) {
            items.push(product)
          }
        }

        this.$store.dispatch('goToCheckout', items)
      },

      updateSubscription(type) {
        this.loading = true
        this.$store.dispatch('updateSubscription', {
          type,
          new_items: Object.keys(this.selectedProducts),
          proration_date: Math.floor(Date.now() / 1000)
        }).then(response => {
          console.log(JSON.parse(JSON.stringify(response)));
          if (response?.status == 200) {
            this.alert = {
              type: 'success',
              message: 'Subscription updated successfully!'
            }
          }
          else {
            this.alert = {
              type: 'error',
              message: 'Problem updating subscription.'
            }
          }
          this.confirmUpgradeModalVisible = false
          this.confirmDowngradeModalVisible = false
        })
      }
    },

    created() {
      this.$store.dispatch('fetchPrices').then(response => {
        if (response.status == 200) {
          this.prices = response.data?.prices
        }
      })

      Object.keys(this.permissions).forEach(product => {
        if (product != 'languages') {
          this.$set(this.ownedProducts, product, true)
          this.$set(this.selectedProducts, product, true)
        }
      })
    },

    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (!vm.$store.state.user) {
          vm.$router.push('/login')
        }
      })
    }
  }
</script>

<style scoped lang="scss">

  .subscribe-title {
    letter-spacing: 1px;
  }

  .subscription-container {
    @include flex($justify: center);
    flex-wrap: wrap;
  }

  .bg-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0.5rem;
    z-index: -1;
    opacity: 0.2;
    transition: all 0.15s;

    .texture-bg {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    z-index: -2;
    // opacity: 0.5;

    &.writings {
      background-image: url('https://media.scrybe-app.com/assets/elements/writings_card.png');
    }
    &.wikis {
      background-image: url('https://media.scrybe-app.com/assets/elements/wikis_card.png');
    }
    &.maps {
      background-image: url('https://media.scrybe-app.com/assets/elements/maps_card.png');
    }
    &.timelines {
      background-image: url('https://media.scrybe-app.com/assets/elements/timelines_card.png');
    }
    &.languages {
      background-image: url('https://media.scrybe-app.com/assets/elements/languages_card.png');
    }
  }

  .subscription-item {
    position: relative;
    @include flex($direction: column);
    width: 320px;
    height: 380px;
    margin: 2rem;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: bg(20%);
    user-select: none;
    transition: all 0.15s;
    z-index: 2;
    overflow: hidden;

    img {
      width: 90px;
      height: 90px;
    }

    h1 {
      margin: 1rem 0;
      font-size: 1.35rem;
    }

    .description {
      color: neutral(85%);
      font-size: 0.85rem;
      line-height: 1.25em;
      text-align: center;
    }

    .card-image {
      transition: filter 0.3s;
    }

    &.owned {
      img, h1, .description {
        opacity: 0.35;
      }
      .bg-container {
        opacity: 0.1;
      }
    }

    &:hover:not(.owned) {
      cursor: pointer;
      transform: scale(1.05);
      box-shadow: 0 40px 60px -50px rgba(0,0,0,.5), 0 40px 60px -50px rgba(0,0,0,1);

      .card-image {
        filter: brightness(1.5) saturate(1.1);
      }
    }
  }

  .border-glow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    border: 1px solid $colorA;
    box-shadow: 0 0 16px rgba($colorA, 0.5);
  }

  .checkmark {
    position: absolute;
    top: 2rem;
    right: 2rem;
    color: $colorA;
  }

  .text {
    @include flex();

    h6 {
      margin-left: 6px;
      color: $colorA;
    }
  }

  .coming-soon {
    @include flex();
    color: #fff;
    font-family: 'Cinzel Decorative', Avenir;
    opacity: 0.5;

    h5 {
      margin-left: 6px;
      font-size: 1rem;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  .price-container {
    @include flex($justify: center, $align: flex-end);
    height: 44px;

    &.due-now {
      .currency-sign, .primary, .secondary, .unit {
        color: $colorA;
      }
    }
  }

  .price {
    @include flex($justify: center, $align: baseline);
    align-self: flex-start;
    width: 100%;
    font-family: 'Cinzel Decorative', Avenir;
    // background-image: url('https://images.unsplash.com/photo-1517999144091-3d9dca6d1e43?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=927&q=80');
    // background-size: cover;
    // background-position: center center;
    // background-clip: text;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // filter: contrast(0.5) brightness(2);
    opacity: 0.5;

    .currency-sign {
      margin-right: 2px;
      color: neutral(75%);
      font-size: 0.9rem;
      align-self: flex-start;
    }
    .primary {
      color: #fff;
      font-size: 2rem;
      letter-spacing: 1px;
    }
    .secondary {
      color: #fff;
      font-size: 1.2rem;
      opacity: 0.75;
    }
  }

  .divider {
    margin: 0 0.5rem;
    height: 30px;
    width: 1px;
    background-color: neutral(50%);
    transform: rotate(30deg);
  }

  .unit {
    color: #fff;
    font-size: 0.8rem;
    opacity: 0.5;

    &.free {
      color: #fff;
      font-family: 'Cinzel Decorative', Avenir;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }

  .total {
    @include flex($direction: column);
    min-width: 400px;
    padding: 2rem;
    border-radius: 0.25rem;
    background-color: bg(0%);

    .price, .unit {
      opacity: 1;
    }

    .cart-items {
      width: 100%;
      margin-bottom: 1rem;
      font-size: 0.9rem;

      h3 {
        margin-bottom: 1.5rem;
        font-size: 0.9rem;
        text-align: center;
        text-transform: uppercase;
        opacity: 0.5;
      }

      h6 {
        font-size: 0.9rem;
        font-weight: 400;
        text-align: center;
        opacity: 0.75;
      }

      .item {
        @include flex($justify: space-between);
        width: 100%;
        text-transform: capitalize;
        white-space: pre;

        &.discount {
          > *, .price > * {
            color: $colorD;
          }
        }

        &.add {
          > *, .price > * {
            color: $alert-success-color;
          }
        }

        &.remove {
          > *, .price > * {
            color: $alert-error-color;
          }
        }

        .price {
          justify-content: flex-end;
          margin-left: 2rem;

          .currency-sign {
            font-size: 0.75rem;
          }
          .primary {
            font-size: 1.25rem;
          }
          .secondary {
            font-size: 0.85rem;
          }
        }
      }
    }
  }

  .horizontal-line {
    margin: 1rem 0;
    opacity: 0.75;

    h5 {
      color: #fff;
      font-family: 'Cinzel Decorative', Avenir;
      font-size: 0.9rem;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1px;
      white-space: pre;
    }
  }

</style>
