<template>
  <div class="page forgot-password">

    <h3>Reset Password</h3>

    <Loader v-if="loading" />


    <template v-if="!resetComplete">
      <p>Enter your new password below two times.</p>

      <div class="inputs">
        <input
          type="email"
          placeholder="Email"
          :value="email"
          @keydown="onKeyDown"
          @input="(e) => email = e.target.value"
          autocomplete="email"
        />

        <input
          type="password"
          placeholder="Password"
          :value="password"
          @keydown="onKeyDown"
          @input="passwordChanged"
          autocomplete="new-password"
        />
        <p v-if="password && passwordValid" class="hint success">Password valid</p>
        <p v-if="password && !passwordValid" class="hint error">Password must be between 8 and 80 characters long.</p>

        <input
          type="password"
          placeholder="Password Repeat"
          :value="passwordRepeat"
          @keydown="onKeyDown"
          @input="(e) => passwordRepeat = e.target.value"
          autocomplete="new-password"
        />
      </div>

      <Button
        label="Send Email"
        icon="right-arrow"
        @click.native="resetPassword"
      />
    </template>

    <template v-else>
      <p class="success">Password reset sucessfully.</p>

      <Button
        label="Go to Login"
        icon="right-arrow"
        @click.native="$router.push('/login')"
      />
    </template>


    <Alert :alert="alert" @dismiss="alert = null" />

    <div class="spacer h5"></div>

  </div>
</template>

<script>
  import { validateEmail } from '@/utility'

  export default {
    name: 'ForgotPassword',

    data() {
      return {
        loading: false,
        isCodeValid: false,
        password: '',
        passwordRepeat: '',
        passwordValid: null,
        resetComplete: false,
        alert: null
      }
    },

    methods: {
      onKeyDown() {
        if (event.key == 'Enter') {
          this.resetPassword()
        }
      },

      passwordChanged() {
        this.password = event.target.value;
        if (!this.password.trim()) {
          this.passwordValid = false;
          return;
        }

        if (this.password.length < 8 || this.password.length > 80) {
          this.passwordValid = false;
        }
        else {
          this.passwordValid = true;
        }
      },

      resetPassword() {
        if (!this.email.trim() || !this.password.trim() || !this.passwordRepeat.trim()) {
          this.alert = { type: 'warning', message: 'Please enter all fields.' };
          return;
        }

        if (!validateEmail(this.email)) {
          this.alert = { type: 'warning', message: 'Please enter a valid email address.' };
          return;
        }

        if (!this.passwordValid) {
          this.alert = { type: 'warning', message: 'Please enter a valid password.' };
          return;
        }

        if (this.password != this.passwordRepeat) {
          this.alert = { type: 'warning', message: 'Passwords must match.' };
          return;
        }

        let code = this.$route.params.code
        let params = {
          email: this.email,
          password: this.password,
          code
        }

        this.loading = true
        this.$store.dispatch('resetPassword', params).then(response => {
          if (response.status === 200) {
            this.alert = null
            this.resetComplete = true
          }
          else {
            this.alert = {
              type: 'error',
              message: response?.data?.error
            }
          }
          this.loading = false
        });
      }
    },

    mounted() {
      this.$store.dispatch('access')
    }
  }
</script>

<style scoped lang="scss">

  .forgot-password {
    @include flex($direction: column, $align: center);
    padding: 4rem;
    color: #ccc;

    > *:not(:last-child) {
      margin-bottom: 2rem;
    }

    .inputs {
      width: 100%;
      max-width: 300px;

      > *:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    .login-button {
      max-width: 200px;
    }
  }

  h4 {
    color: #ccc;
  }

</style>
