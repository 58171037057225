<template>
  <div class="community">
    <!-- <Banner title="Community" :image="require('@/assets/community_bg.jpg')" /> -->

    <section class="forum-categories">
      <CoolButton label="Discussions" :selected="selectedCategory === 0"
        textColor="#fffa81" bgColor="rgb(240,214,102)"
        @click.native="changeSubforum('discussions', 0)" />

      <CoolButton label="Work In Progress" :selected="selectedCategory === 1"
        textColor="#abcaff" bgColor="rgb(28,112,237)"
        @click.native="changeSubforum('workinprogress', 1)" />

      <CoolButton label="Groups" :selected="selectedCategory === 2"
        textColor="#8ffed3" bgColor="rgb(35,210,121)"
        @click.native="changeSubforum('groups', 2)" />

      <CoolButton label="Contests" :selected="selectedCategory === 3"
        textColor="#f77d97" bgColor="rgb(184,47,92)"
        @click.native="changeSubforum('contests', 3)" />
    </section>

    <div style="position: relative;">

      <OrnamentBorder width="60px" />

      <Subforum v-if="!selectedThread" :subforum="this.$store.state.forum.currentSubforum"
      @select-thread="selectThread" @refresh-subforum="refreshSubforum" />

      <Thread v-else :thread="this.$store.state.forum.currentThread"
      @back-to-subforum="backToSubforum" @refresh-thread="refreshThread"/>

    </div>

  </div>
</template>

<script>
  import Subforum from './Subforum.vue';
  import Thread from './Thread.vue';

  export default {
    name: 'CommunityHome',
    components: {
      Subforum,
      Thread
    },
    data() {
      return {
        selectedCategory: 0,
        selectedThread: null,
      }
    },
    methods: {
      changeSubforum(subforumName, index) {
        this.selectedCategory = index;
        this.selectedThread = null;
        this.$store.commit('changeSubforum', subforumName);
        this.$store.dispatch('fetchSubforumData', subforumName);
      },

      selectThread(id) {
        this.selectedThread = id;
        this.$store.dispatch('fetchThreadData', id);
      },

      refreshSubforum() {
        this.$store.dispatch('fetchSubforumData', this.$store.state.forum.currentSubforum.name);
      },

      refreshThread() {
        this.$store.dispatch('fetchThreadData', this.selectedThread);
      },

      backToSubforum(id) {
        this.selectedThread = null;
      }
    },
    created() {
      this.$store.dispatch('fetchSubforumData', this.$store.state.forum.currentSubforum.name);
    }
  }
</script>

<style scoped lang="scss">

  .community {
    box-sizing: border-box;
    min-height: 1200px;
    padding-bottom: 10rem;
  }

  .forum-categories {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 3rem 1rem;

    > * {
      margin: auto;
    }

    > *:not(:last-child) {
      margin-right: 1rem;
    }

    div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 240px;
      height: 60px;

      h3 {
        margin-left: 2rem;
        color: #ccc;
        font-size: 1rem;
        font-weight: 600;
      }

      &:hover {
        cursor: pointer;
        background-color: #2b2c2e;

        h3 {
          color: #fff;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-small) {
    .forum-categories {
      flex-wrap: wrap;

      > * {
        margin: 0.5rem !important;
      }
    }
  }

</style>
