<template>
  <div class="page register">

    <template v-if="!complete">

      <h1 class="colorful-title">Welcome!</h1>
      <p>Create your free account below.</p>
      <div class="spacer h1"></div>

      <div class="inputs">
        <input type="text"
          placeholder="Name"
          :value="name"
          @keydown="onKeyDown"
          @input="nameChanged"
        />
        <input type="text"
          placeholder="Email"
          :value="email"
          @keydown="onKeyDown"
          @input="emailChanged"
        />
        <input type="password"
          placeholder="Password"
          :value="password"
          @keydown="onKeyDown"
          @input="passwordChanged"
          autocomplete="new-password"
        />
        <input type="password"
          placeholder="Password Repeat"
          :value="passwordRepeat"
          @keydown="onKeyDown"
          @input="passwordRepeatChanged"
          autocomplete="new-password"
        />
        <p v-if="password && passwordValid && passwordsMatch" class="hint valid">Password valid</p>
        <p v-if="password && !passwordValid" class="hint invalid">Password must be between 8 and 80 characters long.</p>
        <p v-if="password && passwordValid && !passwordsMatch" class="hint invalid">Passwords don't match.</p>
      </div>

      <!-- <TabSelect :tabs="['male', 'female']" @select="(tab) => gender = tab" />
      <img class="portrait" :src="require(`@/assets/${portrait}`)" /> -->

      <Message
        v-if="isPromotionActive"
        type="success"
        :text="`Applying Promotion: All features 1 year free\nPromotion ends ${formatDate(promotionEndDate, '&mon &dd, &year | &hour12:&min&ap')}`"
      />

      <Alert :alert="alert" @dismiss="alert = null" />


      <template v-if="loading">
        <div class="inline-loading-container">
          <Loader size="80px" />
        </div>
      </template>

      <template v-else>
        <CoolButton
          label="Register"
          type="compact"
          animation="infinite"
          textColor="#9faeff"
          bgColor="rgb(93,100,254)"
          @click.native="register"
        />

        <div class="spacer h3"></div>

        <h4>Already have an account?</h4>
        <CoolButton class="login-button"
          label="Login"
          type="compact"
          animation="infinite"
          textColor="#66f1d0"
          bgColor="rgb(102,241,208)"
          @click.native="() => $router.push('/login')"
        />
      </template>

    </template>


    <template v-else>
      <h3 class="success">Registration Complete!</h3>
      <p style="text-align: center;">Please check your email to activate your account.</p>
    </template>

  </div>
</template>

<script>
  import { validateEmail, encrypt } from '@/utility'
  import promotion from '../../../config/promotion'

  export default {
    name: 'Register',

    data() {
      return {
        loading: false,

        name: '',
        email: '',
        password: '',
        passwordRepeat: '',
        passwordValid: null,
        passwordsMatch: null,
        complete: false,
        alert: null,
        promotionEndDate: promotion.promotionEndDate,

        gender: 'male',
        genre: 'fantasy'
      }
    },

    computed: {
      isPromotionActive() {
        return Date.now() < (new Date(this.promotionEndDate)).getTime()
      },

      portrait() {
        if (this.gender === 'female') return 'wizard_female_1.jpeg';
        else return 'wizard_male_1.jpeg';
      }
    },

    methods: {
      nameChanged() {
        this.name = event.target.value;
      },
      emailChanged() {
        this.email = event.target.value;
      },
      passwordChanged() {
        this.password = event.target.value

        if (!this.password.trim()) {
          this.passwordValid = false
          return
        }

        this.passwordValid = this.password.length >= 8 && this.password.length <= 80
        this.passwordsMatch = this.passwordRepeat == this.password
      },
      passwordRepeatChanged() {
        this.passwordRepeat = event.target.value
        this.passwordsMatch = this.passwordRepeat == this.password
      },

      onKeyDown() {
        if (event.key == 'Enter') {
          this.register()
        }
      },

      async register() {
        if (!this.name.trim() || !this.email.trim() || !this.password.trim() || !this.passwordRepeat.trim()) {
          this.alert = { type: 'warning', message: 'Please enter all fields.' };
          return;
        }
        if (!validateEmail(this.email)) {
          this.alert = { type: 'warning', message: 'Please enter a valid email address.' };
          return;
        }
        if (!this.passwordValid) {
          this.alert = { type: 'warning', message: 'Please enter a valid password.' };
          return;
        }
        if (!this.passwordsMatch) {
          this.alert = { type: 'warning', message: 'Passwords must match.' };
          return;
        }

        let encryptedData = encrypt({
          name: this.name,
          email: this.email,
          password: this.password,
          avatarImage: 'default_avatar.png',
        });


        this.loading = true
        const response = await this.$store.dispatch('register', encryptedData)
        if (response.status === 201) {
          this.complete = true;
        }
        else {
          this.alert = { type: 'error', message: response.data.error };
        }
        this.loading = false
      }
    },

    mounted() {
      this.$store.dispatch('access')
    }
  }
</script>

<style scoped lang="scss">

  .register {
    @include flex($direction: column, $align: center);
    margin: 0 auto;
    padding: 4rem;
    box-sizing: border-box;
    color: #ccc;

    > *:not(:last-child) {
      margin-bottom: 2rem;
    }

    .inputs {
      max-width: 360px;

      > *:not(:first-child) {
        margin-top: 1rem;
      }
      p.hint {
        margin-top: 0.25rem;
        font-size: 0.75rem;
        font-weight: 600;
        text-align: center;

        &.valid {
          color: $alert-success-color;
        }
        &.invalid {
          color: $alert-error-color;
        }
      }
    }
  }

  .portrait {
    width: 120px;
    height: 120px;
    border-radius: 100%;
  }

  .success {
    color: $alert-success-color;
  }

</style>
