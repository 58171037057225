<template>
  <div class="landing-section" id="data">

    <div class="section-bg">
      <img class="bg-image" src="https://cdna.artstation.com/p/assets/images/images/019/864/940/large/ryan-hong-icrshot04-d22.jpg?1565326231" />
      <!-- <img class="bg-image" src="https://images.unsplash.com/photo-1571786256017-aee7a0c009b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2968&q=80" /> -->
    </div>


    <div class="data-animation-container">
      <p v-for="item in animationItems" :class="`color${item.color}`"
        :style="`top: ${item.yOffset}px; animation-delay: ${item.delay}s;`">{{ item.label }}</p>
    </div>


    <div class="section-content">

      <div class="content-header">
        <h2>Your data safety is our #1 priority.</h2>
        <p>Your writings are your gold. They’re irreplaceable. Nothing is worse than losing months, or even years, of hard work. That’s why we secure your data using all means possible.</p>
      </div>

      <div class="points">
        <div>
          <h4><Icon name="lock" size="1rem" />Trusted, Secure Cloud Storage</h4>
          <p>We use one of the most trusted, secure cloud storage platforms in the world. (For security reasons, we can't reveal which one.) With world class speed, security, and redundancy, you're in good hands.</p>
        </div>
        <div>
          <h4><Icon name="database" size="1rem" />Database Backups</h4>
          <p>A world class database provider brings a lot of reliability. But with data this important, you can never be too careful. So just in case, we take periodic, cold storage backups. In even the most unlikely circumstances, we have you covered.</p>
        </div>
        <div>
          <h4><Icon name="download" size="1rem" />Hold onto your own data (Coming Soon!)</h4>
          <p>Still don't trust someone else with your data? That’s ok, just export and download your data with a single click. Print it out, maintain your own backup, do with it whatever you like. Your data is yours, and we just want your precious work to be safe.</p>
        </div>
      </div>

    </div>

  </div>
</template>


<script>
  import data_animation_items from '../data_animation_items'

  export default {
    name: 'LandingData',

    computed: {
      animationItems() {
        return data_animation_items
      }
    },
  }
</script>


<style scoped lang="scss">

  #data {
    padding-top: 14rem;
    text-align: center;

    .section-bg {
      // background-color: #1d2736;
      // clip-path: polygon(0 120px, 100% 0, 100% 100%, 0 calc(100% - 40px));
      .bg-image {
        mask-image: radial-gradient(circle at bottom center, rgba(0,0,0,.5) 0%, rgba(0,0,0,.1) 25%, rgba(0,0,0,.05) 75%);
      }
    }

    .section-content {
      @include flex($direction: column);
    }

    .points {
      @include flex($align: stretch);
      margin-top: 2rem;

      > * {
        max-width: 300px;
        margin: 0 1rem;
      }
    }

    h4 { color: $colorB; }
    .icon { color: $colorA; }
  }

</style>
