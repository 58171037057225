<template>
  <div class="writing-collection">

    <WritingCollectionNewFolder v-if="newFolder"
      :data="newFolder"
      @create-folder="createFolder" />

    <WritingCollectionItem
      v-for="writing in $store.state.writings"
      :data="writing"
      @new-file="newFile"
      @delete-file="deleteFile"
      @create-folder="createFolder"
      @load-file="(file) => $emit('load-file', file)" />

    <div class="spacer"></div>

    <Button
      style="display: inline-flex;"
      label="New File"
      icon="plus"
      @click.native="newFile(null)" />

    <div class="spacer"></div>

    <Button
      style="display: inline-flex;"
      label="New Folder"
      icon="plus"
      @click.native="addFolder" />

  </div>
</template>

<script>
  import WritingCollectionItem from './WritingCollectionItem.vue';
  import WritingCollectionNewFolder from './WritingCollectionNewFolder.vue';

  export default {
    name: 'WritingCollection',
    components: {
      WritingCollectionItem,
      WritingCollectionNewFolder,
    },
    data() {
      return {
        newFolder: null
      }
    },
    methods: {
      addFolder() {
        this.newFolder = {
          type: 'new-folder',
          title: 'New Folder',
          parentID: null
        }
      },

      createFolder(folder) {
        this.$store.dispatch('newWriting', folder).then(response => {
          console.log(response);
          // this.$store.dispatch('fetchWritings');
        });
        this.newFolder = null;
      },

      newFile(originID) {
        console.log(this.$store.state.world?._id);
        let newFile = {
          worldID: this.$store.state.world?._id,
          parentID: originID,
          authorID: this.$store.state.user._id,
          type: 'doc',
          title: 'New File',
          content: [{ style: '', content: '\u200B' }],
          backgroundImage: 'https://cdna.artstation.com/p/assets/images/images/011/726/368/large/berube-jonathan-berube-art-director-matte-192.jpg?1531092197'
        }
        console.log(newFile);

        this.$store.dispatch('newWriting', newFile).then(response => {
          console.log(response);
          newFile._id = response.data.id;
          this.$emit('load-file', newFile);
          // this.$store.dispatch('fetchWritings').then(() => {
          // });
        });
      },

      deleteFile(id) {
        this.$store.dispatch('deleteWriting', id).then(response => {
          console.log(response);
          // this.$store.dispatch('fetchWritings').then(response => {
          //   console.log("fetch:", response);
          // });
        })
      }
    },
    mounted() {
    }
  }
</script>

<style scoped lang="scss">

  .writing-collection {
    @include flex($direction: column);
    width: 100%;
  }

</style>
