<template>
  <div class="language page">

    <!-- <Hint
      title="Coming Soon"
      icon="more-arrow"
      style="position: absolute; right: 2rem;"
      origin="bottom"
    >
      <ul>
        <li>Stems</li>
        <li>Grammar</li>
        <li>Writing System</li>
      </ul>
    </Hint> -->

    <BackButton
      style="top: 0; left: 0;"
      @click.native="comingFromNew ? $router.push('/creation') : $router.back()"
    />


    <h3 v-if="language">{{ language.title }}</h3>
    <div class="spacer"></div>


    <LanguageWriting
      :isAuthor="isAuthor"
      :keyMapUpdate="keyMapUpdate"
      :inverted="inverted"
    />

    <Checkbox
      label="Invert Colors"
      color="#23f584"
      :checked="inverted"
      @checkbox-toggle="inverted = !inverted"
    />

    <div class="spacer h4"></div>

    <KeyMapping
      :isAuthor="isAuthor"
      @key-map-updated="keyMapUpdate = !keyMapUpdate"
      :inverted="inverted"
    />


    <div class="spacer"></div>
    <h4>Words</h4>
    <div class="spacer"></div>

    <Table :highlightedIndex="0"
      :headers="headers"
      :rows="words"
      readonly />

    <div class="spacer h5"></div>

  </div>
</template>

<script>
  import KeyMapping from './KeyMapping.vue'
  import LanguageWriting from './LanguageWriting.vue'
  import exampleWorld from '@/components/World/example_world'

  export default {
    name: 'Language',
    components: {
      KeyMapping,
      LanguageWriting,
    },

    data() {
      return {
        comingFromNew: false,

        inverted: false,

        headers: [
          { label: 'Word', value: 'word', input: 'text' },
          { label: 'Meaning', value: 'meaning', input: 'text' },
          { label: 'Type', value: 'type', input: 'select', options: [
              { value: 'noun', label: 'Noun', color: '#6fa5f5' },
              { value: 'verb', label: 'Verb', color: '#fede6e' },
              { value: 'adjective', label: 'Adjective', color: '#fb6f6f' },
              { value: 'other', label: 'Other', color: '#6ffb81' },
            ]
          }
        ],

        keyMapUpdate: false
      }
    },
    computed: {
      language() {
        if (this.$route.params.id == 'd982a3943') {
          return exampleWorld.languages.find(language => language._id == this.$route.params.id)
        }
        else {
          return this.$store.state.languages?.[this.$route.params.id];
        }
      },

      isAuthor() {
        return this.$store.state.user?._id == this.language?.authorID
      },

      words() {
        return this.language?.words;
      }
    },
    methods: {
      wordFormatter(header, value) {
        if (header === 'type') {
          switch (value) {
            case 'Noun':
              return 'color: #85d3ff;';
            case 'Verb':
              return 'color: #ffa834;';
            default:
              return '';
          }
        }
      }
    },

    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (from.name == 'NewLanguage') vm.comingFromNew = true
      })
    },

    created() {
      if (this.$route.params.id != 'd982a3943') {
        this.$store.dispatch('fetchLanguage', this.$route.params.id).then(response => {
          console.log(response);
        });
      }
    }
  }
</script>

<style scoped lang="scss">

  .language {
    margin-top: 2rem;
    padding-top: 4rem;
  }

  h3 {
    margin-top: 0.5em;
    margin-bottom: 3rem;
    text-transform: uppercase;
  }

  .settings-grid {
    @include grid($columns: 1fr 3fr, $gap: 1.5rem);
    width: 60%;
    max-width: 600px;

    > *:nth-child(odd) {
      color: $colorA;
      font-size: 0.9rem;
      font-weight: 600;
      text-align: right;
    }
    > *:nth-child(even) {
      text-align: left;
    }

    .changed::before {
      content: '';
      display: inline-block;
      margin-bottom: calc(0.5em - 4px);
      margin-right: 1rem;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: $colorC;
    }
  }

  section {
    width: 100%;
    margin: 1rem 0;
    padding: 1rem;

    article {
      position: relative;
      @include grid($columns: 1fr 1fr, $gap: 0.5rem, $align: flex-start);

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      .word-node {
        position: relative;
      }
    }
  }

  .stem-inputs {
    @include flex();
    width: 100%;

    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .word-fake-input {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    span {
      white-space: pre-wrap;
    }

    span:only-child {
      width: 100%;
    }
  }

</style>


<style lang="scss">

  .word-stem {
    color: $stem-color;
    font-weight: bold;

    &:focus {
      color: $stem-color;
    }
  }

  .inverted {
    filter: invert(100%);
  }

</style>
