<template>
  <div class="emoji-picker">
    <button class="small" @click="togglePicker">+</button>
    <div v-if="isPickerOpen" class="picker">
      <span v-for="emoji in emojis" :key="emoji" @click.capture="selectEmoji(emoji)"><p>{{ emoji }}</p></span>
    </div>
  </div>
</template>


<script>
  export default {
    name: 'EmojiPicker',
    data() {
      return {
        isPickerOpen: false,
        emojis: [
          '😊', '💔', '❤️', '👍🏼', '👌🏼', '👏', '💩', '😭', '😓', '😂', '😍', '😢', '🤔', '🧐', '🤨', '😔', '😩', '🙄', '😥', '🤪', '😘', '😱', '😳', '🤯', '😎', '🤭', '🤑', '😴', '🤤', '🤝', '👊🏼', '✍🏼', '🧠', '👀', '👁', '🤷‍♂️', '🤷‍♀️', '🤦‍♂️', '🤦‍♀️', '💡', '❓', '🚫'
        ]
      }
    },
    methods: {
      togglePicker() {
        this.isPickerOpen = !this.isPickerOpen;
      },

      clickOutside() {
        if (!this.$el.contains(event.target)) {
          this.isPickerOpen = false;
        }
      },

      selectEmoji(emoji) {
        this.$emit('emoji-selected', emoji);
        this.isPickerOpen = false;
      },

      showCodePoints() {
        this.emojis.forEach(emoji => {
          console.log(emoji, emoji.codePointAt(0));
        });
      }
    },
    mounted() {
      document.addEventListener('click', this.clickOutside);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.clickOutside);
    }
  }
</script>


<style scoped lang="scss">

  .emoji-picker {
    position: relative;

    button {
      padding: 0 0.35rem;
      color: #aaa;
      background-color: rgba(0,0,0,.25);
      border-radius: 0.4rem;
      font-size: 1.25rem;
      line-height: 1.4rem;
      outline: none !important;

      &:hover {
        color: #fff;
        background-color: rgba(255,255,255,.1);
        border: 1px solid transparent;
      }
    }

    .picker {
      position: absolute;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      padding: 1rem;
      border-radius: 1rem;
      top: 0;
      left: 3rem;
      transform: translateY(-50%);
      background-color: rgba(0,0,0,.75);
      border: 1px solid #5d6165;

      span {
        padding: 0.5rem;
        font-size: 2rem;
        line-height: 1em;
        user-select: none;
        border: 1px solid transparent;
        border-radius: 0.75rem;
        transition: all 0.1s;

        p {
          transform: translateY(0.1em);
        }

        &:hover {
          cursor: pointer;
          background-color: rgba(255,255,255,.15);
          border: 1px solid #c5d4e6;
        }
      }
    }
  }


</style>
