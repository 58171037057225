import Vue from 'vue'
import VueRouter from 'vue-router'

import AboutScrybe from '../components/AboutScrybe.vue'

import Login from '../components/User/Auth/Login.vue'
import Register from '../components/User/Auth/Register.vue'
import Activate from '../components/User/Auth/Activate.vue'
import ForgotPassword from '../components/User/Auth/ForgotPassword.vue'
import ResetPassword from '../components/User/Auth/ResetPassword.vue'

import Introduction from '../components/User/Introduction.vue'
import StartCreating from '../components/User/StartCreating.vue'

import Dashboard from '../components/User/Dashboard.vue'
import DashboardHome from '../components/User/Dashboard/DashboardHome.vue'
import UserSettings from '../components/User/Dashboard/UserSettings.vue'
import LoginHistory from '../components/User/Dashboard/LoginHistory.vue'
import Following from '../components/User/Dashboard/Following.vue'
import Invitations from '../components/User/Dashboard/Invitations.vue'
import NotificationHistory from '../components/User/Dashboard/NotificationHistory.vue'
import ManageSubscription from '../components/User/Dashboard/ManageSubscription.vue'
import Violations from '../components/User/Dashboard/Violations.vue'
import Media from '../components/User/Dashboard/Media.vue'

import Test from '../components/User/Dashboard/Test.vue'
import Subscribe from '../components/User/Subscribe.vue'
import PaymentSuccess from '../components/User/PaymentSuccess.vue'


import Profile from '../components/User/Profile.vue'



import InspirationHome from '../components/Inspiration/InspirationHome.vue'



import CreationHome from '../components/Creation/CreationHome.vue'

import World from '../components/World/World.vue'
import CreateWorld from '../components/World/CreateWorld.vue'
import ManageWorlds from '../components/World/ManageWorlds.vue'
import ManageMembers from '../components/World/ManageMembers.vue'
import Invitation from '../components/World/Invitation.vue'

import Writing from '../components/Creation/Writings/Writing.vue'
import NewWriting from '../components/Creation/Writings/NewWriting.vue'

import WikiPage from '../components/Creation/Wiki/WikiPage.vue'
import NewWiki from '../components/Creation/Wiki/NewWiki.vue'

import Timeline from '../components/Creation/Timeline/Timeline.vue'
import NewTimeline from '../components/Creation/Timeline/NewTimeline.vue'
import Calendar from '../components/Creation/Timeline/Calendar.vue'

import Map from '../components/Creation/Maps/Map.vue'
import NewMap from '../components/Creation/Maps/NewMap.vue'
import MapGenerator from '../components/Creation/Maps/MapGenerator.vue'

import Language from '../components/Creation/Language/Language.vue'
import NewLanguage from '../components/Creation/Language/NewLanguage.vue'



import CommunityHome from '../components/Community/CommunityHome.vue'
import WorldBrowser from '../components/Community/WorldBrowser.vue'
import PostBrowser from '../components/Community/PostBrowser.vue'
import Forum from '../components/Community/Forum/Forum.vue'

import Pricing from '../components/Pricing.vue'
import Support from '../components/Support.vue'
import Updates from '../components/Updates.vue'
import helpRoutes from './help'


import AppDiagram from '../components/AppDiagram.vue'


import PageNotFound from '../components/PageNotFound.vue'


Vue.use(VueRouter)


const routes = [

  { path: '/', name: 'Landing', component: () => import(
    /* webpackChunkName: "landing" */
    /* webpackPreload: true */
    '../components/Landing/Landing.vue'
  ) },
  // { path: '/About', name: 'AboutScrybe', component: AboutScrybe },

  { path: '/login', name: 'Login', component: Login },
  { path: '/register', name: 'Register', component: Register },
  { path: '/activate/:code', name: 'Activate', component: Activate },
  { path: '/forgot-password', name: 'ForgotPassword', component: ForgotPassword },
  { path: '/reset-password/:code', name: 'ResetPassword', component: ResetPassword },

  { path: '/introduction', name: 'Introduction', component: Introduction },
  { path: '/start-creating', name: 'StartCreating', component: StartCreating },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard,
    children: [
      { path: '', name: 'DashboardHome', component: DashboardHome },
      { path: 'settings', name: 'UserSettings', component: UserSettings },
      { path: 'login-history', name: 'LoginHistory', component: LoginHistory },
      { path: 'following', name: 'Following', component: Following },
      { path: 'invitations', name: 'Invitations', component: Invitations },
      { path: 'notification-history', name: 'NotificationHistory', component: NotificationHistory },
      { path: 'manage-subscription', name: 'ManageSubscription', component: ManageSubscription },
      { path: 'violations', name: 'Violations', component: Violations },
      { path: 'media', name: 'Media', component: Media },
    ]
  },
  { path: '/subscribe', name: 'Subscribe', component: Subscribe },
  { path: '/payment-success', name: 'PaymentSuccess', component: PaymentSuccess },
  { path: '/user/:id', name: 'Profile', component: Profile },



  { path: '/inspiration', name: 'InspirationHome', component: InspirationHome },



  { path: '/creation', name: 'CreationHome', component: CreationHome },

  { path: '/world/:id', name: 'World', component: World },
  { path: '/create-world', name: 'CreateWorld', component: CreateWorld },
  { path: '/manage-worlds', name: 'ManageWorlds', component: ManageWorlds },
  { path: '/manage-members', name: 'ManageMembers', component: ManageMembers },
  { path: '/invitation/:id', name: 'Invitation', component: Invitation },

  { path: '/writing-room', name: 'WritingRoom', component: Writing },
  { path: '/writing/:id', name: 'Writing', component: Writing },
  { path: '/new-writing', name: 'NewWriting', component: NewWriting },

  { path: '/wiki/:id', name: 'WikiPage', component: WikiPage },
  { path: '/new-wiki', name: 'NewWiki', component: NewWiki },

  { path: '/timeline/:id', name: 'Timeline', component: Timeline },
  { path: '/new-timeline', name: 'NewTimeline', component: NewTimeline },
  { path: '/calendar', name: 'Calendar', component: Calendar },

  { path: '/map/:id', name: 'Map', component: Map },
  { path: '/new-map', name: 'NewMap', component: NewMap },
  { path: '/generate-map', name: 'MapGenerator', component: MapGenerator },

  { path: '/language/:id', name: 'Language', component: Language },
  { path: '/new-language', name: 'NewLanguage', component: NewLanguage },


  { path: '/community', name: 'CommunityHome', component: CommunityHome,
    children: [
      { path: '', redirect: '/community/worlds' },
      { path: 'worlds', name: 'WorldBrowser', component: WorldBrowser },
      { path: 'posts', name: 'PostBrowser', component: PostBrowser },
    ]
  },

  // { path: '/forum', name: 'Forum', component: Forum },

  { path: '/pricing', name: 'Pricing', component: Pricing },
  { path: '/support', name: 'Support', component: Support },
  { path: '/updates', name: 'Updates', component: Updates },


  { path: '/app-diagram', name: 'AppDiagram', component: AppDiagram },

  { path: '/404', component: PageNotFound, alias: '*' }

  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
]

const router = new VueRouter({
  routes: [
    ...routes,
    ...helpRoutes
  ],

  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
