<template>
  <div class="page">

    <div class="spacer h5"></div>

    <Loader v-if="loading" />

    <template v-else-if="!invitation">
      <h1>World Invitation</h1>
      <div class="spacer h5"></div>
      <Message
        type="error"
        :text="error || 'Invitation not found.'"
      />
      <p class="hint"></p>
    </template>

    <template v-else>
      <h1 class="colorful-title">World Invitation</h1>
      <div class="spacer h4"></div>

      <Avatar
        :userID="invitation.authorID"
        horizontal
      />

      <div class="spacer h3"></div>
      <p>is inviting you to join their world</p>
      <div class="spacer h3"></div>

      <WorldPreview
        :worldID="invitation.worldID"
        @click.native="$router.push('/world/' + invitation.worldID)"
      />

      <div class="spacer h3"></div>
      <div class="row spaced">
        <Button
          label="Reject"
          class="delete"
          @click.native="respondToInvitation('reject')"
        />
        <Button
          label="Accept"
          class="primary"
          @click.native="respondToInvitation('accept')"
        />
      </div>
      <div class="spacer"></div>
      <Alert :alert="alert" @dismiss="alert = null" />

    </template>

    <div class="spacer h5"></div>
    <div class="spacer h5"></div>

  </div>
</template>


<script>
  import Avatar from '../User/Avatar.vue'
  import WorldPreview from './WorldPreview.vue'

  export default {
    name: 'Invitation',
    components: {
      Avatar,
      WorldPreview
    },

    data() {
      return {
        loading: true,
        invitation: null,
        error: null,
        alert: null
      }
    },

    methods: {
      respondToInvitation(decision) {
        this.$store.dispatch('respondToInvitation', {
          invitationID: this.invitation._id,
          decision
        }).then(response => {
          console.log(response);
          if (response?.status == 200) {
            this.alert = {
              type: 'success',
              message: decision == 'accept' ?
                'Invitation accepted successfully.' :
                'Invitation rejected successfully.'
            }
          }
          else {
            this.alert = {
              type: 'error',
              message: response?.data?.error
            }
          }
        })
      }
    },

    created() {
      this.$store.dispatch('fetchInvitation', this.$route.params.id).then(response => {
        console.log(response);
        if (response?.status == 200) {
          this.invitation = response?.data
        }
        else {
          this.error = response?.data?.error
        }
        this.loading = false
      })
    }
  }
</script>


<style scoped lang="scss">

  h1.colorful-title {
    font-size: 2rem;
  }

</style>
