<template>
  <div class="creation">
    <!-- <Banner title="Creation" :image="require('@/assets/creation_bg.jpg')" /> -->

    <!-- <div class="bg-grain"></div> -->
    <div class="creation-bg"></div>

    <div class="spacer h3"></div>



    <template v-if="!user">
      <div class="world-menu floating texture-bg fantasy">

        <div class="world-header">
          <p>You are not logged in.</p>
          <div class="spacer"></div>
          <Button
            class="primary"
            label="Go to Login"
            icon="right-arrow"
            @click.native="$router.push('/login')"
          />
        </div>

      </div>
    </template>


    <template v-else-if="!world">
      <div class="world-menu floating texture-bg fantasy">

        <div class="world-header">
          <p>Worlds are the objects that hold all the "elements" for creation. These include Writings, Wikis, Timelines, Maps, and so on.</p>
          <div class="spacer"></div>
          <p>Create a world to start using the creation elements.</p>
          <div class="spacer"></div>
          <p class="hint">You have no worlds.</p>
          <div class="spacer"></div>
          <Button
            class="primary inline"
            label="Create World"
            icon="plus"
            @click.native="$router.push('/create-world')"
          />
        </div>

      </div>
    </template>



    <div v-else class="creation-menu-container floating">

      <div class="world-image">
        <div class="world-header-image-overlay"></div>
        <img :src="headerImage"></img>

        <div class="edit-world-image">
          <template v-if="loadingWorldImage">
            <div class="inline-loading-container">
              <Loader size="60px" />
            </div>
          </template>

          <template v-else>
            <Button
              icon="mountain"
              label="Upload Image"
              class="primary"
              @click.native="openImageSelect"
            />
            <input
              type="file"
              name="world"
              id="worldImageFileSelect"
              @change="selectWorldImage"
            />
          </template>
        </div>
      </div>

      <div class="toolbar row spaced">
        <Select
          :options="Object.values(ui.visibility)"
          :value="world.visibility || 'private'"
          @select="(value) => changingVisibility = world.visibility == value ? null : value"
          iconSize="0.9rem"
          class="world-visibility"
          style="min-width: 135px;"
          strict
        />

        <Button
          class="dark"
          label="Preview"
          icon="eye flat"
          iconSize="12px"
          @click.native="$router.push('/world/' + world._id)"
        />

        <Button
          class="dark"
          label="Manage Worlds"
          icon="globe"
          iconSize="12px"
          @click.native="$router.push('/manage-worlds')"
        />

        <div class="members-list-button">
          <Button
            label="Members"
            :icon="membersOpen ? 'less-arrow' : 'more-arrow'"
            class="dark"
            @click.native="membersOpen = !membersOpen"
          />
          <div v-if="membersOpen" class="members-list-container floating">
            <div class="members-list">
              <Avatar :userID="user._id" />
              <Avatar
                v-for="memberID in members"
                :userID="memberID"
                :key="memberID"
              />
            </div>
            <div class="spacer"></div>
            <Button
              label="Manage Members"
              icon="user"
              class="inline"
              @click.native="$router.push('/manage-members')"
            />
          </div>
        </div>
      </div>


      <div class="world-header-container">
        <div class="world-header-bg">
          <div class="texture-bg fantasy"></div>
        </div>

        <div class="world-name">
          <div
            v-if="editing == 'name'"
            class="edit-world-name"
          >
            <input
              type="text"
              :value="world.name"
              @input="(e) => newName = e.target.value"
            />
            <div class="actions row right spaced">
              <Button
                icon="check"
                class="primary"
                @click.native="updateWorldName"
              />
              <Button
                icon="x"
                class="delete"
                @click.native="editing = null"
              />
            </div>
          </div>

          <template v-else>
            <DecorativeTitle class="floating">
              <h1>{{ world.name }}</h1>
            </DecorativeTitle>
            <Button
              icon="edit"
              class="edit-name-button primary"
              @click.native="editing = 'name'"
            />
          </template>
        </div>


        <div class="world-header">
          <div
            v-if="editing == 'description'"
            class="edit-world-description"
          >
            <textarea
              :value="world.description"
              @input="(e) => newDescription = e.target.value"
            />
            <div class="actions row right spaced">
              <Button
                icon="check"
                class="primary"
                @click.native="updateWorldDescription"
              />
              <Button
                icon="x"
                class="delete"
                @click.native="editing = null"
              />
            </div>
          </div>
          <div v-else
            class="world-description"
          >
            <p>{{ world.description }}</p>
            <Button
              icon="edit"
              class="edit-description-button primary"
              @click.native="editing = 'description'"
            />
          </div>
        </div>

      </div>

      <div class="spacer h2"></div>


      <div class="menu-container">
        <div :class="`menu`">
          <div class="icon">
            <img src="https://media.scrybe-app.com/assets/elements/writing_icon.png"
              @click="clickMenu('writings')" />
            <h3 style="color: #b0d7ff; text-shadow: 0 0 4px #2a86ff;">Writings</h3>
          </div>
          <div class="icon">
            <img src="https://media.scrybe-app.com/assets/elements/wiki_icon.png"
              @click="clickMenu('wikis')" />
            <h3 style="color: #c2ffe4; text-shadow: 0 0 4px #4bfbb6;">Wikis</h3>
          </div>
          <div class="icon">
            <img src="https://media.scrybe-app.com/assets/elements/timeline_icon.png"
              @click="clickMenu('timelines')" />
            <h3 style="color: #fedee7; text-shadow: 0 0 4px #ff698d;">Timelines</h3>
          </div>
          <div class="icon">
            <img src="https://media.scrybe-app.com/assets/elements/map_icon.png"
              @click="clickMenu('maps')" />
            <h3 style="color: #fff2d5; text-shadow: 0 0 4px #ffe05b;">Maps</h3>
          </div>
          <div v-if="hasPermission('languages')" class="icon">
            <img src="https://media.scrybe-app.com/assets/elements/empty_icon.png"
              @click="clickMenu('languages')" />
            <h3 style="color: #f8ccff; text-shadow: 0 0 4px #9c7bfb;">Languages</h3>
          </div>
        </div>
      </div>


      <div class="element-menu-container">
        <WritingsMenu v-if="tab == 'writings'" />
        <WikiMenu v-if="tab == 'wikis'" />
        <TimelinesMenu v-if="tab == 'timelines'" />
        <MapsMenu v-if="tab == 'maps'" />
        <LanguageMenu v-if="tab == 'languages'" />
      </div>

    </div>


    <ModalContainer v-if="changingVisibility">
      <h4>Make this world {{ changingVisibility }}?</h4>

      <div class="spacer h1"></div>
      <p v-if="changingVisibility == 'private'" class="hint">Only you will be able to see this world.</p>
      <Message
        v-if="changingVisibility == 'public'"
        type="warning"
        text="By making this world public, you agree to the terms of service and privacy policy."
      />
      <div class="spacer h1"></div>

      <div class="row spaced">
        <Button
          label="Yes"
          class="primary"
          @click.native="updateWorldVisibility"
        />
        <Button
          label="No"
          class="delete"
          @click.native="changingVisibility = null"
        />
      </div>
    </ModalContainer>

  </div>
</template>

<script>
  import WritingsMenu from './Writings/WritingsMenu.vue';
  import WikiMenu from './Wiki/WikiMenu.vue';
  import TimelinesMenu from './Timeline/TimelinesMenu.vue';
  import MapsMenu from './Maps/MapsMenu.vue';
  import LanguageMenu from './Language/LanguageMenu.vue';
  import Avatar from '../User/Avatar.vue';

  export default {
    name: 'CreationHome',
    components: {
      WritingsMenu,
      WikiMenu,
      TimelinesMenu,
      MapsMenu,
      LanguageMenu,
      Avatar
    },

    data() {
      return {
        loadingWorldImage: false,

        tab: 'writings',
        editing: null,
        newName: '',
        newDescription: '',
        membersOpen: false,
        changingVisibility: null
      }
    },

    computed: {
      user() {
        return this.$store.state.user;
      },
      world() {
        return this.$store.state.world;
      },
      members() {
        return this.world?.members || []
      },
      headerImage() {
        return this.world?.headerImage ?
          this.publicImageURL('world', this.world?.headerImage, this.user?._id) :
          'https://media.scrybe-app.com/assets/mountains_01.jpg'
      },

      items() {
        if (!this.world?.[this.tab]?.length) return null;
        return this.world?.[this.tab];
      }
    },

    methods: {
      clickMenu(tab) {
        this.tab = tab;
      },

      hasProduct(name) {
        if (name == 'writings' || name == 'wikis') return true
        return this.user?.permissions?.find(productName => productName == name)
      },

      async updateWorldImage() {
        this.loadingWorldImage = true;

        let newImage = event.target.files[0];
        let formData = new FormData();
        formData.append('world_bg', newImage, newImage.name);

        const response = await this.$store.dispatch('updateWorldImage', formData)
        this.$store.dispatch('fetchCurrentUserWorld')

        this.loadingWorldImage = false;
      },

      async updateWorldName() {
        if (!this.newName.trim()) return;

        const response = await this.$store.dispatch('updateWorldName', {
          worldID: this.world._id,
          name: this.newName
        })
        if (response?.status == 200) {
          this.$store.dispatch('fetchCurrentUserWorld')
          this.editing = null
        }
      },

      async updateWorldDescription() {
        if (!this.newDescription.trim()) return;

        const response = await this.$store.dispatch('updateWorldDescription', {
          worldID: this.world._id,
          description: this.newDescription
        })
        if (response?.status == 200) {
          this.$store.dispatch('fetchCurrentUserWorld')
          this.editing = null
        }
      },

      async updateWorldVisibility() {
        const response = await this.$store.dispatch('updateWorldVisibility', {
          worldID: this.world._id,
          visibility: this.changingVisibility
        })
        if (response?.status == 200) {
          this.$store.dispatch('fetchCurrentUserWorld')
        }
        this.changingVisibility = null
      },

      openImageSelect() {
        let fileSelect = document.getElementById('worldImageFileSelect');
        fileSelect.click();
      },

      async selectWorldImage() {
        this.loadingWorldImage = true;

        let newImage = event.target.files[0];
        let formData = new FormData();
        formData.append('world', newImage, newImage.name);

        const response = await this.$store.dispatch('updateWorldImage', {
          worldID: this.world?._id,
          data: formData
        })
        await this.$store.dispatch('fetchCurrentUserWorld')

        this.loadingWorldImage = false;
        // if (response.status == 201) {
        //   this.alert = { type: 'success', message: 'Avatar image updated successfully.' }
        // }
        // else {
        //   this.alert = { type: 'error', message: response?.error }
        // }
      },
    },

    mounted() {
      this.$store.dispatch('access')
    },

    created() {
    }

  }
</script>

<style scoped lang="scss">

  // /deep/ .writings-menu,
  // /deep/ .wikis-menu,
  // /deep/ .timelines-menu,
  // /deep/ .maps-menu,
  // /deep/ .languages-menu {
  //   background: bg(20%) !important;
  // }

  .creation {
    @include flex($direction: column);
    min-height: calc(100vh - #{$navigation-height} - #{$footer-height});
    padding-bottom: 4rem;
    text-align: center;
  }

  .bg-grain, .creation-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
  }

  .bg-grain {
    background-image: url('~@/assets/grain.png');
    background-size: 25px;
    background-repeat: repeat;
    mix-blend-mode: color-dodge;
  }

  .creation-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    // background-image: url('~@/assets/creation_bg.jpeg');
    // background-image: url('https://images.unsplash.com/photo-1491466424936-e304919aada7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1949&q=80');
    // background-image: linear-gradient(0deg, rgba(8,13,32,.75), rgba(8,13,32,.25)), url('https://images.unsplash.com/photo-1568607689150-17e625c1586e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80');
    // background-image: linear-gradient(0deg, rgba(8,13,32,.25), rgba(8,13,32,.75)), url('https://images.unsplash.com/photo-1650738962968-1cda273212eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80');
    background-image: linear-gradient(0deg, rgba(8,13,32,.25), rgba(8,13,32,.75)), url('https://images.unsplash.com/photo-1602917495378-afbaf25dc4fa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80');
    background-size: cover;
    background-position: center bottom;
    z-index: -1;
    mask-image: linear-gradient(180deg, rgba(0,0,0,.75), rgba(0,0,0,.5));
    // opacity: 0.5;
  }

  .creation-menu-container {
    position: relative;
    width: calc(100vw - 4rem);
    max-width: 900px;
    background-color: bg(20%);
    border-radius: 0.25rem;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
  }

  .world-image {
    position: relative;
    width: 100%;
    height: 300px;
    z-index: 0;

    .world-header-image-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0,0,0,0) 70%, rgba(0,0,0,.7));
      z-index: 2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      z-index: 1;
    }
  }

  .edit-world-image {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 3;
  }

  #worldImageFileSelect {
    display: none;
  }

  .toolbar {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: calc(100% - 2rem);
  }

  .world-visibility {
    padding: 0.5rem 1rem !important;
    font-size: 0.9rem;
  }

  .world-header-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #454649;
    overflow: hidden;

    .texture-bg {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .world-menu {
    position: relative;
    width: 100%;
    max-width: 600px;
    padding: 3rem;
    border-radius: 0.25rem;
    background-color: bg(25%);
    overflow: hidden;
    z-index: 2;
  }

  .world-header-container {
    position: relative;
    @include flex($direction: column, $justify: center);
    width: 100%;
    height: 200px;
    margin: 0 auto;
    padding: 2rem;
    padding-top: 3rem;
    background-color: bg(20%);
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.5), 0 8px 30px -12px rgba(0,0,0,.25);
    z-index: 2 !important;
  }

  .world-header {
    position: relative;
    width: 100%;
    height: 100%;

    &:hover {
      .edit-description-button {
        display: block !important;
      }
    }
  }

  .world-name {
    position: absolute !important;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;

    &:hover {
      .edit-name-button {
        display: block !important;
      }
    }
  }

  .edit-world-name {
    position: relative;
    width: 100%;
    max-width: 400px;

    input {
      padding: 1.25rem 2rem;
      font-size: 1rem;
      font-weight: 600;
    }

    .actions {
      position: absolute;
      top: -1rem;
      right: -1rem;
      width: unset;
    }
  }

  .world-description {
    position: relative;
    @include flex();
    width: 100%;
    height: 100%;

    p {
      margin: auto;
    }
  }

  .edit-world-description {
    position: relative;
    width: 100%;
    height: 100%;

    textarea {
      height: 100%;
    }

    .actions {
      position: absolute;
      top: -1rem;
      right: -1rem;
      width: unset;
    }
  }

  .edit-name-button, .edit-description-button {
    position: absolute;
    top: 0;
    right: 0;
    display: none !important;
    transform: translate(0.5rem, -0.5rem);
  }

  .members-list-button {
    margin-left: auto;
  }

  .members-list-container {
    position: absolute;
    top: 3rem;
    right: 0;
    width: 400px;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: #000;
    z-index: 3;
  }

  .members-list {
    @include flex($justify: center, $align: flex-start);
    flex-wrap: wrap;

    > * {
      margin: 1rem;
    }
  }

  .element-menu-container {
    position: relative;
    width: 100%;
    padding: 4rem 2rem;
    padding-top: 0;
  }

  .menu-container {
    position: relative;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    overflow-x: auto;
    z-index: 1;
  }

  .menu {
    position: relative;
    display: block;
    width: calc(5 * (120px + 2rem));
    margin: auto;
    vertical-align: top;

    > * {
      margin: 1rem;
    }

    &.disabled {
      opacity: 0.5;
    }

    .icon {
      position: relative;
      display: inline-flex;

      img {
        width: 100px;
        height: 100px;
      }

      h3 {
        position: absolute;
        left: 50%;
        bottom: -1rem;
        margin-top: 0.5rem;
        font-size: 0.85rem;
        font-weight: 600;
        text-transform: uppercase;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s;
        transform: translateX(-50%);
      }

      &:hover {
        cursor: pointer;

        h3 {
          opacity: 1;
        }
      }
    }
  }

  .items {
    padding: 1rem 2rem;
    background-color: #000;
  }

</style>
