<template>
  <div class="modal-container" @click.self="">
    <div class="modal">

      <h4>{{ message || 'Are you sure?' }}</h4>
      <div class="spacer"></div>
      <input v-if="input" type="text" :placeholder="input" @input="onInput" />
      <div class="spacer"></div>
      <Alert :alert="alert" @dismiss="alert = null" />

      <div v-if="buttons" class="modal-buttons">
        <Button v-for="button in buttons"
          :label="button"
          :class="button"
          :color="formatButtonColor(button)"
          @click.native="confirm(button)"
        />
      </div>
      <div v-else class="modal-buttons">
        <Button label="confirm" class="confirm" @click.native="confirm('confirm')" />
        <Button label="cancel" class="cancel" @click.native="$emit(`modal-cancel`)" />
      </div>

    </div>
  </div>
</template>


<script>
  import Button from './Button.vue';
  import Alert from './Alert.vue';

  export default {
    name: 'Modal',
    components: {
      Button,
      Alert
    },
    props: {
      message: [String],
      input: [String],
      exactMatch: [String],
      buttons: [Array]
    },
    data() {
      return {
        inputValue: '',
        alert: null
      }
    },
    methods: {
      formatButtonColor(button) {
        if (button == 'yes') return '#5edaad'
        else if (button == 'no') return '#df4b6e'
        else return null
      },

      onInput() {
        this.inputValue = event.target.value;
        this.alert = null;
      },

      confirm(button) {
        if (button !== 'ok' && button !== 'submit' && button !== 'confirm' && button !== 'yes') {
          this.$emit(`modal-${button}`);
          return;
        }

        if (!this.input) {
          this.$emit(`modal-${button}`);
          return;
        }

        if (!this.inputValue.trim()) {
          this.alert = {
            type: 'warning',
            message: 'Please enter the requested information.'
          }
          return;
        }

        if (this.exactMatch && this.inputValue != this.exactMatch) {
          this.alert = {
            type: 'warning',
            message: 'Please enter matching information.'
          }
          return;
        }

        this.$emit(`modal-${button}`, this.inputValue);
      }
    }
  }
</script>


<style scoped lang="scss">

  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    @include flex($justify: center);
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    z-index: 999;

    .modal {
      position: absolute;
      @include flex($direction: column);
      padding: 2rem;
      border-radius: 1rem;
      color: #fff;
      background-color: rgba(0,0,0,.75);
      border: 1px solid rgba(255,255,255,.25);
      z-index: 9999;

      h4 {
        font-size: 1.25rem;
        white-space: pre-wrap;
      }

      .modal-buttons {
        @include flex($justify: center);

        .confirm {
          color: #5edaad;
        }

        .cancel, .no {
          color: #df4b6e;
        }

        > *:not(:last-child) {
          margin-right: 1.5rem;
        }
      }
    }
  }

</style>
