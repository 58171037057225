<template>
  <div>
    <section>
      <article v-for="collection in collections" :key="collection.id">
        <div class="collection-info">
          <h4>{{ collection.name }}</h4>
          <p>{{ collection.lastUpdated }}</p>
        </div>
        <div class="collection-images">
          <img v-for="item in collection.items" :src="item.url" />
        </div>
      </article>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'MyCollections',
    components: {

    },
    data() {
      return {
        collections: [
          {
            id: 143123,
            name: 'Fantasy',
            lastUpdated: '5min',
            items: [
              {
                name: '??',
                url: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/6ff2ccfb-d302-4348-87bc-8b9568748e63/de56qou-f34b25f2-5fef-436e-aaeb-fed5083fff8f.jpg/v1/fill/w_730,h_1095,q_70,strp/the_long_list_anthology_cover_by_jjcanvas_de56qou-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOiIsImlzcyI6InVybjphcHA6Iiwib2JqIjpbW3siaGVpZ2h0IjoiPD0xNjAwIiwicGF0aCI6IlwvZlwvNmZmMmNjZmItZDMwMi00MzQ4LTg3YmMtOGI5NTY4NzQ4ZTYzXC9kZTU2cW91LWYzNGIyNWYyLTVmZWYtNDM2ZS1hYWViLWZlZDUwODNmZmY4Zi5qcGciLCJ3aWR0aCI6Ijw9MTA2NiJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.hoAyqmFbGNXvsQ7LLfPnn6OliyQODStt8Uac-r0Ns8A',
                tags: ''
              },
              {
                name: '??',
                url: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/6ff2ccfb-d302-4348-87bc-8b9568748e63/ddwe2tr-a286bb6a-dc25-483a-92f3-1e04556adcb1.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOiIsImlzcyI6InVybjphcHA6Iiwib2JqIjpbW3sicGF0aCI6IlwvZlwvNmZmMmNjZmItZDMwMi00MzQ4LTg3YmMtOGI5NTY4NzQ4ZTYzXC9kZHdlMnRyLWEyODZiYjZhLWRjMjUtNDgzYS05MmYzLTFlMDQ1NTZhZGNiMS5qcGcifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6ZmlsZS5kb3dubG9hZCJdfQ.ffCkg9HYicLGJPy5Gl_1G_ai-5hGvw9ZIQtRxwK8MTs',
                tags: ''
              },
              {
                name: '??',
                url: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/6ff2ccfb-d302-4348-87bc-8b9568748e63/ddbyvjt-e5b33610-aeb3-4e4e-ad07-6a3bb11e9542.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOiIsImlzcyI6InVybjphcHA6Iiwib2JqIjpbW3sicGF0aCI6IlwvZlwvNmZmMmNjZmItZDMwMi00MzQ4LTg3YmMtOGI5NTY4NzQ4ZTYzXC9kZGJ5dmp0LWU1YjMzNjEwLWFlYjMtNGU0ZS1hZDA3LTZhM2JiMTFlOTU0Mi5qcGcifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6ZmlsZS5kb3dubG9hZCJdfQ.QeViVIfBcwhxmYmgGLZ5C7ngXwsPZY6jiSiNJj0tnFc',
                tags: ''
              },
              {
                name: '??',
                url: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/6ff2ccfb-d302-4348-87bc-8b9568748e63/dd98g13-d97bf94b-c9a1-4632-8acd-cc95dc863ed8.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOiIsImlzcyI6InVybjphcHA6Iiwib2JqIjpbW3sicGF0aCI6IlwvZlwvNmZmMmNjZmItZDMwMi00MzQ4LTg3YmMtOGI5NTY4NzQ4ZTYzXC9kZDk4ZzEzLWQ5N2JmOTRiLWM5YTEtNDYzMi04YWNkLWNjOTVkYzg2M2VkOC5qcGcifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6ZmlsZS5kb3dubG9hZCJdfQ.8RIh8cSmI-58RT8QshdvMmsAZ33kDIfSU_ouANlLORg',
                tags: ''
              },
              {
                name: '??',
                url: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/6ff2ccfb-d302-4348-87bc-8b9568748e63/dcyv441-fb5dd206-cf88-4c80-afd7-dca4c738bd8a.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOiIsImlzcyI6InVybjphcHA6Iiwib2JqIjpbW3sicGF0aCI6IlwvZlwvNmZmMmNjZmItZDMwMi00MzQ4LTg3YmMtOGI5NTY4NzQ4ZTYzXC9kY3l2NDQxLWZiNWRkMjA2LWNmODgtNGM4MC1hZmQ3LWRjYTRjNzM4YmQ4YS5qcGcifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6ZmlsZS5kb3dubG9hZCJdfQ.7Y6OKnwbHQMoaX3AId1VTHJj02XltmR-NASsKVCH9kc',
                tags: ''
              },
              {
                name: '??',
                url: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/6ff2ccfb-d302-4348-87bc-8b9568748e63/dcx80l7-ed35fea4-f817-459a-a515-0e1ad716d359.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOiIsImlzcyI6InVybjphcHA6Iiwib2JqIjpbW3sicGF0aCI6IlwvZlwvNmZmMmNjZmItZDMwMi00MzQ4LTg3YmMtOGI5NTY4NzQ4ZTYzXC9kY3g4MGw3LWVkMzVmZWE0LWY4MTctNDU5YS1hNTE1LTBlMWFkNzE2ZDM1OS5qcGcifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6ZmlsZS5kb3dubG9hZCJdfQ.jOnl1QmSoYNfVYlX5v2KM9LDfkuEJbqne3_B6MESlS8',
                tags: ''
              },
              {
                name: '??',
                url: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/6ff2ccfb-d302-4348-87bc-8b9568748e63/dcnwg4y-ce471868-2bc2-47b0-be49-a62813301b2f.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOiIsImlzcyI6InVybjphcHA6Iiwib2JqIjpbW3sicGF0aCI6IlwvZlwvNmZmMmNjZmItZDMwMi00MzQ4LTg3YmMtOGI5NTY4NzQ4ZTYzXC9kY253ZzR5LWNlNDcxODY4LTJiYzItNDdiMC1iZTQ5LWE2MjgxMzMwMWIyZi5qcGcifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6ZmlsZS5kb3dubG9hZCJdfQ.ET9xmL4S2S8FVT4TGNtDerPi_stIIMdShBi5y_ZSLko',
                tags: ''
              }
            ]
          },
          {
            id: 512321,
            name: 'Sci-Fi',
            lastUpdated: '12min',
            items: [
              {
                name: '??',
                url: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/6ff2ccfb-d302-4348-87bc-8b9568748e63/d86tl7u-df44c05f-53ef-4aac-b555-2da95120a1ac.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOiIsImlzcyI6InVybjphcHA6Iiwib2JqIjpbW3sicGF0aCI6IlwvZlwvNmZmMmNjZmItZDMwMi00MzQ4LTg3YmMtOGI5NTY4NzQ4ZTYzXC9kODZ0bDd1LWRmNDRjMDVmLTUzZWYtNGFhYy1iNTU1LTJkYTk1MTIwYTFhYy5qcGcifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6ZmlsZS5kb3dubG9hZCJdfQ.z3fENK6KiBYBHgoP_o9duzsqcgtJDSMNABIDvuDPI4w',
                tags: ''
              },
              {
                name: '??',
                url: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/0936e194-a4fd-4cca-bb66-647d35090bc3/d7n8d7d-a1378ead-2a50-45b3-b111-c8f08aa9d248.jpg/v1/fill/w_1280,h_545,q_75,strp/sci_fi_city_by_emanshiu_d7n8d7d-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOiIsImlzcyI6InVybjphcHA6Iiwib2JqIjpbW3siaGVpZ2h0IjoiPD01NDUiLCJwYXRoIjoiXC9mXC8wOTM2ZTE5NC1hNGZkLTRjY2EtYmI2Ni02NDdkMzUwOTBiYzNcL2Q3bjhkN2QtYTEzNzhlYWQtMmE1MC00NWIzLWIxMTEtYzhmMDhhYTlkMjQ4LmpwZyIsIndpZHRoIjoiPD0xMjgwIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.bFOEMm8hHRCB4VbLyAsxxeY-QZk0zH7TmGfT0JALDpE',
                tags: ''
              },
              {
                name: '??',
                url: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/dcb26fe4-c664-4272-af88-2876a5f00e02/d70z4do-69406867-2aa8-4984-82e4-d7bf70e1907b.png/v1/fill/w_1024,h_410,q_80,strp/sci_fi_city_by_cat_meff_d70z4do-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOiIsImlzcyI6InVybjphcHA6Iiwib2JqIjpbW3siaGVpZ2h0IjoiPD00MTAiLCJwYXRoIjoiXC9mXC9kY2IyNmZlNC1jNjY0LTQyNzItYWY4OC0yODc2YTVmMDBlMDJcL2Q3MHo0ZG8tNjk0MDY4NjctMmFhOC00OTg0LTgyZTQtZDdiZjcwZTE5MDdiLnBuZyIsIndpZHRoIjoiPD0xMDI0In1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.21iwypibRkIVpJkZTniACj6EUJnM7RjD7SNHMdsZASc',
                tags: ''
              }
            ]
          }
        ]
      }
    }
  }
</script>

<style scoped lang="scss">

  // ::-webkit-scrollbar {
  //   height: 12px;
  // }
  // ::-webkit-scrollbar-track {
  //   background: transparent;
  // }
  // ::-webkit-scrollbar-thumb {
  //   background-color: rgba(255,255,255,.25);
  //   border-radius: 6px;
  // }

  section {
    margin: 0 4rem;
  }

  article {
    margin: 2rem 0;

    .collection-info {
      display: grid;
      grid-template-columns: auto 100px;
      padding: 2rem;

      h4 {
        color: #ccc;
        margin-right: auto;
        font-size: 1.1rem;
        font-weight: 300;
        text-align: left;
      }

      p {
        color: #aaa;
        font-size: 0.9rem;
        font-weight: 300;
        text-align: right;
      }
    }

    .collection-images {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;

      img {
        width: 200px;
        height: 200px;
        object-fit: cover;
      }
    }
  }

  article:nth-child(odd) {
    background-color: rgba(101,101,101,.05);
  }

  article:nth-child(even) {
    background-color: rgba(150,150,150,.05);
  }

  article:nth-child(odd):hover,
  article:nth-child(even):hover {
    cursor: pointer;
    background-color: rgba(255,255,255,.05);

    h4 {
      color: #fff;
    }

    p {
      color: #fff;
    }
  }

</style>
