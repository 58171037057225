<template>
  <article>
    <div class="list-item new-folder">
      <Icon name="more-arrow" size="16px" />
      <input type="text" class="title-input" @keydown="newFolderKeyDown" />
    </div>
  </article>
</template>

<script>
  export default {
    name: 'WritingCollectionNewFolder',
    components: {
    },
    props: {
      data: Object
    },
    methods: {
      newFolderKeyDown() {
        if (event.key == 'Enter') {
          let newFolder = {
            title: event.target.value,
            type: 'folder',
            parentID: this.data.parentID || null,
            authorID: this.$store.state.user._id
          }
          this.$emit('create-folder', newFolder);
        }
      }
    }
  }
</script>

<style scoped lang="scss">

  .list-item {
    position: relative;
    @include flex($justify: flex-start, $align: center);
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    user-select: none;

    &.folder, &.new-folder {
      color: #58af97;
      border-bottom: 1px solid transparent;

      .title {
        color: #58af97;
        font-size: 0.9rem;
      }

      .icon {
        margin-right: 0.5rem;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: #000;
    }

    .title {
      margin-right: 1.5rem;
      color: #fff;
      font-weight: 600;
    }
    .content {
      color: #aaa;
      font-size: 0.9rem;
    }
    .last-updated {
      margin-left: auto;
      font-size: 0.75rem;
      color: #5fc3da;
    }
  }

  article:not(:last-child) {
    border-bottom: 1px solid #333;
  }

</style>
