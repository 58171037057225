<template>
  <div class="color-picker-container">
    <div class="color-picker">
      <input type="color" :value="color || '#ffffff'"
        @input="(e) => $emit('color-picked', e.target.value)" />
    </div>
    <p>{{ label }}</p>
  </div>
</template>

<script>
  export default {
    name: 'ColorPicker',
    props: {
      color: [String],
      label: [String]
    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped lang="scss">

  .color-picker-container {
    position: relative;
    @include flex();

    p {
      margin-left: 0.75rem;
    }
  }

  .color-picker {
    position: relative;
    width: 1.75rem;
    height: 1.75rem;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid transparent;
    overflow: hidden;
    transition: border 0.15s;

    &:hover {
      cursor: pointer;
      border: 1px solid rgba(255,255,255,.5);
    }

    input[type="color"] {
      position: absolute;
      top: -2rem;
      left: -2rem;
      width: 6rem;
      height: 6rem;
    }
  }

</style>
