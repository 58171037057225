<template>
  <section class="subforum">

    <div class="subforum-toolbar">
      <Button label="New Thread" icon="plus" iconSize="20px"
              :class="showNewThread ? 'disabled' : ''" @click.native="openNewThread" />
    </div>

    <NewThread v-if="showNewThread" @submit-new-thread="submitNewThread" @cancel-new-thread="cancelNewThread" />

    <Alert :alert="alert" @dismiss="alert = null" />


    <div class="subforum-table">
      <header>
        <h3>Thread Name</h3>
        <h3>Posts</h3>
        <h3>Views</h3>
        <h3>Last Active</h3>
      </header>

      <article v-for="thread in subforum.threads" @click="$emit('select-thread', thread._id)" :key="thread._id">
        <item-layout>
          <h4>{{ thread.title }}</h4>
          <p>{{ thread.originalPosterID }}</p>
        </item-layout>
        <h4>{{ thread.numPosts }}</h4>
        <p>{{ thread.views }}</p>
        <p>{{ formatDateDelta(thread.lastUpdated) }} ago</p>
      </article>
    </div>

  </section>
</template>

<script>
  import NewThread from './NewThread.vue';

  export default {
    name: 'Subforum',
    components: {
      NewThread
    },
    props: {
      subforum: Object
    },
    data() {
      return {
        showNewThread: false,
        alert: null
      }
    },
    methods: {
      openNewThread() {
        this.alert = null;
        this.showNewThread = true;
      },
      submitNewThread(response) {
        this.alert = { type: response.type, content: response.message };
        if (response.type === 'success') {
          this.showNewThread = false;
          this.$emit('refresh-subforum');
        }
      },
      cancelNewThread() {
        this.showNewThread = false;
      }
    }
  }
</script>

<style scoped lang="scss">

  .subforum {
    position: relative;
    @include flex($direction: column);
    width: $forum-width;
    max-width: $forum-max-width;
    margin: auto;
    border-radius: 1rem;
  }

  .subforum-toolbar {
    @include flex($direction: row, $justify: flex-end);
    width: 100%;
    margin-bottom: 1rem;

    > * {
      position: relative;
      display: inline-flex;
    }
  }

  .subforum-table {
    width: 100%;
    margin: 2rem 0;
    border-radius: 1rem;
    overflow: hidden;
  }

  article {
    display: grid;
    grid-template-columns: auto 100px 100px 100px;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;

    item-layout {
      @include flex($direction: column, $align: flex-start);
    }

    h4 {
      color: #fff;
      margin-right: auto;
      font-size: 1.1rem;
      font-weight: 300;
      text-align: left;
    }

    p {
      color: #aaa;
      font-size: 0.9rem;
      font-weight: 300;
    }

    > *:not(:first-child) {
      width: 100%;
      text-align: center;
    }

    &:nth-child(odd) {
      background-color: $subforum-odd-bg-color;

    }

    &:nth-child(even) {
      background-color: $subforum-even-bg-color;
    }

    &:nth-child(odd):hover,
    &:nth-child(even):hover {
      cursor: pointer;
      background-color: $subforum-bg-color-hover;

      h4 {
        color: #fff;
      }

      p {
        color: #fff;
      }
    }
  }

  header {
    display: grid;
    grid-template-columns: auto 100px 100px 100px;
    width: 100%;
    padding: 1.5rem 2rem;
    box-sizing: border-box;
    background-color: $subforum-header-bg-color;

    h3 {
      width: 100%;
      color: #ccc;
      font-size: 0.9rem;
      font-weight: 800;

      &:not(:first-child) {
        text-align: center;
      }
    }

  }

</style>
