<template>
  <div class="text-suggestion">
    <p v-for="(suggestion, index) in suggestions" @mousedown="$emit('select-suggestion', index)">{{ suggestion }}</p>
  </div>
</template>

<script>
  export default {
    name: 'TextSuggestion',
    props: {
      suggestions: [Array]
    }
  }
</script>

<style scoped lang="scss">

  .text-suggestion {
    position: absolute;
    @include flex($direction: column, $align: flex-start);
    left: 0;
    width: 100%;
    border-radius: 0.5rem;
    box-sizing: border-box;
    background-color: rgba(0,0,0,.5);
    font-family: 'Quicksand', Avenir, sans-serif;
    font-size: 1rem;
    text-align: left;
    z-index: 3;
    transition: all 0.3s;

    p {
      padding: 1rem;
      color: #fff;

      &:hover {
        cursor: pointer;
        color: $input-color;
      }
    }
  }

</style>
