<template>
  <div v-if="text"
    :class="`message ${type || 'neutral'}`"
  >
    <Icon :name="switchIcon" size="20px" />
    <p>{{ text }}</p>
  </div>
</template>


<script>
  export default {
    name: 'Alert',
    props: {
      text: [String],
      type: [String]
    },
    computed: {
      switchIcon() {
        switch (this.type) {
          case 'success':
            return 'check';
          case 'error':
            return 'no';
          case 'warning':
            return 'exclamation';
          case 'danger':
            return 'exclamation';
          default:
            return '';
        }
      }
    }
  }
</script>


<style scoped lang="scss">

  .message {
    position: relative;
    @include flex();
    margin: 1rem;
    padding: 0.75rem 1.25rem;
    border-radius: 0.5rem;
    color: #def;
    border: 1px solid #def;
    user-select: none;
    transition: all 0.15s;

    .icon:first-child {
      margin-right: 1rem;
    }

    .icon:last-child {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      margin-left: 1rem;
      opacity: 0.75;
    }

    p {
      font-size: 0.9rem;
      opacity: 0.75;
      white-space: pre-line;
    }

    &.success {
      color: $alert-success-color;
      border-color: rgba($alert-success-color, 0.5);
      p { color: $alert-success-color; }
    }

    &.warning {
      color: $alert-warning-color;
      border-color: rgba($alert-warning-color, 0.5);
      p { color: $alert-warning-color; }
    }

    &.error, &.danger {
      color: $alert-error-color;
      border-color: rgba($alert-error-color, 0.5);
      p { color: $alert-error-color; }
    }
  }

</style>
