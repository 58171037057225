<template>
  <div>

    <Loader v-if="loading" />

    <template v-else>
      <div class="spacer"></div>
      <h2>Login History</h2>
      <div class="spacer"></div>

      <div class="login-history">
        <div v-for="login in logins" class="login floating">
          <div class="location">
            <p>{{ location(login) }}</p>
            <!-- <h5>{{ ip(login) }}</h5> -->
          </div>
          <p>{{ date(login) }}</p>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
  export default {
    name: 'LoginHistory',

    data() {
      return {
        loading: true
      }
    },

    computed: {
      logins() {
        return this.$store.state.user?.loginHistory?.reverse() || [];
      }
    },

    methods: {
      ip(login) {
        return login?.location?.ip || ''
      },
      location(login) {
        return login?.location ?
          `${login.location.city}, ${login.location.country_name}` : ''
      },
      date(login) {
        return this.formatDate(login.date, '&weekday, &month &dd, &year | &hour:&min')
      }
    },

    created() {
      this.$store.dispatch('fetchLoginHistory').then(response => {
        this.loading = false;
      })
    }
  }
</script>

<style scoped lang="scss">

  .login-history {
    @include flex($direction: column);
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
    padding: 0 3rem;
  }

  .login {
    @include flex($justify: space-between);
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem 1.5rem;
    border-radius: 0.25rem;
    background-color: bg(20%);

    > p {
      color: neutral(70%);
      font-size: 0.9rem;
    }
  }

  .location {
    @include flex($direction: column, $align: flex-start);

    p {
      color: $colorA;
    }
    h5 {
      color: $colorB;
      font-weight: 300;
    }
  }

</style>
