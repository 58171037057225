<template>
  <div class="page wiki-page-container">

    <ImmersiveBackground
      :youtubeHTML="youtubeHTML"
      :isYoutubeLocked="isYoutubeLocked"
      :backgroundImage="backgroundImage"
      :overlayColor="overlayColor"
      :overlayOpacity="overlayOpacity"
      @toggle-lock="isYoutubeLocked = !isYoutubeLocked" />

    <BackButton
      @click.native="$router.back()"
    />

    <Loader v-if="!data" />

    <div v-if="data" class="wiki-page floating">
      <div class="wiki-bg"></div>

      <div class="images">
        <div class="image-scroll-container">
          <div v-for="(image, index) in data.images" class="image-container">
            <img :src="publicImageURL('wiki', image)" />
          </div>
        </div>
      </div>

      <div class="header">
        <h1>{{ data.title }}</h1>
        <p>{{ data.subtitle }}</p>
      </div>

      <div class="fields floating">
        <div v-if="data.headerFields" class="field-item"
          v-for="item in data.headerFields"
        >
          <span>{{ item.field }}</span>
          <p>{{ item.value }}</p>
        </div>
      </div>

      <div class="body">
        <section v-for="(section, index) in data.sections">
          <h2>{{ section.title }}</h2>
          <p>{{ section.content }}</p>
        </section>
      </div>
    </div>

  </div>
</template>



<script>
  import ImmersiveBackground from '../Creation/ImmersiveBackground.vue';
  import exampleWorld from '@/components/World/example_world'

  export default {
    name: 'Wiki',
    components: {
      ImmersiveBackground
    },
    data() {
      return {
        youtubeHTML: null,
        isYoutubeLocked: false,
        overlayColor: '#414145',
        overlayOpacity: 0.5
      }
    },
    computed: {
      data() {
        return exampleWorld?.wikis?.[this.$route.params.id];
      },

      backgroundImage() {
        if (!this.data) return;
        return this.data.backgroundImage || 'https://media.scrybe-app.com/assets/mountains_01.jpg';
      }
    }
  }
</script>



<style scoped lang="scss">

  .wiki-page-container {
    position: relative;
    @include flex($direction: column);
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 1rem 5rem;
    padding-bottom: 2rem;
    box-sizing: border-box;
  }

  .back-button {
    top: 2rem;
    left: 2rem;
  }

  .wiki-page {
    position: relative;
    display: grid;
    grid-template-areas:
      "images images"
      "header fields"
      "body body"
      "footer footer";
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem 2rem;
    width: 100%;
    max-width: 1080px;
    margin-top: 6rem;
    padding: 2rem 3rem;
    border-radius: 0.5rem;
    background-color: rgba(0,0,0,.35);
    // backdrop-filter: blur(8px);
    transform: translate3d(0,0,0);
    will-change: transform;

    > * {
      flex-grow: 1;
    }

    .header {
      grid-area: header;
      /* width: 100%; */

      h1, p {
        text-align: left;
      }
      h1 {
        margin-bottom: 1rem;
        color: $colorA;
      }
      p {
        font-size: 0.9rem;
      }
      textarea {
        resize: vertical;
      }
    }
    .body {
      position: relative;
      grid-area: body;

      section {
        position: relative;
        padding-top: 1rem;
        padding-bottom: 3rem;

        &.dragging:last-child {
          position: fixed;
          max-width: calc(100vw - 10rem);
          padding: 0 5rem;
          z-index: -99;
        }

        &.placeholder {
          opacity: 0.2;
        }

        h2 {
          margin-bottom: 0.5rem;
          font-size: 1.25rem;
          color: $colorC;
          text-transform: uppercase;
        }

        textarea {
          resize: vertical;
        }
      }
    }
  }

  .body section, .header, .fields {
    position: relative;
    &:hover {
      .buttons {
        opacity: 1;
      }
    }
  }

  p {
    white-space: pre-line;
  }

  .images {
    position: relative;
    grid-area: images;
    grid-column: 1 / -1;
    width: 100%;
    margin: auto;

    .image-scroll-container {
      position: relative;
      @include flex();
      overflow-x: auto;

      .image-container {
        position: relative;
        height: 200px;
        width: 200px;
        object-fit: cover;
        flex-shrink: 0;

        img {
          height: 100%;
          width: 100%;
        }

        .delete-button {
          position: absolute;
          top: 0.25rem;
          right: 0.25rem;
          opacity: 0;
          color: #ccc;
          background-color: rgba(0,0,0,.5);

          &:hover {
            color: #fff;
            background-color: rgba(0,0,0,1);
          }
        }

        &:hover {
          .delete-button {
            opacity: 1;
          }
        }
      }
    }
  }

  .fields {
    grid-area: fields;
    display: table;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background-color: bg(15%);

    .field-item {
      display: table-row;
      // @include grid($columns: 1fr 1fr, $gap: 1rem);

      * {
        display: table-cell;
      }

      p {
        font-size: 1rem;
        text-align: left;
      }
      span {
        color: $colorC;
        text-align: right;
      }
    }

    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

</style>
