
import { api } from '@/utility.js'


export const forumMutations = {

  changeSubforum(state, subforum) {
    state.forum.currentSubforum.name = subforum;
  },

  loadSubforumData(state, data) {
    state.forum.currentSubforum = data;
  },

  clearThreadData(state) {
    state.forum.currentThread = {
      title: null,
      posts: null
    };
  },

  loadThreadData(state, data) {
    state.forum.currentThread = data;
  }

}


export const forumActions = {

  async fetchSubforumData({ commit, state }, subforum) {
    let response = await api('GET', 'forum/' + subforum);
    if (response.status === 200 && response.data) {
      commit('loadSubforumData', response.data);
    }
    return response;
  },

  async fetchThreadData({ commit, state }, threadID) {
    commit('clearThreadData');
    let response = await api('GET', `forum/${state.forum.currentSubforum.name}/${threadID}`);
    if (response.status === 200 && response.data) {
      commit('loadThreadData', response.data);
    }
    return response;
  },

  async submitNewThread({ commit, state }, params) {
    let response = await api('POST', `forum/${state.forum.currentSubforum.name}/newthread`, params);
    return response;
  },

  async submitNewForumPost({ commit, state }, params) {
    let response = await api('POST', `forum/${state.forum.currentSubforum.name}/${state.forum.currentThread._id}/newpost`, params);
    return response;
  },

}
