import { render, staticRenderFns } from "./AsyncImage.vue?vue&type=template&id=6b4b239c&scoped=true"
import script from "./AsyncImage.vue?vue&type=script&lang=js"
export * from "./AsyncImage.vue?vue&type=script&lang=js"
import style0 from "./AsyncImage.vue?vue&type=style&index=0&id=6b4b239c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b4b239c",
  null
  
)

export default component.exports