<template>
  <div class="featured-worlds">

    <Billboard
      title="Featured Worlds"
      :height="450"
      :slideWidth="400"
    >
      <!-- <template #1>
        <div class="slide-content slide-1"></div>
      </template>
      <template #2>
        <div class="slide-content slide-2"></div>
      </template>
      <template #3>
        <div class="slide-content slide-3"></div>
      </template>
      <template #4>
        <div class="slide-content slide-4"></div>
      </template>
      <template #5>
        <div class="slide-content slide-5"></div>
      </template> -->

      <template #1>
        <WorldPreview
          @click.native="$router.push('/world/example')"
        />
      </template>
      <template #2>
        <WorldPreview
          worldID="60eebcaba489906664df3d6b"
          @click.native="$router.push('/world/60eebcaba489906664df3d6b')"
        />
      </template>
      <template #3>
        <WorldPreview
          @click.native="$router.push('/world/example')"
        />
      </template>
      <template #4>
        <WorldPreview
          @click.native="$router.push('/world/example')"
        />
      </template>
      <template #5>
        <WorldPreview
          @click.native="$router.push('/world/example')"
        />
      </template>
    </Billboard>

  </div>
</template>


<script>
  import WorldPreview from '../World/WorldPreview.vue'

  export default {
    name: 'FeaturedWorlds',
    components: {
      WorldPreview
    }
  }
</script>


<style scoped lang="scss">

  .featured-worlds {
    width: 100%;
  }

  .title {
    font-size: 1.5rem !important;
  }

  .slide-content {
    position: relative;
    @include flex($justify: center);
    width: 400px;
    height: 300px;
    margin: 0.5rem;

    p {
      font-size: 3rem;
      font-weight: 600;
    }
  }

  .slide-1 {
    background-color: #8b34e6;
  }

  .slide-2 {
    background-color: #e6b934;
  }

  .slide-3 {
    background-color: #349be6;
  }

  .slide-4 {
    background-color: #34e665;
  }

  .slide-5 {
    background-color: #e64434;
  }

</style>
