<template>
  <div class="page">

    <h3>Language</h3>

    <Hint
      title="Coming Soon"
      icon="more-arrow"
      style="position: absolute; right: 2rem;"
      origin="left bottom"
    >
      <h4 class="title">Coming Soon</h4>
      <div class="spacer h1"></div>
      <ul>
        <li>Words</li>
        <li>Writing System</li>
      </ul>
    </Hint>

    <BackButton
      style="top: 0; left: 0;"
      @click.native="$router.back()"
    />

    <div class="settings-grid" v-if="language">
      <p :class="newName != language.title ? 'changed' : ''">Name</p>
      <input type="text" :value="newName" @input="(e) => { this.settingsAlert = null; newName = e.target.value; }" />
    </div>

    <div class="spacer"></div>

    <CoolButton type="compact"
      label="Update" animation="infinite"
      textColor="#66f1d0" bgColor="rgb(102,241,208)"
      @click.native="updateSettings" />

    <Alert :alert="settingsAlert" @dismiss="settingsAlert = null" />

    <div class="spacer h5"></div>
    <h4>Stems</h4>
    <div class="spacer"></div>

    <Table :highlightedIndex="0"
      :headers="headers" :rows="stems"
      @cell-changed="stemChanged"
      @add="addStem"
      @delete="deleteStem" />

    <div class="spacer"></div>
    <Alert :alert="stemsAlert" @dismiss="stemsAlert = null" />
    <div class="spacer"></div>

    <div class="spacer h5"></div>

    <!-- <section>
      <article v-for="(stem, index) in stems">
        <input type="text" @keydown="stemsKeyPress(index)" @input="stemsStemInput(index)" :value="stem.stem" />
        <input type="text" @keydown="stemsKeyPress(index)" @input="stemsMeaningInput(index)" :value="stem.meaning" />
      </article>
    </section>

    <h4>Words</h4>

    <section>
      <article v-for="(word, wordIndex) in words" :key="wordIndex" class="language-word">
        <div class="word-node">
          <fake-input class="word-fake-input" @click="focusLastNode(wordIndex, 0)">
            <span v-for="(node, nodeIndex) in word.word" :key="nodeIndex"
              :class="`${node.type === 'stem' ? 'word-stem' : ''}`"
              contenteditable="true"
              @click.prevent="() => {}"
              @focus="setFocus(wordIndex, 0)"
              @blur="removeFocus"
              @keydown="wordsKeyPress(wordIndex, nodeIndex, node.content)"
              @input="wordsWordInput(wordIndex, nodeIndex)"
              @mouseover="stemMouseOver"
              @mouseout="stemMouseOut"
              :v-html.sync="node.content"></span>
          </fake-input>
          <TextSuggestion v-if="currentFocus[0] === wordIndex && currentFocus[1] === 0"
            :suggestions="stemSuggestions" @select-suggestion="suggestionSelected" />
        </div>
        <div class="word-node">
          <input type="text" @keydown="wordsKeyPress(wordIndex)" @input="wordsMeaningInput(wordIndex)" :value="word.meaning" />
          <TextSuggestion v-if="currentFocus[0] === wordIndex && currentFocus[1] === 1"
            :suggestions="stemSuggestions" @select-suggestion="suggestionSelected" />
        </div>
      </article>
    </section>

    <div id="string-size-calculator"></div>
    <div id="stem-string-size-calculator"></div>

    <WordHover v-if="wordHover.visible" :x="wordHover.x" :y="wordHover.y" :word="wordHover.word" :meanings="wordHover.meanings" />

    <DraftSaved :lastInput="lastInput" emitName="save-stems" @save-stems="saveStems()" /> -->
  </div>
</template>

<script>
  import TextSuggestion from './TextSuggestion.vue';
  import WordHover from './WordHover.vue';

  export default {
    name: 'Language',
    components: {
      TextSuggestion,
      WordHover,
    },
    data() {
      return {
        headers: [
          { label: 'Stem', value: 'stem', input: 'text' },
          { label: 'Meaning', value: 'meaning', input: 'text' },
          { label: 'Type', value: 'type', input: 'select', options: [
              { value: 'noun', label: 'Noun', color: '#6fa5f5' },
              { value: 'verb', label: 'Verb', color: '#fede6e' },
              { value: 'adjective', label: 'Adjective', color: '#fb6f6f' },
              { value: 'other', label: 'Other', color: '#6ffb81' },
            ]
          }
        ],

        stemsAlert: null,

        newName: '',
        settingsAlert: null,


        // stems: [{
        //   stem: '',
        //   meaning: ''
        // }],
        // words: [{
        //   word: [{
        //     type: 'text',
        //     content: ''
        //   }],
        //   meaning: ['']
        // }],
        // lastInput: null,
        // stemSuggestions: [],
        // hideSuggestions: false,
        // currentFocus: [null, null], // [index, stem/meaning]
        // wordHover: {
        //   visible: false,
        //   x: 0,
        //   y: 0,
        //   word: '',
        //   meanings: ['']
        // }
      }
    },
    computed: {
      language() {
        if (!this.$store.state.languages || !this.$store.state.languages[this.$route.params.id]) return;
        return this.$store.state.languages[this.$route.params.id];
      },
      stems() {
        return this.language ? this.language.stems : null;
      }
    },
    methods: {
      addStem() {
        let newStem = {
          stem: '',
          meaning: '',
          type: 'noun',
          formatter: this.stemFormatter
        };

        this.$store.dispatch('addStem', {
          languageID: this.$route.params.id,
          stem: newStem
        });
        // this.$store.dispatch('');
      },

      stemChanged(stem) {
        this.$store.dispatch('updateStem', {
          languageID: this.$route.params.id,
          stem
        })
      },

      deleteStem(index) {
        this.$store.dispatch('deleteStem', {
          languageID: this.$route.params.id,
          index
        }).then(response => {
          if (response.status === 201) {
            this.stemsAlert = { type: 'success', message: 'Deleted successfully.' }
          }
          else {
            this.stemsAlert = { type: 'error', message: response.data.error }
          }
        });;
      },

      stemFormatter(header, value) {
        if (header === 'type') {
          switch (value) {
            case 'Noun':
              return 'color: #85d3ff;';
            case 'Verb':
              return 'color: #ffa834;';
            default:
              return '';
          }
        }
      },

      updateSettings() {
        if (this.newName == this.language.title) {
          this.settingsAlert = { type: 'warning', message: 'No changes made.' }
          return;
        }

        this.$store.dispatch('updateLanguage', {
          languageID: this.$route.params.id,
          title: this.newName
        }).then(response => {
          if (response.status === 201) {
            this.settingsAlert = { type: 'success', message: 'Updated successfully.' }
          }
          else {
            this.settingsAlert = { type: 'error', message: response.data.error }
          }
        });
      }



      // stemsKeyPress(index) {
      //   if (event.key === 'Enter') {
      //     let newStem = {
      //       stem: '',
      //       meaning: ''
      //     };
      //     this.stems.splice(index + 1, 0, newStem);
      //   }
      // },
      // stemsStemInput(index) {
      //   this.stems[index].stem = event.target.value;
      //   this.lastInput = Date.now();
      // },
      //
      // stemsMeaningInput(index) {
      //   this.stems[index].meaning = event.target.value;
      //   this.lastInput = Date.now();
      // },
      //
      //
      //
      // wordsKeyPress(wordIndex, nodeIndex, content) {
      //   if (event.key === 'Enter') {
      //     event.preventDefault();
      //     let newWord = {
      //       word: '',
      //       meaning: ''
      //     };
      //     this.words.splice(wordIndex + 1, 0, newWord);
      //     return;
      //   }
      // },
      //
      // wordsWordInput(wordIndex, nodeIndex) {
      //   let text = event.target.innerText;
      //
      //   this.currentFocus = [wordIndex, 0];
      //   this.words[wordIndex].word[nodeIndex].content = text;
      //   this.stemsAutocomplete(text);
      //
      //   // this.lastInput = Date.now();
      // },
      //
      // wordsMeaningInput(index) {
      //   this.currentFocus = [index, 1];
      //   this.words[index].meaning = event.target.innerText;
      //   this.stemsAutocomplete(event.target.innerText);
      //
      //   // this.lastInput = Date.now();
      // },
      //
      // setFocus(index, wordOrMeaning) {
      //   this.currentFocus = [index, wordOrMeaning];
      //   let text = event.target.innerText;
      //   this.stemsAutocomplete(text);
      // },
      //
      // removeFocus() {
      //   this.currentFocus = [null, null];
      // },
      //
      // focusLastNode(wordIndex, wordOrMeaning) {
      //   if (event && event.target.className === '') {
      //     return;
      //   }
      //
      //   const selectedArticle = document.getElementsByClassName('language-word')[wordIndex];
      //   const nodeElements = selectedArticle.children[wordOrMeaning].children[0].children;
      //   nodeElements[nodeElements.length - 1].focus();
      // },
      //
      // stemMouseOver() {
      //   this.wordHover.visible = true;
      //   this.wordHover.x = event.x;
      //   this.wordHover.y = event.y;
      //   this.wordHover.word = event.target.innerText;
      //
      //   let meanings = this.stems.filter(stem => stem.stem === event.target.innerText);
      //   if (!meanings || !meanings.length) this.wordHover.meanings = ['(no meaning found)'];
      //   else {
      //     this.wordHover.meanings = meanings.map(meaning => meaning.meaning);
      //   }
      // },
      //
      // stemMouseOut() {
      //   this.wordHover.visible = false;
      // },
      //
      // stemsAutocomplete(input) {
      //   if (!input.trim()) {
      //     this.stemSuggestions = [];
      //     return;
      //   }
      //
      //   let words = input.split(' ');
      //   let currentWord = words[words.length - 1];
      //
      //   if (!currentWord) {
      //     this.stemSuggestions = [];
      //     return;
      //   }
      //
      //   let suggestions = [];
      //
      //   this.stems.forEach(stem => {
      //     if (stem.stem.startsWith(currentWord)) {
      //       suggestions.push(stem.stem);
      //     }
      //   });
      //
      //   this.stemSuggestions = suggestions;
      // },
      //
      // suggestionSelected(selectedStemIndex) {
      //   event.preventDefault();
      //
      //   const wordIndex = this.currentFocus[0];
      //   const wordOrMeaning = this.currentFocus[1] === 0 ? 'word' : 'meaning';
      //
      //   const selectedWord = this.stemSuggestions[selectedStemIndex];
      //   const selectedArticle = document.getElementsByClassName('language-word')[wordIndex];
      //
      //   let focusedInput = this.words[wordIndex][wordOrMeaning];
      //   console.log("focusedInput:", [...focusedInput]);
      //   let lastNodeIndex = focusedInput.length - 1;
      //   let currentNode = focusedInput[lastNodeIndex];
      //   let words = currentNode.content.split(' ');
      //   words.pop();
      //
      //   if (words.length) {
      //     let revertedText = words.join(' ') + ' ';
      //     this.words[wordIndex][wordOrMeaning][lastNodeIndex].content = revertedText;
      //     // this.$set(this.words[wordIndex][wordOrMeaning][lastNodeIndex], 'content', revertedText);
      //   }
      //   else {
      //     this.words[wordIndex][wordOrMeaning].pop();
      //   }
      //
      //   let stemNode = {
      //     type: 'stem',
      //     content: selectedWord
      //   }
      //   let newNode = {
      //     type: 'text',
      //     content: ''
      //   }
      //
      //   // let nodes = [...this.words[wordIndex][wordOrMeaning]];
      //   // nodes.push(stemNode);
      //   // nodes.push(newNode);
      //
      //   this.words[wordIndex][wordOrMeaning].push(stemNode);
      //   this.words[wordIndex][wordOrMeaning].push(newNode);
      //   // this.$set(this.words[wordIndex], wordOrMeaning, nodes);
      //
      //   console.log("nodes:", this.words[wordIndex][wordOrMeaning]);
      //
      //   setTimeout((currentFocus) => {
      //     this.renderWordNodes(currentFocus[0], currentFocus[1]);
      //     this.focusLastNode(currentFocus[0], currentFocus[1]);
      //   }, 50, this.currentFocus);
      //
      //   const selectedElement = selectedArticle.children[this.currentFocus[1]].children[0];
      //   const stemNodeElement = selectedElement.children[selectedElement.children.length - 2];
      //
      //   selectedElement.children[selectedElement.children.length - 1].focus();
      // },
      //
      // renderWordNodes(wordIndex, wordOrMeaning) {
      //   let wordOrMeaningText = wordOrMeaning === 0 ? 'word' : 'meaning';
      //
      //   const nodes = this.words[wordIndex][wordOrMeaningText];
      //
      //   const selectedArticle = document.getElementsByClassName('language-word')[wordIndex];
      //   let nodeElements = selectedArticle.children[wordOrMeaning].children[0].children;
      //
      //   console.log("nodes:", nodes);
      //   console.log("element children:", nodeElements);
      //
      //   for (let i = 0; i < nodes.length; i++) {
      //     nodeElements[i].innerHTML = nodes[i].content;
      //   }
      // },
      //
      //
      // saveStems() {
      //   let saveStemsString = '';
      //   this.stems.forEach(stem => {
      //     if (!stem.stem.trim() && !stem.meaning.trim()) return;
      //     saveStemsString += stem.stem + '%@' + stem.meaning + '\n';
      //   });
      //
      //   window.localStorage.setItem('language-stems', saveStemsString);
      // },
      //
      // loadStems() {
      //   let loadStemsString = window.localStorage.getItem('language-stems');
      //   if (!loadStemsString) return;
      //
      //   let stems = loadStemsString.split('\n');
      //   if (!stems || !stems.length) return;
      //
      //   this.stems = [];
      //   stems.forEach(stem => {
      //     if (!stem.trim()) return;
      //     let s = stem.split('%@');
      //     let loadedStem = {
      //       stem: s[0],
      //       meaning: s[1]
      //     }
      //     this.stems.push(loadedStem);
      //   });
      // }
    },

    created() {
      this.$store.dispatch('fetchLanguage', this.$route.params.id).then(response => {
        this.newName = this.language.title;
      })
    }
  }
</script>

<style scoped lang="scss">

  h3 {
    margin-top: 0.5em;
    margin-bottom: 3rem;
    text-transform: uppercase;
  }

  .settings-grid {
    @include grid($columns: 1fr 3fr, $gap: 1.5rem);
    width: 60%;
    max-width: 600px;

    > *:nth-child(odd) {
      color: $colorA;
      font-size: 0.9rem;
      font-weight: 600;
      text-align: right;
    }
    > *:nth-child(even) {
      text-align: left;
    }

    .changed::before {
      content: '';
      display: inline-block;
      margin-bottom: calc(0.5em - 4px);
      margin-right: 1rem;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: $colorC;
    }
  }

  section {
    width: 100%;
    margin: 1rem 0;
    padding: 1rem;

    article {
      position: relative;
      @include grid($columns: 1fr 1fr, $gap: 0.5rem, $align: flex-start);

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      .word-node {
        position: relative;
      }
    }
  }

  .stem-inputs {
    @include flex();
    width: 100%;

    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .word-fake-input {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    span {
      white-space: pre-wrap;
    }

    span:only-child {
      width: 100%;
    }
  }

</style>


<style lang="scss">

  .word-stem {
    color: $stem-color;
    font-weight: bold;

    &:focus {
      color: $stem-color;
    }
  }

</style>
