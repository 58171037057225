<template>
  <div class="page">

    <div class="spacer"></div>
    <div v-if="$store.state.user" class="row buttons-row">
      <Button
        label="Back"
        icon="left-arrow"
        class="inline"
        style="margin-right: auto;"
        @click.native="$router.back()"
      />
    </div>


    <Loader v-if="loading" />


    <template v-else-if="!world">
      <Message
        v-if="isMyWorld"
        type="warning"
        text="Your world is private, so it this is how it will appear to everyone else."
      />
      <Message
        v-if="error"
        type="error"
        :text="error"
      />
    </template>


    <template v-else>
      <div class="world-container floating">

        <div class="header floating texture-bg fantasy">

          <div class="world-header-image">
            <div class="world-header-image-overlay"></div>
            <img :src="headerImage" />
          </div>

          <div class="title-container">
            <h1 class="title floating">{{ world.name }}</h1>
          </div>

          <div class="description">
            <TutorialHint :step="1" origin="top center">
              <p>This is the world header, which displays an overview of your world. The name, description, and image can all be customized at any time.</p>
            </TutorialHint>

            <p>{{ world.description }}</p>

            <div class="row" style="margin-top: 2rem;">
              <!-- <CoolButton
                :label="isFollowing ? 'Unfollow' : 'Follow'"
                :icon="isFollowing ? 'x' : 'plus'"
                type="compact"
                animation="infinite"
                :textColor="isFollowing ? '#ffb0b9' : '#66f1d0'"
                :bgColor="isFollowing ? 'rgb(231,78,78)' : 'rgb(102,241,208)'"
                @click.native="toggleFollow"
              /> -->
              <Button v-if="!isAuthor"
                :label="isFollowing ? 'Unfollow' : 'Follow'"
                :icon="isFollowing ? 'x' : 'plus'"
                type="compact"
                :class="isFollowing ? 'delete' : 'primary'"
                @click.native="isAuthor ? '' : toggleFollow"
              />
            </div>

            <Alert :alert="followAlert" @dismiss="followAlert = null" />
          </div>

        </div>



        <div class="world">,
          <TutorialHint :step="2" origin="top center">
            <p>Below are all the elements of the world. The author of the world can decide which individual elements are shown here for public viewing, and which are saved privately.</p>
          </TutorialHint>


          <div class="maps">
            <TutorialHint :step="3" origin="top left">
              <p>Here you can view all the maps of this world. These interactive maps let you visualize the space in the world and explore points of interest marked on the map. Click on any map to open it.</p>
            </TutorialHint>

            <div class="element-title">
              <img src="https://media.scrybe-app.com/assets/elements/map_icon.png" />
              <h2>Maps</h2>
            </div>
            <p v-if="!maps.length" class="hint">This world has no maps.</p>
            <div class="map-items">
              <div v-for="map in maps"
                class="item"
                @click="$router.push('/map/' + map._id)"
              >
                <p>{{ map.title }}</p>
                <img :src="publicImageURL('map', map.image, world.authorID)" />
              </div>
            </div>
          </div>

          <div class="wikis">
            <TutorialHint :step="4" origin="top left">
              <p>Listed here are all the wiki pages for this world. Wikis allow you to organize information relevant to a particular thing: a person, object, idea, etc. Click on any wiki to view the full page.</p>
            </TutorialHint>

            <div class="element-title">
              <img src="https://media.scrybe-app.com/assets/elements/wiki_icon.png" />
              <h2>Wikis</h2>
            </div>
            <p v-if="!wikis.length" class="hint">This world has no wiki pages.</p>
            <div v-for="wiki in wikis"
              class="item"
              @click="$router.push('/wiki/' + wiki._id)">
              <Icon :name="wikiIcon(wiki)" />
              <p>{{ wiki.title }}</p>
            </div>
          </div>

          <div class="writings">
            <TutorialHint :step="5" origin="top left">
              <p>Writings are your story. Rough drafts, snippets, scene ideas, or full chapters. The author can customize the style and background of their writings to set the tone and give a little immersion to each scene.</p>
            </TutorialHint>

            <div class="element-title">
              <img src="https://media.scrybe-app.com/assets/elements/writing_icon.png" />
              <h2>Writings</h2>
            </div>
            <p v-if="!writings.length" class="hint">This world has no writings.</p>
            <div v-for="writing in writings"
              class="item"
              @click="$router.push('/writing/' + writing._id)">
              <p>{{ writing.title }}</p>
            </div>
          </div>

          <div class="timelines">
            <TutorialHint :step="6" origin="top left">
              <p>Timelines allow you to organize events across time. Whether it's the lore of your world's creation or the main plot of your story, here you can interact with your world across time.</p>
            </TutorialHint>

            <div class="element-title">
              <img src="https://media.scrybe-app.com/assets/elements/timeline_icon.png" />
              <h2>Timelines</h2>
            </div>
            <p v-if="!timelines.length" class="hint">This world has no timelines.</p>
            <div v-for="timeline in timelines"
              class="item"
              @click="$router.push('/timeline/' + timeline._id)">
              <p>{{ timeline.title }}</p>
            </div>
          </div>

          <div class="languages">
            <TutorialHint :step="7" origin="top left">
              <h6 class="coming-soon">COMING SOON</h6>
              <div class="spacer h1"></div>
              <p>Creating a language is a complex and difficult task, but when done well can add a realism to any world. Here you can create words, word roots, grammar rules, and even your own writing system.</p>
            </TutorialHint>

            <div class="element-title">
              <img src="https://media.scrybe-app.com/assets/elements/empty_icon.png" />
              <h2>Languages</h2>
            </div>
            <p v-if="!languages.length" class="hint">This world has no languages.</p>
            <div v-for="language in languages"
              class="item"
              @click="$router.push('/language/' + language._id)">
              <p>{{ language.title }}</p>
            </div>
          </div>

        </div>
      </div>

    </template>

    <div class="spacer h5"></div>

  </div>
</template>

<script>
  import CreationItem from './CreationItem.vue'
  import exampleWorld from './example_world'

  console.log('exampleWorld', exampleWorld);

  export default {
    name: 'World',
    components: {
      CreationItem
    },

    data() {
      return {
        loading: true,
        error: null,
        worldData: null,
        followAlert: null
      }
    },

    computed: {
      userWorlds() {
        return this.$store.state.user?.worlds || []
      },
      isMyWorld() {
        const world = this.userWorlds.find(world => world._id == this.$route.params.id)
        return !!world
      },

      world() {
        return this.$route.params.id == 'example' ?
          exampleWorld :
          this.worldData
      },
      isAuthor() {
        return this.world?.authorID == this.$store.state.user?._id
      },
      headerImage() {
        return this.world?.headerImage ?
          this.publicImageURL('world', this.world?.headerImage, this.world?.authorID) :
          'https://media.scrybe-app.com/assets/mountains_01.jpg'
      },

      writings() {
        return this.world?.writings || []
      },
      wikis() {
        return this.world?.wikis || []
      },
      timelines() {
        return this.world?.timelines || []
      },
      maps() {
        return this.world?.maps || []
      },
      languages() {
        return this.world?.languages || []
      },

      isFollowing() {
        if (this.$store.state.user?.following?.worlds?.find(id => id == this.$route.params.id)) {
          return true;
        }
        return false;
      }
    },

    methods: {
      wikiIcon(wiki) {
        if (wiki.category === 'characters') {
          return 'wizard';
        }
        else if (wiki.category === 'places') {
          return 'mountain';
        }
      },

      toggleFollow() {
        this.$store.dispatch('toggleFollowWorld', this.$route.params.id).then(response => {
          if (response.status == 200) {
            this.followAlert = {
              type: 'success',
              message: response?.data?.message
            }
          }
          else {
            this.followAlert = {
              type: 'error',
              message: response?.data?.error
            }
          }
        })
      }
    },

    mounted() {
      if (this.$route.params.id == 'example') {
        this.$store.dispatch('access')
      }
    },

    created() {
      if (this.$route.params.id == 'example') {
        this.loading = false
      }
      else {
        this.$store.dispatch('fetchWorld', this.$route.params.id).then(response => {
          if (response.status == 200) {
            this.worldData = response.data
          }
          else {
            this.error = response?.data?.error
          }
          this.loading = false
        })
      }
    }
  }
</script>

<style scoped lang="scss">

  @import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Qwigley&display=swap');

  .buttons-row {
    max-width: 900px;
  }

  .world-container {
    position: relative;
    width: calc(100vw - 4rem);
    max-width: 900px;
    margin: 2rem auto;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .header {
    position: relative;
    width: 100%;
    background-color: #454649;
    z-index: 2;
  }

  .world-header-image {
    position: relative;
    width: 100%;
    height: 300px;
    overflow: hidden;
    z-index: 5;

    .world-header-image-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0,0,0,0) 70%, rgba(0,0,0,.7));
      z-index: 6;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      z-index: 5;
    }
  }

  .title-container {
    position: relative;
    z-index: 9;
  }

  .title {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    padding: 1.5rem;
    box-sizing: border-box;
    font-family: 'Cinzel Decorative', cursive;
    font-weight: 400;
    font-size: 1.75rem;
    color: $colorD;
    text-transform: uppercase;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      box-shadow: inset 0 0 0 2px $colorD;
    }

    &::after {
      content: '';
      position: absolute;
      top: 45%;
      left: 0;
      width: 100%;
      height: 55%;
      box-sizing: border-box;
      background-color: darken($colorD, 60%);
      mix-blend-mode: multiply;
      opacity: 0.65;
    }

  }

  .description {
    position: relative;
    width: 100%;
    padding: 2.5rem;
    padding-top: 5rem;
    text-align: center;
    white-space: pre-wrap;
    font-size: 0.9rem;
  }

  .world {
    position: relative;
    display: grid;
    grid-template:
      "maps timelines timelines"
      "maps languages languages"
      "wikis wikis wikis"
      "writings writings writings";
    grid-gap: 1rem 1rem;
    padding: 2rem;
    background-color: bg(18%);

    h2 {
      line-height: 48px;
      margin-bottom: 1rem;
      font-family: 'Qwigley', cursive;
      font-weight: 400;
      font-size: 2rem;
      color: $colorD;
    }
  }



  .element-title {
    @include flex();
    margin-bottom: 1rem;

    img {
      width: 48px;
      height: 48px;
      margin-right: 0.5rem;
    }

    h2 {
      margin: 0;
    }
  }

  .item {
    margin: 0.25rem 0;
    padding: 1rem;
    background-color: bg(10%);

    &:hover {
      cursor: pointer;
      background-color: bg(5%);

      p {
        color: $colorA;
      }
    }
  }

  .maps {
    position: relative;
    grid-area: maps;

    .map-items {
      @include flex($align: flex-start);
      flex-wrap: wrap;

      .item {
        margin-top: 0;

        p {
          margin-bottom: 0.5rem;
          text-align: center;
        }
        img {
          width: 160px;
          height: 160px;
        }
      }
    }
  }

  .wikis {
    position: relative;
    grid-area: wikis;

    .item {
      @include flex();

      .icon {
        color: $colorB;
        margin-right: 0.5rem;
      }
    }
  }

  .writings {
    position: relative;
    grid-area: writings;
  }

  .timelines {
    position: relative;
    grid-area: timelines;
  }

  .languages {
    position: relative;
    grid-area: languages;
  }



  .menu-container {
    position: relative;
    width: 100%;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    overflow-x: auto;
    z-index: 1;
  }

  .menu {
    position: relative;
    display: block;
    width: calc(5 * (120px + 2rem));
    margin: auto;
    vertical-align: top;

    > * {
      margin: 1rem;
    }

    .icon {
      position: relative;
      display: inline-flex;

      img {
        width: 120px;
        height: 120px;
      }

      h3 {
        position: absolute;
        left: 50%;
        bottom: -1rem;
        margin-top: 0.5rem;
        font-size: 0.85rem;
        font-weight: 600;
        text-transform: uppercase;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s;
        transform: translateX(-50%);
      }

      &:hover {
        cursor: pointer;

        h3 {
          opacity: 1;
        }
      }
    }
  }

  .coming-soon {
    color: $colorD;
  }

</style>
