
module.exports = {

  encryptionKey: 'idontcareifyouknowthis321',

  STRIPE: {
    TEST: {
      PUBLIC_KEY: 'pk_test_51Jz6B3AZnrsNLlS51XclGicFfKDjik1rUY0tpcoMoKXK8zF5MsFCBatpBHo8YC5OFD0YbAj8e9SkMzYqWMVSpJBc00lrt7cXoI'
    },
    LIVE: {
      PUBLIC_KEY: 'pk_live_51Jz6B3AZnrsNLlS5Pz2n2Y8ElGr97edabnlmWAW73ydoDazArmoSc41HOXujw0aUk05Ilkgz0J4KiJ9LpH5qYfKp00IccvKLqC'
    }
  }

}
