<template>
  <div class="page support">

    <div class="spacer"></div>
    <h1 class="colorful-title">{{ formatTitle }}</h1>

    <div class="spacer"></div>


    <template v-if="reportType == null">
      <div class="spacer"></div>
      <div class="feedback-text floating">
        <h4>Scrybe is still in its infancy.</h4>
        <br />
        <p>After almost 8 months in Beta, we have just launched our official release. However, there are many features that have not yet been added.</p>
        <br />
        <p>We have big plans for Scrybe, and have a full roadmap of exciting features never before seen on any other worldbuilding or creativity platform. But before we go ahead with our own plans, we want to make sure we're listening to you, the user.</p>
        <br />
        <p>We would love if you could share your thoughts on what's missing, what we're doing well, or how things could be better.</p>
      </div>
      <div class="spacer h3"></div>

      <div class="column">
        <Button
          icon=""
          label="Report A Problem"
          class="secondary inline"
          @click.native="selectType('problem')"
        />
        <div class="spacer h1"></div>
        <Button
          icon=""
          label="Leave A Review"
          class="secondary inline"
          @click.native="selectType('feedback')"
        />
        <div class="spacer h1"></div>
        <Button
          icon=""
          label="I Have A Question"
          class="secondary inline"
          @click.native="selectType('question')"
        />
      </div>
    </template>



    <div class="row button-row">
      <Button v-if="reportType != null"
        label="Back"
        icon="left-arrow"
        class="back-button"
        @click.native="reportType = null"
      />
    </div>

    <div class="guidelines" v-if="reportType === 'problem'">
      <p>When reporting a bug or problem, please include the following details:</p>
      <ol>
        <li>The website URL where the problem occurred</li>
        <p>Ex: https://scrybe-app.com/#/login</p>
        <li>What you were doing when the problem occurred</li>
        <p>Ex: I typed my message and clicked the "Post" button</p>
        <li>Any error messages that were displayed</li>
        <p>Ex: An error message said "Problem adding post."</p>
        <p>Ex: An error box appeared but it just said "{}"</p>
      </ol>
      <p><span class="highlight">Important:</span>If you could also attach any screenshots, it might help us locate the problem faster.</p>
      <!-- <ol>
        <li>The entire web page</li>
        <li>The javascript console</li>
      </ol>
      <p>The javascript console can be accessed using the following key commands:</p>
      <div class="key-commands">
        <section>
          <h5>Mac</h5>
          <p>Chrome: <span class="key">⌘ Cmd</span> + <span class="key">⌥ Option</span> + <span class="key">J</span></p>
          <p>Safari: <span class="key">⌘ Cmd</span> + <span class="key">⌥ Option</span> + <span class="key">I</span></p>
        </section>
        <section>
          <h5>Windows</h5>
          <p>Chrome: <span class="key">Ctrl</span> + <span class="key">Alt</span> + <span class="key">J</span></p>
          <p>Safari: <span class="key">Ctrl</span> + <span class="key">Alt</span> + <span class="key">I</span></p>
        </section>
      </div>
      <p>And it should look like this:</p>
      <div class="spacer"></div>
      <img class="example-image" src="@/assets/console_example.png" /> -->
      <div class="spacer h3"></div>
    </div>

    <div v-if="reportType != null" class="inputs">
      <input type="text" placeholder="Subject" :value="subject" @input="(e) => subject = e.target.value" />
      <div class="spacer h1"></div>
      <textarea placeholder="Message" :value="content" @input="(e) => content = e.target.value" />
    </div>

    <div class="spacer"></div>

    <div v-if="reportType == 'problem'" class="screenshots">
      <h4>Attach Screenshots</h4>
      <div class="screenshot-previews">

        <div v-for="(preview, index) in screenshotPreviews" class="screenshot-image"
          @click="removeScreenshot(index)">
          <img :src="preview" />
          <Icon name="x" size="1.5rem" />
        </div>

        <div class="add-screenshot-box" @click="openImageSelect" >
          <Icon name="plus" size="1.5rem" />
        </div>

        <input type="file" multiple name="screenshots" id="screenshotFileSelect" @change="selectImage" />
      </div>
    </div>

    <div class="spacer"></div>

    <CoolButton v-if="reportType != null" label="Submit" type="compact" animation="infinite"
      textColor="#66f1d0" bgColor="rgb(102,241,208)"
      @click.native="submit" />

    <div class="spacer"></div>

    <Alert :alert="alert" @dismiss="alert = null" />

    <div class="spacer h5"></div>
    <div class="spacer h5"></div>

  </div>
</template>

<script>
  export default {
    name: 'Support',

    data() {
      return {
        reportType: null,
        subject: '',
        content: '',
        screenshots: [],
        screenshotPreviews: [],
        alert: null
      }
    },

    computed: {
      user() {
        return this.$store.state.user
      },
      formatTitle() {
        switch (this.reportType) {
          case 'problem':
          return 'Report A Problem';
          case 'feedback':
          return 'Leave A Review';
          case 'question':
          return 'I Have A Question';
          default:
          return 'Submit Feedback';
        }
      }
    },

    methods: {
      selectType(type) {
        if (!this.$store.state.user) this.$router.push('/login');
        else this.reportType = type;
      },

      openImageSelect() {
        let fileSelect = document.getElementById('screenshotFileSelect');
        fileSelect.click();
      },

      selectImage() {
        event.target.files.forEach(file => {
          this.screenshots.push(file);
          this.loadImageFile(file);
        });
      },

      loadImageFile(imageFile) {
        let fileReader = new FileReader();
        fileReader.onload = () => this.screenshotPreviews.push(fileReader.result);
        fileReader.readAsDataURL(imageFile);
      },

      removeScreenshot(index) {
        this.screenshots.splice(index, 1);
        this.screenshotPreviews.splice(index, 1);
      },

      submit() {
        console.log("screenshots:", [...this.screenshots]);
        if (!this.subject.trim() || !this.content.trim()) {
          this.alert = {
            type: 'warning',
            message: 'Please fill in all fields.'
          }
          return;
        }

        let formData = new FormData();
        formData.append('type', this.reportType);
        formData.append('subject', this.subject);
        formData.append('content', this.content);
        formData.append('userID', this.$store.state.user?._id || null);
        if (this.screenshots.length) this.screenshots.forEach(image => {
          formData.append('screenshots', image, image.name);
        });

        this.$store.dispatch('submitReport', formData).then(response => {
          console.log(response);
          if (response.status === 201) {
            let message = 'Support ticket submitted successfully.\nThank you for helping us improve this platform!';
            if (this.reportType == 'feedback') {
              message = 'Feedback submitted successfully.\nThank you for helping us improve this platform!';
            }
            if (this.reportType == 'question') {
              message = 'Question submitted successfully.\nWe\'ll get back to you shortly via email.';
            }

            this.alert = {
              type: 'success',
              message: message
            }

            this.subject = '';
            this.content = '';
            this.screenshots = [];
            this.screenshotPreviews = [];
          }
          else {
            this.alert = {
              type: 'error',
              message: 'Problem submitting report.'
            }
          }
        })
      }
    },

    mounted() {
      this.$store.dispatch('access')
    },

    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (!vm.$store.state.user) {
          vm.$router.push('/login')
        }
      })
    }
  }
</script>

<style scoped lang="scss">

  .feedback-text {
    max-width: 720px;
    padding: 2rem 3rem;
    border-radius: 0.5rem;
    background-color: bg(20%);
    font-size: 0.9rem;
    text-align: center;

    h4 {
      color: $colorA;
      font-size: 1.1rem;
    }
  }

  .inputs {
    width: 100%;
    max-width: 600px;

    textarea {
      height: 12rem;
    }
  }

  .button-row {
    width: 100%;
    max-width: 600px;

    .back-button {
      margin-right: auto;
      margin-bottom: 2rem;
    }
  }

  .guidelines {
    width: 100%;
    max-width: 600px;
  }

  .example-image {
    max-width: 600px;
  }

  .highlight {
    margin-right: 0.5rem;
    color: $colorB;
    font-weight: 600;
  }

  li {
    margin: 0.5rem 0;
    color: $colorA;
  }

  ol p {
    margin: 0.25rem 0;
    margin-left: 1rem;
    color: $colorC;
    font-size: 0.9rem;
  }

  .key-commands {
    @include flex($justify: center);

    section {
      padding: 1rem;
      h5 {
        margin-bottom: 0.75rem;
      }
      p {
        margin: 0.25rem 0;
        color: $colorC;
        font-size: 0.9rem;
      }
    }
  }

  span.key {
    padding: 0.35rem 0.5rem;
    border-radius: 0.375rem;
    font-size: 0.75rem;
    line-height: 2.5em;
    background-color: rgba(0,0,0,.25);
    border: 1px solid rgba(255,255,255,.1);
  }

  .example-image {
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 8px 20px -12px rgba(0,0,0,.5), 0 20px 40px -30px rgba(0,0,0,.75);
  }


  .screenshots {
    text-align: center;

    h4 {
      margin-bottom: 1rem;
      color: $colorB;
      font-size: 0.9rem;
      text-transform: uppercase;
    }
  }

  .screenshot-previews {
    @include flex($justify: center);
    max-width: 680px;
    flex-wrap: wrap;
  }

  .screenshot-image, .add-screenshot-box {
    width: 120px;
    height: 120px;
    margin: 1rem;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .screenshot-image img {
    width: 100%;
    height: 100%;
    transition: opacity 0.15s;

    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }

  .screenshot-image {
    @include flex($justify: center);
    .icon {
      position: absolute;
      pointer-events: none;
      color: #ec4e4e;
      opacity: 0;
      transition: opacity 0.15s;
      filter: drop-shadow(0 0 16px #000);
    }

    &:hover {
      .icon {
        opacity: 1;
      }
    }
  }

  .add-screenshot-box {
    @include flex($justify: center);
    color: #999;
    border: 1px solid #999;
    transition: color 0.15s, border 0.15s;

    &:hover {
      cursor: pointer;
      color: $colorA;
      border: 1px solid $colorA;
    }
  }

  #screenshotFileSelect {
    display: none;
  }

</style>
