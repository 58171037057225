
import Vue from 'vue'
import { api, apiFile } from '@/utility.js'


export const timelineMutations = {

  loadTimelines(state, timelines) {
    let stateTimelines = {};
    timelines.forEach(timeline => {
      stateTimelines[timeline._id] = timeline;
    });
    state.timelines = stateTimelines;
  },

  loadTimeline(state, timeline) {
    if (!state.timelines) state.timelines = {};
    Vue.set(state.timelines, [timeline._id], timeline);
  },

  renameTimeline(state, data) {
    let index = state.world.timelines.findIndex(timeline => timeline._id == data.timelineID)
    state.world.timelines[index].title = data.title;
  },

  deleteTimeline(state, data) {
    let newTimelines = state.world.timelines.filter(timeline => timeline._id != data.timelineID)
    state.world.timelines = newTimelines;
  },


  updateTimelineLines(state, data) {
    state.timelines[data.timelineID].lines = data.lines;
  },

  updateTimelineNodes(state, data) {
    const lineIndex = state.timelines[data.timelineID].lines.findIndex(line => line._id == data.lineID);
    state.timelines[data.timelineID].lines[lineIndex].nodes = data.nodes;
  },

  updateTimelineZones(state, data) {
    state.timelines[data.timelineID].zones = data.zones;
  },

  addNode(state, data) {
    let newLines = state.timelines[data.timelineID].lines;
    const lineIndex = newLines.findIndex(line => line._id == data.lineID);
    if (!newLines[lineIndex].nodes) {
      newLines[lineIndex].nodes = [];
    }
    newLines[lineIndex].nodes.push(data.node);
    state.timelines[data.timelineID].lines = newLines;
  },

  deleteNode(state, data) {
    let newLevels = [...state.timelines[data.timelineID].levels];
    newLevels[data.levelIndex].nodes.splice(data.nodeIndex, 1);
    state.timelines[data.timelineID].levels = newLevels;
  },

}


export const timelineActions = {

  async fetchWorldTimelines({ commit, state }) {
    let response = await api('POST', 'timeline/timelines', { worldID: state.user?.currentWorldID });
    if (response.status === 200 && response.data?.length) {
      commit('loadTimelines', response.data);
    }
    return response;
  },

  async fetchTimeline({ commit, state }, id) {
    let response =  await api('GET', 'timeline/' + id);
    if (response.status === 200 && response.data) {
      commit('loadTimeline', response.data);
    }
    return response;
  },

  async newTimeline({ commit, state }, timeline) {
    return await api('POST', 'timeline/newTimeline', timeline);
  },

  async renameTimeline({ commit, state }, data) {
    return await api('PUT', 'timeline/rename', data);
  },

  async deleteTimeline({ commit, state }, data) {
    return await api('POST', 'timeline/delete', data);
  },

  async updateTimelineLines({ commit, state }, data) {
    commit('updateTimelineLines', data);
    return await api('PUT', 'timeline/updateLines', data);
  },

  async updateTimelineNodes({ commit, state }, data) {
    commit('updateTimelineNodes', data);
    return await api('PUT', 'timeline/updateNodes', data);
  },

  async updateTimelineZones({ commit, state }, data) {
    commit('updateTimelineZones', data);
    return await api('PUT', 'timeline/updateZones', data);
  },

  async uploadTimelinePointImage({ commit, state }, data) {
    return await apiFile('POST', 'timeline/uploadPointImage', data);
  },

  async deleteTimelinePointImage({ commit, state }, data) {
    return await api('POST', 'timeline/deletePointImage', data);
  },

  async addTimelineLink({ commit, state }, data) {
    return await api('POST', 'timeline/addLink', data);
  },

  async removeTimelineLink({ commit, state }, data) {
    return await api('POST', 'timeline/removeLink', data);
  },

  // async deleteNode({ commit, state }, data) {
  //   commit('deleteNode', data);
  //   return await api('PUT', `timeline/${data.timelineID}/updateLevels/`, state.displayWorld.timelines[data.timelineID].levels);
  // },

  async updateTimeline({ commit, state }, params) {
    let response = await api('PUT', `timeline/${params.timelineID}/update/`, params);
    commit('loadTimeline', response.data);
    return response;
  },

}
