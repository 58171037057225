<template>
  <div class="page">
    <div class="discover">

      <div class="discover-toolbar">
        <TabSelect
          :tabs="['discover', 'following']"
          @select="(tab) => mode = tab"
        />
        <Button
          label="New Post"
          icon="plus"
          color="#fff"
          class="primary"
          @click.native="clickNewPost" />
      </div>

      <Alert :alert="alert" @dismiss="alert = null" />

      <NewPost
        v-if="showNewPost"
        type="normal"
        @success="newPostSuccess"
        @close="showNewPost = false" />

      <Post
        v-for="post in posts"
        :post="post"
        :canReply="true"
        @open-reading-room="() => {}"
        @delete="deletePost"
        :key="post._id"
      />

      <template v-if="isLoading">
        <Post v-for="index in limit" :post="null" :key="index" />
      </template>

      <template v-else>
        <p v-if="!posts.length">There are no posts.</p>
      </template>

    </div>
  </div>
</template>

<script>
  import Post from './Posts/Post.vue';
  import NewPost from './Posts/NewPost.vue';

  export default {
    name: 'Feed',
    components: {
      Post,
      NewPost,
    },

    watch: {
      mode: function(newMode) {
        this.resetPosts()
        this.fetchPosts()
      }
    },

    computed: {
      total() {
        return this.$store.state[this.mode].total || 0
      },

      posts() {
        console.log('replies', this.$store.state[this.mode].posts.map(post => post.replies?.length));
        return this.$store.state[this.mode].posts || []
      },

      pagination() {
        return {
          skip: this.page * this.limit,
          limit: this.limit
        }
      }
    },

    data() {
      return {
        mode: 'discover',

        isLoading: false,
        page: 0,
        limit: 3,

        showNewPost: false,
        alert: null
      }
    },

    methods: {
      resetPosts() {
        this.$store.commit('clearAllPosts')
        this.page = 0
      },

      fetchPosts() {
        if (!this.total || this.posts.length < this.total) {
          this.isLoading = true
          let action = this.mode == 'discover' ? 'fetchDiscoverPosts' : 'fetchFollowingPosts'
          this.$store.dispatch(action, this.pagination).then(response => {
            this.isLoading = false;
          });
        }
      },

      onScroll() {
        const FETCH_DISTANCE = 300;

        const maxScroll = document.body.scrollHeight - window.innerHeight;

        if (!this.isLoading && window.scrollY >= maxScroll - FETCH_DISTANCE) {
          this.page++
          this.fetchPosts()
        }
      },

      clickNewPost() {
        if (!this.$store.state.user) this.$router.push('/login');
        else this.showNewPost = true;
      },

      newPostSuccess() {
        this.alert = {
          type: 'success',
          message: 'New post added.'
        }
        this.showNewPost = false;
      },

      deletePost(id) {
        this.$store.dispatch('deletePost', id).then(response => {
          if (response.status === 201) {
            this.alert = {
              type: 'success',
              message: 'Post deleted.'
            }
          }
        });
      }
    },

    created() {
      this.fetchPosts()
    },

    mounted() {
      window.addEventListener('scroll', this.onScroll);
    },

    destroyed() {
      window.addEventListener('scroll', this.onScroll);
    }

  }
</script>

<style scoped lang="scss">

  .discover {
    width: 100%;
    max-width: 900px;
    padding: 0 2rem;
    box-sizing: border-box;
  }

  .scrollLock {
    overflow: hidden;
  }

  .discover-toolbar {
    @include flex($justify: space-between);
    margin-bottom: 2rem;
  }

</style>
