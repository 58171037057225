
import { api, apiFile } from '@/utility.js'


export const wikiMutations = {

  loadWikiPages(state, wikiPages) {
    let stateWikiPages = {};
    wikiPages.forEach(page => {
      stateWikiPages[page._id] = page;
    });
    state.wikiPages = stateWikiPages;
  },

  updateWikiInStore(state, updatedWikiPage) {
    state.wikiPages[updatedWikiPage._id] = updatedWikiPage;
  },

  renameWiki(state, data) {
    let index = state.world.wikis.findIndex(wiki => wiki._id == data.wikiID)
    state.world.wikis[index].title = data.title;
  },

  deleteWiki(state, data) {
    let newWikis = state.world.wikis.filter(wiki => wiki._id != data.wikiID)
    state.world.wikis = newWikis;
  },

}


export const wikiActions = {

  async fetchWikis({ commit, state }) {
    let response = await api('GET', 'creation/wikis');
    if (response.status === 200 && response.data && response.data.length) {
      commit('loadWikiPages', response.data);
    }
    return response;
  },

  async fetchWiki({ commit, state }, id) {
    let response = await api('GET', 'creation/wiki/' + id);
    // if (response.status === 200 && response.data) {
    //   commit('loadWikiPages', response.data);
    // }
    return response;
  },

  async newWiki({ commit, state }, wiki) {
    return await api('POST', 'creation/newWiki', wiki);
  },

  async updateWiki({ commit, state }, params) {
    return await api('PUT', 'creation/updateWiki/' + params.wikiID, params.pageParams);
  },

  async uploadWikiImage({ commit, state }, params) {
    return await apiFile('POST', 'creation/uploadWikiImage/' + params.wikiID, params.image);
  },

  async deleteWikiImage({ commit, state }, params) {
    return await api('POST', 'creation/deleteWikiImage', params);
  },

  async renameWiki({ commit, state }, data) {
    return await api('PUT', 'wiki/rename', data);
  },

  async deleteWiki({ commit, state }, data) {
    return await api('POST', 'wiki/delete', data);
  },

}
