<template>
  <div class="map-page">
    <Loader v-if="loading" class="loading" />

    <div class="page-center">
      <Message :text="error" type="error" />
    </div>

    <template v-if="!loading && !error">

      <BackButton
        style="z-index: 12;"
        @click.native="comingFromNew ? $router.push('/creation') : $router.back()"
      />

      <!-- <Hint
        title="Coming Soon"
        icon="less-arrow"
        style="position: absolute; bottom: 2rem; left: 50%; transform: translateX(-50%); z-index: 99;"
        origin="top"
      >
        <ul>
          <li>Wiki Link and Images</li>
          <li>Areas</li>
          <li>Layers</li>
          <li>Paths</li>
        </ul>
      </Hint> -->

      <div class="toolbar-row">
        <div class="center row">
          <Button
            label="Help"
            icon="question"
            class="help"
            @click.native="helpVisible = true"
          />
        </div>
        <div class="right row spaced">
          <Button v-if="isAuthor"
            label="New Point"
            icon="plus"
            :class="newPointModeOn ? 'primary' : ''"
            @click.native.stop="newPointModeOn = !newPointModeOn"
          />
          <Button v-if="isAuthor"
            label="Reposition"
            icon="pointer"
            :class="repositioning ? 'primary' : ''"
            @click.native.stop="toggleRepositioning"
          />
          <Button
            :icon="isFilterListOpen ? 'less-arrow' : 'more-arrow'"
            size="1.25rem"
            label="Filters"
            @click.native="isFilterListOpen = !isFilterListOpen"
          />
          <Button
            icon="left-arrow" size="1.25rem"
            label="Show List"
            @click.native="isPointListOpen = true"
          />
          <div class="zoom">
            <Button icon="plus" @click.native="changeZoom(0.1)" />
            <Button icon="minus" @click.native="changeZoom(-0.1)" />
          </div>
        </div>
      </div>

      <div v-if="isFilterListOpen" class="filters">
        <h5>By Icon</h5>
        <div class="icon-list">
          <Icon
            v-for="icon in iconList"
            :name="icon.icon" size="1rem"
            :class="isFilterIconSelected(icon.icon) ? 'selected' : ''"
            :key="icon"
            @click.native="toggleIconFilter(icon.icon)"
          />
        </div>
      </div>


      <div
        v-if="dimensions"
        id="map-container"
      >
        <div
          id="map"
          :style="mapImageTransform"
          @touchstart.stop="onMouseDown"
          @mousedown.stop="onMouseDown"
          @touchcancel.stop="stopDrag"
          @touchend.stop="stopDrag"
          @mouseup.stop="stopDrag"
          @mouseleave.stop="stopDrag"
          @touchmove="onCursorMove"
          @mouseenter="onCursorMove"
          @mousemove="onCursorMove"
        >

          <img id="map-image"
            :src="mapImage" />

          <!-- <div class="map-focus" :style="`left: ${currentFocus.x * 100}%; top: ${currentFocus.y * 100}%;`"></div> -->

          <template
            v-if="isPointVisible(point)"
            v-for="point in points"
          >
            <div
              class="map-point"
              :class="(hoveredPoint && hoveredPoint._id == point._id) ? 'highlighted' : ''"
              :style="pointTransform(point)"
              :key="point._id"
              @mouseenter="onMouseEnterMarker(point)"
              @mouseleave="onMouseLeaveMarker"
              @mousedown.bubble="clickMarker(point)"
            >
              <Icon
                class="marker"
                :name="point.icon"
                size="24px"
                :style="markerTransform(point)"
              />
              <Icon
                class="marker-outline"
                :name="point.icon"
                size="32px"
                :style="markerOutlineTransform(point)"
              />
              <div
                class="shadow"
                :style="markerTransform(point)"
              ></div>
            </div>

            <div
              v-if="point.links && point.links.length"
              class="links"
              :style="infoTransform(point)"
            >
              <ElementLink
                v-for="link in point.links"
                :element="link.type"
                :id="link.id"
                :key="link.id"
              />
            </div>
          </template>


          <div
            v-if="selectedPoint"
            class="point-info-container"
            :style="infoTransform(selectedPoint)"
          >
            <div
              v-if="!editing"
              class="point-info"
            >
              <div
                v-if="loadingImage == selectedPoint._id"
                class="image-loader"
              >
                <Loader size="60px" class="loader-center" />
              </div>
              <template v-else>
                <img
                  v-if="selectedPoint.image"
                  :src="publicImageURL('map_point', selectedPoint.image.name, map.authorID)"
                />
              </template>

              <h4>{{ selectedPoint.name }}</h4>
              <p>{{ selectedPoint.description }}</p>
              <h6 v-if="isAuthor">{{ repositioning ? 'Click and drag to reposition' : 'Click to edit' }}</h6>
            </div>


            <div
              v-if="isAuthor && editing"
              class="editing-point-info"
              @mousedown.stop=""
            >
              <div class="row">
                <ColorPicker
                  :color="selectedPoint.color"
                  @color-picked="(color) => changeColor(color, selectedPoint)" />
                <Select
                  class="icon-dropdown"
                  :value="selectedPoint.icon"
                  :options="iconListWithColor"
                  optionsStyle="display: flex; justify-content: center; padding: 1.25rem 1rem;"
                  @select="(icon) => changeIcon(icon, selectedPoint)"
                  @wheel.native.stop=""
                  @mousedown.stop="" />
                <Button
                  icon="delete"
                  class="delete"
                  @click.native="deletePointModalVisible = true"
                />
              </div>

              <div class="point-image">
                <div
                  v-if="loadingImage == selectedPoint._id"
                  class="image"
                >
                  <Loader size="60px" class="loader-center" />
                </div>

                <div
                  v-else-if="selectedPoint.image"
                  class="image"
                  @click="clickDeleteImage(selectedPoint)"
                >
                  <img :src="publicImageURL('map_point', selectedPoint.image.name, map.authorID)" />
                  <Icon name="x" size="1.5rem" />
                </div>

                <div
                  v-else
                  class="add-image-box"
                  @click="openPointImageSelect"
                >
                  <Icon name="plus" size="1.5rem" />
                </div>
                <input
                  type="file"
                  name="map_point"
                  id="pointImageFileSelect"
                  @change="uploadPointImage(selectedPoint)"
                />
              </div>

              <input
                :value="selectedPoint.name"
                @change="(e) => changeName(e, selectedPoint)"
                @mousedown.stop=""
              />
              <input
                :value="selectedPoint.description"
                @change="(e) => changeDescription(e, selectedPoint)"
                @mousedown.stop=""
              />

              <div class="spacer h1"></div>
              <h4>Link Other Elements</h4>
              <ElementLinkInput
                @select="(element) => addLink(selectedPoint, element)"
              />

              <template v-if="selectedPoint.links">
                <div
                  v-for="link in selectedPoint.links"
                  class="row spaced space-between"
                >
                  <ElementLink
                    :element="link.type"
                    :id="link.id"
                    :key="link.id"
                  />
                  <Button
                    icon="delete"
                    class="delete"
                    @click.native="removeLink(selectedPoint, link)"
                  />
                </div>
              </template>

              <div class="row spaced">
                <Button label="Done"
                  icon="check"
                  type="inline"
                  class="primary"
                  @click.native="doneEditing"
                />
                <Button label="Cancel"
                  icon="x"
                  type="inline"
                  @click.native="cancelEditing"
                />
              </div>
            </div>
          </div>

        </div>
      </div>



      <div :class="`point-list-container ${preventTransition ? 'no-transition' : ''} ${isPointListOpen ? 'open' : ''}`">
        <div class="row" style="justify-content: space-between;">
          <Button class="close-point-list"
            icon="right-arrow" size="1.25rem"
            label="Hide List"
            @click.native="isPointListOpen = false"
          />
          <!-- <Button class="primary"
            icon="plus" size="1.25rem"
            label="New Point"
            @click.native="isPointListOpen = false"
          /> -->
        </div>
        <div class="spacer h1"></div>

        <div class="point-list" @wheel.stop="">
          <div v-for="point in points" class="item row"
            @click="selectListPoint(point)"
            @mouseenter="onMouseEnterListPoint(point)"
            @mouseleave="onMouseLeaveListPoint(point)"
          >
            <Icon :name="point.icon" size="1rem" :style="`color: ${point.color};`" />
            <div class="column">
              <p class="name">{{ point.name }}</p>
              <p class="description">{{ point.description }}</p>
            </div>
          </div>
        </div>
      </div>


      <ModalContainer v-if="deletingPointImage">
        <p>Delete this image?</p>
        <Message type="warning" text="This cannot be undone."/>
        <div class="row spaced">
          <Button
            label="Yes"
            class="primary"
            @click.native="deletePointImage"
          />
          <Button
            label="No"
            class="delete"
            @click.native="deletingPointImage = null"
          />
        </div>
      </ModalContainer>


      <ModalContainer v-if="helpVisible" class="help">
        <h3 class="help-title">Maps Guide</h3>
        <div class="spacer"></div>
        <p class="help-header">Navigating the Map</p>
        <p>Zoom in and out by scrolling up and down with a touchpad or mouse, or pinching with two fingers on a touch screen device. Click (or touch) and drag to move around the map.</p>
        <div class="spacer"></div>
        <p class="help-header">Show List of Points</p>
        <p>Click on the
          <Button label="Show List" icon="left-arrow" class="inline compact" />
          button in the toolbar at the top to show the list of all interactive points on the map.
        </p>
        <div class="spacer"></div>
        <p class="help-header">Add Points</p>
        <p>Click on the
          <Button label="New Point" icon="plus" class="inline compact" />
          button in the toolbar at the top to activate New Point Mode. When the button is highlighted
          <Button label="New Point" icon="plus" class="inline compact primary" />
          click (or touch) anywhere on the map to place a point. Click the button again to deactivate it when finished.
        </p>
        <div class="spacer"></div>
        <p class="help-header">Reposition Points</p>
        <p>Click on the
          <Button label="Reposition" icon="pointer" class="inline compact" />
          button in the toolbar at the top to activate Reposition Mode. Similar to placing new points, when the button is highlighted
          <Button label="Reposition" icon="pointer" class="inline compact primary" />
          click (or touch) and drag any point on the map to reposition it to a new location. This allows you to reorganize all your points at the same time. Click the button again to deactivate it when finished.
        </p>
        <div class="spacer"></div>
        <div class="row spaced">
          <Button
            label="Close"
            @click.native="helpVisible = false"
          />
        </div>
      </ModalContainer>

    </template>

  </div>
</template>

<script>
  import ElementLink from '../../ElementLink.vue';
  import ElementLinkInput from '../../ElementLinkInput.vue';

  import { pause } from '@/utility';
  import iconList from './iconList'
  import exampleWorld from '@/components/World/example_world'

  export default {
    name: 'Map',
    components: {
      ElementLink,
      ElementLinkInput
    },

    data() {
      return {
        iconList: iconList,

        loading: true,
        loadingImage: null,
        error: null,
        comingFromNew: false,
        helpVisible: false,

        map: null,
        dimensions: {
          width: 1,
          height: 1
        },

        zoom: 1,
        maxZoom: 3,
        minZoom: 0.5,
        zoomStep: 0.01,

        previousTouch: null,
        cursor: {
          x: 0,
          y: 0
        },

        isDragging: false,
        displace: {
          x: 0,
          y: 0
        },
        displaceBorder: 100,

        points: null,
        selectedPoint: null,

        repositioning: false,
        repositioningPoint: null,

        editing: false,
        newPointModeOn: false,
        deletePointModalVisible: false,
        deletingPointImage: null,

        preventTransition: true,
        isPointListOpen: false,
        hoveredPoint: null,

        isFilterListOpen: false,

        filters: {
          icon: {}
        }
      }
    },

    computed: {
      isAuthor() {
        return this.$store.state.user?._id == this.mapData?.authorID
      },

      editMode() {
        return this.$route.name == 'Map'
        // and is author
      },

      mapData() {
        if (this.$route.params.id == 'ajd81238123a') {
          return exampleWorld.maps.find(map => map._id == 'ajd81238123a');
        }
        return this.map;
      },

      mapImage() {
        if (!this.mapData) return;
        return this.publicImageURL('map', this.mapData.image, this.mapData.authorID);
      },

      // halfWidth() {
      //   return this.dimensions ? this.dimensions.width / 2 : 0;
      // },
      // halfHeight() {
      //   return this.dimensions ? this.dimensions.height / 2 : 0;
      // },

      halfOverflow() {
        return {
          x: (this.overflow?.x || 0) / 2,
          y: (this.overflow?.y || 0) / 2
        }
      },

      leftEdge() {
        return -1 * (this.halfOverflow.x + this.displaceBorder);
      },
      rightEdge() {
        return this.halfOverflow.x + this.displaceBorder;
      },
      topEdge() {
        return -1 * (this.halfOverflow.y + this.displaceBorder);
      },
      bottomEdge() {
        return this.halfOverflow.y + this.displaceBorder;
      },

      currentMapWidth() {
        return this.dimensions.width * this.zoom;
      },
      currentMapHeight() {
        return this.dimensions.height * this.zoom;
      },

      currentFocus() {
        let x = ((this.currentMapWidth / 2) + this.displace.x) / this.currentMapWidth;
        let y = ((this.currentMapHeight / 2) + this.displace.y) / this.currentMapHeight;

        return { x, y }
      },

      overflow() {
        const map = document.getElementById('map-container');

        let x = 0;
        let y = 0;
        if (this.dimensions && map) {
          x = this.currentMapWidth - map.clientWidth;
          y = this.currentMapHeight - map.clientHeight;
        }
        if (x < 0) x = 0;
        if (y < 0) y = 0;

        return { x, y }
      },

      mapImageTransform() {
        if (!this.dimensions) return;
        const { zoom, displace } = this;
        const { width, height } = this.dimensions;

        let cursor = 'default';
        if (this.newPointModeOn) cursor = 'crosshair';
        else {
          if (this.isDragging) cursor = 'grabbing';
          else cursor = 'grab';
        }

        // let dimensionsToDimensionsWithPadding = this.currentMapWidth +
        // let displaceX = displace.x

        return `width: ${this.currentMapWidth}px;` +
          ` height: ${this.currentMapHeight};` +
          ` transform: translateX(calc(-50% + ${-displace.x}px)) translateY(calc(-50% + ${-displace.y}px));` +
          ` cursor: ${cursor};`;
      },

      iconListWithColor() {
        return this.iconList.map(icon => { return {...icon, color: this.selectedPoint.color || '#fff' } })
      }
    },

    methods: {
      loadMap() {
        let image = new Image();
        image.src = this.mapImage;

        setTimeout(() => {
          this.loading = false;

          if (image?.width && image?.height) {
            this.dimensions = {
              width: image.width,
              height: image.height
            }
          }
          else {
            this.error = 'Problem loading map.'
          }
        }, 500);

        setTimeout(() => {
          this.preventTransition = false;
        }, 600)
      },

      iconDisplace(point) {
        let i = this.iconList.find(item => item.icon == point.icon);
        return i?.displace || 0
      },

      pointTransform(point) {
        return `left: ${point.x * 100}%; top: ${point.y * 100}%;`;
      },

      infoTransform(point) {
        if (!point) return '';

        let isUp = point.y >= this.currentFocus.y;

        return this.pointTransform(point) + (isUp ?
          ` transform: translate(-50%, calc(-100% - 1.5rem + ${this.iconDisplace(point)}px))` :
          ` transform: translate(-50%, calc(1.5rem - ${this.iconDisplace(point)}px))`)
      },

      markerTransform(point) {
        let string = `color: ${point.color};` +
          ` transform: translateY(${this.iconDisplace(point)}px);`;
        // if (point.stroke) string += ` stroke: ${point.stroke};`;
        return string;
      },

      markerOutlineTransform(point) {
        return ` transform: translateY(${this.iconDisplace(point)}px);`;
      },

      onScroll() {
        event.stopPropagation();

        const {
          minZoom,
          maxZoom,
          zoomStep,
          zoom,
          displace,
          displaceBorder,
          overflow,
          cursor,
          leftEdge,
          rightEdge,
          topEdge,
          bottomEdge
        } = this;

        let zoomAmount = -event.deltaY * zoom / 1000;
        this.zoom += zoomAmount;

        if (this.zoom > maxZoom) this.zoom = maxZoom;
        else if (this.zoom < minZoom) this.zoom = minZoom;
        else {
          // Center on cursor while zooming in
          if (event.deltaY < 0) {
            let fromCenter = {
              x: cursor.x - 0.5,
              y: cursor.y - 0.5
            }
            let widthIncrease = zoomAmount * this.dimensions.width;
            let heightIncrease = zoomAmount * this.dimensions.height;
            this.displace.x += fromCenter.x * widthIncrease;
            this.displace.y += fromCenter.y * heightIncrease;
          }
          // Center on map while zooming out
          else {
            if (overflow?.x) {
              let x = displace.x;
              if (x < leftEdge) x = leftEdge;
              if (x > rightEdge) x = rightEdge;

              let fromCenter = x / (overflow.x + displaceBorder * 2);
              let widthIncrease = zoomAmount * this.dimensions.width;
              this.displace.x += fromCenter * widthIncrease;
            }
            if (overflow?.y) {
              let y = displace.y;
              if (y < topEdge) y = topEdge;
              if (y > bottomEdge) y = bottomEdge;

              let fromCenter = y / (overflow.y + displaceBorder * 2);
              let heightIncrease = zoomAmount * this.dimensions.height;
              this.displace.y += fromCenter * heightIncrease;
            }
          }
        }

        this.onCursorMove();
        this.limitBounds();
      },

      onCursorMove() {
        let xPercent = 0;
        let yPercent = 0;

        if (event.type == 'touchmove') {
          let rect = event.target.getBoundingClientRect();
          let offsetX = Math.round(event.touches[0].pageX - rect.left);
          let offsetY = Math.round(event.touches[0].pageY - rect.top);

          xPercent = offsetX / this.currentMapWidth;
          yPercent = offsetY / this.currentMapHeight;
        }
        else {
          let xLocation = event.offsetX;
          xPercent = xLocation / this.currentMapWidth;

          let yLocation = event.offsetY;
          yPercent = yLocation / this.currentMapHeight;
        }

        this.cursor = {
          x: xPercent,
          y: yPercent
        }

        if (this.isDragging) this.onDrag(event)
      },

      onMouseDown() {
        if (this.newPointModeOn) {
          this.addPoint(this.cursor.x, this.cursor.y);
        }
        else {
          this.isDragging = true;
          this.onDrag(event);
        }
      },

      onDrag(event) {
        event.preventDefault();

        if (!this.isDragging) return;
        if (this.zoom < this.minZoom) return;

        let movementX = 0;
        let movementY = 0;

        if (event.type == 'touchstart' || event.type == 'touchmove') {

          if (this.previousTouch) {
            movementX = event.touches[0].screenX - this.previousTouch.x;
            movementY = event.touches[0].screenY - this.previousTouch.y;
          }

          // if (!this.repositioningPoint) {
          //   this.displace.x -= movementX;
          //   this.displace.y -= movementY;
          // }

          if (event.touches.length > 1) {
            let movementX2 = 0;
            let movementY2 = 0;

            if (this.previousTouch.x2 && this.previousTouch.y2) {
              movementX2 = event.touches[1].screenX - this.previousTouch.x2;
              movementY2 = event.touches[1].screenY - this.previousTouch.y2;

              let previousTouchDistanceX = this.previousTouch.x - this.previousTouch.x2;
              let previousTouchDistanceY = this.previousTouch.y - this.previousTouch.y2;

              let currentTouchDistanceX = event.touches[0].screenX - event.touches[1].screenX;
              let currentTouchDistanceY = event.touches[0].screenY - event.touches[1].screenY;

              let previousTouchDistance = Math.hypot(previousTouchDistanceX, previousTouchDistanceY);
              let currentTouchDistance = Math.hypot(currentTouchDistanceX, currentTouchDistanceY);

              if (currentTouchDistance != previousTouchDistance) {
                this.onPinch(currentTouchDistance - previousTouchDistance)
              }
            }
          }

          this.previousTouch = {
            x: event.touches[0].screenX,
            y: event.touches[0].screenY
          }
          if (event.touches.length > 1) {
            this.previousTouch.x2 = event.touches[1].screenX;
            this.previousTouch.y2 = event.touches[1].screenY;
          }
        }

        else {
          movementX = event.movementX / 2;
          movementY = event.movementY / 2;

          // if (!this.repositioningPoint) {
          //   this.displace.x -= event.movementX;
          //   this.displace.y -= event.movementY;
          // }
        }

        // console.log(movementX, movementY);
        // console.log(this.displace.x, this.displace.y);
        // let prevX = this.displace.x.toString()

        if (!this.repositioningPoint) {
          this.displace.x -= movementX;
          this.displace.y -= movementY;
        }

        // console.log(movementX, prevX, this.displace.x);

        // let map = document.getElementById('map')
        // let xPadding = this.overflow.x + (this.displaceBorder * 2)
        // console.log(window.innerWidth, xPadding, this.displace?.x);

        this.limitBounds()

        if (this.repositioning && this.repositioningPoint) {
          this.repositionPoint(movementX, movementY)
        }
      },

      repositionPoint(x, y) {
        let moveX = x / this.currentMapWidth;
        let moveY = y / this.currentMapHeight;
        this.points[this.repositioningPoint._id].x += moveX;
        this.points[this.repositioningPoint._id].y += moveY;
      },

      onPinch(amount) {
        this.zoom += amount / 100;

        if (this.zoom > this.maxZoom) this.zoom = this.maxZoom;
        else if (this.zoom < this.minZoom) this.zoom = this.minZoom;

        let fromCenter = {
          x: this.cursor.x - 0.5,
          y: this.cursor.y - 0.5
        }
        let widthIncrease = (amount / 100) * this.dimensions.width;
        let heightIncrease = (amount / 100) * this.dimensions.height;
        this.displace.x += fromCenter.x * widthIncrease;
        this.displace.y += fromCenter.y * heightIncrease;
      },

      stopDrag() {
        this.isDragging = false;
        this.previousTouch = null;
        this.repositioningPoint = null;
      },

      limitBounds() {
        const {
          leftEdge,
          rightEdge,
          topEdge,
          bottomEdge
        } = this;

        if (!this.overflow?.x) {
          this.displace.x = 0;
        }
        else {
          if (this.displace.x < leftEdge) {
            this.displace.x = leftEdge;
          }
          else if (this.displace.x > rightEdge) {
            this.displace.x = rightEdge;
          }
        }

        if (!this.overflow?.y) {
          this.displace.y = 0;
        }
        else {
          if (this.displace.y < topEdge) {
            this.displace.y = topEdge;
          }
          else if (this.displace.y > bottomEdge) {
            this.displace.y = bottomEdge;
          }
        }
      },

      loadPoints() {
        if (!this.mapData?.points?.length) return;

        let newPoints = {}
        this.mapData.points.forEach(point => {
          newPoints[point._id] = point;
        });

        this.points = newPoints

        if (this.selectedPoint) {
          this.selectedPoint = newPoints[this.selectedPoint._id]
        }
      },

      addPoint(x, y) {
        let points;
        if (!this.points) points = {};
        else points = { ...this.points };

        let randomID = Math.round(Math.random() * 99999999).toString(16);
        let newPoint = {
          _id: randomID,
          x,
          y,
          icon: 'pin',
          color: '#ff5444',
          name: 'Name',
          description: 'Description'
        };

        points[randomID] = newPoint;
        this.points = points;

        this.selectedPoint = newPoint;
        this.editing = true;
        this.newPointModeOn = false;
      },

      deletePoint(point) {
        delete this.points[point._id];

        this.selectedPoint = false;
        this.deletePointModalVisible = false;
        this.editing = false;

        this.$store.dispatch('updateMapPoints', {
          mapID: this.mapData._id,
          points: this.points
        });
      },

      onMouseEnterMarker(point) {
        if (!this.editing) this.selectedPoint = point;
      },

      onMouseLeaveMarker() {
        if (!this.editing) this.selectedPoint = null;
      },

      onMouseEnterListPoint(point) {
        this.hoveredPoint = point
        if (!this.editing) {
          this.selectedPoint = point
        }
      },

      onMouseLeaveListPoint(point) {
        this.hoveredPoint = null
        if (!this.editing) {
          this.selectedPoint = null
        }
      },

      clickMarker(point) {
        if (this.isAuthor && this.repositioning) {
          this.repositioningPoint = point;
          return;
        }
        if (this.isAuthor && !this.editing) {
          this.editing = true
        }
      },

      changeIcon(icon, point) {
        point.icon = icon;
      },

      changeColor(color, point) {
        point.color = color;
      },

      changeName(e, point) {
        point.name = e.target.value;
      },

      changeDescription(e, point) {
        point.description = e.target.value;
      },

      openPointImageSelect() {
        document.getElementById('pointImageFileSelect').click()
      },

      async uploadPointImage(point) {
        this.loadingImage = point._id

        const image = event.target.files[0]

        let formData = new FormData()
        formData.append('mapID', this.mapData._id)
        formData.append('pointID', point._id)
        formData.append('map_point', image, image.name)

        await this.$store.dispatch('uploadMapPointImage', formData)
        const response = await this.$store.dispatch('fetchMap', this.$route.params.id)
        if (response?.status == 200) {
          this.$set(this.map, 'points', response?.data?.points)
          this.loadPoints()
        }

        this.loadingImage = null
      },

      clickDeleteImage(point) {
        this.deletingPointImage = point._id
      },

      async deletePointImage() {
        const pointID = this.deletingPointImage
        this.loadingImage = pointID
        this.deletingPointImage = null

        await this.$store.dispatch('deleteMapPointImage', {
          mapID: this.mapData._id,
          pointID: pointID
        })
        const response = await this.$store.dispatch('fetchMap', this.$route.params.id)
        if (response?.status == 200) {
          this.$set(this.map, 'points', response?.data?.points)
          this.loadPoints()
        }

        this.loadingImage = null
      },

      async addLink(point, element) {
        const newLink = {
          type: element.type,
          ...(element.category && { category: element.category }),
          id: element._id
        }

        await this.$store.dispatch('addMapLink', {
          mapID: this.mapData._id,
          pointID: point?._id,
          link: newLink
        })

        const response = await this.$store.dispatch('fetchMap', this.$route.params.id)
        if (response?.status == 200) {
          this.$set(this.map, 'points', response?.data?.points)
          this.loadPoints()
        }
      },

      async removeLink(point, link) {
        await this.$store.dispatch('removeMapLink', {
          mapID: this.mapData._id,
          pointID: point?._id,
          linkID: link?.id
        })

        const response = await this.$store.dispatch('fetchMap', this.$route.params.id)
        if (response?.status == 200) {
          this.$set(this.map, 'points', response?.data?.points)
          this.loadPoints()
        }
      },

      doneEditing() {
        this.editing = false;
        this.selectedPoint = null;
        this.$store.dispatch('updateMapPoints', {
          mapID: this.mapData._id,
          points: this.points
        });
      },

      cancelEditing() {
        this.editing = false;
        this.selectedPoint = null;
      },

      toggleRepositioning() {
        this.repositioning = !this.repositioning;

        if (!this.repositioning) {
          this.repositioningPoint = null;
          this.$store.dispatch('updateMapPoints', {
            mapID: this.mapData._id,
            points: this.points
          });
        }
      },

      changeZoom(amount) {
        this.zoom += amount;

        if (this.zoom > this.maxZoom) this.zoom = this.maxZoom;
        else if (this.zoom < this.minZoom) this.zoom = this.minZoom;
      },

      isPointVisible(point) {
        if (!Object.keys(this.filters.icon).length) return true;

        if (this.filters.icon[point.icon]) return true;
        return false;
      },

      toggleIconFilter(icon) {
        if (this.filters.icon[icon]) this.$delete(this.filters.icon, icon)
        else this.$set(this.filters.icon, icon, true)
      },

      isFilterIconSelected(icon) {
        if (this.filters.icon[icon] || !Object.keys(this.filters.icon).length) {
          return true;
        }
        return false;
      },

      selectListPoint(point) {
        // this.selectedPoint = point;
      }
    },

    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (from.name == 'NewMap') vm.comingFromNew = true
      })
    },

    created() {
      if (this.$route.params.id == 'ajd81238123a') {
        this.loadMap()
        this.loadPoints()
        return;
      }
      this.$store.dispatch('fetchMap', this.$route.params.id).then(response => {
        if (response.status == 200) {
          this.map = response.data
          this.loadMap()
          this.loadPoints()
        }
        else {
          this.error = 'Problem fetching map data.'
        }
      })
    },

    mounted() {
      window.addEventListener('wheel', this.onScroll);
    },
    destroyed() {
      window.removeEventListener('wheel', this.onScroll);
    }
  }
</script>

<style scoped lang="scss">

  .map-page {
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  .back-button {
    top: 2rem;
    left: 2rem;
  }

  .toolbar-row {
    top: 2rem;
    left: 2rem;
    align-items: flex-start;
    width: calc(100vw - 4rem);
    z-index: 9;

    .center, .right {
      align-items: flex-start;
    }
  }

  .page-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .help {
    text-align: center;
    font-size: 0.9rem;

    .help-title {
      color: $colorA;
      text-transform: uppercase;
    }

    .help-header {
      color: $colorB;
      font-size: 0.9rem;
      font-weight: 600;
    }
  }

  .filters {
    position: absolute;
    top: 5rem;
    right: 5rem;
    width: 400px;
    height: 240px;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: bg(20%);
    z-index: 99;

    h5 {
      margin-bottom: 0.5rem;
      color: $colorA;
    }

    .icon-list {
      @include flex();
      flex-wrap: wrap;
      color: #fff;

      > * {
        margin: 2px;
        padding: 0.75rem;
        border-radius: 0.25rem;
        background-color: #000;

        &:hover {
          cursor: pointer;
          background-color: #222;
        }

        &.selected {
          background-color: #1d9877;

          &:hover {
            background-color: #26a885;
          }
        }
      }
    }
  }

  .zoom {
    position: relative;
    @include flex($direction: column);

    > *:first-child {
      border-radius: 6px 6px 0 0;
    }
    > *:last-child {
      border-radius: 0 0 6px 6px;
    }
  }

  .buttons {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
  }

  #map-container {
    height: 100%;
    width: 100%;
  }

  #map {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    user-select: none;
    cursor: grab;
  }

  #map-image {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .map-focus {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #f62040;
    transform: translate(-50%, -50%);
    z-index: 999;
  }

  .map-point {
    position: absolute;
    transform: translate(-50%, -50%);

    .marker {
      position: relative;
      color: #ff5444;
      z-index: 3;
      stroke: #fff;
      stroke-width: 15;
    }

    .marker-outline {
      display: none;
      position: absolute;
      top: -4px;
      left: -4px;
      z-index: 2;
      color: #fff;
      filter: blur(2px);
      opacity: 0.5;
    }

    .shadow {
      position: absolute;
      top: 8px;
      left: 0px;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: #000;
      filter: blur(6px);
      opacity: 1;
      z-index: 1;
    }

    &:hover, &.highlighted {
      cursor: pointer;

      .marker {
        color: #fff !important;
        filter: drop-shadow(0 0 8px #fff);
      }
      .marker-outline {
        display: block;
      }
    }
  }

  .links {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.35rem 0.75rem;
    border-radius: 0.5rem;
    background-color: rgba(0,0,0,.75);
    font-size: 0.9rem;
  }

  .point-info-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 400px;
    // padding: 2rem;
    transform: translate(-50%, calc(-100% - 1.5rem));
  }

  .point-info, .editing-point-info {
    display: block;
    padding: 1.25rem 1.5rem;
    border-radius: 0.5rem;
    background-color: rgba(28,24,23,.8);
    text-align: center;

    .icon-dropdown {
      width: 70px;
      margin: auto;
    }

    > *:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    h4 {
      font-family: 'Raleway', 'Avenir', sans-serif;
      font-size: 0.9rem;
      font-weight: 600;
      color: #fff;
      text-transform: uppercase;
      text-shadow: 0 0 2px #000, 0 0 8px #000;
      white-space: pre;
    }

    p {
      font-size: 0.8rem;
      color: #bbb;
      line-height: 1.5em;
    }

    h6 {
      padding-top: 0.5rem;
      color: $colorA;
    }

    input {
      width: 100%;
    }

    .images {
      @include flex($justify: center);
      margin-bottom: 0.5rem;

      img {
        width: 120px;
        height: 120px;
        margin: 0.25rem;
      }
    }
  }

  .point-info {
    img, .image-loader {
      width: 120px;
      height: 120px;
      margin-bottom: 1.5rem !important;
    }
  }

  .point-list-container {
    position: fixed;
    @include flex($direction: column);
    top: 1rem;
    right: -320px;
    width: 320px;
    height: calc(100vh - 2rem);
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: rgba(0,0,0,.5);
    z-index: 99;
    transform: translateX(0);
    transition: transform 0.5s;
    backdrop-filter: blur(16px);

    &.no-transition {
      transition: unset;
    }

    &.open {
      transform: translateX(calc(-320px - 1rem));
    }
  }

  .point-list {
    @include flex($direction: column);
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .item {
      width: 100%;
      margin: 0.5rem 0;
      padding: 1rem;
      border-radius: 0.5rem;
      background-color: #000;

      &:hover {
        cursor: pointer;
        background-color: #222;
      }

      .column {
        margin-left: 1rem;
        align-items: flex-start;
      }

      .name {
        font-size: 0.9rem;
        font-weight: 600;
      }

      .description {
        font-size: 0.75rem;
        line-height: 1.25em;
      }
    }
  }

  .point-image {
    @include flex($justify: center);
    margin: 1rem 0 !important;
  }

  .image, .add-image-box {
    width: 120px;
    height: 120px;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .image {
    @include flex($justify: center);

    img {
      width: 100%;
      height: 100%;
      transition: opacity 0.15s;

      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }

    .icon {
      position: absolute;
      pointer-events: none;
      color: #ec4e4e;
      opacity: 0;
      transition: opacity 0.15s;
      filter: drop-shadow(0 0 16px #000);
    }

    &:hover {
      .icon {
        opacity: 1;
      }
    }
  }

  .add-image-box {
    @include flex($justify: center);
    color: #999;
    border: 1px solid #999;
    transition: color 0.15s, border 0.15s;

    &:hover {
      cursor: pointer;
      color: $colorA;
      border: 1px solid $colorA;
    }
  }

  #pointImageFileSelect {
    display: none;
  }

</style>
