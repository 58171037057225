<template>
  <div class="slider">

    <p v-if="label">{{ label }}</p>

    <input type="range"
      :min="min != null ? parseFloat(min) : 1"
      :max="max != null ? parseFloat(max) : 100"
      :step="step != null ? parseFloat(step) : 1"
      :value="defaultValue"
      @input="(e) => $emit('change', e.target.value)"
    />

  </div>
</template>

<script>
  export default {
    name: 'Slider',

    props: {
      min: [Number, String],
      max: [Number, String],
      step: [Number, String],
      value: [Number, String],
      label: [String]
    },

    data() {
      return {
        defaultValue: null
      }
    },

    watch: {
      value: function(newValue) {
        this.$nextTick(() => {
          this.defaultValue = newValue
        })
      }
    },

    mounted() {
      this.defaultValue = this.value != null ? parseFloat(this.value) : (this.min != null ? parseFloat(this.min) : 1)
    }
  }
</script>

<style scoped lang="scss">

  .slider {
    position: relative;
    @include flex();
    width: 100%;
    margin: 0.5rem 0;

    input[type="range"] {
      width: 100%;
      border: 0;
      padding: 0;
    }

    p {
      margin-right: 0.5rem;
    }
  }

</style>
