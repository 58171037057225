<template>
  <div class="item">
    <div :class="`file ${data.type} ${currentFile(data._id)}`"
      @click="clickFileOrFolder(data)"
      @mouseover="buttonsVisible = true"
      @mouseleave="buttonsVisible = false"
    >
      <Icon v-if="data.type === 'folder'"
        class="folder-arrow"
        :name="isExpanded ? 'less-arrow' : 'more-arrow'"
        size="16px" />
      <p class="title">{{ data.title }}</p>
      <p :class="`content ${emptyFolder}`">{{ contentPreview }}</p>
      <p class="last-updated">{{ formatDateDelta(parseInt(data.lastUpdated)) }} ago</p>

      <Icon v-if="buttonsVisible && data.type === 'folder'"
        class="add-file"
        name="new file"
        @click.native.stop="clickAddFile(data.type)" />
      <Icon v-if="buttonsVisible && data.type === 'folder'"
        class="add-folder"
        name="new folder"
        @click.native.stop="clickAddFolder(data.type)" />
      <Icon v-if="buttonsVisible"
        class="delete"
        name="delete"
        @click.native.stop="clickDelete(data.type, data.title)" />
    </div>

    <WritingCollectionNewFolder v-if="newFolder"
      class="child-file"
      :data="newFolder"
      @create-folder="(folder) => $emit('create-folder', folder)" />

    <WritingCollectionItem v-if="isExpanded"
      v-for="child in data.docs"
      class="child-file"
      :data="child"
      @add-folder="addFolder"
      @new-file="(id) => $emit('new-file', id)"
      @delete-file="(id) => $emit('delete-file', id)"
      @create-folder="createFolder"
      @load-file="(file) => $emit('load-file', file)" />

    <Modal v-if="deleteModalVisible"
      :message="deleteModalMessage"
      :buttons="['delete', 'cancel']"
      @modal-delete="confirmDelete"
      @modal-cancel="hideDeleteModal" />

  </div>
</template>

<script>
  import WritingCollectionItem from './WritingCollectionItem.vue';
  import WritingCollectionNewFolder from './WritingCollectionNewFolder.vue';

  export default {
    name: 'WritingCollectionItem',
    components: {
      WritingCollectionItem,
      WritingCollectionNewFolder,
    },
    props: {
      data: Object
    },
    computed: {
      contentPreview() {
        if (this.data.type === 'folder') {
          return this.data.docs && this.data.docs.length ? this.data.docs.length : '(empty)';
        }

        if (!this.data.content.length) return '';

        const PREVIEW_LENGTH = 20;

        let content = this.data.content[0].content || '';
        return content.length > PREVIEW_LENGTH ? content.substring(0, PREVIEW_LENGTH) + '...' : content;
      },
      emptyFolder() {
        if (this.data.type !== 'folder') return '';
        return this.data.docs && this.data.docs.length ? '' : 'empty';
      }
    },
    data() {
      return {
        isExpanded: false,
        buttonsVisible: false,
        newFolder: null,
        deleteModalVisible: false,
        deleteModalMessage: ''
      }
    },
    methods: {
      currentFile(id) {
        return this.$store.state.currentWriting?._id == id ? 'current-file' : '';
      },

      clickFileOrFolder(file) {
        if (file.type === 'empty') return;
        if (file.type === 'folder') this.isExpanded = !this.isExpanded;
        else this.$emit('load-file', file);
      },

      clickAddFolder(type) {
        this.isExpanded = true;
        this.newFolder = {
          type: 'new-folder',
          title: 'New Folder',
          parentID: this.data._id
        };
      },

      clickAddFile(type) {
        if (type === 'folder') this.isExpanded = true;
        this.$emit('new-file', this.data._id);
      },

      clickDelete(type, title) {
        this.deleteModalMessage = `Delete "${title}"?`;
        this.deleteModalVisible = true;
      },

      confirmDelete() {
        this.$emit('delete-file', this.data._id);
        this.deleteModalVisible = false;
      },

      hideDeleteModal() {
        this.deleteModalVisible = false;
      },

      createFolder(folder) {
        this.newFolder = null;
        this.$emit('create-folder', folder);
      }
    }
  }
</script>

<style scoped lang="scss">

  .item {
    width: 100%;

    &:not(:last-child) {
      border-bottom: 1px solid #333;
    }
  }

  .file {
    position: relative;
    @include flex($justify: flex-start, $align: center);
    padding: 1rem 1.5rem;
    user-select: none;

    &.folder, &.new-folder {
      color: #58af97;
      border-bottom: 1px solid transparent;

      .title {
        color: #58af97;
        font-size: 0.9rem;
      }

      .icon {
        margin-right: 0.5rem;
      }
    }

    .folder-arrow {
      opacity: 0.25;
      transition: opacity 0.15s;
    }

    &:hover {
      cursor: pointer;
      background-color: #000;

      .folder-arrow {
        opacity: 1;
      }
    }

    .title {
      margin-right: 1.5rem;
      color: #fff;
      font-size: 0.9rem;
      font-weight: 600;
    }
    .content {
      color: #aaa;
      font-size: 0.9rem;

      &.empty {
        color: #555;
      }
    }
    .last-updated {
      margin-left: auto;
      font-size: 0.7rem;
      color: $colorB;
    }

    &.current-file {
      .title {
        color: #5baee3;
      }
    }

    &.empty {
      .title {
        color: #777;
        font-weight: 300;
      }
    }
  }

  .child-file {
    margin-left: 1rem;
  }

  .add-folder, .add-file, .delete {
    position: absolute;
    padding: 0.5rem;
    border-radius: 0.5rem;
    top: 0.6rem;
    color: #888;

    &:hover {
      color: #fff;
      background-color: rgba(#fff,.25);
    }
  }

  .add-file {
    right: 10rem;
  }
  .add-folder {
    right: 7.5rem;
  }
  .delete {
    right: 5rem;
  }

</style>
