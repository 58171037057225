<template>
  <div class="immersive-bg-container">

    <div class="immersive-bg">
      <div
        class="overlay"
        :style="overlayStyle"
      ></div>

      <div v-if="youtubeHTML"
        v-html="youtubeHTML"
        class="youtube-container"
      ></div>
      <div v-else
        class="image"
        :style="`background-image: url(${backgroundImage});`"
      ></div>
    </div>

    <Icon
      v-if="youtubeHTML"
      :class="`lock-button${isYoutubeLocked ? ' locked' : ''}`"
      :name="isYoutubeLocked ? 'lock' : 'unlock'"
      size="1.25rem"
      @click.native="$emit('toggle-lock')"
    />
  </div>
</template>

<script>
  // https://www.youtube.com/watch?v=wSYoT_ptT00&t=6267s

  export default {
    name: 'ImmersiveBackground',

    props: {
      youtubeHTML: String,
      isYoutubeLocked: Boolean,
      backgroundImage: String,
      overlayColor: String,
      overlayOpacity: Number
    },

    computed: {
      overlayStyle() {
        return `background-color: ${this.overlayColor || '#414145'}; opacity: ${this.overlayOpacity != null ? this.overlayOpacity : 0.1}; pointer-events: ${this.isYoutubeLocked ? 'auto' : 'none'};`
      }
    }
  }
</script>

<style scoped lang="scss">

  .immersive-bg-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  .immersive-bg {
    &, > * {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    opacity: 0.5;
    z-index: -1;

    .overlay {
      position: absolute;
      z-index: -1;
      pointer-events: none;
    }

    .image {
      position: absolute;
      background-size: cover;
      background-position: center;
      // mask-image: linear-gradient(45deg, rgba(0,0,0,.25), rgba(0,0,0,.5));
      z-index: -2;
    }

    .youtube-container {
      z-index: -2;
    }
  }

  .lock-button {
    position: fixed;
    display: inline-block;
    top: 2.5rem;
    left: 50%;
    // right: calc(50% + 3rem);
    border-radius: 0.5rem;
    padding: 1rem;
    color: rgba(255,255,255,.5);
    transform: translateX(-50%);
    z-index: 99;

    &.locked {
      color: $colorA;
      background-color: rgba($colorA,.15);
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(255,255,255,.25);
    }
  }

</style>
