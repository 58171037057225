<template>
  <div class="word-hover" :style="`top: ${y}px; left: ${x}px;`">
    <p class="word">{{ word }}</p>
    <div class="meanings">
      <p v-for="meaning in meanings">{{ meaning }}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WordHover',
    props: {
      x: Number,
      y: Number,
      word: String,
      meanings: Array
    }
  }
</script>

<style scoped lang="scss">

  .word-hover {
    position: fixed;
    @include flex();
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid #fff;
    color: #ccc;
    background-color: #000;
    z-index: 99;

    .word {
      margin-right: 0.5rem;
      color: $stem-color;
      font-weight: bold;
    }

    .meanings {
      @include flex($direction: column, $align: flex-start);
    }
  }

</style>
