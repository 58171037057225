<template>
  <div class="collection-container">
    <div class="collection">

      <div class="header row">
        <Icon name="book" style="margin-right: 0.5rem;" />
        <h4>Select Element</h4>
      </div>

      <div class="menu">
        <div class="icon">
          <img src="https://media.scrybe-app.com/assets/elements/writing_icon.png"
            @click="tab = 'writings'" />
          <h3 style="color: #b0d7ff; text-shadow: 0 0 4px #2a86ff;">Writings</h3>
        </div>
        <div class="icon">
          <img src="https://media.scrybe-app.com/assets/elements/empty_icon.png"
            @click="tab = 'wikis'" />
          <h3 style="color: #c2ffe4; text-shadow: 0 0 4px #4bfbb6;">Wikis</h3>
        </div>
        <div class="icon">
          <img src="https://media.scrybe-app.com/assets/elements/empty_icon.png"
            @click="tab = 'timelines'" />
          <h3 style="color: #fedee7; text-shadow: 0 0 4px #ff698d;">Timelines</h3>
        </div>
        <div class="icon">
          <img src="https://media.scrybe-app.com/assets/elements/map_icon.png"
            @click="tab = 'maps'" />
          <h3 style="color: #fff2d5; text-shadow: 0 0 4px #ffe05b;">Maps</h3>
        </div>
        <div class="icon">
          <img src="https://media.scrybe-app.com/assets/elements/empty_icon.png"
            @click="tab = 'languages'" />
          <h3 style="color: #f8ccff; text-shadow: 0 0 4px #9c7bfb;">Languages</h3>
        </div>
      </div>


      <div class="list">

        <SelectWritingItem v-if="tab == 'writings'"
          v-for="element in elements"
          :data="element"
          :key="element._id"
          @select="(writing) => { $emit('select', writing) }"
        />

        <div v-else class="creation-list">
          <div v-for="element in elements"
            class="creation-item"
            :key="element._id"
            @click="$emit('select', element)"
          >
            <Icon v-if="tab == 'wikis'" :name="formatWikiIcon(element.category)" />
            <p>{{ element.title }}</p>
          </div>
        </div>

      </div>


      <div class="close-button-container">
        <Button
          label="Close"
          class="inline"
          @click.native="$emit('close')"
        />
      </div>

    </div>
  </div>
</template>

<script>
  import SelectWritingItem from './SelectWritingItem.vue'

  export default {
    name: 'SelectElement',
    components: {
      SelectWritingItem
    },

    data() {
      return {
        tab: 'writings',
      }
    },

    computed: {
      world() {
        return this.$store.state.world || {}
      },

      elements() {
        return Object.values(this.world[this.tab] || {})
      }
    },

    methods: {
      formatWikiIcon(category) {
        if (category === 'characters') return 'witch hat';
        else if (category === 'places') return 'mountain';
        else if (category === 'magic') return 'magic';
        else if (category === 'events') return 'stopwatch';
        else return '';
      },
    },
  }
</script>

<style scoped lang="scss">

  .collection-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.25);
    z-index: 9;
    backdrop-filter: blur(4px);
  }

  .collection {
    position: fixed;
    @include flex($direction: column, $align: center);
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 600px;
    min-height: 300px;
    max-height: 60vh;
    margin: 1rem;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: bg(15%);
    z-index: 9;

    .list {
      width: 100%;
      overflow: auto;
      background-color: bg(18%);
    }

    .header {
      width: 100%;
      padding: 1rem;
      border-radius: 8px 8px 0 0;
      background-color: #253e36;
      text-align: center;
      font-size: 0.75rem;
      text-transform: uppercase;
      box-shadow: 0px 4px 20px -16px rgba(0,0,0,.5);
      user-select: none;

      h4, .icon {
        color: $colorA;
      }
    }

    .menu {
      position: relative;
      @include flex($justify: center);
      flex-shrink: 0;
      width: 100%;
      height: 120px;
      margin: 0 auto;
      margin-bottom: 1rem;
      vertical-align: top;

      > * {
        margin: 1rem;
      }

      &.disabled {
        opacity: 0.5;
      }

      .icon {
        position: relative;
        display: inline-flex;

        img {
          width: 80px;
          height: 80px;
        }

        h3 {
          position: absolute;
          left: 50%;
          bottom: -1rem;
          margin-top: 0.5rem;
          font-size: 0.85rem;
          font-weight: 600;
          text-transform: uppercase;
          opacity: 0;
          pointer-events: none;
          transition: opacity 0.3s;
          transform: translateX(-50%);
        }

        &:hover {
          cursor: pointer;

          h3 {
            opacity: 1;
          }
        }
      }
    }

    .creation-list {
      width: 100%;
    }

    .creation-item {
      @include flex();
      width: 100%;
      padding: 0.75rem 1.5rem;

      &:not(:last-child) {
        border-bottom: 1px solid #333;
      }

      .icon {
        margin-right: 1rem;
      }

      p, .icon {
        color: #fff;
      }

      &:hover {
        cursor: pointer;
        background-color: #212225;

        p, .icon {
          color: $colorA;
        }
      }
    }

    .close-button-container {
      margin-top: auto;
      padding: 0.5rem;
    }
  }

</style>
