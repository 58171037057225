<template>
  <div class="timelines-menu floating">

    <div class="timelines-bg"></div>

    <div class="timelines-header">
      <h4>Timelines</h4>
    </div>


    <div class="timelines-body">

      <template v-if="!hasPermission('timelines')">
        <Message type="warning" text="You do not own this product." />
        <CoolButton
          type="compact"
          label="Subscribe"
          iconAfter="right-arrow"
          iconSize="12px"
          style="margin: 2rem 0;"
          animation="infinite"
          textColor="#78ffc6"
          bgColor="rgb(48,215,140)"
          @click.native="$router.push('/subscribe')"
        />
      </template>


      <template v-else>
        <div class="toolbar-row">
          <div class="center row spaced">
            <Button
              label="New Timeline"
              icon="plus"
              class="new-button primary"
              @click.native="$router.push('/new-timeline')"
            />
          </div>
          <div class="right">
            <Button v-if="timelines.length"
              label="Edit"
              icon="edit"
              :class="editing ? 'primary' : 'dark'"
              style="margin-left: auto;"
              @click.native="editing = !editing"
            />
          </div>
        </div>

        <div class="spacer"></div>

        <Alert :alert="alert" @dismiss="alert = null" />

        <p v-if="!timelines.length">You have not created any timelines.</p>


        <div v-for="item in timelines"
          :class="`item ${item.visibility == 'public' ? 'public-element' : 'private-element'}`"
          @click="clickItem(item._id)"
        >
          <div class="info">
            <input v-if="renamingTimeline && renamingTimeline._id == item._id"
              type="text"
              :value="item.title"
              @input="(e) => renamingTimeline.title = e.target.value"
            />
            <p v-else>{{ item.title }}</p>
            <h6>{{ formatDate(item.lastUpdated, '&mon &dd, &year') }}</h6>
          </div>

          <template v-if="!editing || renamingTimeline">
            <Icon v-if="item.visibility == 'public'"
              name="eye"
              class="public-icon"
              style="margin-left: auto;"
            />
            <Icon v-else
              name="lock"
              class="private-icon"
              style="margin-left: auto;"
            />
          </template>

          <template v-if="editing">
            <template v-if="renamingTimeline && renamingTimeline._id == item._id">
              <Button
                icon="check"
                class="primary"
                style="margin-left: 1rem;"
                @click.native="renameTimeline"
              />
              <Button v-if="renamingTimeline"
                icon="x"
                class="delete"
                style="margin-left: 0.5rem;"
                @click.native="renamingTimeline = null"
              />
            </template>
            <template v-else>
              <Select
                :options="Object.values(ui.visibility)"
                :value="item.visibility ? item.visibility : 'private'"
                @select="(value) => visibilityChanged(value, item)"
                style="min-width: 135px; margin-left: auto;"
                strict
              />
              <Button
                icon="edit"
                style="margin-left: 1rem;"
                @click.native="renamingTimeline = item"
              />
              <Button
                icon="delete"
                class="delete"
                style="margin-left: 0.5rem;"
                @click.native="deletingTimeline = item"
              />
            </template>
          </template>
        </div>

      </template>

    </div>


    <ModalContainer v-if="deletingTimeline">
      <h4>Delete "{{ deletingTimeline.title }}"?</h4>
      <Message type="warning" text="All data in this timeline will be lost." />
      <div class="row spaced">
        <Button
          label="Yes"
          class="primary"
          @click.native="deleteTimeline"
        />
        <Button
          label="No"
          class="delete"
          @click.native="deletingTimeline = null"
        />
      </div>
    </ModalContainer>


    <ModalContainer v-if="changingVisibilityElement">
      <h4>Make "{{ changingVisibilityElement.title }}" {{ newVisibility }}?</h4>

      <div class="spacer h1"></div>
      <p v-if="newVisibility == 'private'" class="hint">Only you will be able to see this element.</p>
      <Message
        v-if="newVisibility == 'public'"
        type="warning"
        text="By making this element public, you agree to the terms of service and privacy policy."
      />
      <div class="spacer h1"></div>

      <div class="row spaced">
        <Button
          label="Yes"
          class="primary"
          @click.native="updateElementVisibility"
        />
        <Button
          label="No"
          class="delete"
          @click.native="changingVisibilityElement = null"
        />
      </div>
    </ModalContainer>

  </div>
</template>

<script>
  export default {
    name: 'TimelinesMenu',

    computed: {
      timelines() {
        return this.$store.state.world?.timelines || []
      },
    },

    data() {
      return {
        editing: false,
        renamingTimeline: null,
        deletingTimeline: null,
        alert: null,

        changingVisibilityElement: null,
        newVisibility: 'private',
      }
    },

    methods: {
      clickItem(id) {
        if (!this.editing) this.$router.push('/timeline/' + id)
      },

      renameTimeline() {
        if (!this.renamingTimeline) return;
        if (!this.renamingTimeline.title?.trim()) {
          this.alert = {
            type: 'error',
            message: 'Please enter a name.'
          }
          return;
        }

        let params = {
          timelineID: this.renamingTimeline._id,
          worldID: this.$store.state.user?.currentWorldID,
          title: this.renamingTimeline.title
        }

        this.$store.dispatch('renameTimeline', params).then(response => {
          if (response.status == 200) {
            this.$store.commit('renameTimeline', params)
          }
          else {
            this.alert = {
              type: 'error',
              message: response.data.error
            }
          }
          this.renamingTimeline = null;
        })
      },

      deleteTimeline() {
        if (!this.deletingTimeline) return;

        let params = {
          timelineID: this.deletingTimeline._id,
          worldID: this.$store.state.user?.currentWorldID
        }

        this.$store.dispatch('deleteTimeline', params).then(response => {
          if (response.status == 200) {
            this.$store.commit('deleteTimeline', params)
            this.alert = {
              type: 'success',
              message: 'Timeline deleted successfully.'
            }
          }
          else {
            this.alert = {
              type: 'error',
              message: response.data.error
            }
          }
          this.deletingTimeline = null;
        })
      },

      visibilityChanged(value, element) {
        this.newVisibility = value;
        this.changingVisibilityElement = element;
      },

      updateElementVisibility() {
        this.$store.dispatch('updateElementVisibility', {
          element: 'timeline',
          elementID: this.changingVisibilityElement._id,
          worldID: this.$store.state.world?._id,
          visibility: this.newVisibility
        }).then(response => {
          if (response.status == 200) {
            this.alert = { type: 'success', message: `Made "${this.changingVisibilityElement.title}" ${this.newVisibility}.` }
            this.$store.dispatch('fetchCurrentUserWorld')
          }
          else {
            this.alert = { type: 'error', message: response?.data?.error }
          }
          this.changingVisibilityElement = null;
          this.newVisibility = 'private';
        })
      }
    }
  }
</script>

<style scoped lang="scss">

  .timelines-menu {
    position: relative;
    @include flex($direction: column);
  }

  .timelines-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 0.5rem;
    overflow: hidden;
    background-image: url('https://media.scrybe-app.com/assets/elements/timelines_card.png');
    background-size: cover;
    background-position: bottom center;
  }

  .timelines-header {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: rgba(255,255,255,0.15);
    z-index: 2;

    h4 {
      font-size: 0.75rem;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .timelines-body {
    @include flex($direction: column);
    width: 100%;
    height: 100%;
    padding: 1rem;
    z-index: 2;
  }

  .new-button {
    display: inline-flex !important;
  }

  .item {
    @include flex();
    width: 100%;
    margin: 0.25rem 0;
    padding: 1rem;
    border-radius: 0.25rem;
    background-color: bg(25%);

    .info {
      @include flex($direction: column, $align: flex-start);
    }

    h6 {
      color: $colorB;
    }

    &:hover {
      cursor: pointer;
      background-color: bg(30%);

      p {
        color: $colorA;
      }
    }
  }

</style>
