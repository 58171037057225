<template>
  <div class="page">

    <div class="spacer"></div>
    <h2>Payment Successful!</h2>
    <div class="spacer"></div>
    <p class="hint">You can manage your subscription by <a href="/#/dashboard/manage-subscription">clicking here</a> or via the user dashboard.</p>
    <div class="spacer"></div>

    <Button
      label="Start Using Scrybe"
      class="primary"
      @click.native="$router.push('/creation')"
    />

  </div>
</template>

<script>
  export default {
    name: 'PaymentSuccess',
  }
</script>

<style scoped lang="scss">

  h2 {
    color: $colorA;
  }

  a {
    color: $colorB;
    font-weight: 600;
  }

</style>
