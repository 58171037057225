<template>
  <div class="timeline-page">

    <!-- <div class="timeline-bg" :style="`background-image: url(${backgroundImage});`"></div> -->

    <ImmersiveBackground
      :backgroundImage="backgroundImage"
      :overlayColor="overlayColor"
      :overlayOpacity="overlayOpacity"
      style="z-index: -1;" />

    <BackButton
      @click.native="comingFromNew ? $router.push('/creation') : $router.back()"
    />

    <!-- <div class="floating calendar">
      <p>{{ calendar.name }}</p>
    </div> -->

    <div class="timeline-toolbar">
      <div class="toolbar-button-container"></div>
      <div class="toolbar-button-container">
        <Button
          label="Help"
          icon="question"
          class="help"
          @click.native="helpVisible = true"
        />
      </div>
      <div class="toolbar-button-container row spaced">
        <Button v-if="isAuthor"
          icon="pointer"
          label="Reposition"
          :class="repositioning ? 'primary' : ''"
          @click.native="toggleRepositioning"
        />
        <Button v-if="isAuthor"
          icon="plus"
          label="New Point"
          :class="newPointModeOn ? 'primary' : ''"
          @click.native="newPointModeOn = !newPointModeOn"
        />
        <Button
          icon="left-arrow"
          label="Show List"
          @click.native="isListOpen = true"
        />
      </div>
    </div>

    <!-- <div v-if="timeline" class="zoom-levels">
      <h5>Levels</h5>
      <div v-for="(level, index) in timeline.levels"
        :class="`level${currentLevelIndex === index ? ' current' : ''}`">
        <p>{{ level.name }}</p>
      </div>
    </div> -->


    <div v-if="timeline" class="timeline">

      <div id="viewport"
        :class="`viewport ${newPointModeOn ? 'pointer' : ''}`"
        :style="`width: calc(100% + ${zoom}px); left: ${-1 * displace}px;`"
        @touchstart.stop="onMouseDown"
        @mousedown.stop="onMouseDown"
        @touchcancel.stop="onMouseUp"
        @touchend.stop="onMouseUp"
        @mouseup.stop="onMouseUp"
        @mouseleave.stop="onMouseUp"
        @touchmove="onMouseMove"
        @mouseenter="onMouseMove"
        @mousemove="onMouseMove"
      >

        <div v-for="index in numGridLines"
          class="grid-line-container"
          :style="`left: ${((index - 1) / (numGridLines - 1)) * 100}%;`"
        >
          <div
            v-if="isGridLineVisible(index)"
            class="viewport-grid-line"
            style="left: 0;"
          >
            <div v-if="gridLineText">
              <p>{{ gridLineText[index - 1].date }}</p>
              <h6>{{ gridLineText[index - 1].year }}</h6>
            </div>
          </div>
          <!-- <div v-if="isGridLineMonthVisible(index)"
            class="months"
            :style="`width: ${yearSpacing}px;`"
          >
            <div v-for="month in calendar.months"
              class="viewport-grid-line-month"
              :style="`width: ${month.numDays * daySpacing}px;`"
            >
              <span>{{ month.name }}</span>
            </div>
          </div> -->
        </div>

        <!-- <div class="focus" :style="`left: ${currentFocus}px;`"></div> -->


        <template v-for="(line, lineIndex) in lines"
          v-if="visibleLines[line._id]"
        >
          <div class="timeline-container"
            :id="line._id"
            @mousedown="storeLine(line._id)"
            @touchstart="storeLine(line._id)"
            @mouseenter="storeLine(line._id)"
            @mousemove="storeLine(line._id)"
            @touchmove="storeLine(line._id)"
          >

            <div :id="`root-${line._id}`" class="root-line"></div>

            <div class="title" :style="`left: ${(screenWidth / 2) + displace}px;`">
              <p>{{ line.name }}</p>
            </div>

            <div v-for="(node, nodeIndex) in line.nodes"
              :class="`node-container ${checkEdge(node)} ${editingPoint.point && editingPoint.point._id == node._id ? 'editing' : ''}`"
              :style="calcPosition(node)"
            >
              <template v-if="node.type == 'range'">
                <div class="range"></div>
                <div class="info">
                  <div class="basic-info range-info">
                    <span>{{ dateAtPosition(node) }}</span>
                    <p>{{ node.title }}</p>
                  </div>
                </div>
              </template>

              <template v-else>
                <div
                  class="point-container"
                  @mousedown.bubble="clickNode(node, line)"
                  @touchstart.bubble="clickNode(node, line)"
                >
                  <div class="point-interactive-area">
                    <div class="point"></div>
                  </div>

                  <div
                    v-if="editingPoint.point && editingPoint.point._id == node._id"
                    :class="`info-container editing ${pointDisplayClass(line._id)}`"
                  >
                    <div
                      class="editing-info"
                      @wheel.stop=""
                    >
                      <div class="point-image">
                        <div
                          v-if="loadingImage.lineIndex == lineIndex && loadingImage.nodeIndex == nodeIndex"
                          class="image"
                        >
                          <Loader size="60px"class="loader-center" />
                        </div>

                        <div
                          v-else-if="node.image"
                          class="image"
                          @click="clickDeleteImage(lineIndex, nodeIndex)"
                        >
                          <img :src="publicImageURL('timeline', node.image.name, timeline.authorID)" />
                          <Icon name="x" size="1.5rem" />
                        </div>

                        <div
                          v-else
                          class="add-image-box"
                          @click="openPointImageSelect"
                        >
                          <Icon name="plus" size="1.5rem" />
                        </div>
                        <input
                          type="file"
                          name="timeline"
                          id="pointImageFileSelect"
                          @change="uploadPointImage(lineIndex, nodeIndex)"
                        />
                      </div>

                      <input type="number"
                        placeholder="Year"
                        :value="node.year"
                        @input="(e) => editingPoint.point.year = parseInt(e.target.value)"
                      />
                      <input type="text"
                        placeholder="Month"
                        :value="node.month"
                        @input="(e) => editingPoint.point.month = e.target.value"
                      />
                      <input type="number"
                        placeholder="Day"
                        :value="node.day"
                        @input="(e) => editingPoint.point.day = parseInt(e.target.value)"
                      />
                      <input type="text"
                        placeholder="Title"
                        :value="node.title"
                        @input="(e) => editingPoint.point.title = e.target.value"
                      />

                      <div class="spacer h1"></div>
                      <h4>Link Other Elements</h4>
                      <ElementLinkInput
                        @select="(element) => addLink(lineIndex, nodeIndex, element)"
                      />

                      <template v-if="editingPoint.point.links">
                        <div
                          v-for="link in editingPoint.point.links"
                          class="row spaced space-between"
                        >
                          <ElementLink
                            :element="link.type"
                            :id="link.id"
                            :key="link.id"
                          />
                          <Button
                            icon="delete"
                            class="delete"
                            @click.native="removeLink(lineIndex, nodeIndex, link)"
                          />
                        </div>
                      </template>

                      <div class="buttons row spaced">
                        <Button
                          label="Delete"
                          icon="delete"
                          class="delete"
                          @click.native="clickDeletePoint(node, line)"
                        />
                        <Button
                          label="Cancel"
                          icon="x"
                          @click.native="cancelEditing"
                        />
                        <Button
                          label="Done"
                          icon="check"
                          class="primary"
                          @click.native="doneEditing"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-else
                    class="info-container"
                  >
                    <div
                      v-if="loadingImage.lineIndex == lineIndex && loadingImage.nodeIndex == nodeIndex"
                      class="image-loader"
                    >
                      <Loader size="60px" class="loader-center" />
                    </div>
                    <template v-else>
                      <img
                        v-if="node.image"
                        :src="publicImageURL('timeline', node.image.name, timeline.authorID)"
                      />
                    </template>

                    <div class="basic-info">
                      <span>{{ dateAtPosition(node) }}</span>
                      <p>{{ node.title }}</p>
                    </div>
                  </div>
                </div>

                <div
                  v-if="node.links && node.links.length"
                  class="links"
                  @mousedown.stop=""
                >
                  <ElementLink
                    v-for="link in node.links"
                    :element="link.type"
                    :id="link.id"
                    :key="link.id"
                  />
                </div>
              </template>
            </div>

            <div v-if="newPointModeOn"
              :class="`node-container cursor ${cursor.line == line._id ? 'selected' : ''}`"
              :style="`left: ${cursor.position}px;`"
            >
              <div class="point-container">
                <div class="point-interactive-area">
                  <div class="point"></div>
                </div>
                <div class="info-container">
                  <span>{{ formatDate(dateAtCursor) }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>

      </div>
    </div>

    <div :class="`list-container ${isListOpen ? 'open' : ''}`">
      <div class="row" style="justify-content: space-between;">
        <Button class="close-list"
          icon="right-arrow" size="1.25rem"
          label="Hide List"
          @click.native="isListOpen = false"
        />
        <Button v-if="isAuthor"
          label="Edit"
          icon="edit"
          :class="editLineModeOn ? 'primary' : ''"
          @click.native="editLineModeOn = !editLineModeOn"
        />
      </div>
      <div class="spacer h1"></div>


      <div class="list" @wheel.stop="">
        <div class="row">
          <p>Show Lines</p>
          <Button v-if="isAuthor"
            icon="plus" size="1.25rem"
            label="New Line"
            class="primary"
            style="margin-left: auto;"
            @click.native="newLineModalVisible = true"
          />
        </div>

        <div class="spacer h1"></div>
        <p v-if="!lines.length" class="hint">You have no lines.</p>

        <div v-for="line in lines" class="line">
          <template v-if="editLineModeOn">
            <template v-if="renamingLine && renamingLine._id == line._id">
              <input
                type="text"
                :value="renamingLine.name"
                @input="(e) => renamingLine.name = e.target.value"
              />
              <Button
                icon="check"
                class="primary"
                style="margin-left: 0.5rem;"
                @click.native="renameLine(line)"
              />
              <Button
                icon="x"
                class="delete"
                style="margin-left: 0.5rem;"
                @click.native="renamingLine = null"
              />
            </template>
            <template v-else>
              <p>{{ line.name }}</p>
              <Button
                icon="edit"
                style="margin-left: auto;"
                @click.native="renamingLine = {...line}"
              />
              <Button
                icon="delete"
                class="delete"
                style="margin-left: 0.5rem;"
                @click.native="clickDeleteLine(line)"
              />
            </template>
          </template>

          <template v-else>
            <p style="margin-right: auto;">{{ line.name }}</p>
            <Checkbox
              label=""
              color="#14cb7e"
              :checked="visibleLines[line._id]"
              @checkbox-toggle="toggleShowLine(line._id)"
            />
          </template>
        </div>


        <div class="row" style="margin-top: 4rem;">
          <p>Zones</p>
          <Button v-if="isAuthor"
            icon="plus" size="1.25rem"
            label="New Zone"
            class="primary"
            style="margin-left: auto;"
            @click.native="newZoneModalVisible = true"
          />
        </div>

        <div class="spacer h1"></div>

        <div v-for="zone in Object.values(zones)"
          :class="`zone ${selectedZone && selectedZone._id == zone._id ? 'selected' : ''}`"
          @click="changeZone(zone)"
        >
          <template v-if="editLineModeOn">
            <template v-if="renamingZone && renamingZone._id == zone._id">
              <input
                type="text"
                :value="renamingZone.name"
                @input="(e) => renamingZone.name = e.target.value"
              />
              <Button
                icon="check"
                class="primary"
                style="margin-left: 0.5rem;"
                @click.native="renameZone"
              />
              <Button
                icon="x"
                class="delete"
                style="margin-left: 0.5rem;"
                @click.native="renamingZone = null"
              />
            </template>
            <template v-else>
              <p>{{ zone.name }}</p>
              <Button
                icon="edit"
                style="margin-left: auto;"
                @click.native="renamingZone = {...zone}"
              />
              <Button
                icon="delete"
                class="delete"
                style="margin-left: 0.5rem;"
                @click.native="clickDeleteZone(zone)"
              />
            </template>
          </template>

          <template v-else>
            <p style="margin-right: auto;">{{ zone.name }}</p>
            <h6 style="margin-right: 1rem;">{{ zone.start }} - {{ zone.end }}</h6>
            <Icon v-if="selectedZone && selectedZone._id == zone._id"
              name="check" size="12px"
              class="zone-check"
            />
            <div v-else style="width: 12px;"></div>
          </template>
        </div>
      </div>


      <!-- <div class="point-list" @wheel.stop="">
        <div v-for="point in points" class="item row"
          @click="selectListPoint(point)"
          @mouseenter="hoveredPoint = point; selectedPoint = point;"
          @mouseleave="hoveredPoint = null; selectedPoint = null;"
        >
          <Icon :name="point.icon" size="1rem" :style="`color: ${point.color};`" />
          <div class="column">
            <p class="name">{{ point.name }}</p>
            <p class="description">{{ point.description }}</p>
          </div>
        </div>
      </div> -->
    </div>

    <div v-if="isAuthor" :class="`customize-container ${customizeVisible ? 'open' : ''}`">
      <Button
        label="Customize"
        :icon="customizeVisible ? 'right-arrow' : 'left-arrow'"
        class="customize-button"
        @click.native="customizeVisible = !customizeVisible"
      />

      <h3>Customize</h3>
      <div class="spacer h3"></div>
      <p>Background Image:</p>
      <div class="spacer h1"></div>
      <input type="text" :value="backgroundImage" @change="changeBackgroundImage" />

      <div class="spacer h1"></div>
      <ColorPicker :color="overlayColor" label="Overlay Color"
        @color-picked="overlayColorChanged"/>
      <Slider :min="0" :max="100" :step="1" :value="overlayOpacity" label="Opacity"
        @change="overlayOpacityChanged"/>

      <Alert :alert="customizeAlert" @dismiss="customizeAlert = null" />
    </div>


    <NewLine v-if="newLineModalVisible"
      :year="newPoint.year"
      :month="newPoint.month"
      :day="newPoint.day"
      @add="addLine"
      @cancel="newLineModalVisible = false"
    />

    <NewPoint v-if="newPointModalVisible"
      :year="newPoint.year"
      :month="newPoint.month"
      :day="newPoint.day"
      @add="addPoint"
      @cancel="newPointModalVisible = false"
    />

    <ModalContainer v-if="newZoneModalVisible">
      <div class="column spaced">
        <p>New Zone</p>
        <input type="text"
          placeholder="Name"
          :value="newZone.name"
          @input="(e) => newZone.name = e.target.value"
        />
        <input type="number"
          placeholder="Start Year"
          :value="newZone.start"
          @input="(e) => newZone.start = parseInt(e.target.value)"
        />
        <input type="number"
          placeholder="End Year"
          :value="newZone.end"
          @input="(e) => newZone.end = parseInt(e.target.value)"
        />
      </div>
      <div class="spacer h1"></div>
      <div class="row spaced">
        <Button
          label="Yes"
          class="primary"
          @click.native="addZone"
        />
        <Button
          label="No"
          class="delete"
          @click.native="newZoneModalVisible = false"
        />
      </div>
    </ModalContainer>

    <ModalContainer v-if="deleteZoneModalVisible">
      <p>Delete "{{ deletingZone.name }}"?</p>
      <Message type="success" text="All points will be unaffected."/>
      <div class="row spaced">
        <Button
          label="Yes"
          class="primary"
          @click.native="deleteZone"
        />
        <Button
          label="No"
          class="delete"
          @click.native="deleteZoneModalVisible = false"
        />
      </div>
    </ModalContainer>

    <ModalContainer v-if="deleteLineModalVisible">
      <p>Delete "{{ deletingLine.name }}"?</p>
      <Message type="warning" :text="'All points on this line will be deleted.\nThis cannot be undone.'"/>
      <div class="row spaced">
        <Button
          label="Yes"
          class="primary"
          @click.native="deleteLine"
        />
        <Button
          label="No"
          class="delete"
          @click.native="deleteLineModalVisible = false"
        />
      </div>
    </ModalContainer>

    <ModalContainer v-if="deletePointModalVisible">
      <p>Delete "{{ deletingPoint.point.title }}"?</p>
      <Message type="warning" text="This cannot be undone."/>
      <div class="row spaced">
        <Button
          label="Yes"
          class="primary"
          @click.native="deletePoint"
        />
        <Button
          label="No"
          class="delete"
          @click.native="deletePointModalVisible = false"
        />
      </div>
    </ModalContainer>

    <ModalContainer v-if="deletingPointImage">
      <p>Delete this image?</p>
      <Message type="warning" text="This cannot be undone."/>
      <div class="row spaced">
        <Button
          label="Yes"
          class="primary"
          @click.native="deletePointImage"
        />
        <Button
          label="No"
          class="delete"
          @click.native="deletingPointImage = null"
        />
      </div>
    </ModalContainer>


    <ModalContainer v-if="helpVisible" class="help">
      <h3 class="help-title">Timelines Guide</h3>
      <div class="spacer"></div>
      <p class="help-header">Navigating the Timeline</p>
      <p>Zoom in and out by scrolling up and down with a touchpad or mouse, or pinching with two fingers on a touch screen device. Click (or touch) and drag, or scroll horizontally on a touchpad or mouse, to move around the timeline.</p>
      <div class="spacer"></div>
      <p class="help-header">Lines & Zones</p>
      <p style="margin-bottom: 0.5rem;">Lines are individual sub-timelines that contain points in time. Each line contains its own set of points, so you can track events across time which are relevant to only that line. Multiple lines can be displayed at one time to keep track of each thread of events simultaneously.</p>
      <p style="margin-bottom: 0.5rem;">Zones are like windows to the timeline, which allow you to change the scale of time. Each zone has its own start to end range, allowing you to view micro events in detail, or macro events in one sweeping view. A default zone is created for each new timeline automatically, with the original start and end dates. Additional zones can be created to give quick access to certain windows within your timeline.</p>
      <p>Due to performance reasons, there is zoom limit on each timeline based on the start to end range. For example, a timeline from year 500 to year 1000 has range of 500 years, which limits the ability to manage daily or monthly events. However, with zones, that range can be altered to, for example, 600-610, allowing finer control of events in that window.</p>
      <div class="spacer"></div>
      <p class="help-header">Create a Line</p>
      <p>Click on the
        <Button label="Show List" icon="left-arrow" class="inline compact" />
        button in the toolbar at the top to open the side menu. This menu shows a list of lines and zones. You can display multiple lines at one time by checking the boxes next to each line. Click the
        <Button label="New Line" icon="plus" class="inline compact primary" />
        button to create a new line. Check the box beside it to display it on screen.
      </p>
      <div class="spacer"></div>
      <p class="help-header">Change Zone</p>
      <p>Open the side menu as described above. In the Zones section, the default zone will be shown and selected by default. Click the
        <Button label="New Zone" icon="plus" class="inline compact primary" />
        button to create a new zone, and select it from the side menu to change the active range.
      </p>
      <div class="spacer"></div>
      <p class="help-header">Add Points</p>
      <p>If no lines are displayed, open the side menu as described above and check the box next to a line to display it. Once done, close the side menu, and click on the
        <Button label="New Point" icon="plus" class="inline compact" />
        button in the toolbar at the top to activate New Point Mode. When the button is highlighted
        <Button label="New Point" icon="plus" class="inline compact primary" />
        you will see a date indicator with a ☝🏼 icon that follows your mouse around, if you are using a mouse. <span class="highlight">If you are using a touch screen, no indicator will appear.</span> To place a point, click (or touch) anywhere on the timeline, <span class="highlight">on the desired line</span> (if multiple lines are displayed). A popup will appear to add a point at that date, but you can type in a date manually if you choose. Click the button again to deactivate New Point Mode when finished.
      </p>
      <div class="spacer"></div>
      <p class="help-header">Reposition Points</p>
      <p>Click on the
        <Button label="Reposition" icon="pointer" class="inline compact" />
        button in the toolbar at the top to activate Reposition Mode. Similar to placing new points, when the button is highlighted
        <Button label="Reposition" icon="pointer" class="inline compact primary" />
        click (or touch) and drag any point on the timeline to reposition it to a new point in time. This allows you to reorganize multiple points across multiple lines at the same time. Click the button again to deactivate it when finished.
      </p>
      <div class="spacer"></div>

      <div class="close-help row spaced">
        <Button
          label="Close"
          @click.native="helpVisible = false"
        />
      </div>
    </ModalContainer>

  </div>
</template>

<script>
  import ImmersiveBackground from '../ImmersiveBackground.vue';
  import NewPoint from './NewPoint.vue';
  import NewLine from './NewLine.vue';
  import ElementLink from '../../ElementLink.vue';
  import ElementLinkInput from '../../ElementLinkInput.vue';

  import defaultCalendar from './default_calendar';
  import exampleWorld from '@/components/World/example_world'
  import binaryTest from './timeline_binary_test';

  const MAX_ZOOM = 20000;

  export default {
    name: 'Timeline',
    components: {
      ImmersiveBackground,
      NewPoint,
      NewLine,
      ElementLink,
      ElementLinkInput
    },

    data() {
      return {
        comingFromNew: false,
        helpVisible: false,

        viewportWidth: 0,
        screenWidth: 0,
        cumulativeCalendar: null,
        currentLevelIndex: 0,
        currentParentID: null,

        zoom: 0,
        maxZoom: 30000,
        steps: [11],
        stepSize: 1000,
        displace: 0,

        numGridLines: 11,
        gridLineText: null,

        overlayColor: '#414145',
        overlayOpacity: 0.5,

        isListOpen: false,
        visibleLines: {},
        selectedZone: null,

        newZoneModalVisible: false,
        newZone: {
          name: '',
          start: 0,
          end: 10
        },

        newLineModalVisible: false,

        newPointModeOn: false,
        newPointModalVisible: false,
        newPoint: {
          year: 0,
          month: '',
          day: 0
        },

        editingPoint: {
          point: null,
          line: null
        },

        editLineModeOn: false,
        renamingLine: null,
        renamingZone: null,

        loadingImage: {
          lineIndex: null,
          pointIndex: null
        },

        deleteLineModalVisible: false,
        deleteZoneModalVisible: false,
        deletePointModalVisible: false,
        deletingLine: null,
        deletingZone: null,
        deletingPoint: {
          point: null,
          line: null
        },
        deletingPointImage: null,

        repositioning: false,
        repositioningPoint: null,
        repositioningLine: null,

        cursor: {
          line: null,
          position: 0
        },

        customizeVisible: false,
        customizeAlert: null,
        overlayColor: '#414145',
        overlayOpacity: 0.5,

        dragging: false,
        previousTouch: null,
      }
    },

    computed: {
      timeline() {
        // Example
        if (this.$route.params.id == 'f8a99df00asd') {
          return exampleWorld.timelines.find(timeline => timeline._id == this.$route.params.id)
        }
        return this.$store.state.timelines?.[this.$route.params.id];
      },

      isAuthor() {
        return this.$store.state.user?._id == this.timeline?.authorID
      },

      lines() {
        return this.timeline?.lines || []
      },

      zones() {
        return this.timeline?.zones || {}
      },

      calendar() {
        const selectedCalendarID = 'a4b81';
        return this.$store.state.calendars?.[selectedCalendarID] || defaultCalendar
      },

      daysPerYear() {
        let days = 0;
        const daysPerMonth = this.calendar.months.forEach(month => days += month.numDays)
        return days;
      },

      backgroundImage() {
        return this.timeline?.backgroundImage || 'https://media.scrybe-app.com/assets/timeline_bg.jpg'
      },

      currentLevel() {
        return this.timeline.levels[this.currentLevelIndex] || [];
      },

      currentNodes() {
        const nodes = this.currentLevel.nodes;
        if (!nodes || !Object.keys(nodes).length) return;

        if (this.currentParentID == null) return nodes;

        let filteredNodes = {};
        Object.values(nodes).forEach(node => {
          if (node.parent == this.currentParentID) {
            filteredNodes[node.id] = node;
          }
        });

        return filteredNodes;
      },

      endPoints() {
        if (!this.timeline || !this.selectedZone)  return;

        if (this.selectedZone.start != null && this.selectedZone.end != null) {
          return {
            least: this.selectedZone.start,
            most: this.selectedZone.end + 1
          }
        }

        let least = 0;
        let most = 0;
        if (this.currentNodes) {
          Object.values(this.currentNodes).forEach((node, index) => {
            if (index === 0) {
              least = node.type == 'range' ? node.start : node.position;
              most = node.type == 'range' ? node.end : node.position;
            }
            else {
              if (node.type == 'range') {
                if (node.end > most) most = node.end;
                else if (node.start < least) least = node.start;
              }
              else {
                if (node.position > most) most = node.position;
                else if (node.position < least) least = node.position;
              }
            }
          });
        }
        return { least, most };
      },

      range() {
        return this.endPoints.most - this.endPoints.least;
      },

      gridSpacing() {
        return this.viewportWidth / (this.numGridLines - 1);
      },

      yearsPerGrid() {
        return this.range / (this.numGridLines - 1);
      },

      yearSpacing() {
        return this.gridSpacing / this.yearsPerGrid;
      },

      daySpacing() {
        return this.yearSpacing / this.daysPerYear;
      },

      currentFocus() {
        let halfScreen = this.screenWidth / 2;
        return halfScreen + this.displace - 32;
      },

      dateAtCursor() {
        let position = (this.cursor.position / this.viewportWidth) * this.range;

        let percent = (this.cursor.position % this.yearSpacing) / this.yearSpacing;
        const date = this.yearPercentToDay(percent);

        return {
          year: Math.floor(this.endPoints.least + position),
          month: date.month,
          day: date.day
        }
      },
    },

    methods: {
      updateWidth() {
        if (!this.timeline) return;

        this.viewportWidth = document.getElementById('viewport').clientWidth || 0;
        this.screenWidth = window.innerWidth;
        this.updateGridLines()
      },

      updateGridLines() {
        let zoom = Math.floor(this.zoom / this.stepSize);

        if (zoom >= this.steps.length) return;

        if (this.numGridLines != this.steps[zoom]) {
          this.numGridLines = this.steps[zoom];
          this.updateGridLineText();
        }
      },

      updateGridLineText() {
        let gridLineText = []
        const showMonths = this.yearsPerGrid < 1;
        const showDays = this.yearsPerGrid < (1 / (this.calendar.months?.length));

        for (let index = 0; index < this.numGridLines; index++) {
          let text = { date: '', year: '' }

          let percent = index / (this.numGridLines - 1);
          let year = this.endPoints.least + (index * this.yearsPerGrid);
          text.year = Math.floor(year);

          if (showMonths) {
            let yearPercent = Math.round((year % 1) * 1000) / 1000;
            if (showDays) {
              let date = this.yearPercentToDay(yearPercent);
              text.date += date.month + ' ' + date.day;
            }
            else {
              // let date = this.yearPercentToRoundedDate(yearPercent);
              let date = this.yearPercentToDay(yearPercent);
              text.date = date.month;
            }
          }

          gridLineText[index] = text
        }

        this.gridLineText = gridLineText;
      },

      // updateMaxZoom() {
      //   const numDaysInTimeline = this.daysPerYear * this.range;
      //   console.log('numDaysInTimeline', numDaysInTimeline);
      //
      //   // let maxDaySpacing = 0.5;
      //   let maxDaySpacing = 0.1 + (10000 / numDaysInTimeline);
      //   console.log('maxDaySpacing', maxDaySpacing);
      //   this.maxZoom = maxDaySpacing * numDaysInTimeline;
      //   console.log('maxZoom', this.maxZoom);
      //
      //   // for (let i = 1; i < 40; i++) {
      //   //   let numYears = Math.round(1.5 ** i);
      //   //   let numDays = numYears * 365;
      //   //   let numTenThousandDays = numDays / 10000;
      //   //
      //   //   const slope = 0.0001;
      //   //   const minSteps = 30;
      //   //   let maxSpacing = slope + (minSteps * 1000 / numDays);
      //   //   console.log('years', numYears, 'days', numDays, 'maxSpacing', maxSpacing, 'maxZoom', maxSpacing * numDays, 'maxSteps', Math.round(maxSpacing * numDays / 1000));
      //   // }
      // },

      updateMaxSteps() {
        let maxSteps = Math.floor(this.maxZoom / this.stepSize);

        let steps = []
        let step = 10;
        for (let i = 0; i < maxSteps; i++) {
          steps.push(step + 1);
          let isPowerOf2 = Math.log2(i + 2) % 1 == 0;
          if (isPowerOf2) {
            step *= 2;
          }
        }

        this.steps = steps;
      },

      onScroll() {
        if (this.helpVisible) return;
        this.updateWidth();

        let zoomAmount = -event.deltaY * (1 + (this.zoom / 1000));
        let willZoomTo = this.zoom + zoomAmount;

        const focusPosition = this.currentFocus / this.viewportWidth;

        if (willZoomTo > this.maxZoom) {
          willZoomTo = this.maxZoom;
          zoomAmount = 0;
        }
        if (willZoomTo < 0) {
          willZoomTo = 0;
          zoomAmount = 0;
        }

        this.zoom = willZoomTo;


        let zoomDisplaceCompensation = zoomAmount * focusPosition;

        let willDisplaceTo = this.displace + event.deltaX + zoomDisplaceCompensation;
        if (willDisplaceTo > this.zoom) willDisplaceTo = this.zoom;
        if (willDisplaceTo < 0) willDisplaceTo = 0;

        this.displace = willDisplaceTo;
      },

      isGridLineVisible(index) {
        if (!this.viewportWidth) return;
        let position = this.viewportWidth * (index / this.numGridLines);

        let min = this.displace;
        let max = this.displace + window.innerWidth;

        if (position >= min && position <= max) {
          return true;
        }
        return false;
      },

      isGridLineMonthVisible(index) {
        if (!this.viewportWidth) return;

        if (this.daySpacing < 1) return false;

        let position = this.viewportWidth * ((index - 1) / this.numGridLines);

        const min = this.displace - this.yearSpacing;
        const max = (window.innerWidth - 64) + this.displace;

        if (position >= min && position <= max) {
          return true;
        }
        return false;
      },

      storeLine(id) {
        this.cursor.line = id;
      },

      storePosition(event) {
        if (!Object.keys(this.visibleLines).length) return;

        let screenX = event.pageX;
        if (event.type == 'touchstart' || event.type == 'touchmove') {
          screenX = Math.round(event.touches[0].clientX);
        }
        let x = screenX + this.displace - 32;
        if (x <= 0) x = 0;
        if (x >= this.viewportWidth) x = this.viewportWidth;
        if (x < 5 && this.cursor.position > 100) {
          x = this.viewportWidth;
        }

        this.cursor.position = x;
      },

      onMouseDown() {
        if (this.newPointModeOn) {
          const date = this.dateAtCursor;
          this.newPoint = {
            year: date.year,
            month: date.month,
            day: date.day
          }
          this.newPointModalVisible = true;
        }

        this.storePosition(event)

        if (!this.newPointModeOn) {
          this.dragging = true;
          this.onDrag(event);
        }
      },

      onMouseMove() {
        event.preventDefault();

        this.storePosition(event)

        if (this.dragging) this.onDrag(event);

        if (this.repositioning && this.repositioningPoint) {
          const cursor = this.dateAtCursor;
          const lineIndex = this.lines.findIndex(line => line._id == this.repositioningLine._id)
          const nodeIndex = this.lines[lineIndex].nodes.findIndex(node => node._id == this.repositioningPoint._id)
          this.lines[lineIndex].nodes[nodeIndex].year = cursor.year;
          if (this.lines[lineIndex].nodes[nodeIndex].month || this.lines[lineIndex].nodes[nodeIndex].day) {
            this.lines[lineIndex].nodes[nodeIndex].month = cursor.month;
            this.lines[lineIndex].nodes[nodeIndex].day = cursor.day;
          }
        }

        if (event.type == 'touchstart' || event.type == 'touchmove') {
          this.previousTouch = {
            x: event.touches[0].screenX,
            y: event.touches[0].screenY
          }
          if (event.touches.length > 1) {
            this.previousTouch.x2 = event.touches[1].screenX;
            this.previousTouch.y2 = event.touches[1].screenY;
          }
        }
      },

      onDrag(event) {
        let movementX = 0;
        let movementY = 0;

        if (event.type == 'touchstart' || event.type == 'touchmove') {

          if (this.previousTouch) {
            movementX = event.touches[0].screenX - this.previousTouch.x;
            movementY = event.touches[0].screenY - this.previousTouch.y;
          }

          if (event.touches.length > 1) {
            let movementX2 = 0;
            let movementY2 = 0;

            if (this.previousTouch?.x2 && this.previousTouch?.y2) {
              movementX2 = event.touches[1].screenX - this.previousTouch.x2;
              movementY2 = event.touches[1].screenY - this.previousTouch.y2;

              let previousTouchDistanceX = this.previousTouch.x - this.previousTouch.x2;
              let previousTouchDistanceY = this.previousTouch.y - this.previousTouch.y2;

              let currentTouchDistanceX = event.touches[0].screenX - event.touches[1].screenX;
              let currentTouchDistanceY = event.touches[0].screenY - event.touches[1].screenY;

              let previousTouchDistance = Math.hypot(previousTouchDistanceX, previousTouchDistanceY);
              let currentTouchDistance = Math.hypot(currentTouchDistanceX, currentTouchDistanceY);

              if (currentTouchDistance != previousTouchDistance) {
                this.onPinch(currentTouchDistance - previousTouchDistance)
              }
            }
          }
        }

        else {
          movementX = event.movementX / 2;
        }

        if (!this.repositioningPoint) {
          let willDisplaceTo = this.displace - movementX;
          if (willDisplaceTo > this.zoom) willDisplaceTo = this.zoom;
          if (willDisplaceTo < 0) willDisplaceTo = 0;

          this.displace = willDisplaceTo;
        }
      },

      onPinch(amount) {
        this.updateWidth();

        let zoomAmount = amount * 10;
        let willZoomTo = this.zoom + zoomAmount;

        const focusPosition = this.currentFocus / this.viewportWidth;

        if (willZoomTo > this.maxZoom) {
          willZoomTo = this.maxZoom;
          zoomAmount = 0;
        }
        if (willZoomTo < 0) {
          willZoomTo = 0;
          zoomAmount = 0;
        }

        this.zoom = willZoomTo;


        let zoomDisplaceCompensation = zoomAmount * focusPosition;

        let willDisplaceTo = this.displace + zoomDisplaceCompensation;
        if (willDisplaceTo > this.zoom) willDisplaceTo = this.zoom;
        if (willDisplaceTo < 0) willDisplaceTo = 0;

        this.displace = willDisplaceTo;
      },

      onMouseUp() {
        this.dragging = false;
        this.previousTouch = null;

        this.repositioningPoint = null;
        this.repositioningLine = null;
      },

      clickNode(node, line) {
        if (!this.isAuthor) return;

        if (!this.editingPoint.point && !this.repositioning) {
          event.preventDefault();
        }

        if (this.repositioning) {
          this.repositioningPoint = node;
          this.repositioningLine = line;
        }
        else {
          if (!this.editingPoint.point) {
            this.editingPoint.point = {...node};
            this.editingPoint.line = line;
          }
        }
      },

      openPointImageSelect() {
        document.getElementById('pointImageFileSelect').click()
      },

      async uploadPointImage(lineIndex, nodeIndex) {
        this.editingPoint.point = null
        this.loadingImage = {
          lineIndex,
          nodeIndex
        }

        const image = event.target.files[0]

        let formData = new FormData()
        formData.append('timelineID', this.timeline._id)
        formData.append('lineIndex', lineIndex)
        formData.append('nodeIndex', nodeIndex)
        formData.append('timeline', image, image.name)

        const response = await this.$store.dispatch('uploadTimelinePointImage', formData)
        await this.$store.dispatch('fetchTimeline', this.$route.params.id)

        this.loadingImage = {
          lineIndex: null,
          nodeIndex: null
        }
      },

      clickDeleteImage(lineIndex, nodeIndex) {
        this.deletingPointImage = {
          lineIndex,
          nodeIndex
        }
      },

      async deletePointImage() {
        const { lineIndex, nodeIndex } = this.deletingPointImage
        if (lineIndex == null || nodeIndex == null) {
          return
        }

        this.editingPoint.point = null
        this.loadingImage = {
          lineIndex,
          nodeIndex
        }

        const response = await this.$store.dispatch('deleteTimelinePointImage', {
          timelineID: this.timeline._id,
          lineIndex,
          nodeIndex
        })
        await this.$store.dispatch('fetchTimeline', this.$route.params.id)

        this.loadingImage = {
          lineIndex: null,
          nodeIndex: null
        }
        this.deletingPointImage = null
      },

      async addLink(lineIndex, nodeIndex, element) {
        const newLink = {
          type: element.type,
          ...(element.category && { category: element.category }),
          id: element._id
        }

        const response = await this.$store.dispatch('addTimelineLink', {
          timelineID: this.timeline._id,
          lineIndex,
          nodeIndex,
          link: newLink
        })

        await this.$store.dispatch('fetchTimeline', this.$route.params.id)
        this.editingPoint.point = this.lines?.[lineIndex]?.nodes?.[nodeIndex]
      },

      async removeLink(lineIndex, nodeIndex, link) {
        const response = await this.$store.dispatch('removeTimelineLink', {
          timelineID: this.timeline._id,
          lineIndex,
          nodeIndex,
          linkID: link?.id
        })

        await this.$store.dispatch('fetchTimeline', this.$route.params.id)
        this.editingPoint.point = this.lines?.[lineIndex]?.nodes?.[nodeIndex]
      },

      doneEditing() {
        const validMonth = this.calendar.months?.find(month => month.name == this.editingPoint.point.month)
        if (this.editingPoint.point.month != '' && !validMonth) {
          console.log('Invalid month.');
          this.editingPoint.point = null;
          return;
        }

        const lineIndex = this.lines.findIndex(line => line._id == this.editingPoint.line._id);
        let newNodes = this.lines[lineIndex].nodes || [];
        const nodeIndex = newNodes.findIndex(node => node._id == this.editingPoint.point._id);
        newNodes[nodeIndex] = this.editingPoint.point;

        this.$store.dispatch('updateTimelineNodes', {
          timelineID: this.$route.params.id,
          lineID: this.editingPoint.line._id,
          nodes: newNodes
        });

        this.editingPoint.point = null;
      },

      cancelEditing() {
        this.editingPoint.point = null;
      },

      centerOfRange(node) {
        return node.end - ((node.end - node.start) / 2)
      },

      dateAtPosition(node) {
        let string;
        if (node.type == 'range') {
          return `Year ${node.start.year} - ${node.end.year}`
        }
        else {
          string = `Year ${node.year}`
          if (node.month) string += ` ${node.month}`
          if (node.day) string += ` ${node.day}`
        }
        return string;
      },

      calcPosition(node) {
        let string;

        if (node.type == 'range') {
          let startPosition = (node.start.year - this.endPoints.least) / this.range;
          let endPosition = (node.end.year - this.endPoints.least) / this.range;
          let width = endPosition - startPosition;
          let center = startPosition + (width / 2);
          string = `left: ${center * 100}%; width: ${width * 100}%; padding-left: 0; padding-right: 0;`
        }
        else {
          let position = (node.year - this.endPoints.least) / this.range;
          if (node.month || node.day) {
            position += this.dateToYearPercent(node.month, node.day) / this.range;
          }
          string = `left: ${position * 100}%;`
        }

        return string;
      },

      checkEdge(node) {
        let endPoints = this.endPoints;
        let range = endPoints.most - endPoints.least;
        let ratio = this.viewportWidth / range;
        if (node.type == 'range') {
          let startPositionPercent = (node.start - endPoints.least) / range;
          let endPositionPercent = (node.end - endPoints.least) / range;

          if (endPositionPercent > 0.8) return 'right-edge';
          else if (startPositionPercent < 0.2) return 'left-edge';
          else return '';
        }
        else {
          let positionPercent = (node.position - endPoints.least) / range;

          if (positionPercent > 0.8) return 'right-edge';
          else if (positionPercent < 0.2) return 'left-edge';
          else return '';
        }
      },

      pointDisplayClass(lineID) {
        const line = document.getElementById(`root-${lineID}`)
        if (!line) return ''

        const lineY = line.getBoundingClientRect().y

        // If line is in upper half of screen
        if (lineY / window.innerHeight < 0.5) {
          return 'is-top-half'
        }
        return ''
      },

      addLine(name) {
        let newLines = this.lines || []

        let randomID = Math.round(Math.random() * 99999999).toString(16);
        newLines.push({
          name: name,
          _id: randomID,
          nodes: null
        });

        this.$store.dispatch('updateTimelineLines', {
          timelineID: this.$route.params.id,
          lines: newLines
        });

        this.newLineModalVisible = false;
      },

      addZone() {
        let newZones = this.zones || {}

        let randomID = Math.round(Math.random() * 99999999).toString(16);
        newZones[randomID] = {
          ...this.newZone,
          _id: randomID
        }

        this.$store.dispatch('updateTimelineZones', {
          timelineID: this.$route.params.id,
          zones: newZones
        });

        this.newZoneModalVisible = false;
      },

      addPoint(point) {
        const lineIndex = this.lines.findIndex(line => line._id == this.cursor.line);
        let newNodes = this.lines[lineIndex].nodes || [];

        let randomID = Math.round(Math.random() * 99999999).toString(16);
        newNodes.push({
          ...point,
          _id: randomID,
        });

        this.$store.dispatch('updateTimelineNodes', {
          timelineID: this.$route.params.id,
          lineID: this.cursor.line,
          nodes: newNodes
        });

        this.newPointModalVisible = false;
      },

      clickDeleteLine(line) {
        this.deletingLine = line;
        this.deleteLineModalVisible = true;
      },

      deleteLine() {
        let newLines = this.lines || [];
        const lineIndex = this.lines.findIndex(line => line._id == this.deletingLine._id);
        newLines.splice(lineIndex, 1);

        this.$store.dispatch('updateTimelineLines', {
          timelineID: this.$route.params.id,
          lines: newLines
        });

        this.deletingLine = null;
        this.deleteLineModalVisible = false;
      },

      clickDeleteZone(zone) {
        this.deletingZone = zone;
        this.deleteZoneModalVisible = true;
      },

      deleteZone() {
        let newZones = this.zones || {};
        delete newZones[this.deletingZone._id];

        this.$store.dispatch('updateTimelineZones', {
          timelineID: this.$route.params.id,
          zones: newZones
        });

        if (this.selectedZone._id == this.deletingZone._id) {
          this.changeZone(this.zones['default'])
        }

        this.deletingZone = null;
        this.deleteZoneModalVisible = false;
      },

      clickDeletePoint(node, line) {
        this.deletingPoint.point = node;
        this.deletingPoint.line = line;
        this.deletePointModalVisible = true;
      },

      deletePoint() {
        const lineIndex = this.lines.findIndex(line => line._id == this.deletingPoint.line._id);
        let newNodes = this.lines[lineIndex].nodes || [];
        const nodeIndex = newNodes.findIndex(node => node._id == this.deletingPoint.point._id);
        newNodes.splice(nodeIndex, 1);

        this.$store.dispatch('updateTimelineNodes', {
          timelineID: this.$route.params.id,
          lineID: this.cursor.line,
          nodes: newNodes
        });

        this.deletingPoint.point = null;
        this.deletingPoint.line = null;
        this.deletePointModalVisible = false;
      },

      toggleShowLine(id) {
        if (this.visibleLines[id]) this.$delete(this.visibleLines, id)
        else this.$set(this.visibleLines, id, true)
      },

      renameLine(line) {
        let newLines = this.lines || []
        const lineIndex = this.lines.findIndex(l => l._id == line._id);
        newLines[lineIndex] = this.renamingLine;

        this.$store.dispatch('updateTimelineLines', {
          timelineID: this.$route.params.id,
          lines: newLines
        });

        this.renamingLine = null;
      },

      renameZone() {
        let newZones = this.zones || {}
        newZones[this.renamingZone._id] = this.renamingZone;

        this.$store.dispatch('updateTimelineZones', {
          timelineID: this.$route.params.id,
          zones: newZones
        });

        this.renamingZone = null;
      },

      changeZone(zone) {
        this.selectedZone = zone;
        this.zoom = 0;
        this.displace = 0;

        setTimeout(() => {
          this.updateWidth();
          this.updateGridLineText();
        }, 10)
      },

      toggleRepositioning() {
        this.repositioning = !this.repositioning;

        if (!this.repositioning) {
          const lineIndex = this.lines.findIndex(line => line._id == this.cursor.line);

          this.$store.dispatch('updateTimelineNodes', {
            timelineID: this.$route.params.id,
            lineID: this.cursor.line,
            nodes: this.lines[lineIndex].nodes || []
          });
        }
      },

      formatDate(date) {
        return `Year ${date.year} ${date.month} ${date.day}`
      },

      yearPercentToDay(percent) {
        let day = Math.floor(this.daysPerYear * percent) + 1;
        let month = ''

        if (this.calendar?.months?.length) {
          for (let m of this.calendar.months) {
            if (day <= m.numDays) {
              month = m.name;
              break;
            }
            day -= m.numDays;
          }
        }

        return { month, day }
      },

      yearPercentToRoundedDate(percent) {
        let day = Math.floor(this.daysPerYear * percent) + 1;
        let month = ''

        if (this.calendar?.months?.length) {
          for (let m of this.calendar.months) {
            if (day <= m.numDays / 2) {
              month = m.name;
              break;
            }
            day -= m.numDays;
          }
        }

        return { month, day }
      },

      dateToYearPercent(month, day) {
        let days = (this.cumulativeCalendar[month] || 0) + (day ? day - 1 : 0);

        return days / this.daysPerYear;
      },

      generateCumulativeCalendar() {
        if (!this.calendar?.months?.length) return;

        let cumulativeCalendar = []
        let days = 0;
        for (let month of this.calendar.months) {
          cumulativeCalendar[month.name] = days;
          days += month.numDays;
        }

        this.cumulativeCalendar = cumulativeCalendar;
      },


      changeBackgroundImage() {
        let input = event.target.value;
        if (input.startsWith('http://') || input.startsWith('https://')) {
          this.$store.dispatch('updateTimeline', {
            timelineID: this.$route.params.id,
            backgroundImage: input
          });
        }
      },

      overlayColorChanged(color) {
        this.overlayColor = color;
      },

      overlayOpacityChanged(input) {
        this.overlayOpacity = parseInt(input) / 100;
      },
    },

    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (from.name == 'NewTimeline') vm.comingFromNew = true
      })
    },

    created() {
      // if (this.$route.name == 'Timeline') {
      //   this.$store.dispatch('fetchWorldTimelines').then(response => {
      //     Object.values(this.lines || {}).forEach(line => {
      //       this.$set(this.visibleLines, line._id, true)
      //     });
      //
      //     this.selectedZone = this.zones['default'];
      //     this.updateWidth();
      //     this.updateGridLineText();
      //     this.generateCumulativeCalendar();
      //     this.updateMaxSteps();
      //     // this.updateMaxZoom();
      //   });
      // }
      // else {
        this.$store.dispatch('fetchTimeline', this.$route.params.id).then(response => {
          Object.values(this.lines || {}).forEach(line => {
            this.$set(this.visibleLines, line._id, true)
          });

          this.selectedZone = this.zones['default'];
          this.updateWidth();
          this.updateGridLineText();
          this.generateCumulativeCalendar();
          this.updateMaxSteps();
          // this.updateMaxZoom();
        });
      // }
    },

    mounted() {
      // binaryTest();
      this.updateWidth();
      window.addEventListener('resize', this.updateWidth);
      window.addEventListener('wheel', this.onScroll);
    },

    destroyed() {
      window.removeEventListener('resize', this.updateWidth);
      window.removeEventListener('wheel', this.onScroll);
    }
  }
</script>

<style scoped lang="scss">

  .timeline-page {
    --pointSize: 12px;

    position: fixed;
    padding: 2rem;
  }

  .timeline-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    z-index: -1;
    mask-image: linear-gradient(45deg, rgba(0,0,0,.1), rgba(0,0,0,.25))
  }

  .help {
    text-align: center;
    font-size: 0.9rem;

    .help-title {
      color: $colorA;
      text-transform: uppercase;
    }

    .help-header {
      color: $colorB;
      font-size: 0.9rem;
      font-weight: 600;
    }

    .highlight {
      color: $colorA;
      font-weight: 600;
    }

    .close-help {
      position: sticky;
      bottom: -2rem;
      padding: 1rem;
      width: calc(100% + 4rem);
      background-color: bg(10%);
    }
  }


  // .zoom-levels {
  //   max-width: 120px;
  //   margin: 2rem 0;
  //   z-index: 99;
  //
  //   h5 {
  //     margin-bottom: 0.75rem;
  //     color: $colorA;
  //     text-transform: uppercase;
  //   }
  //
  //   .level {
  //     margin: 0.25rem 0;
  //     padding: 0.5rem 0.75rem;
  //     background-color: rgba(72,81,87,.75);
  //
  //     &.current {
  //       background-color: rgba(51,130,97,.75);
  //     }
  //   }
  // }

  .calendar {
    position: absolute;
    left: 50%;
    padding: 0.5rem 1rem;
    background-color: bg(25%);
    font-size: 0.8rem;
    transform: translateX(-50%);
    user-select: none;
  }

  .title {
    position: absolute;
    top: 0;
    left: 50%;
    pointer-events: none;
    opacity: 0.5;
    font-size: 1.25rem;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    user-select: none;
    transform: translateX(-50%);

    span {
      color: #608edf;
      font-size: 0.9rem;
      font-weight: 600;
    }
  }

  .timeline-toolbar {
    position: relative;
    @include grid($columns: 1fr 1fr 1fr);
    width: 100%;
    height: 40px;
    white-space: pre;

    .toolbar-button-container {
      display: inline-flex;

      .button-component {
        padding: 0.65rem 0.9rem;
      }

      &:nth-child(1) {
        justify-content: flex-start;
      }
      &:nth-child(2) {
        justify-content: center;
      }
      &:nth-child(3) {
        justify-content: flex-end;
      }
    }
  }

  .timeline {
    position: absolute;
    left: 0;
    top: calc(4rem + 40px);
    height: calc(100vh - 4rem - 40px);
    width: calc(100% - 4rem);
    margin: 0 2rem;
    @include flex();
    z-index: -1;
  }

  .timeline-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .root-line {
    position: absolute;
    left: -2rem;
    top: 50%;
    width: calc(100% + 4rem);
    height: 1px;
    background-color: rgba(255,255,255,.5);
  }

  .viewport {
    position: absolute;
    height: 100%;
    @include flex($direction: column);

    &.pointer {
      cursor: pointer;
    }
  }

  .grid-line-container {
    position: absolute;
    top: 50%;
    height: calc(100% - 8rem);
  }

  .viewport-grid-line {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: rgba(255,255,255,.1);
    transform: translateY(-50%);

    div {
      position: absolute;
      @include flex($direction: column);
      top: -0.25rem;
      user-select: none;
      transform: translateY(-100%);

      p, h6 {
        line-height: 1.5em;
        white-space: pre;
      }

      p {
        color: rgba(255,255,255,.5);
        font-size: 0.75rem;
      }

      h6 {
        color: rgba(255,255,255,.5);
        font-size: 0.85rem;
        font-weight: 600;
      }
    }
  }

  // .viewport-grid-line-month {
  //   position: relative;
  //   top: 0;
  //   flex-grow: 0;
  //   flex-shrink: 0;
  //   height: 200px;
  //   border-left: 1px solid rgba(255,255,255,.1);
  //   transform: translateY(-50%);
  //
  //   span {
  //     position: absolute;
  //     color: rgba(255,255,255,.35);
  //     top: 0;
  //     left: 0.25rem;
  //     font-size: 0.75rem;
  //     user-select: none;
  //   }
  // }
  //
  // .months {
  //   position: absolute;
  //   left: 0;
  //   display: flex;
  //   // background-color: rgba(111,172,217,.5);
  // }

  .focus {
    position: absolute;
    top: 50%;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    background-color: #e20000;
  }

  .node-container {
    position: absolute;
    top: 50%;
    z-index: 1;

    &.right-edge .info {
      left: unset;
      right: 0;
      transform: translateX(0);
      align-items: flex-end;
      text-align: right;
    }

    &.left-edge .info {
      left: 0;
      right: unset;
      transform: translateX(0);
      align-items: flex-start;
      text-align: left;
    }

    &.editing {
      z-index: 9;
    }

    &:not(.editing):hover {
      cursor: pointer;

      .range {
        background-color: rgba($colorA,.75);
      }
    }
  }

  .cursor {
    opacity: 0.25;
    pointer-events: none;

    .info {
      top: 2.5rem;
      bottom: unset;
    }

    &.selected {
      opacity: 1;

      .point {
        background-color: $colorA;
      }
    }
  }

  .point-container {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;

    .point-interactive-area {
      padding: 0.5rem;

      .point {
        width: var(--pointSize);
        height: var(--pointSize);
        border-radius: 100%;
        background-color: #fff;
      }
    }

    .info-container {
      position: absolute;
      left: calc(0.5rem + (var(--pointSize) / 2));
      bottom: 1.5rem;
      @include flex($direction: column);
      text-align: center;
      transform: translateX(-50%);
      white-space: nowrap;
      user-select: none;

      img, .image-loader {
        width: 80px;
        height: 80px;
        margin: 0.5rem 0;
      }

      .basic-info {
        padding: 0.75rem;
        border-radius: 0.5rem;

        &.range-info {
          left: 50%;
          top: 2.5rem;
          bottom: unset;
        }
      }

      .editing-info {
        @include flex($direction: column);
        width: 360px;
        padding: 1.25rem;
        border-radius: 0.5rem;
        background-color: #000;

        > *:not(:last-child) {
          margin-bottom: 0.5rem;
        }

        .buttons {
          padding-top: 1rem;
        }
      }

      span {
        color: #d08165;
        font-size: 0.9rem;
        font-weight: 600;
      }

      &.is-top-half {
        top: 1.5rem;
        bottom: unset;
      }
    }

    &:hover {
      .point {
        background-color: $colorA;
      }
      .basic-info {
        background-color: rgba(255,255,255,.1);
      }
    }
  }

  .range {
    width: 100%;
    height: var(--pointSize);
    border-radius: 2px;
    background-color: rgba(255,255,255,.5);
  }

  .links {
    position: absolute;
    top: 1.25rem;
    @include flex($direction: column);
    transform: translateX(-50%);

    > *:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .point-image {
    @include flex();
    margin-bottom: 1rem !important;
  }

  .image, .add-image-box {
    width: 80px;
    height: 80px;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .image {
    @include flex($justify: center);

    img {
      width: 100%;
      height: 100%;
      transition: opacity 0.15s;

      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }

    .icon {
      position: absolute;
      pointer-events: none;
      color: #ec4e4e;
      opacity: 0;
      transition: opacity 0.15s;
      filter: drop-shadow(0 0 16px #000);
    }

    &:hover {
      .icon {
        opacity: 1;
      }
    }
  }

  .add-image-box {
    @include flex($justify: center);
    color: #999;
    border: 1px solid #999;
    transition: color 0.15s, border 0.15s;

    &:hover {
      cursor: pointer;
      color: $colorA;
      border: 1px solid $colorA;
    }
  }

  #pointImageFileSelect {
    display: none;
  }

  .list-container {
    position: fixed;
    @include flex($direction: column);
    top: 1rem;
    right: 1rem;
    width: 320px;
    height: calc(100vh - 2rem);
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: rgba(0,0,0,.5);
    z-index: 99;
    transform: translateX(calc(320px + 1rem));
    transition: transform 0.5s;
    backdrop-filter: blur(16px);

    &.open {
      transform: translateX(0);
    }
  }

  .list {
    @include flex($direction: column);
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .line, .zone {
      @include flex();
      width: 100%;
      margin: 0.5rem 0;
      padding: 1rem;
      border-radius: 0.5rem;
      background-color: #000;

      &:hover {
        cursor: pointer;
        background-color: #222;
      }

      &.selected {
        background-color: $primary;
      }

      .zone-check {
        color: $colorA;
      }
    }

    .line p {
      line-height: 36px;
    }
  }

  .point-list {
    @include flex($direction: column);
    height: 100%;
    overflow-y: auto;

    .item {
      margin: 0.5rem 0;
      padding: 1rem;
      border-radius: 0.5rem;
      background-color: #000;

      &:hover {
        cursor: pointer;
        background-color: #222;
      }

      .column {
        margin-left: 1rem;
        align-items: flex-start;
      }

      .name {
        font-size: 0.9rem;
        font-weight: 600;
      }

      .description {
        font-size: 0.75rem;
        line-height: 1.25em;
      }
    }
  }

  .customize-container {
    position: fixed;
    @include flex($direction: column);
    top: 0;
    right: 0;
    width: 280px;
    height: 100vh;
    padding: 1rem;
    background-color: rgba(0,0,0,.5);
    z-index: 99;
    transform: translateX(280px);
    transition: transform 0.5s;
    backdrop-filter: blur(16px);

    &.open {
      transform: translateX(0);
    }
  }

  .customize-button {
    position: absolute;
    bottom: 2rem;
    right: calc(100% + 2rem);
    border-radius: 0.5rem;
    padding: 1rem;
    color: $colorA;

    &:hover {
      cursor: pointer;
      background-color: rgba(0,0,0,.5);
    }
  }

</style>
