<template>
  <div class="page">

    <template v-if="!user || !world">
      <Loader />
    </template>

    <template v-else>
      <div class="spacer"></div>
      <h1>Manage Members</h1>
      <div class="spacer h3"></div>


      <div class="list-container">
        <Button
          label="Back"
          icon="left-arrow"
          class="inline"
          style="margin-right: auto;"
          @click.native="$router.back()"
        />
      </div>
      <div class="spacer"></div>

      <div class="members-list">
        <Avatar :userID="user._id" horizontal />
        <Avatar v-for="member in members" :userID="member" horizontal />
      </div>


      <template v-if="!members.length">
        <div class="spacer"></div>
        <p class="hint">You, the author, are the only member.</p>
      </template>

      <div class="spacer h5"></div>
      <h2>Invite A Friend</h2>
      <div class="spacer h1"></div>
      <div class="invite-description">
        <p class="hint">Invite a friend to your world on Scrybe via email. If they are not a Scrybe member, they will be invited to register a free account.</p>
      </div>
      <div class="spacer"></div>

      <div class="invite-email row spaced">
        <input
          type="text"
          placeholder="Friend's Email"
          :value="inviteEmail"
          @input="(e) => inviteEmail = e.target.value"
        />
        <Button
          label="Send Invitation"
          icon="user"
          class="primary inline"
          :disabled="!isEmailValid"
          @click.native="sendInvitation"
        />
      </div>
      <Alert :alert="alert" @dismiss="alert = null" />

      <div class="spacer h5"></div>
      <h3>Pending Invitations</h3>
      <div class="spacer h1"></div>
      <p v-for="invitation in invitations" class="hint">{{ invitation.invitedMemberEmail }}</p>
      <p v-if="!invitations.length" class="hint">There are no pending invitations.</p>

      <div class="spacer h5"></div>
      <div class="spacer h5"></div>

    </template>


  </div>
</template>

<script>
  import Avatar from '../User/Avatar.vue';

  export default {
    name: 'ManageMembers',
    components: {
      Avatar
    },

    computed: {
      user() {
        return this.$store.state.user
      },
      world() {
        return this.$store.state.world
      },
      members() {
        return this.world?.members || []
      },
      invitations() {
        return this.world?.invitations || []
      },
      isEmailValid() {
        return !!this.inviteEmail.trim()
      }
    },

    data() {
      return {
        inviteEmail: '',
        alert: null
      }
    },

    methods: {
      sendInvitation() {
        if (!this.isEmailValid) {
          return;
        }

        this.$store.dispatch('inviteMember', {
          invitedMemberEmail: this.inviteEmail,
          worldID: this.world?._id
        }).then(response => {
          console.log(response);

          this.inviteEmail = ''
          if (response?.status == 201) {
            this.alert = {
              type: 'success',
              message: 'Invitation sent successfully.'
            }
          }
          else {
            this.alert = {
              type: 'error',
              message: response?.data?.error
            }
          }
        })
      }
    },
  }
</script>

<style scoped lang="scss">

  .list-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }

  .members-list {
    @include flex($justify: center);
    flex-wrap: wrap;

    > * {
      margin: 1rem;
    }
  }

  .invite-description {
    width: 100%;
    max-width: 600px;
    text-align: center;
  }

  .invite-email {
    width: 100%;
    max-width: 450px;
    text-align: center;
  }

</style>
