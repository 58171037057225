<template>
  <div class="page about-scrybe">
    <div class="spacer h5"></div>
    <h1 class="colorful-title">About Scrybe</h1>
    <div class="spacer h5"></div>

    <p>Who are we?</p>
    <div class="spacer h5"></div>

    <h2>Meet the Developer</h2>
    <div class="paragraph meet-the-developer">
      <div class="img"></div>
      <div class="description">
        <h3>James Vargo</h3>
        <p class="hint">Architect & Sole Developer</p>
        <p>I'm a fantasy nerd, always have been.</p>
        <p>I work professionally as a software engineer.</p>
        <p>I'm a jack of all trades. I'm deeply interested in philosphy, music, art & design, science, technology, economics, and most of all, stories.</p>
        <p>This means that my weakness is in spreading myself out too thin. But I also find a lot of joy in the integration of several disciplines.</p>
        <p>I made Scrybe because I felt there were no user friendly alternatives for worldbuilding applications. I wanted a place people who loved to build imaginary worlds could come together, share ideas, and have useful tools at their disposal, whether that be fantasy, sci-fi, or any other genre.</p>
      </div>
    </div>
    <div class="spacer h5"></div>

    <h2>The History of Scrybe</h2>
    <div class="paragraph">
      <p>Scrybe started with a note in my phone in late 2016.</p>
      <p>I was living in Los Angeles and had just graduated college. I studied Film Production and Music Composition. I wanted to be a Hollywood composer.</p>
      <p>In college, my peers and I were always dreaming up ideas for a short film, or an animated series, or a documentary. What stuck with me about being a young aspiring filmmaker and collaborator were two major things.</p>
      <p>The first was that communicating your idea how it plays out in your head is extremely hard. Even the best communicators, the best directors, the best writers, they all struggle with it.</p>
      <p>The second was that even if you managed to communicate well, the cost to put that idea onto a screen was ridiculously expensive. It was expensive in time, in effort, and especially in money.</p>
      <p>So after getting my degree which was supposed to lead me to becoming a Hollywood composer, I started revisiting my old high school hobby - programming. And I once again got that spark of inspiration, that feeling that there's endless potential out there.</p>
      <p>In May of 2016, in the notes app on my phone, along with the vast list of other random ideas, I wrote this note:</p>
      <ul>
        <p class="hint">Interactive book: narrative text on screen, reader can flip through the story with music and artwork for each "scene"</p>
      </ul>
      <p>I imagined this interactive book would include various interactive elements, like maps and timelines, or archives of research data about the world. I wanted a tool that allowed you to actually see and experience another world, not just imagine it in your head.</p>
      <p>This led me to look into worldbuilding apps. In 2016, there were very few apps out there for worldbuilding, mostly forums and niche tools scattered around the internet.</p>
      <p>So I decided to make one.</p>
      <p>After a first pass on what I called the "Writing Room", the project got put on hold when I moved to Japan in 2017.</p>
      <p>A few years later, I had discovered that people were making their own worldbuilding sites. I felt relieved, and thought briefly that the app that I always wanted had finally come into existence. As I explored every site I could get my hands on, however, I quickly became disappointed.</p>
      <p>While I was glad worldbuilding was becoming more popular, none of the apps I encountered felt up to scratch. Confusing, difficult to use interfaces, UIs that felt stuck in the 2000s, hard-coded plugins from third parties that offered no flexibility. The list goes on.</p>
      <p>We can do better, I thought.</p>
      <p>So after working professionally as a software engineer, I finally decided to restart the project from scratch at the start of 2021.</p>
      <p>Almost 2 years later, and both an Alpha and Beta test release, Scrybe has officially launched and gone public.</p>
    </div>

    <h2>The Future of Scrybe</h2>
    <div class="paragraph">
      <p>Scrybe has become my baby. I admit I have a tight grip on it, and it's sad knowing that will need to change.</p>
      <p>As Scrybe gets bigger and grows up, it will need more support.</p>
      <p>We are planning to hire more developers to join the Scrybe team, so we can create the platform that the worldbuilding community deserves.</p>
      <p>The best way to support the site is to use it, and give us your feedback. The better the product becomes, the more people will want to use it. And the more people use it, the more means we have to work with to make it even better.</p>
      <p>I'm personally happy about anyone using the site, even on a free account.</p>
      <p>If you want to see this project progress, consider subscribing. More funds means more and/or better developers, which means faster progress.</p>
      <p>If you really, really want to see this project progress even faster, consider donating using the link below. You will go down in Scrybe's hall of fame as one of the original believers, who wanted to make the world(building) a better place.</p>
      <p>Do we want your money? Of course we do. But ultimately, your money is yours. You don't need me to tell you that.</p>
    </div>

    <div class="spacer h5"></div>
    <div class="spacer h5"></div>
  </div>
</template>


<script>
  export default {
    name: 'AboutScrybe',
  }
</script>


<style scoped lang="scss">

  .about-scrybe {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }

  .paragraph {
    width: 100%;
    margin: 2rem 0;
    padding: 2rem;

    p {
      margin-bottom: 2rem;
    }
  }

  .meet-the-developer {
    @include flex($align: flex-start);

    .img {
      width: 120px;
      height: 120px;
      flex-shrink: 0;
      margin-right: 2rem;
      border-radius: 100%;
      background-color: #535961;
    }
  }

</style>
