<template>
  <div class="book-icon-library-container">
    <div class="book-icon-library">
      <BookIcon v-for="index in numBooks"
        :name="bookList[(index - 1) % bookList.length].name"
        :rotation="bookList[(index - 1) % bookList.length].rotation" />
    </div>
  </div>
</template>

<script>
  import BookIcon from './BookIcon.vue';

  export default {
    name: 'BookIconLibrary',
    components: {
      BookIcon
    },
    data() {
      return {
        bookList: [
          { name: 'plain big', rotation: 0 },
          { name: 'plain small', rotation: 0 },
          { name: 'deco small', rotation: 10 },
          { name: 'elegant wide', rotation: 0 },
          { name: 'plain small', rotation: -5 },
          { name: 'plain big', rotation: 0 },
          { name: 'deco narrow', rotation: 0 },
          { name: 'elegant narrow', rotation: 0 },
          { name: 'space narrow', rotation: 0 },
          { name: 'deco small', rotation: 0 },
          { name: 'plain narrow', rotation: 10 },
          { name: 'plain narrow', rotation: 0 },
          { name: 'plain small', rotation: 0 },
          { name: 'deco small', rotation: 0 },
          { name: 'plain narrow', rotation: 0 },
          { name: 'elegant wide', rotation: 0 },
          { name: 'plain small', rotation: 0 },
          { name: 'plain big', rotation: 0 },
          { name: 'plain small', rotation: 0 },
          { name: 'plain narrow', rotation: 0 },
          { name: 'plain narrow', rotation: 0 },
          { name: 'plain small', rotation: -10 },
          { name: 'space wide', rotation: 0 },
          { name: 'deco small', rotation: 0 },
          { name: 'plain narrow', rotation: 0 },
          { name: 'deco narrow', rotation: -8 },
          { name: 'deco small', rotation: 0 },
          { name: 'space narrow', rotation: 0 },
          { name: 'plain big', rotation: 0 },
          { name: 'plain small', rotation: 0 },
          { name: 'deco small', rotation: 10 },
          { name: 'elegant wide', rotation: 0 },
          { name: 'elegant narrow', rotation: 0 },
          { name: 'plain narrow', rotation: -10 },
          { name: 'space wide', rotation: 0 },
        ],
        numBooks: 1000
      }
    }
  }
</script>

<style scoped lang="scss">

  .book-icon-library-container {
    position: absolute;
    // height: 600px;
    // overflow: hidden;
  }

  .book-icon-library {
    position: relative;
    opacity: 0.25;
  }

</style>
