<template>
  <div
    class="element-link-input"
    @wheel.stop=""
  >

    <input
      type="text"
      :value="inputText"
      placeholder="Type to search..."
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
    />

    <div
      v-if="elements && elements.length && isFocused"
      class="select-element-dropdown"
    >
      <div
        v-for="element in elements"
        class="element"
        @mousedown.bubble.stop="selectElement(element)"
      >
        <Icon
          v-if="elementIcon(element.type)"
          :name="elementIcon(element.type)"
          size="0.9rem"
        />
        <p>{{ element.title }}</p>
      </div>
    </div>

  </div>
</template>


<script>
  const DEBOUNCE_TIME = 500

  export default {
    name: 'ElementLinkInput',

    data() {
      return {
        lastInput: Date.now(),
        inputText: '',
        elements: [],
        isFocused: false
      }
    },

    methods: {
      elementIcon(element) {
        switch (element) {
          case 'writing':
            return 'book'
          case 'wiki':
            return 'wizard'
          case 'timeline':
            return 'time'
          case 'map':
            return 'map'
          case 'language':
            return ''
          default:
            return ''
        }
      },

      onInput() {
        this.inputText = event.target.value
        this.lastInput = Date.now()
        window.setTimeout(this.checkDebounce, DEBOUNCE_TIME)
      },

      onFocus() {
        this.isFocused = true
      },

      onBlur() {
        this.isFocused = false
      },

      checkDebounce() {
        const timeSinceLastInput = Date.now() - this.lastInput

        if (timeSinceLastInput >= DEBOUNCE_TIME) {
          this.search()
        }
      },

      async search() {
        if (this.inputText.trim()) {
          const response = await this.$store.dispatch('searchElements', {
            search: this.inputText
          })
          if (response?.status == 200) {
            this.elements = response?.data || []
          }
        }
        else {
          // clear options
          this.elements = []
        }
      },

      selectElement(element) {
        this.$emit('select', element)
        this.inputText = ''
        this.elements = []
      }
    },
  }
</script>


<style scoped lang="scss">

  .element-link-input {
    position: relative;
    width: 100%;
  }

  .select-element-dropdown {
    position: absolute;
    bottom: 0;
    left: 0;
    @include flex($direction: column, $align: flex-start);
    width: 100%;
    max-height: 200px;
    padding: 0.5rem 0;
    background-color: bg(15%);
    transform: translateY(100%);
    overflow-y: auto;
    z-index: 10;

    .element {
      @include flex();
      margin: 0.5rem 1rem;
      color: $colorB;

      .icon {
        margin-right: 0.75rem;
      }

      p {
        color: inherit;
        font-size: 0.9rem;
        font-weight: 600;
      }

      &:hover {
        cursor: pointer;
        color: $colorA;
      }
    }
  }

</style>
