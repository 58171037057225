<template>
  <div class="start-creating">

    <div class="spacer h5"></div>
    <h1>Step One</h1>
    <div class="spacer h5"></div>

    <p>Worlds are the backbone of Scrybe. Within a world, you can create writings, wiki pages, maps, timelines, and more.</p>
    <div class="spacer"></div>
    <p>Let's start by creating a world.</p>

    <div class="spacer h5"></div>

    <CoolButton
      icon="right-arrow"
      label="Create World"
      textColor="#a3ffd0"
      bgColor="rgb(36,255,189)"
      type="compact"
      animation="infinite"
      @click.native="$router.push('/create-world')"
    />

    <div class="spacer h5"></div>
    <div class="spacer h5"></div>
    <div class="spacer h5"></div>

  </div>
</template>

<script>
  export default {
    name: 'StartCreating',
    components: {

    },
  }
</script>

<style scoped lang="scss">

  .start-creating {
    @include flex($direction: column);
    padding: 2rem;
    text-align: center;
  }

  .glow {
    filter: drop-shadow(0 0 8px rgba(255,255,255,.75));
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

</style>
