<template>
  <div :class="`help-menu floating ${$route.name != 'HelpHome' ? 'top' : ''}`">

    <div class="row spaced">
      <Button
        label="FAQ"
        icon="question"
        :class="`inline ${$route.name == 'FAQ' ? 'primary' : 'secondary'}`"
        @click.native="$router.push('/help/faq')"
      />
      <Button
        label="Terms Of Service"
        icon="user"
        :class="`inline ${$route.name == 'TermsOfService' ? 'primary' : 'secondary'}`"
        @click.native="$router.push('/help/terms-of-service')"
      />
      <Button
        label="Privacy Policy"
        icon="lock"
        :class="`inline ${$route.name == 'PrivacyPolicy' ? 'primary' : 'secondary'}`"
        @click.native="$router.push('/help/privacy-policy')"
      />
    </div>

    <!-- <div class="articles">
      <ul>
        <li><router-link to="/help/storing-passwords">How we store passwords</router-link></li>
        <li><router-link to="/help/inappropriate-content">How we handle inappropriate content</router-link></li>
      </ul>
    </div> -->

  </div>
</template>

<script>
  export default {
    name: 'HelpMenu',
    components: {

    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped lang="scss">

  .help-menu {
    position: sticky;
    top: 0rem;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: bg(20%);
    z-index: 3;

    &.top {
      width: 100%;
      padding: 1rem;
      border-radius: 0 0 0.75rem 0.75rem;
    }
  }

  @media (max-width: 980px) {
    .help-menu.top {
      border-radius: 0;
    }
  }

  h3 {
    text-align: center;
  }

  ul {
    margin: 0;
    padding-left: 0;
    list-style-position: inside;

    li {
      margin: 0.5rem 0;
      color: neutral(60%);
    }
  }

  a {
    color: $colorB;

    &:hover {
      cursor: pointer;
      color: $colorA;
    }

    &.router-link-active {
      color: $colorA;
    }
  }

  .articles {
    @include flex($direction: column, $align: center);
    width: 100%;
    max-width: 600px;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: bg(20%);

    .hint {
      text-align: center;
    }

    .buttons {
      > * {
        margin: 0.25rem;
      }
    }
  }

</style>
