<template>
  <div class="faq">

    <h1 class="colorful-title">Frequently Asked Questions</h1>
    <div class="spacer h3"></div>

    <h4>Payment</h4>
    <Details label="Is there a free version?">
      <p>Writings and Wikis are free to everyone. There is currently no free trial for the other elements.</p>
      <div class="spacer h1"></div>
      <p>While Scrybe is in Beta, all users who register before the official release will get 1 year free for all elements.</p>
    </Details>
    <Details label="How do I get a refund?">
      <p>If you are unhappy with the platform, or have experienced some technical issue, feel free to request a refund via the <router-link to="/support">Support</router-link> page.</p>
      <div class="spacer h1"></div>
      <p>Note: Only refund requests for subscriptions less than 14 days old will be accepted, unless exceptional circumstances are present.</p>
    </Details>
    <Details label="Can I pay for only Maps, for example?">
      <p>Yes, all elements on Scrybe are billed individually, so you can customize your subscription to suit your needs. You only pay for what you use.</p>
      <div class="spacer h1"></div>
      <p>Check out our <router-link to="/pricing">Pricing</router-link> page for more details.</p>
    </Details>
    <Details label="How do you handle credit card information?">
      <p>We don't. Scrybe does not see or touch any credit card information or billing address information.</p>
      <div class="spacer h1"></div>
      <p>All payment is handled through <a href="https://stripe.com" target="_blank">Stripe</a>, the world's most trusted electronic payment service.</p>
      <div class="spacer h1"></div>
      <p>Check out our <router-link to="/help/privacy-policy">Privacy Policy</router-link> page for more details.</p>
    </Details>

    <div class="spacer"></div>

    <h4>Data</h4>
    <Details label="I want to cancel my subscription. Will my data still be there?">
      <p>Yes, we hold onto your data, unless you specifically request we delete it.</p>
      <div class="spacer h1"></div>
      <p>If you stop using the platform for whatever reason, be it emergency, disinterest, or just the busyness of life, rest assured all your data will be here for you when you get back.</p>
    </Details>
    <Details label="Can I hold onto my data myself?">
      <p>Not yet, but this is coming soon!</p>
      <div class="spacer h1"></div>
      <p>This is high up on our To Do list. We will be providing an option to download all your data with the click of a button, so you can hold onto it, print it out, or do whatever else you like with it.</p>
      <div class="spacer h1"></div>
      <p>Or, if you don't trust us in case things go sideways. Though we hope you still do. 😊</p>
    </Details>

  </div>
</template>

<script>
  export default {
    name: 'FAQ',
    components: {

    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped lang="scss">

  .faq {
  }

  h1.colorful-title {
    font-size: 2rem;
  }

  h3 {
    text-align: center;
  }

  h4 {
    margin-bottom: 0.5rem;
  }

  ul {
    margin: 0;
    padding-left: 0;
    list-style-position: inside;

    li {
      margin: 0.5rem 0;
      color: neutral(60%);
    }
  }

  a, /deep/ .details a {
    color: $colorB;
    font-weight: 600;

    &:hover {
      cursor: pointer;
      color: $colorA;
    }
  }

  /deep/ .details .label {
    color: $colorB;

    &:hover {
      color: $colorA;
    }
  }

</style>
