<template>
  <div class="page">

    <div class="spacer h5"></div>

    <h1
      class="colorful-title glow animate"
      data-glow-text="Create New World"
    >Create New World</h1>


    <div class="spacer h4"></div>

    <div class="inputs floating">
      <input
        type="text"
        placeholder="World Name"
        @input="(e) => name = e.target.value"
      />
      <textarea
        placeholder="Description"
        @input="(e) => description = e.target.value"
      />
    </div>

    <div class="spacer h1"></div>
    <p class="hint">World name and description can be changed at any time.</p>
    <div class="spacer h3"></div>

    <div class="row">
      <Button
        label="Back"
        icon="left-arrow"
        iconSize="12px"
        style="margin-right: 1rem;"
        @click.native="$router.back()"
      />
      <Button
        label="Create World"
        class="primary"
        @click.native="createWorld"
      />
    </div>

    <div class="spacer"></div>

    <Alert :alert="alert" @dismiss="alert = null" />

    <div class="spacer h5"></div>

  </div>
</template>

<script>
  export default {
    name: 'CreateWorld',

    data() {
      return {
        alert: null,

        name: null,
        description: null
      }
    },

    methods: {
      async createWorld() {
        if (!this.name?.trim()) {
          this.alert = {
            type: 'warning',
            message: 'Please enter a name for your world.'
          }
          return;
        }

        let newWorld = {
          name: this.name,
          description: this.description
        }

        const response = await this.$store.dispatch('createWorld', newWorld)
        if (response.status == 201) {
          this.alert = {
            type: 'success',
            message: 'World created successfully.'
          }
          await this.$store.dispatch('getMe')
          await this.$store.dispatch('fetchCurrentUserWorld')
          window.setTimeout(() => {
            this.$router.push('/creation')
          }, 500);
        }
      }
    }
  }
</script>

<style scoped lang="scss">

  h1.colorful-title {
    font-size: 2rem;
  }

  .inputs {
    width: 100%;
    max-width: 600px;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: bg(20%);

    textarea {
      height: 8rem;
    }

    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .required {
    color: #ff2553;
  }

</style>
