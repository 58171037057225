<template>
  <div class="page">
    <!-- <Banner title="Community" :image="require('@/assets/community_bg.jpg')" /> -->

    <div class="header-image-container">
      <div class="header-image"></div>
    </div>



    <div class="spacer"></div>
    <div class="search-bar-container">
      <SearchBar
        @search="search"
        @clear="searchResults = null;"
        :searchCleared="searchCleared"
      />
    </div>
    <div class="spacer"></div>



    <template v-if="!searchResults">

      <div class="spacer"></div>

      <TabSelect
        :tabs="['worlds', 'posts']"
        @select="(tab) => $router.push('/community/' + tab)"
        :selectedTab="selectedTab"
      />

      <div class="spacer h5"></div>
      <router-view />
      <div class="spacer h5"></div>

    </template>



    <template v-else>

      <div class="toolbar-row">
        <div class="left">
          <Button
            label="Back"
            icon="left-arrow"
            class="inline dark"
            @click.native="clearSearch"
          />
        </div>
        <div class="center">
          <h2>Search Results</h2>
        </div>
      </div>

      <div class="search-results">
        <template v-for="result in searchResults">
          <WorldPreview
            class="result"
            :worldObject="result"
          />
        </template>
        <p v-if="!searchResults.length">No results found.</p>
      </div>

    </template>

  </div>
</template>

<script>
  import WorldBrowser from './WorldBrowser.vue';
  import PostBrowser from './PostBrowser.vue';
  import WorldPreview from '../World/WorldPreview.vue';

  export default {
    name: 'CommunityHome',
    components: {
      WorldBrowser,
      PostBrowser,
      WorldPreview,
    },

    data() {
      return {
        selectedTab: 'worlds',
        searchLoading: false,
        searchResults: null,
        searchCleared: false
      }
    },

    watch: {
      '$route': function(route) {
        this.setSelectedTab()
      }
    },

    methods: {
      setSelectedTab() {
        const segments = this.$route.path.split('/')
        const tab = segments?.length ? segments[segments.length - 1] : 'worlds'
        this.selectedTab = tab
      },

      search(text) {
        this.searchLoading = true;

        this.$store.dispatch('search', text).then(response => {
          this.searchLoading = false;
          if (response.status == 200) {
            this.searchResults = response.data
          }
        })
      },

      clearSearch() {
        this.searchResults = null;
        this.searchCleared = !this.searchCleared;
      }
    },

    created() {
      this.setSelectedTab()
    },

    mounted() {
      this.$store.dispatch('access')
    }
  }
</script>

<style scoped lang="scss">

  .search-bar-container {
    width: 100%;
    max-width: 600px;
    margin: auto;
  }

  .toolbar-row {
    max-width: 800px;
    margin: auto;
  }

  .search-results {
    @include flex($justify: center, $align: flex-start);
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 2rem;

    .result {
      margin: 1rem;
    }
  }

  h2 {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    text-transform: uppercase;
    white-space: pre;
  }

  .header-image-container {
    position: absolute;
    width: 100vw;
    height: 1000px;
    overflow: hidden;
    z-index: -1;
  }

  .header-image {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    // background-image: url('~@/assets/community_bg.jpg');
    // background-image: linear-gradient(0deg, rgba(57,84,42,.8), rgba(57,84,42,.5)), url('https://images.unsplash.com/photo-1592853338606-6df619d6fadd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2342&q=80');
    // background-image: linear-gradient(0deg, rgba(57,84,42,.8), rgba(57,84,42,.5)), url('https://images.unsplash.com/photo-1530122037265-a5f1f91d3b99?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80');
    background-image: linear-gradient(0deg, rgba(23,52,17,1), rgba(23,52,17,.6)), url('https://images.unsplash.com/photo-1566475955255-404134a79aeb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80');
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    transform: scale(1.02);
    // filter: blur(2px);
    mask-image: linear-gradient(0deg, rgba(0,0,0,0), rgba(0,0,0,.3) 50%, rgba(0,0,0,.75));
  }

</style>
