
export default [
  { label: 'Character Bios', color: 'A', yOffset: 10, delay: 0.4 },
  { label: 'Places', color: 'B', yOffset: 55, delay: 3 },
  { label: 'Chapter Drafts', color: 'C', yOffset: 65, delay: 1 },
  { label: 'Languages', color: 'D', yOffset: 70, delay: 5.4 },
  { label: 'Maps', color: 'A', yOffset: 15, delay: 3.6 },
  { label: 'Wiki Articles', color: 'B', yOffset: 115, delay: 2.6 },
  { label: 'Plot Outlines', color: 'C', yOffset: 105, delay: 9.2 },
  { label: 'Scene Drafts', color: 'D', yOffset: 55, delay: 8.8 },
  { label: 'Dialogue', color: 'A', yOffset: 10, delay: 11 },
  { label: 'Names', color: 'B', yOffset: 110, delay: 6 },
  { label: 'Races & Cultures', color: 'C', yOffset: 10, delay: 7.6 },
  { label: 'Magic Systems', color: 'D', yOffset: 65, delay: 17.8 },
  { label: 'Subplot Lines', color: 'A', yOffset: 70, delay: 12.4 },
  { label: 'Family Trees', color: 'B', yOffset: 110, delay: 14.8 },
  { label: 'History & Mythology', color: 'C', yOffset: 20, delay: 16 }
]
