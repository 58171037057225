<template>
  <div class="landing-section roadmap">

    <div class="section-bg">
      <!-- <img class="bg-image" src="https://cdnb.artstation.com/p/assets/images/images/004/940/409/large/damien-mammoliti-pzo92100-ustalav-map.jpg?1487351382" /> -->
      <img class="bg-image" src="https://images.unsplash.com/photo-1617140178977-4df0c1255e75?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80" />
    </div>


    <div class="section-content">

      <h1 class="colorful-title">Coming Soon</h1>
      <div class="spacer"></div>

      <div class="description">
        <p>This is only the beginning.</p>
        <br>
        <p>We will continue to release new features as they are completed, and we'll be looking for lots of user feedback. So feel free to share your feedback with us via our <router-link to="/support">Support</router-link> page.</p>
        <br>
        <p>We've only just begun, but you can help shape the future of Scrybe.</p>
      </div>
      <div class="spacer"></div>
      <div class="row">
        <Button
          label="View All Updates"
          icon="right-arrow"
          class=""
          @click.native="$router.push('/updates')"
        />
      </div>

      <template v-if="timeUntilRelease">
        <div class="spacer h5"></div>
        <div class="small-horizontal-line"></div>
        <div class="spacer h5"></div>
        <p><span class="promotion">All features, 1 year free</span></p>
        <div class="spacer"></div>
        <div class="about-hook">
          <TypewriterText
            text="During the promotional period for the Beta release, all new Scrybe users will receive one year free acceess to all Scrybe features."
            :delay="1000"
            :stagger="15"
            on
          />
          <!-- <p>During the promotional period for the Beta release, all new Scrybe users will receive one year free acceess to all Scrybe features.</p> -->
        </div>
        <div class="spacer"></div>
        <p>Promotion ends in:</p>
        <h1 class="colorful-title" style="font-size: 2rem;">{{ timeUntilRelease }}</h1>
        <div class="spacer"></div>
      </template>

      <div class="register-container">
        <CoolButton
          label="Create a Free Account"
          type="compact"
          textColor="#66f1d0"
          bgColor="rgb(102,241,208)"
          selected
          animation="infinite"
          @click.native="$router.push('/register')"
        />
      </div>

    </div>


    <div class="spacer h5"></div>
    <ProgressBubbles />
    <div class="spacer h5"></div>

  </div>
</template>


<script>
  import ProgressBubbles from './ProgressBubbles.vue'

  export default {
    name: 'Roadmap',
    components: {
      ProgressBubbles
    },
    props: {
      timeUntilRelease: [String]
    }
  }
</script>


<style scoped lang="scss">

  .roadmap {
    margin-top: -40px;

    h1 {
      margin-bottom: 0;

      &.colorful-title {
        opacity: 1;
      }
    }

    .section-bg {
      background: radial-gradient(circle at bottom center, #27263b, #121318);
      // clip-path: polygon(0 0, 100% 40px, 100% 100%, 0 100%);

      .bg-image {
        mask-image: radial-gradient(circle at 65% 80%, rgba(0,0,0,.25), rgba(0,0,0,.1) 35%, rgba(0,0,0,0) 100%);
      }
    }

    .section-content {
      @include flex($direction: column);
      grid-gap: 0;

      .description {
        max-width: 600px;
      }
    }
  }

  a {
    color: $colorB;
    font-weight: 600;

    &:hover {
      color: $colorA;
    }
  }

  .promotion {
    color: $colorA;
    font-size: 1.25rem;
    font-weight: 600;
    filter: drop-shadow(0 0 8px rgba(149,255,195,.75));
  }

</style>
