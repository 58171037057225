<template>
  <div class="reading-room">

    <ImmersiveBackground
      :backgroundImage="backgroundImage"
      :overlayColor="overlayColor"
      :overlayOpacity="overlayOpacity"
      style="z-index: -1;" />

    <BackButton
      @click.native="$router.back()"
    />

    <div class="reading-area-container">
      <div class="reading-area">
        <p v-for="line in content" :style="line.style">{{ line.content }}</p>
      </div>
    </div>

  </div>
</template>

<script>
  import ImmersiveBackground from '../Creation/ImmersiveBackground.vue';
  import exampleWorld from '@/components/World/example_world'

  export default {
    name: 'Writing',
    components: {
      ImmersiveBackground
    },

    data() {
      return {
        overlayColor: '#414145',
        overlayOpacity: 0.5,
      }
    },
    computed: {
      writing() {
        return exampleWorld?.writings?.[this.$route.params.id];
      },

      backgroundImage() {
        return this.writing?.backgroundImage;
      },

      content() {
        return this.writing?.content;
      }
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">

  .reading-room {
    position: relative;
    padding: 2rem;
    // position: fixed;
    // @include flex($direction: column, $justify: center, $align: center);
    // width: 100vw;
    // height: 100vh;
    // top: 0;
    // left: 0;
    // padding: 4rem;
    // box-sizing: border-box;
    // z-index: 999;
    // background-color: rgba(0,0,0,.5);
  }

  .reading-area-container {
    position: fixed;
    width: calc(100% - 4rem);
    height: calc(100% - 4rem);
    margin-bottom: 1rem;
    box-sizing: border-box;
    white-space: pre-wrap;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .reading-area {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 4rem;
    padding-top: 6rem;
    box-sizing: border-box;
    overflow-y: auto;

    p {
      margin-bottom: 1em;
      text-shadow: 0 0 0.5em #000;
    }
  }

</style>
