<template>
  <div class="page">

    <div class="spacer"></div>
    <h1>Generate Map</h1>
    <div class="spacer"></div>

    <div class="row" style="max-width: 800px;">
      <BackButton normal
        @click.native="$router.back()"
      />
    </div>
    <div class="spacer"></div>

    <p class="hint">Use this tool to kickstart ideas for your own maps.</p>


    <div class="spacer"></div>
    <div :class="`controls floating ${loading ? 'disabled' : ''}`">
      <div class="row">
        <div class="control-item">
          <h5>Width (px)</h5>
          <input type="number"
            :value="width"
            :min="100" :max="2000" :step="1"
            @change="widthChanged"
            :disabled="loading"
          />
        </div>
        <Icon name="link" style="margin-top: 2.5rem; color: #aaa;" />
        <div class="control-item">
          <h5>Height (px)</h5>
          <input type="number"
            :value="height"
            :min="100" :max="2000" :step="1"
            @change="heightChanged"
            :disabled="loading"
          />
        </div>
      </div>

      <div class="control-item">
        <div class="row">
          <h5>Detail</h5>
          <p class="slider-value">{{ layers.length }}</p>
          <Hint
            icon="question"
            origin="right"
            bgColor="#249363"
            color="#fff"
            class="detail-hint"
            small
          >
            <p><b>What is Detail?</b></p>
            <br/>
            <p>To generate random terrain, we generate images of smooth randomness using a technique called "Perlin Noise."</p>
            <br/>
            <p>We stack increasingly finer layers of this noise on top of each other to achieve higher resolution while maintaining the macro structure of the landmass.</p>
            <br/>
            <p>The Detail setting represents the number of layers generated.</p>
            <br/>
            <p>1 - Low Detail (smooth edges, blob-like)</p>
            <p>5 - High Detail (crisp edges, realistic)</p>
          </Hint>
        </div>
        <Slider
          :min="1"
          :max="5"
          :step="1"
          :value="layers.length"
          @change="layersChanged"
        />
      </div>

      <div class="control-item">
        <div class="row align-left">
          <h5>Strength</h5>
          <p class="slider-value">{{ strength }}</p>
          <Hint
            icon="question"
            origin="right"
            bgColor="#249363"
            color="#fff"
            class="detail-hint"
            small
          >
            <p><b>What is Strength?</b></p>
            <br/>
            <p>This controls how strictly the map generator should follow the node structure given below.</p>
            <br/>
            <p>0 - Completely ignore the node structure, map is completely random.</p>
            <br/>
            <p>1 - Follow the node structure rigidly (warning: extremely high strength settings will likely result in undesirable lines and artifacts)</p>
          </Hint>
        </div>
        <Slider
          :min="0"
          :max="1"
          :step="0.01"
          :value="strength"
          @change="(value) => strength = parseFloat(value)"
        />
      </div>

      <div class="spacer h1"></div>
      <div class="row spaced" style="z-index: 0;">
        <Button
          label="Back"
          icon="back-arrow"
          class="dark"
          @click.native="$router.back()"
        />
        <Button
          label="Generate"
          class="primary"
          @click.native="generate"
        />
      </div>
    </div>



    <div class="spacer h5"></div>


    <p class="hint">Select the nodes below to customize region types.</p>
    <div class="spacer h1"></div>

    <div class="map-generator-container">
      <canvas id="map-generator"
        :width="width"
        :height="height"
        :style="`transform: translateX(-50%) translateY(calc(-50% + ${height * canvasScale / 2}px)) scale(${canvasScale});`"
      />

      <div v-if="!loading && regionNodesVisible"
        class="map-generator-nodes"
        :style="`width: ${width * canvasScale}px; height: ${height * canvasScale}px;`"
      >
        <div v-for="(row, row_i) in regionNodes" class="node-row">
          <div v-for="(node, node_i) in row" class="node-region">
            <div class="node-container">

              <div :class="`node ${node} ${isSelected(node_i, row_i) ? 'selected' : ''}`"
                @click.bubble="selectNode(node_i, row_i)"
              >
                <Icon :name="regionIcons[node]" />
              </div>

              <div v-if="isSelected(node_i, row_i)" class="node-options">
                <div v-for="(icon, region) of regionIcons"
                  :class="`region ${region}`"
                  @click="selectRegion(node_i, row_i, region)"
                >
                  <div :class="`node ${region}`">
                    <Icon :name="icon" />
                  </div>
                  <p class="region">{{ region }}</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div :style="`height: ${canvasScale * height}px;`"></div>


    <div class="spacer"></div>

    <div v-if="loading" class="loading-progress-container">
      <Loader size="72px" />
      <div class="spacer h5"></div>
      <div class="spacer"></div>
      <LoadingBar v-if="loading" height="8" :progress="loadingProgress" />
      <div class="spacer h1"></div>
      <p>{{ `${loadingProgress} %` }}</p>
      <div class="spacer h1"></div>
      <p>{{ `${pixelsDrawn.toLocaleString()} / ${maxPixels.toLocaleString()} pixels` }}</p>

      <div class="spacer"></div>
      <Button
        label="Stop"
        icon="x"
        class="delete inline"
        @click.native="stop = true;"
      />
      <div class="spacer"></div>
    </div>

    <template v-if="!loading && imageCreated">
      <div class="row spaced">
        <Checkbox
          label="Show Region Nodes"
          color="#32ff98"
          :checked="regionNodesVisible"
          @checkbox-toggle="regionNodesVisible = !regionNodesVisible"
        />
      </div>
      <div class="spacer"></div>
      <div class="row spaced">
        <Button
          label="Download"
          icon="download"
          @click.native="downloadMap"
        />
        <Button
          label="Use Map"
          icon="check"
          class="primary"
          @click.native="useMap"
        />
      </div>
    </template>

    <div class="spacer"></div>


    <div class="spacer h5"></div>
  </div>
</template>

<script>
  import { pause } from '@/utility';

  export default {
    name: 'MapGenerator',
    components: {
    },
    data() {
      return {
        loading: false,
        stop: false,
        imageCreated: false,
        pixelsDrawn: 0,
        maxPixels: 0,
        loadingProgress: 0,
        canvasScale: 0.5,

        context: null,
        position: {
          x: 0,
          y: 0
        },

        width: 800,
        height: 800,

        baseColor: 'rgba(53,73,107,1)',

        regionNodes: [
          ['water', 'land', 'mountains', 'mountains'],
          ['water', 'water', 'land', 'land'],
          ['water', 'water', 'water', 'land'],
          ['water', 'water', 'water', 'water'],
        ],
        regionIcons: {
          'water': 'water',
          'land': 'wheat',
          'mountains': 'mountain',
        },
        selectedRegionNode: null,
        regionNodesVisible: true,
        nodeWeights: {
          // 0 = flat, 1 = bumpy
          'land': 0.57,
          'mountains': 1,
          'water': 0.39,
        },

        strength: 0.8,
        layers: [0.5, 0.3, 0.13, 0.05, 0.02],
        thresholds: null,

        nodes: [
          {
            grid: null,
            weight: 0.5
          },
          {
            grid: null,
            weight: 0.5
          },
          {
            grid: null,
            weight: 0.5
          },
          {
            grid: null,
            weight: 0.5
          },
          {
            grid: null,
            weight: 0.5
          }
        ],

        regions: {
          OCEAN: {
            threshold: 0.39,
            color: 'hsl(207,83%,32%)',
            min_lightness: 0.25
          },
          COAST: {
            threshold: 0.4,
            color: 'hsl(197,43%,78%)',
            min_lightness: 0.65
          },
          LAND: {
            threshold: 0.57,
            color: 'hsl(31,16%,50%)',
            min_lightness: 0.65
          },
          FOREST: {
            threshold: 1,
            color: 'hsl(117,41%,50%)',
            min_lightness: 0.5
          }
        },
      }
    },

    methods: {
      isSelected(node_i, row_i) {
        return this.selectedRegionNode && this.selectedRegionNode.x == node_i && this.selectedRegionNode.y == row_i
      },

      selectNode(node_i, row_i) {
        if (this.isSelected(node_i, row_i)) {
          this.selectedRegionNode = null
        }
        else {
          this.selectedRegionNode = { x: node_i, y: row_i }
        }
      },

      selectRegion(node_i, row_i, region) {
        this.$set(this.regionNodes[row_i], node_i, region)
        this.selectedRegionNode = null
      },

      setPosition() {
        this.position = {
          x: event.offsetX,
          y: event.offsetY
        }
      },
      draw() {
        // If mouse is not pressed
        if (!event.buttons) return;

        this.context.beginPath();
        this.context.lineWidth = 5;
        this.context.lineCap = 'round';
        this.context.strokeStyle = '#86d4ca';

        this.context.moveTo(this.position.x, this.position.y);
        this.setPosition(event);
        this.context.lineTo(this.position.x, this.position.y);

        this.context.stroke();
      },



      widthChanged() {
        this.width = Math.floor(parseInt(event.target.value));
        if (this.width < 100) this.width = 100;
        if (this.width > 2000) this.width = 2000;
        this.height = this.width;
        setTimeout(() => {
          this.updateDimensions();
        }, 10)
      },

      heightChanged() {
        this.height = Math.floor(parseInt(event.target.value));
        if (this.height < 100) this.height = 100;
        if (this.height > 2000) this.height = 2000;
        this.width = this.height;
        setTimeout(() => {
          this.updateDimensions();
        }, 10)
      },

      layersChanged(value) {
        let numLayers = parseInt(value);
        if (numLayers < 1) numLayers = 1;
        if (numLayers > 5) numLayers = 5;

        let layers = [0.5, 0.3, 0.13, 0.05, 0.02]
        if (numLayers == 4) {
          layers = [0.5, 0.32, 0.15, 0.03]
        }
        else if (numLayers == 3) {
          layers = [0.55, 0.35, 0.1]
        }
        else if (numLayers == 2) {
          layers = [0.75, 0.25]
        }
        else if (numLayers == 1) {
          layers = [1]
        }

        this.layers = layers;
      },

      updateDimensions() {
        this.context.fillStyle = this.baseColor;
        this.context.fillRect(0, 0, this.width, this.height);
      },





      weightedRandomNumber(target, strength) {
        let distanceFromTarget = 1 - strength;
        let min = Math.round((target - distanceFromTarget) * 10) / 10;
        let max = Math.round((target + distanceFromTarget) * 10) / 10;

        return (Math.random() * (max - min)) + min;
      },

      randomVector() {
        let theta1 = Math.random() * 2 * Math.PI;
        let theta2 = Math.random() * 2 * Math.PI;

        return {
          x: Math.cos(theta1),
          y: Math.cos(theta2)
        }

        // // sin(theta) should be in range [0, 1]
        // let max = Math.PI / 2;
        //
        // let theta1 = Math.random() * max;
        // let theta2 = Math.random() * max;
        //
        // return {
        //   x: Math.sin(theta1),
        //   y: Math.sin(theta2)
        // }
      },

      weightedRandomVector(x, y) {
        // let theta1 = Math.random() * 2 * Math.PI;
        // let theta2 = Math.random() * 2 * Math.PI;
        let region = this.regionNodes[x][y];
        let weight = this.nodeWeights[region] ?? 1;

        // sin(theta) should be in range [0, 1]
        let max = Math.PI / 2;
        let theta1 = this.weightedRandomNumber(weight, this.strength) * max;
        let theta2 = this.weightedRandomNumber(weight, this.strength) * max;

        // return {
        //   x: Math.cos(theta1),
        //   y: Math.cos(theta2),
        //   region
        // }
        return {
          x: Math.sin(theta1),
          y: Math.sin(theta2),
          region
        }
      },

      generateNodeGrid(layerNum, xNodes, yNodes) {
        let grid = [];

        // if (layerNum == 0) {
        //   for (let i = 0; i < xNodes; i++) {
        //     let row = [];
        //     for (let j = 0; j < yNodes; j++) {
        //       row.push(this.weightedRandomVector(i, j));
        //     }
        //     grid.push(row);
        //   }
        // }
        // else {
          for (let i = 0; i < xNodes; i++) {
            let row = [];
            for (let j = 0; j < yNodes; j++) {
              row.push(this.randomVector());
            }
            grid.push(row);
          }
        // }

        this.nodes[layerNum].grid = grid;
      },

      dotProduct(x, y, layer, nodeX, nodeY) {
        let node = this.nodes[layer].grid[nodeX][nodeY];
        let distance = {
          x: x - nodeX,
          y: y - nodeY
        };
        return distance.x * node.x + distance.y * node.y;

        // let weightedX = node.x * (1 - distance.x);
        // let weightedY = node.y * (1 - distance.y);
        // // return average
        // return (weightedX + weightedY) / 2;
      },

      interpolate(x, a, b) {
        let smoothX = 6*x**5 - 15*x**4 + 10*x**3;
        return a + smoothX * (b - a);
      },

      perlinNoise(x, y, layer) {
        // x and y are pixel coordinates
        // We need to transform these coordinates to
        // locate which node the pixel is in

        // factor = percent of node grid traversed by each pixel
        // e.g. 200x200 pixel canvas, 4x4 node grid
        // factor = 0.015 = each pixel traverses 1.5% of grid
        let widthFactor = (3 ** (layer + 1) - 1) / this.width;
        let heightFactor = (3 ** (layer + 1) - 1) / this.height;
        let adjX = x * widthFactor;
        let adjY = y * heightFactor;

        // Get the corners of each edge of the node
        let left = Math.floor(adjX);
        let right = left + 1;
        let top = Math.floor(adjY);
        let bottom = top + 1;

        // Get the distances (weights) from each corner
        let topLeft = this.dotProduct(adjX, adjY, layer, left, top);
        let topRight = this.dotProduct(adjX, adjY, layer, right, top);
        let bottomLeft = this.dotProduct(adjX, adjY, layer, left, bottom);
        let bottomRight = this.dotProduct(adjX, adjY, layer, right, bottom);

        // Interpolate a weighted average horizontally, then vertically
        let distanceX = adjX - left;
        let topAverage = this.interpolate(distanceX, topLeft, topRight);
        let bottomAverage = this.interpolate(distanceX, bottomLeft, bottomRight);
        let distanceY = adjY - top;
        let centerAverage = this.interpolate(distanceY, topAverage, bottomAverage);

        return centerAverage;
      },

      calculateHeight(x, y) {
        let sum = 0;

        for (let i = 0; i < this.layers.length; i++) {
          sum += this.perlinNoise(x, y, i) * this.layers[i];
        }

        // convert from [-1, 1] to [0, 1]
        let height = (sum + 1) / 2;

        height = this.weightOpacityToRegionNode(x, y, height);

        if (height < 0) height = 0;
        if (height > 1) height = 1;

        return height;
      },

      determineRegion(opacity) {
        return this.thresholds[Math.round(opacity * 100)]
      },

      weightNumber(number, target, strength) {
        let distanceFromTarget = 1 - strength;
        let min = Math.round((target - distanceFromTarget) * 10) / 10;
        let max = Math.round((target + distanceFromTarget) * 10) / 10;

        return (number * (max - min)) + min;
      },

      weightOpacityToRegionNode(x, y, opacity) {
        // factor = percent of node grid traversed by each pixel
        // e.g. 200x200 pixel canvas, 4x4 node grid
        // factor = 0.015 = each pixel traverses 1.5% of grid
        let widthFactor = 3 / this.width;
        let heightFactor = 3 / this.height;
        let adjX = x * widthFactor;
        let adjY = y * heightFactor;

        // Get the nearest region node
        let left = Math.floor(adjX);
        let right = left + 1;
        let top = Math.floor(adjY);
        let bottom = top + 1;

        // Get weights of the nearest region nodes
        let topLeft = this.nodeWeights[this.regionNodes[top][left]];
        let topRight = this.nodeWeights[this.regionNodes[top][right]];
        let bottomLeft = this.nodeWeights[this.regionNodes[bottom][left]];
        let bottomRight = this.nodeWeights[this.regionNodes[bottom][right]];

        // Get average weight of all region nodes for (x, y)
        let distanceX = adjX - left;
        let distanceY = adjY - top;
        let topAverage = this.interpolate(distanceX, topLeft, topRight);
        let bottomAverage = this.interpolate(distanceX, bottomLeft, bottomRight);
        let centerAverage = this.interpolate(distanceY, topAverage, bottomAverage);

        // Weight opacity to target by strength amount
        let target = centerAverage;
        let distanceFromTarget = 1 - this.strength;
        let min = Math.round((target - distanceFromTarget) * 100) / 100;
        let max = Math.round((target + distanceFromTarget) * 100) / 100;

        return (opacity * (max - min)) + min;
      },

      async drawPixel(x, y, height) {
        const { OCEAN, COAST, LAND, FOREST } = this.regions;

        this.context.beginPath();
        this.context.fillStyle = `rgba(255,255,255,${height})`;

        let region = this.determineRegion(height);
        // this.context.fillStyle = this.regions[region].color;

        let regionalOpacity = height / this.regions[region].threshold;
        let lightness = this.regions[region].min_lightness + ((1 - this.regions[region].min_lightness) * regionalOpacity);
        this.context.fillStyle = this.calcColor(region, lightness);

        this.context.fillRect(x, y, 1, 1);
      },

      updateProgress() {
        this.loadingProgress = Math.round((this.pixelsDrawn / this.maxPixels) * 100);
      },

      calcColor(region, opacity) {
        return this.regions[region].color.replace(`${this.regions[region].max_lightness}%)`, `${Math.round(opacity * this.regions[region].max_lightness)}%)`);
      },

      generateThresholds() {
        let thresholds = []
        for (let i = 0; i <= 100; i++) {
          for (let region in this.regions) {
            if (i <= this.regions[region].threshold * 100) {
              thresholds[i] = region;
              break;
            }
          }
        }
        this.thresholds = thresholds;
      },

      async generate() {

        const UPDATE_EVERY_X_PIXELS = 1000;

        this.loading = true;
        this.stop = false;
        this.maxPixels = this.width * this.height;
        this.pixelsDrawn = 0;
        this.loadingProgress = 0;

        await pause(50);

        this.regions.OCEAN.max_lightness = parseInt(/%,(.*?)%\)/.exec(this.regions.OCEAN.color)[1]);
        this.regions.COAST.max_lightness = parseInt(/%,(.*?)%\)/.exec(this.regions.COAST.color)[1]);
        this.regions.LAND.max_lightness = parseInt(/%,(.*?)%\)/.exec(this.regions.LAND.color)[1]);
        this.regions.FOREST.max_lightness = parseInt(/%,(.*?)%\)/.exec(this.regions.FOREST.color)[1]);

        this.context.clearRect(0, 0, this.width, this.height);

        // // Calculate number of nodes based on aspect ratio
        // // In order to avoid a squashed look
        // if (this.width > this.height) {
        //   let ratio = this.width / this.height;
        //   this.yNodes = Math.round(this.regionNodes / ratio);
        //   this.xNodes = this.regionNodes;
        // }
        // else {
        //   let ratio = this.height / this.width;
        //   this.xNodes = Math.round(this.regionNodes / ratio);
        //   this.yNodes = this.regionNodes;
        // }


        this.generateThresholds()

        for (let i = 0; i < this.layers.length; i++) {
          this.generateNodeGrid(i, 3 ** (i + 1), 3 ** (i + 1));
        }

        console.log(JSON.parse(JSON.stringify(this.nodes)));

        this.context.fillStyle = `rgba(6,43,91,1)`;
        this.context.fillRect(0, 0, this.width, this.height);

        // i = index in height (y coordinate)
        // j = index in width (x coordinate)
        for (let i = 0; i < this.height; i++) {
          for (let j = 0; j < this.width; j++) {
            await this.drawPixel(j, i, this.calculateHeight(j, i));
            this.pixelsDrawn++;
            if (this.pixelsDrawn % UPDATE_EVERY_X_PIXELS === 0) {
              if (this.stop) break;
              await pause(1);
              this.updateProgress();
            }
          }
          if (this.stop) break;
        }

        this.loading = false;
        this.imageCreated = true;
      },

      useMap() {
        let canvas = document.getElementById('map-generator');
        canvas.toBlob((blob) => {
          let url = URL.createObjectURL(blob);
          this.$store.commit('saveGeneratedMap', url)
          this.$router.push('/new-map');
        })
      },

      downloadMap() {
        let canvas = document.getElementById('map-generator');
        let imageData = canvas.toDataURL();

        // let slicedImageData = imageData.substring(23);

        let link = document.createElement('a');
        link.download = 'generated_map.png';
        link.href = imageData;
        link.click();
      },






    },

    mounted() {
      let canvas = document.getElementById('map-generator');
      this.context = canvas.getContext('2d');

      this.updateDimensions()

      // canvas.addEventListener('mouseenter', this.setPosition);
      // canvas.addEventListener('mousedown', this.setPosition);
      // canvas.addEventListener('mousemove', this.draw);
    },
    beforeDestroy() {
      // window.removeEventListener('mouseenter', this.setPosition);
      // window.removeEventListener('mousedown', this.setPosition);
      // window.removeEventListener('mousemove', this.draw);
    }
  }
</script>

<style scoped lang="scss">

  .detail-hint {
    margin-left: auto;

    /deep/ .content {
      width: 320px;
    }
  }

  .map-generator-container {
    position: relative;
    width: 1px;
    height: 1px;
  }

  #map-generator {
    position: absolute;
    top: -50%;
    left: -50%;
    border: 1px solid rgba(255,255,255,.1);
    transform-origin: center;
  }

  .map-generator-nodes {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include flex($direction: column);
    transform: translateX(-50%);

    .node-row {
      position: relative;
      width: 100%;
      height: 100%;
      @include flex();

      .node-region {
        position: relative;
        width: 100%;
        height: 100%;
        @include flex($justify: center);
      }
    }
  }

  .node-container {
    position: relative;

    .node {
      position: relative;
      padding: 0.5rem;
      border-radius: 100%;
      background-color: #fff;
      z-index: 8;

      .icon {
        color: #fff;
      }

      &.selected {
        border: 1px solid #fff;
      }

      &:hover {
        cursor: pointer;
        background-color: #ffd177;
      }

      &.land {
        background-color: #af904d;
        &:hover { background-color: #c3a462; }
      }
      &.mountains {
        background-color: #827b76;
        &:hover { background-color: #9a938e; }
      }
      &.water {
        background-color: #399aff;
        &:hover { background-color: #60acfc; }
      }
    }
  }

  .node-options {
    position: absolute;
    top: -0.5rem;
    left: 50%;
    padding: 1.25rem 1.5rem;
    border-radius: 0.5rem;
    background-color: #000;
    transform: translate(-50%, -100%);
    z-index: 9;

    .region {
      @include flex();
      margin: 0.5rem 0;
      padding: 2px 8px;
      border-radius: 0.25rem;
      border: 1px solid #fff;

      p {
        margin-left: 0.5rem;
        font-size: 0.9rem;
        text-transform: capitalize;
        border: 0;
      }

      &:hover {
        cursor: pointer;
      }

      &.land {
        border-color: #af904d;
        &:hover { border-color: #c3a462; }
      }
      &.mountains {
        border-color: #827b76;
        &:hover { border-color: #9a938e; }
      }
      &.water {
        border-color: #399aff;
        &:hover { border-color: #60acfc; }
      }
    }
  }

  .controls {
    width: 100%;
    max-width: 300px;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: bg(20%);

    div.row {
      margin: 0 !important;
    }

    .control-item {
      width: 100%;
      @include flex($direction: column, $align: flex-start);
      padding: 0.5rem;

      h5 {
        margin-left: 0.5rem;
        margin-bottom: 0.25rem;
        color: $colorC;
        font-size: 0.9rem;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 2rem;
      }
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .slider-value {
    margin-left: 0.75rem;
    padding: 0.25rem 0.75rem;
    border-radius: 0.5rem;
    border: 1px solid $colorC;
  }

  .loading-progress-container {
    text-align: center;
  }

</style>
