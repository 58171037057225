<template>
  <div class="select-writing-item">

    <div :class="`list-item ${data.type}`"
      @click="clickFileOrFolder(data)"
    >
      <Icon
        v-if="data.type === 'folder'"
        class="folder-arrow"
        :name="isExpanded ? 'less-arrow' : 'more-arrow'"
        size="16px" />
      <p class="title">{{ data.title }}</p>
      <p :class="`content ${emptyFolder}`">{{ contentPreview }}</p>
      <p class="last-updated">{{ formatDateDelta(data.lastUpdated) }} ago</p>
    </div>

    <SelectWritingItem
      v-if="isExpanded"
      v-for="doc in data.docs"
      class="sub-doc"
      :data="doc"
      @select="(writing) => $emit('select', writing)" />

  </div>
</template>

<script>
  import SelectWritingItem from './SelectWritingItem.vue';

  export default {
    name: 'SelectWritingItem',
    components: {
      SelectWritingItem,
    },
    props: {
      data: Object
    },
    data() {
      return {
        isExpanded: false
      }
    },
    computed: {
      contentPreview() {
        const { type, docs, content } = this.data;
        if (type === 'folder') {
          return docs && docs.length ? docs.length : '(empty)';
        }
        if (!content) return '';

        let contentString = typeof content == 'string' ? content : content.map(line => line.content).join(' ');
        return contentString.length > 50 ? contentString.substring(0, 50) + '...' : contentString;
      },
      emptyFolder() {
        if (this.data.type !== 'folder') return '';
        return this.data.docs && this.data.docs.length ? '' : 'empty';
      }
    },
    methods: {
      clickFileOrFolder(writing) {
        if (writing.type === 'empty') return;
        if (writing.type === 'folder') this.isExpanded = !this.isExpanded;
        else this.$emit('select', writing);
      },
    }
  }
</script>

<style scoped lang="scss">

  .select-writing-item {
    width: 100%;
  }

  .list-item {
    position: relative;
    @include flex($justify: flex-start, $align: center);
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    user-select: none;

    &.folder, &.new-folder {
      color: #58af97;
      border-bottom: 1px solid transparent;

      .title {
        color: #58af97;
        font-size: 0.85rem;
      }

      .icon {
        margin-right: 0.5rem;
      }
    }

    .folder-arrow {
      opacity: 0.25;
      transition: opacity 0.15s;
    }

    &:hover {
      cursor: pointer;
      background-color: #212225;

      .title {
        color: $colorB;
      }

      .folder-arrow {
        opacity: 1;
      }
    }

    .title {
      margin-right: 1.5rem;
      color: #fff;
      font-size: 0.85rem;
      font-weight: 600;
    }
    .content {
      color: #aaa;
      font-size: 0.9rem;

      &.empty {
        color: #555;
      }
    }
    .last-updated {
      margin-left: auto;
      font-size: 0.75rem;
      color: $colorA;
    }

    &.current-doc {
      .title {
        color: #5baee3;
      }
    }

    &.empty {
      .title {
        color: #777;
        font-weight: 300;
      }
    }
  }

  .select-writing-item:not(:last-child) {
    border-bottom: 1px solid #333;
  }

  .sub-doc {
    margin-left: 1rem;
  }

  .add-folder, .add-file, .delete {
    position: absolute;
    padding: 0.5rem;
    border-radius: 0.5rem;
    top: 0.6rem;
    color: #888;

    &:hover {
      color: #fff;
      background-color: rgba(#fff,.25);
    }
  }

</style>
