

export default {
  'a': { _id: 0, order: 0, rune: 'assets/runes/aniron/a.png' },
  'b': { _id: 1, order: 1, rune: 'assets/runes/aniron/b.png' },
  'c': { _id: 2, order: 2, rune: 'assets/runes/aniron/c.png' },
  'd': { _id: 3, order: 3, rune: 'assets/runes/aniron/d.png' },
  'e': { _id: 4, order: 4, rune: 'assets/runes/aniron/e.png' },
  'f': { _id: 5, order: 5, rune: 'assets/runes/aniron/f.png' },
  'g': { _id: 6, order: 6, rune: 'assets/runes/aniron/g.png' },
  'h': { _id: 7, order: 7, rune: 'assets/runes/aniron/h.png' },
  'i': { _id: 8, order: 8, rune: 'assets/runes/aniron/i.png' },
  'j': { _id: 9, order: 9, rune: 'assets/runes/aniron/j.png' },
  'k': { _id: 10, order: 10, rune: 'assets/runes/aniron/k.png' },
  'l': { _id: 11, order: 11, rune: 'assets/runes/aniron/l.png' },
  'm': { _id: 12, order: 12, rune: 'assets/runes/aniron/m.png' },
  'n': { _id: 13, order: 13, rune: 'assets/runes/aniron/n.png' },
  'o': { _id: 14, order: 14, rune: 'assets/runes/aniron/o.png' },
  'p': { _id: 15, order: 15, rune: 'assets/runes/aniron/p.png' },
  'q': { _id: 16, order: 16, rune: 'assets/runes/aniron/q.png' },
  'r': { _id: 17, order: 17, rune: 'assets/runes/aniron/r.png' },
  's': { _id: 18, order: 18, rune: 'assets/runes/aniron/s.png' },
  't': { _id: 19, order: 19, rune: 'assets/runes/aniron/t.png' },
  'u': { _id: 20, order: 20, rune: 'assets/runes/aniron/u.png' },
  'v': { _id: 21, order: 21, rune: 'assets/runes/aniron/v.png' },
  'w': { _id: 22, order: 22, rune: 'assets/runes/aniron/w.png' },
  'x': { _id: 23, order: 23, rune: 'assets/runes/aniron/x.png' },
  'y': { _id: 24, order: 24, rune: 'assets/runes/aniron/y.png' },
  'z': { _id: 25, order: 25, rune: 'assets/runes/aniron/z.png' },
}
