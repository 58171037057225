
export default {

  visibility: {
    private: {
      value: 'private',
      label: 'Private',
      icon: 'lock',
      color: '#7381ff'
    },
    public: {
      value: 'public',
      label: 'Public',
      icon: 'eye',
      color: '#66f1d0'
    }
  },


  wiki: {
    categories: {
      characters: {
        value: 'characters',
        label: 'Characters',
        icon: 'witch hat',
        color: '#66e9f1'
      },
      places: {
        value: 'places',
        label: 'Places',
        icon: 'mountain',
        color: '#f5c568'
      },
      magic: {
        value: 'magic',
        label: 'Magic',
        icon: 'magic',
        color: '#fc6b81'
      },
      events: {
        value: 'events',
        label: 'Events',
        icon: 'stopwatch',
        color: '#53f189'
      }
    }
  }

}
