<template>
  <div class="privacy-policy">

    <h1 class="colorful-title">Privacy Policy</h1>
    <div class="spacer h1"></div>
    <p class="hint" style="text-align: center;">Last Updated: August 10, 2022</p>
    <div class="spacer"></div>


    <ol class="table-of-contents">
      <li><a @click="goToSection('logs')">Logs & User Activity</a></li>
      <li><a @click="goToSection('passwords')">Passwords</a></li>
      <li><a @click="goToSection('creditCard')">Credit Card & Address Information</a></li>
      <li><a @click="goToSection('ip')">Intellectual Property</a></li>
    </ol>


    <h3>Introduction</h3>

    <p>The following article details the ways in which Scrybe handles and stores personal information, and maintains security of its users' data.</p>


    <h4 ref="logs">1. Logs & User Activity</h4>

    <p>Scrybe logs and keeps records of all server activity, for the purpose of security and identifying illegal activity, as well as for business analytics.</p>
    <p>Activity such as the following will be logged:</p>
    <ul>
      <li>Authentication: user registration, login attempts, reset password requests, etc.</li>
      <li>Service Usage: creating a world, updating a map, uploading an image, etc.</li>
      <li>Error Messages: invalid or forbidden requests, internal server errors, etc.</li>
      <li>Client Information: IP address, user agent (browser & device information)</li>
    </ul>


    <h4 ref="passwords">2. Passwords</h4>

    <p>As is common practice in web security, Scrybe does not store passwords in plain text. We use high security hashing algorithms to create a hash of the user's password, which is stored in the database and checked against during login.</p>
    <p>In the unlikely event of a data leak, only the hashes will be leaked, thereby protecting users' passwords.</p>
    <p>For more information about how password authentication works, check out <router-link to="/help/storing-passwords">this article</router-link>.</p>


    <h4 ref="creditCard">3. Credit Card & Address Information</h4>

    <p>Scrybe does not see or touch any credit card information or billing address information. All payment is handled through <a href="https://stripe.com" target="_blank">Stripe</a>, the world's most trusted electronic payment service.</p>
    <p>All forms and pages which ask for or contain credit card or address information is hosted by Stripe, and cannot be seen by Scrybe.</p>
    <p>Scrybe only receives limited information about the payment transaction from Stripe, such as:</p>
    <ul>
      <li>User email</li>
      <li>Transaction date & time</li>
      <li>Payment status</li>
      <li>Payment amount</li>
      <li>Card validity</li>
    </ul>
    <p>Stripe maintains PCI (Payment Card Industry) compliance, and is trusted by millions of websites in dozens of countries throughout the world.</p>


    <h4 ref="ip">4. Intellectual Property</h4>

    <p>Scrybe does its best to offer its user as many options as possible for which data is publically visible and which is only privately accessible.</p>
    <p>According to Scrybe's <router-link to="/help/terms-of-service">Terms of Service</router-link>, all users agree to publically post only content of which they own the rights to, or to which they have explicit permission from the owner to post publically.</p>
    <p>While Scrybe does not legally ensure copyright protection for its users' intellectual property, we do our best to secure user data and hold electronic records of user activity, such that a Scrybe user can provide evidence in the event of a copyright dispute.</p>

  </div>
</template>

<script>
  export default {
    name: 'PrivacyPolicy',

    methods: {
      goToSection(name) {
        const element = this.$refs[name];
        if (!element) return;

        element.scrollIntoView()
      }
    },

    mounted() {
      console.log(window.scrollY);
    }
  }
</script>

<style scoped lang="scss">

.privacy-policy {
}

h1.colorful-title {
  font-size: 2rem;
}

h2 {
  text-align: center;
}

h3 {
  color: $colorA;
  font-weight: 300;
  margin-top: 5rem;
  margin-bottom: 1rem;
}

h4 {
  color: $colorB;
  margin-top: 2rem;
  margin-bottom: 0.75rem;
}

p {
  margin-bottom: 1rem;
}

ul, ol {
  margin-bottom: 1.5rem;
  padding-left: 1rem;
  list-style-position: inside;

  li {
    margin: 0.75rem 0;
    line-height: 1.5em;
    color: neutral(80%);
  }
}

.table-of-contents {
  padding-left: 0;

  li {
    margin: 0.5rem 0;
    color: neutral(60%);

    a {
      color: $colorB;
      font-weight: 600;

      &:hover {
        cursor: pointer;
        color: $colorA;
      }
    }
  }
}

.highlight {
  color: $colorD;
  font-weight: 600;

  &.green {
    color: $colorA;
  }
}

a, /deep/ .details a {
  color: $colorB;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    color: $colorA;
  }
}

</style>
