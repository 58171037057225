<template>
  <div>
    <div class="spacer"></div>
    <h3>How we store passwords</h3>
  </div>
</template>

<script>
  export default {
    name: 'StoringPasswords',
    components: {

    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped lang="scss">



</style>
