<template>
  <div class="page">

    <h3 v-if="!activationStatus">Activating your account...</h3>

    <Loader v-if="!activationStatus" />


    <template v-if="activationStatus === 'success'">
      <h3 class="success">Account activated!</h3>
      <p>Welcome to Scrybe.</p>
      <div class="spacer"></div>

      <CoolButton class="login-button"
        label="Login"
        type="compact"
        animation="infinite"
        textColor="#66f1d0"
        bgColor="rgb(102,241,208)"
        @click.native="() => $router.push('/login')"
      />
    </template>


    <template v-if="activationStatus === 'active'">
      <h3 class="warning">Account already active.</h3>
    </template>


    <template v-if="activationStatus === 'invalid'">
      <h3 class="error">Invalid confirmation code.</h3>
    </template>


    <template v-if="activationStatus === 'error'">
      <h3 class="error">Something went wrong in the server.</h3>
      <p>Please let us know by submitting feedback <a @click="$router.push('/support')">here</a>.</p>
    </template>

  </div>
</template>

<script>
  export default {
    name: 'Activate',
    components: {
    },
    data() {
      return {
        activationStatus: null,
      }
    },
    created() {
      let code = this.$route.params.code;
      this.$store.dispatch('activateUser', code).then(response => {
        console.log(response);
        setTimeout(() => {
          if (response.status === 200) this.activationStatus = 'success';
          else if (response.status === 406) this.activationStatus = 'active';
          else if (response.status === 400) this.activationStatus = 'invalid';
          else this.activationStatus = 'error';
        }, 1500);
      });
    }
  }
</script>

<style scoped lang="scss">

  h3 {
    margin: 2rem 0;

    &.success {
      color: $alert-success-color;
    }
    &.warning {
      color: $alert-warning-color;
    }
    &.error {
      color: $alert-error-color;
    }
  }

  a {
    color: #53b1f5;
    font-weight: 600;
    transition: color 0.3s;

    &:hover {
      cursor: pointer;
      color: $colorA;
    }
  }

</style>
