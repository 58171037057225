<template>
  <div class="page forgot-password">

    <h3>Forgot Password</h3>

    <p>Enter your email below to receive a link to reset your password.</p>

    <div class="inputs">
      <input
        type="text"
        placeholder="Email"
        :value="email"
        @keydown="onKeyDown"
        @input="(e) => email = e.target.value"
        autocomplete="email"
      />
    </div>

    <Button
      label="Send Email"
      icon="right-arrow"
      @click.native="sendResetEmail"
    />

    <Alert :alert="alert" @dismiss="alert = null" />

    <div class="spacer h5"></div>

  </div>
</template>

<script>
  export default {
    name: 'ForgotPassword',

    data() {
      return {
        email: '',
        alert: null
      }
    },

    methods: {
      onKeyDown() {
        if (event.key == 'Enter') {
          this.sendResetEmail()
        }
      },

      sendResetEmail() {
        if (!this.email.trim()) {
          this.alert = { type: 'error', message: 'Please enter your email.' };
          return;
        }

        let params = {
          email: this.email
        }

        this.$store.dispatch('forgotPassword', params).then(response => {
          if (response.status === 200) {
            this.alert = {
              type: 'success',
              message: response?.data?.message
            }
            this.email = null;
          }
          else {
            this.alert = {
              type: 'error',
              message: response?.data?.error
            }
          }
        });
      }
    },

    mounted() {
      this.$store.dispatch('access')
    }
  }
</script>

<style scoped lang="scss">

  .forgot-password {
    @include flex($direction: column, $align: center);
    padding: 4rem;
    color: #ccc;

    > *:not(:last-child) {
      margin-bottom: 2rem;
    }

    .inputs {
      width: 100%;
      max-width: 300px;

      > *:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    .login-button {
      max-width: 200px;
    }
  }

  h4 {
    color: #ccc;
  }

</style>
