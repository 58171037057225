<template>
  <div :class="`${on ? 'on' : 'off'} ${hidden ? 'hidden' : ''}`">
    <span
      v-for="(char, index) in text"
      class="typewriter-character"
      :style="staggerDelay(index)"
    >{{ char }}</span>
  </div>
</template>

<script>
  export default {
    name: 'TypewriterText',

    props: {
      text: String,
      stagger: [Number],
      delay: [Number],
      on: Boolean,
      hidden: Boolean
    },

    watch: {
      trigger: function(newValue) {
        console.log(newValue);
      }
    },

    methods: {
      staggerDelay(index) {
        let delay = (this.delay || 0) + (index * (this.stagger || 20));
        return `animation-delay: ${delay}ms;`
      }
    },
  }
</script>

<style scoped lang="scss">

  .on .typewriter-character {
    animation-name: fade;
  }

  .off .typewriter-character {
    opacity: 0.5;
  }

  .hidden {
    opacity: 0;
  }

  .typewriter-character {
    animation-duration: 0.75s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    opacity: 0;
    transition: textShadow 0.5s;
  }

  @keyframes fade {
  	0% {
      opacity: 0;
      // text-shadow: 0 0 8px rgba(255,255,255, 0), 0 0 16px rgba(255,255,255, 0);
    }
  	15% {
      opacity: 1;
      // text-shadow: 0 0 8px rgba(255,255,255, 1), 0 0 16px rgba(255,255,255, 1);
    }
  	100% {
      opacity: 0.5;
      // text-shadow: 0 0 8px rgba(255,255,255, 0), 0 0 16px rgba(255,255,255, 0);
    }
  }

</style>
