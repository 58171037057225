<template>
  <div class="modal-container" @click.self="">
    <div class="modal">
      <slot></slot>
    </div>
  </div>
</template>


<script>
  import Button from './Button.vue';
  import Alert from './Alert.vue';

  export default {
    name: 'ModalContainer',
    components: {
      Button,
      Alert
    }
  }
</script>


<style scoped lang="scss">

  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    @include flex($justify: center);
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    z-index: 999;

    .modal {
      position: absolute;
      @include flex($direction: column);
      max-width: 800px;
      max-height: calc(100vh - 4rem);
      padding: 2rem;
      border-radius: 1rem;
      color: #fff;
      background-color: rgba(0,0,0,.75);
      border: 1px solid rgba(255,255,255,.25);
      z-index: 9999;
      overflow-x: hidden;
      overflow-y: auto;

      h4 {
        font-size: 1.25rem;
        white-space: pre-wrap;
      }

      .modal-buttons {
        @include flex($justify: center);

        > *:not(:last-child) {
          margin-right: 1.5rem;
        }
      }
    }
  }

</style>
