<template>
  <div class="reading-room">
    <!-- <OrnamentBorder /> -->
    <div class="reading-area-container">
      <div class="reading-area-bg" :style="formatBackground(backgroundImage)"></div>
      <div class="reading-area">
        <p>{{ formattedContent }}</p>
      </div>
    </div>
    <CoolButton label="Close" textColor="#ffce6f" bgColor="rgb(250,172,56)"
      type="compact" animation="infinite"
      @click.native="$emit('close')" />
  </div>
</template>

<script>
  export default {
    name: 'ReadingRoom',
    components: {
    },
    props: {
      content: [String, Array],
      backgroundImage: [String]
    },
    computed: {
      formattedContent() {
        if (!this.content || !this.content.length) return;
        if (typeof this.content == 'string') return this.content;
        return this.content.map(line => line.content).join('\n');
      }
    },
    methods: {
      formatBackground(url) {
        if (!url) return 'background-color: rgba(0,0,0,.5);';
        else return `background-image: url(${url});`
      }
    }
  }
</script>

<style scoped lang="scss">

  .reading-room {
    position: fixed;
    @include flex($direction: column, $justify: center, $align: center);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    padding: 4rem;
    box-sizing: border-box;
    z-index: 999;
    background-color: rgba(0,0,0,.5);
  }

  .reading-area-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
    box-sizing: border-box;
    border-radius: 1rem;
    background-color: #000;
    // border: 1px solid #82765b;
    white-space: pre-wrap;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .reading-area-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // opacity: 0.4;
    z-index: 0;
    background-position: center;
    background-size: cover;
    filter: contrast(65%) brightness(50%);
    mask-image: radial-gradient(circle at top center, rgba(0,0,0,1), rgba(0,0,0,.5))
  }

  .reading-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 4rem;
    box-sizing: border-box;
    overflow-y: auto;

    p {
      // position: absolute;
      text-shadow: 0 0 0.5em #000;
    }
  }

</style>
