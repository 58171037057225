<template>
  <div class="post-container">
    <div :class="`post ${this.postType} level${this.level} ${this.$store.state.theme}`">
      <Avatar :userID="posterID" :postType="this.postType" />
      <item-layout>
        <h5 class="name">{{ name }}</h5>
        <span class="date">{{ formatDateDelta(date) }} ago</span>
        <div class="content">
          <p>{{ content }}</p>
        </div>

        <div class="reaction-bar">
          <div v-for="(num, emoji) in reactionEmojis"
          :class="`reaction ${myReactionEmoji === emoji ? 'myReaction' : ''}`"
          @click="selectEmoji(emoji)"
          :key="emoji">
            <span class="emoji">{{ emoji }}</span>
            <span class="number">{{ num }}</span>
          </div>
          <EmojiPicker @emoji-selected="reactFromPicker" />
        </div>

        <div v-if="numReplies > 0" class="replies-toggle" @click="toggleReplies">
          <Icon :name="!showReplies ? 'more-arrow' : 'less-arrow'" size="16px" />
          <h5>{{ !showReplies ? `${numReplies} Replies` : 'Hide Replies' }} </h5>
        </div>
      </item-layout>
    </div>

    <section v-if="numReplies > 0 && showReplies" class="replies">
      <ForumPost v-for="post in replies" :key="post.id"
      type="reply"
      :level="level != null ? (level % 3) + 1 : 0"
      :posterID="post.posterID"
      :name="post.userName"
      :content="post.content"
      :date="post.datePosted"
      :reactions="post.reactions"
      :myReaction="null"
      :replies="post.replies" />
    </section>
  </div>
</template>


<script>
  import Avatar from '../../User/Avatar.vue';
  import EmojiPicker from '../Posts/EmojiPicker.vue';
  import ForumPost from './ForumPost.vue';

  export default {
    name: 'ForumPost',
    components: {
      Avatar,
      EmojiPicker,
      ForumPost
    },
    props: {
      type: String,
      level: Number,
      posterID: [String, Number],
      name: String,
      content: String,
      date: [String, Number],
      reactions: Object,
      myReaction: String,
      replies: Array
    },
    data() {
      return {
        postType: 'normal',
        reactionEmojis: {
          '👌🏼': 3,
          '💔': 1
        },
        myReactionEmoji: null,
        showReplies: false,
        numReplies: 0
      }
    },
    created() {
      this.reactionEmojis = this.reactions;
      this.myReactionEmoji = this.myReaction;
      if (this.type) this.postType = this.type;

      if (this.replies && this.replies.length) {
        this.numReplies = this.calcNumReplies(this.replies, 0);
      }
    },
    methods: {
      selectEmoji(emoji) {
        // If I click my own reaction, remove it entirely
        if (this.myReactionEmoji === emoji) {
          this.removeMyReaction();
          this.myReactionEmoji = null;
        }
        else {
          // Remove any other reaction I have and make this my new one
          if (this.myReactionEmoji) {
            this.removeMyReaction();
          }
          this.reactionEmojis[emoji]++;
          this.myReactionEmoji = emoji;
        }
      },

      reactFromPicker(emoji) {
        if (!!this.reactionEmojis) {
          // Add +1 to existing reaction if it exists
          // Otherwise make a new one
          if (this.reactionEmojis[emoji]) {
            this.reactionEmojis[emoji]++;
          }
          else {
            this.$set(this.reactionEmojis, emoji, 1);
          }

          this.removeMyReaction();
        }
        // Make a brand new object if this is the first reaction
        else {
          this.reactionEmojis = { [emoji]: 1 };
        }

        this.myReactionEmoji = emoji;
      },

      removeMyReaction() {
        // If my reaction exists, subtract -1 to old one or delete it if 0
        if (this.myReactionEmoji) {
          this.reactionEmojis[this.myReactionEmoji]--;

          if (this.reactionEmojis[this.myReactionEmoji] <= 0) {
            delete this.reactionEmojis[this.myReactionEmoji];
          }
        }
      },

      toggleReplies() {
        this.$emit('toggle-reply');
        this.showReplies = !this.showReplies;
      },

      calcNumReplies(replies, num) {
        if (replies && replies.length) {
          let newNum = num + replies.length;
          replies.forEach(reply => {
            if (reply.replies) newNum = this.calcNumReplies(reply.replies, newNum);
          });
          return newNum;
        }
        else {
          return num;
        }
      }
    }
  }
</script>


<style scoped lang="scss">

  .post-container {
    @include flex($direction: column, $align: flex-start);
    width: 100%;

    &:not(:first-child) {
      margin-top: $post-space-between;
    }
  }

  .post {
    @include flex($direction: row, $align: flex-start);
    width: 100%;
    padding: 1.75rem;
    padding-bottom: 0.75rem;
    box-sizing: border-box;
    background-color: $post-bg-color;

    &.reply {
      padding: 1rem 1.25rem;
      padding-bottom: 0.5rem;
      background-color: $post-reply-bg-color;
    }

    &.level1 {
      border-left: $post-replies-border-thickness solid $post-replies-color-level1;
    }
    &.level2 {
      border-left: $post-replies-border-thickness solid $post-replies-color-level2;
    }
    &.level3 {
      border-left: $post-replies-border-thickness solid $post-replies-color-level3;
    }

    item-layout {
      @include flex($direction: column, $align: flex-start);
      margin-left: 1.5rem;

      h5.name {
        color: #33a190 !important;
      }

      .date {
        font-size: 0.7rem;
      }

      .content {
        margin-top: 1rem;
        white-space: pre-wrap;
        text-align: left;

        p {
          font-family: 'Philosopher', Avenir, sans-serif;
          font-size: 1rem;
        }
      }
    }
  }

  .post.dark {
    p {
      color: #fff;
    }
    .date {
      color: #86909d;
    }
  }

  .reaction-bar {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
    margin-right: auto;

    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }

    & > :last-child {
      margin-left: 0.5rem;
    }

    & > :only-child {
      margin-left: 0;
    }
  }

  .reaction {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.35rem;
    border-radius: 0.6rem;
    border: 1px solid transparent;
    transition: all 0.15s;
    user-select: none;

    .emoji {
      position: relative;
      font-size: 1.25rem;
      line-height: 1em;
      top: 0.05em;
    }

    .number {
      margin-left: 0.3rem;
      margin-right: 0.1rem;
      font-size: 0.75rem;
      font-weight: bold;
      color: #fff;
    }

    &.myReaction .number {
      color: $post-my-reaction-color;
    }

    &:hover {
      cursor: pointer;
      background-color: #2d3338;
      border: 1px solid $post-my-reaction-color;
    }
  }

  .replies-toggle {
    @include flex($align: center);
    margin-top: 0.5rem;
    padding: 0.5rem;
    padding-left: 0;
    color: $post-view-replies-color;
    opacity: 0.6;
    transition: all 0.1s;

    > *:first-child {
      margin-right: 0.25rem;
    }

    h5 {
      color: $post-view-replies-color;
      font-family: 'Quicksand', Avenir, sans-serif;
      font-size: 0.8rem;
      font-weight: 600;
      text-transform: uppercase;
      user-select: none;
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  .replies {
    width: calc(100% - #{$post-replies-indent});
    margin-left: $post-replies-indent;

    .post-container {
      margin-top: $post-replies-space-between;
    }
  }

</style>
