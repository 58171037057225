
import { api, apiFile } from '@/utility.js'


export const worldMutations = {

  loadAllWorlds(state, data) {
    state.worldBrowser = data;
  },

  loadUserWorlds(state, worlds) {
    if (!state.user) return;
    state.user.worlds = worlds;
  },

  loadWorld(state, world) {
    state.world = world;
  },

  loadDisplayWorld(state, world) {
    state.displayWorld = world;
  },

  updateCurrentWorldID(state, worldID) {
    if (!state.user) return;
    state.user.currentWorldID = worldID;
  }

}


export const worldActions = {

  async fetchAllWorlds({ commit, state }, params) {
    let response = await api('POST', 'allWorlds', params);
    if (response.status === 200) {
      commit('loadAllWorlds', response.data);
    }
    return response
  },

  async fetchUserWorlds({ commit, state }) {
    let response = await api('GET', 'worlds');
    if (response.status === 200) {
      commit('loadUserWorlds', response.data);
    }
    return response;
  },

  async fetchWorld({ commit, state }, id) {
    return await api('GET', 'world/' + id);
  },

  async fetchCurrentUserWorld({ commit, state }) {
    if (!state.user?.currentWorldID) return

    let response = await api('POST', 'world/' + state.user?.currentWorldID);
    if (response.status == 200) {
      commit('loadWorld', response.data);
      if (response.data?.writings?.length) {
        commit('constructWritingsTree', response.data.writings)
      }
    }
    return response;
  },

  async createWorld({ commit, state }, world) {
    return await api('POST', 'createWorld', world);
  },

  async updateWorldImage({ commit, state }, params) {
    return await apiFile('POST', `world/updateWorldImage/${params.worldID}`, params.data);
  },

  async updateWorldName({ commit, state }, params) {
    return await api('POST', 'world/updateName', params);
  },

  async updateWorldDescription({ commit, state }, params) {
    return await api('POST', 'world/updateDescription', params);
  },

  async deleteWorld({ commit, state }, worldID) {
    return await api('DELETE', 'deleteWorld/' + worldID);
  },

  async selectWorld({ commit, state, dispatch }, worldID) {
    let response = await api('POST', 'user/selectWorld', { worldID });
    if (response.status != 200) return response;

    let worldResponse = await dispatch('fetchWorld', worldID);
    if (worldResponse.status != 200) return worldResponse;

    commit('loadWorld', worldResponse.data);
    commit('updateCurrentWorldID', worldID);

    return response;
  },

  async fetchInvitation({ commit, state }, id) {
    return await api('POST', 'world/invitation', { invitationID: id });
  },

  async inviteMember({ commit, state }, params) {
    return await api('POST', 'world/inviteMember', params);
  },

  async respondToInvitation({ commit, state }, params) {
    return await api('POST', 'world/respondToInvitation', params);
  },

  async updateWorldVisibility({ commit, state }, params) {
    return await api('POST', 'world/updateVisibility', params);
  },

  async updateElementVisibility({ commit, state }, params) {
    return await api('POST', 'updateElementVisibility', params);
  },

  async fetchElementLink({ commit, state }, params) {
    return await api('GET', `link/${params.element}/${params.id}`);
  },

  async searchElements({ commit, state }, params) {
    return await api('POST', 'elementSearch', params);
  },

}
