<template>
  <div class="loader-container">
    <div class="loader" :style="`width: ${size}; height: ${size};`">
      <div class="ring-inside" :style="`border-bottom-width: ${calcWidth(4)}px;`"></div>
      <div class="ring-middle" :style="`border-right-width: ${calcWidth(5)}px;`"></div>
      <div class="ring-outside" :style="`border-top-width: ${calcWidth(6)}px;`"></div>
    </div>
  </div>
</template>


<script>
  export default {
    name: 'Loader',
    props: {
      size: String
    },
    methods: {
      calcWidth(defaultSize) {
        if (!this.size) return defaultSize;

        let ratio = defaultSize / 120; // default size 120px
        let px = this.size.split('px')[0];

        if (isNaN(px)) return defaultSize;
        return Math.round(ratio * px);
      }
    }
  }
</script>


<style scoped lang="scss">

  .loader-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
  }

  .loader {
    position: absolute;
    @include flex($justify: center);
    height: 120px;
    width: 120px;
    z-index: 99;
    user-select: none;

    .ring-outside, .ring-middle, .ring-inside {
      position: absolute;
      border-radius: 100%;
      animation-name: spin;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    .ring-outside {
      width: 100%;
      height: 100%;
      border-top: 6px solid $loader-outside-color;
      animation-duration: 2.25s;
      filter: drop-shadow(0 0 $loader-blur rgba($loader-outside-color, $loader-blur-opacity));
    }
    .ring-middle {
      width: 70%;
      height: 70%;
      border-right: 5px solid $loader-middle-color;
      animation-duration: 1.5s;
      filter: drop-shadow(0 0 $loader-blur rgba($loader-middle-color, $loader-blur-opacity));
    }
    .ring-inside {
      width: 40%;
      height: 40%;
      border-bottom: 4px solid $loader-inside-color;
      animation-duration: 0.75s;
      filter: drop-shadow(0 0 $loader-blur rgba($loader-inside-color, $loader-blur-opacity));
    }
  }

  @keyframes spinFlash {
    0% {
      transform: rotate(0deg);
      opacity: 0.1;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 0.1;
    }
    75% {
      opacity: 1;
    }
    100% {
      transform: rotate(360deg);
      opacity: 0.1;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

</style>
