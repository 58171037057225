
import { api } from '@/utility.js'


export const postMutations = {

  clearAllPosts(state) {
    state.discover = {
      total: 0,
      posts: []
    }
    state.following = {
      total: 0,
      posts: []
    }
  },

  loadDiscoverPosts(state, data) {
    state.discover = {
      total: data.total,
      posts: [...state.discover.posts, ...data.posts]
    }
  },

  loadFollowingPosts(state, data) {
    state.following = {
      total: data.total,
      posts: [...state.following.posts, ...data.posts]
    }
  }

}


export const postActions = {

  async fetchDiscoverPosts({ commit, state }, params) {
    let response = await api('POST', 'posts/discoverFeed', params);
    if (response.status === 200) {
      commit('loadDiscoverPosts', response.data);
    }
    return response;
  },

  async fetchFollowingPosts({ commit, state }, params) {
    let response = await api('POST', 'posts/followingFeed', params);
    if (response.status === 200) {
      commit('loadFollowingPosts', response.data);
    }
    return response;
  },

  async submitNewPost({ commit, state }, post) {
    return await api('POST', 'posts/newPost', post);
  },

  async deletePost({ commit, state }, id) {
    return await api('DELETE', 'posts/deletePost/' + id);
  },

  async fetchAttachment({ commit, state }, id) {
    return await api('GET', 'posts/attachment/' + id);
  },

  async submitNewReply({ commit, state }, reply) {
    return await api('POST', 'posts/newReply', reply);
  },

  async deleteReply({ commit, state }, replyID) {
    return await api('DELETE', 'posts/deleteReply/' + replyID);
  },

}
