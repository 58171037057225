<template>
  <div id="app"
    v-if="isUserFetched"
    :class="`${theme('app')}`"
    :style="determineScrollLock"
  >
    <TopNavigation v-if="routeHasNav" />
    <router-view class="router-view" />
    <!-- <div class="app-bg"></div> -->
    <Footer v-if="routeHasNav && routeHasFooter" />
  </div>
</template>

<script>
  import TopNavigation from './components/Navigation/TopNavigation.vue';
  import Footer from './components/Navigation/Footer.vue';
  import { beacon } from '@/utility'

  export default {
    name: 'App',
    components: {
      TopNavigation,
      Footer
    },

    data() {
      return {
        isUserFetched: false,
        scroll: 0
      }
    },

    computed: {
      routeHasNav() {
        if (
          this.$route.name === 'WritingRoom' ||
          this.$route.name === 'Writing' ||
          this.$route.name === 'Wiki' ||
          this.$route.name === 'WikiPage' ||
          this.$route.name === 'WritingDisplay' ||
          this.$route.name === 'WikiDisplay' ||
          this.$route.name === 'TimelineDisplay' ||
          this.$route.name === 'TimelineBinary' ||
          this.$route.name === 'Timeline' ||
          this.$route.name === 'Map' ||
          this.$route.name === 'Language' ||
          this.$route.name === 'Introduction'
        ) {
          return false;
        }
        else return true;
      },

      routeHasFooter() {
        if (
          this.$route.matched[0]?.name == 'Help'
        ) {
          return false;
        }
        else return true;
      },

      determineScrollLock() {
        if (this.$store.state.scrollLocked) return `overflow: hidden; position: fixed; top: ${this.scroll * -1}px;`;
        else return '';
      }
    },

    methods: {
      onScroll() {
        if (!this.$store.state.scrollLocked) this.scroll = window.scrollY;
      },

      changeVisibility() {
        if (document.visibilityState == 'visible') {
          beacon('visible')
        }
        else if (document.visibilityState == 'hidden') {
          beacon('hidden')
        }
      },

      accessClose() {
        this.$store.dispatch('accessClose')
      }
    },

    async created() {
      window.addEventListener('beforeunload', this.accessClose)
      window.addEventListener('popstate', this.accessClose)

      let token = window.localStorage.getItem('token');
      if (!this.$store.state.user && token) {
        let response = await this.$store.dispatch('getMe');
        if (response.status === 200) {
          this.$store.commit('openNotificationSocket', this.$store);
          this.$store.dispatch('fetchCurrentUserWorld')
          if (response.data?.numLogins == 1) {
            this.$router.push('/introduction');
          }
        }
      }
      this.isUserFetched = true;
      console.log('User fetched.');
    },

    mounted() {
      this.scroll = window.scrollY;
      window.addEventListener('scroll', this.onScroll);
      document.addEventListener('visibilitychange', this.changeVisibility)

      // Remove search params from url
      if (window.location.search?.trim()) {
        window.setTimeout(() => {
          window.history.replaceState(null, '', window.location.origin + '/#' + this.$route.fullPath)
        }, 3000)
      }
    },

    destroyed() {
      window.removeEventListener('scroll', this.onScroll);
      window.removeEventListener('beforeunload', this.accessClose)
      window.removeEventListener('popstate', this.accessClose)
    }
  }
</script>

<style lang="scss">

  .app-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    // background: url('./assets/bg_pattern_1.jpg');
    background-size: 30%;
    opacity: 0.02;
    z-index: 0;
  }

  .router-view {
    position: relative;
    width: 100vw;
    z-index: 1;
  }

</style>
