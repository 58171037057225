<template>
  <div class="page">

    <div class="spacer"></div>
    <h2>Test</h2>
    <div class="spacer"></div>

    <!-- <Input /> -->

    <Alert :alert="alert" @dismiss="alert = null" />

    <CoolButton class="button" type="compact"
      label="New Notification" animation="infinite"
      textColor="#9faeff" bgColor="rgb(93,100,254)"
      @click.native="$store.dispatch('newNotification', {
        icon: 'time',
        title: 'Test Notification',
        subtitle: 'As you were...',
        link: '/community'
      })"
    />

    <CoolButton class="button" type="compact"
      label="Send Test Email" animation="infinite"
      textColor="#9faeff" bgColor="rgb(93,100,254)"
      @click.native="sendTestEmail" />

    <CoolButton class="button" type="compact"
      label="Subscribe" animation="infinite"
      textColor="#78ffc6" bgColor="rgb(48,215,140)"
      @click.native="$router.push('/subscribe')" />

    <CoolButton class="button" type="compact"
      label="Test Endpoint" animation="infinite"
      textColor="#9faeff" bgColor="rgb(93,100,254)"
      @click.native="test" />

    <CoolButton class="button" type="compact"
      label="Test Logs" animation="infinite"
      textColor="#9faeff" bgColor="rgb(93,100,254)"
      @click.native="test('logFileNames')" />


    <div class="spacer h5"></div>
    <h3>Stripe</h3>

    <CoolButton class="button" type="compact"
      label="Check/Add Customer" animation="infinite"
      textColor="#78ffc6" bgColor="rgb(48,215,140)"
      @click.native="testStripe('customer')" />

    <CoolButton class="button" type="compact"
      label="Check/Add Card" animation="infinite"
      textColor="#78ffc6" bgColor="rgb(48,215,140)"
      @click.native="testStripe('card')" />

    <CoolButton class="button" type="compact"
      label="Subscribe" animation="infinite"
      textColor="#78ffc6" bgColor="rgb(48,215,140)"
      @click.native="testStripe('subscribe')" />

    <CoolButton class="button" type="compact"
      label="Get All Sessions" animation="infinite"
      textColor="#78ffc6" bgColor="rgb(48,215,140)"
      @click.native="testStripe('sessions')" />


    <div class="spacer"></div>

    <div class="subscription-plan floating">
      <h3>Test Plan A</h3>
      <h5>A plan for testing subscriptions</h5>
      <CoolButton class="button" type="compact"
        label="Select" animation="infinite"
        textColor="#78ffc6" bgColor="rgb(48,215,140)"
        @click.native="testStripeCheckout" />
    </div>

  </div>
</template>

<script>
  export default {
    name: 'Test',

    data() {
      return {
        alert: null
      }
    },

    methods: {
      sendTestEmail() {
        this.$store.dispatch('sendTestEmail', {}).then(response => {
          if (response.status == 200) {
            this.alert = {
              type: 'success',
              message: 'Test email sent.'
            }
          }
          else {
            console.error(response);
            this.alert = {
              type: 'error',
              message: 'Problem sending test email.'
            }
          }
        });
      },

      test(endpoint) {
        this.$store.dispatch('test', endpoint).then(response => {
          console.log(response);
          if (response.status == 200 || response.status == 201) {
            this.alert = {
              type: 'success',
              message: response?.data?.message
            }
          }
          else {
            this.alert = {
              type: 'error',
              message: response?.data?.error
            }
          }
        });
      },

      testStripe(endpoint) {
        this.$store.dispatch('testStripe', endpoint).then(response => {
          console.log(response);
          if (response.status == 200 || response.status == 201) {
            this.alert = {
              type: 'success',
              message: response?.data?.message
            }
          }
          else {
            console.error(response);
            this.alert = {
              type: 'error',
              message: 'Problem with Stripe test.'
            }
          }
        });
      },

      testStripeCheckout() {
        this.$store.dispatch('testStripe', 'checkout').then(response => {
          console.log(response);
          if (response.status == 200 || response.status == 201) {
            this.alert = {
              type: 'success',
              message: response?.data?.message
            }

            console.log('Testing redirect to checkout.');

            const session = response?.data?.session;
            this.$store.dispatch('testGoToCheckout', session?.id).then(response => {
              if (response?.error) {
                this.alert = {
                  type: 'error',
                  message: response?.error?.message
                }
              }
            })
          }
          else {
            console.error(response);
            this.alert = {
              type: 'error',
              message: 'Problem with Stripe test.'
            }
          }
        });
      }
    },

    mounted() {
    }
  }
</script>

<style scoped lang="scss">

  .button {
    margin-top: 2rem;
  }

  .subscription-plan {
    @include flex($direction: column);
    margin: 2rem;
    padding: 2rem;
    background-color: bg(20%);
    border-radius: 0.25rem;

    h3 {
      margin-bottom: 0.5rem;
      font-weight: 300;
    }
  }

</style>
