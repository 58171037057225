import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/_store'
import {
  formatDateDelta,
  formatDate,
  publicImageURL,
  theme,
  hasPermission
} from '@/utility.js'
import ui_config from '@/ui_config.js'


Vue.config.productionTip = false


const globalComponents = require.context(
  './components/UI',
  true, // Whether or not to look in subfolders
  /\.vue/ // The regular expression used to match base component filenames
)

globalComponents.keys().forEach(fileName => {
  const componentConfig = globalComponents(fileName)

  // Gets the file name regardless of folder depth
  const componentName = fileName.split('/').pop().replace(/\.\w+$/, '')

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})


Vue.mixin({
  methods: {
    formatDateDelta,
    formatDate,
    publicImageURL,
    theme,
    hasPermission
  },
  data() {
    return {
      ui: ui_config
    }
  }
  // computed: {
  //   theme() {
  //     return Vue.store.state.theme
  //   }
  // }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
