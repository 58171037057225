<template>
  <div class="page">

    <div class="spacer"></div>
    <h1>Create New Writing</h1>
    <div class="spacer h5"></div>


    <div class="inputs table centered">

      <div class="table-row">
        <p>Name <span class="required">*</span></p>
        <input type="text" @input="(e) => title = e.target.value" />
      </div>

    </div>


    <div class="spacer h3"></div>

    <div class="row">
      <Button
        label="Back"
        icon="left-arrow"
        iconSize="12px"
        style="margin-right: 1rem;"
        @click.native="$router.back()"
      />
      <Button
        label="Create Writing"
        class="primary"
        @click.native="createWriting"
      />
    </div>

    <div class="spacer"></div>

    <Alert :alert="alert" @dismiss="alert = null" />

  </div>
</template>

<script>
  export default {
    name: 'NewWriting',
    components: {

    },
    data() {
      return {
        alert: null,
        title: null,
      }
    },

    methods: {
      async createWriting() {
        if (!this.title?.trim()) {
          this.alert = {
            type: 'warning',
            message: 'Please enter a name.'
          }
          return;
        }

        let newWriting = {
          isFolder: false,
          title: this.title,
          worldID: this.$store.state.world?._id,
          parentID: null
        }

        const response = await this.$store.dispatch('newWriting', newWriting)
        if (response?.status == 201) {
          this.$store.dispatch('fetchCurrentUserWorld')
          // this.$store.dispatch('refreshWritings')
          if (response?.data?.id) {
            this.$router.push('/writing/' + response.data.id)
          }
          else {
            this.$router.push('/creation')
          }
        }
      }
    }
  }
</script>

<style scoped lang="scss">

  .inputs {
    width: 100%;
    max-width: 600px;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: bg(20%);
  }

  .required {
    color: #ff2553;
  }

</style>
