<template>
  <div class="wikis-menu floating">

    <div class="wikis-bg"></div>

    <div class="wikis-header">
      <h4>Wikis</h4>
    </div>


    <div class="wikis-body">

      <div class="toolbar-row">
        <div class="center row spaced">
          <Button
            label="New Wiki"
            icon="plus"
            class="new-button primary"
            @click.native="$router.push('/new-wiki')"
          />
        </div>
        <div class="right">
          <Button v-if="wikis.length"
            label="Edit"
            icon="edit"
            :class="editing ? 'primary' : 'dark'"
            style="margin-left: auto;"
            @click.native="editing = !editing"
          />
        </div>
      </div>

      <div class="spacer"></div>

      <div class="categories">
        <div class="category">
          <CoolButton type="circle"
            icon="witch hat"
            iconSize="1.25rem"
            textColor="#66d0f1"
            bgColor="rgb(102,233,241)"
            animation="infinite"
            class="category-button dark"
            :selected="selectedCategory == 'characters'"
            @click.native="clickCategory('characters')" />
          <span :style="'color: #66d0f1'">Characters</span>
        </div>
        <div class="category">
          <CoolButton type="circle"
            icon="mountain"
            iconSize="1.25rem"
            textColor="#fad47e"
            bgColor="rgb(245,197,104)"
            animation="infinite"
            class="category-button dark"
            :selected="selectedCategory == 'places'"
            @click.native="clickCategory('places')" />
          <span :style="'color: #fad47e'">Places</span>
        </div>
        <div class="category">
          <CoolButton type="circle"
            icon="magic"
            iconSize="1.25rem"
            textColor="#ff8e8e"
            bgColor="rgb(252,107,129)"
            animation="infinite"
            class="category-button dark"
            :selected="selectedCategory == 'magic'"
            @click.native="clickCategory('magic')" />
          <span :style="'color: #ff8e8e'">Magic</span>
        </div>
        <div class="category">
          <CoolButton type="circle"
            icon="stopwatch"
            iconSize="1.25rem"
            textColor="#7dffa5"
            bgColor="rgb(83,241,137)"
            animation="infinite"
            class="category-button dark"
            :selected="selectedCategory == 'events'"
            @click.native="clickCategory('events')" />
          <span :style="'color: #7dffa5'">Events</span>
        </div>
      </div>

      <div class="spacer"></div>

      <Alert :alert="alert" @dismiss="alert = null" />

      <p v-if="!items.length">You have not created any {{ selectedCategory }}.</p>


      <div v-for="item in items"
        :class="`item ${item.visibility == 'public' ? 'public-element' : 'private-element'}`"
        @click="clickItem(item._id)"
      >
        <Icon
          :name="ui.wiki.categories[item.category].icon"
          :style="`color: ${ui.wiki.categories[item.category].color}; margin-right: 0.75rem;`"
        />

        <div class="info">
          <input v-if="renamingWiki && renamingWiki._id == item._id"
            type="text"
            :value="item.title"
            @input="(e) => renamingWiki.title = e.target.value"
          />
          <p v-else>{{ item.title }}</p>
          <h6>{{ formatDate(item.lastUpdated, '&mon &dd, &year') }}</h6>
        </div>

        <template v-if="!editing || renamingWiki">
          <Icon v-if="item.visibility == 'public'"
            name="eye"
            class="public-icon"
            style="margin-left: auto;"
          />
          <Icon v-else
            name="lock"
            class="private-icon"
            style="margin-left: auto;"
          />
        </template>

        <template v-if="editing">
          <template v-if="renamingWiki && renamingWiki._id == item._id">
            <Button
              icon="check"
              class="primary"
              style="margin-left: 1rem;"
              @click.native="renameWiki"
            />
            <Button v-if="renamingWiki"
              icon="x"
              class="delete"
              style="margin-left: 0.5rem;"
              @click.native="renamingWiki = null"
            />
          </template>
          <template v-else>
            <Select
              :options="Object.values(ui.visibility)"
              :value="item.visibility ? item.visibility : 'private'"
              @select="(value) => visibilityChanged(value, item)"
              style="min-width: 135px; margin-left: auto;"
              strict
            />
            <Button
              icon="edit"
              style="margin-left: 1rem;"
              @click.native="renamingWiki = item"
            />
            <Button
              icon="delete"
              class="delete"
              style="margin-left: 0.5rem;"
              @click.native="deletingWiki = item"
            />
          </template>
        </template>
      </div>

    </div>


    <ModalContainer v-if="deletingWiki">
      <h4>Delete "{{ deletingWiki.title }}"?</h4>
      <Message type="warning" text="All data in this wiki will be lost." />
      <div class="row spaced">
        <Button
          label="Yes"
          class="primary"
          @click.native="deleteWiki"
        />
        <Button
          label="No"
          class="delete"
          @click.native="deletingWiki = null"
        />
      </div>
    </ModalContainer>


    <ModalContainer v-if="changingVisibilityElement">
      <h4>Make "{{ changingVisibilityElement.title }}" {{ newVisibility }}?</h4>

      <div class="spacer h1"></div>
      <p v-if="newVisibility == 'private'" class="hint">Only you will be able to see this element.</p>
      <Message
        v-if="newVisibility == 'public'"
        type="warning"
        text="By making this element public, you agree to the terms of service and privacy policy."
      />
      <div class="spacer h1"></div>

      <div class="row spaced">
        <Button
          label="Yes"
          class="primary"
          @click.native="updateElementVisibility"
        />
        <Button
          label="No"
          class="delete"
          @click.native="changingVisibilityElement = null"
        />
      </div>
    </ModalContainer>

  </div>
</template>

<script>
  export default {
    name: 'WikiMenu',

    computed: {
      wikis() {
        return this.$store.state.world?.wikis || []
      },

      items() {
        if (!this.wikis?.length) return []
        return this.wikis.filter(wiki => wiki.category == this.selectedCategory)
      }
    },

    data() {
      return {
        selectedCategory: 'characters',
        editing: false,
        renamingWiki: null,
        deletingWiki: null,
        alert: null,

        changingVisibilityElement: null,
        newVisibility: 'private',
      }
    },

    methods: {
      clickCategory(category) {
        this.selectedCategory = category
      },

      clickItem(id) {
        if (!this.editing) this.$router.push('/wiki/' + id)
      },

      renameWiki() {
        if (!this.renamingWiki) return;
        if (!this.renamingWiki.title?.trim()) {
          this.alert = {
            type: 'error',
            message: 'Please enter a name.'
          }
          return;
        }

        let params = {
          wikiID: this.renamingWiki._id,
          worldID: this.$store.state.user?.currentWorldID,
          title: this.renamingWiki.title
        }

        this.$store.dispatch('renameWiki', params).then(response => {
          if (response.status == 200) {
            this.$store.commit('renameWiki', params)
          }
          else {
            this.alert = {
              type: 'error',
              message: response.data.error
            }
          }
          this.renamingWiki = null;
        })
      },

      deleteWiki() {
        if (!this.deletingWiki) return;

        let params = {
          wikiID: this.deletingWiki._id,
          worldID: this.$store.state.user?.currentWorldID
        }

        this.$store.dispatch('deleteWiki', params).then(response => {
          if (response.status == 200) {
            this.$store.commit('deleteWiki', params)
            this.alert = {
              type: 'success',
              message: 'Wiki deleted successfully.'
            }
          }
          else {
            this.alert = {
              type: 'error',
              message: response.data.error
            }
          }
          this.deletingWiki = null;
        })
      },

      visibilityChanged(value, element) {
        this.newVisibility = value;
        this.changingVisibilityElement = element;
      },

      updateElementVisibility() {
        this.$store.dispatch('updateElementVisibility', {
          element: 'wiki',
          elementID: this.changingVisibilityElement._id,
          worldID: this.$store.state.world?._id,
          visibility: this.newVisibility
        }).then(response => {
          if (response.status == 200) {
            this.alert = { type: 'success', message: `Made "${this.changingVisibilityElement.title}" ${this.newVisibility}.` }
            this.$store.dispatch('fetchCurrentUserWorld')
          }
          else {
            this.alert = { type: 'error', message: response?.data?.error }
          }
          this.changingVisibilityElement = null;
          this.newVisibility = 'private';
        })
      }
    }
  }
</script>

<style scoped lang="scss">

  .wikis-menu {
    position: relative;
    @include flex($direction: column);
  }

  .wikis-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 0.5rem;
    overflow: hidden;
    background-image: url('https://media.scrybe-app.com/assets/elements/wikis_card.png');
    background-size: cover;
    background-position: bottom center;
  }

  .wikis-header {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: rgba(255,255,255,0.15);
    z-index: 2;

    h4 {
      font-size: 0.75rem;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .wikis-body {
    @include flex($direction: column);
    width: 100%;
    height: 100%;
    padding: 1rem;
    z-index: 2;
  }

  .new-button {
    display: inline-flex !important;
  }

  .categories {
    @include flex($justify: center);
    flex-wrap: wrap;
    border-radius: 0.5rem;
  }

  .category {
    position: relative;
    @include flex($direction: column);
    margin: 0 1rem;
    color: #fff;
    pointer-events: visible;
    border-radius: 100%;
    background-color: rgba(#e2d474,.16);

    span {
      position: absolute;
      bottom: -1.75rem;
      margin-top: 0.5rem;
      font-size: 0.75rem;
      font-weight: 400;
      text-transform: uppercase;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s;
    }

    &:hover {
      span {
        opacity: 1;
      }
    }
  }

  .item {
    @include flex();
    width: 100%;
    margin: 0.25rem 0;
    padding: 1rem;
    border-radius: 0.25rem;
    background-color: bg(25%);

    .info {
      @include flex($direction: column, $align: flex-start);
    }

    h6 {
      color: $colorB;
    }

    &:hover {
      cursor: pointer;
      background-color: bg(30%);

      p {
        color: $colorA;
      }
    }
  }

</style>
