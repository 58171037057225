<template>
  <div class="page writing-room">

    <ImmersiveBackground
      :youtubeHTML="scenes[currentSceneIndex].youtubeHTML"
      :isYoutubeLocked="isYoutubeLocked"
      :backgroundImage="publicImageURL('backgrounds', scenes[currentSceneIndex].backgroundImage)"
      :overlayColor="scenes[currentSceneIndex].overlayColor"
      :overlayOpacity="scenes[currentSceneIndex].overlayOpacity"
      @toggle-lock="isYoutubeLocked = !isYoutubeLocked" />


    <div class="toolbar-row">
      <div class="left">
        <BackButton
          @click.native="comingFromNew ? $router.push('/creation') : $router.back()"
        />
      </div>
      <div class="center">
        <div class="row spaced">
          <Button
            label="Previous Scene"
            icon="left-arrow"
            :disabled="currentSceneIndex <= 0"
            @click.native="previousScene"
          />
          <Button
            label="Next Scene"
            icon="right-arrow"
            :disabled="scenes && scenes.length && currentSceneIndex >= scenes.length - 1"
            @click.native="nextScene"
          />
        </div>
      </div>
      <div class="right">
        <div class="row spaced right">
          <Button v-if="isAuthor"
            :label="isPreviewing ? 'Stop Previewing' : 'Preview'"
            icon="eye"
            :class="isPreviewing ? 'primary' : ''"
            @click.native="isPreviewing = !isPreviewing"
          />
          <Button v-if="isAuthor && !isPreviewing"
            label="Show Scenes"
            icon="left-arrow"
            @click.native="scenesOpen = !scenesOpen"
          />
        </div>
      </div>
    </div>

    <div class="spacer h1"></div>



    <Loader v-if="loading" style="padding-top: 10rem;" />



    <template v-if="isAuthor && !isPreviewing">

      <input v-if="writingID"
        class="title"
        type="text"
        :value="title"
        @input="titleChanged" />

      <WordProcessor
        :content="scenes[currentSceneIndex].content"
        :currentSceneIndex="currentSceneIndex"
        @updated="contentUpdated"
      />



      <!-- <div class="draft-saved-indicators">
        <section>
          <p>Local:</p>
          <DraftSaved :lastInput="lastInput"
            emitName="update-doc" @update-doc="updateDoc"
            :confirmSaved="confirmSavedLocal" />
        </section>
        <section>
          <p>Server:</p>
          <DraftSaved :lastInput="lastInput"
            :manuallySavingChanges="savingToServer"
            :confirmSaved="confirmSavedServer" />
        </section>
      </div> -->



      <div :class="`scenes ${scenesOpen ? 'open' : ''}`">
        <Button
          label="Hide Scenes"
          icon="right-arrow"
          style="margin-right: auto;"
          @click.native="scenesOpen = false"
        />

        <div class="spacer"></div>
        <h3>Scenes</h3>
        <div class="spacer h3"></div>

        <Button
          label="New Scene"
          icon="plus"
          class="primary"
          @click.native="newSceneModalVisible = true"
        />

        <div class="scenes-list">
          <div v-for="(scene, index) in scenes"
            :class="`scene ${currentSceneIndex == index ? 'selected' : ''}`"
            @click="currentSceneIndex = index"
          >
            <p>{{ scene.name }}</p>
          </div>
        </div>


        <div class="spacer" style="margin-top: auto;"></div>
        <div class="horizontal-line">
          <h5>Customize</h5>
        </div>
        <div class="spacer"></div>


        <p>Background Image:</p>
        <div class="spacer h1"></div>
        <input type="text" :value="scenes[currentSceneIndex].backgroundImage" @change="changeBackgroundImage" />
        <div class="spacer h1"></div>

        <p>Youtube:</p>
        <div class="spacer h1"></div>
        <input type="text" :value="scenes[currentSceneIndex].youtubeHTML" @change="changeYoutube" />

        <div class="spacer h1"></div>
        <ColorPicker
          label="Overlay Color"
          :color="scenes[currentSceneIndex].overlayColor"
          @color-picked="overlayColorChanged"
        />
        <Slider
          label="Opacity"
          :min="0"
          :max="100"
          :step="1"
          :value="scenes[currentSceneIndex].overlayOpacity * 100"
          @change="overlayOpacityChanged"
        />

        <Alert :alert="customizeAlert" @dismiss="customizeAlert = null" />

        <div class="spacer"></div>
      </div>


      <Modal v-if="showDifferenceModal"
        :message="differenceModalMessage"
        :buttons="['local', 'server']"
        @modal-local="modalLocal" @modal-server="modalServer"
      />

      <ModalContainer v-if="newSceneModalVisible">
        <p>New Scene</p>
        <div class="spacer"></div>
        <input
          type="text"
          placeholder="Name"
          @input="(e) => newScene.name = e.target.value"
        />
        <div class="spacer"></div>
        <div class="row spaced">
          <Button
            label="Cancel"
            @click.native="newSceneModalVisible = false"
          />
          <Button
            label="Done"
            class="primary"
            @click.native="createNewScene"
          />
        </div>
      </ModalContainer>

    </template>



    <template v-else>

      <div class="reading-area-container">
        <div class="reading-area">
          <p
            v-for="line in scenes[currentSceneIndex].content"
            :class="`node ${line.style}`"
            :style="line.color ? `color: ${line.color};` : ''"
          >{{ line.content }}</p>
        </div>
      </div>

    </template>

  </div>
</template>



<script>
  import ImmersiveBackground from '../ImmersiveBackground.vue';
  import WritingCollection from './WritingCollection.vue';
  import WordProcessor from './WordProcessor.vue';
  import exampleWorld from '@/components/World/example_world'

  export default {
    name: 'Writing',
    components: {
      ImmersiveBackground,
      WritingCollection,
      WordProcessor
    },

    computed: {
      isAuthor() {
        return this.$store.state.user?._id == this.writing?.authorID
      }
    },

    data() {
      return {
        loading: false,
        comingFromNew: false,
        writing: null,
        isPreviewing: false,

        title: '',
        content: '',
        lastUpdated: null,
        writingID: null,

        lastInput: null,
        confirmSavedLocal: false,
        confirmSavedServer: false,
        savingToServer: 0,

        showDifferenceModal: false,
        differenceModalMessage: '',

        scenesOpen: false,
        newSceneModalVisible: false,
        scenes: [{}],
        currentSceneIndex: 0,
        newScene: {
          name: null
        },

        customizeOpen: false,
        customizeAlert: null,

        isYoutubeLocked: false,

        doneTyping: true,
        inputQueue: []
      }
    },

    methods: {

      previousScene() {
        if (this.currentSceneIndex > 0) this.currentSceneIndex--;
      },

      nextScene() {
        if (this.scenes?.length && this.currentSceneIndex < this.scenes.length - 1) this.currentSceneIndex++;
      },

      titleChanged() {
        this.title = event.target.value;
      },

      contentUpdated(lines) {
        this.inputQueue.push(0)
        window.setTimeout(() => {
          this.checkIfDoneTyping(lines)
        }, 1000)

      },

      checkIfDoneTyping(lines) {
        if (this.inputQueue.length) {
          this.inputQueue.pop()
        }

        if (!this.inputQueue.length) {
          console.log('update content');
          this.updateContent(lines)
        }
      },

      updateContent(lines) {
        this.scenes[this.currentSceneIndex].content = lines;
        this.updateWriting()
      },

      loadFile(file) {
        this.title = file.title;
        this.content = file.content;
        this.lastUpdated = file.lastUpdated;
        this.writingID = file._id;
        this.$store.commit('loadCurrentWriting', file);
      },

      // saveFile() {
      //   this.savingToServer = 1;
      //   let lastUpdated = Date.now();
      //   let doc = {
      //     worldID: this.$store.state.world?._id,
      //     parentID: this.$store.state.currentWriting ? this.$store.state.currentWriting.parentID : null,
      //     type: 'doc',
      //     title: this.title,
      //     content: this.content,
      //     lastUpdated: lastUpdated,
      //     backgroundImage: this.backgroundImage
      //   };
      //
      //   if (!this.writingID) {
      //     this.$store.dispatch('newWriting', doc).then(response => {
      //       if (response.status === 201) {
      //         this.confirmSavedServer = true;
      //         this.savingToServer = 0;
      //       }
      //       // this.$store.dispatch('fetchWritings');
      //     });
      //   }
      //   else {
      //     let params = {
      //       docID: this.writingID,
      //       doc: doc
      //     };
      //
      //     this.$store.dispatch('updateWriting', params).then(response => {
      //       if (response.status === 201) {
      //         this.confirmSavedServer = true;
      //         this.savingToServer = 0;
      //       }
      //       // this.$store.dispatch('fetchWritings').then(response => {
      //       //   // this.loading = false;
      //       // });
      //     });
      //   }
      // },

      createNewScene() {
        let newScene = {
          name: this.newScene.name,
          content: [],
          overlayColor: '#414145',
          overlayOpacity: 0.5,
          backgroundImage: 'https://media.scrybe-app.com/assets/mountains_01.jpg',
          youtubeHTML: null
        }

        this.scenes.push(newScene)
        this.newSceneModalVisible = false;
        this.updateWriting()
      },


      changeBackgroundImage() {
        let input = event.target.value;
        if (input.startsWith('http://') || input.startsWith('https://')) {
          this.scenes[this.currentSceneIndex].backgroundImage = input;
          this.updateWriting()
        }
      },

      changeYoutube() {
        let input = event.target.value;
        if (!input.trim()) {
          this.scenes[this.currentSceneIndex].youtubeHTML = null;
          return;
        }
        if (!input.startsWith('https://www.youtube.com') && !input.startsWith('https://youtu.be')) {
          this.customizeAlert = { type: 'warning', message: 'Link is not from youtube.' };
          return;
        }

        let youtubeURL = input;
        fetch(`https://www.youtube.com/oembed?url=${youtubeURL}&format=json`).then(response => response.json()).then(response => {
          let html = response.html;
          let url = /src=\"(.*?)\"/.exec(html)[1];
          if (url) {
            let newURL = url + '&autoplay=1';
            html = html.replace(url, newURL);
          }

          let width = /width=\"(.*?)\"/.exec(html);
          let newWidth = width[0];
          newWidth = newWidth.replace(width[1], '100%');
          html = html.replace(width[0], newWidth);

          let height = /height=\"(.*?)\"/.exec(html);
          let newHeight = height[0];
          newHeight = newHeight.replace(height[1], '100%');
          html = html.replace(height[0], newHeight);

          this.scenes[this.currentSceneIndex].youtubeHTML = html;
          this.updateWriting()
        }).catch(error => {
          this.customizeAlert = { type: 'error', message: 'Invalid link.' };
        });
      },

      overlayColorChanged(color) {
        this.scenes[this.currentSceneIndex].overlayColor = color;
        this.updateWriting()
      },

      overlayOpacityChanged(input) {
        this.scenes[this.currentSceneIndex].overlayOpacity = parseInt(input) / 100;
        this.updateWriting()
      },

      updateWriting() {
        this.$store.dispatch('updateWriting', {
          writingID: this.$route.params.id,
          scenes: this.scenes
        });
      }
    },

    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (from.name == 'NewWriting') vm.comingFromNew = true
      })
    },

    created() {
      if (this.$route.params.id == 'a32df19234ad1a') {
        this.writing = exampleWorld.writings.find(writing => writing._id == this.$route.params.id)
        this.scenes = this.writing?.scenes || [{}]
      }
      else {
        this.$store.dispatch('fetchWriting', this.$route.params.id).then(response => {
          console.log(response);
          if (response.status == 200) {
            this.writing = response?.data
            this.scenes = this.writing?.scenes || [{}]
          }
        })
      }
    },

    mounted() {
      // this.loading = true;
      // this.$store.dispatch('fetchWritings').then(response => {
      //   this.loading = false;
      //   this.filesOpen = true;
      //
      //   // if (response.data.hasOwnProperty('missing')) {
      //   //   if (!storedText) this.confirmSavedServer = true;
      //   //   console.log("no docs in server, don't compare.");
      //   // }
      //   // else {
      //   //   const server = response.data.docs.lastUpdated;
      //   //   const local = this.lastUpdated;
      //   //   if (server == local) {
      //   //     this.confirmSavedServer = true;
      //   //   }
      //   //   else {
      //   //     let localTime = this.formatDate(local, '&mon &dd, &year &hour:&min:&sec');
      //   //     let serverTime = this.formatDate(server, '&mon &dd, &year &hour:&min:&sec');
      //   //     let mostRecent = `${local > server ? 'Local' : 'Server'} is most recently updated.`;
      //   //     this.differenceModalMessage = `Local: ${localTime}\nServer: ${serverTime}\n\n${mostRecent}\n\nWhich would you like to use?`;
      //   //     this.showDifferenceModal = true;
      //   //     this.lastInput = Date.now();
      //   //   }
      //   // }
      // })
    }
  }
</script>



<style scoped lang="scss">

  .writing-room {
    position: relative;
    @include flex($direction: column);
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    margin: 0;
    padding: 2rem;
    padding-bottom: 2rem;
    box-sizing: border-box;
  }

  .draft-saved-indicators {
    @include flex($direction: column, $align: center);
    margin-top: 1rem;

    section {
      @include flex($direction: row)

      p {
        margin-right: 0.5rem;
        font-size: 0.9rem;
        color: #ccc;
      }
    }
  }

  .title {
    margin-top: 2rem;
    padding: 1rem 1.5rem;
    z-index: 99;

    &:hover {
      color: #fff;
    }
    &:focus {
      background-color: rgba(0,0,0,.4);
    }
  }

  .high-button {
    z-index: 9;
  }

  .customize-button {
    position: relative;
    top: 50%;
    border-radius: 0.5rem;
    padding: 1rem;
    color: $colorA;
    transform: translateY(-50%);
    background-color: bg(0%);

    &:hover {
      cursor: pointer;
      background-color: bg(10%);
    }
  }

  .scenes {
    position: fixed;
    @include flex($direction: column);
    top: 1rem;
    right: -280px;
    width: 280px;
    height: calc(100vh - 2rem);
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: rgba(0,0,0,.5);
    z-index: 99;
    transform: translateX(0);
    transition: transform 0.5s;
    backdrop-filter: blur(16px);
    overflow: auto;

    &.open {
      transform: translateX(calc(-280px - 1rem));
    }
  }

  .scenes-list {
    position: relative;
    width: 100%;
    margin-top: 1rem;
  }

  .scene {
    width: 100%;
    margin: 0.5rem 0;
    padding: 0.75rem 1rem;
    border-radius: 0.25rem;
    background-color: bg(20%);
    user-select: none;

    &:hover {
      cursor: pointer;
      background-color: bg(25%);
    }

    &.selected {
      background-color: $primary;

      &:hover {
        background-color: $primary-hover;
      }
    }
  }

  .horizontal-line h5 {
    font-family: 'Cinzel Decorative', cursive;
    font-weight: 400;
  }

  // .customize {
  //   position: fixed;
  //   @include flex($direction: column);
  //   top: 0;
  //   right: -280px;
  //   height: 100vh;
  //   width: 280px;
  //   padding: 1rem;
  //   background-color: rgba(0,0,0,.5);
  //   z-index: 99;
  //   transform: translateX(0);
  //   transition: transform 0.5s;
  //   backdrop-filter: blur(16px);
  //
  //   .customize-button {
  //     right: calc(50% + 3rem);
  //   }
  //
  //   &.open {
  //     transform: translateX(-280px);
  //   }
  // }



  .reading-area-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
    box-sizing: border-box;
    white-space: pre-wrap;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .reading-area {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 3rem;
    box-sizing: border-box;
    overflow-y: auto;

    .node {
      position: relative;
      display: block;
      white-space: pre;
      color: #fff;
      // flex-wrap: wrap;

      p {
        color: inherit;
        margin-bottom: 1em;
        text-shadow: 0 0 0.5em #000;
      }

      &.title {
        margin-bottom: 0.25rem;
        font-family: 'Cinzel Decorative', Avenir, sans-serif;
        font-size: 1.5rem;
        text-align: center;
        font-weight: 400;
      }

      &.subtitle {
        margin-bottom: 0.25rem;
        color: #94acd7;
        font-size: 0.9rem;
        font-weight: 600;
        text-align: center;
        letter-spacing: 0.25px;
        text-transform: uppercase;
      }

      &.bold {
        font-weight: 600;
      }
    }
  }

</style>
