<template>
  <div>

    <div class="spacer"></div>
    <h2>Violation History</h2>
    <div class="spacer"></div>

    <p v-if="!violationHistory.length">You have no violation history.</p>

    <div class="violation-history">
      <template v-for="violation in violationHistory">
        <div v-if="!violation.repealedOn" class="violation floating">
          <p class="date">{{ formatDate(violation.date) }}</p>
          <p>Reason: {{ violation.reason }}</p>
          <div class="spacer"></div>

          <p class="appeal">Appeal</p>
          <p v-if="violation.appealedOn" class="date">{{ formatDate(violation.appealedOn) }}</p>
          <p v-if="violation.appealReason">Reason: {{ violation.appealReason }}</p>
          <div v-if="violation.rejectedOn" class="rejected">
            <Icon name="x" size="12px"/>
            <p>Rejected on {{ formatDate(violation.rejectedOn) }}</p>
          </div>
          <div v-else class="pending">
            <Icon name="question" size="12px"/>
            <p>Pending</p>
          </div>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'Violations',

    computed: {
      user() {
        return this.$store.state.user
      },
      violationHistory() {
        return this.user?.violationHistory || []
      }
    },

    data() {
      return {

      }
    }
  }
</script>

<style scoped lang="scss">

  .violation-history {
    @include flex($direction: column);
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
    padding: 0 3rem;
  }

  .violation {
    // @include flex($justify: space-between);
    @include flex($direction: column);
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem 1.5rem;
    border-radius: 0.25rem;
    background-color: bg(20%);

    > p {
      color: neutral(70%);
      font-size: 0.9rem;

      &.date {
        color: $colorA;
        font-weight: 300;
      }
    }
  }

  .appeal {
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  .pending {
    @include flex();
    color: $alert-warning-color;

    p {
      margin-left: 0.5rem;
      color: $alert-warning-color;
      font-size: 0.9rem;
    }
  }

  .rejected {
    @include flex();
    color: $alert-error-color;

    p {
      margin-left: 0.5rem;
      color: $alert-error-color;
      font-size: 0.9rem;
    }
  }

</style>
