<template>
  <div class="landing-section" id="wikis">
    <div class="section-bg">
      <!-- <img class="bg-image" src="https://cdna.artstation.com/p/assets/images/images/032/447/460/large/andreas-rocha-oldbookshop01.jpg?1606467764" /> -->
      <img class="bg-image" src="https://media.scrybe-app.com/assets/landing/wikis_background.jpeg" />
    </div>

    <h1>Wiki</h1>

    <div class="section-content">
      <div class="showcase-image">
        <AsyncImage src="https://media.scrybe-app.com/assets/landing/wikis_screenshot.png" />
      </div>
      <div class="content-header">
        <h2>Organize your world.</h2>
        <p>Keeping track of every piece of your world is exhausting, and extremely difficult.</p>
        <br>
        <p>Make it easier by maintaining a library of information about your world. Write articles that link to other Elements of the world, and maintain consitency throughout your world.</p>
      </div>
      <div class="points">
        <article>
          <h4><Icon name="books" size="1rem" />Information at Your Fingertips</h4>
          <p>Organize your articles into different categories, such as Characters, Places, Events, Magic System, etc. Create and rearrange your own sections, such as History or Appearance. Keep stats and short records at the top, and upload images to paint a clearer picture.</p>
        </article>
        <article>
          <h4><Icon name="open book" size="1rem" />Stay Focused</h4>
          <p>No separate editor or a forest of ugly menus. Edit one thing at a time, on the spot, with all other information in plain view. Customize the article's background to add a touch of immersion.</p>
        </article>
      </div>
    </div>
  </div>
</template>


<script>
  export default {
    name: 'LandingWikis',
  }
</script>


<style scoped lang="scss">

  #wikis {
    margin-bottom: -60px;
    padding-top: 10rem;
    padding-bottom: 14rem;

    .icon {
      color: $wiki-color;
    }

    .section-bg {
      // background-color: #2a2522;
      background-color: #1c3630;
      clip-path: polygon(0 0, 100% 60px, 100% calc(100% - 60px), 0 100%);
      .bg-image {
        mask-image: radial-gradient(circle at center left, rgba(0,0,0,.6), rgba(0,0,0,.15) 25%, rgba(0,0,0,.0) 100%);
      }
    }

    .section-content{
      grid-template-areas:
        "header image"
        "points image";
    }

    .content-header {
      text-align: left;
      margin-right: auto;
    }

    .showcase-image .async-image {
      width: 600;
      height: 428px;
      transform: perspective(25cm) rotate3d(2, -10, 1, 15deg);
    }

    .points {
      > * {
        margin-right: auto;
      }
    }
  }

</style>
