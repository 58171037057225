<template>
  <div class="landing-section" id="maps">

    <div class="section-bg">
      <img class="bg-image" src="https://media.scrybe-app.com/assets/landing/maps_background.jpeg" />
    </div>

    <h1>Maps</h1>


    <div class="section-content">

      <div class="showcase-image">
        <AsyncImage src="https://media.scrybe-app.com/assets/landing/maps_screenshot.png" />
      </div>

      <div class="content-header">
        <h2>Visualize your story across space.</h2>
        <p>All stories take place somewhere, and in many stories, the setting plays a lead role.</p>
        <br>
        <p>Upload your own images, or generate a map in seconds as a starting point. Place markers and drag them around, adding images and descriptions for more context. View all your markers in a list or filter them to focus only on certain places or events.</p>
      </div>

      <div class="points">
        <div>
          <h4><Icon name="magic" size="1.25rem" />Map Generator</h4>
          <p>This feature is the first of its kind, never before seen. If you don't know where to start, generate a random map to spark your inspiration.</p>
          <br>
          <p>Set parameters such as map size, detail, and terrain nodes, and watch a random map come to life on the screen. Download it and make it your own, or use it directly in Scrybe.</p>
        </div>
        <div>
          <h4><Icon name="map" size="1rem" />Visualize Routes (Coming Soon!)</h4>
          <p>Create a route, or connect a Timeline, and watch as a path appears on your map, viewable as an animation.</p>
        </div>
        <div>
          <h4><Icon name="mountain" size="1rem" />Built from the Ground Up</h4>
          <p>Maps features a custom-built, dynamic interface that we created from scratch, in native Javascript, with no canvas, iFrames, or third party plugins. That means it's more efficient and lightweight, so it responds faster and uses less data. No extra baggage.</p>
        </div>
      </div>

    </div>

  </div>
</template>


<script>
  export default {
    name: 'LandingMaps',
  }
</script>


<style scoped lang="scss">

  #maps {
    margin-bottom: -60px;
    padding-top: 10rem;
    padding-bottom: 14rem;
    text-align: right;

    .icon {
      color: $maps-color;
    }

    .section-bg {
      background-color: #3e3525;
      clip-path: polygon(0 0, 100% 60px, 100% calc(100% - 60px), 0 100%);
      .bg-image {
        mask-image: radial-gradient(circle at center left, rgba(0,0,0,.6), rgba(0,0,0,.15) 25%, rgba(0,0,0,.0) 100%);
      }
    }

    .section-content{
      grid-template-areas:
        "header image"
        "points image";
    }

    .content-header {
      text-align: left;
      margin-right: auto;
    }

    .showcase-image .async-image {
      width: 600px;
      height: 418px;
      transform: perspective(25cm) rotate3d(2, -10, 1, 15deg);
    }

    .points {
      > * {
        margin-right: auto;
      }
    }
  }

</style>
