<template>
  <div class="core">

    <div class="core-item inspiration" @click="$router.push('/inspiration')">
      <div class="title">
        <Icon name="eye" size="1.5rem" />
        <h4>Inspiration</h4>
      </div>
      <p>Discover and collect new ideas from other creators</p>
    </div>

    <Icon name="curved arrow" size="2rem"
      class="arrow one" />

    <div class="core-item creation" @click="$router.push('/creation')">
      <div class="title">
        <Icon name="fire" size="1.25rem" />
        <h4>Creation</h4>
      </div>
      <p>Filter through your collected ideas and develop your own story</p>
    </div>

    <Icon name="curved arrow" size="2rem"
      class="arrow two" />

    <div class="core-item community" @click="$router.push('/community')">
      <div class="title">
        <Icon name="person exchange" size="1.5rem" />
        <h4>Community</h4>
      </div>
      <p>Share your unique creations with the world and inspire others</p>
    </div>

    <Icon name="curved arrow" size="2rem"
      class="arrow three" />

  </div>
</template>

<script>
  import Icon from './Icon.vue';

  export default {
    name: 'CoreThree',
    components: {
      Icon
    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped lang="scss">

  .core {
    position: relative;
    width: 480px;
    height: 400px;
  }

  .core-item {
    position: absolute;
    @include flex($direction: column, $justify: center);
    margin: 0.5rem;
    padding: 1rem;
    width: 200px;
    height: 140px;
    border-radius: 0.5rem;
    transition: background 0.15s;

    .title {
      @include flex($justify: center);
      margin-bottom: 1rem;

      .icon {
        margin-right: 1rem;
      }

      h4 {
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }

    p {
      color: neutral(75%);
      font-size: 0.9rem;
      text-align: center;
      line-height: 1.25em;
    }

    &.inspiration {
      bottom: 60px;
      left: 0;

      h4, .icon {
        color: $inspiration;
      }
    }
    &.creation {
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      h4, .icon {
        color: $creation;
      }
    }
    &.community {
      bottom: 60px;
      right: 0;

      h4, .icon {
        color: $community;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: bg(25%);
    }
  }

  .arrow {
    position: absolute;
    color: neutral(80%);

    &.one {
      top: 120px;
      left: 50px;
      transform: rotate(-65deg);
    }
    &.two {
      top: 120px;
      right: 50px;
      transform: rotate(50deg);
    }
    &.three {
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%) rotate(180deg);
    }
  }

</style>
