<template>
  <div class="landing-section" id="writings">
    <div class="section-bg">
      <!-- <img
        class="bg-image"
        src="https://media.scrybe-app.com/assets/landing/writings_background.jpg"
        @load="loaded"
      /> -->
      <img class="bg-image" src="https://images.unsplash.com/photo-1514539079130-25950c84af65?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1949&q=80" />
    </div>

    <h1>Writings</h1>

    <div class="section-content">
      <div class="showcase-image">
        <AsyncImage src="https://media.scrybe-app.com/assets/landing/writings_screenshot.png" />
      </div>
      <div class="content-header">
        <h2>Experience your story as you write it.</h2>
        <p>Welcome to the writing room, a custom-built word processor that's both immersive and minimalistic.</p>
        <br>
        <p>We built it from the ground up, in native Javascript, with no third party plugins. You won't be bogged down by slow responsiveness or hefty data usage.</p>
      </div>
      <div class="points">
        <div>
          <h4><Icon name="palette" size="1rem" />Immersive Scenes</h4>
          <p>Add customizable "scenes" that allow you to change the ambience of the space to fit the context of the story, allowing for immersion for both you and your readers.</p>
        </div>
        <div>
          <h4><Icon name="open book" size="1rem" />No Distractions</h4>
          <p>No excessive menus or sidebars. Focus only on the story, and stay in the moment.</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  export default {
    name: 'LandingWritings'
  }
</script>


<style scoped lang="scss">

  #writings {
    margin-top: -60px;
    margin-bottom: -60px;
    padding-top: 10rem;
    padding-bottom: 14rem;

    .icon {
      color: $writings-color;
    }

    .section-bg {
      // background-color: #142b26;
      background-color: #1b253d;
      clip-path: polygon(0 60px, 100% 0, 100% 100%, 0 calc(100% - 60px));
      .bg-image {
        mask-image: radial-gradient(circle at center right, rgba(0,0,0,.75), rgba(0,0,0,.25) 25%, rgba(0,0,0,0) 75%);
      }
    }

    .section-content{
      grid-template-areas:
        "image header"
        "image points";
    }

    .content-header {
      text-align: right;
      margin-left: auto;
    }

    .showcase-image .async-image {
      width: 600px;
      height: 432px;
      transform: perspective(25cm) rotate3d(3, 5, -1, 20deg);
    }

    .points {
      text-align: right;

      h4 {
        justify-content: flex-end;

      }

      > * {
        margin-left: auto;
      }
    }
  }

</style>
