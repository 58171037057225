<template>
  <div class="page">

    <div class="spacer"></div>
    <h1>Create New Wiki Page</h1>
    <div class="spacer h5"></div>


    <div class="inputs table centered">

      <div class="table-row">
        <p>Category <span class="required">*</span></p>
        <div>
          <Select
            :options="Object.values(ui.wiki.categories)"
            @select="(e) => { category = e }"
          />
        </div>
      </div>

      <div class="table-row">
        <p>Title <span class="required">*</span></p>
        <input type="text" @input="(e) => title = e.target.value" />
      </div>

      <div class="table-row">
        <p>Subtitle</p>
        <textarea @input="(e) => subtitle = e.target.value" />
      </div>

    </div>


    <div class="spacer h3"></div>

    <div class="row">
      <Button
        label="Back"
        icon="left-arrow"
        iconSize="12px"
        style="margin-right: 1rem;"
        @click.native="$router.back()"
      />
      <Button
        label="Create Wiki"
        class="primary"
        @click.native="createWiki"
      />
    </div>

    <div class="spacer"></div>

    <Alert :alert="alert" @dismiss="alert = null" />

    <div class="spacer h5"></div>

  </div>
</template>

<script>
  export default {
    name: 'NewWiki',
    components: {

    },
    data() {
      return {
        alert: null,

        category: null,
        title: null,
        subtitle: null
      }
    },

    methods: {
      createWiki() {
        let newWiki = {
          category: this.category,
          title: this.title,
          subtitle: this.subtitle || 'Subtitle or description...',

          worldID: this.$store.state.world?._id,
          authorID: this.$store.state.user._id,
          parentID: null,
          images: ['https://media.scrybe-app.com/assets/mountains_01.jpg'],
          headerFields: [{
            field: 'Field',
            value: 'Value'
          }]
        }

        console.log('newWiki', newWiki);

        this.$store.dispatch('newWiki', newWiki).then(response => {
          console.log(response);
          if (response.status === 201) {
            this.$store.dispatch('fetchCurrentUserWorld')
            if (response?.data?.id) {
              this.$router.push('/wiki/' + response.data.id)
            }
            else {
              this.$router.push('/creation')
            }
          }
        });
      }
    }
  }
</script>

<style scoped lang="scss">

  .inputs {
    width: 100%;
    max-width: 600px;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: bg(20%);
  }

  .required {
    color: #ff2553;
  }

</style>
