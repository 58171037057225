
import Vue from 'vue'
import { api, apiFile } from '@/utility.js'


export const mapMutations = {

  loadMaps(state, maps) {
    let stateMaps = {};
    maps.forEach(map => {
      stateMaps[map._id] = map;
    });
    state.maps = stateMaps;
  },

  saveGeneratedMap(state, generatedMap) {
    state.generatedMap = generatedMap;
  },

  clearGeneratedMap(state) {
    state.generatedMap = null;
  },

  renameMap(state, data) {
    let index = state.world.maps.findIndex(map => map._id == data.mapID)
    state.world.maps[index].title = data.title;
  },

  deleteMap(state, data) {
    let newMaps = state.world.maps.filter(map => map._id != data.mapID)
    state.world.maps = newMaps;
  },

}


export const mapActions = {

  async fetchMaps({ commit, state }) {
    let response = await api('GET', 'map/maps');
    if (response.status === 200 && response.data && response.data.length) {
      commit('loadMaps', response.data);
    }
    return response;
  },

  async fetchMap({ commit, state }, mapID) {
    return await api('GET', 'map/' + mapID);
  },

  async newMap({ commit, state }, map) {
    return await apiFile('POST', 'map/newMap', map);
  },

  async renameMap({ commit, state }, data) {
    return await api('PUT', 'map/rename', data);
  },

  async deleteMap({ commit, state }, data) {
    return await api('POST', 'map/delete', data);
  },

  async updateMapPoints({ commit, state }, data) {
    let response = await api('PUT', 'map/updatePoints/' + data.mapID, data.points);
    console.log(response);
    return response;
  },

  async uploadMapPointImage({ commit, state }, data) {
    return await apiFile('POST', 'map/uploadPointImage', data);
  },

  async deleteMapPointImage({ commit, state }, data) {
    return await api('POST', 'map/deletePointImage', data);
  },

  async addMapLink({ commit, state }, data) {
    return await api('POST', 'map/addLink', data);
  },

  async removeMapLink({ commit, state }, data) {
    return await api('POST', 'map/removeLink', data);
  },

}
