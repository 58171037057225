<template>
  <div class="post-wrapper">

    <div :class="`${theme('post-container')} ${isReplying ? 'replying' : ''}`">
      <Avatar :userID="authorID" />
      <section class="post-content">
        <!-- <Icon v-if="post.feedback != null"
          :class="`feedback-icon option-${post.feedback}`"
          :name="formatFeedbackIcon(post.feedback)"
          size="0.75rem"
          @mouseover.native="() => tooltipVisible = true" @mouseout.native="() => tooltipVisible = false" />
        <p v-if="tooltipVisible" :class="`feedback-tooltip option-${post.feedback}`">{{ formatFeedback(post.feedback) }}</p> -->

        <p class="type">Work In Progress</p>

        <p v-if="post" class="post-date">{{ formatDate(post.postedOn, '&mon &dd, &year') }}</p>

        <p v-if="post" class="content-text">{{ post.content }}</p>
        <TextLoader v-if="!post" />

        <div v-if="post && post.links" class="links">
          <div v-for="link in post.links"
            class="link"
            @click="loadAttachment(link)"
          >
            <Icon :name="formatAttachmentIcon(link.type)" />
            <p>{{ link.title || '(Attachment Missing)' }}</p>
          </div>
        </div>

        <div class="post-buttons">
          <div v-if="post && post.replies && post.replies.length" class="view-replies-button"
            @click="repliesVisible = !repliesVisible">
            <Icon :name="repliesVisible ? 'less-arrow' : 'more-arrow'" size="0.75rem" />
            <span>{{ `${repliesVisible ? 'Hide' : 'View'} ${formatReplyCount}` }}</span>
          </div>

          <div v-if="canReply" class="reply-button" @click="clickNewReply">
            <Icon name="plus" size="0.75rem" />
            <span>Reply</span>
          </div>

          <div v-if="post && $store.state.user && $store.state.user._id == post.authorID" class="delete-button"
            @click="deleteModalVisible = true">
            <Icon name="x" size="0.75rem" />
            <span>Delete</span>
          </div>
        </div>

        <ReadingRoom v-if="readingRoomVisible"
          :content="attachmentPreview ? attachmentPreview.content : null"
          backgroundImage="https://cdnb.artstation.com/p/assets/images/images/005/620/115/large/raphael-lacoste-mountain-rider-v02-net.jpg?1492521048"
          @close="closeReadingRoom()" />
      </section>
    </div>

    <div v-if="repliesVisible" class="replies-container">
      <Reply v-for="reply in post.replies" :reply="reply" :canReply="canReply" />
    </div>

    <div v-if="isReplying" class="new-reply-container">
      <NewReply :parentID="post._id" @close="isReplying = false"
        @success="newReplySuccess"/>
    </div>

    <Modal v-if="deleteModalVisible" message="Delete post?" :buttons="['yes', 'no']"
      @modal-yes="deletePost" @modal-no="deleteModalVisible = false"/>

  </div>
</template>

<script>
  import Avatar from '../../User/Avatar.vue';
  import ReadingRoom from '../../Creation/Writings/ReadingRoom.vue';
  import Reply from './Reply.vue'
  import NewReply from './NewReply.vue';

  export default {
    name: 'Post',
    components: {
      Avatar,
      ReadingRoom,
      Reply,
      NewReply
    },
    props: {
      post: Object,
      canReply: Boolean
    },
    data() {
      return {
        tooltipVisible: false,
        readingRoomVisible: false,
        attachmentPreview: null,
        repliesVisible: false,
        isReplying: false,
        deleteModalVisible: false
      }
    },
    computed: {
      authorID() {
        if (!this.post) return null;
        return this.post.authorID;
      },

      formatReplyCount() {
        if (!this.post.replies || !this.post.replies.length) return '0 Replies';

        const calc = (replies, count) => {
          let newCount = count;
          replies.forEach(reply => {
            if (reply.replies && reply.replies.length) newCount += calc(reply.replies, count);
          });
          return newCount + replies.length;
        }

        let count = calc(this.post.replies, 0);

        if (count === 1) return `${count} Reply`;
        else return `${count} Replies`;
      }
    },

    methods: {

      formatFeedback(feedback) {
        switch(feedback) {
          case 0:
            return 'Tough Love';
          case 2:
            return 'Reactions Only';
          default:
            return 'Constructive Criticism';
        }
      },

      formatFeedbackIcon(feedback) {
        switch(feedback) {
          case 0:
            return 'swords';
          case 2:
            return 'no comments';
          default:
            return 'rook';
        }
      },

      formatAttachmentIcon(type) {
        if (type === 'writing') return 'book';
        else if (type === 'wiki') return 'books';
        else if (type === 'timeline') return 'time';
        else if (type === 'map') return 'map';
        else return '';
      },

      loadAttachment(attachment) {
        if (attachment.type === 'writing') {
          this.$store.dispatch('fetchAttachment', attachment._id).then(response => {
            if (response.status === 200) {
              this.attachmentPreview = response.data;
              this.readingRoomVisible = true;
              this.$store.commit('changeScrollLock', true);
            }
          });
        }
        else if (attachment.type === 'wiki') {
          this.$router.push('/wiki/' + attachment._id);
        }
        else if (attachment.type === 'timeline') {
          this.$router.push('/timeline/' + attachment._id);
        }
        else if (attachment.type === 'map') {
          this.$router.push('/map/' + attachment._id);
        }
      },

      closeReadingRoom() {
        this.readingRoomVisible = false;
        this.$store.commit('changeScrollLock', false);
      },

      clickNewReply() {
        if (!this.$store.state.user) this.$router.push('/login');
        else this.isReplying = !this.isReplying;
      },

      newReplySuccess() {
        this.isReplying = false;
        this.repliesVisible = true;
      },

      deletePost() {
        this.$emit('delete', this.post._id);
        this.deleteModalVisible = false;
      }
    }
  }
</script>

<style scoped lang="scss">

  .post-wrapper {
    margin-top: 2rem;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 8px 20px -12px rgba(0,0,0,.5), 0 20px 40px -30px rgba(0,0,0,.75);
  }

  .post-container {
    position: relative;
    @include flex($align: flex-start);
    flex: 1;
    padding: 0.75rem 1.5rem;
    box-sizing: border-box;
    background-color: bg(20%);
    // background-color: #292d32;

    &.light {
      background-color: #dfe2e8;
    }

    .avatar {
      margin-right: 1.5rem;
    }

    &.replying {
      // border-radius: 1rem 1rem 0 0;
      box-shadow: 0 6px 12px -6px rgba(0,0,0,.5);
    }
  }

  .type {
    position: absolute;
    right: 0;
    color: $colorD;
    font-size: 0.75rem;
  }

  .post-date {
    margin-bottom: 0.25rem;
    color: $colorB;
    font-size: 0.75rem;
    font-weight: 500;
  }

  .post-content {
    position: relative;
    @include flex($direction: column, $align: flex-start);
    width: 100%;

    .feedback-icon{
      padding: 0.25rem;

      &.option-0 {
        color: #df4545;
      }
      &.option-1 {
        color: #467ac8;
      }
      &.option-2 {
        color: #35bb6b;
      }
    }

    .feedback-tooltip {
      position: absolute;
      top: -2.75rem;
      left: 0;
      padding: 0.5rem 1rem;
      transform: translateX(calc(-50% + 0.75rem));
      border-radius: 0.5rem;
      background-color: #34383e;
      font-size: 0.75rem;
      font-weight: 600;
      z-index: 999;

      &.option-0 {
        color: #df4545;
      }
      &.option-1 {
        color: #467ac8;
      }
      &.option-2 {
        color: #35bb6b;
      }
    }
  }

  .content-text {
    margin-top: 0.5rem;
  }

  .links {
    width: 100%;
    margin-bottom: 1rem;
  }

  .link {
    @include flex();
    width: 100%;
    max-height: 180px;
    margin-top: 1rem;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background-color: #1e1f22;
    white-space: pre-wrap;
    overflow: auto;

    .icon {
      color: $colorA;
      margin-right: 1rem;
    }

    p {
      color: $colorA;
      font-size: 0.8rem;
      font-weight: 600;
    }

    &:hover {
      cursor: pointer;
      background-color: #222428;
    }
  }

  .post-buttons {
    width: 100%;
    @include flex($justify: flex-end);

    > * {
      position: relative;
      @include flex();
      padding: 0.5rem 0.75rem;
      box-sizing: border-box;
      opacity: 0.75;
      transition: opacity 0.15s;
      user-select: none;

      &, span {
        color: $colorA;
      }
      span {
        margin-left: 0.25rem;
        font-size: 0.9rem;
        font-weight: 400;
      }

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }

    .view-replies-button {
      &, span {
        color: $colorC;
      }
    }

    .delete-button {
      &, span {
        color: $alert-error-color;
      }
    }
  }

  .new-reply-container {
    width: 100%;
    padding: 2rem;
    border-radius: 0 0 1rem 1rem;
    background-color: #4d5360;
  }

</style>
