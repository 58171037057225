<template>
  <section class="new-post">
    <input type="text" placeholder="Thread Title" :value="editingTitleText" @input="titleInputChanged"></input>
    <textarea @input="postInputChanged" @focus="postInputChanged">{{ editingPostText }}</textarea>
    <DraftSaved :lastInput="lastInput" />
    <item-layout>
      <Button label="Post" @click.native="submitNewThread" />
      <Button label="Cancel" @click.native="cancelNewThread" />
      <Button label="Clear" @click.native="openModal" />
    </item-layout>
    <Modal v-if="modalOpen" @modal-confirm="clearThreadProgress" @modal-cancel="closeModal" />
  </section>
</template>


<script>
  export default {
    name: 'NewThread',
    components: {
    },
    data() {
      return {
        editingTitleText: '',
        editingPostText: '',
        modalOpen: false,
        lastInput: null
      }
    },
    methods: {
      postInputChanged() {
        this.editingPostText = event.target.value;
        window.localStorage.setItem('new-thread-editing', event.target.value);

        let height = window.getComputedStyle(event.target).height;
        if (event.target.scrollHeight >= parseInt(height)) {
          event.target.style.height = event.target.scrollHeight + 'px';
        }
        else {
          event.target.style.height = '80px';
        }
        this.lastInput = Date.now();
      },

      titleInputChanged() {
        this.editingTitleText = event.target.value;
      },

      clearThreadProgress() {
        window.localStorage.removeItem('new-thread-editing');
        this.editingTitleText = '';
        this.editingPostText = '';
        this.closeModal();
      },

      submitNewThread() {
        if (!this.editingTitleText.trim()) {
          let alert = {
            type: 'error',
            message: 'No thread title.'
          }
          this.$emit('submit-new-thread', alert);
          return;
        }
        if (!this.editingPostText.trim()) {
          let alert = {
            type: 'error',
            message: 'No post content.'
          }
          this.$emit('submit-new-thread', alert);
          return;
        }

        const myID = 945664;

        let sendData = {
          userID: myID,
          postType: 'text',
          title: this.editingTitleText,
          content: this.editingPostText
        };

        this.$store.dispatch('submitNewThread', sendData)
          .then(response => {
            let alert;
            if (response.status === 201) {
              alert = {
                type: 'success',
                message: 'New thread added!'
              }
              this.clearThreadProgress();
            }
            else {
              alert = {
                type: 'error',
                message: response.response
              }
            }
            this.$emit('submit-new-thread', alert);
          });
      },

      cancelNewThread() {
        this.$emit('cancel-new-thread');
      },

      openModal() {
        this.modalOpen = true;
      },
      closeModal() {
        this.modalOpen = false;
      }
    },
    created() {
      this.editingPostText = window.localStorage.getItem('new-thread-editing') || '';
      // console.log("this.editingText", this.editingText);
    },
    updated() {
      // console.log("this.editingText", this.editingText);
    }
  }
</script>


<style scoped lang="scss">

  .new-post {
    width: 60%;
    margin: 1rem 0;

    textarea {
      height: 80px;
      max-height: 300px;
    }

    > *:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  item-layout {
    @include flex($justify: center);

    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }

</style>
