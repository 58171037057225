<template>
  <div class="world-preview floating">

    <img :src="headerImage" />

    <div v-if="world" class="world-info">
      <h3>{{ world.name }}</h3>
      <div class="spacer h1"></div>
      <p class="description">{{ description }}</p>
      <div class="spacer h1"></div>

      <template v-if="world.lastUpdated">
        <h6>Last Updated</h6>
        <h5>{{ formatDate(world.lastUpdated, '&weekday, &month &dd, &year') }}</h5>
      </template>

      <div class="elements-info">
        <div class="element">
          <h6>Writings</h6>
          <h5>{{ num_writings }}</h5>
        </div>
        <div class="element">
          <h6>Wikis</h6>
          <h5>{{ num_wikis }}</h5>
        </div>
        <div class="element">
          <h6>Timelines</h6>
          <h5>{{ num_timelines }}</h5>
        </div>
        <div class="element">
          <h6>Maps</h6>
          <h5>{{ num_maps }}</h5>
        </div>
        <div class="element">
          <h6>Languages</h6>
          <h5>{{ num_languages }}</h5>
        </div>
      </div>
    </div>

    <div v-else class="world-info">
      <TextLoader />
      <div class="spacer h1"></div>
      <TextLoader />
      <TextLoader />
    </div>

  </div>
</template>

<script>
  import exampleWorld from './example_world'

  export default {
    name: 'WorldPreview',

    props: {
      worldID: [String],
      worldObject: [Object]
    },

    data() {
      return {
        worldData: null
      }
    },

    computed: {
      world() {
        return this.worldObject ? this.worldObject : this.worldData
      },

      headerImage() {
        return this.world?.headerImage ?
          this.publicImageURL('world', this.world?.headerImage, this.world?.authorID) :
          'https://media.scrybe-app.com/assets/mountains_01.jpg'
      },

      description() {
        if (!this.world?.description) return ''
        return this.world.description.length > 80 ?
          this.world.description.substring(0, 80) + '...' :
          this.world.description
      },

      num_writings() {
        if (!this.world) return 0
        return this.world.num_writings ?? this.world.writings?.length
      },
      num_wikis() {
        if (!this.world) return 0
        return this.world.num_wikis ?? this.world.wikis?.length
      },
      num_timelines() {
        if (!this.world) return 0
        return this.world.num_timelines ?? this.world.timelines?.length
      },
      num_maps() {
        if (!this.world) return 0
        return this.world.num_maps ?? this.world.maps?.length
      },
      num_languages() {
        if (!this.world) return 0
        return this.world.num_languages ?? this.world.languages?.length
      },
    },

    created() {
      if (this.worldID) {
        this.$store.dispatch('fetchWorld', this.worldID).then(response => {
          this.worldData = response.data
        })
      }
      else {
        this.worldData = exampleWorld
      }
    }
  }
</script>

<style scoped lang="scss">

  .world-preview {
    @include flex($direction: column);
    width: 400px;
    height: 450px;
    border-radius: 0.25rem;
    background-color: bg(20%);
    user-select: none;

    img {
      width: 100%;
      height: 200px;
      border-radius: 0.25rem 0.25rem 0 0;
    }

    &:hover {
      cursor: pointer;
      background-color: bg(25%);

      img {
        filter: brightness(115%);
      }
    }
  }

  .world-info {
    @include flex($direction: column);
    height: 100%;
    padding: 2rem;

    h3 {
      color: $colorA;
    }
    h5 {
      color: $colorB;
    }
    h6 {
      color: neutral(75%);
    }

    .description {
      font-size: 0.9rem;
      text-align: center;
      line-height: 1.25em;
      opacity: 0.75;
    }
  }

  .elements-info {
    @include flex($justify: center);
    margin-top: auto;

    .element {
      @include flex($direction: column);
      margin: 0 0.75rem;
    }
  }

</style>
