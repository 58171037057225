<template>
  <div class="page login">

    <h3>Welcome Back!</h3>

    <div class="inputs">
      <input
        type="text"
        placeholder="Email"
        :value="email"
        @keydown="onKeyDown"
        @input="(e) => email = e.target.value"
        autocomplete="email"
      />
      <input
        type="password"
        placeholder="Password"
        :value="password"
        @keydown="onKeyDown"
        @input="(e) => password = e.target.value"
        autocomplete="password"
      />
    </div>


    <Alert :alert="alert" @dismiss="alert = null" />


    <template v-if="loading">
      <div class="inline-loading-container">
        <Loader size="80px" />
      </div>
    </template>

    <template v-else>
      <CoolButton
        class="login-button"
        label="Login"
        type="compact"
        animation="infinite"
        textColor="#66f1d0"
        bgColor="rgb(102,241,208)"
        @click.native="login"
      />

      <router-link to="/forgot-password"><h4>Forgot Password?</h4></router-link>

      <div class="spacer h3"></div>

      <h4>Not a member? We'd love to have you.</h4>
      <CoolButton
        class="login-button"
        label="Register"
        type="compact"
        animation="infinite"
        textColor="#9eb3ff"
        bgColor="rgb(107,114,255)"
        @click.native="() => $router.push('/register')"
      />
    </template>

  </div>
</template>

<script>
  import { encrypt } from '@/utility';

  export default {
    name: 'Login',
    components: {
    },
    data() {
      return {
        loading: false,

        email: '',
        password: '',
        alert: null
      }
    },

    methods: {
      onKeyDown() {
        if (event.key == 'Enter') {
          this.login()
        }
      },

      async login() {
        this.alert = null

        if (!this.email.trim() || !this.password.trim()) {
          this.alert = { type: 'error', message: 'Please enter all fields.' };
          return;
        }

        let params = {
          email: this.email,
          password: this.password
        }


        this.loading = true

        const response = await this.$store.dispatch('login', params)

        if (response.status === 200) {
          this.alert = { type: 'success', message: 'Login successful!' };
          window.setTimeout(() => {
            this.$router.go(0)
          }, 500);
        }
        else {
          this.alert = { type: 'error', message: response?.data?.error };
        }

        this.loading = false
      }
    },

    created() {
      if (this.$store.state.user) {
        this.$router.push('/dashboard')
      }
    },

    mounted() {
      this.$store.dispatch('access')
    }

  }
</script>

<style scoped lang="scss">

  .login {
    @include flex($direction: column, $align: center);
    max-width: 420px;
    margin: 0 auto;
    padding: 4rem;
    color: #ccc;

    > *:not(:last-child) {
      margin-bottom: 2rem;
    }

    .inputs {
      > *:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    .login-button {
      max-width: 200px;
    }
  }

  h4 {
    color: #ccc;
  }

  a h4 {
    color: $colorB;

    &:hover {
      color: $colorA;
    }
  }

</style>
